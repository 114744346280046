import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ButtonItem, EventModel, EventElementModel, Action } from 'projects/common-lib/src/lib/ux-models';
import { NgModel } from '@angular/forms';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';

@Component({
  selector: 'ib-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.css']
})
export class ActionButtonComponent implements OnInit {

  @Input() public button: ButtonItem;
  @Input() public buttonStyle: string;
  @Input() public data: any;
  /**
  Control this action button is attached to (if any).
  */
  @Input() public control: NgModel;
  /**
  Cargo to pass with event (if any);
  */
  @Input() public cargo: any;
  @Input() public allowSearch: boolean = false;
  @Output() public click: EventEmitter<EventModel> = new EventEmitter();


  searchText: string = "";

  public isButtonVisible: boolean = true;

  protected originalLabel: string = "";
  protected originalIcon: string = "";

  get blockClass(): string {
    if (!this.button || !this.button.fullWidth) {
      return "";
    }
    return "btn-block";
  }

  get scrollHeightClass(): string {
    if (!this.button || !this.button.scrollHeight || this.button.scrollHeight === "none") {
      return "";
    }
    if (this.button.scrollHeight === "normal") {
      return "ib-dropdown-scroll";
    } else if (this.button.scrollHeight === "large") {
      return "ib-dropdown-scroll-large";
    } else if (this.button.scrollHeight === "auto") {
      // TODO make this more dynamic.  Can we get the position of the button compared to item count and
      // view height and determine which scroll class to apply (if any)?
      if (this.button.options && this.button.options.length > 10) {
        //console.error("max component height", Helper.getMaxComponentHeight());
        if (Helper.getMaxComponentHeight() > 550) {
          return "ib-dropdown-scroll-large";
        } else {
          return "ib-dropdown-scroll";
        }
      }
    }
    return "";
  }

  constructor(protected appService: AppService) { }

  ngOnInit() {
    if (this.button) {
      this.originalLabel = this.button.label;
      this.originalIcon = this.button.icon;
    } else {
      this.isButtonVisible = false;
    }
    this.update();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.button && this.button) {
      this.originalLabel = this.button.label;
      this.originalIcon = this.button.icon;
    }
    this.update();
    if (this.button) {
      this.isButtonVisible = this.isVisible(this.button);
    } else {
      this.isButtonVisible = false;
    }
  }

  update() {

    if (!this.button) {
      return;
    }

    if (this.button.getLabel) {
      try {
        this.button.label = this.button.getLabel(this.data, this.cargo);
      } catch (err) {
        Log.errorMessage(err);
      }
    }

    if (this.button.getIcon) {
      try {
        this.button.icon = this.button.getIcon(this.data, this.cargo);
      } catch (err) {
        Log.errorMessage(err);
      }
    }

    if (this.button.update) {
      try {
        this.button.update(this.button, this.data, this.cargo);
      } catch (err) {
        Log.errorMessage(err);
      }
    }

  }


  isVisible(action: Action): boolean {
    // No action then we're good
    if (!action) {
      return true;
    }
    // Check visible role (if any)
    if (action.requiresRole) {
      if (!this.appService.hasRole(action.requiresRole)) {
        // Missing role means false but existence of role doesn't guarantee true since there may be other visibility checks
        return false;
      }
    }
    // Check visible permission (if any)
    if (action.requiresPermissionAccessArea) {
      if (!this.appService.hasPermission(action.requiresPermissionAccessArea, Helper.getFirstDefinedString(action.requiresPermissionRight, "F"))) {
        // Missing permission means false but existence of permission doesn't guarantee true since there may be other visibility checks
        return false;
      }
    }
    // No visible function, or data then we default to visible
    if (!action.visible || (!this.data && !this.cargo)) {
      return true;
    }
    try {
      return action.visible(this.data, this.cargo);
    } catch (err) {
      Log.errorMessage(err);
      return true;
    }
  }

  isActive(action: Action): boolean {
    // No action, active function, or data then we default to not active
    if (!action || !action.active || (!this.data && !this.cargo)) {
      return false;
    }
    try {
      return action.active(this.data, this.cargo);
    } catch (err) {
      Log.errorMessage(err);
      return true;
    }
  }

  public fireClick(event: any, button: ButtonItem, action: Action, index: number) {
    let payload: EventModel;
    let cargo: any = {};
    if (this.cargo) {
      cargo = this.cargo;
    }
    cargo.action = action;
    cargo.button = button;
    cargo.actionIndex = index;
    cargo.control = this.control;
    if (action) {
      payload = new EventModel("click", event, this.data, new EventElementModel("action", action.actionId, "", action.label, action.icon), cargo);
      if (action.action) {
        action.action(payload);
      }
    } else if (button) {
      payload = new EventModel("click", event, this.data, new EventElementModel("button", button.actionId, "", button.label, button.icon), cargo);
      if (button.action) {
        button.action(payload);
      }
    }
    this.click.emit(payload);
    try {
      // Don't let event propagate resulting in double event firing (see https://stackoverflow.com/a/42112272).
      // If we used different event names then it wouldn't be an issue but using these event names is more developer friendly.
      event.stopPropagation();
      event.preventDefault();
    } catch (err) {
      //Log.errorMessage(err);
    }
  }

}
