// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';
import * as m5core from './ngModelsCore5';
import * as m5sec from './ngModelsSecurity5';
import * as m5web from './ngModelsWeb5';
import * as m5pay from './ngModelsPayment5';


/**
 * @name ApiReportId
 */
export module ApiReportId {
    export var InventoryExpiringMaintenance: string = "expiring-maintenance";
    export var InventoryExpiringWarranty: string = "expiring-warranty";
    export var InventoryExpiringLease: string = "expiring-lease";
}



/**
 * @name Activity
 * @description
 * The XRYYYYMM table contains activity records from voice, data, video, and content switches.  The XRYYYYMM table is an extended
 * record format which contains the largest amount of information but consumes the largest amount of space.  See the SRYYYYMM
 * and MRYYYYMM tables for other activity record storage options.  The table name is XRYYYYMM where XR is fixed, YYYY is the
 * year, and MM is the month.  A new activity table is created for each calendar month.  For example, the activity table for
 * May 2007 would be named XR200705.
 */
export class ActivityEditViewModel {

    /**
     * @name ActivityId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ActivityId: number = 0;

    /**
     * @name ActivityDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActivityDateTime: Date = null;

    /**
     * @name SourceNumber
     * @servertype String
     * @type {string}
     */
    SourceNumber: string = "";

    /**
     * @name AniIi
     * @servertype String
     * @type {string}
     */
    AniIi: string = "";

    /**
     * @name OtherNumber
     * @servertype String
     * @type {string}
     */
    OtherNumber: string = "";

    /**
     * @name OtherNumber2
     * @servertype String
     * @type {string}
     */
    OtherNumber2: string = "";

    /**
     * @name DnisNumber
     * @servertype String
     * @type {string}
     */
    DnisNumber: string = "";

    /**
     * @name TargetNumber
     * @servertype String
     * @type {string}
     */
    TargetNumber: string = "";

    /**
     * @name Description
     * @description 
     * Description of the activity record.  For example, voice records may use this for the city, state, country, etc.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Details
     * @description 
     * Additional details regarding the activity record.  For example, voice records may use this for the city, state, country,
     * etc.
     * @servertype String
     * @type {string}
     */
    Details: string = "";

    /**
     * @name AccessCode
     * @servertype String
     * @type {string}
     */
    AccessCode: string = "";

    /**
     * @name TotalUnits
     * @servertype Double (nullable)
     * @type {number}
     */
    TotalUnits: number = 0;

    /**
     * @name Connect
     * @description 
     * Connect duration represented in seconds.  Connect time is the number of seconds required to setup the connection (while the
     * phone rang, etc.).
     * @servertype Double (nullable)
     * @type {number}
     */
    Connect: number = 0;

    /**
     * @name HoldQueue
     * @servertype Double (nullable)
     * @type {number}
     */
    HoldQueue: number = 0;

    /**
     * @name ActualDuration
     * @servertype Double (nullable)
     * @type {number}
     */
    ActualDuration: number = 0;

    /**
     * @name SerialNumber
     * @servertype String
     * @type {string}
     */
    SerialNumber: string = "";

    /**
     * @name Source
     * @servertype IB.Web.API.Models.v5.ActivityEndpointEditViewModel
     * @type {ActivityEndpointEditViewModel}
     */
    Source: ActivityEndpointEditViewModel = null;

    /**
     * @name Target
     * @servertype IB.Web.API.Models.v5.ActivityEndpointEditViewModel
     * @type {ActivityEndpointEditViewModel}
     */
    Target: ActivityEndpointEditViewModel = null;

    /**
     * @name Extension
     * @description 
     * Extension associated with this activity record.
     * @servertype String
     * @type {string}
     */
    Extension: string = null;

    /**
     * @name TransferredExtension
     * @servertype String
     * @type {string}
     */
    TransferredExtension: string = "";

    /**
     * @name DirectoryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DirectoryId: number = 0;

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name SwitchCode
     * @servertype String
     * @type {string}
     */
    SwitchCode: string = "";

    /**
     * @name SwitchName
     * @servertype String
     * @type {string}
     */
    SwitchName: string = "";

    /**
     * @name SwitchId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SwitchId: number = 0;

    /**
     * @name Incoming
     * @description 
     * I = Incoming
     * O = Outgoing
     * E = Extension/Internal (i.e. neither incoming or outgoing)
     * D = DISA/Pass-Through/External (i.e. both incoming and outgoing)
     * @servertype String
     * @type {string}
     */
    Incoming: string = "D";

    /**
     * @name Completed
     * @description 
     * When true the activity was completed and should be considered billable.  For voice traffic, accurate reporting for this column
     * may depend on answer supervision control in the switch.
     * @servertype Boolean
     * @type {boolean}
     */
    Completed: boolean = false;

    /**
     * @name AccountCode
     * @servertype String
     * @type {string}
     */
    AccountCode: string = "";

    /**
     * @name AuthorizationCode
     * @servertype String
     * @type {string}
     */
    AuthorizationCode: string = "";

    /**
     * @name ClassificationType
     * @servertype String
     * @type {string}
     */
    ClassificationType: string = "";

    /**
     * @name OtherCode
     * @servertype String
     * @type {string}
     */
    OtherCode: string = "";

    /**
     * @name Phase
     * @description 
     * The phase field contains indicators of the types of records that are included in this activity record.  The specific values
     * are switch dependent.
     * @servertype String
     * @type {string}
     */
    Phase: string = "";

    /**
     * @name Flags
     * @description 
     * The flag field contains one or more switch dependent values.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name ServiceType
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name ServiceId
     * @servertype String
     * @type {string}
     */
    ServiceId: string = "";

    /**
     * @name NetworkId
     * @servertype String
     * @type {string}
     */
    NetworkId: string = "";

    /**
     * @name SubscriberId
     * @servertype String
     * @type {string}
     */
    SubscriberId: string = "";

    /**
     * @name GroupId
     * @servertype String
     * @type {string}
     */
    GroupId: string = "";

    /**
     * @name AccountingId
     * @servertype String
     * @type {string}
     */
    AccountingId: string = "";

    /**
     * @name ClassOfService
     * @servertype String
     * @type {string}
     */
    ClassOfService: string = "";

    /**
     * @name Quality
     * @description 
     * Voice quality - switch dependent.
     * @servertype String
     * @type {string}
     */
    Quality: string = "";

    /**
     * @name VocodingType
     * @servertype String
     * @type {string}
     */
    VocodingType: string = "";

    /**
     * @name Disconnect
     * @description 
     * The disconnect code field is a switch dependent value specifying the disconnect status and/or resolution for this activity
     * record (i.e. success, failure, etc.).
     * @servertype String
     * @type {string}
     */
    Disconnect: string = "";

    /**
     * @name CostCenters
     * @servertype ActivityCostCenterEditViewModel array
     * @type {ActivityCostCenterEditViewModel[]}
     */
    CostCenters: ActivityCostCenterEditViewModel[] = [];

    /**
     * @name FaxPages
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FaxPages: number = 0;

    /**
     * @name PacketsSentSource
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsSentSource: number = 0;

    /**
     * @name PacketsReceivedSource
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsReceivedSource: number = 0;

    /**
     * @name PacketsSentTarget
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsSentTarget: number = 0;

    /**
     * @name PacketsReceivedTarget
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsReceivedTarget: number = 0;

    /**
     * @name PacketsLostSource
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsLostSource: number = 0;

    /**
     * @name PacketsLostTarget
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PacketsLostTarget: number = 0;

    /**
     * @name JitterSource
     * @servertype Int32 (nullable)
     * @type {number}
     */
    JitterSource: number = 0;

    /**
     * @name JitterTarget
     * @servertype Int32 (nullable)
     * @type {number}
     */
    JitterTarget: number = 0;

    /**
     * @name LatencySource
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LatencySource: number = 0;

    /**
     * @name LatencyTarget
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LatencyTarget: number = 0;

    /**
     * @name OctetsSentSource
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OctetsSentSource: number = 0;

    /**
     * @name OctetsReceivedSource
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OctetsReceivedSource: number = 0;

    /**
     * @name OctetsSentTarget
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OctetsSentTarget: number = 0;

    /**
     * @name OctetsReceivedTarget
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OctetsReceivedTarget: number = 0;

    /**
     * @name Stat01SentSource
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat01SentSource: number = 0;

    /**
     * @name Stat01ReceivedSource
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat01ReceivedSource: number = 0;

    /**
     * @name Stat01SentTarget
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat01SentTarget: number = 0;

    /**
     * @name Stat01ReceivedTarget
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat01ReceivedTarget: number = 0;

    /**
     * @name Stat02
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat02: number = 0;

    /**
     * @name Stat03
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat03: number = 0;

    /**
     * @name Stat04
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat04: number = 0;

    /**
     * @name Stat05
     * @servertype Double (nullable)
     * @type {number}
     */
    Stat05: number = 0;

    /**
     * @name Stat06
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat06: number = 0;

    /**
     * @name Stat07
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat07: number = 0;

    /**
     * @name Stat08
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat08: number = 0;

    /**
     * @name Stat09
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat09: number = 0;

    /**
     * @name Stat10
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Stat10: number = 0;

    /**
     * @name Bandwidth
     * @description 
     * Bandwidth expressed in bits per second.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Bandwidth: number = 0;

    /**
     * @name RecordStatus
     * @servertype Byte (nullable)
     * @type {number}
     */
    RecordStatus: number = 0;

    /**
     * @name RecordFlag
     * @servertype Byte (nullable)
     * @type {number}
     */
    RecordFlag: number = 0;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name UtcOffset
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZoneDescription
     * @servertype String
     * @type {string}
     */
    TimeZoneDescription: string = "";

    /**
     * @name DataSourceTableName
     * @servertype String
     * @type {string}
     */
    DataSourceTableName: string = "";

}



/**
 * @name Activity Endpoint
 */
export class ActivityEndpointEditViewModel {

    /**
     * @name SwitchDomainName
     * @servertype String
     * @type {string}
     */
    SwitchDomainName: string = "";

    /**
     * @name SwitchIpAddress
     * @servertype String
     * @type {string}
     */
    SwitchIpAddress: string = "";

    /**
     * @name GatewayId
     * @servertype String
     * @type {string}
     */
    GatewayId: string = "";

    /**
     * @name SwitchCode
     * @servertype String
     * @type {string}
     */
    SwitchCode: string = "";

    /**
     * @name SwitchName
     * @servertype String
     * @type {string}
     */
    SwitchName: string = "";

    /**
     * @name PortGroupIn
     * @servertype String
     * @type {string}
     */
    PortGroupIn: string = "";

    /**
     * @name PortIn
     * @servertype String
     * @type {string}
     */
    PortIn: string = "";

    /**
     * @name PortGroupOut
     * @servertype String
     * @type {string}
     */
    PortGroupOut: string = "";

    /**
     * @name PortOut
     * @servertype String
     * @type {string}
     */
    PortOut: string = "";

    /**
     * @name Info
     * @servertype String
     * @type {string}
     */
    Info: string = "";

    /**
     * @name SwitchId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SwitchId: number = 0;

    /**
     * @name PortGroupInId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PortGroupInId: number = 0;

    /**
     * @name PortInId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PortInId: number = 0;

    /**
     * @name PortGroupOutId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PortGroupOutId: number = 0;

    /**
     * @name PortOutId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PortOutId: number = 0;

    /**
     * @name ConnectionType
     * @servertype String
     * @type {string}
     */
    ConnectionType: string = "";

    /**
     * @name Endpoint
     * @servertype String
     * @type {string}
     */
    Endpoint: string = "";

    /**
     * @name Location
     * @servertype String
     * @type {string}
     */
    Location: string = "";

}



/**
 * @name Activity Cost Center
 */
export class ActivityCostCenterEditViewModel {

    /**
     * @name CostCenterType
     * @servertype String
     * @type {string}
     */
    CostCenterType: string = "";

    /**
     * @name CostCenterLevel
     * @servertype Byte
     * @type {number}
     */
    CostCenterLevel: number = 0;

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name BaseCost
     * @servertype Double (nullable)
     * @type {number}
     */
    BaseCost: number = 0;

    /**
     * @name FullCost
     * @servertype Double (nullable)
     * @type {number}
     */
    FullCost: number = 0;

    /**
     * @name Duration
     * @servertype Double (nullable)
     * @type {number}
     */
    Duration: number = 0;

    /**
     * @name RateResult
     * @servertype Int16 (nullable)
     * @type {number}
     */
    RateResult: number = 0;

    /**
     * @name RateCode
     * @servertype String
     * @type {string}
     */
    RateCode: string = "";

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name PackageOccurrenceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = 0;

    /**
     * @name BillingResult
     * @servertype Int16 (nullable)
     * @type {number}
     */
    BillingResult: number = 0;

    /**
     * @name BillingTransactionId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingTransactionId: number = 0;

    /**
     * @name ExceptionBillingTransactionId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ExceptionBillingTransactionId: number = 0;

}



/**
 * @name Authorization Code List
 * @description
 * The association table contains information detailing associations with activity, usage, services, etc. and how they relate
 * to packages, billing accounts, or contacts.
 */
export class AuthorizationCodeListViewModel {

    /**
     * @name AssociationId
     * @description 
     * The id of the association.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssociationId: number = null;

    /**
     * @name ExternalAssociationId
     * @description 
     * Association Id used in external systems or as an alternate means of identification.
     * @servertype String
     * @type {string}
     */
    ExternalAssociationId: string = "";

    /**
     * @name Description
     * @description 
     * A description for this association.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Independent
     * @description 
     * True indicates the association is independent of any typical ownership and typical owner id values will all be null.
     * @servertype Boolean
     * @type {boolean}
     */
    Independent: boolean = false;

    /**
     * @name ParentAssociationId
     * @description 
     * The association id that represents the parent of this association (if any).  This can be used to facilitate child associations
     * for associations that are always linked together.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentAssociationId: number = null;

    /**
     * @name PackageOccurrenceId
     * @description 
     * Package Occurrence Id for the package occurrence this association record belongs to.  This can be null when owned elsewhere.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = null;

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id for the billing account this association record belongs to.  This can be null when owned elsewhere.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name ContactId
     * @description 
     * Contact Id for the contact this association record belongs to.  This can be null when owned elsewhere.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name SwitchId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SwitchId: number = 0;

    /**
     * @name VoucherId
     * @description 
     * When the association is linked to a voucher for life cycle, balance, etc. reasons this is the id of the voucher the association
     * is linked to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VoucherId: number = null;

    /**
     * @name InventoryHistoryId
     * @description 
     * When the association was triggered by an inventory assignment this provides a reference to the inventory history id that
     * uniquely identifies the inventory history record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryHistoryId: number = null;

    /**
     * @name EntityType
     * @description 
     * The entity type is related to contact type and contact level and represents a context for the association ownership.  The
     * same association cannot exist twice with overlapping scope and entity type.  Possible values include: 
     * R1 = Retail 1
     * R2 = Retail 2
     * A1 = Agent 1
     * A2 = Agent 2
     * W1 = Wholesale 1
     * W2 = Wholesale 2
     * W3 = Wholesale 3
     * @servertype String
     * @type {string}
     */
    EntityType: string = "R1";

    /**
     * @name AuthorizationCodeGroup
     * @servertype String
     * @type {string}
     */
    AuthorizationCodeGroup: string = "";

    /**
     * @name AuthorizationCode
     * @servertype String
     * @type {string}
     */
    AuthorizationCode: string = "";

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this association is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this association is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name UtcOffset
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name Authorization Code
 * @description
 * The association table contains information detailing associations with activity, usage, services, etc. and how they relate
 * to packages, billing accounts, or contacts.
 */
export class AuthorizationCodeEditViewModel {

    /**
     * @name AssociationId
     * @description 
     * The id of the association.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssociationId: number = null;

    /**
     * @name ExternalAssociationId
     * @description 
     * Association Id used in external systems or as an alternate means of identification.
     * @servertype String
     * @type {string}
     */
    ExternalAssociationId: string = "";

    /**
     * @name Description
     * @description 
     * A description for this association.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Independent
     * @description 
     * True indicates the association is independent of any typical ownership and typical owner id values will all be null.
     * @servertype Boolean
     * @type {boolean}
     */
    Independent: boolean = false;

    /**
     * @name ParentAssociationId
     * @description 
     * The association id that represents the parent of this association (if any).  This can be used to facilitate child associations
     * for associations that are always linked together.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentAssociationId: number = null;

    /**
     * @name PackageOccurrenceId
     * @description 
     * Package Occurrence Id for the package occurrence this association record belongs to.  This can be null when owned elsewhere.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = null;

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id for the billing account this association record belongs to.  This can be null when owned elsewhere.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name ContactId
     * @description 
     * Contact Id for the contact this association record belongs to.  This can be null when owned elsewhere.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name SwitchId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SwitchId: number = 0;

    /**
     * @name VoucherId
     * @description 
     * When the association is linked to a voucher for life cycle, balance, etc. reasons this is the id of the voucher the association
     * is linked to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VoucherId: number = null;

    /**
     * @name InventoryHistoryId
     * @description 
     * When the association was triggered by an inventory assignment this provides a reference to the inventory history id that
     * uniquely identifies the inventory history record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryHistoryId: number = null;

    /**
     * @name EntityType
     * @description 
     * The entity type is related to contact type and contact level and represents a context for the association ownership.  The
     * same association cannot exist twice with overlapping scope and entity type.  Possible values include: 
     * R1 = Retail 1
     * R2 = Retail 2
     * A1 = Agent 1
     * A2 = Agent 2
     * W1 = Wholesale 1
     * W2 = Wholesale 2
     * W3 = Wholesale 3
     * @servertype String
     * @type {string}
     */
    EntityType: string = "R1";

    /**
     * @name AuthorizationCodeGroup
     * @servertype String
     * @type {string}
     */
    AuthorizationCodeGroup: string = "";

    /**
     * @name AuthorizationCode
     * @servertype String
     * @type {string}
     */
    AuthorizationCode: string = "";

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this association is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this association is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Password
     * @description 
     * The password that is required for this association (if any).
     * @servertype String
     * @type {string}
     */
    Password: string = "";

    /**
     * @name AuthenticationCount
     * @description 
     * The number of times this authentication has been performed.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AuthenticationCount: number = 0;

    /**
     * @name FailedAuthenticationCount
     * @description 
     * The number of times this authentication failed since the last successful authentication.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FailedAuthenticationCount: number = 0;

    /**
     * @name LastAuthenticationDateTime
     * @description 
     * The date and time when this authentication was last done.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAuthenticationDateTime: Date = null;

    /**
     * @name LastAuthenticationInformation
     * @description 
     * Information about the last authentication.
     * @servertype String
     * @type {string}
     */
    LastAuthenticationInformation: string = "";

    /**
     * @name Status
     * @description 
     * Status of the association.  Possible values include: 
     * P = Pending (Association is not active yet)
     * A = Active (Association is active)
     * I = Inactive (Association flagged inactive per business rules)
     * E = Expired (Association is past the valid end date time)
     * S = Suspended (Association has been suspended)
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name InUse
     * @description 
     * When true this association is currently being used.  This flag is used to prevent simultaneous use of single-user associations.
     * @servertype Boolean
     * @type {boolean}
     */
    InUse: boolean = false;

    /**
     * @name InUseInformation
     * @description 
     * Information about the resource currently using this association.
     * @servertype String
     * @type {string}
     */
    InUseInformation: string = "";

    /**
     * @name Comments
     * @description 
     * Comments about the association.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Cargo
     * @servertype System.Object
     * @type {any}
     */
    Cargo: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Association List
 * @description
 * The association table contains information detailing associations with activity, usage, services, etc. and how they relate
 * to packages, billing accounts, or contacts.
 */
export class AssociationListViewModel {

    /**
     * @name AssociationId
     * @description 
     * The id of the association.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssociationId: number = null;

    /**
     * @name ExternalAssociationId
     * @description 
     * Association Id used in external systems or as an alternate means of identification.
     * @servertype String
     * @type {string}
     */
    ExternalAssociationId: string = "";

    /**
     * @name Description
     * @description 
     * A description for this association.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Unassigned
     * @description 
     * True indicates the association is unassigned until the valid end date time is reached and then is available for use.
     * @servertype Boolean
     * @type {boolean}
     */
    Unassigned: boolean = false;

    /**
     * @name Independent
     * @description 
     * True indicates the association is independent of any typical ownership and typical owner id values will all be null.
     * @servertype Boolean
     * @type {boolean}
     */
    Independent: boolean = false;

    /**
     * @name ParentAssociationId
     * @description 
     * The association id that represents the parent of this association (if any).  This can be used to facilitate child associations
     * for associations that are always linked together.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentAssociationId: number = null;

    /**
     * @name PackageOccurrenceId
     * @description 
     * Package Occurrence Id for the package occurrence this association record belongs to.  This can be null when owned elsewhere.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = null;

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id for the billing account this association record belongs to.  This can be null when owned elsewhere.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name ContactId
     * @description 
     * Contact Id for the contact this association record belongs to.  This can be null when owned elsewhere.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name NetworkElementId
     * @description 
     * The id of the network element this association record belongs to.  This can be null when association value is not linked
     * to a network element.  Network port groups and ports should have this value populated.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NetworkElementId: number = null;

    /**
     * @name VoucherId
     * @description 
     * When the association is linked to a voucher for life cycle, balance, etc. reasons this is the id of the voucher the association
     * is linked to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VoucherId: number = null;

    /**
     * @name InventoryHistoryId
     * @description 
     * When the association was triggered by an inventory assignment this provides a reference to the inventory history id that
     * uniquely identifies the inventory history record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryHistoryId: number = null;

    /**
     * @name EntityType
     * @description 
     * The entity type is related to contact type and contact level and represents a context for the association ownership.  The
     * same association cannot exist twice with overlapping scope and entity type.  Possible values include: 
     * R1 = Retail 1
     * R2 = Retail 2
     * A1 = Agent 1
     * A2 = Agent 2
     * W1 = Wholesale 1
     * W2 = Wholesale 2
     * W3 = Wholesale 3
     * @servertype String
     * @type {string}
     */
    EntityType: string = "R1";

    /**
     * @name AssociationGroup
     * @description 
     * The group this association belongs to.  This can be null when owned elsewhere or when no grouping is required.
     * @servertype String
     * @type {string}
     */
    AssociationGroup: string = "";

    /**
     * @name AssociationType
     * @description 
     * The type of association.  Possible values include: 
     * EXT = Extension
     * TRE = Transferred Extension
     * ACC = Account Code
     * AUT = Authorization Code.
     * NET = Network Id
     * SUB = Subscriber Id
     * SID = Source Identifier
     * TID = Target Identifier
     * OTI = Original Target Identifier
     * OI1 = Other Identifier 1
     * OI2 = Other Identifier 2
     * SNE = Source Network Element
     * SGI = Source Port Group In
     * SPI = Source Port Number In
     * SGO = Source Port Group Out
     * SPO = Source Port Number Out
     * TNE = Target Network Element
     * TGI = Target Port Group In
     * TPI = Target Port Number In
     * TGO = Target Port Group Out
     * TPO = Target Port Number Out
     * @servertype String
     * @type {string}
     */
    AssociationType: string = "";

    /**
     * @name AssociationValue
     * @description 
     * The association value of type specified by the association type.
     * @servertype String
     * @type {string}
     */
    AssociationValue: string = "";

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this association is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this association is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name SharedAssociation
     * @description 
     * When true this association is shared and only is associated with the assigned entity based on either the contact work schedule
     * or based on the days, start time, and end time values.  Note that typically the same association type and value are not allowed
     * to have overlapping valid date ranges but when this shared association setting is true duplicates are expressly allowed.
     * @servertype Boolean
     * @type {boolean}
     */
    SharedAssociation: boolean = false;

    /**
     * @name UseWorkSchedule
     * @description 
     * When true shared use of this association is governed by the contact work schedule.
     * @servertype Boolean
     * @type {boolean}
     */
    UseWorkSchedule: boolean = false;

    /**
     * @name Days
     * @description 
     * A string of codes that represent the days the shared association is associated with the assigned entity.  Where: 
     * S = Sunday
     * M = Monday
     * T = Tuesday
     * W = Wednesday
     * R = Thursday
     * F = Friday
     * U = Saturday
     * @servertype String
     * @type {string}
     */
    Days: string = "";

    /**
     * @name StartTime
     * @description 
     * Start time in format HH:MM representing the starting time of day on days specified in the days when the shared association
     * is associated with the assigned entity.
     * @servertype String
     * @type {string}
     */
    StartTime: string = "";

    /**
     * @name EndTime
     * @description 
     * End time in format HH:MM representing the ending time of day on days specified in the days when the shared association is
     * associated with the assigned entity.
     * @servertype String
     * @type {string}
     */
    EndTime: string = "";

    /**
     * @name Status
     * @description 
     * Status of the association.  Possible values include: 
     * P = Pending (Association is not active yet)
     * A = Active (Association is active)
     * I = Inactive (Association flagged inactive per business rules)
     * E = Expired (Association is past the valid end date time)
     * S = Suspended (Association has been suspended)
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name InUse
     * @description 
     * When true this association is currently being used.  This flag is used to prevent simultaneous use of single-user associations.
     * @servertype Boolean
     * @type {boolean}
     */
    InUse: boolean = false;

    /**
     * @name InUseInformation
     * @description 
     * Information about the resource currently using this association.
     * @servertype String
     * @type {string}
     */
    InUseInformation: string = "";

    /**
     * @name TransferredIn
     * @description 
     * When true this association value was transferred in.  This can be used when the association represents a portable resource.
     * @servertype Boolean
     * @type {boolean}
     */
    TransferredIn: boolean = false;

    /**
     * @name TransferredOut
     * @description 
     * When true this association value was transferred out.  This can be used when the association represents a portable resource.
     * @servertype Boolean
     * @type {boolean}
     */
    TransferredOut: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name Association
 * @description
 * The association table contains information detailing associations with activity, usage, services, etc. and how they relate
 * to packages, billing accounts, or contacts.
 */
export class AssociationEditViewModel {

    /**
     * @name AssociationId
     * @description 
     * The id of the association.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssociationId: number = null;

    /**
     * @name ExternalAssociationId
     * @description 
     * Association Id used in external systems or as an alternate means of identification.
     * @servertype String
     * @type {string}
     */
    ExternalAssociationId: string = "";

    /**
     * @name Description
     * @description 
     * A description for this association.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Unassigned
     * @description 
     * True indicates the association is unassigned until the valid end date time is reached and then is available for use.
     * @servertype Boolean
     * @type {boolean}
     */
    Unassigned: boolean = false;

    /**
     * @name Independent
     * @description 
     * True indicates the association is independent of any typical ownership and typical owner id values will all be null.
     * @servertype Boolean
     * @type {boolean}
     */
    Independent: boolean = false;

    /**
     * @name ParentAssociationId
     * @description 
     * The association id that represents the parent of this association (if any).  This can be used to facilitate child associations
     * for associations that are always linked together.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentAssociationId: number = null;

    /**
     * @name PackageOccurrenceId
     * @description 
     * Package Occurrence Id for the package occurrence this association record belongs to.  This can be null when owned elsewhere.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = null;

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id for the billing account this association record belongs to.  This can be null when owned elsewhere.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name ContactId
     * @description 
     * Contact Id for the contact this association record belongs to.  This can be null when owned elsewhere.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name NetworkElementId
     * @description 
     * The id of the network element this association record belongs to.  This can be null when association value is not linked
     * to a network element.  Network port groups and ports should have this value populated.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NetworkElementId: number = null;

    /**
     * @name VoucherId
     * @description 
     * When the association is linked to a voucher for life cycle, balance, etc. reasons this is the id of the voucher the association
     * is linked to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VoucherId: number = null;

    /**
     * @name InventoryHistoryId
     * @description 
     * When the association was triggered by an inventory assignment this provides a reference to the inventory history id that
     * uniquely identifies the inventory history record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryHistoryId: number = null;

    /**
     * @name EntityType
     * @description 
     * The entity type is related to contact type and contact level and represents a context for the association ownership.  The
     * same association cannot exist twice with overlapping scope and entity type.  Possible values include: 
     * R1 = Retail 1
     * R2 = Retail 2
     * A1 = Agent 1
     * A2 = Agent 2
     * W1 = Wholesale 1
     * W2 = Wholesale 2
     * W3 = Wholesale 3
     * @servertype String
     * @type {string}
     */
    EntityType: string = "R1";

    /**
     * @name AssociationGroup
     * @description 
     * The group this association belongs to.  This can be null when owned elsewhere or when no grouping is required.
     * @servertype String
     * @type {string}
     */
    AssociationGroup: string = "";

    /**
     * @name AssociationType
     * @description 
     * The type of association.  Possible values include: 
     * EXT = Extension
     * TRE = Transferred Extension
     * ACC = Account Code
     * AUT = Authorization Code.
     * NET = Network Id
     * SUB = Subscriber Id
     * SID = Source Identifier
     * TID = Target Identifier
     * OTI = Original Target Identifier
     * OI1 = Other Identifier 1
     * OI2 = Other Identifier 2
     * SNE = Source Network Element
     * SGI = Source Port Group In
     * SPI = Source Port Number In
     * SGO = Source Port Group Out
     * SPO = Source Port Number Out
     * TNE = Target Network Element
     * TGI = Target Port Group In
     * TPI = Target Port Number In
     * TGO = Target Port Group Out
     * TPO = Target Port Number Out
     * @servertype String
     * @type {string}
     */
    AssociationType: string = "";

    /**
     * @name AssociationValue
     * @description 
     * The association value of type specified by the association type.
     * @servertype String
     * @type {string}
     */
    AssociationValue: string = "";

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this association is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this association is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name SharedAssociation
     * @description 
     * When true this association is shared and only is associated with the assigned entity based on either the contact work schedule
     * or based on the days, start time, and end time values.  Note that typically the same association type and value are not allowed
     * to have overlapping valid date ranges but when this shared association setting is true duplicates are expressly allowed.
     * @servertype Boolean
     * @type {boolean}
     */
    SharedAssociation: boolean = false;

    /**
     * @name UseWorkSchedule
     * @description 
     * When true shared use of this association is governed by the contact work schedule.
     * @servertype Boolean
     * @type {boolean}
     */
    UseWorkSchedule: boolean = false;

    /**
     * @name Days
     * @description 
     * A string of codes that represent the days the shared association is associated with the assigned entity.  Where: 
     * S = Sunday
     * M = Monday
     * T = Tuesday
     * W = Wednesday
     * R = Thursday
     * F = Friday
     * U = Saturday
     * @servertype String
     * @type {string}
     */
    Days: string = "";

    /**
     * @name StartTime
     * @description 
     * Start time in format HH:MM representing the starting time of day on days specified in the days when the shared association
     * is associated with the assigned entity.
     * @servertype String
     * @type {string}
     */
    StartTime: string = "";

    /**
     * @name EndTime
     * @description 
     * End time in format HH:MM representing the ending time of day on days specified in the days when the shared association is
     * associated with the assigned entity.
     * @servertype String
     * @type {string}
     */
    EndTime: string = "";

    /**
     * @name Password
     * @description 
     * The password that is required for this association (if any).
     * @servertype String
     * @type {string}
     */
    Password: string = "";

    /**
     * @name AuthenticationCount
     * @description 
     * The number of times this authentication has been performed.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AuthenticationCount: number = 0;

    /**
     * @name FailedAuthenticationCount
     * @description 
     * The number of times this authentication failed since the last successful authentication.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FailedAuthenticationCount: number = 0;

    /**
     * @name LastAuthenticationDateTime
     * @description 
     * The date and time when this authentication was last done.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAuthenticationDateTime: Date = null;

    /**
     * @name LastAuthenticationInformation
     * @description 
     * Information about the last authentication.
     * @servertype String
     * @type {string}
     */
    LastAuthenticationInformation: string = "";

    /**
     * @name Status
     * @description 
     * Status of the association.  Possible values include: 
     * P = Pending (Association is not active yet)
     * A = Active (Association is active)
     * I = Inactive (Association flagged inactive per business rules)
     * E = Expired (Association is past the valid end date time)
     * S = Suspended (Association has been suspended)
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name InUse
     * @description 
     * When true this association is currently being used.  This flag is used to prevent simultaneous use of single-user associations.
     * @servertype Boolean
     * @type {boolean}
     */
    InUse: boolean = false;

    /**
     * @name InUseInformation
     * @description 
     * Information about the resource currently using this association.
     * @servertype String
     * @type {string}
     */
    InUseInformation: string = "";

    /**
     * @name TransferredIn
     * @description 
     * When true this association value was transferred in.  This can be used when the association represents a portable resource.
     * @servertype Boolean
     * @type {boolean}
     */
    TransferredIn: boolean = false;

    /**
     * @name TransferredOut
     * @description 
     * When true this association value was transferred out.  This can be used when the association represents a portable resource.
     * @servertype Boolean
     * @type {boolean}
     */
    TransferredOut: boolean = false;

    /**
     * @name Comments
     * @description 
     * Comments about the association.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Billing Account
 * @description
 * The billing account table contains information about billing accounts for customers, agents, etc.
 */
export class BillingAccountListViewModel {

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id uniquely identifies this billing account.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name ParentBillingAccountId
     * @description 
     * Parent Billing Account Id represents the billing account id this billing account is a subaccount under.  For non-subaccounts
     * this value will be null.  Note that not all columns in this table are valid for subaccounts since they share a balance with
     * their parent account, are consolidated on the parent account invoice, etc.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentBillingAccountId: number = null;

    /**
     * @name ContactId
     * @description 
     * The Contact Id of the contact this billing account belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name BillingAccountType
     * @description 
     * Billing account type for this billing account.  Possible values include:
     * R = Receivable - accounts receivable billing account.
     * P = Payable - accounts payable billing account.
     * @servertype String
     * @type {string}
     */
    BillingAccountType: string = "R";

    /**
     * @name BillingProfileId
     * @description 
     * Billing Profile Id used for this account.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingProfileId: number = null;

    /**
     * @name AccountNumber
     * @description 
     * Account number for this billing account.
     * @servertype String
     * @type {string}
     */
    AccountNumber: string = "";

    /**
     * @name ExternalAccountNumber
     * @description 
     * External account number is the unique account number used to identify this billing account in external systems.
     * @servertype String
     * @type {string}
     */
    ExternalAccountNumber: string = "";

    /**
     * @name AccountName
     * @description 
     * Account name for this billing account.
     * @servertype String
     * @type {string}
     */
    AccountName: string = "";

    /**
     * @name AccountType
     * @description 
     * Account type for this billing account.
     * @servertype String
     * @type {string}
     */
    AccountType: string = "";

    /**
     * @name AccountClass
     * @description 
     * Account class for this billing account.  This field can be used to classify accounts by a common value as specified in a
     * system pick list.
     * @servertype String
     * @type {string}
     */
    AccountClass: string = "";

    /**
     * @name AccountGroup
     * @description 
     * Account group for this billing account.  This field can be used to group accounts by a common value as specified for the
     * billing entity this account belongs to.
     * @servertype String
     * @type {string}
     */
    AccountGroup: string = "";

    /**
     * @name MarketType
     * @description 
     * Market type for this billing account.
     * @servertype String
     * @type {string}
     */
    MarketType: string = "";

    /**
     * @name ServiceType
     * @description 
     * Type of service for this account.  Possible values are:
     * N = Normal Postpaid Account
     * P = Prepaid Account
     * M = Prepaid Multiuser Account (This service type is identical to prepaid service type except that multiple users can use
     * this prepaid account at the same time.)
     * O = One-time Use Account (This service type is identical to prepaid service type except when the first usage record terminates
     * the balance is automatically set to zero.)
     * C = Clearinghouse Member Account (This service type is identical to normal service type except that instead of billing for
     * usage records where the IDs match (i.e. Directory Id for directory entries and Customer Id for customer entries), billing
     * is done where the ID for this account matches the Network Id of the Source Network Element Id or Target Network Element Id
     * of the usage record.  Usage records where the Id matches the Source Network Element Id (i.e. this clearinghouse member's
     * network originates the usage) increases the invoice amount.  Usage records where the ID matches the Target Network Element
     * Id (i.e. this clearinghouse member's network terminates the usage) decreases the invoice amount.  Usage records where the
     * Network Id of the Source Network Element Id and Target Network Element Id match result in no change to the invoice amount.
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "N";

    /**
     * @name Status
     * @description 
     * Status of the billing account.  Possible values include: 
     * A = Active.  Account is active (same as if column is null or empty).
     * I = Inactive.  Account is inactive (as determined by business rule with no special treatment).
     * S = Suspended.  Account is suspended.
     * C = Closed.  Account is closed or cancelled.
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

    /**
     * @name CreatedDateTime
     * @description 
     * Date and time when the account was created.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CreatedDateTime: Date = null;

    /**
     * @name SuspendedDateTime
     * @description 
     * Date and time when the account was last suspended.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedDateTime: Date = null;

    /**
     * @name ClosedDateTime
     * @description 
     * Date and time when account was last closed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ClosedDateTime: Date = null;

    /**
     * @name ReactivatedDateTime
     * @description 
     * Date and time when the account was last reactivated after being suspended or closed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReactivatedDateTime: Date = null;

    /**
     * @name IsoCurrencyCode
     * @description 
     * ISO Currency Code.  Ignored for subaccounts.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Balance
     * @description 
     * Account balance representing the amount owed by this customer.  Invoices increase this amount, payments decrease this amount
     * and adjustments can increase or decrease this amount depending upon the adjustment.  For postpaid billing accounts this amount
     * is changed only when an invoice, payment or adjustment is entered into the system.  For prepaid billing accounts this amount
     * is normally negative indicating that there were more payments than billed activity records.  Since the account is prepaid
     * this is as expected.  The balance field is changed when an activity record, payment or adjustment is entered into the system.
     *  Since prepaid accounts are no longer active when the balance reaches the credit limit (normally zero), waiting for an invoice
     * to be created is not an option and the balance has to be adjusted for each and every activity record.  Ignored for subaccounts.
     * @servertype Double (nullable)
     * @type {number}
     */
    Balance: number = 0;

    /**
     * @name PendingInvoiceAmount
     * @description 
     * This is the pending invoice amount and is a support field for balance.  The behavior of this field depends upon the value
     * of the service type.  Ignored for subaccounts.
     * For prepaid accounts, this value increases with every activity record just like the balance field.  When an invoice is created
     * for a prepaid account, it is created for the amount represented by this field and this value is set to zero.  This allows
     * all activity (invoices, payments and adjustments) to reconcile to the balance.
     * For postpaid and clearinghouse member accounts, the balance field only increases when an invoice is created and not with
     * each activity record.  This pending invoice amount, however, does increment with each activity record.  When an invoice is
     * created, this field is decreased by the invoice amount.  This field provides a real-time balance figure that can be used
     * to do comparisons with the account's credit limit.
     * @servertype Double (nullable)
     * @type {number}
     */
    PendingInvoiceAmount: number = 0;

    /**
     * @name CreditHold
     * @description 
     * When true the account is on credit hold.  Ignored for subaccounts.
     * @servertype Boolean
     * @type {boolean}
     */
    CreditHold: boolean = false;

    /**
     * @name BillingCycle
     * @description 
     * The billing cycle outlines how frequently invoices should be generated for this account: 
     * D = Daily (Billing cycle interval is not used.)
     * W = Weekly (Billing cycle interval is the day of week with 0 being Sunday.)
     * S = Semi-Monthly (Billing cycle interval is the day of the month to generate the first invoice on and that interval +14 is
     * the day of the month to generate the second invoice on.)
     * M = Monthly (Billing cycle interval is the day of the month to generate invoices on 1-31 or last if the interval exceeds
     * the number of days in a month.)
     * Q = Quarterly (Billing cycle interval is MMDD.  Invoices will happen on the quarterly anniversary of MMDD.)
     * Y = Yearly (Billing cycle interval is MMDD.  Invoices will happen on the annual anniversary of MMDD.)
     * C = Custom (Billing cycle interval is the number 
     * of days to wait to generate the invoice.  For example, if 10 the invoices are generated every 10 days.)
     * N = N/A - On demand only
     * Ignored for subaccounts.
     * @servertype String
     * @type {string}
     */
    BillingCycle: string = "M";

    /**
     * @name BillingCycleInterval
     * @description 
     * The billing cycle interval.  See billing cycle for more information.  Ignored for subaccounts.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    BillingCycleInterval: number = 0;

    /**
     * @name LastInvoiceNumber
     * @description 
     * Invoice number for the last invoice created for this account.  Ignored for subaccounts.
     * @servertype String
     * @type {string}
     */
    LastInvoiceNumber: string = "";

    /**
     * @name LastInvoiceDate
     * @description 
     * Date when the last invoice was created for this account.  Ignored for subaccounts.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastInvoiceDate: Date = null;

    /**
     * @name LastInvoiceAmount
     * @description 
     * Amount of the last invoice created for this account.  Ignored for subaccounts.
     * @servertype Double (nullable)
     * @type {number}
     */
    LastInvoiceAmount: number = 0;

    /**
     * @name LastPaymentMethod
     * @description 
     * Payment method for the last payment entered for this account.  Ignored for subaccounts.
     * @servertype String
     * @type {string}
     */
    LastPaymentMethod: string = "";

    /**
     * @name LastPaymentNumber
     * @description 
     * Payment number for the last payment entered for this account.  Ignored for subaccounts.
     * @servertype String
     * @type {string}
     */
    LastPaymentNumber: string = "";

    /**
     * @name LastPaymentDate
     * @description 
     * Date when the last payment was entered for this account.  Ignored for subaccounts.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastPaymentDate: Date = null;

    /**
     * @name LastPaymentAmount
     * @description 
     * Amount of the last payment entered for this account.  Ignored for subaccounts.
     * @servertype Double (nullable)
     * @type {number}
     */
    LastPaymentAmount: number = 0;

    /**
     * @name LastAdjustmentNumber
     * @description 
     * Adjustment number for the last adjustment created for this account.  Ignored for subaccounts.
     * @servertype String
     * @type {string}
     */
    LastAdjustmentNumber: string = "";

    /**
     * @name LastAdjustmentDate
     * @description 
     * Date when the last adjustment was created for this account.  Ignored for subaccounts.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAdjustmentDate: Date = null;

    /**
     * @name LastAdjustmentAmount
     * @description 
     * Amount of the last adjustment entered for this account.  Ignored for subaccounts.
     * @servertype Double (nullable)
     * @type {number}
     */
    LastAdjustmentAmount: number = 0;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name BillingAccountEditViewModel
 */
export class BillingAccountEditViewModel {

    /**
     * @name BillingAccountId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = 0;

    /**
     * @name ParentBillingAccountId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentBillingAccountId: number = 0;

    /**
     * @name ContactId
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name BillingAccountType
     * @servertype String
     * @type {string}
     */
    BillingAccountType: string = "";

    /**
     * @name BillingProfileId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingProfileId: number = 0;

    /**
     * @name AccountNumber
     * @servertype String
     * @type {string}
     */
    AccountNumber: string = "";

    /**
     * @name ExternalAccountNumber
     * @servertype String
     * @type {string}
     */
    ExternalAccountNumber: string = "";

    /**
     * @name AccountName
     * @servertype String
     * @type {string}
     */
    AccountName: string = "";

    /**
     * @name AccountType
     * @servertype String
     * @type {string}
     */
    AccountType: string = "";

    /**
     * @name AccountClass
     * @servertype String
     * @type {string}
     */
    AccountClass: string = "";

    /**
     * @name AccountGroup
     * @servertype String
     * @type {string}
     */
    AccountGroup: string = "";

    /**
     * @name MarketType
     * @servertype String
     * @type {string}
     */
    MarketType: string = "";

    /**
     * @name ServiceType
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name AgentContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentContactId: number = null;

    /**
     * @name AgentContactName
     * @servertype String
     * @type {string}
     */
    AgentContactName: string = "";

    /**
     * @name AgentPlanId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentPlanId: number = 0;

    /**
     * @name TelecomLocationProfileId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TelecomLocationProfileId: number = 0;

    /**
     * @name TelecomHomeCountryCode
     * @servertype String
     * @type {string}
     */
    TelecomHomeCountryCode: string = "";

    /**
     * @name TelecomHomeNpa
     * @servertype String
     * @type {string}
     */
    TelecomHomeNpa: string = "";

    /**
     * @name TelecomHomeNxx
     * @servertype String
     * @type {string}
     */
    TelecomHomeNxx: string = "";

    /**
     * @name TelecomHomeLata
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TelecomHomeLata: number = 0;

    /**
     * @name TelecomHomeState
     * @servertype String
     * @type {string}
     */
    TelecomHomeState: string = "";

    /**
     * @name Status
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name StatusLocked
     * @servertype Boolean
     * @type {boolean}
     */
    StatusLocked: boolean = false;

    /**
     * @name CreatedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CreatedDateTime: Date = null;

    /**
     * @name SuspendedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedDateTime: Date = null;

    /**
     * @name ClosedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ClosedDateTime: Date = null;

    /**
     * @name ReactivatedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReactivatedDateTime: Date = null;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Deposit
     * @servertype Double (nullable)
     * @type {number}
     */
    Deposit: number = 0;

    /**
     * @name DepositBillingTransactionId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DepositBillingTransactionId: number = 0;

    /**
     * @name Balance
     * @servertype Double (nullable)
     * @type {number}
     */
    Balance: number = 0;

    /**
     * @name PendingInvoiceAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    PendingInvoiceAmount: number = 0;

    /**
     * @name AutoPay
     * @servertype Boolean
     * @type {boolean}
     */
    AutoPay: boolean = false;

    /**
     * @name AutoPayMinimumThreshold
     * @servertype Double (nullable)
     * @type {number}
     */
    AutoPayMinimumThreshold: number = 0;

    /**
     * @name AutoPayMaximumThreshold
     * @servertype Double (nullable)
     * @type {number}
     */
    AutoPayMaximumThreshold: number = 0;

    /**
     * @name AutoPayRechargeAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    AutoPayRechargeAmount: number = 0;

    /**
     * @name AlarmCreditLimitThreshold
     * @servertype Double (nullable)
     * @type {number}
     */
    AlarmCreditLimitThreshold: number = 0;

    /**
     * @name AlarmBalanceThreshold
     * @servertype Double (nullable)
     * @type {number}
     */
    AlarmBalanceThreshold: number = 0;

    /**
     * @name TermsDescription
     * @servertype String
     * @type {string}
     */
    TermsDescription: string = "";

    /**
     * @name TermsNetDays
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TermsNetDays: number = 0;

    /**
     * @name TermsDiscountPercent
     * @servertype Double (nullable)
     * @type {number}
     */
    TermsDiscountPercent: number = 0;

    /**
     * @name TermsDiscountDays
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TermsDiscountDays: number = 0;

    /**
     * @name CreditLimit
     * @servertype Double (nullable)
     * @type {number}
     */
    CreditLimit: number = 0;

    /**
     * @name CreditHold
     * @servertype Boolean
     * @type {boolean}
     */
    CreditHold: boolean = false;

    /**
     * @name BillingCycle
     * @servertype String
     * @type {string}
     */
    BillingCycle: string = "";

    /**
     * @name BillingCycleInterval
     * @servertype Int32 (nullable)
     * @type {number}
     */
    BillingCycleInterval: number = 0;

    /**
     * @name LagDays
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LagDays: number = 0;

    /**
     * @name MaximumPastBillingPeriodsToScan
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaximumPastBillingPeriodsToScan: number = 0;

    /**
     * @name MaximumBillingPeriodsPerInvoice
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaximumBillingPeriodsPerInvoice: number = 0;

    /**
     * @name MinimumInvoiceThreshold
     * @servertype Double (nullable)
     * @type {number}
     */
    MinimumInvoiceThreshold: number = 0;

    /**
     * @name AlwaysInvoiceFlag
     * @servertype String
     * @type {string}
     */
    AlwaysInvoiceFlag: string = "";

    /**
     * @name ZeroAmountInvoiceWhenBelowThreshold
     * @servertype Boolean
     * @type {boolean}
     */
    ZeroAmountInvoiceWhenBelowThreshold: boolean = false;

    /**
     * @name PrintZeroAmountInvoice
     * @servertype String
     * @type {string}
     */
    PrintZeroAmountInvoice: string = "";

    /**
     * @name IncludeEmptyLineItems
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeEmptyLineItems: boolean = false;

    /**
     * @name IncludeIncomingActivity
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeIncomingActivity: boolean = false;

    /**
     * @name IncludeInternalActivity
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeInternalActivity: boolean = false;

    /**
     * @name IncludeUncompletedActivity
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeUncompletedActivity: boolean = false;

    /**
     * @name IncludeFreeActivity
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeFreeActivity: boolean = false;

    /**
     * @name IncludeZeroCostActivity
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeZeroCostActivity: boolean = false;

    /**
     * @name Geocode
     * @servertype String
     * @type {string}
     */
    Geocode: string = "";

    /**
     * @name InOutCity
     * @servertype String
     * @type {string}
     */
    InOutCity: string = "";

    /**
     * @name InOutLocal
     * @servertype String
     * @type {string}
     */
    InOutLocal: string = "";

    /**
     * @name Taxable
     * @servertype String
     * @type {string}
     */
    Taxable: string = "";

    /**
     * @name TaxCustomerType
     * @servertype String
     * @type {string}
     */
    TaxCustomerType: string = "";

    /**
     * @name TaxServiceProviderType
     * @servertype String
     * @type {string}
     */
    TaxServiceProviderType: string = "";

    /**
     * @name BillingReportProfileId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingReportProfileId: number = 0;

    /**
     * @name AddressType
     * @servertype String
     * @type {string}
     */
    AddressType: string = "";

    /**
     * @name LastInvoiceNumber
     * @servertype String
     * @type {string}
     */
    LastInvoiceNumber: string = "";

    /**
     * @name LastInvoiceDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastInvoiceDate: Date = null;

    /**
     * @name LastInvoiceAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    LastInvoiceAmount: number = 0;

    /**
     * @name LastPaymentMethod
     * @servertype String
     * @type {string}
     */
    LastPaymentMethod: string = "";

    /**
     * @name LastPaymentNumber
     * @servertype String
     * @type {string}
     */
    LastPaymentNumber: string = "";

    /**
     * @name LastPaymentDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastPaymentDate: Date = null;

    /**
     * @name LastPaymentAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    LastPaymentAmount: number = 0;

    /**
     * @name LastAdjustmentNumber
     * @servertype String
     * @type {string}
     */
    LastAdjustmentNumber: string = "";

    /**
     * @name LastAdjustmentDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAdjustmentDate: Date = null;

    /**
     * @name LastAdjustmentAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    LastAdjustmentAmount: number = 0;

    /**
     * @name Flags
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name BillingEventEditViewModel
 * @description
 * The billing event table contains events and items that have been paid or need to be invoiced.  For events and items linked
 * to a payment transaction the PaymentTransactionId property will be populated.  Items that need to be invoiced will have the
 * BillingTransactionId property populated once these items are invoiced.
 */
export class BillingEventEditViewModel {

    /**
     * @name BillingEventId
     * @description 
     * Billing Event Id uniquely identifies this billing event.
     * @servertype String
     * @type {string}
     */
    BillingEventId: string = null;

    /**
     * @name ExternalBillingEventId
     * @description 
     * An external billing event id.  If populated this is expected to be unique and can be used to prevent duplicate billing events
     * from being posted for the same calendar month.
     * @servertype String
     * @type {string}
     */
    ExternalBillingEventId: string = "";

    /**
     * @name BillingEventType
     * @description 
     * The type for this billing event.  Possible values include: 
     * B = Billing Event For Invoice
     * P = Payment Transaction Item Detail
     * O = Other
     * @servertype String
     * @type {string}
     */
    BillingEventType: string = "B";

    /**
     * @name ContactId
     * @description 
     * The Contact Id for this contact this billing event belongs to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id that this billing event belongs to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name PackageOccurrenceId
     * @description 
     * Package Occurrence Id associated with this billing event.  If there is no package occurrence this can be zero or a negative
     * random value which will be used to group billing event items that are grouped together.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = null;

    /**
     * @name PaymentTransactionId
     * @description 
     * When the billing event represents a payment transaction item detail this is the payment transaction id the detail belongs
     * to.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionId: string = null;

    /**
     * @name InventoryId
     * @description 
     * This inventory id provides identification for the inventory item triggering this billing event.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryId: number = null;

    /**
     * @name InventoryHistoryId
     * @description 
     * This inventory history id provides unique identification for the inventory history triggering this billing event.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryHistoryId: number = null;

    /**
     * @name CaseId
     * @description 
     * The Case Id identifies the case triggering this billing event.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseId: number = null;

    /**
     * @name RegardingResourceType
     * @description 
     * Other resource type (e.g. table, etc.) this billing event is regarding (if any).
     * @servertype String
     * @type {string}
     */
    RegardingResourceType: string = "";

    /**
     * @name RegardingResourceId
     * @description 
     * The resource id is the key of the other resource type this billing event is regarding (if any).  For example, if the regarding
     * resource type is InventoryType this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RegardingResourceId: number = 0;

    /**
     * @name RegardingResourceId2
     * @description 
     * The resource id 2 is the key of the other resource type this billing event is regarding (if any) when that id is a string
     * data type.
     * @servertype String
     * @type {string}
     */
    RegardingResourceId2: string = "";

    /**
     * @name ItemDateTime
     * @description 
     * The item date and time represents when the item should be billed.  Items dated after the ending date of an invoice will not
     * be included on that invoice.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ItemDateTime: Date = new Date();

    /**
     * @name RequestedItemType
     * @description 
     * If specified this is used along with the requested item id as an item alias pair to look up the item id.
     * @servertype String
     * @type {string}
     */
    RequestedItemType: string = "";

    /**
     * @name RequestedItemId
     * @description 
     * If specified this is used along with the requested item type as an item alias pair to look up the item id.
     * @servertype String
     * @type {string}
     */
    RequestedItemId: string = "";

    /**
     * @name ItemId
     * @description 
     * Item Id to use for billing this event.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ItemId: number = null;

    /**
     * @name RateId
     * @description 
     * The rate id is used to identify the rate to be used.
     * @servertype String
     * @type {string}
     */
    RateId: string = "";

    /**
     * @name Description
     * @description 
     * Description for this item.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Quantity
     * @description 
     * Quantity of this item.
     * @servertype Double (nullable)
     * @type {number}
     */
    Quantity: number = 0;

    /**
     * @name RequestedIsoCurrencyCode
     * @description 
     * The requested ISO Currency Code for this item.
     * @servertype String
     * @type {string}
     */
    RequestedIsoCurrencyCode: string = "";

    /**
     * @name RequestedPriceEach
     * @description 
     * The requested price per unit for this item.
     * @servertype Double (nullable)
     * @type {number}
     */
    RequestedPriceEach: number = 0;

    /**
     * @name RequestedAmount
     * @description 
     * The requested amount for this item.  This is the Quantity * PriceEach.  This is stored in the table as opposed to calculated
     * because for some items it isn't appropriate to use a PriceEach.  For example, phone calls while they have an average price
     * would simply be billed by this Amount field.  Refer to the ShowAmountOnly column.
     * @servertype Double (nullable)
     * @type {number}
     */
    RequestedAmount: number = 0;

    /**
     * @name RequestedTaxAmount
     * @description 
     * The requested tax portion of amount if tax is included in the amount.  Often tax will be a separate billing event record
     * so this value may be 0.
     * @servertype Double (nullable)
     * @type {number}
     */
    RequestedTaxAmount: number = 0;

    /**
     * @name IsoCurrencyCode
     * @description 
     * ISO Currency Code for this item.  Note that in situations where RequestedIsoCurrencyCode is different than the contact's
     * IsoCurrencyCode the values in RequestedPriceEach and RequestedAmount will be converted to the contact's IsoCurrencyCode and
     * represented in the PriceEach and Amount columns.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name PriceEach
     * @description 
     * The price per unit for this item.
     * @servertype Double (nullable)
     * @type {number}
     */
    PriceEach: number = 0;

    /**
     * @name Amount
     * @description 
     * The amount for this item.  This is the Quantity * PriceEach.  This is stored in the table as opposed to calculated because
     * for some items it isn't appropriate to use a PriceEach.  For example, phone calls while they have an average price would
     * simply be billed by this Amount field.  Refer to the ShowAmountOnly field.
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name ShowAmountOnly
     * @description 
     * When true it is not appropriate for this item to display the PriceEach field because the Amount is a combination of various
     * items.
     * @servertype Boolean
     * @type {boolean}
     */
    ShowAmountOnly: boolean = false;

    /**
     * @name ExpenseAmount
     * @description 
     * If the item represents an expense this is the amount of that expense expressed in the requested currency.
     * @servertype Double (nullable)
     * @type {number}
     */
    ExpenseAmount: number = 0;

    /**
     * @name TaxAmount
     * @description 
     * Tax portion of amount if tax is included in the amount.  Often tax will be a separate billing event record so this value
     * may be 0.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxAmount: number = 0;

    /**
     * @name RefundedAmount
     * @description 
     * Amount of item that has been refunded.  This is used when the billing event is payment transaction item detail so refunds
     * can be tied to specific items.  For other billing event types this value is always 0.
     * @servertype Double (nullable)
     * @type {number}
     */
    RefundedAmount: number = 0;

    /**
     * @name BillingTransactionId
     * @description 
     * Billing Transaction Id this item is invoiced on, excluded with, etc.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingTransactionId: number = null;

    /**
     * @name BillingTransactionDetailId
     * @description 
     * Billing Transaction Detail Id this item is invoiced on, excluded with, etc.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingTransactionDetailId: number = null;

    /**
     * @name ExceptionBillingTransactionId
     * @description 
     * Exception Billing Transaction Id this item was waived with.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ExceptionBillingTransactionId: number = null;

    /**
     * @name BillingResultCode
     * @description 
     * The billing result code indicates the result of the billing process.  Possible values include: 
     * <0 = Hold - the event is not ready for billing (i.e. problem posting, etc.).
     * 0 = Pending - the event is ready for billing.
     * 1000-1999 = Success Codes
     * 2000-2999 = Warning Codes
     * 3000-3999 = Error Codes
     * 4000-4999 = Excluded - the item has been excluded from the billing process by an adjustment.  The specific value provides
     * more information.
     * 5000-5999 = Waived - the item was billed but subsequently waived by an adjustment.  The specific value provides more information.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    BillingResultCode: number = 0;

    /**
     * @name BillingResultMessage
     * @description 
     * A billing result message for the outcome of the billing process.
     * @servertype String
     * @type {string}
     */
    BillingResultMessage: string = "";

    /**
     * @name IncludeTrace
     * @description 
     * True indicates trace information should be logged.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeTrace: boolean = false;

    /**
     * @name Trace
     * @servertype String
     * @type {string}
     */
    Trace: string = "";

    /**
     * @name Flags
     * @description 
     * A list of flags for this billing event.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description 
     * Custom properties for this billing event beyond the user-defined values.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name BillingProfileListViewModel
 * @description
 * The billing profile table contains information about billing profiles.  A billing profile is a collection of default settings
 * to be applied to a billing account.  The settings actually used are those stored in the billing account not the billing profile.
 */
export class BillingProfileListViewModel {

    /**
     * @name BillingProfileId
     * @description 
     * Billing Profile Id uniquely identifies this billing profile.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingProfileId: number = null;

    /**
     * @name ExternalBillingProfileId
     * @description 
     * Billing profile used in external systems or as an alternate means of identification.
     * @servertype String
     * @type {string}
     */
    ExternalBillingProfileId: string = "";

    /**
     * @name Description
     * @description 
     * Billing profile description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the billing profile is enabled.  Setting to false provides an easy way to cause a billing profile to be temporarily
     * unavailable until later enabled again.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this billing profile is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this billing profile is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name DefaultProfile
     * @description 
     * When true the billing profile is the default profile for the stated visibility.  If more than one billing profile with the
     * same visibility is configured as a default there is no guarantee which will be selected.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultProfile: boolean = true;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name BillingProfileEditViewModel
 */
export class BillingProfileEditViewModel {

    /**
     * @name BillingProfileId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingProfileId: number = 0;

    /**
     * @name ExternalBillingProfileId
     * @servertype String
     * @type {string}
     */
    ExternalBillingProfileId: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ValidStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name VisibilityCostCenterType
     * @servertype string array
     * @type {string[]}
     */
    VisibilityCostCenterType: string[] = [];

    /**
     * @name VisibilityAccountType
     * @servertype String
     * @type {string}
     */
    VisibilityAccountType: string = "";

    /**
     * @name VisibilityAccountClass
     * @servertype String
     * @type {string}
     */
    VisibilityAccountClass: string = "";

    /**
     * @name VisibilityMarketType
     * @servertype String
     * @type {string}
     */
    VisibilityMarketType: string = "";

    /**
     * @name VisibilityContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VisibilityContactId: number = null;

    /**
     * @name VisibilityContactName
     * @servertype String
     * @type {string}
     */
    VisibilityContactName: string = "";

    /**
     * @name DefaultProfile
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultProfile: boolean = false;

    /**
     * @name AccountType
     * @servertype String
     * @type {string}
     */
    AccountType: string = "";

    /**
     * @name AccountClass
     * @servertype String
     * @type {string}
     */
    AccountClass: string = "";

    /**
     * @name AccountGroup
     * @servertype String
     * @type {string}
     */
    AccountGroup: string = "";

    /**
     * @name MarketType
     * @servertype String
     * @type {string}
     */
    MarketType: string = "";

    /**
     * @name ServiceType
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name AgentContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentContactId: number = null;

    /**
     * @name AgentContactName
     * @servertype String
     * @type {string}
     */
    AgentContactName: string = "";

    /**
     * @name AgentPlanId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentPlanId: number = 0;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name AutoPay
     * @servertype Boolean
     * @type {boolean}
     */
    AutoPay: boolean = false;

    /**
     * @name AutoPayMinimumThreshold
     * @servertype Double (nullable)
     * @type {number}
     */
    AutoPayMinimumThreshold: number = 0;

    /**
     * @name AutoPayMaximumThreshold
     * @servertype Double (nullable)
     * @type {number}
     */
    AutoPayMaximumThreshold: number = 0;

    /**
     * @name AutoPayRechargeAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    AutoPayRechargeAmount: number = 0;

    /**
     * @name AlarmCreditLimitThreshold
     * @servertype Double (nullable)
     * @type {number}
     */
    AlarmCreditLimitThreshold: number = 0;

    /**
     * @name AlarmBalanceThreshold
     * @servertype Double (nullable)
     * @type {number}
     */
    AlarmBalanceThreshold: number = 0;

    /**
     * @name TermsDescription
     * @servertype String
     * @type {string}
     */
    TermsDescription: string = "";

    /**
     * @name TermsNetDays
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TermsNetDays: number = 0;

    /**
     * @name TermsDiscountPercent
     * @servertype Double (nullable)
     * @type {number}
     */
    TermsDiscountPercent: number = 0;

    /**
     * @name TermsDiscountDays
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TermsDiscountDays: number = 0;

    /**
     * @name CreditLimit
     * @servertype Double (nullable)
     * @type {number}
     */
    CreditLimit: number = 0;

    /**
     * @name CreditHold
     * @servertype Boolean
     * @type {boolean}
     */
    CreditHold: boolean = false;

    /**
     * @name BillingCycle
     * @servertype String
     * @type {string}
     */
    BillingCycle: string = "";

    /**
     * @name BillingCycleInterval
     * @servertype Int32 (nullable)
     * @type {number}
     */
    BillingCycleInterval: number = 0;

    /**
     * @name LagDays
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LagDays: number = 0;

    /**
     * @name MaximumPastBillingPeriodsToScan
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaximumPastBillingPeriodsToScan: number = 0;

    /**
     * @name MaximumBillingPeriodsPerInvoice
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaximumBillingPeriodsPerInvoice: number = 0;

    /**
     * @name MinimumInvoiceThreshold
     * @servertype Double (nullable)
     * @type {number}
     */
    MinimumInvoiceThreshold: number = 0;

    /**
     * @name AlwaysInvoiceFlag
     * @servertype String
     * @type {string}
     */
    AlwaysInvoiceFlag: string = "";

    /**
     * @name ZeroAmountInvoiceWhenBelowThreshold
     * @servertype Boolean
     * @type {boolean}
     */
    ZeroAmountInvoiceWhenBelowThreshold: boolean = false;

    /**
     * @name PrintZeroAmountInvoice
     * @servertype String
     * @type {string}
     */
    PrintZeroAmountInvoice: string = "";

    /**
     * @name IncludeEmptyLineItems
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeEmptyLineItems: boolean = false;

    /**
     * @name IncludeIncomingActivity
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeIncomingActivity: boolean = false;

    /**
     * @name IncludeInternalActivity
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeInternalActivity: boolean = false;

    /**
     * @name IncludeUncompletedActivity
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeUncompletedActivity: boolean = false;

    /**
     * @name IncludeFreeActivity
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeFreeActivity: boolean = false;

    /**
     * @name IncludeZeroCostActivity
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeZeroCostActivity: boolean = false;

    /**
     * @name Taxable
     * @servertype String
     * @type {string}
     */
    Taxable: string = "";

    /**
     * @name TaxCustomerType
     * @servertype String
     * @type {string}
     */
    TaxCustomerType: string = "";

    /**
     * @name TaxServiceProviderType
     * @servertype String
     * @type {string}
     */
    TaxServiceProviderType: string = "";

    /**
     * @name BillingReportProfileId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingReportProfileId: number = 0;

    /**
     * @name AddressType
     * @servertype String
     * @type {string}
     */
    AddressType: string = "";

    /**
     * @name Flags
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name BillingReportEditViewModel
 */
export class BillingReportEditViewModel {

    /**
     * @name BillingReportId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingReportId: number = 0;

    /**
     * @name BillingReportProfileId
     * @servertype Int64
     * @type {number}
     */
    BillingReportProfileId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name PrintBatch
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PrintBatch: number = 0;

    /**
     * @name SequenceNumber
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SequenceNumber: number = 0;

    /**
     * @name QueryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    QueryId: number = 0;

    /**
     * @name FilterId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FilterId: number = 0;

    /**
     * @name ReportId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportId: number = 0;

    /**
     * @name SystemReportId
     * @servertype String
     * @type {string}
     */
    SystemReportId: string = "";

    /**
     * @name ReportTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportTemplateId: number = 0;

    /**
     * @name SystemReportTemplateId
     * @servertype String
     * @type {string}
     */
    SystemReportTemplateId: string = "";

    /**
     * @name ReportInstructions
     * @servertype String
     * @type {string}
     */
    ReportInstructions: string = "";

    /**
     * @name ReferenceCopy
     * @servertype Boolean
     * @type {boolean}
     */
    ReferenceCopy: boolean = false;

    /**
     * @name Printed
     * @servertype Boolean
     * @type {boolean}
     */
    Printed: boolean = false;

    /**
     * @name PrintServer
     * @servertype String
     * @type {string}
     */
    PrintServer: string = "";

    /**
     * @name Printer
     * @servertype String
     * @type {string}
     */
    Printer: string = "";

    /**
     * @name Export
     * @servertype Boolean
     * @type {boolean}
     */
    Export: boolean = false;

    /**
     * @name Compress
     * @servertype Boolean
     * @type {boolean}
     */
    Compress: boolean = false;

    /**
     * @name FileFormat
     * @servertype String
     * @type {string}
     */
    FileFormat: string = "";

    /**
     * @name FileName
     * @servertype String
     * @type {string}
     */
    FileName: string = "";

    /**
     * @name ExportToDocumentsFolder
     * @servertype Boolean
     * @type {boolean}
     */
    ExportToDocumentsFolder: boolean = false;

    /**
     * @name ExportToFolderName
     * @servertype String
     * @type {string}
     */
    ExportToFolderName: string = "";

    /**
     * @name NotificationGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = 0;

    /**
     * @name AttachToNotification
     * @servertype Boolean
     * @type {boolean}
     */
    AttachToNotification: boolean = false;

    /**
     * @name DeleteFileAfterNotification
     * @servertype Boolean
     * @type {boolean}
     */
    DeleteFileAfterNotification: boolean = false;

    /**
     * @name Flags
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name BillingReportProfileListViewModel
 * @description
 * The billing report profile table contains information about billing report profiles.  A billing report profile is a collection
 * of report settings that dictate how billing transaction documents (e.g. invoices) are created.
 */
export class BillingReportProfileListViewModel {

    /**
     * @name BillingReportProfileId
     * @description 
     * Billing Report Profile Id uniquely identifies this billing report profile.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingReportProfileId: number = null;

    /**
     * @name Description
     * @description 
     * Billing report profile description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the billing report profile is enabled.  Setting to false provides an easy way to cause a billing report profile
     * to be temporarily unavailable until later enabled again.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this billing report profile is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this billing report profile is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name DefaultProfile
     * @description 
     * When true the billing report profile is the default profile for the stated visibility.  If more than one billing report profile
     * with the same visibility is configured as a default there is no guarantee which will be selected.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultProfile: boolean = true;

    /**
     * @name InvoiceLanguage
     * @description 
     * Language to use for printed invoices.  Stored in format lg or lg-lo where lg is the 2 byte iso language identifier and lo
     * is the 2 byte iso locale identifier.  For example, English may be stored as 'en' or 'en-US' or 'en-UK'.
     * @servertype String
     * @type {string}
     */
    InvoiceLanguage: string = "";

    /**
     * @name RemitToName
     * @description 
     * Remit to name for invoices.
     * @servertype String
     * @type {string}
     */
    RemitToName: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name BillingReportProfileEditViewModel
 */
export class BillingReportProfileEditViewModel {

    /**
     * @name BillingReportProfileId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingReportProfileId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ValidStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name VisibilityCostCenterType
     * @servertype string array
     * @type {string[]}
     */
    VisibilityCostCenterType: string[] = [];

    /**
     * @name VisibilityAccountType
     * @servertype String
     * @type {string}
     */
    VisibilityAccountType: string = "";

    /**
     * @name VisibilityAccountClass
     * @servertype String
     * @type {string}
     */
    VisibilityAccountClass: string = "";

    /**
     * @name VisibilityMarketType
     * @servertype String
     * @type {string}
     */
    VisibilityMarketType: string = "";

    /**
     * @name VisibilityContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VisibilityContactId: number = null;

    /**
     * @name VisibilityContactName
     * @servertype String
     * @type {string}
     */
    VisibilityContactName: string = "";

    /**
     * @name DefaultProfile
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultProfile: boolean = false;

    /**
     * @name InvoiceLanguage
     * @servertype String
     * @type {string}
     */
    InvoiceLanguage: string = "";

    /**
     * @name PrimaryInvoiceMessageAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PrimaryInvoiceMessageAssetId: number = 0;

    /**
     * @name SecondaryInvoiceMessageAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SecondaryInvoiceMessageAssetId: number = 0;

    /**
     * @name DefaultAddressType
     * @servertype String
     * @type {string}
     */
    DefaultAddressType: string = "";

    /**
     * @name BillToAddressBlock
     * @servertype String
     * @type {string}
     */
    BillToAddressBlock: string = "";

    /**
     * @name RemitToName
     * @servertype String
     * @type {string}
     */
    RemitToName: string = "";

    /**
     * @name RemitToAddressBlock
     * @servertype String
     * @type {string}
     */
    RemitToAddressBlock: string = "";

    /**
     * @name RemitToPostalCode
     * @servertype String
     * @type {string}
     */
    RemitToPostalCode: string = "";

    /**
     * @name RemitToLogoImage
     * @servertype String
     * @type {string}
     */
    RemitToLogoImage: string = "";

    /**
     * @name RemitToLogoAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RemitToLogoAssetId: number = 0;

    /**
     * @name RemitToMessageAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RemitToMessageAssetId: number = 0;

    /**
     * @name Flags
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name BillingTransactionBatchEditViewModel
 * @description
 * The BillingTransactionBatch table contains details about billing transaction batches.
 */
export class BillingTransactionBatchEditViewModel {

    /**
     * @name BillingTransactionBatchId
     * @description 
     * Billing Transaction Batch Id uniquely identifies this billing transaction batch.
     * @servertype Int64
     * @type {number}
     */
    BillingTransactionBatchId: number = null;

    /**
     * @name TransactionType
     * @description 
     * Transaction type represented by this record.  Possible values include: 
     * For Accounts Receivable:
     * F = Future Invoice (used for data aggregation in preparation for invoice creation)
     * R = Review Invoice (after review this can be committed as a pending invoice)
     * E = Pending Invoice (ready to be committed as invoice when batch is ready)
     * I = Invoice
     * P = Payment
     * A = Adjustment
     * W = Waiver
     * X = Exclusion
     * For Accounts Payable:
     * U = Future Bill (used for data aggregation in preparation for bill creation)
     * V = Review Bill (after review this can be committed as a pending bill)
     * G = Pending Bill (ready to be committed as bill when batch is ready)
     * B = Bill
     * Y = Payment
     * J = Adjustment
     * Z = Waiver
     * N = Exclusion
     * @servertype String
     * @type {string}
     */
    TransactionType: string = "";

    /**
     * @name BatchType
     * @description 
     * Some batches are created to handle activation invoices, cancellation invoices, billing cycle invoices, etc.  This value indicates
     * if any special batch type applies to this batch.  Possible values include: 
     * A = Activation Invoices
     * C = Cancellation Invoices
     * B = Billing Cycle Invoices
     * O = One-Time (i.e. non-recurring) Invoices
     * U = Unspecified.  See transaction type for more details on what this batch includes.
     * @servertype String
     * @type {string}
     */
    BatchType: string = "U";

    /**
     * @name TransactionDescription
     * @description 
     * Description to use for transactions of this batch.
     * @servertype String
     * @type {string}
     */
    TransactionDescription: string = "";

    /**
     * @name TransactionComments
     * @description 
     * Comments to use for transactions of this batch.
     * @servertype String
     * @type {string}
     */
    TransactionComments: string = "";

    /**
     * @name AsOfDateTime
     * @description 
     * The date to use for as-of date for billing transactions in the batch.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AsOfDateTime: Date = null;

    /**
     * @name BillingPeriodBeginningDateTime
     * @description 
     * Combined with BillingPeriodEndingDateTime this represents the date range for this batch.  For activation or cancellation
     * invoices (see batch type) this is typically a calendar month.  For billing cycle invoices (see batch type and billing cycle)
     * this represents an approximation of the billing cycle for invoices that are part of a batch.  Note that for a variety of
     * reasons there may be billing cycle transactions that are part of this batch but have a different date range (e.g. services
     * started part way through the billing cycle, low activity charges resulted in delayed billing, etc.).
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BillingPeriodBeginningDateTime: Date = null;

    /**
     * @name BillingPeriodEndingDateTime
     * @description 
     * Combined with BillingPeriodBeginningDateTime this represents the date range for this batch.  For activation or cancellation
     * invoices (see batch type) this is typically a calendar month.  For billing cycle invoices (see batch type and billing cycle)
     * this represents an approximation of the billing cycle for invoices that are part of a batch.  Note that for a variety of
     * reasons there may be billing cycle transactions that are part of this batch but have a different date range (e.g. services
     * started part way through the billing cycle, low activity charges resulted in delayed billing, etc.).
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BillingPeriodEndingDateTime: Date = null;

    /**
     * @name BillingCycle
     * @description 
     * The billing cycle of accounts to include in batch.  If not specified, accounts with any billing cycle are included.
     * @servertype String
     * @type {string}
     */
    BillingCycle: string = "";

    /**
     * @name AccountType
     * @description 
     * The account type of accounts to include in batch.  If not specified, accounts with any account type are included.
     * @servertype String
     * @type {string}
     */
    AccountType: string = "";

    /**
     * @name AccountClass
     * @description 
     * The account class of accounts to include in batch.  If not specified, accounts with any account class are included.
     * @servertype String
     * @type {string}
     */
    AccountClass: string = "";

    /**
     * @name MarketType
     * @description 
     * The market type of accounts to include in batch.  If not specified, accounts with any market type are included.
     * @servertype String
     * @type {string}
     */
    MarketType: string = "";

    /**
     * @name AgentContactId
     * @description 
     * The Contact Id of the agent of accounts to include in batch.  If not specified, accounts with any agent id are included.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentContactId: number = null;

    /**
     * @name AgentContactName
     * @servertype String
     * @type {string}
     */
    AgentContactName: string = "";

    /**
     * @name IsoCurrencyCode
     * @description 
     * The currency of accounts to include in batch.  If not specified, accounts with any currency are included.  If accounts with
     * differing currencies are included in a single batch the batch amount value is meaningless.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name ContactFilterId
     * @description 
     * The filter id of an optional filter that can be used to determine which contacts are included in the batch.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactFilterId: number = null;

    /**
     * @name BillingAccountFilterId
     * @description 
     * The filter id of an optional filter that can be used to determine which billing accounts are included in the batch.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountFilterId: number = null;

    /**
     * @name TransactionCount
     * @description 
     * Count of billing transactions.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TransactionCount: number = 0;

    /**
     * @name PendingCount
     * @description 
     * Count of billing transactions with pending status.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PendingCount: number = 0;

    /**
     * @name RunningCount
     * @description 
     * Count of billing transactions with running status.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RunningCount: number = 0;

    /**
     * @name SuccessCount
     * @description 
     * Count of billing transactions with success status.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SuccessCount: number = 0;

    /**
     * @name WarningCount
     * @description 
     * Count of billing transactions with warning status.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    WarningCount: number = 0;

    /**
     * @name ErrorCount
     * @description 
     * Count of billing transactions with error status.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ErrorCount: number = 0;

    /**
     * @name TraceOnlyCount
     * @description 
     * Count of billing transactions with trace-only status.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TraceOnlyCount: number = 0;

    /**
     * @name ReviewPendingCount
     * @description 
     * Count of billing transactions with a review pending.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ReviewPendingCount: number = 0;

    /**
     * @name ReviewCompletedCount
     * @description 
     * Count of billing transactions with review completed.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ReviewCompletedCount: number = 0;

    /**
     * @name BatchAmount
     * @description 
     * Total amount for this batch.
     * @servertype Double (nullable)
     * @type {number}
     */
    BatchAmount: number = 0;

    /**
     * @name ApprovedDateTime
     * @description 
     * The date and time when the batch was approved.  When approved the following actions are taken: 
     * For invoices the transaction type is changed from R (review invoice) to I (invoice) and transactions are posted to accounts.
     * For payments the payment is applied to invoices and adjustments with outstanding balance and transactions are posted to accounts.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ApprovedDateTime: Date = null;

    /**
     * @name ApprovedByContactId
     * @description 
     * The contact id that represents who approved this batch.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApprovedByContactId: number = null;

    /**
     * @name ApprovedByContactName
     * @servertype String
     * @type {string}
     */
    ApprovedByContactName: string = "";

    /**
     * @name ApprovalComments
     * @description 
     * Comments entered regarding the approval process.  This can be used to explain why something wasn't approved or to add notes
     * about the approval.
     * @servertype String
     * @type {string}
     */
    ApprovalComments: string = "";

    /**
     * @name IncludeTrace
     * @description 
     * True indicates trace information should be logged.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeTrace: boolean = false;

    /**
     * @name Status
     * @description 
     * Status of this billing transaction batch.  Possible values are: 
     * N = Not Ready
     * P = Pending
     * R = Running
     * S = Success
     * W = Success with Warnings
     * F = Failure
     * @servertype String
     * @type {string}
     */
    Status: string = "N";

    /**
     * @name ProcessingServer
     * @description 
     * The server processing this transaction.  The type of processing going on may depend on transaction type, exported, or printed
     * property values.
     * @servertype String
     * @type {string}
     */
    ProcessingServer: string = "";

    /**
     * @name ProcessingDateTime
     * @description 
     * Date and time when the server started processing this transaction.  This can be used to help determine if there are any transaction
     * process that failed.  In that scenario the processing server should be checked for logged messages.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessingDateTime: Date = null;

    /**
     * @name Flags
     * @description 
     * A list of flags for this billing transaction batch.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description 
     * Custom properties for this billing transaction batch beyond the user-defined values.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Trace
     * @description 
     * Trace information that can be used for logging errors, warnings, and/or debugging batch processes.
     * @servertype String
     * @type {string}
     */
    Trace: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name BillingTransactions
     * @servertype BillingTransactionEditViewModel array
     * @type {BillingTransactionEditViewModel[]}
     */
    BillingTransactions: BillingTransactionEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name BillingTransactionEditViewModel
 * @description
 * The BillingTransaction table contains accounts receivable and accounts payable transactions including invoices, adjustments
 * and payments.
 */
export class BillingTransactionEditViewModel {

    /**
     * @name BillingTransactionId
     * @description 
     * Billing Transaction Id uniquely identifies this billing transaction.
     * @servertype Int64
     * @type {number}
     */
    BillingTransactionId: number = null;

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id this transaction belongs to.
     * @servertype Int64
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name ContactId
     * @description 
     * The Contact Id of the contact this billing transaction belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name TransactionType
     * @description 
     * Transaction type represented by this record.  Possible values include: 
     * For Accounts Receivable:
     * F = Future Invoice (used for data aggregation in preparation for invoice creation)
     * R = Review Invoice (after review this can be committed as a pending invoice)
     * E = Pending Invoice (ready to be committed as invoice when batch is ready)
     * I = Invoice
     * P = Payment
     * A = Adjustment
     * W = Waiver
     * X = Exclusion
     * For Accounts Payable:
     * U = Future Bill (used for data aggregation in preparation for bill creation)
     * V = Review Bill (after review this can be committed as a pending bill)
     * G = Pending Bill (ready to be committed as bill when batch is ready)
     * B = Bill
     * Y = Payment
     * J = Adjustment
     * Z = Waiver
     * N = Exclusion
     * @servertype String
     * @type {string}
     */
    TransactionType: string = "";

    /**
     * @name TransactionCount
     * @description 
     * The transaction count for this billing account and transaction type (or subset).  For example, if the transaction is the
     * third payment from a customer this value would be 3.  Note that one time invoices are counted separately from billing cycle
     * invoices (billing cycle invoices have beginning and ending dates specified).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TransactionCount: number = 0;

    /**
     * @name TransactionDate
     * @description 
     * The transaction date is the date of the invoice, payment or adjustment.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TransactionDate: Date = null;

    /**
     * @name TransactionNumber
     * @description 
     * Transaction number for this transaction.  For invoices this is the invoice number, for payments this is the check number,
     * credit card authorization number, voucher code, etc. for adjustments this is an adjustment number or code.
     * @servertype String
     * @type {string}
     */
    TransactionNumber: string = "";

    /**
     * @name ExternalTransactionNumber
     * @description 
     * External transaction number is the number used to identify this transaction in external systems.
     * @servertype String
     * @type {string}
     */
    ExternalTransactionNumber: string = "";

    /**
     * @name TransactionMethod
     * @description 
     * For payments the transaction method specifies the method of payment (check, credit card, voucher, etc.).
     * @servertype String
     * @type {string}
     */
    TransactionMethod: string = "";

    /**
     * @name Description
     * @description 
     * Description of this transaction.  This description is printed on the invoice.  To provide description information that is
     * not printed (i.e. internal use only) use the comments property.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name IsoCurrencyCode
     * @description 
     * ISO Currency Code for this transaction.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Amount
     * @description 
     * Amount of invoice, payment or adjustment.
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name AmountPaid
     * @description 
     * Amount of invoice or adjustment that has been paid.  For payments this field represents the amount of the payment that has
     * been applied.  The difference between amount and amount represents the amount due for invoices and adjustments and the unapplied
     * payment amount for payments.
     * @servertype Double (nullable)
     * @type {number}
     */
    AmountPaid: number = 0;

    /**
     * @name Tax
     * @description 
     * Tax portion of amount when taxes are applicable.
     * @servertype Double (nullable)
     * @type {number}
     */
    Tax: number = 0;

    /**
     * @name TaxInformation
     * @description 
     * A csv of tax information for the transaction.  This could include things like: 
     * 1. Taxes that are precalculated and then submitted with a transaction may include a tax scenario id that can be used to recreate
     * the same tax details for the transaction.
     * 2. A Taxable true/false indicator.
     * 3. The id of the tax configuration that was used.
     * 4. A list of tax matrix identifiers (pipe delimited) from the taxation engine.
     * 5. A list of result codes (pipe delimited) from the taxation engine.
     * 6. A list of simple tax rates (pipe delimited) used for calculating taxes (more complex scenarios found only in tax matrix
     * and/or logs).
     * 7. A list of tax display requirement flags (pipe delimited) used for displaying taxes.
     * For example: TaxScenarioId:1q8-u72-7sl-y9ze,Taxable:true,Config:1,Matrices:2240|39134|10101|1599242,ResultCodes:101|282|283,Rates:0.07,DisplayRequirements:O|O|P|O
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    TaxInformation: {[key: string]:  string} = {};

    /**
     * @name BeginningDate
     * @description 
     * Beginning date of the billing period for this transaction.  This is null if the transaction is not a billing period invoice.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BeginningDate: Date = null;

    /**
     * @name EndingDate
     * @description 
     * Ending date of the billing period for this transaction.  This is null if the transaction is not a billing period invoice
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EndingDate: Date = null;

    /**
     * @name DueDate
     * @description 
     * The invoice due date.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DueDate: Date = null;

    /**
     * @name AddressType
     * @description 
     * The address type to use for billing this transaction.  Customers can have multiple contacts and addresses and this setting
     * dictates which setting is used as the billing address when available.  See contact address type for more information.
     * @servertype String
     * @type {string}
     */
    AddressType: string = "";

    /**
     * @name Address
     * @description 
     * Address used for this transaction.  See address type property for information on which address was used and the billing report
     * profile bill to address block property for information on how the address was formatted.  It is stored with each transaction
     * for audit history of where a specific invoice was delivered to.
     * @servertype String
     * @type {string}
     */
    Address: string = "";

    /**
     * @name AddressPostalCode
     * @description 
     * The address postal code is stored separately here for bar code printing, presorting outgoing mail, etc.
     * @servertype String
     * @type {string}
     */
    AddressPostalCode: string = "";

    /**
     * @name ActivityCount
     * @description 
     * For Invoices and Bills this column represents a count of activity detail rows included in this transaction.  This can be
     * used to make some intelligent invoice reporting decisions in terms of detail, summary, or no activity information.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ActivityCount: number = 0;

    /**
     * @name EarliestActivityDate
     * @description 
     * Date of the earliest activity included in this transaction.  This would normally match the beginning date property but if
     * there was activity from previous billing periods that was not billed and our billing profile dictates that we scan past billing
     * periods for unbilled activity then this date would represent that earliest date.  Since our activity records are stored by
     * calendar month this value helps us quickly find the different activities billed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EarliestActivityDate: Date = null;

    /**
     * @name ActivityIncluded
     * @description 
     * Information about the scope of activity data included in this transaction.
     * @servertype String
     * @type {string}
     */
    ActivityIncluded: string = "";

    /**
     * @name BillingTransactionBatchId
     * @description 
     * The billing transaction batch id identifies all transactions of a given batch.  This represents all transactions of a certain
     * type created during the same session (i.e. invoice creation session).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingTransactionBatchId: number = null;

    /**
     * @name LinkedBillingTransactionId
     * @description 
     * Billing Transaction Id of a linked billing transaction.  For example, if an adjustment is related to a specific invoice the
     * billing transaction id of the invoice being adjusted would be noted here.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LinkedBillingTransactionId: number = null;

    /**
     * @name PrintBatch
     * @description 
     * The print batch used when printing billing reports.  Only reports that belong to the print batch specified in the billing
     * report table will be printed.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PrintBatch: number = 0;

    /**
     * @name Status
     * @description 
     * Status of this billing transaction.  Possible values are: 
     * N = Not Ready
     * P = Pending
     * R = Running
     * S = Success
     * W = Success with Warnings
     * F = Failure
     * @servertype String
     * @type {string}
     */
    Status: string = "N";

    /**
     * @name ExportStatus
     * @description 
     * Status of export for this billing transaction.  Possible values are: 
     * N = Not Ready
     * P = Pending
     * R = Running
     * S = Success
     * W = Success with Warnings
     * F = Failure
     * @servertype String
     * @type {string}
     */
    ExportStatus: string = "N";

    /**
     * @name PrintStatus
     * @description 
     * Status of print for this billing transaction.  Possible values are: 
     * N = Not Ready
     * P = Pending
     * R = Running
     * S = Success
     * W = Success with Warnings
     * F = Failure
     * @servertype String
     * @type {string}
     */
    PrintStatus: string = "N";

    /**
     * @name ProcessingServer
     * @description 
     * The server processing this transaction.  The type of processing going on may depend on transaction type, exported, or printed
     * property values.
     * @servertype String
     * @type {string}
     */
    ProcessingServer: string = "";

    /**
     * @name ProcessingDateTime
     * @description 
     * Date and time when the server started processing this transaction.  This can be used to help determine if there are any transaction
     * process that failed.  In that scenario the processing server should be checked for logged messages.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessingDateTime: Date = null;

    /**
     * @name Flags
     * @description 
     * A list of flags for this billing transaction.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description 
     * Custom properties for this billing transaction beyond the user-defined values.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Trace
     * @description 
     * Trace information that can be used for logging errors, warnings, and/or debugging invoice creation processes.
     * @servertype String
     * @type {string}
     */
    Trace: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Details
     * @servertype BillingTransactionDetailEditViewModel array
     * @type {BillingTransactionDetailEditViewModel[]}
     */
    Details: BillingTransactionDetailEditViewModel[] = [];

    /**
     * @name CommissionDetails
     * @servertype BillingTransactionCommissionDetailEditViewModel array
     * @type {BillingTransactionCommissionDetailEditViewModel[]}
     */
    CommissionDetails: BillingTransactionCommissionDetailEditViewModel[] = [];

    /**
     * @name PaymentDetails
     * @servertype BillingTransactionPaymentDetailEditViewModel array
     * @type {BillingTransactionPaymentDetailEditViewModel[]}
     */
    PaymentDetails: BillingTransactionPaymentDetailEditViewModel[] = [];

    /**
     * @name Payments
     * @servertype BillingTransactionPaymentDetailEditViewModel array
     * @type {BillingTransactionPaymentDetailEditViewModel[]}
     */
    Payments: BillingTransactionPaymentDetailEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name BillingTransactionDetailEditViewModel
 * @description
 * The BillingTransactionDetail table contains details (line items) for the BillingTransaction table.
 */
export class BillingTransactionDetailEditViewModel {

    /**
     * @name BillingTransactionDetailId
     * @description 
     * Billing Transaction Detail Id uniquely identifies this billing transaction detail.
     * @servertype Int64
     * @type {number}
     */
    BillingTransactionDetailId: number = null;

    /**
     * @name BillingTransactionId
     * @description 
     * Billing Transaction Id this detail object belongs to.
     * @servertype Int64
     * @type {number}
     */
    BillingTransactionId: number = null;

    /**
     * @name PackageOccurrenceId
     * @description 
     * Package Occurrence Id this item belongs to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = null;

    /**
     * @name PackageOccurrenceItemId
     * @description 
     * Package Occurrence Item Id this item belongs to.  May be null if item taken from PackageItem table.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceItemId: number = null;

    /**
     * @name PackageItemId
     * @description 
     * Package Item Id this item belongs to.  May be null if taken from PackageOccurrenceItem table.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageItemId: number = null;

    /**
     * @name SequenceNumber
     * @description 
     * The sequence used when displaying all details for one transaction.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SequenceNumber: number = 0;

    /**
     * @name Quantity
     * @description 
     * Quantity of this line item.
     * @servertype Double (nullable)
     * @type {number}
     */
    Quantity: number = 0;

    /**
     * @name ItemId
     * @description 
     * Item Id of the item.
     * @servertype Int64
     * @type {number}
     */
    ItemId: number = null;

    /**
     * @name ItemRateId
     * @description 
     * Item Rate Id of the item rate.
     * @servertype Int64
     * @type {number}
     */
    ItemRateId: number = null;

    /**
     * @name Description
     * @description 
     * Description for this item.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name PriceEach
     * @description 
     * The price per unit for this item.
     * @servertype Double (nullable)
     * @type {number}
     */
    PriceEach: number = 0;

    /**
     * @name Amount
     * @description 
     * The amount for this detail item.  This is the Quantity x PriceEach.  This is stored in the table as opposed to calculated
     * because for some items it isn't appropriate to use a price each value.  For example, phone calls while they have an average
     * price would simply be billed by this Amount property.  See the show amount only property.
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name ShowAmountOnly
     * @description 
     * When true it is not appropriate for this item to display the price each property because the amount is a combination of various
     * items like phone calls.  This property is set to true when quantity is zero for this item.  Any quantities are taken from
     * activity data and the price each field is only an average price per quantity.
     * @servertype Boolean
     * @type {boolean}
     */
    ShowAmountOnly: boolean = false;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this record is valid.  Needed for separation of
     * activity data for tax calculations.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this record is valid.  Needed for separation of activity
     * data for tax calculations.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name AccountingBeginningDateTime
     * @description 
     * Accounting period beginning date and time.  Used in conjunction with AccountingEndingDateTime to determine accounting periods
     * for this transaction item.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AccountingBeginningDateTime: Date = null;

    /**
     * @name AccountingEndingDateTime
     * @description 
     * Accounting period ending date and time.  Used in conjunction with AccountingBeginningDateTime to determine accounting periods
     * for this transaction item.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AccountingEndingDateTime: Date = null;

    /**
     * @name BillingBeginningDateTime
     * @description 
     * Billing period beginning date and time.  Used in conjunction with BillingEndingDateTime to report billing periods for this
     * transaction item.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BillingBeginningDateTime: Date = null;

    /**
     * @name BillingEndingDateTime
     * @description 
     * Billing period ending date and time.  Used in conjunction with BillingBeginningDateTime to report billing periods for this
     * transaction item.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BillingEndingDateTime: Date = null;

    /**
     * @name Status
     * @description 
     * Status of this billing transaction detail.  Possible values are: 
     * N = Not Ready
     * P = Pending
     * R = Running
     * S = Success
     * W = Success with Warnings
     * F = Failure
     * T = Trace Data Only (not included in invoice but available here as part of trace process)
     * @servertype String
     * @type {string}
     */
    Status: string = "N";

    /**
     * @name Trace
     * @description 
     * Trace information that can be used for logging errors, warnings, and/or debugging invoice creation processes.
     * @servertype String
     * @type {string}
     */
    Trace: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name BillingTransactionCommissionDetailEditViewModel
 * @description
 * The BillingTransactionCommissionDetail table contains commission details for the BillingTransaction table.
 */
export class BillingTransactionCommissionDetailEditViewModel {

    /**
     * @name BillingTransactionCommissionDetailId
     * @description 
     * Billing Transaction Commission Detail Id uniquely identifies this billing transaction commission detail.
     * @servertype Int64
     * @type {number}
     */
    BillingTransactionCommissionDetailId: number = null;

    /**
     * @name BillingTransactionId
     * @description 
     * Billing Transaction Id this detail object belongs to.  For commission records that have been calculated but not yet assigned
     * to a transaction this value is null.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingTransactionId: number = null;

    /**
     * @name AgentContactId
     * @description 
     * The Contact Id of the agent.
     * @servertype Int64
     * @type {number}
     */
    AgentContactId: number = null;

    /**
     * @name AgentContactName
     * @servertype String
     * @type {string}
     */
    AgentContactName: string = "";

    /**
     * @name AgentPlanId
     * @description 
     * Agent Plan Id for this package occurrence.  Note that the value assigned to this field may be different than the value assigned
     * to the account and may have been dictated by the package agent plan id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentPlanId: number = null;

    /**
     * @name ActualAgentPlanId
     * @description 
     * Most frequently the same as agent plan id.  However, if the agent plan included inheriting of commission details from another
     * agent plan(s) this value represents the actual agent plan id used.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ActualAgentPlanId: number = null;

    /**
     * @name AgentPlanDetailId
     * @description 
     * Agent Plan Detail Id used for this commission.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentPlanDetailId: number = null;

    /**
     * @name AgentPlanMethod
     * @description 
     * Method for commissions for this agent plan.  Possible values include:
     * I = Invoice.  Send this agent an accounts receivable invoice for the agent to pay.
     * B = Bill.  Enter an accounts payable bill to be paid to this agent.
     * @servertype String
     * @type {string}
     */
    AgentPlanMethod: string = "";

    /**
     * @name BaseBillingTransactionId
     * @description 
     * The Billing Transaction Id of the invoice or adjustment transaction for this commission.
     * @servertype Int64
     * @type {number}
     */
    BaseBillingTransactionId: number = null;

    /**
     * @name BaseBillingTransactionDetailId
     * @description 
     * The Billing Transaction Detail Id of the invoice or adjustment transaction line item for this commission.
     * @servertype Int64
     * @type {number}
     */
    BaseBillingTransactionDetailId: number = null;

    /**
     * @name PaymentBillingTransactionId
     * @description 
     * The Billing Transaction Id of the payment that triggered this commission if the commission was triggered by a payment.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentBillingTransactionId: number = null;

    /**
     * @name PaymentPercent
     * @description 
     * The percent of invoice or adjustment paid by the payment that triggered this commission.  An invoice payment of 50% of the
     * invoice amount would have a value of .5 recorded here to indicate that commission should only be based on 50% of the invoice
     * amounts.  Future payments towards the invoice would trigger remaining commission amounts.
     * @servertype Double (nullable)
     * @type {number}
     */
    PaymentPercent: number = 0;

    /**
     * @name CommissionAmount
     * @description 
     * The amount of calculated commission.
     * @servertype Double (nullable)
     * @type {number}
     */
    CommissionAmount: number = 0;

    /**
     * @name AccountingBeginningDateTime
     * @description 
     * Accounting period beginning date and time.  Used in conjunction with AccountingEndingDateTime to determine accounting periods
     * for this transaction item.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AccountingBeginningDateTime: Date = null;

    /**
     * @name AccountingEndingDateTime
     * @description 
     * Accounting period ending date and time.  Used in conjunction with AccountingBeginningDateTime to determine accounting periods
     * for this transaction item.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AccountingEndingDateTime: Date = null;

    /**
     * @name BillingBeginningDateTime
     * @description 
     * Billing period beginning date and time.  Used in conjunction with BillingEndingDateTime to report billing periods for this
     * transaction item.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BillingBeginningDateTime: Date = null;

    /**
     * @name BillingEndingDateTime
     * @description 
     * Billing period ending date and time.  Used in conjunction with BillingBeginningDateTime to report billing periods for this
     * transaction item.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BillingEndingDateTime: Date = null;

    /**
     * @name Status
     * @description 
     * Status of this billing transaction detail.  Possible values are: 
     * N = Not Ready
     * P = Pending
     * R = Running
     * S = Success
     * W = Success with Warnings
     * F = Failure
     * T = Trace Data Only (not included in invoice but available here as part of trace process)
     * @servertype String
     * @type {string}
     */
    Status: string = "N";

    /**
     * @name Trace
     * @description 
     * Trace information that can be used for logging errors, warnings, and/or debugging invoice creation processes.
     * @servertype String
     * @type {string}
     */
    Trace: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name BillingTransactionPaymentDetailEditViewModel
 * @description
 * The BillingTransactionPaymentDetail table contains details indicating transactions that were paid as part of a specific payment.
 */
export class BillingTransactionPaymentDetailEditViewModel {

    /**
     * @name BillingTransactionPaymentDetailId
     * @description 
     * Billing Transaction Payment Detail Id uniquely identifies this billing transaction payment detail.
     * @servertype Int64
     * @type {number}
     */
    BillingTransactionPaymentDetailId: number = null;

    /**
     * @name BillingTransactionId
     * @description 
     * Billing Transaction Id of the payment transaction this payment detail object belongs to.
     * @servertype Int64
     * @type {number}
     */
    BillingTransactionId: number = null;

    /**
     * @name PaidBillingTransactionId
     * @description 
     * Billing Transaction Id of the invoice or adjustment transaction that was paid.
     * @servertype Int64
     * @type {number}
     */
    PaidBillingTransactionId: number = null;

    /**
     * @name Amount
     * @description 
     * The amount of the invoice or adjustment identified by the paid billing transaction id that is paid with this payment.
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name BillingTransactionListViewModel
 * @description
 * The BillingTransaction table contains accounts receivable and accounts payable transactions including invoices, adjustments
 * and payments.
 */
export class BillingTransactionListViewModel {

    /**
     * @name BillingTransactionId
     * @description 
     * Billing Transaction Id uniquely identifies this billing transaction.
     * @servertype Int64
     * @type {number}
     */
    BillingTransactionId: number = null;

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id this transaction belongs to.
     * @servertype Int64
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name ContactId
     * @description 
     * The Contact Id of the contact this billing transaction belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name TransactionType
     * @description 
     * Transaction type represented by this record.  Possible values include: 
     * For Accounts Receivable:
     * F = Future Invoice (used for data aggregation in preparation for invoice creation)
     * R = Review Invoice (after review this can be committed as a pending invoice)
     * E = Pending Invoice (ready to be committed as invoice when batch is ready)
     * I = Invoice
     * P = Payment
     * A = Adjustment
     * W = Waiver
     * X = Exclusion
     * For Accounts Payable:
     * U = Future Bill (used for data aggregation in preparation for bill creation)
     * V = Review Bill (after review this can be committed as a pending bill)
     * G = Pending Bill (ready to be committed as bill when batch is ready)
     * B = Bill
     * Y = Payment
     * J = Adjustment
     * Z = Waiver
     * N = Exclusion
     * @servertype String
     * @type {string}
     */
    TransactionType: string = "";

    /**
     * @name TransactionDate
     * @description 
     * The transaction date is the date of the invoice, payment or adjustment.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TransactionDate: Date = null;

    /**
     * @name TransactionNumber
     * @description 
     * Transaction number for this transaction.  For invoices this is the invoice number, for payments this is the check number,
     * credit card authorization number, voucher code, etc. for adjustments this is an adjustment number or code.
     * @servertype String
     * @type {string}
     */
    TransactionNumber: string = "";

    /**
     * @name ExternalTransactionNumber
     * @description 
     * External transaction number is the number used to identify this transaction in external systems.
     * @servertype String
     * @type {string}
     */
    ExternalTransactionNumber: string = "";

    /**
     * @name TransactionMethod
     * @description 
     * For payments the transaction method specifies the method of payment (check, credit card, voucher, etc.).
     * @servertype String
     * @type {string}
     */
    TransactionMethod: string = "";

    /**
     * @name Amount
     * @description 
     * Amount of invoice, payment or adjustment.
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name AmountPaid
     * @description 
     * Amount of invoice or adjustment that has been paid.  For payments this field represents the amount of the payment that has
     * been applied.  The difference between amount and amount represents the amount due for invoices and adjustments and the unapplied
     * payment amount for payments.
     * @servertype Double (nullable)
     * @type {number}
     */
    AmountPaid: number = 0;

    /**
     * @name BeginningDate
     * @description 
     * Beginning date of the billing period for this transaction.  This is null if the transaction is not a billing period invoice.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BeginningDate: Date = null;

    /**
     * @name EndingDate
     * @description 
     * Ending date of the billing period for this transaction.  This is null if the transaction is not a billing period invoice
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EndingDate: Date = null;

    /**
     * @name DueDate
     * @description 
     * The invoice due date.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DueDate: Date = null;

    /**
     * @name Status
     * @description 
     * Status of this billing transaction.  Possible values are: 
     * N = Not Ready
     * P = Pending
     * R = Running
     * S = Success
     * W = Success with Warnings
     * F = Failure
     * @servertype String
     * @type {string}
     */
    Status: string = "N";

    /**
     * @name ExportStatus
     * @description 
     * Status of export for this billing transaction.  Possible values are: 
     * N = Not Ready
     * P = Pending
     * R = Running
     * S = Success
     * W = Success with Warnings
     * F = Failure
     * @servertype String
     * @type {string}
     */
    ExportStatus: string = "N";

    /**
     * @name PrintStatus
     * @description 
     * Status of print for this billing transaction.  Possible values are: 
     * N = Not Ready
     * P = Pending
     * R = Running
     * S = Success
     * W = Success with Warnings
     * F = Failure
     * @servertype String
     * @type {string}
     */
    PrintStatus: string = "N";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name ItemListEditViewModel
 * @description
 * The ItemList table contains a list of values linked to a particular item in the item table.  The contents may vary depending
 * on the item calculation type.  For example, when doing a percent surcharge/discount for items those items are in this table.
 *  For tax items the tax types and categories are found in this table.
 */
export class ItemListEditViewModel {

    /**
     * @name ItemListId
     * @description 
     * Item List Id uniquely identifies this item list record.
     * @servertype Int64
     * @type {number}
     */
    ItemListId: number = null;

    /**
     * @name ItemId
     * @description 
     * Item Id that the items in this list belong to.
     * @servertype Int64
     * @type {number}
     */
    ItemId: number = null;

    /**
     * @name LinkedItemId
     * @description 
     * The item id of the item linked.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LinkedItemId: number = null;

    /**
     * @name LinkedItemNumber
     * @servertype String
     * @type {string}
     */
    LinkedItemNumber: string = "";

    /**
     * @name LinkedTaxType
     * @description 
     * The tax type linked to the item.  Any combination of tax type, category, and/or item code may be used to roll up taxes into
     * the item id owning this list.
     * @servertype String
     * @type {string}
     */
    LinkedTaxType: string = "";

    /**
     * @name LinkedTaxCategory
     * @description 
     * The tax category linked to the item.  Any combination of tax type, category, and/or item code may be used to roll up taxes
     * into the item id owning this list.
     * @servertype String
     * @type {string}
     */
    LinkedTaxCategory: string = "";

    /**
     * @name LinkedTaxItemCode
     * @description 
     * The tax item code linked to the item.  Any combination of tax type, category, and/or item code may be used to roll up taxes
     * into the item id owning this list.
     * @servertype String
     * @type {string}
     */
    LinkedTaxItemCode: string = "";

    /**
     * @name Flags
     * @description 
     * A list of flags for this item link.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description 
     * Custom properties for this item link beyond the user-defined values.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ItemRateEditViewModel
 * @description
 * The ItemRate table contains rate information for items in the Item table.
 */
export class ItemRateEditViewModel {

    /**
     * @name ItemRateId
     * @description 
     * Item Rate Id uniquely identifies this item rate record.
     * @servertype Int64
     * @type {number}
     */
    ItemRateId: number = null;

    /**
     * @name ItemId
     * @description 
     * Item Id.
     * @servertype Int64
     * @type {number}
     */
    ItemId: number = null;

    /**
     * @name RateId
     * @description 
     * The rate id is used to identify the rate to be used.  Different customers can have different rates for the same item based
     * on the rate id value selected for the package.
     * @servertype String
     * @type {string}
     */
    RateId: string = "";

    /**
     * @name GeneralLedgerCode
     * @description 
     * General ledger code for this item.
     * @servertype String
     * @type {string}
     */
    GeneralLedgerCode: string = "";

    /**
     * @name CommissionMaximumCheck
     * @description 
     * Flag that indicates if there is a maximum value check on commissions paid for this item.  Possible values include: 
     * N = No maximum commission check.
     * P = Maximum percent commission check.  Commission maximum value represents the maximum percentage (in decimal 10% = 0.1)
     * that can be paid in commissions for this item.
     * M = Maximum monetary commission check.  Commission maximum value represents the maximum monetary value (in currency) that
     * can be paid in commissions for this item.
     * @servertype String
     * @type {string}
     */
    CommissionMaximumCheck: string = "N";

    /**
     * @name CommissionMaximumValue
     * @description 
     * Maximum percent or money (see commission maximum check setting) to be paid in commissions for this item.  When commissions
     * for an item total more than this value they are all decreased by an equal factor so commissions cannot exceed this stated
     * value.  For example, if the maximum commission for an item is $10 but the commissions totaled $15 then each commission would
     * be multiplied by .667 ($10/$15) so the total commission would be in line with the maximum available for commissions.  This
     * provides a safeguard against incorrectly designed agent commission plans.
     * @servertype Double (nullable)
     * @type {number}
     */
    CommissionMaximumValue: number = 0;

    /**
     * @name BillingPeriodsWhenAutoPay
     * @description 
     * This integer represents the number of billing periods to include on an invoice when the billed party is flagged for automatic
     * payment.  Valid range is -1 to 24.  -1 and 0 represent billing for the past billing period.  Positive values (1-24) represent
     * the number of billing periods in the future (based on invoice date) to include on the invoice.  See BillingPeriodsWhenNotAutoPay
     * for the corresponding value used when the billed party is not flagged for automatic payment.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    BillingPeriodsWhenAutoPay: number = 0;

    /**
     * @name BillingPeriodsWhenNotAutoPay
     * @description 
     * This integer represents the number of billing periods to include on an invoice when the billed party is not flagged for automatic
     * payment.  Valid range is -1 to 24.  -1 and 0 represent billing for the past billing period.  Positive values (1-24) represent
     * the number of billing periods in the future (based on invoice date) to include on the invoice.  See BillingPeriodsWhenAutoPay
     * for the corresponding value used when the billed party is flagged for automatic payment.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    BillingPeriodsWhenNotAutoPay: number = 0;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this rate record is valid.  Rate records can have
     * a period of time for which they are valid thus allowing rate changes while leaving existing rates in place for historical
     * rating.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this rate record is valid.  Rate records can have a
     * period of time for which they are valid thus allowing rate changes while leaving existing rates in place for historical rating.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name IsoCurrencyCode
     * @description 
     * ISO Currency Code for currency used by this rate.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name RateCalculationFlag
     * @description 
     * Flag that indicates how the rate is calculated in relation to the quantity.  Possible values include: 
     * S = Step (default).  The rate at each step applies to the quantity specified in that step (i.e. RateStep1Rate applies for
     * RateStep1Quantity up to RateStep1MaximumQuantity).
     * T = Total.  The rate at the step matching the quantity applies to the full quantity (i.e. RateStep1Rate applies when the
     * quantity is between RateStep1Quantity and RateStep1MaximumQuantity).
     * @servertype String
     * @type {string}
     */
    RateCalculationFlag: string = "S";

    /**
     * @name RateStep1Quantity
     * @description 
     * Quantity for the first rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep1Quantity: number = 0;

    /**
     * @name RateStep1Rate
     * @description 
     * Rate for the first rate step.  Note that this is the rate per RateStep1Quantity not the rate per single quantity unit.  This
     * allows rating based on groups of quantities.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep1Rate: number = 0;

    /**
     * @name RateStep1MaximumQuantity
     * @description 
     * The maximum number to be included in rate step one.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep1MaximumQuantity: number = 0;

    /**
     * @name RateStep2Quantity
     * @description 
     * Quantity for the second rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep2Quantity: number = 0;

    /**
     * @name RateStep2Rate
     * @description 
     * Rate for the second rate step.  Note that this is the rate per RateStep2Quantity not the rate per single quantity unit. 
     * This allows rating based on groups of quantities.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep2Rate: number = 0;

    /**
     * @name RateStep2MaximumQuantity
     * @description 
     * The maximum number to be included in rate step two.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep2MaximumQuantity: number = 0;

    /**
     * @name RateStep3Quantity
     * @description 
     * Quantity for the third rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep3Quantity: number = 0;

    /**
     * @name RateStep3Rate
     * @description 
     * Rate for the third rate step.  Note that this is the rate per RateStep3Quantity not the rate per single quantity unit.  This
     * allows rating based on groups of quantities.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep3Rate: number = 0;

    /**
     * @name RateStep3MaximumQuantity
     * @description 
     * The maximum number to be included in rate step three.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep3MaximumQuantity: number = 0;

    /**
     * @name RateStep4Quantity
     * @description 
     * Quantity for the fourth rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep4Quantity: number = 0;

    /**
     * @name RateStep4Rate
     * @description 
     * Rate for the fourth rate step.  Note that this is the rate per RateStep4Quantity not the rate per single quantity unit. 
     * This allows rating based on groups of quantities.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep4Rate: number = 0;

    /**
     * @name RateStep4MaximumQuantity
     * @description 
     * This field is not currently being used.  All remaining units that were not rated using the first three rate steps will be
     * rated using this fourth rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    RateStep4MaximumQuantity: number = 0;

    /**
     * @name SetupRate
     * @description 
     * Setup rate is a flat rate applied to each item in addition to quantity rates involved.
     * @servertype Double (nullable)
     * @type {number}
     */
    SetupRate: number = 0;

    /**
     * @name MinimumRate
     * @description 
     * If a calculated rate is less than the cost minimum then the rate minimum will be used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    MinimumRate: number = 0;

    /**
     * @name MaximumRate
     * @description 
     * If a calculated rate is more than the cost maximum then the rate maximum will be used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    MaximumRate: number = 0;

    /**
     * @name SurchargeCalculationFlag
     * @description 
     * Flag that indicates how the surcharge is calculated in relation to the quantity.  Possible values include: 
     * S = Step (default).  The rate at each step applies to the quantity specified in that step (i.e. SurchargeStep1Rate applies
     * for SurchargeStep1Quantity up to SurchargeStep1MaximumQuantity).
     * T = Total.  The rate at the step matching the quantity applies to the full quantity (i.e. SurchargeStep1Rate applies when
     * the quantity is between SurchargeStep1Quantity and SurchargeStep1MaximumQuantity).
     * @servertype String
     * @type {string}
     */
    SurchargeCalculationFlag: string = "S";

    /**
     * @name SurchargeStep1Quantity
     * @description 
     * Quantity for the first rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep1Quantity: number = 0;

    /**
     * @name SurchargeStep1Rate
     * @description 
     * Surcharge for the first rate step.  Note that this is the surcharge per SurchargeStep1Quantity not the rate per single quantity
     * unit.  This allows rating based on groups of quantities.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep1Rate: number = 0;

    /**
     * @name SurchargeStep1Percent
     * @description 
     * Surcharge percent for this rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep1Percent: number = 0;

    /**
     * @name SurchargeStep1MaximumQuantity
     * @description 
     * The maximum number to be included in rate step one.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep1MaximumQuantity: number = 0;

    /**
     * @name SurchargeStep2Quantity
     * @description 
     * Quantity for the second rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep2Quantity: number = 0;

    /**
     * @name SurchargeStep2Rate
     * @description 
     * Surcharge for the second rate step.  Note that this is the surcharge per SurchargeStep2Quantity not the rate per single quantity
     * unit.  This allows rating based on groups of quantities.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep2Rate: number = 0;

    /**
     * @name SurchargeStep2Percent
     * @description 
     * Surcharge percent for this rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep2Percent: number = 0;

    /**
     * @name SurchargeStep2MaximumQuantity
     * @description 
     * The maximum number to be included in rate step two.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep2MaximumQuantity: number = 0;

    /**
     * @name SurchargeStep3Quantity
     * @description 
     * Quantity for the third rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep3Quantity: number = 0;

    /**
     * @name SurchargeStep3Rate
     * @description 
     * Surcharge for the third rate step.  Note that this is the surcharge per SurchargeStep3Quantity not the rate per single quantity
     * unit.  This allows rating based on groups of quantities.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep3Rate: number = 0;

    /**
     * @name SurchargeStep3Percent
     * @description 
     * Surcharge percent for this rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep3Percent: number = 0;

    /**
     * @name SurchargeStep3MaximumQuantity
     * @description 
     * The maximum number to be included in rate step three.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep3MaximumQuantity: number = 0;

    /**
     * @name SurchargeStep4Quantity
     * @description 
     * Quantity for the fourth rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep4Quantity: number = 0;

    /**
     * @name SurchargeStep4Rate
     * @description 
     * Surcharge for the fourth rate step.  Note that this is the surcharge per SurchargeStep4Quantity not the rate per single quantity
     * unit.  This allows rating based on groups of quantities.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep4Rate: number = 0;

    /**
     * @name SurchargeStep4Percent
     * @description 
     * Surcharge percent for this rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep4Percent: number = 0;

    /**
     * @name SurchargeStep4MaximumQuantity
     * @description 
     * This field is not currently being used.  All remaining units that were not rated using the first three rate steps will be
     * rated using this fourth rate step.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeStep4MaximumQuantity: number = 0;

    /**
     * @name SurchargeSetupRate
     * @description 
     * Surcharge setup rate is a flat rate applied to each call in addition to unit rates involved.  It can be used as a flat surcharge
     * per record.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeSetupRate: number = 0;

    /**
     * @name SurchargePercent
     * @description 
     * Surcharge percent that is added to other surcharges.  It is calculated by multiplying this value by the calculated cost.
     *  Note that SurchargePercent is entered as a percent not decimal.  For example, a twenty percent surcharge would be entered
     * as 20 not .20.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargePercent: number = 0;

    /**
     * @name SurchargeMinimumRate
     * @description 
     * If a calculated surcharge is less than the surcharge minimum then the surcharge minimum will be used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeMinimumRate: number = 0;

    /**
     * @name SurchargeMaximumRate
     * @description 
     * If a calculated surcharge is more than the surcharge maximum then the surcharge maximum will be used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeMaximumRate: number = 0;

    /**
     * @name TaxCalculationMethod
     * @description 
     * The calculation method to use for calculating the tax.  Possible values include: 
     * C = Calculated from Item Price
     * I = Included in Item Price
     * @servertype String
     * @type {string}
     */
    TaxCalculationMethod: string = "C";

    /**
     * @name Flags
     * @description 
     * A list of flags for this item rate.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Rules
     * @description 
     * A list of rate application rules that can be used for more complex rating.
     * @servertype String
     * @type {string}
     */
    Rules: string = "";

    /**
     * @name Properties
     * @description 
     * Custom properties for this item rate beyond the user-defined values.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ItemEditViewModel
 * @description
 * The item table contains a list of items (products, services, etc.) available to the system.
 */
export class ItemEditViewModel {

    /**
     * @name ItemId
     * @description 
     * Item Id uniquely identifies this item record.
     * @servertype Int64
     * @type {number}
     */
    ItemId: number = null;

    /**
     * @name ItemNumber
     * @description 
     * The Item Number for this item.
     * @servertype String
     * @type {string}
     */
    ItemNumber: string = "";

    /**
     * @name ExternalItemNumber
     * @description 
     * Item number used in external systems or as an alternate means of identification.
     * @servertype String
     * @type {string}
     */
    ExternalItemNumber: string = "";

    /**
     * @name Description
     * @description 
     * Description for this item.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ItemCategory
     * @description 
     * Category taken from system pick list.
     * @servertype String
     * @type {string}
     */
    ItemCategory: string = "";

    /**
     * @name ItemType
     * @description 
     * Item Type taken from system pick list.
     * @servertype String
     * @type {string}
     */
    ItemType: string = "";

    /**
     * @name ItemClass
     * @description 
     * Item Class taken from system pick list.
     * @servertype String
     * @type {string}
     */
    ItemClass: string = "";

    /**
     * @name ItemGroup
     * @description 
     * Item Group taken from system pick list.
     * @servertype String
     * @type {string}
     */
    ItemGroup: string = "";

    /**
     * @name VisibilityAccountType
     * @description 
     * Account type that this item is valid for.  Empty indicates valid for all account types.
     * @servertype String
     * @type {string}
     */
    VisibilityAccountType: string = "";

    /**
     * @name VisibilityAccountClass
     * @description 
     * Account class that this item is valid for.  Empty indicates valid for all account classes.
     * @servertype String
     * @type {string}
     */
    VisibilityAccountClass: string = "";

    /**
     * @name VisibilityMarketType
     * @description 
     * Market type that this item is valid for.  Empty indicates valid for all market types.
     * @servertype String
     * @type {string}
     */
    VisibilityMarketType: string = "";

    /**
     * @name VisibilityContactId
     * @description 
     * A Contact Id that represents the only contact that this item is valid for.  Null indicates valid for all contacts.  If this
     * contact is an agent the item is valid for all customers assigned to this agent.  If this contact is a primary customer the
     * item is valid for all secondary customers assigned to this primary customer.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VisibilityContactId: number = null;

    /**
     * @name VisibilityContactName
     * @servertype String
     * @type {string}
     */
    VisibilityContactName: string = "";

    /**
     * @name ServiceType
     * @description 
     * Service type for this item.  Possible values include:
     * * = Any
     * V = Voice
     * D = Data
     * I = Video
     * C = Content
     * M = Mixed
     * S = Service
     * P = Product
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "*";

    /**
     * @name GeneralLedgerCode
     * @description 
     * General ledger code for this item.
     * @servertype String
     * @type {string}
     */
    GeneralLedgerCode: string = "";

    /**
     * @name TaxItemCode
     * @description 
     * A product item code defining products for tax purposes.  For the taxation engine to work properly, each product and service
     * must be assigned a tax item code.
     * @servertype String
     * @type {string}
     */
    TaxItemCode: string = "";

    /**
     * @name TaxCalculationMethod
     * @description 
     * The calculation method to use for calculating the tax.  Possible values include: 
     * C = Calculated from Item Price
     * I = Included in Item Price
     * @servertype String
     * @type {string}
     */
    TaxCalculationMethod: string = "C";

    /**
     * @name InventoryItem
     * @description 
     * When true this item represents an inventory item.  The inventory type is specified in the inventory type id.
     * @servertype Boolean
     * @type {boolean}
     */
    InventoryItem: boolean = false;

    /**
     * @name InventoryId
     * @description 
     * Inventory Id for this inventory item.  Note this is often null for durable inventory items not tracked in quantity.  Inventory
     * not tracked in quantity will typically have the inventory type id value populated.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryId: number = null;

    /**
     * @name InventoryTypeId
     * @description 
     * Inventory Type Id for this inventory item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryTypeId: number = null;

    /**
     * @name CommissionMaximumCheck
     * @description 
     * Flag that indicates if there is a maximum value check on commissions paid for this item.  Possible values include: 
     * N = No maximum commission check.
     * P = Maximum percent commission check.  Commission maximum value represents the maximum percentage (in decimal 10% = 0.1)
     * that can be paid in commissions for this item.
     * M = Maximum monetary commission check.  Commission maximum value represents the maximum monetary value (in currency) that
     * can be paid in commissions for this item.
     * @servertype String
     * @type {string}
     */
    CommissionMaximumCheck: string = "N";

    /**
     * @name CommissionMaximumValue
     * @description 
     * Maximum percent or money (see commission maximum check setting) to be paid in commissions for this item.  When commissions
     * for an item total more than this value they are all decreased by an equal factor so commissions cannot exceed this stated
     * value.  For example, if the maximum commission for an item is $10 but the commissions totaled $15 then each commission would
     * be multiplied by .667 ($10/$15) so the total commission would be in line with the maximum available for commissions.  This
     * provides a safeguard against incorrectly designed agent commission plans.
     * @servertype Double (nullable)
     * @type {number}
     */
    CommissionMaximumValue: number = 0;

    /**
     * @name CalculationType
     * @description 
     * Calculation type for this item:
     * F = Fixed Amount
     * A = Activity Types
     * P = Percent Surcharge/Discount
     * T = Tax (see item list)
     * @servertype String
     * @type {string}
     */
    CalculationType: string = "F";

    /**
     * @name CalculationFixedBillingPeriod
     * @description 
     * Billing period for fixed amount types.  Used to determine the quantity included for an invoice including any necessary prorating.
     *  Possible values include:
     * D = Daily
     * W = Weekly
     * S = Semi-Monthly
     * M = Monthly
     * Q = Quarterly
     * Y = Yearly
     * C-xx = Custom where xx is number of days.
     * N = N/A for items that are not time period sensitive.
     * @servertype String
     * @type {string}
     */
    CalculationFixedBillingPeriod: string = "";

    /**
     * @name CalculationFixedProrate
     * @description 
     * Flag specifying how prorating of billing periods should be handled.  Possible values are: 
     * F = Fixed.  Billing periods are not prorated.  For example, a monthly fee will be charged twice on an invoice that includes
     * a time period of six weeks.
     * C = Casual Prorate.  Billing periods are prorated using a 'casual' prorating standard of 4 weeks or 30 days in a month. 
     * For example, a monthly fee will be charged 1.5 times on an invoice that includes a time period of six weeks.
     * S = Strict Prorate.  Billing periods are prorated using a 'strict' prorating standard of the actual fraction of weeks and
     * actual number of days in a given month.  For example, a weekly fee will be charged 4.43 times on an invoice that includes
     * a time period of one month and that month includes 31 days (31-28(4x7)=3 and 3/7=.43).
     * @servertype String
     * @type {string}
     */
    CalculationFixedProrate: string = "C";

    /**
     * @name CalculationFixedConsolidate
     * @description 
     * Flag specifying how fixed charges for multiple billing periods should be handled.  Possible values are: 
     * C = Consolidate.  Fixed charges for multiple billing periods are consolidated into one line item on an invoice.
     * B = Consolidate Before Calculation.  Fixed charges for multiple billing periods are consolidated into one line on an invoice
     * and the quantity is consolidated (stated quantity x billing periods on invoice) before cost of item is calculated.
     * S = Separate.  Fixed charges for multiple billing periods are listed as separate line items on an invoice.
     * A = Annotated.  Same as S but the description is annotated to include the billing period for the line item.
     * A-xxx = Annotated Association.  Same as A except done once for each association of type xxx belonging to customer and only
     * for the valid date range of that association.
     * C-xxx = Consolidated Association.  Same as C except done once for each association of type xxx belonging to customer and
     * only for the valid date range of that association.
     * @servertype String
     * @type {string}
     */
    CalculationFixedConsolidate: string = "A";

    /**
     * @name CalculationPercentFlag
     * @description 
     * Flag indicating how percent calculation types should be applied.  Possible values are: 
     * A = Applied to all items.
     * P = Applied to all items that are not percent calculation type items.
     * L = Applied to all items in list (see item list).
     * N = Applied to all items not in list (see item list).
     * @servertype String
     * @type {string}
     */
    CalculationPercentFlag: string = "P";

    /**
     * @name CalculationPercentConsolidate
     * @description 
     * Flag specifying how percent charges for multiple accounting and/or billing periods should be handled.  Possible values are:
     * A = Accounting.  The percent charge is assessed for each accounting period as a separate line item on an invoice.
     * B = Billing.  The percent charge is assessed for each billing period as a separate line item on an invoice.
     * J = Joint (Accounting and Billing).  The percent charges is assessed for each accounting and billing period combination as
     * a separate line item on an invoice.
     * C = Combined.  The percent charge appears as one line item on an invoice regardless of accounting and billing periods included.
     * @servertype String
     * @type {string}
     */
    CalculationPercentConsolidate: string = "B";

    /**
     * @name CalculationTaxConsolidate
     * @description 
     * Flag specifying how tax charges for multiple accounting and/or billing periods should be handled.  Possible values are: 
     * A = Accounting.  The tax is assessed for each accounting period as a separate line item on an invoice.
     * B = Billing.  The tax is assessed for each billing period as a separate line item on an invoice.
     * J = Joint (Accounting and Billing).  The tax is assessed for each accounting and billing period combination as a separate
     * line item on an invoice.
     * C = Combined.  The tax appears as one line item on an invoice regardless of accounting and billing periods included.
     * @servertype String
     * @type {string}
     */
    CalculationTaxConsolidate: string = "B";

    /**
     * @name CalculationActivityTypeList
     * @description 
     * A list of usage types to include with this item when the item's calculation type is A.  For example: LO,IT would include
     * local and intralata usage types for all usage tables.  Possible values can include:
     * LO = Voice - Local
     * IT = Voice - IntraLATA
     * IS = Voice - Intrastate
     * LD = Voice - Interstate
     * IN = Voice - International
     * FR = Voice - Free
     * EI = Voice - Extension/Internal
     * MO = Voice - Mobile
     * EX = Voice - Exception
     * UN = Voice - Unknown
     * EM = Voice - Emergency
     * OP = Voice - Operator
     * IO = Voice - International Operator
     * LI = Voice - Local Information
     * OI = Voice - Other Information (i.e. long distance)
     * DW = Data - Web
     * DE = Data - Email
     * DF = Data - Ftp
     * DN = Data - Nntp
     * DI = Data - IM
     * DS = Data - SMS
     * DM = Data - MMS
     * DA = Data - Analytics
     * DU = Data - Usage
     * DO = Data - Other
     * VC = Video - Conference
     * VM = Video - Movie
     * VT = Video - TV
     * VO = Video - Other
     * CA = Content - Application
     * CG = Content - Gaming
     * CD = Content - Download
     * CP = Content - Pay Per View/Use
     * CM = Content - Media
     * CS = Content - Services
     * CO = Content - Other
     * @servertype string array
     * @type {string[]}
     */
    CalculationActivityTypeList: string[] = [];

    /**
     * @name CalculationActivityTypeQuantityProperty
     * @description 
     * Activity property used for quantity on this item.  An empty or null value defaults to Count.  Possible values include: 
     * C = Count
     * S = Seconds
     * M = Minutes
     * H = Hours
     * P = Pulses
     * U = Units
     * FP = Fax Pages
     * BSS = Bytes Sent Source
     * BRS = Bytes Received Source
     * BTS = Bytes Total Source
     * BST = Bytes Sent Target
     * BRT = Bytes Received Target
     * BTT = Bytes Total Target
     * BT = Bytes Total Source and Target
     * KBSS = Kilobytes Sent Source
     * KBRS = Kilobytes Received Source
     * KBTS = Kilobytes Total Source
     * KBST = Kilobytes Sent Target
     * KBRT = Kilobytes Received Target
     * KBTT = Kilobytes Total Target
     * KBT = Kilobytes Total Source and Target
     * MBSS = Megabytes Sent Source
     * MBRS = Megabytes Received Source
     * MBTS = Megabytes Total Source
     * MBST = Megabytes Sent Target
     * MBRT = Megabytes Received Target
     * MBTT = Megabytes Total Target
     * MBT = Megabytes Total Source and Target
     * GBSS = Gigabytes Sent Source
     * GBRS = Gigabytes Received Source
     * GBTS = Gigabytes Total Source
     * GBST = Gigabytes Sent Target
     * GBRT = Gigabytes Received Target
     * GBTT = Gigabytes Total Target
     * GBT = Gigabytes Total Source and Target
     * PKSS = Packets Sent Source
     * PKRS = Packets Received Source
     * PKTS = Packets Total Source
     * PKST = Packets Sent Target
     * PKRT = Packets Received Target
     * PKTT = Packets Total Target
     * PKT = Packets Total Source and Target
     * @servertype String
     * @type {string}
     */
    CalculationActivityTypeQuantityProperty: string = "C";

    /**
     * @name CalculationActivityTypeRateFlag
     * @description 
     * Activity type rate flag that indicates how item is to be rated.  Possible values include: 
     * P = Prerated.  Existing activity rates are used.
     * R = Rate.  Activity rates need to be calculated.
     * S = Surcharge/Discount.  Existing activity rates should be surcharged or discounted.
     * D = Surcharge/Discount as separate item.  Existing activity rates should be surcharged or discounted but the surcharge/discount
     * is entered as a separate line item.  Under this circumstance the item list table contains the item id of the items that are
     * to be surcharged or discounted.
     * M = Minimum.  Existing activity rates should have a minimum applied.
     * N = Minimum as separate item.  Existing activity rates should have a minimum applied but the difference between the actual
     * rate and the minimum is entered as a separate line item.  Under this circumstance the item list table contains the item id
     * of the items that are to have the minimum applied.
     * X = Maximum.  Existing activity rates should have a maximum applied.
     * I = Maximum as separate item.  Existing activity rates should have a maximum applied but the difference between the actual
     * rate and the maximum is entered as a separate line item.  Under this circumstance the item list table contains the item id
     * of the items that are to have the maximum applied.
     * @servertype String
     * @type {string}
     */
    CalculationActivityTypeRateFlag: string = "P";

    /**
     * @name CalculationActivityFilterId
     * @description 
     * The filter id of an activity Filter used for further breakdown of the item when the calculation type is A (activity types).
     *  This allows things like breakdown of traffic to Mexico from other countries but must be used with extreme care to ensure
     * that no data is excluded from billing.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CalculationActivityFilterId: number = null;

    /**
     * @name Flags
     * @description 
     * A list of flags for this item.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description 
     * Custom properties for this item beyond the user-defined values.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Package
 * @description
 * The package table contains information for product and service offerings.
 */
export class PackageListViewModel {

    /**
     * @name PackageId
     * @description 
     * Package Id uniquely identifies this package.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = null;

    /**
     * @name ExternalPackageId
     * @description 
     * Package Id used in external systems or as an alternate means of identification.
     * @servertype String
     * @type {string}
     */
    ExternalPackageId: string = "";

    /**
     * @name Description
     * @description 
     * Package Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name FullDescription
     * @description 
     * Full description for this package.
     * @servertype String
     * @type {string}
     */
    FullDescription: string = "";

    /**
     * @name PackageType
     * @description 
     * Package Type taken from system pick list.  Can be used to group packages of same type.
     * @servertype String
     * @type {string}
     */
    PackageType: string = "";

    /**
     * @name PackageCode
     * @description 
     * Package Code taken from system pick list.  Can be used to group packages of same code.
     * @servertype String
     * @type {string}
     */
    PackageCode: string = "";

    /**
     * @name PackageClass
     * @description 
     * Package Class taken from system pick list.  Can be used to group packages of same class.
     * @servertype String
     * @type {string}
     */
    PackageClass: string = "";

}



/**
 * @name PackageEditViewModel
 */
export class PackageEditViewModel {

    /**
     * @name PackageId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name ExternalPackageId
     * @servertype String
     * @type {string}
     */
    ExternalPackageId: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name FullDescription
     * @servertype String
     * @type {string}
     */
    FullDescription: string = "";

    /**
     * @name PackageOccurrenceDescription
     * @servertype String
     * @type {string}
     */
    PackageOccurrenceDescription: string = "";

    /**
     * @name PackageType
     * @servertype String
     * @type {string}
     */
    PackageType: string = "";

    /**
     * @name PackageCode
     * @servertype String
     * @type {string}
     */
    PackageCode: string = "";

    /**
     * @name PackageClass
     * @servertype String
     * @type {string}
     */
    PackageClass: string = "";

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ValidStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name VisibilityCostCenterType
     * @servertype string array
     * @type {string[]}
     */
    VisibilityCostCenterType: string[] = [];

    /**
     * @name VisibilityAccountType
     * @servertype String
     * @type {string}
     */
    VisibilityAccountType: string = "";

    /**
     * @name VisibilityAccountClass
     * @servertype String
     * @type {string}
     */
    VisibilityAccountClass: string = "";

    /**
     * @name VisibilityMarketType
     * @servertype String
     * @type {string}
     */
    VisibilityMarketType: string = "";

    /**
     * @name VisibilityServiceType
     * @servertype String
     * @type {string}
     */
    VisibilityServiceType: string = "";

    /**
     * @name VisibilityBillingCycle
     * @servertype String
     * @type {string}
     */
    VisibilityBillingCycle: string = "";

    /**
     * @name VisibilityContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VisibilityContactId: number = null;

    /**
     * @name VisibilityContactName
     * @servertype String
     * @type {string}
     */
    VisibilityContactName: string = "";

    /**
     * @name PackageSelectable
     * @servertype Boolean
     * @type {boolean}
     */
    PackageSelectable: boolean = false;

    /**
     * @name QuantitySelectable
     * @servertype Boolean
     * @type {boolean}
     */
    QuantitySelectable: boolean = false;

    /**
     * @name CaseTemplateIdStart
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateIdStart: number = 0;

    /**
     * @name CaseTemplateIdModify
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateIdModify: number = 0;

    /**
     * @name CaseTemplateIdClose
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateIdClose: number = 0;

    /**
     * @name CaseTemplateIdConvert
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateIdConvert: number = 0;

    /**
     * @name CaseBasedActivation
     * @servertype Boolean
     * @type {boolean}
     */
    CaseBasedActivation: boolean = false;

    /**
     * @name CaseBasedCancellation
     * @servertype Boolean
     * @type {boolean}
     */
    CaseBasedCancellation: boolean = false;

    /**
     * @name ConversionTriggerCaseStart
     * @servertype Boolean
     * @type {boolean}
     */
    ConversionTriggerCaseStart: boolean = false;

    /**
     * @name ConversionTriggerCaseClose
     * @servertype Boolean
     * @type {boolean}
     */
    ConversionTriggerCaseClose: boolean = false;

    /**
     * @name NotificationGroupIdStart
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStart: number = 0;

    /**
     * @name NotificationGroupIdActivate
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdActivate: number = 0;

    /**
     * @name NotificationGroupIdModify
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdModify: number = 0;

    /**
     * @name NotificationGroupIdCancel
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdCancel: number = 0;

    /**
     * @name NotificationGroupIdClose
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdClose: number = 0;

    /**
     * @name NotificationGroupIdConvert
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdConvert: number = 0;

    /**
     * @name ContractRequired
     * @servertype Boolean
     * @type {boolean}
     */
    ContractRequired: boolean = false;

    /**
     * @name ContractDurationMonths
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ContractDurationMonths: number = 0;

    /**
     * @name ContractAutoRenew
     * @servertype Boolean
     * @type {boolean}
     */
    ContractAutoRenew: boolean = false;

    /**
     * @name ContractRenewDurationMonths
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ContractRenewDurationMonths: number = 0;

    /**
     * @name ContractCanCancel
     * @servertype Boolean
     * @type {boolean}
     */
    ContractCanCancel: boolean = false;

    /**
     * @name ContractCanConvert
     * @servertype String
     * @type {string}
     */
    ContractCanConvert: string = "";

    /**
     * @name TaxItemCode
     * @servertype String
     * @type {string}
     */
    TaxItemCode: string = "";

    /**
     * @name TaxCalculationMethod
     * @servertype String
     * @type {string}
     */
    TaxCalculationMethod: string = "";

    /**
     * @name LifeTimeMinutes
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LifeTimeMinutes: number = 0;

    /**
     * @name InvoiceOnActivation
     * @servertype Boolean
     * @type {boolean}
     */
    InvoiceOnActivation: boolean = false;

    /**
     * @name PackageConversionFlag
     * @servertype String
     * @type {string}
     */
    PackageConversionFlag: string = "";

    /**
     * @name AgentTrackingFlag
     * @servertype String
     * @type {string}
     */
    AgentTrackingFlag: string = "";

    /**
     * @name AgentPlanId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentPlanId: number = 0;

    /**
     * @name QuantityMinimum
     * @servertype Int32 (nullable)
     * @type {number}
     */
    QuantityMinimum: number = 0;

    /**
     * @name QuantityMaximum
     * @servertype Int32 (nullable)
     * @type {number}
     */
    QuantityMaximum: number = 0;

    /**
     * @name QuantityCalculationFlag
     * @servertype String
     * @type {string}
     */
    QuantityCalculationFlag: string = "";

    /**
     * @name ValidBillingPeriodFlag
     * @servertype String
     * @type {string}
     */
    ValidBillingPeriodFlag: string = "";

    /**
     * @name RateAdjustmentFlag
     * @servertype String
     * @type {string}
     */
    RateAdjustmentFlag: string = "";

    /**
     * @name Flags
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PackageAssociationEditViewModel
 */
export class PackageAssociationEditViewModel {

    /**
     * @name PackageAssociationId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageAssociationId: number = 0;

    /**
     * @name PackageId
     * @servertype Int64
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name AssociationType
     * @servertype String
     * @type {string}
     */
    AssociationType: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name FullDescription
     * @servertype String
     * @type {string}
     */
    FullDescription: string = "";

    /**
     * @name QuantitySelectable
     * @servertype Boolean
     * @type {boolean}
     */
    QuantitySelectable: boolean = false;

    /**
     * @name QuantityMinimum
     * @servertype Int32 (nullable)
     * @type {number}
     */
    QuantityMinimum: number = 0;

    /**
     * @name QuantityMaximum
     * @servertype Int32 (nullable)
     * @type {number}
     */
    QuantityMaximum: number = 0;

    /**
     * @name ActivateActionFlag
     * @servertype String
     * @type {string}
     */
    ActivateActionFlag: string = "";

    /**
     * @name CloseActionFlag
     * @servertype String
     * @type {string}
     */
    CloseActionFlag: string = "";

    /**
     * @name LagDaysClose
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LagDaysClose: number = 0;

    /**
     * @name LagDaysUnassigned
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LagDaysUnassigned: number = 0;

    /**
     * @name Flags
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PackageInventoryEditViewModel
 */
export class PackageInventoryEditViewModel {

    /**
     * @name PackageInventoryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageInventoryId: number = 0;

    /**
     * @name PackageId
     * @servertype Int64
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name InventoryTypeId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryTypeId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name FullDescription
     * @servertype String
     * @type {string}
     */
    FullDescription: string = "";

    /**
     * @name QuantitySelectable
     * @servertype Boolean
     * @type {boolean}
     */
    QuantitySelectable: boolean = false;

    /**
     * @name QuantityMinimum
     * @servertype Int32 (nullable)
     * @type {number}
     */
    QuantityMinimum: number = 0;

    /**
     * @name QuantityMaximum
     * @servertype Int32 (nullable)
     * @type {number}
     */
    QuantityMaximum: number = 0;

    /**
     * @name ActivateActionFlag
     * @servertype String
     * @type {string}
     */
    ActivateActionFlag: string = "";

    /**
     * @name UnassignedContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    UnassignedContactId: number = null;

    /**
     * @name UnassignedContactName
     * @servertype String
     * @type {string}
     */
    UnassignedContactName: string = "";

    /**
     * @name CloseActionFlag
     * @servertype String
     * @type {string}
     */
    CloseActionFlag: string = "";

    /**
     * @name MoveContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    MoveContactId: number = null;

    /**
     * @name MoveContactName
     * @servertype String
     * @type {string}
     */
    MoveContactName: string = "";

    /**
     * @name LagDaysClose
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LagDaysClose: number = 0;

    /**
     * @name LagDaysUnassigned
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LagDaysUnassigned: number = 0;

    /**
     * @name Flags
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PackageItemEditViewModel
 */
export class PackageItemEditViewModel {

    /**
     * @name PackageItemId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageItemId: number = 0;

    /**
     * @name PackageId
     * @servertype Int64
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name ItemTrigger
     * @servertype String
     * @type {string}
     */
    ItemTrigger: string = "";

    /**
     * @name ItemTriggerBillingPeriod
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ItemTriggerBillingPeriod: number = 0;

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name SequenceNumber
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SequenceNumber: number = 0;

    /**
     * @name QuantitySelectable
     * @servertype Boolean
     * @type {boolean}
     */
    QuantitySelectable: boolean = false;

    /**
     * @name Quantity
     * @servertype Double (nullable)
     * @type {number}
     */
    Quantity: number = 0;

    /**
     * @name ItemId
     * @servertype Int64
     * @type {number}
     */
    ItemId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RateId
     * @servertype String
     * @type {string}
     */
    RateId: string = "";

    /**
     * @name ActivityFilterId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ActivityFilterId: number = 0;

    /**
     * @name ValidStartBillingPeriod
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValidStartBillingPeriod: number = 0;

    /**
     * @name ValidEndBillingPeriod
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValidEndBillingPeriod: number = 0;

    /**
     * @name ValidStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Flags
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Package Occurrence
 * @description
 * The package occurrence table contains information about occurrences of packages assigned to accounts.
 */
export class PackageOccurrenceListViewModel {

    /**
     * @name PackageOccurrenceId
     * @description 
     * Package Occurrence Id uniquely identifies this package occurrence.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = null;

    /**
     * @name PackageId
     * @description 
     * Package Id.
     * @servertype Int64
     * @type {number}
     */
    PackageId: number = null;

    /**
     * @name ExternalPackageOccurrenceId
     * @description 
     * Package Occurrence Id used in external systems or as an alternate means of identification.
     * @servertype String
     * @type {string}
     */
    ExternalPackageOccurrenceId: string = "";

    /**
     * @name BillingAccountId
     * @description 
     * Billing Account Id that this package occurrence belongs to.
     * @servertype Int64
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name SequenceNumber
     * @description 
     * The sequence used when displaying multiple packages (for example, on an invoice).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SequenceNumber: number = 0;

    /**
     * @name Description
     * @description 
     * Description for this package.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ActivationFlag
     * @description 
     * Activation flags for this package occurrence.  Flags indicates pending steps required for package activation.  Once all of
     * the activation flags have been cleared the activated and valid start date time values will be updated.  More than one value
     * is possible and possible values include:
     * ! = Activation not attempted yet.
     * R = Requested start date and time has yet to be reached.
     * C = Start case has yet to be completed.
     * A = Association has yet to be assigned.
     * V = Inventory has yet to be assigned.
     * M = Missing required attribute.
     * B = Package should be started before next invoice (dependent on other flags being cleared).
     * N = Package should be started after next invoice (dependent on other flags being cleared).
     * E = Error
     *   = No pending activation action for this package occurrence.
     * @servertype String
     * @type {string}
     */
    ActivationFlag: string = "";

    /**
     * @name CancellationFlag
     * @description 
     * Cancellation flags for this package occurrence.  Flags indicates pending steps required for package cancellation.  Once all
     * of the cancellation flags have been cleared the closed and valid end date time values will be updated.  More than one value
     * is possible and possible values include:
     * ! = Close not attempted yet.
     * R = Requested close date and time has yet to be reached.
     * C = Close case has yet to be completed.
     * B = Close this package before next invoice (dependent on other flags being cleared).
     * N = Close this package after next invoice (dependent on other flags being cleared).
     * T = Terminate this package at end of contract (dependent on other flags being cleared).
     * E = Error
     *   = No pending cancellation action for this package occurrence.
     * @servertype String
     * @type {string}
     */
    CancellationFlag: string = "";

    /**
     * @name Status
     * @description 
     * Status of the package occurrence.  Possible values include: 
     * P = Pending Activation.  See activation flag for activation steps required.
     * A = Active.  Package occurrence is active.
     * I = Inactive.  Package occurrence is inactive (as determined by business rule with no special treatment).
     * S = Suspended.  Package occurrence is suspended.
     * N = Pending Cancellation.  See cancellation flag for cancellation steps required.
     * C = Closed.  Package occurrence is closed.
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name OrderedDateTime
     * @description 
     * The ordered date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    OrderedDateTime: Date = null;

    /**
     * @name RequestedStartDateTime
     * @description 
     * The date and time this package was requested to start on.  This will frequently be the activated date time and valid start
     * date time, however, in situations where provisioning is required service for the package may not be able to start on this
     * date.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RequestedStartDateTime: Date = null;

    /**
     * @name ActivatedDateTime
     * @description 
     * The activated date and time.  May differ from ordered date time or requested start date time if activation depends on provisioning
     * case being completed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActivatedDateTime: Date = null;

    /**
     * @name ContractEndDateTime
     * @description 
     * The date and time the contract ends.  This may be updated from time-to-time to reflect automatic contract renewals except
     * in cases where package has been cancelled.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ContractEndDateTime: Date = null;

    /**
     * @name SuspendedDateTime
     * @description 
     * Date and time when the package occurrence was last suspended.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedDateTime: Date = null;

    /**
     * @name ReactivatedDateTime
     * @description 
     * Date and time when the package occurrence was last reactivated after being suspended.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReactivatedDateTime: Date = null;

    /**
     * @name CancelledDateTime
     * @description 
     * The cancelled date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CancelledDateTime: Date = null;

    /**
     * @name ClosedDateTime
     * @description 
     * The closed date and time.  May differ from cancelled date time if cancellation depends on provisioning case being completed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ClosedDateTime: Date = null;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this item is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this item is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name PackageOccurrenceEditViewModel
 */
export class PackageOccurrenceEditViewModel {

    /**
     * @name PackageOccurrenceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = 0;

    /**
     * @name PackageId
     * @servertype Int64
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name ExternalPackageOccurrenceId
     * @servertype String
     * @type {string}
     */
    ExternalPackageOccurrenceId: string = "";

    /**
     * @name BillingAccountId
     * @servertype Int64
     * @type {number}
     */
    BillingAccountId: number = 0;

    /**
     * @name SequenceNumber
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SequenceNumber: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name AgentContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentContactId: number = null;

    /**
     * @name AgentContactName
     * @servertype String
     * @type {string}
     */
    AgentContactName: string = "";

    /**
     * @name AgentPlanId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentPlanId: number = 0;

    /**
     * @name CaseIdStart
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseIdStart: number = 0;

    /**
     * @name CaseIdClose
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseIdClose: number = 0;

    /**
     * @name ActivationFlag
     * @servertype String
     * @type {string}
     */
    ActivationFlag: string = "";

    /**
     * @name CancellationFlag
     * @servertype String
     * @type {string}
     */
    CancellationFlag: string = "";

    /**
     * @name ContractCancelled
     * @servertype Boolean
     * @type {boolean}
     */
    ContractCancelled: boolean = false;

    /**
     * @name ConvertedFromPackageOccurrenceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ConvertedFromPackageOccurrenceId: number = 0;

    /**
     * @name ConvertedToPackageOccurrenceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ConvertedToPackageOccurrenceId: number = 0;

    /**
     * @name Status
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name StatusLocked
     * @servertype Boolean
     * @type {boolean}
     */
    StatusLocked: boolean = false;

    /**
     * @name OrderedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    OrderedDateTime: Date = null;

    /**
     * @name RequestedStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RequestedStartDateTime: Date = null;

    /**
     * @name ActivatedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActivatedDateTime: Date = null;

    /**
     * @name ContractEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ContractEndDateTime: Date = null;

    /**
     * @name SuspendedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedDateTime: Date = null;

    /**
     * @name ReactivatedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReactivatedDateTime: Date = null;

    /**
     * @name CancelledDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CancelledDateTime: Date = null;

    /**
     * @name ClosedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ClosedDateTime: Date = null;

    /**
     * @name ValidStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PackageOccurrenceItemEditViewModel
 */
export class PackageOccurrenceItemEditViewModel {

    /**
     * @name PackageOccurrenceItemId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceItemId: number = 0;

    /**
     * @name PackageOccurrenceId
     * @servertype Int64
     * @type {number}
     */
    PackageOccurrenceId: number = 0;

    /**
     * @name PackageId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name PackageItemId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageItemId: number = 0;

    /**
     * @name ItemTrigger
     * @servertype String
     * @type {string}
     */
    ItemTrigger: string = "";

    /**
     * @name ItemTriggerBillingPeriod
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ItemTriggerBillingPeriod: number = 0;

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name SequenceNumber
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SequenceNumber: number = 0;

    /**
     * @name Quantity
     * @servertype Double (nullable)
     * @type {number}
     */
    Quantity: number = 0;

    /**
     * @name ItemId
     * @servertype Int64
     * @type {number}
     */
    ItemId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RateId
     * @servertype String
     * @type {string}
     */
    RateId: string = "";

    /**
     * @name ActivityFilterId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ActivityFilterId: number = 0;

    /**
     * @name ValidStartBillingPeriod
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValidStartBillingPeriod: number = 0;

    /**
     * @name ValidEndBillingPeriod
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValidEndBillingPeriod: number = 0;

    /**
     * @name ValidStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PackageOccurrenceRateAdjustmentEditViewModel
 */
export class PackageOccurrenceRateAdjustmentEditViewModel {

    /**
     * @name PackageOccurrenceRateAdjustmentId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceRateAdjustmentId: number = 0;

    /**
     * @name PackageOccurrenceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = 0;

    /**
     * @name PackageId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ParentBillingAccountId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentBillingAccountId: number = 0;

    /**
     * @name BillingAccountId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = 0;

    /**
     * @name TypeList
     * @servertype string array
     * @type {string[]}
     */
    TypeList: string[] = [];

    /**
     * @name TargetLocationList
     * @servertype string array
     * @type {string[]}
     */
    TargetLocationList: string[] = [];

    /**
     * @name BillingPeriodStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BillingPeriodStartDateTime: Date = null;

    /**
     * @name BillingPeriodEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BillingPeriodEndDateTime: Date = null;

    /**
     * @name UnitBasis
     * @servertype String
     * @type {string}
     */
    UnitBasis: string = "";

    /**
     * @name UnitType
     * @servertype String
     * @type {string}
     */
    UnitType: string = "";

    /**
     * @name UnitsFullValue
     * @servertype Double (nullable)
     * @type {number}
     */
    UnitsFullValue: number = 0;

    /**
     * @name UnitsCurrentValue
     * @servertype Double (nullable)
     * @type {number}
     */
    UnitsCurrentValue: number = 0;

    /**
     * @name UnitsPushForwardValue
     * @servertype Double (nullable)
     * @type {number}
     */
    UnitsPushForwardValue: number = 0;

    /**
     * @name UnitsPullForwardValue
     * @servertype Double (nullable)
     * @type {number}
     */
    UnitsPullForwardValue: number = 0;

    /**
     * @name SurchargeOrDiscount
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeOrDiscount: number = 0;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PackageRateAdjustmentEditViewModel
 */
export class PackageRateAdjustmentEditViewModel {

    /**
     * @name PackageRateAdjustmentId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageRateAdjustmentId: number = 0;

    /**
     * @name PackageId
     * @servertype Int64
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name TypeList
     * @servertype string array
     * @type {string[]}
     */
    TypeList: string[] = [];

    /**
     * @name TargetLocationList
     * @servertype string array
     * @type {string[]}
     */
    TargetLocationList: string[] = [];

    /**
     * @name UnitBasis
     * @servertype String
     * @type {string}
     */
    UnitBasis: string = "";

    /**
     * @name UnitType
     * @servertype String
     * @type {string}
     */
    UnitType: string = "";

    /**
     * @name UnitsFullValue
     * @servertype Double (nullable)
     * @type {number}
     */
    UnitsFullValue: number = 0;

    /**
     * @name SurchargeOrDiscount
     * @servertype Double (nullable)
     * @type {number}
     */
    SurchargeOrDiscount: number = 0;

    /**
     * @name ValidStartBillingPeriod
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValidStartBillingPeriod: number = 0;

    /**
     * @name ValidEndBillingPeriod
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValidEndBillingPeriod: number = 0;

    /**
     * @name ValidStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Flags
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PayPal Authorization Request
 */
export class PayPalAuthorizationRequestModel {

    /**
     * @name PaymentProviderId
     * @servertype String
     * @type {string}
     */
    PaymentProviderId: string = "";

    /**
     * @name ReturnUrl
     * @servertype String
     * @type {string}
     */
    ReturnUrl: string = "";

    /**
     * @name CancelUrl
     * @servertype String
     * @type {string}
     */
    CancelUrl: string = "";

    /**
     * @name Packages
     * @servertype PayPalAuthorizationRequestPackageModel array
     * @type {PayPalAuthorizationRequestPackageModel[]}
     */
    Packages: PayPalAuthorizationRequestPackageModel[] = [];

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Cargo
     * @servertype System.Object
     * @type {any}
     */
    Cargo: any = {};

    /**
     * @name Options
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Options: {[key: string]:  string} = {};

    /**
     * @name UtcOffset
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name PayPal Authorization Request Package
 */
export class PayPalAuthorizationRequestPackageModel {

    /**
     * @name PackageId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name PackageType
     * @servertype String
     * @type {string}
     */
    PackageType: string = "";

    /**
     * @name PackageCode
     * @servertype String
     * @type {string}
     */
    PackageCode: string = "";

    /**
     * @name PackageClass
     * @servertype String
     * @type {string}
     */
    PackageClass: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name RetailAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    RetailAmount: number = 0;

    /**
     * @name BillingAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    BillingAmount: number = 0;

    /**
     * @name AuthorizationCode
     * @servertype String
     * @type {string}
     */
    AuthorizationCode: string = "";

    /**
     * @name ValidStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Options
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Options: {[key: string]:  string} = {};

}



/**
 * @name Purchase Request
 */
export class PurchaseRequestModel {

    /**
     * @name TransactionId
     * @servertype String
     * @type {string}
     */
    TransactionId: string = "";

    /**
     * @name CustomerId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CustomerId: number = 0;

    /**
     * @name Customer
     * @servertype IB.Web.API.Models.v5.CustomerEditViewModel
     * @type {CustomerEditViewModel}
     */
    Customer: CustomerEditViewModel = null;

    /**
     * @name BillingAccountId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = 0;

    /**
     * @name BillingAccount
     * @servertype IB.Web.API.Models.v5.BillingAccountEditViewModel
     * @type {BillingAccountEditViewModel}
     */
    BillingAccount: BillingAccountEditViewModel = null;

    /**
     * @name Packages
     * @servertype PurchaseRequestPackageModel array
     * @type {PurchaseRequestPackageModel[]}
     */
    Packages: PurchaseRequestPackageModel[] = [];

    /**
     * @name Payment
     * @servertype IB.Web.API.Models.v5.PurchaseRequestPaymentModel
     * @type {PurchaseRequestPaymentModel}
     */
    Payment: PurchaseRequestPaymentModel = null;

    /**
     * @name LinkedPurchaseCheckoutId
     * @servertype String
     * @type {string}
     */
    LinkedPurchaseCheckoutId: string = "";

    /**
     * @name WorkflowCaseTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WorkflowCaseTemplateId: number = 0;

    /**
     * @name WorkflowAlwaysCreatesCase
     * @servertype Boolean
     * @type {boolean}
     */
    WorkflowAlwaysCreatesCase: boolean = false;

    /**
     * @name WorkflowTimeoutSeconds
     * @servertype Int32 (nullable)
     * @type {number}
     */
    WorkflowTimeoutSeconds: number = 0;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Cargo
     * @servertype System.Object
     * @type {any}
     */
    Cargo: any = {};

    /**
     * @name Options
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Options: {[key: string]:  string} = {};

    /**
     * @name UtcOffset
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name Customer
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class CustomerEditViewModel {

    /**
     * @name CustomerId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CustomerId: number = 0;

    /**
     * @name ExternalCustomerId
     * @servertype String
     * @type {string}
     */
    ExternalCustomerId: string = "";

    /**
     * @name CustomerName
     * @servertype String
     * @type {string}
     */
    CustomerName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name MiddleName
     * @servertype String
     * @type {string}
     */
    MiddleName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name OtherName
     * @servertype String
     * @type {string}
     */
    OtherName: string = "";

    /**
     * @name CourtesyTitle
     * @servertype String
     * @type {string}
     */
    CourtesyTitle: string = "";

    /**
     * @name PostNominalTitles
     * @servertype String
     * @type {string}
     */
    PostNominalTitles: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name County
     * @description 
     * County.
     * @servertype String
     * @type {string}
     */
    County: string = "";

    /**
     * @name Country
     * @description 
     * Country.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Fax
     * @description 
     * Fax.
     * @servertype String
     * @type {string}
     */
    Fax: string = "";

    /**
     * @name HomePhone
     * @servertype String
     * @type {string}
     */
    HomePhone: string = "";

    /**
     * @name Cellular
     * @description 
     * Cellular.
     * @servertype String
     * @type {string}
     */
    Cellular: string = "";

    /**
     * @name AlternatePhone
     * @servertype String
     * @type {string}
     */
    AlternatePhone: string = "";

    /**
     * @name Extension
     * @description 
     * Extension.
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name AlternateEmail
     * @servertype String
     * @type {string}
     */
    AlternateEmail: string = "";

    /**
     * @name ImAddress
     * @servertype String
     * @type {string}
     */
    ImAddress: string = "";

    /**
     * @name ImNetwork
     * @servertype String
     * @type {string}
     */
    ImNetwork: string = "";

    /**
     * @name AlternateImAddress
     * @servertype String
     * @type {string}
     */
    AlternateImAddress: string = "";

    /**
     * @name AlternateImNetwork
     * @servertype String
     * @type {string}
     */
    AlternateImNetwork: string = "";

    /**
     * @name WebSite
     * @servertype String
     * @type {string}
     */
    WebSite: string = "";

    /**
     * @name FtpSite
     * @servertype String
     * @type {string}
     */
    FtpSite: string = "";

    /**
     * @name TaxId
     * @servertype String
     * @type {string}
     */
    TaxId: string = "";

    /**
     * @name TickerSymbol
     * @servertype String
     * @type {string}
     */
    TickerSymbol: string = "";

    /**
     * @name SicCode
     * @servertype String
     * @type {string}
     */
    SicCode: string = "";

    /**
     * @name Industry
     * @description 
     * Industry.
     * @servertype String
     * @type {string}
     */
    Industry: string = "";

    /**
     * @name ReferredBy
     * @servertype String
     * @type {string}
     */
    ReferredBy: string = "";

    /**
     * @name RepresentativeDirectoryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RepresentativeDirectoryId: number = 0;

    /**
     * @name RepresentativeDirectoryName
     * @servertype String
     * @type {string}
     */
    RepresentativeDirectoryName: string = "";

    /**
     * @name AgentId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentId: number = 0;

    /**
     * @name AgentName
     * @servertype String
     * @type {string}
     */
    AgentName: string = "";

    /**
     * @name WebLanguage
     * @servertype String
     * @type {string}
     */
    WebLanguage: string = "";

    /**
     * @name IvrLanguage
     * @servertype String
     * @type {string}
     */
    IvrLanguage: string = "";

    /**
     * @name TimeZoneId
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = "";

    /**
     * @name Salutation
     * @description 
     * Salutation for this contact.
     * @servertype String
     * @type {string}
     */
    Salutation: string = "";

    /**
     * @name AssistantName
     * @servertype String
     * @type {string}
     */
    AssistantName: string = "";

    /**
     * @name ManagerName
     * @servertype String
     * @type {string}
     */
    ManagerName: string = "";

    /**
     * @name SpouseName
     * @servertype String
     * @type {string}
     */
    SpouseName: string = "";

    /**
     * @name ChildrenNames
     * @servertype String
     * @type {string}
     */
    ChildrenNames: string = "";

    /**
     * @name DepartmentName
     * @servertype String
     * @type {string}
     */
    DepartmentName: string = "";

    /**
     * @name Office
     * @description 
     * Office.
     * @servertype String
     * @type {string}
     */
    Office: string = "";

    /**
     * @name Profession
     * @description 
     * Profession.
     * @servertype String
     * @type {string}
     */
    Profession: string = "";

    /**
     * @name Hobbies
     * @description 
     * Hobbies.
     * @servertype String
     * @type {string}
     */
    Hobbies: string = "";

    /**
     * @name Gender
     * @description 
     * Gender.  Possible values include:
     * M = Male
     * F = Female
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    Gender: string = "U";

    /**
     * @name BirthDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BirthDate: Date = null;

    /**
     * @name AnniversaryDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AnniversaryDate: Date = null;

    /**
     * @name NotifyAlert
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyAlert: boolean = false;

    /**
     * @name NotifyIssueChange
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChange: boolean = false;

    /**
     * @name NotifyIssueChangeGlobal
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChangeGlobal: boolean = false;

    /**
     * @name NotifyProductInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyProductInfo: boolean = false;

    /**
     * @name NotifyVendorInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyVendorInfo: boolean = false;

    /**
     * @name NotifyThirdPartyInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyThirdPartyInfo: boolean = false;

    /**
     * @name NotifyFormat
     * @servertype String
     * @type {string}
     */
    NotifyFormat: string = "";

    /**
     * @name NotifyStandardStartTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardStartTime: string = "";

    /**
     * @name NotifyStandardEndTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardEndTime: string = "";

    /**
     * @name NotifyUrgentStartTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentStartTime: string = "";

    /**
     * @name NotifyUrgentEndTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentEndTime: string = "";

    /**
     * @name Status
     * @description 
     * Status of the contact.  Possible values include: 
     * V = Invited (Contact was sent invitation to access the system but has not yet done so.)
     * P = Pending (Contact is pending until approved to be active.  While pending the contact has no access.)
     * A = Active (Contact is active.)
     * I = Inactive (Contact is inactive as determined by business rule with no special treatment.)
     * S = Suspended (Contact is suspended.)
     * C = Closed (Contact is closed or cancelled.)
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

    /**
     * @name StatusLocked
     * @servertype Boolean
     * @type {boolean}
     */
    StatusLocked: boolean = false;

    /**
     * @name CreateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CreateDateTime: Date = null;

    /**
     * @name SuspendDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendDateTime: Date = null;

    /**
     * @name CloseDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CloseDateTime: Date = null;

    /**
     * @name ReactivateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReactivateDateTime: Date = null;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name CurrencyFormat
     * @servertype String
     * @type {string}
     */
    CurrencyFormat: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Purchase Request Package
 */
export class PurchaseRequestPackageModel {

    /**
     * @name PackageId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name PackageType
     * @servertype String
     * @type {string}
     */
    PackageType: string = "";

    /**
     * @name PackageCode
     * @servertype String
     * @type {string}
     */
    PackageCode: string = "";

    /**
     * @name PackageClass
     * @servertype String
     * @type {string}
     */
    PackageClass: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name RetailAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    RetailAmount: number = 0;

    /**
     * @name BillingAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    BillingAmount: number = 0;

    /**
     * @name TaxAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxAmount: number = 0;

    /**
     * @name TaxScenarioId
     * @servertype String
     * @type {string}
     */
    TaxScenarioId: string = "";

    /**
     * @name ExternalAssociationId
     * @servertype String
     * @type {string}
     */
    ExternalAssociationId: string = "";

    /**
     * @name AuthorizationCode
     * @servertype String
     * @type {string}
     */
    AuthorizationCode: string = "";

    /**
     * @name ValidStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Payment
     * @servertype IB.Web.API.Models.v5.PurchaseRequestPaymentModel
     * @type {PurchaseRequestPaymentModel}
     */
    Payment: PurchaseRequestPaymentModel = null;

    /**
     * @name Options
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Options: {[key: string]:  string} = {};

}



/**
 * @name Purchase Request Payment
 */
export class PurchaseRequestPaymentModel {

    /**
     * @name Complimentary
     * @servertype Boolean
     * @type {boolean}
     */
    Complimentary: boolean = false;

    /**
     * @name ComplimentaryReason
     * @servertype String
     * @type {string}
     */
    ComplimentaryReason: string = "";

    /**
     * @name PaymentMethodId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentMethodId: number = 0;

    /**
     * @name CreditCard
     * @servertype IB.Web.API.Models.v5.PaymentMethodCreditCardEditViewModel
     * @type {m5pay.PaymentMethodCreditCardEditViewModel}
     */
    CreditCard: m5pay.PaymentMethodCreditCardEditViewModel = null;

    /**
     * @name ExternalPayment
     * @servertype IB.Web.API.Models.v5.PurchaseRequestExternalPaymentModel
     * @type {PurchaseRequestExternalPaymentModel}
     */
    ExternalPayment: PurchaseRequestExternalPaymentModel = null;

    /**
     * @name Transaction
     * @servertype IB.Web.API.Models.v5.PaymentTransactionEditViewModel
     * @type {m5pay.PaymentTransactionEditViewModel}
     */
    Transaction: m5pay.PaymentTransactionEditViewModel = null;

    /**
     * @name VoucherCode
     * @servertype String
     * @type {string}
     */
    VoucherCode: string = "";

    /**
     * @name VoucherPreapproved
     * @servertype Boolean
     * @type {boolean}
     */
    VoucherPreapproved: boolean = false;

    /**
     * @name PaymentProviderId
     * @servertype String
     * @type {string}
     */
    PaymentProviderId: string = "";

    /**
     * @name PaymentProviderSelector
     * @servertype String
     * @type {string}
     */
    PaymentProviderSelector: string = "";

    /**
     * @name PaymentTransactionGroupingId
     * @servertype String
     * @type {string}
     */
    PaymentTransactionGroupingId: string = "";

    /**
     * @name EmailReceipt
     * @servertype Boolean
     * @type {boolean}
     */
    EmailReceipt: boolean = false;

    /**
     * @name Options
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Options: {[key: string]:  string} = {};

}



/**
 * @name Purchase Request External Payment
 */
export class PurchaseRequestExternalPaymentModel {

    /**
     * @name PaymentProvider
     * @servertype String
     * @type {string}
     */
    PaymentProvider: string = "";

    /**
     * @name TransactionId
     * @servertype String
     * @type {string}
     */
    TransactionId: string = "";

    /**
     * @name PayerId
     * @servertype String
     * @type {string}
     */
    PayerId: string = "";

    /**
     * @name Token
     * @servertype String
     * @type {string}
     */
    Token: string = "";

    /**
     * @name PaymentType
     * @servertype String
     * @type {string}
     */
    PaymentType: string = "";

    /**
     * @name Options
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Options: {[key: string]:  string} = {};

}



/**
 * @name PayPal Authorization Response
 */
export class PayPalAuthorizationResponseModel {

    /**
     * @name PayPalPaymentId
     * @servertype String
     * @type {string}
     */
    PayPalPaymentId: string = "";

    /**
     * @name PayPalApprovalUrl
     * @servertype String
     * @type {string}
     */
    PayPalApprovalUrl: string = "";

    /**
     * @name ReturnUrl
     * @servertype String
     * @type {string}
     */
    ReturnUrl: string = "";

    /**
     * @name CancelUrl
     * @servertype String
     * @type {string}
     */
    CancelUrl: string = "";

    /**
     * @name PaymentProviderId
     * @servertype String
     * @type {string}
     */
    PaymentProviderId: string = "";

    /**
     * @name Cargo
     * @servertype System.Object
     * @type {any}
     */
    Cargo: any = {};

    /**
     * @name UtcOffset
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name Purchase Response
 */
export class PurchaseResponseModel {

    /**
     * @name ResponseCode
     * @servertype IB.Web.API.Models.v5.PurchaseResponseCode Enum
     * @type {PurchaseResponseCode}
     */
    ResponseCode: PurchaseResponseCode = null;

    /**
     * @name ResponseMessage
     * @servertype String
     * @type {string}
     */
    ResponseMessage: string = "";

    /**
     * @name TransactionId
     * @servertype String
     * @type {string}
     */
    TransactionId: string = "";

    /**
     * @name PurchaseCheckoutId
     * @servertype String
     * @type {string}
     */
    PurchaseCheckoutId: string = "";

    /**
     * @name CustomerId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CustomerId: number = 0;

    /**
     * @name BillingAccountId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = 0;

    /**
     * @name PaymentMethodId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentMethodId: number = 0;

    /**
     * @name PaymentMethod
     * @servertype String
     * @type {string}
     */
    PaymentMethod: string = "";

    /**
     * @name PaymentMethodDetail
     * @servertype String
     * @type {string}
     */
    PaymentMethodDetail: string = "";

    /**
     * @name PaymentTransactionId
     * @servertype String
     * @type {string}
     */
    PaymentTransactionId: string = "";

    /**
     * @name PaymentTransactionOrderReference
     * @servertype String
     * @type {string}
     */
    PaymentTransactionOrderReference: string = "";

    /**
     * @name Packages
     * @servertype PurchaseResponsePackageModel array
     * @type {PurchaseResponsePackageModel[]}
     */
    Packages: PurchaseResponsePackageModel[] = [];

    /**
     * @name ContactInformation
     * @servertype IB.Web.API.Models.v5.PurchaseResponseContactInformationModel
     * @type {PurchaseResponseContactInformationModel}
     */
    ContactInformation: PurchaseResponseContactInformationModel = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Cargo
     * @servertype System.Object
     * @type {any}
     */
    Cargo: any = {};

    /**
     * @name UtcOffset
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name PurchaseResponseCode Enum
 * @readonly
 * @enum {number}
 */
export enum PurchaseResponseCode {

    /**
     * Success
     */
    Success = 0,

    /**
     * Partial Success
     */
    PartialSuccess = 1,

    /**
     * Pending
     */
    Pending = 2,

    /**
     * Authorization Code Add Error
     */
    AuthorizationCodeAddError = 110,

    /**
     * Authorization Code Already Registered
     */
    AuthorizationCodeAlreadyRegistered = 120,

    /**
     * Authorization Code Creation Error
     */
    AuthorizationCodeCreationError = 130,

    /**
     * Authorization Code Valid Date Range Error
     */
    AuthorizationCodeValidDateRangeError = 140,

    /**
     * Package Occurrence Add Error
     */
    PackageOccurrenceAddError = 210,

    /**
     * Package Occurrence Attribute Error
     */
    PackageOccurrenceAttributeError = 220,

    /**
     * Package Not Found
     */
    PackageNotFound = 230,

    /**
     * Package Validation Error
     */
    PackageValidationError = 240,

    /**
     * Billing Account Add Error
     */
    BillingAccountAddError = 310,

    /**
     * Billing Account Not Found
     */
    BillingAccountNotFound = 320,

    /**
     * Billing Account Too Many Found
     */
    BillingAccountTooManyFound = 321,

    /**
     * Contact Add Error
     */
    ContactAddError = 410,

    /**
     * Contact Not Found
     */
    ContactNotFound = 420,

    /**
     * Contact Too Many Found
     */
    ContactTooManyFound = 421,

    /**
     * Payment Method Add Error
     */
    PaymentMethodAddError = 510,

    /**
     * Payment Method Not Found
     */
    PaymentMethodNotFound = 511,

    /**
     * Payment Method Encryption Tag Not Found
     */
    PaymentMethodEncryptionTagNotFound = 515,

    /**
     * Payment Method Encryption Error
     */
    PaymentMethodEncryptionError = 516,

    /**
     * Payment Method Missing Street Address
     */
    PaymentMethodMissingStreetAddress = 520,

    /**
     * Payment Method Missing City
     */
    PaymentMethodMissingCity = 530,

    /**
     * Payment Method Missing Postal Code
     */
    PaymentMethodMissingPostalCode = 540,

    /**
     * Payment Method Missing Card Number
     */
    PaymentMethodMissingCardNumber = 550,

    /**
     * Payment Method Missing Card Expiration
     */
    PaymentMethodMissingCardExpiration = 560,

    /**
     * Payment Method Missing Card Id
     */
    PaymentMethodMissingCardId = 570,

    /**
     * Payment Method Missing External Payment Provider Credential
     */
    PaymentMethodMissingExternalPaymentProviderCredential = 575,

    /**
     * Payment Method Card Number Not Valid
     */
    PaymentMethodCardNumberNotValid = 580,

    /**
     * Payment Method Card Expired
     */
    PaymentMethodCardExpired = 590,

    /**
     * Payment Provider Not Found
     */
    PaymentProviderNotFound = 601,

    /**
     * Payment Transaction Add Error
     */
    PaymentTransactionAddError = 610,

    /**
     * Payment Transaction Not Valid
     */
    PaymentTransactionNotValid = 615,

    /**
     * Payment Transaction Watch Error
     */
    PaymentTransactionWatchError = 620,

    /**
     * Payment Transaction Timeout
     */
    PaymentTransactionTimeout = 630,

    /**
     * Payment Transaciton Processing Error
     */
    PaymentTransacitonProcessingError = 640,

    /**
     * Payment Transaction Duplicated
     */
    PaymentTransactionDuplicated = 650,

    /**
     * Payment Transaction Card Declined
     */
    PaymentTransactionCardDeclined = 670,

    /**
     * Payment Transaction Card Do Not Honor
     */
    PaymentTransactionCardDoNotHonor = 671,

    /**
     * Payment Transaction Card Lost Or Stolen
     */
    PaymentTransactionCardLostOrStolen = 672,

    /**
     * Payment Transaction Failed
     */
    PaymentTransactionFailed = 680,

    /**
     * Payment Transaction Failed Avs
     */
    PaymentTransactionFailedAvs = 681,

    /**
     * Payment Transaction Failed Card Id
     */
    PaymentTransactionFailedCardId = 682,

    /**
     * Payment Transaction Failed Credit Limit
     */
    PaymentTransactionFailedCreditLimit = 683,

    /**
     * Payment Transaction Failed Invalid Information
     */
    PaymentTransactionFailedInvalidInformation = 684,

    /**
     * Payment Transaction Failed Invalid Card Number
     */
    PaymentTransactionFailedInvalidCardNumber = 685,

    /**
     * Payment Transaction Failed Invalid Expiration Date
     */
    PaymentTransactionFailedInvalidExpirationDate = 686,

    /**
     * Voucher Not Found
     */
    VoucherNotFound = 710,

    /**
     * Voucher On Hold
     */
    VoucherOnHold = 711,

    /**
     * Voucher Expired
     */
    VoucherExpired = 712,

    /**
     * Voucher Lapsed
     */
    VoucherLapsed = 713,

    /**
     * Voucher Suspended
     */
    VoucherSuspended = 714,

    /**
     * Vourcher Used
     */
    VourcherUsed = 715,

    /**
     * Voucher Status Unknown
     */
    VoucherStatusUnknown = 720,

    /**
     * Voucher Not Valid Iso Currency Code
     */
    VoucherNotValidIsoCurrencyCode = 721,

    /**
     * Voucher Not Valid Visibility
     */
    VoucherNotValidVisibility = 722,

    /**
     * Voucher Lot Not Pending Activation
     */
    VoucherLotNotPendingActivation = 730,

    /**
     * Purchase Request Validation Error
     */
    PurchaseRequestValidationError = 990,

    /**
     * Unknown
     */
    Unknown = -1

}



/**
 * @name Purchase Response Package
 */
export class PurchaseResponsePackageModel {

    /**
     * @name ResponseCode
     * @servertype IB.Web.API.Models.v5.PurchaseResponseCode Enum
     * @type {PurchaseResponseCode}
     */
    ResponseCode: PurchaseResponseCode = null;

    /**
     * @name ResponseMessage
     * @servertype String
     * @type {string}
     */
    ResponseMessage: string = "";

    /**
     * @name PackageOccurrenceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = 0;

    /**
     * @name PackageId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name PackageType
     * @servertype String
     * @type {string}
     */
    PackageType: string = "";

    /**
     * @name PackageCode
     * @servertype String
     * @type {string}
     */
    PackageCode: string = "";

    /**
     * @name PackageClass
     * @servertype String
     * @type {string}
     */
    PackageClass: string = "";

    /**
     * @name PaymentMethodId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentMethodId: number = 0;

    /**
     * @name PaymentMethod
     * @servertype String
     * @type {string}
     */
    PaymentMethod: string = "";

    /**
     * @name PaymentMethodDetail
     * @servertype String
     * @type {string}
     */
    PaymentMethodDetail: string = "";

    /**
     * @name PaymentTransactionId
     * @servertype String
     * @type {string}
     */
    PaymentTransactionId: string = "";

    /**
     * @name PaymentTransactionOrderReference
     * @servertype String
     * @type {string}
     */
    PaymentTransactionOrderReference: string = "";

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name RetailAmount
     * @servertype Double
     * @type {number}
     */
    RetailAmount: number = 0;

    /**
     * @name BillingAmount
     * @servertype Double
     * @type {number}
     */
    BillingAmount: number = 0;

    /**
     * @name TaxAmount
     * @servertype Double
     * @type {number}
     */
    TaxAmount: number = 0;

    /**
     * @name TaxScenarioId
     * @servertype String
     * @type {string}
     */
    TaxScenarioId: string = "";

    /**
     * @name AuthorizationCode
     * @servertype String
     * @type {string}
     */
    AuthorizationCode: string = "";

    /**
     * @name ValidStartDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

}



/**
 * @name Purchase Response Contact Information
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class PurchaseResponseContactInformationModel {

    /**
     * @name Source
     * @servertype String
     * @type {string}
     */
    Source: string = "";

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name ExternalId
     * @servertype String
     * @type {string}
     */
    ExternalId: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name Name
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name OtherName
     * @servertype String
     * @type {string}
     */
    OtherName: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name Country
     * @description 
     * Country.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

}



/**
 * @name CachePackageSignupEditViewModel
 * @description
 * The cache package table contains query ready information about packages, owners, attributes, etc.
 */
export class CachePackageSignupEditViewModel {

    /**
     * @name PackageOccurrenceId
     * @description 
     * The Package Occurrence Id uniquely identifies this package.
     * @servertype Int64
     * @type {number}
     */
    PackageOccurrenceId: number = null;

    /**
     * @name BillingAccountId
     * @description 
     * The Billing Account Id this package occurrence belongs to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name ContactId
     * @description 
     * The Contact Id this package occurrence belongs to.
     * @servertype String
     * @type {string}
     */
    ContactId: string = null;

    /**
     * @name ContactId2
     * @description 
     * The Contact Id 2 this package occurrence belongs to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId2: number = null;

    /**
     * @name ExternalContactId
     * @description 
     * External contact id is the unique contact id used to identify this contact in external systems.
     * @servertype String
     * @type {string}
     */
    ExternalContactId: string = null;

    /**
     * @name ContactName
     * @description 
     * Contact name.
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name FirstName
     * @description 
     * First name.
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @description 
     * Last name.
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name Country
     * @description 
     * Country.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name PackageId
     * @description 
     * The Package Id for this package occurrence.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = null;

    /**
     * @name PackageType
     * @description 
     * Package Type for this package occurrence.
     * @servertype String
     * @type {string}
     */
    PackageType: string = "";

    /**
     * @name PackageClass
     * @description 
     * Package Class for this package occurrence.
     * @servertype String
     * @type {string}
     */
    PackageClass: string = "";

    /**
     * @name PackageCode
     * @description 
     * Package Code for this package occurrence.
     * @servertype String
     * @type {string}
     */
    PackageCode: string = "";

    /**
     * @name ProductId
     * @description 
     * Product Id for product associated with this package occurrence (if any).
     * @servertype String
     * @type {string}
     */
    ProductId: string = "";

    /**
     * @name ProductName
     * @description 
     * Product Name for product associated with this package occurrence (if any).
     * @servertype String
     * @type {string}
     */
    ProductName: string = "";

    /**
     * @name EventDateTime
     * @description 
     * The event date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EventDateTime: Date = null;

    /**
     * @name ActivatedDateTime
     * @description 
     * The activated date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActivatedDateTime: Date = null;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name AssociationId
     * @description 
     * The Association Id for the association related to this package occurrence (if any).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssociationId: number = null;

    /**
     * @name AssociationType
     * @description 
     * The type of association.  Possible values include: 
     * EXT = Extension
     * TRE = Transferred Extension
     * ACC = Account Code
     * AUT = Authorization Code.
     * NET = Network Id
     * SUB = Subscriber Id
     * SID = Source Identifier
     * TID = Target Identifier
     * OTI = Original Target Identifier
     * OI1 = Other Identifier 1
     * OI2 = Other Identifier 2
     * SNE = Source Network Element
     * SGI = Source Port Group In
     * SPI = Source Port Number In
     * SGO = Source Port Group Out
     * SPO = Source Port Number Out
     * TNE = Target Network Element
     * TGI = Target Port Group In
     * TPI = Target Port Number In
     * TGO = Target Port Group Out
     * TPO = Target Port Number Out
     * @servertype String
     * @type {string}
     */
    AssociationType: string = "";

    /**
     * @name AssociationValue
     * @description 
     * The association value of type AssociationType.
     * @servertype String
     * @type {string}
     */
    AssociationValue: string = "";

    /**
     * @name PaymentMethod
     * @description 
     * Payment method for this package occurrence (if any).
     * @servertype String
     * @type {string}
     */
    PaymentMethod: string = "";

    /**
     * @name PaymentMethodDetail
     * @description 
     * Payment method detail for this package occurrence (if any).
     * @servertype String
     * @type {string}
     */
    PaymentMethodDetail: string = "";

    /**
     * @name PaymentMethodInformation
     * @description 
     * Payment method information for this package occurrence (if any).
     * @servertype String
     * @type {string}
     */
    PaymentMethodInformation: string = "";

    /**
     * @name VoucherCode
     * @description 
     * The voucher code associated with this package occurrence (if any).
     * @servertype String
     * @type {string}
     */
    VoucherCode: string = "";

    /**
     * @name VoucherId
     * @description 
     * Voucher Id uniquely identifies this voucher.  Voucher code is unique but only in the context of current vouchers.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VoucherId: number = null;

    /**
     * @name VoucherType
     * @description 
     * User defined value to specify what type of voucher this represents.
     * @servertype String
     * @type {string}
     */
    VoucherType: string = "";

    /**
     * @name PaymentMethodId
     * @description 
     * The id of the payment method.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentMethodId: number = null;

    /**
     * @name PaymentMethodType
     * @description 
     * The payment method type.  Possible values include: 
     * C = Credit Card
     * D = Debit Card
     * B = Bank Account (Checking)
     * S = Bank Account (Savings)
     * P = PayPal
     * A = Amazon
     * V = Visa Checkout
     * O = Other
     * Note that Visa Checkout is a wallet and the type is effectively the same as Credit Card but is assigned a unique type to
     * provide some distinction for reporting purposes.
     * @servertype String
     * @type {string}
     */
    PaymentMethodType: string = "C";

    /**
     * @name PaymentMethodCardTypeId
     * @description 
     * The name for the payment method card type (e.g. AmEx, MasterCard, etc.) see the Payment Method Card Type table.
     * @servertype String
     * @type {string}
     */
    PaymentMethodCardTypeId: string = null;

    /**
     * @name PaymentMethodTypeId
     * @description 
     * A known static identifier that represents this payment method card type in a non-volatile manner.  Possible values include:
     * 0 = Unknown
     * 101 = VISA
     * 201 = MasterCard
     * 301 = AmericanExpress
     * 401 = Discover
     * 501 = DinersClub
     * 502 = DinersClubCarteBlanche
     * 503 = DinersClubEnRoute
     * 504 = DinersClubInternational
     * 505 = DinersClubUnitedStatesAndCanada
     * 601 = JCB
     * 1201 = ChinaUnionPay
     * 1301 = InterPayment
     * 1401 = InstaPayment
     * 2201 = Maestro
     * 2301 = Dankort
     * 2401 = MIR
     * 2501 = Verve
     * 3101 = Troy
     * 3501 = BcCard
     * 6101 = Elo
     * 6201 = Aura
     * 70101 = UATP
     * 80101 = PayPal
     * 80201 = Amazon
     * 80301 = ApplePayCardTypeUnknown
     * 80401 = GooglePayCardTypeUnknown
     * 80501 = WeChatPay
     * 80601 = Alipay
     * 80701 = Venmo
     * 89101 = BankAccountChecking
     * 89201 = BankAccountSavings
     * 90101 = StoreCard
     * 91101 = Other
     * 92101 = Custom
     * @servertype Int32
     * @type {number}
     */
    PaymentMethodTypeId: number = 0;

    /**
     * @name AccountNumberReferenceSubset
     * @description 
     * The subset of the account number that can be used for a reference.  Typically the last four digits.
     * @servertype String
     * @type {string}
     */
    AccountNumberReferenceSubset: string = "";

    /**
     * @name PaymentRequestDateTime
     * @description 
     * The date and time the payment request was initially submitted.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    PaymentRequestDateTime: Date = null;

    /**
     * @name PaymentTransactionId
     * @description 
     * The id of the payment transaction within the monthly transaction table noted by the payment request date time.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PaymentTransactionId: number = null;

    /**
     * @name PaymentTransactionOrderReference
     * @description 
     * The order reference for the payment transaction.  
     * For Nubill Wallet this is the Nubill Wallet PaymentTransactionId and can be used to uniquely identify the transaction.  
     * For Amazon this is the AmazonOrderReferenceId.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionOrderReference: string = "";

    /**
     * @name PaymentTransactionPaymentId
     * @description 
     * The transaction payment id used by the payment provider to identify the payment.  
     * For PayPal this is the post-execution sale id.  
     * For Amazon this is the capture id.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionPaymentId: string = "";

    /**
     * @name IsoCurrencyCode
     * @description 
     * ISO Currency Code for this transaction.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name PaymentAmount
     * @description 
     * Amount of payment associated with the package occurrence (if any).
     * @servertype Double (nullable)
     * @type {number}
     */
    PaymentAmount: number = 0;

    /**
     * @name RetailAmount
     * @description 
     * The full retail value amount associated with the package occurrence prior to any discounts being applied.
     * @servertype Double (nullable)
     * @type {number}
     */
    RetailAmount: number = 0;

    /**
     * @name TaxAmount
     * @description 
     * The tax amount associated with the package occurrence.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxAmount: number = 0;

    /**
     * @name TaxInformation
     * @description 
     * A csv of tax information for the transaction.  This could include things like: 
     * 1. Taxes that are precalculated and then submitted with a transaction may include a tax scenario id that can be used to recreate
     * the same tax details for the transaction.
     * 2. A Taxable true/false indicator.
     * 3. The id of the tax configuration that was used.
     * 4. A list of tax matrix identifiers (pipe delimited) from the taxation engine.
     * 5. A list of result codes (pipe delimited) from the taxation engine.
     * 6. A list of simple tax rates (pipe delimited) used for calculating taxes (more complex scenarios found only in tax matrix
     * and/or logs).
     * 7. A list of tax display requirement flags (pipe delimited) used for displaying taxes.
     * For example: TaxScenarioId:1q8-u72-7sl-y9ze,Taxable:true,Config:1,Matrices:2240|39134|10101|1599242,ResultCodes:101|282|283,Rates:0.07,DisplayRequirements:O|O|P|O
     * @servertype String
     * @type {string}
     */
    TaxInformation: string = "";

    /**
     * @name Status
     * @description 
     * 0 = Nothing Synchronized.
     * 1 = Package Occurrence Synchronized.
     * 2 = Package Occurrence Attributes Synchronized.
     * 3 = Package Synchronized.
     * 4 = Association Synchronized.
     * 5 = Contact Synchronized.
     * 6 = Payment Synchronized.
     * 199 = Fully Synchronized.
     * @servertype Byte (nullable)
     * @type {number}
     */
    Status: number = 0;

    /**
     * @name LastUpdatedDateTime
     * @description 
     * The date and time when the row was last updated from source data.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUpdatedDateTime: Date = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name CacheSummaryDataEditViewModel
 * @description
 * The cache summary data table contains query ready summary data.
 */
export class CacheSummaryDataEditViewModel {

    /**
     * @name CacheSummaryDataId
     * @description 
     * Unique value that represents this cached summary data row.
     * @servertype Int64
     * @type {number}
     */
    CacheSummaryDataId: number = null;

    /**
     * @name CacheSummaryConfigurationId
     * @description 
     * The id of the configuration this summary data is part of.
     * @servertype Int64
     * @type {number}
     */
    CacheSummaryConfigurationId: number = null;

    /**
     * @name DateRangeType
     * @description 
     * Date range type for attributes that are storing date range based data.  Possible values include:
     * H = Hour
     * D = Day
     * W = Week
     * M = Month
     * Q = Quarter
     * Y = Year
     * A = All
     * C = Custom
     * N = None (Not Date Based)
     * @servertype String
     * @type {string}
     */
    DateRangeType: string = "N";

    /**
     * @name BeginningDateTime
     * @description 
     * The beginning date time of the date range.  This serves as the monthly table reference value for determining which data goes
     * in which monthly table so if the data is not date based this should be an as-of date as it is not optional.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BeginningDateTime: Date = null;

    /**
     * @name EndingDateTime
     * @description 
     * The ending date time of the date range.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EndingDateTime: Date = null;

    /**
     * @name SegmentValue01
     * @description 
     * User defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue01: string = "";

    /**
     * @name SegmentValue01Description
     * @description 
     * Description related to user defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue01Description: string = "";

    /**
     * @name SegmentValue02
     * @description 
     * User defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue02: string = "";

    /**
     * @name SegmentValue02Description
     * @description 
     * Description related to user defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue02Description: string = "";

    /**
     * @name SegmentValue03
     * @description 
     * User defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue03: string = "";

    /**
     * @name SegmentValue03Description
     * @description 
     * Description related to user defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue03Description: string = "";

    /**
     * @name SegmentValue04
     * @description 
     * User defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue04: string = "";

    /**
     * @name SegmentValue04Description
     * @description 
     * Description related to user defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue04Description: string = "";

    /**
     * @name SegmentValue05
     * @description 
     * User defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue05: string = "";

    /**
     * @name SegmentValue05Description
     * @description 
     * Description related to user defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue05Description: string = "";

    /**
     * @name SegmentValue06
     * @description 
     * User defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue06: string = "";

    /**
     * @name SegmentValue06Description
     * @description 
     * Description related to user defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue06Description: string = "";

    /**
     * @name SegmentValue07
     * @description 
     * User defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue07: string = "";

    /**
     * @name SegmentValue07Description
     * @description 
     * Description related to user defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue07Description: string = "";

    /**
     * @name SegmentValue08
     * @description 
     * User defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue08: string = "";

    /**
     * @name SegmentValue08Description
     * @description 
     * Description related to user defined value for segmenting data.
     * @servertype String
     * @type {string}
     */
    SegmentValue08Description: string = "";

    /**
     * @name Value001
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name Value011
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value011: string = "";

    /**
     * @name Value012
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value012: string = "";

    /**
     * @name Value013
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value013: string = "";

    /**
     * @name Value014
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value014: string = "";

    /**
     * @name Value015
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value015: string = "";

    /**
     * @name Value016
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value016: string = "";

    /**
     * @name Value017
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value017: string = "";

    /**
     * @name Value018
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value018: string = "";

    /**
     * @name Value019
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value019: string = "";

    /**
     * @name Value020
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype String
     * @type {string}
     */
    Value020: string = "";

    /**
     * @name Value021
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value021: number = 0;

    /**
     * @name Value022
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value022: number = 0;

    /**
     * @name Value023
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value023: number = 0;

    /**
     * @name Value024
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value024: number = 0;

    /**
     * @name Value025
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value025: number = 0;

    /**
     * @name Value026
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value026: number = 0;

    /**
     * @name Value027
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value027: number = 0;

    /**
     * @name Value028
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value028: number = 0;

    /**
     * @name Value029
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value029: number = 0;

    /**
     * @name Value030
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value030: number = 0;

    /**
     * @name Value031
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value031: number = 0;

    /**
     * @name Value032
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value032: number = 0;

    /**
     * @name Value033
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value033: number = 0;

    /**
     * @name Value034
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value034: number = 0;

    /**
     * @name Value035
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value035: number = 0;

    /**
     * @name Value036
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value036: number = 0;

    /**
     * @name Value037
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value037: number = 0;

    /**
     * @name Value038
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value038: number = 0;

    /**
     * @name Value039
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value039: number = 0;

    /**
     * @name Value040
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value040: number = 0;

    /**
     * @name Value041
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value041: number = 0;

    /**
     * @name Value042
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value042: number = 0;

    /**
     * @name Value043
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value043: number = 0;

    /**
     * @name Value044
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value044: number = 0;

    /**
     * @name Value045
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value045: number = 0;

    /**
     * @name Value046
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value046: number = 0;

    /**
     * @name Value047
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value047: number = 0;

    /**
     * @name Value048
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value048: number = 0;

    /**
     * @name Value049
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value049: number = 0;

    /**
     * @name Value050
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value050: number = 0;

    /**
     * @name Value051
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value051: number = 0;

    /**
     * @name Value052
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value052: number = 0;

    /**
     * @name Value053
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value053: number = 0;

    /**
     * @name Value054
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value054: number = 0;

    /**
     * @name Value055
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value055: number = 0;

    /**
     * @name Value056
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value056: number = 0;

    /**
     * @name Value057
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value057: number = 0;

    /**
     * @name Value058
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value058: number = 0;

    /**
     * @name Value059
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value059: number = 0;

    /**
     * @name Value060
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value060: number = 0;

    /**
     * @name Value061
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value061: number = 0;

    /**
     * @name Value062
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value062: number = 0;

    /**
     * @name Value063
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value063: number = 0;

    /**
     * @name Value064
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value064: number = 0;

    /**
     * @name Value065
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value065: number = 0;

    /**
     * @name Value066
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value066: number = 0;

    /**
     * @name Value067
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value067: number = 0;

    /**
     * @name Value068
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value068: number = 0;

    /**
     * @name Value069
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value069: number = 0;

    /**
     * @name Value070
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value070: number = 0;

    /**
     * @name Value071
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value071: number = 0;

    /**
     * @name Value072
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value072: number = 0;

    /**
     * @name Value073
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value073: number = 0;

    /**
     * @name Value074
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value074: number = 0;

    /**
     * @name Value075
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value075: number = 0;

    /**
     * @name Value076
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value076: number = 0;

    /**
     * @name Value077
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value077: number = 0;

    /**
     * @name Value078
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value078: number = 0;

    /**
     * @name Value079
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value079: number = 0;

    /**
     * @name Value080
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value080: number = 0;

    /**
     * @name Value081
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value081: number = 0;

    /**
     * @name Value082
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value082: number = 0;

    /**
     * @name Value083
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value083: number = 0;

    /**
     * @name Value084
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value084: number = 0;

    /**
     * @name Value085
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value085: number = 0;

    /**
     * @name Value086
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value086: number = 0;

    /**
     * @name Value087
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value087: number = 0;

    /**
     * @name Value088
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value088: number = 0;

    /**
     * @name Value089
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value089: number = 0;

    /**
     * @name Value090
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value090: number = 0;

    /**
     * @name Value091
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value091: number = 0;

    /**
     * @name Value092
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value092: number = 0;

    /**
     * @name Value093
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value093: number = 0;

    /**
     * @name Value094
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value094: number = 0;

    /**
     * @name Value095
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value095: number = 0;

    /**
     * @name Value096
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value096: number = 0;

    /**
     * @name Value097
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value097: number = 0;

    /**
     * @name Value098
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value098: number = 0;

    /**
     * @name Value099
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value099: number = 0;

    /**
     * @name Value100
     * @description 
     * A summary data value as defined by the summary configuration.
     * @servertype Double (nullable)
     * @type {number}
     */
    Value100: number = 0;

    /**
     * @name AsOfDateTime
     * @description 
     * The date and time when the row was last updated from source data.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AsOfDateTime: Date = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name CaseAccessEditViewModel
 * @description
 * The CaseAccess table contains list of entities that have some from of access to an case.  This table defines the scope of
 * access.  The actual access permissions are still maintained by the contact roles assigned.
 */
export class CaseAccessEditViewModel {

    /**
     * @name CaseAccessId
     * @description 
     * The id of the case access record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseAccessId: number = null;

    /**
     * @name CaseId
     * @description 
     * The id of the case this access belongs to.  May be null if access belongs to some other object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseId: number = null;

    /**
     * @name CaseTaskListId
     * @description 
     * The id of the task list this access belongs to.  May be null if access belongs to some other object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTaskListId: number = null;

    /**
     * @name CaseAccessRole
     * @description 
     * Case access role for this contact as defined by a system pick list.
     * @servertype String
     * @type {string}
     */
    CaseAccessRole: string = "";

    /**
     * @name ExternalCaseNumber
     * @description 
     * If the contact has their own case number for this case that is referenced here.
     * @servertype String
     * @type {string}
     */
    ExternalCaseNumber: string = "";

    /**
     * @name ContactId
     * @description 
     * The Contact Id with the specified notification, reminder, and access.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name BillingRate
     * @description 
     * The hourly billing rate for this person.
     * @servertype Double (nullable)
     * @type {number}
     */
    BillingRate: number = 0;

    /**
     * @name Budget
     * @description 
     * The budget for this person in fees or hours depending on the case budget type.
     * @servertype Double (nullable)
     * @type {number}
     */
    Budget: number = 0;

    /**
     * @name NotificationEntity
     * @description 
     * Non-contact entity that wishes to watch the case for notification of changes in an case.  No access is granted other than
     * receiving notifications.  For example: 
     * _MAILTO: + email address = email address to notify of changes in an case.
     * @servertype String
     * @type {string}
     */
    NotificationEntity: string = "";

    /**
     * @name NotificationChanges
     * @description 
     * True indicates the entity receives notification of all changes configured to trigger notification.
     * @servertype Boolean
     * @type {boolean}
     */
    NotificationChanges: boolean = false;

    /**
     * @name NotificationCorrespondence
     * @description 
     * True indicates the entity receives notification of correspondence.
     * @servertype Boolean
     * @type {boolean}
     */
    NotificationCorrespondence: boolean = false;

    /**
     * @name ReminderAll
     * @description 
     * True indicates the entity receives reminder notifications for all tasks not marked private.  Reminder intervals are defined
     * by the task.
     * @servertype Boolean
     * @type {boolean}
     */
    ReminderAll: boolean = false;

    /**
     * @name ScopeTaskAll
     * @description 
     * True indicates the contact receives scope to see all tasks not marked private including those not assigned to or assigned
     * by the contact.  This is only valid when a contact id has been provided.
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeTaskAll: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name CaseCorrespondenceRecipientEditViewModel
 * @description
 * The CaseCorrespondenceRecipient table contains recipients for case correspondence.  Correspondence with no recipients is
 * intended for everyone with case scope.
 */
export class CaseCorrespondenceRecipientEditViewModel {

    /**
     * @name CaseCorrespondenceRecipientId
     * @description 
     * The id of the case correspondence recipient.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseCorrespondenceRecipientId: number = null;

    /**
     * @name CaseCorrespondenceId
     * @description 
     * The id of the case correspondence the recipient belongs to.
     * @servertype Int64
     * @type {number}
     */
    CaseCorrespondenceId: number = null;

    /**
     * @name ContactId
     * @description 
     * The Contact Id that correspondence is addressed to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name HiddenRecipient
     * @description 
     * When true the recipient is hidden from other recipients like BCC on email.
     * @servertype Boolean
     * @type {boolean}
     */
    HiddenRecipient: boolean = false;

    /**
     * @name ReadDateTime
     * @description 
     * The date and time when the correspondence was read by the recipient.  Note that this is different than read confirmation
     * in that it is set without any act of confirmation on the part of the recipient.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReadDateTime: Date = null;

    /**
     * @name RepliedTo
     * @description 
     * When true the recipient replied to this correspondence.
     * @servertype Boolean
     * @type {boolean}
     */
    RepliedTo: boolean = false;

    /**
     * @name RequireReadConfirmation
     * @description 
     * When true the recipient must provide confirmation that correspondence has been read.  This is typically done by clicking
     * a button, link, keypress, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    RequireReadConfirmation: boolean = false;

    /**
     * @name ReadConfirmationDateTime
     * @description 
     * The date and time when the correspondence was marked as read.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReadConfirmationDateTime: Date = null;

    /**
     * @name LastReadReminderDateTime
     * @description 
     * The date and time when the last reminder was sent regarding this correspondence.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastReadReminderDateTime: Date = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name CaseCorrespondenceEditViewModel
 * @description
 * The CaseCorrespondence table contains correspondence between parties for cases.
 */
export class CaseCorrespondenceEditViewModel {

    /**
     * @name CaseCorrespondenceId
     * @description 
     * The id of the case correspondence.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseCorrespondenceId: number = null;

    /**
     * @name CaseId
     * @description 
     * The id of the case this correspondence belongs to.
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = null;

    /**
     * @name Method
     * @description 
     * The method of correspondence.  Possible values include:
     * U = Unknown
     * E = Email
     * W = Web
     * S = Social Media
     * O = Other
     * @servertype String
     * @type {string}
     */
    Method: string = "U";

    /**
     * @name MethodType
     * @description 
     * The type of correspondence method when needed.  For example, if the method is social media this might be the social media
     * type (e.g. twitter).
     * @servertype String
     * @type {string}
     */
    MethodType: string = "";

    /**
     * @name MethodCorrespondenceId
     * @description 
     * The id attached to the correspondence by the method.  For example, when the method is email this is the message id from the
     * email header.
     * @servertype String
     * @type {string}
     */
    MethodCorrespondenceId: string = "";

    /**
     * @name MethodReplyToCorrespondenceId
     * @description 
     * If the correspondence is in reply to another correspondence this is the id attached to the reply to correspondence by the
     * method.  For example, when the method is email this is the in reply to id from the email header.
     * @servertype String
     * @type {string}
     */
    MethodReplyToCorrespondenceId: string = "";

    /**
     * @name CorrespondenceFromContactId
     * @description 
     * The contact id of the contact the correspondence was from.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CorrespondenceFromContactId: number = null;

    /**
     * @name CorrespondenceFromContactName
     * @servertype String
     * @type {string}
     */
    CorrespondenceFromContactName: string = "";

    /**
     * @name CorrespondenceFrom
     * @description 
     * Who the correspondence was from.
     * @servertype String
     * @type {string}
     */
    CorrespondenceFrom: string = "";

    /**
     * @name CorrespondenceTo
     * @description 
     * Who the correspondence was to.
     * @servertype String
     * @type {string}
     */
    CorrespondenceTo: string = "";

    /**
     * @name CorrespondenceCc
     * @description 
     * Who the correspondence was CC'd to.
     * @servertype String
     * @type {string}
     */
    CorrespondenceCc: string = "";

    /**
     * @name Subject
     * @description 
     * Subject for the correspondence.  May not be relevant for cases which tend to be short and specific by nature but can be very
     * important for projects where correspondence can be on a variety of subjects.
     * @servertype String
     * @type {string}
     */
    Subject: string = "";

    /**
     * @name Correspondence
     * @description 
     * Actual text of the correspondence entered for the case.
     * @servertype String
     * @type {string}
     */
    Correspondence: string = "";

    /**
     * @name CorrespondenceAlternateFormat
     * @description 
     * Alternate format for the correspondence text (e.g. HTML).
     * @servertype String
     * @type {string}
     */
    CorrespondenceAlternateFormat: string = "";

    /**
     * @name HasAttachments
     * @description 
     * When true the correspondence has one or more attachments.
     * @servertype Boolean
     * @type {boolean}
     */
    HasAttachments: boolean = false;

    /**
     * @name SuppressNotification
     * @description 
     * When true suppress any notification that would normally be triggered by this correspondence.  This is normally flagged true
     * when the correspondence is being logged from an email or IM session where the parties are already aware of the correspondence
     * and do not need to be notified of new correspondence.
     * @servertype Boolean
     * @type {boolean}
     */
    SuppressNotification: boolean = false;

    /**
     * @name PublicCorrespondence
     * @description 
     * When true the correspondence is intended for everyone who has this case in their scope.  When false the CaseCorrespondenceRecipient
     * table contains the recipients.
     * @servertype Boolean
     * @type {boolean}
     */
    PublicCorrespondence: boolean = false;

    /**
     * @name ReplyToCaseCorrespondenceId
     * @description 
     * If this correspondence is in reply to another correspondence this is the id of the case correspondence this correspondence
     * is replying to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReplyToCaseCorrespondenceId: number = null;

    /**
     * @name CorrespondenceDateTime
     * @description 
     * The date and time when the correspondence was received or processed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CorrespondenceDateTime: Date = null;

    /**
     * @name ReadDateTime
     * @description 
     * The date and time when the correspondence was read by someone other than the sender.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReadDateTime: Date = null;

    /**
     * @name ReplyNeeded
     * @description 
     * When true the correspondence still needs a reply.  This can be turned off by replying to the correspondence or by manually
     * changing the flag when no reply is needed.
     * @servertype Boolean
     * @type {boolean}
     */
    ReplyNeeded: boolean = true;

    /**
     * @name Attachments
     * @servertype AssetListViewModel array
     * @type {AssetListViewModel[]}
     */
    Attachments: AssetListViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Asset List
 * @description
 * The Asset table contains asset text, links to resources like images, videos, web sites, downloads, etc.
 */
export class AssetListViewModel {

    /**
     * @name AssetId
     * @description 
     * The id of the asset.
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = null;

    /**
     * @name ExternalAssetId
     * @description 
     * An optional external asset id for this asset.  For child assets that fill specific roles for widgets these may be explicitly
     * named id values like 'template', 'properties-template', 'properties-template-partial'.
     * Likewise notification messages can have specific values that identify messages intended to replace the default messages for
     * certain scenarios like 'New User Welcome', 'Password Recovery', 'Case Assignment', etc.
     * @servertype String
     * @type {string}
     */
    ExternalAssetId: string = "";

    /**
     * @name Title
     * @description 
     * Title for this asset.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name ShortDescription
     * @description 
     * Short description for this asset.
     * @servertype String
     * @type {string}
     */
    ShortDescription: string = "";

    /**
     * @name ExternalUrl
     * @description 
     * For assets that are external assets (e.g. asset types web page and url) this is the URL for accessing that asset.  For external
     * files this is the UNC of the location of the file.
     * @servertype String
     * @type {string}
     */
    ExternalUrl: string = "";

    /**
     * @name FriendlyName
     * @description 
     * The friendly name of this asset.  For items being downloaded this download file name without file extension (the extension
     * is taken from the file type property).  For items not being downloaded this is used as a semantic URL so the URL for the
     * asset is more user friendly.
     * @servertype String
     * @type {string}
     */
    FriendlyName: string = "";

    /**
     * @name SystemAssetId
     * @description 
     * A system asset id for this asset.  Typically only assigned for system defined assets that need to be retrieved by this id.
     * @servertype String
     * @type {string}
     */
    SystemAssetId: string = "";

    /**
     * @name SystemAssetGroup
     * @description 
     * A system asset group for this asset.  These are used as system defined groups for picking assets that are valid for the specified
     * group context.  Possible values include:
     * Asset               = Asset (The asset is not part of a system asset group.)
     * Attachment          = Attachment (The asset is an attachment to the object specified as the owner or is an email attachment
     * for a NotificationMessage asset.)
     * NotificationMessage = Notification Message (The asset is a notification message.)
     * InvoiceMessage      = Invoice Message (The asset is an invoice message.)
     * Trigger             = Trigger (The asset is a trigger.)
     * Report              = Report (The asset is a report.)
     * ReportTemplate      = Report Template (The asset is a report template.)
     * Package             = Package (The asset is some type of package.)
     * Data                = Data (The asset is some type of data.)
     * Logo                = Logo (The asset is part of the logo group the file type and dimensions can be used to pick the desired
     * asset within this group.)
     * Article = Article (The asset is an article.)
     * ArticleGroup = Article Group (The asset is an table of contents for a group of articles.)
     * CodeSnippet = Code Snippet (The asset is a code snippet.)
     * WebPage             = Web Page (The asset is part of the web page group.)
     * @servertype String
     * @type {string}
     */
    SystemAssetGroup: string = "Asset";

    /**
     * @name AssetType
     * @description 
     * Asset type.  See the File Type and Content Type properties for more details.  Possible values include:
     * D = Document (The asset is a document file.)
     * I = Image (The asset is an image file.)
     * V = Video (The asset is a video file.)
     * A = Audio (The asset is an audio file.)
     * T = Text (The asset is text.)
     * O = Object (The asset is an object.)
     * S = Settings (The asset is a settings/configuration object.)
     * W = Web page (The asset is a link to a web page.)
     * P = Program (The asset is a program, app, or script.)
     * U = URL (This asset is a link to an external asset of unspecified type.)
     * * = Variables (The asset is a list of variables and values in the format Variable=Value with each on a new line.)
     * ~ = Virtual Organization (The asset is a virtual path for organizing and sharing assets.)
     * C = Collection (The asset is a collection of child assets using the Parent Asset Id property and/or shared assets as referenced
     * in the asset reference table.)
     * @servertype String
     * @type {string}
     */
    AssetType: string = "D";

    /**
     * @name FileType
     * @description 
     * For assets that are files this is the file type for this asset.  It is used as the extension for the file without leading
     * period.  For example: html, jpg, mp4, xml, json, etc.
     * For assets that are programs, apps, or scripts this is the type of program.  Since programs are tied to a particular context
     * (e.g. Webhooks) this setting can be utilized to limit which programs can be selected in a given context.  Possible values
     * include: 
     * TextBuilder = Text Builder (Program can be triggered when custom text needs to be built)
     * WebhookProcessor = Webhook Processor (Program can be triggered by a webhook)
     * Other = Other (Other Program)
     * @servertype String
     * @type {string}
     */
    FileType: string = "";

    /**
     * @name ContentType
     * @description 
     * The content type for this asset.  For example: text/html or image/jpg.
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name SizeBytes
     * @description 
     * Size (in bytes) of asset.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SizeBytes: number = 0;

    /**
     * @name SizeOther
     * @description 
     * Size of asset other than the number of bytes.  For example, pages for documents, seconds of duration for video and audio,
     * etc.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SizeOther: number = 0;

    /**
     * @name SizeInformation
     * @description 
     * Meta information about the size of the asset.  Since physical size can be represented by the Size Bytes property and page
     * count or duration can be represented by the Size Other property, this is typically meta about the asset size that cannot
     * otherwise be represented.
     * @servertype String
     * @type {string}
     */
    SizeInformation: string = "";

    /**
     * @name Width
     * @description 
     * The width of the asset.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Width: number = 0;

    /**
     * @name Height
     * @description 
     * The height of the asset.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Height: number = 0;

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the asset in when using a display ordered interface.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Priority
     * @description 
     * The priority for assets that need a priority.  A lower value has higher priority.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name AssetCategory
     * @description 
     * Category for this asset.  This is taken from system pick list.
     * @servertype String
     * @type {string}
     */
    AssetCategory: string = "";

    /**
     * @name AssetClass
     * @description 
     * Class for this asset.  This is taken from system pick list.
     * @servertype String
     * @type {string}
     */
    AssetClass: string = "";

    /**
     * @name AssetGroup
     * @description 
     * Group for this asset.  This is taken from system pick list.
     * @servertype String
     * @type {string}
     */
    AssetGroup: string = "";

    /**
     * @name AssetScope
     * @description 
     * Setting that indicates the scope this asset is applicable to.  
     * For assets that are part of the notification message system asset group the possible values include:
     * Email = Email Message
     * SMS = SMS Message
     * Phone = Phone Message/Script
     * Fax = Fax Message
     *   = Not Specified
     * For assets that are part of the attachment system asset group the possible values include:
     * Primary = Primary
     *   = Not Specified
     * For assets that are part of the trigger system asset group the possible values include:
     * Before = Trigger is executed before the event happens
     * After = Trigger is executed after the event happens
     * For assets that are part of the article system asset group this is an optional see also group name that will result in articles
     * that have the same group name being included in a see also list of articles.  See asset options for more information on see
     * also list creation.
     * For other assets this could be any custom value that makes sense for the asset.
     * @servertype String
     * @type {string}
     */
    AssetScope: string = "";

    /**
     * @name Visibility
     * @description 
     * Asset visibility.  Possible values include:
     * P = Public (The asset is publicly available.)
     * V = Private (Private assets are only available to the person who created the asset.)
     * I = Internal (Internal assets are public but only for directory users.)
     * H = Hidden (Hidden is used to remove access to an asset without deleting the asset.)
     * D = Draft (Draft indicates the asset is a work in progress and is not completed and is only available to the person who created
     * the asset.)
     * @servertype String
     * @type {string}
     */
    Visibility: string = "I";

    /**
     * @name Language
     * @description 
     * Language for this asset.  Stored in format lg or lg-lo where lg is the 2 byte iso language identifier and lo is the 2 byte
     * iso locale identifier.  For example, English may be stored as 'en' or 'en-US' or 'en-UK'.
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name VersionId
     * @description 
     * A guid-style id that is used to indicate that this is a version of an existing asset.  All assets that are versions of the
     * same asset have the same version id value including versions that are targeting different languages or file types (e.g. jpg,
     * png, etc.).  Assets that are the only version of an asset should typically have this value empty.
     * @servertype String
     * @type {string}
     */
    VersionId: string = "";

    /**
     * @name VersionNumber
     * @description 
     * An incrementing value that indicates the version number of this asset.  This can be used in conjunction with VersionId when
     * keeping multiple versions of an asset or as the way of versioning a structured asset (e.g. xml) where that structure may
     * be changed for future versions of the asset.  Versions that are the same revision but targeting different languages or file
     * types should typically have the same version number for identification purposes.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    VersionNumber: number = 0;

    /**
     * @name VersionCurrent
     * @description 
     * True indicates this asset is the current version of the asset.  See Version Id and Version Number.  Note that multiple assets
     * with the same version id can be marked as current when they are for different languages, file types, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    VersionCurrent: boolean = false;

    /**
     * @name VersionFinal
     * @description 
     * True indicates this asset is the final version of the asset.  See Version Id and Version Number.  Note that multiple assets
     * with the same version id can be marked as current when they are for different languages, file types, etc.  This can also
     * be used for non-versioned assets to flag that the asset as been finalized.
     * @servertype Boolean
     * @type {boolean}
     */
    VersionFinal: boolean = false;

    /**
     * @name VersionInventoryTypeId
     * @description 
     * If this asset is linked to versions of a specific inventory type this the inventory type id it is linked to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VersionInventoryTypeId: number = null;

    /**
     * @name VersionStarting
     * @description 
     * For assets tied to versions this is the starting version that this asset is valid for.  When this is blank but an ending
     * version is specified than this is considered valid for all versions though that ending version.
     * @servertype String
     * @type {string}
     */
    VersionStarting: string = "";

    /**
     * @name VersionEnding
     * @description 
     * For assets tied to versions this is the ending version that this asset is valid for.  When this is blank but a staring version
     * is specified than this is considered valid for all versions starting with the starting version.
     * @servertype String
     * @type {string}
     */
    VersionEnding: string = "";

    /**
     * @name NeedsReview
     * @description 
     * True indicates this asset was flagged as needing review.  That could be because of a broken link, content that was tagged
     * as inappropriate, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    NeedsReview: boolean = false;

    /**
     * @name ReviewedDateTime
     * @description 
     * The date and time when the asset was last reviewed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReviewedDateTime: Date = null;

    /**
     * @name ReviewComments
     * @description 
     * Comments entered about the review.  This can be used to explain why something was flagged for review or to add notes about
     * the review.
     * @servertype String
     * @type {string}
     */
    ReviewComments: string = "";

    /**
     * @name NextReviewDateTime
     * @description 
     * The date and time when the asset should next be reviewed.  NULL means no further review is needed.  This is frequently used
     * when the asset may become stale, out-of-date, or otherwise less useful over time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NextReviewDateTime: Date = null;

    /**
     * @name NextReviewContactId
     * @description 
     * The Contact Id that is assigned to perform the next review.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NextReviewContactId: number = null;

    /**
     * @name NextReviewContactName
     * @servertype String
     * @type {string}
     */
    NextReviewContactName: string = "";

    /**
     * @name ApprovedDateTime
     * @description 
     * The date and time when the asset was approved.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ApprovedDateTime: Date = null;

    /**
     * @name HitCount
     * @description 
     * Number of times the asset was accessed.  This is typically only used for searchable assets.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HitCount: number = 0;

    /**
     * @name LastHitDateTime
     * @description 
     * The date and time when the asset was last accessed.  This is typically only used for searchable assets.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastHitDateTime: Date = null;

    /**
     * @name PromptHelped
     * @description 
     * True indicates the user is prompted to indicate if this asset helped (yes, no, n/a).
     * @servertype Boolean
     * @type {boolean}
     */
    PromptHelped: boolean = false;

    /**
     * @name PromptRating
     * @description 
     * True indicates the user is prompted to provide a 1-5 rating for this asset.
     * @servertype Boolean
     * @type {boolean}
     */
    PromptRating: boolean = false;

    /**
     * @name PromptFeedback
     * @description 
     * True indicates the user is prompted to leave feedback regarding the asset.
     * @servertype Boolean
     * @type {boolean}
     */
    PromptFeedback: boolean = false;

    /**
     * @name AllowAnonymousFeedback
     * @description 
     * True indicates we accept anonymous feedback.  Some assets allow anonymous access but do not accept anonymous feedback.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousFeedback: boolean = false;

    /**
     * @name HelpedYes
     * @description 
     * Number of times user said the asset helped.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HelpedYes: number = 0;

    /**
     * @name HelpedYesPercent
     * @servertype Double
     * @type {number}
     */
    HelpedYesPercent: number = 0;

    /**
     * @name HelpedNo
     * @description 
     * Number of times user said the asset did not help.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HelpedNo: number = 0;

    /**
     * @name HelpedNoPercent
     * @servertype Double
     * @type {number}
     */
    HelpedNoPercent: number = 0;

    /**
     * @name HelpedNa
     * @description 
     * Number of times user said the asset was not applicable.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HelpedNa: number = 0;

    /**
     * @name HelpedNaPercent
     * @servertype Double
     * @type {number}
     */
    HelpedNaPercent: number = 0;

    /**
     * @name HelpedTotal
     * @servertype Int32
     * @type {number}
     */
    HelpedTotal: number = 0;

    /**
     * @name Rating01
     * @description 
     * Number of times user rated the asset as 1 on scale of 1-5.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating01: number = 0;

    /**
     * @name Rating01Percent
     * @servertype Double
     * @type {number}
     */
    Rating01Percent: number = 0;

    /**
     * @name Rating02
     * @description 
     * Number of times user rated the asset as 2 on scale of 1-5.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating02: number = 0;

    /**
     * @name Rating02Percent
     * @servertype Double
     * @type {number}
     */
    Rating02Percent: number = 0;

    /**
     * @name Rating03
     * @description 
     * Number of times user rated the asset as 3 on scale of 1-5.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating03: number = 0;

    /**
     * @name Rating03Percent
     * @servertype Double
     * @type {number}
     */
    Rating03Percent: number = 0;

    /**
     * @name Rating04
     * @description 
     * Number of times user rated the asset as 4 on scale of 1-5.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating04: number = 0;

    /**
     * @name Rating04Percent
     * @servertype Double
     * @type {number}
     */
    Rating04Percent: number = 0;

    /**
     * @name Rating05
     * @description 
     * Number of times user rated the asset as 5 on scale of 1-5.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating05: number = 0;

    /**
     * @name Rating05Percent
     * @servertype Double
     * @type {number}
     */
    Rating05Percent: number = 0;

    /**
     * @name RatingAverage
     * @servertype Double
     * @type {number}
     */
    RatingAverage: number = 0;

    /**
     * @name RatingTotal
     * @servertype Int32
     * @type {number}
     */
    RatingTotal: number = 0;

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) of this asset if the asset belongs to another resource.  This is most often used
     * for assets that are attachments to the resource stated in the owner resource type and id or for assets that are triggers
     * for the resource type stated here.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this asset.  For example, if the owner resource type is InventoryType
     * this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.  For assets that are triggers
     * this can be a CSV of event types that the trigger belongs to with possible values of: add,edit,delete
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name Value001
     * @description 
     * User defined value for this asset.
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description 
     * User defined value for this asset.
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description 
     * User defined value for this asset.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description 
     * User defined value for this asset.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description 
     * User defined value for this asset.
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @description 
     * User defined value for this asset.
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @description 
     * User defined value for this asset.
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @description 
     * User defined value for this asset.
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @description 
     * User defined value for this asset.
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @description 
     * User defined value for this asset.
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name AddedDateTime
     * @description 
     * The date and time when the row was added.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AddedDateTime: Date = new Date();

    /**
     * @name AddedByContactId
     * @description 
     * The contact id that represents who added this row.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AddedByContactId: number = null;

    /**
     * @name AddedByContactName
     * @servertype String
     * @type {string}
     */
    AddedByContactName: string = "";

    /**
     * @name LastEditedDateTime
     * @description 
     * The date and time when the row was last edited.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastEditedDateTime: Date = null;

}



/**
 * @name CaseContactSummaryViewModel
 */
export class CaseContactSummaryViewModel {

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name Grouping1
     * @servertype String
     * @type {string}
     */
    Grouping1: string = "";

    /**
     * @name Grouping2
     * @servertype String
     * @type {string}
     */
    Grouping2: string = "";

    /**
     * @name Grouping3
     * @servertype String
     * @type {string}
     */
    Grouping3: string = "";

    /**
     * @name Total
     * @servertype Int64
     * @type {number}
     */
    Total: number = 0;

    /**
     * @name Active
     * @servertype Int64
     * @type {number}
     */
    Active: number = 0;

    /**
     * @name Closed
     * @servertype Int64
     * @type {number}
     */
    Closed: number = 0;

    /**
     * @name ReviewInProgress
     * @servertype Int64
     * @type {number}
     */
    ReviewInProgress: number = 0;

    /**
     * @name Other
     * @servertype Int64
     * @type {number}
     */
    Other: number = 0;

    /**
     * @name PastDue
     * @servertype Int64
     * @type {number}
     */
    PastDue: number = 0;

}



/**
 * @name Article Group
 */
export class AssetArticleGroupEditViewModel {

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name RootContents
     * @servertype AssetArticleGroupContentEditViewModel array
     * @type {AssetArticleGroupContentEditViewModel[]}
     */
    RootContents: AssetArticleGroupContentEditViewModel[] = [];

    /**
     * @name Structure
     * @servertype AssetArticleGroupStructureEditViewModel array
     * @type {AssetArticleGroupStructureEditViewModel[]}
     */
    Structure: AssetArticleGroupStructureEditViewModel[] = [];

    /**
     * @name StructureContents
     * @servertype AssetArticleGroupContentEditViewModel array
     * @type {AssetArticleGroupContentEditViewModel[]}
     */
    StructureContents: AssetArticleGroupContentEditViewModel[] = [];

    /**
     * @name TableOfContentsCodeSnippetAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TableOfContentsCodeSnippetAssetId: number = 0;

    /**
     * @name TableOfContentsCodeSnippetAssetTitle
     * @servertype String
     * @type {string}
     */
    TableOfContentsCodeSnippetAssetTitle: string = "";

    /**
     * @name ChildContentLinkCodeSnippetAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ChildContentLinkCodeSnippetAssetId: number = 0;

    /**
     * @name ChildContentLinkCodeSnippetAssetTitle
     * @servertype String
     * @type {string}
     */
    ChildContentLinkCodeSnippetAssetTitle: string = "";

    /**
     * @name IncludeArticleSeeAlsoItems
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeArticleSeeAlsoItems: boolean = false;

    /**
     * @name IncludeArticleSeeAlsoItemsNotIncludedInArticleGroup
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeArticleSeeAlsoItemsNotIncludedInArticleGroup: boolean = false;

    /**
     * @name IncludeIndex
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeIndex: boolean = false;

    /**
     * @name IncludeBreadcrumbs
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeBreadcrumbs: boolean = false;

    /**
     * @name TableOfContentsPicker
     * @servertype Boolean
     * @type {boolean}
     */
    TableOfContentsPicker: boolean = false;

    /**
     * @name VersionPicker
     * @servertype Boolean
     * @type {boolean}
     */
    VersionPicker: boolean = false;

    /**
     * @name VersionInventoryTypeId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VersionInventoryTypeId: number = 0;

    /**
     * @name VersionMaximumAgeDays
     * @servertype Int32
     * @type {number}
     */
    VersionMaximumAgeDays: number = 0;

    /**
     * @name LanguagePicker
     * @servertype Boolean
     * @type {boolean}
     */
    LanguagePicker: boolean = false;

    /**
     * @name LanguageDefault
     * @servertype String
     * @type {string}
     */
    LanguageDefault: string = "";

    /**
     * @name LanguageOptions
     * @servertype string array
     * @type {string[]}
     */
    LanguageOptions: string[] = [];

    /**
     * @name NavHome
     * @servertype IB.Web.API.Models.v5.AssetArticleGroupNavEditViewModel
     * @type {AssetArticleGroupNavEditViewModel}
     */
    NavHome: AssetArticleGroupNavEditViewModel = null;

    /**
     * @name NavTableOfContents
     * @servertype IB.Web.API.Models.v5.AssetArticleGroupNavEditViewModel
     * @type {AssetArticleGroupNavEditViewModel}
     */
    NavTableOfContents: AssetArticleGroupNavEditViewModel = null;

    /**
     * @name NavIndex
     * @servertype IB.Web.API.Models.v5.AssetArticleGroupNavEditViewModel
     * @type {AssetArticleGroupNavEditViewModel}
     */
    NavIndex: AssetArticleGroupNavEditViewModel = null;

    /**
     * @name NavPrevious
     * @servertype IB.Web.API.Models.v5.AssetArticleGroupNavEditViewModel
     * @type {AssetArticleGroupNavEditViewModel}
     */
    NavPrevious: AssetArticleGroupNavEditViewModel = null;

    /**
     * @name NavNext
     * @servertype IB.Web.API.Models.v5.AssetArticleGroupNavEditViewModel
     * @type {AssetArticleGroupNavEditViewModel}
     */
    NavNext: AssetArticleGroupNavEditViewModel = null;

}



/**
 * @name Article Group Content
 */
export class AssetArticleGroupContentEditViewModel {

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name Slug
     * @servertype String
     * @type {string}
     */
    Slug: string = "";

    /**
     * @name ContentAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContentAssetId: number = 0;

    /**
     * @name ContentAssetTitle
     * @servertype String
     * @type {string}
     */
    ContentAssetTitle: string = "";

    /**
     * @name ContentAssetIsArticleGroup
     * @servertype Boolean
     * @type {boolean}
     */
    ContentAssetIsArticleGroup: boolean = false;

    /**
     * @name ContentType
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name ContentText
     * @servertype String
     * @type {string}
     */
    ContentText: string = "";

    /**
     * @name Contents
     * @servertype AssetArticleGroupContentEditViewModel array
     * @type {AssetArticleGroupContentEditViewModel[]}
     */
    Contents: AssetArticleGroupContentEditViewModel[] = [];

    /**
     * @name Structure
     * @servertype IB.Web.API.Models.v5.AssetArticleGroupStructureEditViewModel
     * @type {AssetArticleGroupStructureEditViewModel}
     */
    Structure: AssetArticleGroupStructureEditViewModel = null;

}



/**
 * @name Article Group Structure
 */
export class AssetArticleGroupStructureEditViewModel {

    /**
     * @name StructureLevel
     * @servertype Int32
     * @type {number}
     */
    StructureLevel: number = 0;

    /**
     * @name StructureName
     * @servertype String
     * @type {string}
     */
    StructureName: string = "";

    /**
     * @name StructureIcon
     * @servertype String
     * @type {string}
     */
    StructureIcon: string = "";

    /**
     * @name StructureTitleTemplate
     * @servertype String
     * @type {string}
     */
    StructureTitleTemplate: string = "";

    /**
     * @name Numbering
     * @servertype String
     * @type {string}
     */
    Numbering: string = "";

    /**
     * @name ChildContentLinkLocation
     * @servertype String
     * @type {string}
     */
    ChildContentLinkLocation: string = "";

    /**
     * @name ChildContentLinkCodeSnippetAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ChildContentLinkCodeSnippetAssetId: number = 0;

    /**
     * @name ChildContentLinkCodeSnippetAssetTitle
     * @servertype String
     * @type {string}
     */
    ChildContentLinkCodeSnippetAssetTitle: string = "";

    /**
     * @name ShowOnMenu
     * @servertype Boolean
     * @type {boolean}
     */
    ShowOnMenu: boolean = false;

    /**
     * @name ShowInTableOfContents
     * @servertype Boolean
     * @type {boolean}
     */
    ShowInTableOfContents: boolean = false;

    /**
     * @name ShowInChildContentLinks
     * @servertype Boolean
     * @type {boolean}
     */
    ShowInChildContentLinks: boolean = false;

}



/**
 * @name Article Group Nav
 */
export class AssetArticleGroupNavEditViewModel {

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name Label
     * @servertype String
     * @type {string}
     */
    Label: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

}



/**
 * @name AssetArticleGroupStructureChildContentLinkLocation Enum
 * @readonly
 * @enum {number}
 */
export enum AssetArticleGroupStructureChildContentLinkLocation {

    /**
     * None
     */
    None = 0,

    /**
     * Top
     */
    Top = 1,

    /**
     * Bottom
     */
    Bottom = 2,

    /**
     * Top And Bottm
     */
    TopAndBottm = 3,

    /**
     * After
     */
    After = 4,

    /**
     * After And Bottom
     */
    AfterAndBottom = 5

}



/**
 * @name Asset Options Article
 */
export class AssetOptionsArticleEditViewModel {

    /**
     * @name SeeAlsoCodeSnippetAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SeeAlsoCodeSnippetAssetId: number = 0;

    /**
     * @name SeeAlsoCodeSnippetAssetTitle
     * @servertype String
     * @type {string}
     */
    SeeAlsoCodeSnippetAssetTitle: string = "";

    /**
     * @name SeeAlsoUseArticleDisplayOrderWhenOrderNotExplicitlySet
     * @servertype Boolean
     * @type {boolean}
     */
    SeeAlsoUseArticleDisplayOrderWhenOrderNotExplicitlySet: boolean = false;

    /**
     * @name SeeAlsoItems
     * @servertype AssetOptionsSeeAlsoItemEditViewModel array
     * @type {AssetOptionsSeeAlsoItemEditViewModel[]}
     */
    SeeAlsoItems: AssetOptionsSeeAlsoItemEditViewModel[] = [];

    /**
     * @name VersionStartingAllowedClosestCodeSnippetAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VersionStartingAllowedClosestCodeSnippetAssetId: number = 0;

    /**
     * @name VersionStartingAllowedClosestCodeSnippetAssetTitle
     * @servertype String
     * @type {string}
     */
    VersionStartingAllowedClosestCodeSnippetAssetTitle: string = "";

    /**
     * @name VersionEndingAllowedClosestCodeSnippetAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VersionEndingAllowedClosestCodeSnippetAssetId: number = 0;

    /**
     * @name VersionEndingAllowedClosestCodeSnippetAssetTitle
     * @servertype String
     * @type {string}
     */
    VersionEndingAllowedClosestCodeSnippetAssetTitle: string = "";

}



/**
 * @name Asset Options See Also Item
 */
export class AssetOptionsSeeAlsoItemEditViewModel {

    /**
     * @name SeeAlsoGroupName
     * @servertype String
     * @type {string}
     */
    SeeAlsoGroupName: string = "";

    /**
     * @name SeeAlsoAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SeeAlsoAssetId: number = 0;

    /**
     * @name SeeAlsoAssetTitle
     * @servertype String
     * @type {string}
     */
    SeeAlsoAssetTitle: string = "";

}



/**
 * @name Asset Options See Also Result
 */
export class AssetOptionsSeeAlsoResultViewModel {

    /**
     * @name Id
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Id: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Slug
     * @servertype String
     * @type {string}
     */
    Slug: string = "";

}



/**
 * @name Inventory Type Version Batch Add
 */
export class InventoryTypeVersionBatchAddModel {

    /**
     * @name InventoryTypeIds
     * @servertype long array
     * @type {number[]}
     */
    InventoryTypeIds: number[] = [];

    /**
     * @name NumberOfVersionsToCreate
     * @servertype Int32
     * @type {number}
     */
    NumberOfVersionsToCreate: number = 0;

    /**
     * @name ReleaseType
     * @servertype String
     * @type {string}
     */
    ReleaseType: string = "";

    /**
     * @name CreateBasedOnLastReleaseDate
     * @servertype Boolean
     * @type {boolean}
     */
    CreateBasedOnLastReleaseDate: boolean = false;

}



/**
 * @name ObjectStatusEditViewModel
 * @description
 * The object status table contains information about the status of various objects in the database.
 */
export class ObjectStatusEditViewModel {

    /**
     * @name ObjectStatusId
     * @description 
     * Object Status Id uniquely identifies this object status record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ObjectStatusId: number = null;

    /**
     * @name Description
     * @description 
     * A description for this object status record.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name StatusType
     * @description 
     * Flag that indicates what type of status entry this is.  Possible values include: 
     * T = Trace
     * I = Info
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    StatusType: string = "I";

    /**
     * @name StatusCode
     * @description 
     * A code about this status item that uniquely identifies the information, warning, or error status in the context of the regarding
     * resource type.  For example, if this is regarding resource type 'Asset' the status code might be 'Spelling', 'Bad Link',
     * etc.
     * @servertype String
     * @type {string}
     */
    StatusCode: string = "";

    /**
     * @name StatusAsOfDateTime
     * @description 
     * The as-of date and time for this status item.  An as-of time that is newer than the resolved time may indicate the issue
     * was not properly or fully resolved.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StatusAsOfDateTime: Date = new Date();

    /**
     * @name Information
     * @description 
     * Information about the status item sufficient to explain the entry.
     * @servertype String
     * @type {string}
     */
    Information: string = "";

    /**
     * @name Detail
     * @description 
     * Detail beyond that reported in information.  This may be structured data (e.g. json) in a format helpful for the context
     * of the resource type and status code.
     * @servertype String
     * @type {string}
     */
    Detail: string = "";

    /**
     * @name Property01
     * @description 
     * A property used with its corresponding value to hold information about this status item.
     * @servertype String
     * @type {string}
     */
    Property01: string = "";

    /**
     * @name Value01
     * @description 
     * A value used with its corresponding property for information about this status item.
     * @servertype String
     * @type {string}
     */
    Value01: string = "";

    /**
     * @name Property02
     * @description 
     * A property used with its corresponding value to hold information about this status item.
     * @servertype String
     * @type {string}
     */
    Property02: string = "";

    /**
     * @name Value02
     * @description 
     * A value used with its corresponding property for information about this status item.
     * @servertype String
     * @type {string}
     */
    Value02: string = "";

    /**
     * @name Property03
     * @description 
     * A property used with its corresponding value to hold information about this status item.
     * @servertype String
     * @type {string}
     */
    Property03: string = "";

    /**
     * @name Value03
     * @description 
     * A value used with its corresponding property for information about this status item.
     * @servertype String
     * @type {string}
     */
    Value03: string = "";

    /**
     * @name Property04
     * @description 
     * A property used with its corresponding value to hold information about this status item.
     * @servertype String
     * @type {string}
     */
    Property04: string = "";

    /**
     * @name Value04
     * @description 
     * A value used with its corresponding property for information about this status item.
     * @servertype String
     * @type {string}
     */
    Value04: string = "";

    /**
     * @name Property05
     * @description 
     * A property used with its corresponding value to hold information about this status item.
     * @servertype String
     * @type {string}
     */
    Property05: string = "";

    /**
     * @name Value05
     * @description 
     * A value used with its corresponding property for information about this status item.
     * @servertype String
     * @type {string}
     */
    Value05: string = "";

    /**
     * @name Property06
     * @description 
     * A property used with its corresponding value to hold information about this status item.
     * @servertype String
     * @type {string}
     */
    Property06: string = "";

    /**
     * @name Value06
     * @description 
     * A value used with its corresponding property for information about this status item.
     * @servertype String
     * @type {string}
     */
    Value06: string = "";

    /**
     * @name Property07
     * @description 
     * A property used with its corresponding value to hold information about this status item.
     * @servertype String
     * @type {string}
     */
    Property07: string = "";

    /**
     * @name Value07
     * @description 
     * A value used with its corresponding property for information about this status item.
     * @servertype String
     * @type {string}
     */
    Value07: string = "";

    /**
     * @name Property08
     * @description 
     * A property used with its corresponding value to hold information about this status item.
     * @servertype String
     * @type {string}
     */
    Property08: string = "";

    /**
     * @name Value08
     * @description 
     * A value used with its corresponding property for information about this status item.
     * @servertype String
     * @type {string}
     */
    Value08: string = "";

    /**
     * @name Property09
     * @description 
     * A property used with its corresponding value to hold information about this status item.
     * @servertype String
     * @type {string}
     */
    Property09: string = "";

    /**
     * @name Value09
     * @description 
     * A value used with its corresponding property for information about this status item.
     * @servertype String
     * @type {string}
     */
    Value09: string = "";

    /**
     * @name Property10
     * @description 
     * A property used with its corresponding value to hold information about this status item.
     * @servertype String
     * @type {string}
     */
    Property10: string = "";

    /**
     * @name Value10
     * @description 
     * A value used with its corresponding property for information about this status item.
     * @servertype String
     * @type {string}
     */
    Value10: string = "";

    /**
     * @name RegardingResourceType
     * @description 
     * The resource type (e.g. table, etc.) this status is regarding.
     * @servertype String
     * @type {string}
     */
    RegardingResourceType: string = "";

    /**
     * @name RegardingResourceId
     * @description 
     * The resource id is the key of the resource type this status is regarding.  For example, if the regarding resource type is
     * InventoryType this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RegardingResourceId: number = 0;

    /**
     * @name RegardingResourceId2
     * @description 
     * The resource id 2 is the key of the resource type this status is regarding when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    RegardingResourceId2: string = "";

    /**
     * @name RegardingResourceScope
     * @description 
     * An optional scope about the resource this status is regarding.  For example, a resource type of assets might be limited to
     * a scope of articles.
     * @servertype String
     * @type {string}
     */
    RegardingResourceScope: string = "";

    /**
     * @name RegardingResourceDescription
     * @description 
     * The description of the resource this status is regarding.
     * @servertype String
     * @type {string}
     */
    RegardingResourceDescription: string = "";

    /**
     * @name ResolvedDateTime
     * @description 
     * The date and time when this was resolved.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ResolvedDateTime: Date = null;

    /**
     * @name ResolvedByContactId
     * @description 
     * The contact id that represents who resolved this item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ResolvedByContactId: number = null;

    /**
     * @name ResolvedByContactName
     * @servertype String
     * @type {string}
     */
    ResolvedByContactName: string = "";

    /**
     * @name ResolvedComments
     * @description 
     * Comments entered regarding the resolution process.
     * @servertype String
     * @type {string}
     */
    ResolvedComments: string = "";

    /**
     * @name IgnoredDateTime
     * @description 
     * The date and time when this was ignored.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    IgnoredDateTime: Date = null;

    /**
     * @name IgnoredByContactId
     * @description 
     * The contact id that represents who ignored this item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    IgnoredByContactId: number = null;

    /**
     * @name IgnoredByContactName
     * @servertype String
     * @type {string}
     */
    IgnoredByContactName: string = "";

    /**
     * @name IgnoredComments
     * @description 
     * Comments entered regarding the ignore process.
     * @servertype String
     * @type {string}
     */
    IgnoredComments: string = "";

    /**
     * @name ReviewedDateTime
     * @description 
     * The date and time when the item was reviewed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReviewedDateTime: Date = null;

    /**
     * @name ReviewedByContactId
     * @description 
     * The contact id that represents who last reviewed this item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReviewedByContactId: number = null;

    /**
     * @name ReviewedByContactName
     * @servertype String
     * @type {string}
     */
    ReviewedByContactName: string = "";

    /**
     * @name ReviewedComments
     * @description 
     * Comments entered about the review.  This can be used to explain why something was flagged for review or to add notes about
     * the review.
     * @servertype String
     * @type {string}
     */
    ReviewedComments: string = "";

    /**
     * @name Flags
     * @description 
     * A list of flags for this status.
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name Properties
     * @description 
     * Custom properties for this status beyond the user-defined values.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Task System Task Action
 */
export class TaskSystemTaskActionEditViewModel {

    /**
     * @name SystemTaskId
     * @servertype String
     * @type {string}
     */
    SystemTaskId: string = "";

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Version
     * @servertype Int32
     * @type {number}
     */
    Version: number = 0;

    /**
     * @name Value
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name Task System Task Action
 */
export class TaskSystemTaskParametersEditViewModel {

    /**
     * @name SystemTaskId
     * @servertype String
     * @type {string}
     */
    SystemTaskId: string = "";

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Version
     * @servertype Int32
     * @type {number}
     */
    Version: number = 0;

    /**
     * @name CanReassign
     * @servertype Boolean
     * @type {boolean}
     */
    CanReassign: boolean = false;

    /**
     * @name CanEdit
     * @servertype Boolean
     * @type {boolean}
     */
    CanEdit: boolean = false;

    /**
     * @name CanCopy
     * @servertype Boolean
     * @type {boolean}
     */
    CanCopy: boolean = false;

    /**
     * @name CanChooseCompletionDate
     * @servertype Boolean
     * @type {boolean}
     */
    CanChooseCompletionDate: boolean = false;

    /**
     * @name CanDelete
     * @servertype Boolean
     * @type {boolean}
     */
    CanDelete: boolean = false;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name Task System Task Action
 */
export class TaskSystemTaskResultsEditViewModel {

    /**
     * @name SystemTaskId
     * @servertype String
     * @type {string}
     */
    SystemTaskId: string = "";

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Version
     * @servertype Int32
     * @type {number}
     */
    Version: number = 0;

    /**
     * @name ResultCode
     * @servertype Int32
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name Task List Status
 */
export class TaskListStatusViewModel {

    /**
     * @name TotalCount
     * @servertype Int32
     * @type {number}
     */
    TotalCount: number = 0;

    /**
     * @name TotalCountHighPriority
     * @servertype Int32
     * @type {number}
     */
    TotalCountHighPriority: number = 0;

    /**
     * @name TotalCountNotHighPriority
     * @servertype Int32
     * @type {number}
     */
    TotalCountNotHighPriority: number = 0;

    /**
     * @name PersonalCount
     * @servertype Int32
     * @type {number}
     */
    PersonalCount: number = 0;

    /**
     * @name SharedCount
     * @servertype Int32
     * @type {number}
     */
    SharedCount: number = 0;

    /**
     * @name LastUpdate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUpdate: Date = null;

}



/**
 * @name Case Template Layout
 */
export class CaseTemplateLayoutEditViewModel {

    /**
     * @name WhenCustomAttributeForm
     * @servertype IB.Web.API.Models.v5.CaseTemplateLayoutWhenCustomAttributeFormEditViewModel
     * @type {CaseTemplateLayoutWhenCustomAttributeFormEditViewModel}
     */
    WhenCustomAttributeForm: CaseTemplateLayoutWhenCustomAttributeFormEditViewModel = null;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name Case Template Layout When Custom Attribute Form
 */
export class CaseTemplateLayoutWhenCustomAttributeFormEditViewModel {

    /**
     * @name PositionTitle
     * @servertype String
     * @type {string}
     */
    PositionTitle: string = "";

    /**
     * @name PositionDescription
     * @servertype String
     * @type {string}
     */
    PositionDescription: string = "";

    /**
     * @name PositionNotes
     * @servertype String
     * @type {string}
     */
    PositionNotes: string = "";

    /**
     * @name PositionForm
     * @servertype String
     * @type {string}
     */
    PositionForm: string = "";

}



/**
 * @name Case List
 * @description
 * The Case table contains cases that need to be resolved.  Items that might be included in the Case table are things like trouble
 * tickets, work orders, bug reports, tasks lists, projects, etc.
 */
export class CaseListViewModel {

    /**
     * @name CaseId
     * @description 
     * Case Id uniquely identifies this case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseId: number = null;

    /**
     * @name CaseNumber
     * @description 
     * Case number uniquely identifies this case within the specified partition using a user friendly reference number.
     * @servertype Int64
     * @type {number}
     */
    CaseNumber: number = 0;

    /**
     * @name ExternalCaseNumber
     * @description 
     * Case number used in external case tracking system.
     * @servertype String
     * @type {string}
     */
    ExternalCaseNumber: string = "";

    /**
     * @name ContactCaseNumber
     * @description 
     * Case number used in contact case tracking system.
     * @servertype String
     * @type {string}
     */
    ContactCaseNumber: string = "";

    /**
     * @name ParentCaseId
     * @description 
     * If this case is a sub-case this is the id of the parent case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentCaseId: number = null;

    /**
     * @name ContactId
     * @description 
     * The Contact Id for this table is the contact this case is for.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name SiteContactId
     * @description 
     * The Contact Id that represents an actual site location, if any.  This is used for instances where the case is related to
     * a site or requires someone to do on-site work.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SiteContactId: number = null;

    /**
     * @name SiteContactName
     * @servertype String
     * @type {string}
     */
    SiteContactName: string = "";

    /**
     * @name OpenedByContactId
     * @description 
     * The Contact Id that represents who opened this case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OpenedByContactId: number = null;

    /**
     * @name OpenedByContactName
     * @servertype String
     * @type {string}
     */
    OpenedByContactName: string = "";

    /**
     * @name OwnedByContactId
     * @description 
     * The Contact Id that represents who owns this case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnedByContactId: number = null;

    /**
     * @name OwnedByContactName
     * @servertype String
     * @type {string}
     */
    OwnedByContactName: string = "";

    /**
     * @name AssignedToContactId
     * @description 
     * The Contact Id that the case is currently assigned to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssignedToContactId: number = null;

    /**
     * @name AssignedToContactName
     * @servertype String
     * @type {string}
     */
    AssignedToContactName: string = "";

    /**
     * @name SupervisorContactId
     * @description 
     * The Contact Id that has supervisor responsibility for this case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SupervisorContactId: number = null;

    /**
     * @name SupervisorContactName
     * @servertype String
     * @type {string}
     */
    SupervisorContactName: string = "";

    /**
     * @name SystemStatus
     * @description 
     * A system status indicator.  Unlike status which is user defined this provides an internal system status for the case.  Possible
     * values include:
     * A = Active
     * C = Closed with Indeterminate Outcome
     * S = Closed with Success Outcome
     * F = Closed with Failure Outcome
     * R = Review in Progress
     * O = Other
     * @servertype String
     * @type {string}
     */
    SystemStatus: string = "A";

    /**
     * @name Type
     * @description 
     * Type for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Category
     * @description 
     * Category for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name CaseGroup
     * @description 
     * Group for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    CaseGroup: string = "";

    /**
     * @name Priority
     * @description 
     * Priority for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Priority: string = "";

    /**
     * @name PriorityRanking
     * @description 
     * A priority ranking for this case.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PriorityRanking: number = 0;

    /**
     * @name Status
     * @description 
     * Current status for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name Progress
     * @description 
     * Current progress for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Progress: string = "";

    /**
     * @name PercentDone
     * @description 
     * Progress of this case stated as percent completed 0%-100%.
     * @servertype Byte (nullable)
     * @type {number}
     */
    PercentDone: number = 0;

    /**
     * @name Outcome
     * @description 
     * The outcome for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Outcome: string = "";

    /**
     * @name RiskLevel
     * @description 
     * Risk level of this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    RiskLevel: string = "";

    /**
     * @name EventDateTime
     * @description 
     * The date and time of the event that triggered this case.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EventDateTime: Date = null;

    /**
     * @name OpenedDateTime
     * @description 
     * The date and time when the case was opened.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    OpenedDateTime: Date = null;

    /**
     * @name StartDateTime
     * @description 
     * The date and time when the case is scheduled to start.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name CheckDateTime
     * @description 
     * The date and time when the case is checked.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CheckDateTime: Date = null;

    /**
     * @name DueDateTime
     * @description 
     * The date and time when the case is due to be closed by.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DueDateTime: Date = null;

    /**
     * @name EstimatedCloseDateTime
     * @description 
     * The date and time when the case is estimated to close.  This value can be combined with the ActualCloseDateTime field to
     * help identify problems with work flow or estimated work flow.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EstimatedCloseDateTime: Date = null;

    /**
     * @name ActualCloseDateTime
     * @description 
     * The date and time when the case actually closed.  This value can be combined with the EstimatedCloseDateTime field to help
     * identify problems with work flow or estimated work flow.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActualCloseDateTime: Date = null;

    /**
     * @name ReportDateTime
     * @description 
     * The date and time when the report for the case is issued (if any).
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReportDateTime: Date = null;

    /**
     * @name EstimatedTime
     * @description 
     * Estimated time (in minutes) needed to complete this case.  This value is an entered value or is computed by rolling up the
     * values from case tasks depending on the case template.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    EstimatedTime: number = 0;

    /**
     * @name ActualTime
     * @description 
     * Actual time (in minutes) needed to complete this case.  This value is an entered value or is computed by rolling up the values
     * from case tasks depending on the case template.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ActualTime: number = 0;

    /**
     * @name Title
     * @description 
     * Title for the case.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Description
     * @description 
     * Description of the case as provided by the person opening the case.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name PromptHelped
     * @description 
     * True indicates the user is prompted to indicate if this case helped (yes, no, n/a).
     * @servertype Boolean
     * @type {boolean}
     */
    PromptHelped: boolean = false;

    /**
     * @name PromptRating
     * @description 
     * True indicates the user is prompted to provide a 1-5 rating for this case.
     * @servertype Boolean
     * @type {boolean}
     */
    PromptRating: boolean = false;

    /**
     * @name PromptFeedback
     * @description 
     * True indicates the user is prompted to leave feedback regarding the case.
     * @servertype Boolean
     * @type {boolean}
     */
    PromptFeedback: boolean = false;

    /**
     * @name HelpedYes
     * @description 
     * Number of times user said case entry helped.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HelpedYes: number = 0;

    /**
     * @name HelpedYesPercent
     * @servertype Double
     * @type {number}
     */
    HelpedYesPercent: number = 0;

    /**
     * @name HelpedNo
     * @description 
     * Number of times user said case entry did not help.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HelpedNo: number = 0;

    /**
     * @name HelpedNoPercent
     * @servertype Double
     * @type {number}
     */
    HelpedNoPercent: number = 0;

    /**
     * @name HelpedNa
     * @description 
     * Number of times user said case entry was not applicable.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HelpedNa: number = 0;

    /**
     * @name HelpedNaPercent
     * @servertype Double
     * @type {number}
     */
    HelpedNaPercent: number = 0;

    /**
     * @name HelpedTotal
     * @servertype Int32
     * @type {number}
     */
    HelpedTotal: number = 0;

    /**
     * @name Rating01
     * @description 
     * Number of times user rated the case as 1 on scale of 1-5.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating01: number = 0;

    /**
     * @name Rating01Percent
     * @servertype Double
     * @type {number}
     */
    Rating01Percent: number = 0;

    /**
     * @name Rating02
     * @description 
     * Number of times user rated the case as 2 on scale of 1-5.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating02: number = 0;

    /**
     * @name Rating02Percent
     * @servertype Double
     * @type {number}
     */
    Rating02Percent: number = 0;

    /**
     * @name Rating03
     * @description 
     * Number of times user rated the case as 3 on scale of 1-5.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating03: number = 0;

    /**
     * @name Rating03Percent
     * @servertype Double
     * @type {number}
     */
    Rating03Percent: number = 0;

    /**
     * @name Rating04
     * @description 
     * Number of times user rated the case as 4 on scale of 1-5.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating04: number = 0;

    /**
     * @name Rating04Percent
     * @servertype Double
     * @type {number}
     */
    Rating04Percent: number = 0;

    /**
     * @name Rating05
     * @description 
     * Number of times user rated the case as 5 on scale of 1-5.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating05: number = 0;

    /**
     * @name Rating05Percent
     * @servertype Double
     * @type {number}
     */
    Rating05Percent: number = 0;

    /**
     * @name RatingAverage
     * @servertype Double
     * @type {number}
     */
    RatingAverage: number = 0;

    /**
     * @name RatingTotal
     * @servertype Int32
     * @type {number}
     */
    RatingTotal: number = 0;

    /**
     * @name Publish
     * @description 
     * When true the case is available to the public which is useful for building of a knowledgebase.  When this is set to false
     * the case is only available to the involved parties.
     * @servertype Boolean
     * @type {boolean}
     */
    Publish: boolean = false;

    /**
     * @name Faq
     * @description 
     * When true the case is listed in FAQ (Frequently Asked Question) listings  Note that Publish must also be true for this setting
     * to be useful.
     * @servertype Boolean
     * @type {boolean}
     */
    Faq: boolean = false;

    /**
     * @name PublishCategory
     * @description 
     * The publish or faq category for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    PublishCategory: string = "";

    /**
     * @name ReadCount
     * @description 
     * Number of times case entry was read.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ReadCount: number = 0;

    /**
     * @name LastReadDateTime
     * @description 
     * The date and time when the case was last read.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastReadDateTime: Date = null;

    /**
     * @name CorrespondenceReplyNeeded
     * @description 
     * When true the case has correspondence that still needs a reply.
     * @servertype Boolean
     * @type {boolean}
     */
    CorrespondenceReplyNeeded: boolean = false;

    /**
     * @name CaseOpenItemsApplied
     * @description 
     * True indicates the items specified to trigger on case open have been applied.
     * @servertype Boolean
     * @type {boolean}
     */
    CaseOpenItemsApplied: boolean = false;

    /**
     * @name CaseCloseItemsApplied
     * @description 
     * True indicates the items specified to trigger on case close have been applied.
     * @servertype Boolean
     * @type {boolean}
     */
    CaseCloseItemsApplied: boolean = false;

    /**
     * @name ContactIdNotified
     * @description 
     * True indicates the Contact Id has been notified of the resolution to this case.
     * @servertype Boolean
     * @type {boolean}
     */
    ContactIdNotified: boolean = false;

    /**
     * @name Sanitized
     * @description 
     * True indicates the case has been sanitized as dictated by the case template.
     * @servertype Boolean
     * @type {boolean}
     */
    Sanitized: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name CaseEditViewModel
 * @description
 * The Case table contains cases that need to be resolved.  Items that might be included in the Case table are things like trouble
 * tickets, work orders, bug reports, tasks lists, projects, etc.
 */
export class CaseEditViewModel {

    /**
     * @name CaseId
     * @description 
     * Case Id uniquely identifies this case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseId: number = null;

    /**
     * @name CaseNumber
     * @description 
     * Case number uniquely identifies this case within the specified partition using a user friendly reference number.
     * @servertype Int64
     * @type {number}
     */
    CaseNumber: number = 0;

    /**
     * @name ExternalCaseNumber
     * @description 
     * Case number used in external case tracking system.
     * @servertype String
     * @type {string}
     */
    ExternalCaseNumber: string = "";

    /**
     * @name ContactCaseNumber
     * @description 
     * Case number used in contact case tracking system.
     * @servertype String
     * @type {string}
     */
    ContactCaseNumber: string = "";

    /**
     * @name CaseTemplateId
     * @description 
     * The id of the case template used.
     * @servertype Int64
     * @type {number}
     */
    CaseTemplateId: number = null;

    /**
     * @name ParentCaseId
     * @description 
     * If this case is a sub-case this is the id of the parent case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentCaseId: number = null;

    /**
     * @name ContactId
     * @description 
     * The Contact Id for this table is the contact this case is for.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name SiteContactId
     * @description 
     * The Contact Id that represents an actual site location, if any.  This is used for instances where the case is related to
     * a site or requires someone to do on-site work.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SiteContactId: number = null;

    /**
     * @name SiteContactName
     * @servertype String
     * @type {string}
     */
    SiteContactName: string = "";

    /**
     * @name OpenedByContactId
     * @description 
     * The Contact Id that represents who opened this case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OpenedByContactId: number = null;

    /**
     * @name OpenedByContactName
     * @servertype String
     * @type {string}
     */
    OpenedByContactName: string = "";

    /**
     * @name OwnedByContactId
     * @description 
     * The Contact Id that represents who owns this case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnedByContactId: number = null;

    /**
     * @name OwnedByContactName
     * @servertype String
     * @type {string}
     */
    OwnedByContactName: string = "";

    /**
     * @name AssignedToContactId
     * @description 
     * The Contact Id that the case is currently assigned to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssignedToContactId: number = null;

    /**
     * @name AssignedToContactName
     * @servertype String
     * @type {string}
     */
    AssignedToContactName: string = "";

    /**
     * @name SupervisorContactId
     * @description 
     * The Contact Id that has supervisor responsibility for this case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SupervisorContactId: number = null;

    /**
     * @name SupervisorContactName
     * @servertype String
     * @type {string}
     */
    SupervisorContactName: string = "";

    /**
     * @name SystemStatus
     * @description 
     * A system status indicator.  Unlike status which is user defined this provides an internal system status for the case.  Possible
     * values include:
     * A = Active
     * C = Closed with Indeterminate Outcome
     * S = Closed with Success Outcome
     * F = Closed with Failure Outcome
     * R = Review in Progress
     * O = Other
     * @servertype String
     * @type {string}
     */
    SystemStatus: string = "A";

    /**
     * @name Type
     * @description 
     * Type for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Category
     * @description 
     * Category for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name CaseGroup
     * @description 
     * Group for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    CaseGroup: string = "";

    /**
     * @name Priority
     * @description 
     * Priority for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Priority: string = "";

    /**
     * @name PriorityRanking
     * @description 
     * A priority ranking for this case.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PriorityRanking: number = 0;

    /**
     * @name Status
     * @description 
     * Current status for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name Progress
     * @description 
     * Current progress for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Progress: string = "";

    /**
     * @name PercentDone
     * @description 
     * Progress of this case stated as percent completed 0%-100%.
     * @servertype Byte (nullable)
     * @type {number}
     */
    PercentDone: number = 0;

    /**
     * @name Outcome
     * @description 
     * The outcome for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Outcome: string = "";

    /**
     * @name RiskLevel
     * @description 
     * Risk level of this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    RiskLevel: string = "";

    /**
     * @name RegardingResourceType
     * @description 
     * The resource type (e.g. table, etc.) this case is regarding (if any).
     * @servertype String
     * @type {string}
     */
    RegardingResourceType: string = "";

    /**
     * @name RegardingResourceId
     * @description 
     * The resource id is the key of the resource type this case is regarding (if any).  For example, if the regarding resource
     * type is InventoryType this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RegardingResourceId: number = 0;

    /**
     * @name RegardingResourceId2
     * @description 
     * The resource id 2 is the key of the resource type this case is regarding (if any) when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    RegardingResourceId2: string = "";

    /**
     * @name RegardingResourceAssignedByContactId
     * @description 
     * The Contact Id that assigned the case to the regarding resource.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RegardingResourceAssignedByContactId: number = null;

    /**
     * @name RegardingResourceAssignedByContactName
     * @servertype String
     * @type {string}
     */
    RegardingResourceAssignedByContactName: string = "";

    /**
     * @name RegardingResourceAssignedDateTime
     * @description 
     * The date and time the regarding resource was assigned.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RegardingResourceAssignedDateTime: Date = null;

    /**
     * @name ReportedVersion
     * @description 
     * The version number this case was reported for.
     * @servertype String
     * @type {string}
     */
    ReportedVersion: string = "";

    /**
     * @name IdentifiedStartingVersion
     * @description 
     * The starting version number that was identified as being affected.
     * @servertype String
     * @type {string}
     */
    IdentifiedStartingVersion: string = "";

    /**
     * @name IdentifiedEndingVersion
     * @description 
     * The ending version number that was identified as being affected.
     * @servertype String
     * @type {string}
     */
    IdentifiedEndingVersion: string = "";

    /**
     * @name ResolvedVersion
     * @description 
     * The version number that resolved this case.
     * @servertype String
     * @type {string}
     */
    ResolvedVersion: string = "";

    /**
     * @name EventDateTime
     * @description 
     * The date and time of the event that triggered this case.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EventDateTime: Date = null;

    /**
     * @name OpenedDateTime
     * @description 
     * The date and time when the case was opened.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    OpenedDateTime: Date = null;

    /**
     * @name StartDateTime
     * @description 
     * The date and time when the case is scheduled to start.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name CheckDateTime
     * @description 
     * The date and time when the case is checked.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CheckDateTime: Date = null;

    /**
     * @name DueDateTime
     * @description 
     * The date and time when the case is due to be closed by.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DueDateTime: Date = null;

    /**
     * @name EstimatedCloseDateTime
     * @description 
     * The date and time when the case is estimated to close.  This value can be combined with the ActualCloseDateTime field to
     * help identify problems with work flow or estimated work flow.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EstimatedCloseDateTime: Date = null;

    /**
     * @name ActualCloseDateTime
     * @description 
     * The date and time when the case actually closed.  This value can be combined with the EstimatedCloseDateTime field to help
     * identify problems with work flow or estimated work flow.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActualCloseDateTime: Date = null;

    /**
     * @name ReportDateTime
     * @description 
     * The date and time when the report for the case is issued (if any).
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReportDateTime: Date = null;

    /**
     * @name EstimatedTime
     * @description 
     * Estimated time (in minutes) needed to complete this case.  This value is an entered value or is computed by rolling up the
     * values from case tasks depending on the case template.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    EstimatedTime: number = 0;

    /**
     * @name ActualTime
     * @description 
     * Actual time (in minutes) needed to complete this case.  This value is an entered value or is computed by rolling up the values
     * from case tasks depending on the case template.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ActualTime: number = 0;

    /**
     * @name Title
     * @description 
     * Title for the case.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Description
     * @description 
     * Description of the case as provided by the person opening the case.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Summary
     * @description 
     * Summary for the case.  This is typically an edited version of Description especially for published cases.
     * @servertype String
     * @type {string}
     */
    Summary: string = "";

    /**
     * @name Resolution
     * @description 
     * Resolution of the case.
     * @servertype String
     * @type {string}
     */
    Resolution: string = "";

    /**
     * @name AppliesToList
     * @description 
     * Applies to list for the case.  Each item in the list is CRLF delimited in the following format:
     * {{Package.PackageId}}
     * {{Item.ItemId}}
     * {{InventoryType.InventoryTypeId}}
     * Any free form text desired.
     * @servertype String
     * @type {string}
     */
    AppliesToList: string = "";

    /**
     * @name DependsOnList
     * @description 
     * Depends on list for the case is a comma delimited list of case numbers this case depends on.
     * @servertype long array
     * @type {number[]}
     */
    DependsOnList: number[] = [];

    /**
     * @name SeeAlsoList
     * @description 
     * See also list for the case is a comma delimited list of case numbers related to this case.
     * @servertype long array
     * @type {number[]}
     */
    SeeAlsoList: number[] = [];

    /**
     * @name Tags
     * @description 
     * A comma separated list of tags for this case.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name PromptHelped
     * @description 
     * True indicates the user is prompted to indicate if this case helped (yes, no, n/a).
     * @servertype Boolean
     * @type {boolean}
     */
    PromptHelped: boolean = false;

    /**
     * @name PromptRating
     * @description 
     * True indicates the user is prompted to provide a 1-5 rating for this case.
     * @servertype Boolean
     * @type {boolean}
     */
    PromptRating: boolean = false;

    /**
     * @name PromptFeedback
     * @description 
     * True indicates the user is prompted to leave feedback regarding the case.
     * @servertype Boolean
     * @type {boolean}
     */
    PromptFeedback: boolean = false;

    /**
     * @name HelpedYes
     * @description 
     * Number of times user said case entry helped.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HelpedYes: number = 0;

    /**
     * @name HelpedYesPercent
     * @servertype Double
     * @type {number}
     */
    HelpedYesPercent: number = 0;

    /**
     * @name HelpedNo
     * @description 
     * Number of times user said case entry did not help.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HelpedNo: number = 0;

    /**
     * @name HelpedNoPercent
     * @servertype Double
     * @type {number}
     */
    HelpedNoPercent: number = 0;

    /**
     * @name HelpedNa
     * @description 
     * Number of times user said case entry was not applicable.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HelpedNa: number = 0;

    /**
     * @name HelpedNaPercent
     * @servertype Double
     * @type {number}
     */
    HelpedNaPercent: number = 0;

    /**
     * @name HelpedTotal
     * @servertype Int32
     * @type {number}
     */
    HelpedTotal: number = 0;

    /**
     * @name Rating01
     * @description 
     * Number of times user rated the case as 1 on scale of 1-5.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating01: number = 0;

    /**
     * @name Rating01Percent
     * @servertype Double
     * @type {number}
     */
    Rating01Percent: number = 0;

    /**
     * @name Rating02
     * @description 
     * Number of times user rated the case as 2 on scale of 1-5.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating02: number = 0;

    /**
     * @name Rating02Percent
     * @servertype Double
     * @type {number}
     */
    Rating02Percent: number = 0;

    /**
     * @name Rating03
     * @description 
     * Number of times user rated the case as 3 on scale of 1-5.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating03: number = 0;

    /**
     * @name Rating03Percent
     * @servertype Double
     * @type {number}
     */
    Rating03Percent: number = 0;

    /**
     * @name Rating04
     * @description 
     * Number of times user rated the case as 4 on scale of 1-5.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating04: number = 0;

    /**
     * @name Rating04Percent
     * @servertype Double
     * @type {number}
     */
    Rating04Percent: number = 0;

    /**
     * @name Rating05
     * @description 
     * Number of times user rated the case as 5 on scale of 1-5.  See optional CaseFeedback table for detailed feedback.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating05: number = 0;

    /**
     * @name Rating05Percent
     * @servertype Double
     * @type {number}
     */
    Rating05Percent: number = 0;

    /**
     * @name RatingAverage
     * @servertype Double
     * @type {number}
     */
    RatingAverage: number = 0;

    /**
     * @name RatingTotal
     * @servertype Int32
     * @type {number}
     */
    RatingTotal: number = 0;

    /**
     * @name Publish
     * @description 
     * When true the case is available to the public which is useful for building of a knowledgebase.  When this is set to false
     * the case is only available to the involved parties.
     * @servertype Boolean
     * @type {boolean}
     */
    Publish: boolean = false;

    /**
     * @name Faq
     * @description 
     * When true the case is listed in FAQ (Frequently Asked Question) listings  Note that Publish must also be true for this setting
     * to be useful.
     * @servertype Boolean
     * @type {boolean}
     */
    Faq: boolean = false;

    /**
     * @name PublishCategory
     * @description 
     * The publish or faq category for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    PublishCategory: string = "";

    /**
     * @name ReadOnlyShareId
     * @description 
     * A guid-style id that is used to reference this case for read-only sharing purposes.  Note that anyone that has the URL that
     * references this value will be able to view the case.
     * @servertype String
     * @type {string}
     */
    ReadOnlyShareId: string = "";

    /**
     * @name FirstPublishedDateTime
     * @description 
     * The date and time when the case was first published.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FirstPublishedDateTime: Date = null;

    /**
     * @name RevisionDateTime
     * @description 
     * The date and time when the case was most recently revised.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RevisionDateTime: Date = null;

    /**
     * @name RevisionNumber
     * @description 
     * The revision number of this case.  This should be modified after a public case is modified.
     * @servertype String
     * @type {string}
     */
    RevisionNumber: string = "";

    /**
     * @name NeedsReview
     * @description 
     * True indicates this case was flagged as needing review.  That could be because first time publishing, negative ratings, or
     * because the case template requires a review before the case can be closed.  Upon successful review this will be set false.
     * @servertype Boolean
     * @type {boolean}
     */
    NeedsReview: boolean = false;

    /**
     * @name ReviewedDateTime
     * @description 
     * The date and time when the case was last reviewed.  If the review was successful the needs review flag will be set to false.
     *  If the review was unsuccessful the needs review flag will be true.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReviewedDateTime: Date = null;

    /**
     * @name ReviewedByContactId
     * @description 
     * The contact id that represents who last reviewed this case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReviewedByContactId: number = null;

    /**
     * @name ReviewedByContactName
     * @servertype String
     * @type {string}
     */
    ReviewedByContactName: string = "";

    /**
     * @name ReviewComments
     * @description 
     * Comments entered about the review.  This can be used to explain why something was flagged for review or to add notes about
     * the review.
     * @servertype String
     * @type {string}
     */
    ReviewComments: string = "";

    /**
     * @name NextReviewDateTime
     * @description 
     * The date and time when the case should next be reviewed.  NULL means no further review is needed.  This is frequently used
     * when the case resolution is a 'work around' that will have a better resolution at a future date when, for example, a new
     * version may be released after which the resolution should be updated.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NextReviewDateTime: Date = null;

    /**
     * @name NextReviewContactId
     * @description 
     * The Contact Id that is assigned to perform the next review.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NextReviewContactId: number = null;

    /**
     * @name NextReviewContactName
     * @servertype String
     * @type {string}
     */
    NextReviewContactName: string = "";

    /**
     * @name Billable
     * @description 
     * When true the case is considered billable.
     * @servertype Boolean
     * @type {boolean}
     */
    Billable: boolean = true;

    /**
     * @name BillingRate
     * @description 
     * The hourly billing rate for this case.
     * @servertype Double (nullable)
     * @type {number}
     */
    BillingRate: number = 0;

    /**
     * @name BillingType
     * @description 
     * A flag indicating the case billing type.  Possible values include:
     * N = None (no hourly rate applied)
     * T = Task (task hourly rate applied)
     * P = Person (person hourly rate applied)
     * C = Case (case hourly rate applied)
     * @servertype String
     * @type {string}
     */
    BillingType: string = "N";

    /**
     * @name BillingMinuteMinimum
     * @description 
     * The minimum number of minutes to bill for each billable event.  For example, if a time log had 10 minutes and the billing
     * minute minimum was 30 the event would be billed at 30 minutes.
     * @servertype Byte (nullable)
     * @type {number}
     */
    BillingMinuteMinimum: number = 0;

    /**
     * @name BillingMinuteIncrement
     * @description 
     * The minute increment to use for each billable event.  For example, if a time log had 35 minutes and the billing minute increment
     * was set to 15 minutes the event would be billed at 45 minutes.
     * @servertype Byte (nullable)
     * @type {number}
     */
    BillingMinuteIncrement: number = 0;

    /**
     * @name BudgetType
     * @description 
     * A flag indicating the case budget type.  Possible values include:
     * N = None (no budget)
     * T = Task Hours (task hours based budget)
     * K = Task Fees (task fees based budget)
     * P = Person Hours (person hours based budget)
     * F = Case Fees (case fees based budget)
     * H = Case Hours (case hours based budget)
     * @servertype String
     * @type {string}
     */
    BudgetType: string = "N";

    /**
     * @name Budget
     * @description 
     * The budget for this case in fees or hours depending on the budget type.
     * @servertype Double (nullable)
     * @type {number}
     */
    Budget: number = 0;

    /**
     * @name BudgetWarningPercent
     * @description 
     * Warn when case exceeds this percent of the budget.
     * @servertype Byte (nullable)
     * @type {number}
     */
    BudgetWarningPercent: number = 0;

    /**
     * @name ReopenedCount
     * @description 
     * Number of times case was reopened.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ReopenedCount: number = 0;

    /**
     * @name ReadCount
     * @description 
     * Number of times case entry was read.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ReadCount: number = 0;

    /**
     * @name LastReadDateTime
     * @description 
     * The date and time when the case was last read.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastReadDateTime: Date = null;

    /**
     * @name CorrespondenceReplyNeeded
     * @description 
     * When true the case has correspondence that still needs a reply.
     * @servertype Boolean
     * @type {boolean}
     */
    CorrespondenceReplyNeeded: boolean = false;

    /**
     * @name CaseOpenItemsApplied
     * @description 
     * True indicates the items specified to trigger on case open have been applied.
     * @servertype Boolean
     * @type {boolean}
     */
    CaseOpenItemsApplied: boolean = false;

    /**
     * @name CaseCloseItemsApplied
     * @description 
     * True indicates the items specified to trigger on case close have been applied.
     * @servertype Boolean
     * @type {boolean}
     */
    CaseCloseItemsApplied: boolean = false;

    /**
     * @name ContactIdNotified
     * @description 
     * True indicates the Contact Id has been notified of the resolution to this case.
     * @servertype Boolean
     * @type {boolean}
     */
    ContactIdNotified: boolean = false;

    /**
     * @name Sanitized
     * @description 
     * True indicates the case has been sanitized as dictated by the case template.
     * @servertype Boolean
     * @type {boolean}
     */
    Sanitized: boolean = false;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Correspondence
     * @servertype CaseCorrespondenceEditViewModel array
     * @type {CaseCorrespondenceEditViewModel[]}
     */
    Correspondence: CaseCorrespondenceEditViewModel[] = [];

    /**
     * @name Access
     * @servertype CaseAccessEditViewModel array
     * @type {CaseAccessEditViewModel[]}
     */
    Access: CaseAccessEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Case Template List
 * @description
 * The CaseTemplate table contains default information for cases that use this template.
 */
export class CaseTemplateListViewModel {

    /**
     * @name CaseTemplateId
     * @description 
     * Unique value that represents this case template.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = null;

    /**
     * @name ExternalCaseTemplateId
     * @description 
     * An optional external case template id for this template.
     * @servertype String
     * @type {string}
     */
    ExternalCaseTemplateId: string = "";

    /**
     * @name Description
     * @description 
     * Description for this case template.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the case template is enabled and can be used for new cases.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Classification
     * @description 
     * Classification for this case template.  Possible values include:
     * C = Case
     * W = Workflow Case
     * T = Task
     * P = Project
     * @servertype String
     * @type {string}
     */
    Classification: string = "C";

    /**
     * @name Presentation
     * @description 
     * The presentation style for the case.  Possible values include:
     * N = Note based simple layout
     * C = Correspondence based simple layout
     * @servertype String
     * @type {string}
     */
    Presentation: string = "N";

    /**
     * @name Type
     * @description 
     * Default Type for case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Priority
     * @description 
     * Default priority for case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Priority: string = "";

    /**
     * @name Status
     * @description 
     * Default status for case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name Progress
     * @description 
     * Default progress for case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Progress: string = "";

    /**
     * @name DefaultTitle
     * @description 
     * Default Title for cases using this template.
     * @servertype String
     * @type {string}
     */
    DefaultTitle: string = "";

    /**
     * @name LastUsedDateTime
     * @description 
     * Date and time when the template was last used.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUsedDateTime: Date = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name CaseTemplateEditViewModel
 * @description
 * The CaseTemplate table contains default information for cases that use this template.
 */
export class CaseTemplateEditViewModel {

    /**
     * @name CaseTemplateId
     * @description 
     * Unique value that represents this case template.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = null;

    /**
     * @name ExternalCaseTemplateId
     * @description 
     * An optional external case template id for this template.
     * @servertype String
     * @type {string}
     */
    ExternalCaseTemplateId: string = "";

    /**
     * @name Description
     * @description 
     * Description for this case template.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the case template is enabled and can be used for new cases.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Classification
     * @description 
     * Classification for this case template.  Possible values include:
     * C = Case
     * W = Workflow Case
     * T = Task
     * P = Project
     * @servertype String
     * @type {string}
     */
    Classification: string = "C";

    /**
     * @name Presentation
     * @description 
     * The presentation style for the case.  Possible values include:
     * N = Note based simple layout
     * C = Correspondence based simple layout
     * @servertype String
     * @type {string}
     */
    Presentation: string = "N";

    /**
     * @name Layout
     * @description 
     * Layout for the user interface.  This is a JSON object in the following format:
     * {
     *    "WhenCustomAttributeForm": {
     *       "PositionTitle": "above",
     *       "PositionDescription": "below",
     *       "PositionNotes": "separate",
     *       "PositionForm": "main",
     *       "_comment": "Title, description, notes options: above, below, separate.  Form options: main, separate."
     *    },
     *    "Properties": {
     *       "_comment": "For custom layout properties"
     *    },
     * }
     * @servertype IB.Web.API.Models.v5.CaseTemplateLayoutEditViewModel
     * @type {CaseTemplateLayoutEditViewModel}
     */
    Layout: CaseTemplateLayoutEditViewModel = null;

    /**
     * @name LayoutFeatures
     * @description 
     * A comma delimited list of features to include in the layout for this case template.  Possible values include: 
     * Note = Notes
     * Correspondence = Correspondence
     * Task = Tasks
     * TimeLog = Time Log
     * @servertype String
     * @type {string}
     */
    LayoutFeatures: string = "";

    /**
     * @name LayoutAssetId
     * @description 
     * The Asset Id to use for user interface layout.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LayoutAssetId: number = null;

    /**
     * @name LayoutFormId
     * @description 
     * The Form Id to use for user interface layout.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LayoutFormId: number = null;

    /**
     * @name CustomAttributeFormId
     * @description 
     * The Form Id to use for custom attribute section of user interface layout.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CustomAttributeFormId: number = null;

    /**
     * @name Type
     * @description 
     * Default Type for case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Category
     * @description 
     * Category for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name CaseGroup
     * @description 
     * Group for this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    CaseGroup: string = "";

    /**
     * @name Priority
     * @description 
     * Default priority for case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Priority: string = "";

    /**
     * @name Status
     * @description 
     * Default status for case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name StatusWhenDone
     * @description 
     * Default status when case is done.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    StatusWhenDone: string = "";

    /**
     * @name Progress
     * @description 
     * Default progress for case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Progress: string = "";

    /**
     * @name ProgressWhenDone
     * @description 
     * Default progress when case is done.
     * @servertype String
     * @type {string}
     */
    ProgressWhenDone: string = "";

    /**
     * @name RiskLevel
     * @description 
     * Risk level of this case.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    RiskLevel: string = "";

    /**
     * @name DefaultDescription
     * @description 
     * Default Description for cases using this template.  This is typically used for cases that need some static text in the description
     * to contain things like instructions, simple fill in the blank forms, etc.
     * @servertype String
     * @type {string}
     */
    DefaultDescription: string = "";

    /**
     * @name DefaultTitle
     * @description 
     * Default Title for cases using this template.
     * @servertype String
     * @type {string}
     */
    DefaultTitle: string = "";

    /**
     * @name DefaultSummary
     * @description 
     * Default Summary for case.  Available macros include:
     * {{NewLine}} = a new line
     * {{Description}} = case description provided when case was created
     * For example: Unconfirmed but submitted as {{NewLine}} {{Description}}
     * @servertype String
     * @type {string}
     */
    DefaultSummary: string = "";

    /**
     * @name DefaultTags
     * @description 
     * A comma separated list of default tags for cases using this template.
     * @servertype string array
     * @type {string[]}
     */
    DefaultTags: string[] = [];

    /**
     * @name RegardingPrompt
     * @description 
     * Flag that indicates what regarding prompt should be provided.  Possible values include:
     * NNN = None - there is no regarding prompt provided.
     * IIR = Inventory Item Required (a valid inventory item must be provided)
     * IIO = Inventory Item Optional (an inventory item is asked for but not required)
     * ITR = Inventory Type Required (a valid inventory type must be provided)
     * ITO = Inventory Type Optional (an inventory type is asked for but not required)
     * POR = Package Occurrence Required (a valid package occurrence must be provided)
     * POO = Package Occurrence Optional (a package occurrence is asked for but not required)
     * PKR = Package Required (a valid package must be provided)
     * PKO = Package Optional (a package is asked for but not required)
     * IPR = Item from Package Required (a valid item that is part of a current package must be provided)
     * IPO = Item from Package Optional (a item that is part of a current package is asked for but not required)
     * IMR = Item Required (a valid item must be provided)
     * IMO = Item Optional (an item is asked for but not required)
     * @servertype String
     * @type {string}
     */
    RegardingPrompt: string = "NNN";

    /**
     * @name PromptVersion
     * @description 
     * Flag that indicates what version prompt should be provided.  Possible values include:
     * NNN = None (there is no version prompt provided)
     * FVR = Free Form Version Required (a free form version must be provided)
     * FVO = Free Form Version Optional (a free form version is asked for but not required)
     * IVR = Inventory Type Version Required (a valid inventory type version must be provided)
     * IVO = Inventory Type Version Optional (an inventory type version is asked for but not required)
     * EVR = Either Required (a valid inventory type version or a free form version must be provided)
     * EVO = Either Optional (a valid inventory type version or a free form version is asked for but not required)
     * @servertype String
     * @type {string}
     */
    PromptVersion: string = "NNN";

    /**
     * @name CaseTimeFromTasks
     * @description 
     * When true the estimated and actual time on the case are roll up values from tasks.  When false they are entered values.
     * @servertype Boolean
     * @type {boolean}
     */
    CaseTimeFromTasks: boolean = false;

    /**
     * @name ShowExternalCaseNumber
     * @description 
     * When true the external case number is displayed.
     * @servertype Boolean
     * @type {boolean}
     */
    ShowExternalCaseNumber: boolean = false;

    /**
     * @name RequireUniqueExternalCaseNumber
     * @description 
     * When true the external case number is required to be unique.
     * @servertype Boolean
     * @type {boolean}
     */
    RequireUniqueExternalCaseNumber: boolean = false;

    /**
     * @name CaseNumberAssignment
     * @description 
     * Flag that indicates how case number assignment occurs for internally opened cases of this template type.  Possible values
     * include:
     * S = System Assigned
     * U = User Assigned
     * E = Numeric portion of the external case number
     * @servertype String
     * @type {string}
     */
    CaseNumberAssignment: string = "S";

    /**
     * @name CanAddItems
     * @description 
     * When true the directory entity closing the case can add items.
     * @servertype Boolean
     * @type {boolean}
     */
    CanAddItems: boolean = false;

    /**
     * @name PublicCaseTemplate
     * @description 
     * When true this case template is one that can be presented to the public for selection when entering a new case using a public
     * interface like the web interface.  Other values indicate this case template is only available for cases created using a private
     * interface like CSR web portal.
     * @servertype Boolean
     * @type {boolean}
     */
    PublicCaseTemplate: boolean = false;

    /**
     * @name PublicCaseNotes
     * @description 
     * When true all notes on the case are public to non-directory user case participants (e.g. the customer the case is about).
     *  When false, notes are only visible to directory users
     * @servertype Boolean
     * @type {boolean}
     */
    PublicCaseNotes: boolean = false;

    /**
     * @name AllowReopen
     * @description 
     * Flag that indicates if case is allowed to be reopened after being closed.  Possible values include:
     * N = Case cannot be reopened
     * D = Case can only be reopened by a directory entry
     * A = Case can be reopened by any case participant
     * @servertype String
     * @type {string}
     */
    AllowReopen: string = "N";

    /**
     * @name DueMinutes
     * @description 
     * The number of minutes after due trigger to use for setting the due date for cases using this template.  When null or zero
     * the case due date is only set manually.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DueMinutes: number = 0;

    /**
     * @name DueTrigger
     * @description 
     * Flag that indicates what triggers due minutes being added to automatically set the due date for a case.  Possible values
     * include:
     * E = Event
     * O = Opened
     * S = Started
     * C = Estimated Close
     * @servertype String
     * @type {string}
     */
    DueTrigger: string = "O";

    /**
     * @name Publish
     * @description 
     * When true the case has publish options that would, if enabled, make the case available to the public which is useful for
     * building of a knowledgebase.  When false publishing cases is not an option.
     * @servertype Boolean
     * @type {boolean}
     */
    Publish: boolean = false;

    /**
     * @name PublishCategory
     * @description 
     * The default publish or FAQ category for this template.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    PublishCategory: string = "";

    /**
     * @name NeedsReview
     * @description 
     * True indicates the case should be flagged as needing review when closed.  This is frequently a precursor to it being published.
     * @servertype Boolean
     * @type {boolean}
     */
    NeedsReview: boolean = false;

    /**
     * @name PromptHelped
     * @description 
     * True indicates the user is prompted to indicate if this case helped (yes, no, n/a).
     * @servertype Boolean
     * @type {boolean}
     */
    PromptHelped: boolean = false;

    /**
     * @name PromptRating
     * @description 
     * True indicates the user is prompted to provide a 1-5 rating for this case.
     * @servertype Boolean
     * @type {boolean}
     */
    PromptRating: boolean = false;

    /**
     * @name PromptFeedback
     * @description 
     * True indicates the user is prompted to leave feedback regarding the case.
     * @servertype Boolean
     * @type {boolean}
     */
    PromptFeedback: boolean = false;

    /**
     * @name ViewRequiresRoleId
     * @description 
     * Specifies the required role id required to access this case even when Publish is true.  This can be used for scenarios where
     * only select groups should have access to an case even when that case is publicly available.  For example, beta testers may
     * need to have access to cases regarding a beta version of a new product.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ViewRequiresRoleId: number = null;

    /**
     * @name CreateRequiresRoleId
     * @description 
     * Specifies the required role id required to create cases using this template.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CreateRequiresRoleId: number = null;

    /**
     * @name ViewRequiresGroupId
     * @description 
     * Specifies the required group id required to access this case even when Publish is true.  This can be used for scenarios where
     * only select groups should have access to an case even when that case is publicly available.  For example, beta testers may
     * need to have access to cases regarding a beta version of a new product.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ViewRequiresGroupId: number = null;

    /**
     * @name CreateRequiresGroupId
     * @description 
     * Specifies the required group id required to create cases using this template.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CreateRequiresGroupId: number = null;

    /**
     * @name Billable
     * @description 
     * When true the case is considered billable.
     * @servertype Boolean
     * @type {boolean}
     */
    Billable: boolean = true;

    /**
     * @name BillingRate
     * @description 
     * The hourly billing rate for this case.
     * @servertype Double (nullable)
     * @type {number}
     */
    BillingRate: number = 0;

    /**
     * @name BillingType
     * @description 
     * A flag indicating the case billing type.  Possible values include:
     * N = None (no hourly rate applied)
     * T = Task (task hourly rate applied)
     * P = Person (person hourly rate applied)
     * C = Case (case hourly rate applied)
     * @servertype String
     * @type {string}
     */
    BillingType: string = "N";

    /**
     * @name BillingMinuteMinimum
     * @description 
     * The minimum number of minutes to bill for each billable event.  For example, if a time log had 10 minutes and the billing
     * minute minimum was 30 the event would be billed at 30 minutes.
     * @servertype Byte (nullable)
     * @type {number}
     */
    BillingMinuteMinimum: number = 0;

    /**
     * @name BillingMinuteIncrement
     * @description 
     * The minute increment to use for each billable event.  For example, if a time log had 35 minutes and the billing minute increment
     * was set to 15 minutes the event would be billed at 45 minutes.
     * @servertype Byte (nullable)
     * @type {number}
     */
    BillingMinuteIncrement: number = 0;

    /**
     * @name BudgetType
     * @description 
     * A flag indicating the case budget type.  Possible values include:
     * N = None (no budget)
     * T = Task Hours (task hours based budget)
     * K = Task Fees (task fees based budget)
     * P = Person Hours (person hours based budget)
     * F = Case Fees (case fees based budget)
     * H = Case Hours (case hours based budget)
     * @servertype String
     * @type {string}
     */
    BudgetType: string = "N";

    /**
     * @name Budget
     * @description 
     * The budget for this case in fees or hours depending on the budget type.
     * @servertype Double (nullable)
     * @type {number}
     */
    Budget: number = 0;

    /**
     * @name BudgetWarningPercent
     * @description 
     * Warn when case exceeds this percent of the budget.
     * @servertype Byte (nullable)
     * @type {number}
     */
    BudgetWarningPercent: number = 0;

    /**
     * @name NotificationGroupIdNewCase
     * @description 
     * Notification Group Id to notify when new case is created.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdNewCase: number = null;

    /**
     * @name NotificationGroupIdModifiedCase
     * @description 
     * Notification Group Id to notify when case is modified.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdModifiedCase: number = null;

    /**
     * @name NotificationGroupIdAssignedCase
     * @description 
     * Notification Group Id to notify when case is assigned.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdAssignedCase: number = null;

    /**
     * @name NotificationGroupIdClosedCase
     * @description 
     * Notification Group Id to notify when case is closed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdClosedCase: number = null;

    /**
     * @name NotificationGroupIdCaseCorrespondence
     * @description 
     * Notification Group Id to notify when case correspondence is logged.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdCaseCorrespondence: number = null;

    /**
     * @name NotificationGroupIdAnnounce
     * @description 
     * Notification Group Id to notify when case announcement is triggered.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdAnnounce: number = null;

    /**
     * @name NotificationGroupIdReminder
     * @description 
     * Notification Group Id to notify when case reminder is triggered.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdReminder: number = null;

    /**
     * @name AllowThirdPartiesToWatchCase
     * @description 
     * When true third parties are allowed to watch cases using this case template (i.e. they can be added to the notification list
     * for the case).
     * @servertype Boolean
     * @type {boolean}
     */
    AllowThirdPartiesToWatchCase: boolean = false;

    /**
     * @name AutoCloseWhenAllTasksCompleted
     * @description 
     * When true the case will automatically close when all tasks have been completed.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoCloseWhenAllTasksCompleted: boolean = false;

    /**
     * @name CloseRuleTitleRequired
     * @description 
     * When true the case cannot be closed unless a title has been provided.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleTitleRequired: boolean = false;

    /**
     * @name CloseRuleSummaryRequired
     * @description 
     * When true the case cannot be closed unless a summary has been provided.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleSummaryRequired: boolean = false;

    /**
     * @name CloseRuleResolutionRequired
     * @description 
     * When true the case cannot be closed unless a resolution has been provided.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleResolutionRequired: boolean = false;

    /**
     * @name CloseRuleTagsRequired
     * @description 
     * When true the case cannot be closed unless tags have been provided.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleTagsRequired: boolean = false;

    /**
     * @name CloseRuleAppliesToListRequired
     * @description 
     * When true the case cannot be closed unless an applies to list has been provided.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleAppliesToListRequired: boolean = false;

    /**
     * @name CloseRuleSummaryNotDescription
     * @description 
     * When true the case cannot be closed unless the summary is different than the description.  Since the summary will frequently
     * default to the description this rule forces the summary to have been edited to be more user friendly for knowledgebase purposes.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleSummaryNotDescription: boolean = false;

    /**
     * @name CloseRuleAllTasksCompleted
     * @description 
     * When true the case cannot be closed unless all tasks have first been completed.  Under certain scenarios users have the ability
     * to close out an case at any point in the process which will automatically complete all open tasks.  This rule prevents users
     * who would otherwise have that right from being able to close an case with open tasks.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleAllTasksCompleted: boolean = false;

    /**
     * @name CloseRuleSanitizeCase
     * @description 
     * When true the case cannot be closed unless it has been sanitized.  See sanitize steps for information as to what is done
     * when the case is sanitized.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleSanitizeCase: boolean = false;

    /**
     * @name CloseRuleReviewRequired
     * @description 
     * When true the case cannot be closed unless it has been reviewed by someone other than the case owner.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleReviewRequired: boolean = false;

    /**
     * @name CloseRuleOutcomeRequired
     * @description 
     * When true the case cannot be closed unless an outcome value has been provided.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleOutcomeRequired: boolean = false;

    /**
     * @name CloseAsSuccessOutcomes
     * @description 
     * A list of outcome pick list values that indicate the case outcome was success.  If the outcome is not in this list and not
     * in the close as failure outcomes list the case is closed with an indeterminate outcome.
     * @servertype string array
     * @type {string[]}
     */
    CloseAsSuccessOutcomes: string[] = [];

    /**
     * @name CloseAsFailureOutcomes
     * @description 
     * A list of outcome pick list values that indicate the case outcome was failure.  If the outcome is not in this list and not
     * in the close as success outcomes list the case is closed with an indeterminate outcome.
     * @servertype string array
     * @type {string[]}
     */
    CloseAsFailureOutcomes: string[] = [];

    /**
     * @name SupportChildCase
     * @servertype Boolean
     * @type {boolean}
     */
    SupportChildCase: boolean = false;

    /**
     * @name SupportTask
     * @servertype Boolean
     * @type {boolean}
     */
    SupportTask: boolean = false;

    /**
     * @name SupportTimeLog
     * @servertype Boolean
     * @type {boolean}
     */
    SupportTimeLog: boolean = false;

    /**
     * @name SupportInternalCaseNumber
     * @servertype Boolean
     * @type {boolean}
     */
    SupportInternalCaseNumber: boolean = false;

    /**
     * @name SupportExternalCaseNumber
     * @servertype Boolean
     * @type {boolean}
     */
    SupportExternalCaseNumber: boolean = false;

    /**
     * @name SupportContactCaseNumber
     * @servertype Boolean
     * @type {boolean}
     */
    SupportContactCaseNumber: boolean = false;

    /**
     * @name SupportType
     * @servertype Boolean
     * @type {boolean}
     */
    SupportType: boolean = false;

    /**
     * @name SupportTypeStatic
     * @servertype Boolean
     * @type {boolean}
     */
    SupportTypeStatic: boolean = false;

    /**
     * @name SupportCategory
     * @servertype Boolean
     * @type {boolean}
     */
    SupportCategory: boolean = false;

    /**
     * @name SupportCategoryStatic
     * @servertype Boolean
     * @type {boolean}
     */
    SupportCategoryStatic: boolean = false;

    /**
     * @name SupportGroup
     * @servertype Boolean
     * @type {boolean}
     */
    SupportGroup: boolean = false;

    /**
     * @name SupportGroupStatic
     * @servertype Boolean
     * @type {boolean}
     */
    SupportGroupStatic: boolean = false;

    /**
     * @name SupportPriority
     * @servertype Boolean
     * @type {boolean}
     */
    SupportPriority: boolean = false;

    /**
     * @name SupportPriorityRanking
     * @servertype Boolean
     * @type {boolean}
     */
    SupportPriorityRanking: boolean = false;

    /**
     * @name SupportStatus
     * @servertype Boolean
     * @type {boolean}
     */
    SupportStatus: boolean = false;

    /**
     * @name SupportProgress
     * @servertype Boolean
     * @type {boolean}
     */
    SupportProgress: boolean = false;

    /**
     * @name SupportPercentDone
     * @servertype Boolean
     * @type {boolean}
     */
    SupportPercentDone: boolean = false;

    /**
     * @name SupportRiskLevel
     * @servertype Boolean
     * @type {boolean}
     */
    SupportRiskLevel: boolean = false;

    /**
     * @name SupportEstimatedTime
     * @servertype Boolean
     * @type {boolean}
     */
    SupportEstimatedTime: boolean = false;

    /**
     * @name SupportCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    SupportCustomer: boolean = false;

    /**
     * @name SupportCustomerSite
     * @servertype Boolean
     * @type {boolean}
     */
    SupportCustomerSite: boolean = false;

    /**
     * @name SupportResolution
     * @servertype Boolean
     * @type {boolean}
     */
    SupportResolution: boolean = false;

    /**
     * @name SupportDependsOn
     * @servertype Boolean
     * @type {boolean}
     */
    SupportDependsOn: boolean = false;

    /**
     * @name SupportSeeAlso
     * @servertype Boolean
     * @type {boolean}
     */
    SupportSeeAlso: boolean = false;

    /**
     * @name SupportComments
     * @servertype Boolean
     * @type {boolean}
     */
    SupportComments: boolean = false;

    /**
     * @name SupportChangeLogging
     * @description 
     * When true the case supports change logging so changes to the case are logged with notes.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportChangeLogging: boolean = true;

    /**
     * @name SupportCorrespondence
     * @description 
     * When true the case supports correspondence.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportCorrespondence: boolean = true;

    /**
     * @name SupportAddingTaskLists
     * @description 
     * When true the case will allow new task lists and tasks to be created.  Note that this is a flag that applies to cases using
     * this template.  New tasks lists and tasks can always be added to the template itself regardless of this setting.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportAddingTaskLists: boolean = true;

    /**
     * @name SupportSystemTasks
     * @description 
     * When true the case provides support for system tasks.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportSystemTasks: boolean = false;

    /**
     * @name SupportTriggers
     * @description 
     * When true the case provides support for triggers.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportTriggers: boolean = false;

    /**
     * @name SupportAdvancedSettings
     * @description 
     * When true the case provides options for various advanced settings.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportAdvancedSettings: boolean = false;

    /**
     * @name SupportExtendedAttributes
     * @description 
     * When true the case provides support for extended attributes.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportExtendedAttributes: boolean = false;

    /**
     * @name SupportOtherFeatureList
     * @servertype string array
     * @type {string[]}
     */
    SupportOtherFeatureList: string[] = [];

    /**
     * @name SanitizeRemoveNotes
     * @servertype Boolean
     * @type {boolean}
     */
    SanitizeRemoveNotes: boolean = false;

    /**
     * @name SanitizeRemoveCorrespondence
     * @servertype Boolean
     * @type {boolean}
     */
    SanitizeRemoveCorrespondence: boolean = false;

    /**
     * @name SanitizeRemoveChangeLog
     * @servertype Boolean
     * @type {boolean}
     */
    SanitizeRemoveChangeLog: boolean = false;

    /**
     * @name SanitizeRemoveTimeLog
     * @servertype Boolean
     * @type {boolean}
     */
    SanitizeRemoveTimeLog: boolean = false;

    /**
     * @name SanitizeRemoveTasks
     * @servertype Boolean
     * @type {boolean}
     */
    SanitizeRemoveTasks: boolean = false;

    /**
     * @name SanitizeRemoveAllAttachments
     * @servertype Boolean
     * @type {boolean}
     */
    SanitizeRemoveAllAttachments: boolean = false;

    /**
     * @name SanitizeRemoveDraftAttachments
     * @servertype Boolean
     * @type {boolean}
     */
    SanitizeRemoveDraftAttachments: boolean = false;

    /**
     * @name SanitizeCaseOtherSteps
     * @servertype string array
     * @type {string[]}
     */
    SanitizeCaseOtherSteps: string[] = [];

    /**
     * @name DisplayColor
     * @description 
     * The color used to display this item on calendars or other multi-item displays.
     * @servertype String
     * @type {string}
     */
    DisplayColor: string = "";

    /**
     * @name OwnedByContactId
     * @description 
     * The Contact Id that cases using this template is owned by.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnedByContactId: number = null;

    /**
     * @name OwnedByContactName
     * @servertype String
     * @type {string}
     */
    OwnedByContactName: string = "";

    /**
     * @name CaseOwnership
     * @description 
     * Flag that indicates how case ownership occurs.  Possible values include:
     * A = Automatic (Automatic case ownership flag sets the case owner to who the case is assigned to or, if that is unavailable,
     * who opened the case.)
     * M = Manual (Manual case ownership flag sets the case owner to the contact specified in the owned by contact settings.  If
     * that contact is not set it will use the automatic setting.)
     * U = Unassigned (Unassigned case ownership flag does not set a case owner.)
     * @servertype String
     * @type {string}
     */
    CaseOwnership: string = "A";

    /**
     * @name AssignedToContactId
     * @description 
     * The Contact Id that cases using this template is assigned to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssignedToContactId: number = null;

    /**
     * @name AssignedToContactName
     * @servertype String
     * @type {string}
     */
    AssignedToContactName: string = "";

    /**
     * @name CaseAssignment
     * @description 
     * Flag that indicates how case assignment occurs.  Possible values include:
     * A = Automatic (Automatic case assignment flag assigns the case to the owner or, if that is unavailable, to who opened the
     * case.)
     * M = Manual (Manual case assignment flag assigns the case to the contact specified in the assigned to contact settings.  If
     * that contact is not set it will use the automatic setting.)
     * U = Unassigned (Unassigned case assignment flag does not assign the case.)
     * @servertype String
     * @type {string}
     */
    CaseAssignment: string = "A";

    /**
     * @name SupervisorContactId
     * @description 
     * The Contact Id that has supervisor responsibility for this case.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SupervisorContactId: number = null;

    /**
     * @name SupervisorContactName
     * @servertype String
     * @type {string}
     */
    SupervisorContactName: string = "";

    /**
     * @name SupervisorAssignment
     * @description 
     * Flag that indicates how case supervisor assignment occurs.  Possible values include:
     * A = Automatic (Automatic supervisor flag sets the case supervisor to the owner or, if that is unavailable, to who the case
     * is assigned to.)
     * M = Manual (Manual case supervisor flag sets the case supervisor to the contact specified in the supervisor contact settings.
     *  If that contact is not set it will use the automatic setting.)
     * S = Supervisor (Supervisor case supervisor flag sets the case supervisor to the user supervisor of the case owner or, if
     * that is unavailable, to the user supervisor of who the case is assigned to.  If neither is available it will use the manual
     * setting.)
     * U = Unassigned (Unassigned case supervisor flag does not set a case supervisor.)
     * @servertype String
     * @type {string}
     */
    SupervisorAssignment: string = "A";

    /**
     * @name NextReviewToPreviousReviewer
     * @servertype Boolean
     * @type {boolean}
     */
    NextReviewToPreviousReviewer: boolean = false;

    /**
     * @name NextReviewContactId
     * @description 
     * The Contact Id that is assigned to perform the next review.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NextReviewContactId: number = null;

    /**
     * @name NextReviewContactName
     * @servertype String
     * @type {string}
     */
    NextReviewContactName: string = "";

    /**
     * @name ReviewAssignment
     * @description 
     * Flag that indicates how case review assignment occurs.  Note that cases being assigned for second or subsequent reviews may
     * automatically be assigned to the last reviewer for purposes of continuity.  Possible values include:
     * A = Assigned (assigned to contact specified in next review contact)
     * M = Manual (reviewer must be selected when submitting for review)
     * S = Supervisor (assigned to the case supervisor for review)
     * G = Group Member (assigned to member of the specified group who has the lightest case load)
     * Note that if the requested assignment cannot be made due to configuration issue a default will be selected.
     * @servertype String
     * @type {string}
     */
    ReviewAssignment: string = "A";

    /**
     * @name StatusWhenReviewSubmitted
     * @description 
     * Default status when case is submitted for review.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    StatusWhenReviewSubmitted: string = "";

    /**
     * @name StatusWhenReviewSuccess
     * @description 
     * Default status when case review is successful.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    StatusWhenReviewSuccess: string = "";

    /**
     * @name StatusWhenReviewFail
     * @description 
     * Default status when case review is failed.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    StatusWhenReviewFail: string = "";

    /**
     * @name ProgressWhenReviewSubmitted
     * @description 
     * Default progress when case is submitted for review.
     * @servertype String
     * @type {string}
     */
    ProgressWhenReviewSubmitted: string = "";

    /**
     * @name ProgressWhenReviewSuccess
     * @description 
     * Default progress when case review is successful.
     * @servertype String
     * @type {string}
     */
    ProgressWhenReviewSuccess: string = "";

    /**
     * @name ProgressWhenReviewFail
     * @description 
     * Default progress when case review is failed.
     * @servertype String
     * @type {string}
     */
    ProgressWhenReviewFail: string = "";

    /**
     * @name LastUsedDateTime
     * @description 
     * Date and time when the template was last used.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUsedDateTime: Date = null;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Contact List
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class ChildContactListViewModel {

    /**
     * @name ContactId
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ParentContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentContactId: number = null;

    /**
     * @name ExternalContactId
     * @servertype String
     * @type {string}
     */
    ExternalContactId: string = null;

    /**
     * @name AddressType
     * @servertype String
     * @type {string}
     */
    AddressType: string = "";

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

}



/**
 * @name Contact
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class ChildContactEditViewModel {

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ParentContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentContactId: number = null;

    /**
     * @name ExternalContactId
     * @servertype String
     * @type {string}
     */
    ExternalContactId: string = null;

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name AddressType
     * @servertype String
     * @type {string}
     */
    AddressType: string = "";

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name MiddleName
     * @servertype String
     * @type {string}
     */
    MiddleName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name OtherName
     * @servertype String
     * @type {string}
     */
    OtherName: string = "";

    /**
     * @name CourtesyTitle
     * @servertype String
     * @type {string}
     */
    CourtesyTitle: string = "";

    /**
     * @name PostNominalTitles
     * @servertype String
     * @type {string}
     */
    PostNominalTitles: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name County
     * @description 
     * County.
     * @servertype String
     * @type {string}
     */
    County: string = "";

    /**
     * @name Country
     * @description 
     * Country.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Fax
     * @description 
     * Fax.
     * @servertype String
     * @type {string}
     */
    Fax: string = "";

    /**
     * @name HomePhone
     * @servertype String
     * @type {string}
     */
    HomePhone: string = "";

    /**
     * @name Cellular
     * @description 
     * Cellular.
     * @servertype String
     * @type {string}
     */
    Cellular: string = "";

    /**
     * @name AlternatePhone
     * @servertype String
     * @type {string}
     */
    AlternatePhone: string = "";

    /**
     * @name Extension
     * @description 
     * Extension.
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name AlternateEmail
     * @servertype String
     * @type {string}
     */
    AlternateEmail: string = "";

    /**
     * @name ImAddress
     * @servertype String
     * @type {string}
     */
    ImAddress: string = "";

    /**
     * @name ImNetwork
     * @servertype String
     * @type {string}
     */
    ImNetwork: string = "";

    /**
     * @name AlternateImAddress
     * @servertype String
     * @type {string}
     */
    AlternateImAddress: string = "";

    /**
     * @name AlternateImNetwork
     * @servertype String
     * @type {string}
     */
    AlternateImNetwork: string = "";

    /**
     * @name WebSite
     * @servertype String
     * @type {string}
     */
    WebSite: string = "";

    /**
     * @name FtpSite
     * @servertype String
     * @type {string}
     */
    FtpSite: string = "";

    /**
     * @name TaxId
     * @servertype String
     * @type {string}
     */
    TaxId: string = "";

    /**
     * @name WebLanguage
     * @servertype String
     * @type {string}
     */
    WebLanguage: string = "";

    /**
     * @name IvrLanguage
     * @servertype String
     * @type {string}
     */
    IvrLanguage: string = "";

    /**
     * @name TimeZoneId
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = "";

    /**
     * @name Salutation
     * @description 
     * Salutation for this contact.
     * @servertype String
     * @type {string}
     */
    Salutation: string = "";

    /**
     * @name AssistantName
     * @servertype String
     * @type {string}
     */
    AssistantName: string = "";

    /**
     * @name ManagerName
     * @servertype String
     * @type {string}
     */
    ManagerName: string = "";

    /**
     * @name SpouseName
     * @servertype String
     * @type {string}
     */
    SpouseName: string = "";

    /**
     * @name ChildrenNames
     * @servertype String
     * @type {string}
     */
    ChildrenNames: string = "";

    /**
     * @name DepartmentName
     * @servertype String
     * @type {string}
     */
    DepartmentName: string = "";

    /**
     * @name Office
     * @description 
     * Office.
     * @servertype String
     * @type {string}
     */
    Office: string = "";

    /**
     * @name Profession
     * @description 
     * Profession.
     * @servertype String
     * @type {string}
     */
    Profession: string = "";

    /**
     * @name Hobbies
     * @description 
     * Hobbies.
     * @servertype String
     * @type {string}
     */
    Hobbies: string = "";

    /**
     * @name Gender
     * @description 
     * Gender.  Possible values include:
     * M = Male
     * F = Female
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    Gender: string = "U";

    /**
     * @name BirthDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BirthDate: Date = null;

    /**
     * @name AnniversaryDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AnniversaryDate: Date = null;

    /**
     * @name NotifyAlert
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyAlert: boolean = false;

    /**
     * @name NotifyIssueChange
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChange: boolean = false;

    /**
     * @name NotifyIssueChangeGlobal
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChangeGlobal: boolean = false;

    /**
     * @name NotifyProductInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyProductInfo: boolean = false;

    /**
     * @name NotifyVendorInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyVendorInfo: boolean = false;

    /**
     * @name NotifyThirdPartyInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyThirdPartyInfo: boolean = false;

    /**
     * @name NotifyFormat
     * @servertype String
     * @type {string}
     */
    NotifyFormat: string = "";

    /**
     * @name NotifyStandardStartTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardStartTime: string = "";

    /**
     * @name NotifyStandardEndTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardEndTime: string = "";

    /**
     * @name NotifyUrgentStartTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentStartTime: string = "";

    /**
     * @name NotifyUrgentEndTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentEndTime: string = "";

    /**
     * @name Status
     * @description 
     * Status of the contact.  Possible values include: 
     * V = Invited (Contact was sent invitation to access the system but has not yet done so.)
     * P = Pending (Contact is pending until approved to be active.  While pending the contact has no access.)
     * A = Active (Contact is active.)
     * I = Inactive (Contact is inactive as determined by business rule with no special treatment.)
     * S = Suspended (Contact is suspended.)
     * C = Closed (Contact is closed or cancelled.)
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

    /**
     * @name StatusLocked
     * @servertype Boolean
     * @type {boolean}
     */
    StatusLocked: boolean = false;

    /**
     * @name CreateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CreateDateTime: Date = null;

    /**
     * @name SuspendDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendDateTime: Date = null;

    /**
     * @name CloseDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CloseDateTime: Date = null;

    /**
     * @name ReactivateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReactivateDateTime: Date = null;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name CurrencyFormat
     * @servertype String
     * @type {string}
     */
    CurrencyFormat: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Contact Reference
 */
export class ContactReferenceViewModel {

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactEmail
     * @servertype String
     * @type {string}
     */
    ContactEmail: string = "";

}



/**
 * @name ContactAccessEditViewModel
 * @description
 * The Contact Access table contains a list of access identifiers used by a contact when accessing the system.  It can be used
 * to provide historical context to a system access request and allow an application to decide to accept a token based access
 * request or to ask for login or password authentication again.  Then depending on desired security settings existing tokens
 * can be revoked, access can be challenged, or a notification of access can be sent.
 */
export class ContactAccessEditViewModel {

    /**
     * @name ContactAccessId
     * @description 
     * The id of the Contact Access record.
     * @servertype Int64
     * @type {number}
     */
    ContactAccessId: number = null;

    /**
     * @name ContactId
     * @description 
     * The id of the contact this access record belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name AccessIdentifierType
     * @description 
     * The identifier type for this access record.  Possible values include:
     * I = Public IP Address
     * D = Device Id
     * @servertype String
     * @type {string}
     */
    AccessIdentifierType: string = "";

    /**
     * @name AccessIdentifier
     * @description 
     * The access identifier of the specified type.
     * @servertype String
     * @type {string}
     */
    AccessIdentifier: string = "";

    /**
     * @name AccessLocation
     * @description 
     * If the access identifier has an identifiable location the location is stored here.
     * @servertype String
     * @type {string}
     */
    AccessLocation: string = "";

    /**
     * @name AccessDetails
     * @description 
     * Details about the access.  This could be location details, device details, etc.
     * @servertype String
     * @type {string}
     */
    AccessDetails: string = "";

    /**
     * @name AccessCount
     * @description 
     * The number of times this access identifier has been used by a login.  Note that token based access does not increment this
     * count.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AccessCount: number = 0;

    /**
     * @name LastAccessDateTime
     * @description 
     * The date and time when this access identifier was last used by a login.  Note that token based access does not update this
     * value.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAccessDateTime: Date = null;

    /**
     * @name ActionLinkDateTime
     * @description 
     * If system access required additional user action this is the date and time of that action.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActionLinkDateTime: Date = null;

    /**
     * @name ActionLinkId
     * @description 
     * If system access required additional user action this is the id of the action link.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ActionLinkId: number = null;

    /**
     * @name NotificationDateTime
     * @description 
     * If system access triggered a notification to the contact this is the date and time of that notification.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NotificationDateTime: Date = null;

    /**
     * @name NotificationEventId
     * @description 
     * If system access triggered a notification to the contact this is the notification event id for that notification.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationEventId: number = null;

    /**
     * @name Suspect
     * @description 
     * When true this access identifier has been identified as suspect by the system.
     * @servertype Boolean
     * @type {boolean}
     */
    Suspect: boolean = false;

    /**
     * @name BlockedAutomatically
     * @description 
     * When true access from this access identifier has been blocked automatically by the system.
     * @servertype Boolean
     * @type {boolean}
     */
    BlockedAutomatically: boolean = false;

    /**
     * @name BlockedManually
     * @description 
     * When true access from this access identifier has been manually blocked.  This can be used to black list an access point.
     * @servertype Boolean
     * @type {boolean}
     */
    BlockedManually: boolean = false;

    /**
     * @name Whitelisted
     * @description 
     * When true this access identifier has been whitelisted to prevent any automatic blocking the system may attempt.
     * @servertype Boolean
     * @type {boolean}
     */
    Whitelisted: boolean = false;

    /**
     * @name Properties
     * @description 
     * Properties related to this access.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ContactConsentEditViewModel
 * @description
 * The Contact Consent table contains information about consent items requested, granted, revoked, etc. for a contact.
 */
export class ContactConsentEditViewModel {

    /**
     * @name ContactConsentId
     * @description 
     * The id of the contact consent record.
     * @servertype Int64
     * @type {number}
     */
    ContactConsentId: number = null;

    /**
     * @name ContactId
     * @description 
     * The id of the contact this consent record belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name Consent
     * @description 
     * The consent item for this consent record.  This comes from a system pick list.
     * @servertype String
     * @type {string}
     */
    Consent: string = "";

    /**
     * @name Enabled
     * @description 
     * When true this consent is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name FirstRequestDateTime
     * @description 
     * The date and time of the first request.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FirstRequestDateTime: Date = null;

    /**
     * @name LastRequestDateTime
     * @description 
     * The date and time of the last request.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastRequestDateTime: Date = null;

    /**
     * @name GrantDateTime
     * @description 
     * The date and time of the consent grant.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    GrantDateTime: Date = null;

    /**
     * @name RevokeDateTime
     * @description 
     * The date and time of the consent revoke.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RevokeDateTime: Date = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ContactExternalAuthenticationEditViewModel
 * @description
 * The Contact External Authentication table contains a list of values for various pick lists used in the system.
 */
export class ContactExternalAuthenticationEditViewModel {

    /**
     * @name ContactExternalAuthenticationId
     * @description 
     * The id of the Contact External Authentication.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactExternalAuthenticationId: number = null;

    /**
     * @name ContactId
     * @description 
     * The id of the contact this external authentication record belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name AuthenticationService
     * @description 
     * The authentication service for this authentication record.  Possible values include:
     * TOTP (Time-Based One-Time Password)
     * MFA (used for MFA purposes to provide up to 5 additional phone numbers and email addresses)
     * Partner:* (A partner auth service where * represents the partner name up to 12 characters long)
     * OperatingSystem (windows domain controller, windows server, LDAP, etc.)
     * Google
     * Facebook
     * Microsoft
     * Twitter
     * LinkedIn
     * Yahoo
     * Dropbox
     * Instagram
     * Foursquare
     * Flickr
     * @servertype String
     * @type {string}
     */
    AuthenticationService: string = "";

    /**
     * @name UserId
     * @description 
     * The id of the user.  This is provided by the external authentication service.  
     * When service is TOTP this is the base32 shared secret that must be supplied to the authenticator client.
     * When service is MFA this is a phone number available for voice or SMS.
     * @servertype String
     * @type {string}
     */
    UserId: string = "";

    /**
     * @name UserName
     * @description 
     * The user name.  
     * When service is TOTP this is the user name to use for the user.
     * When service is MFA this is a phone number available for voice or SMS.
     * @servertype String
     * @type {string}
     */
    UserName: string = "";

    /**
     * @name DisplayName
     * @description 
     * The display name.  
     * When service is TOTP this is the application display name to use which is typically the app brand.
     * When service is MFA this is a phone number available for voice or SMS.
     * @servertype String
     * @type {string}
     */
    DisplayName: string = "";

    /**
     * @name FirstName
     * @description 
     * The first name.
     * When service is MFA this is a phone number available for voice or SMS.
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @description 
     * The last name.
     * When service is MFA this is a phone number available for voice or SMS.
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name Email
     * @description 
     * The email address.  
     * When service is MFA this is an email address that can be used.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name AlternateEmail
     * @description 
     * The second email address.
     * When service is MFA this is an email address that can be used.
     * @servertype String
     * @type {string}
     */
    AlternateEmail: string = "";

    /**
     * @name Language
     * @description 
     * The user language setting.
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name ProfileUrl
     * @description 
     * The URL of the user profile.
     * When service is MFA this is an email address that can be used.
     * @servertype String
     * @type {string}
     */
    ProfileUrl: string = "";

    /**
     * @name ImageUrl
     * @description 
     * The URL of the user image.
     * When service is MFA this is an email address that can be used.
     * @servertype String
     * @type {string}
     */
    ImageUrl: string = "";

    /**
     * @name ThumbnailUrl
     * @description 
     * The URL of the user thumbnail.
     * When service is MFA this is an email address that can be used.
     * @servertype String
     * @type {string}
     */
    ThumbnailUrl: string = "";

    /**
     * @name AuthenticationCount
     * @description 
     * The number of times this authentication has been performed.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AuthenticationCount: number = 0;

    /**
     * @name LastAuthenticationDateTime
     * @description 
     * The date and time when this authentication was last done.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAuthenticationDateTime: Date = null;

    /**
     * @name Properties
     * @description 
     * Properties related to this external authentication.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Contact Properties (Group)
 */
export class ContactPropertiesContactTypeGroup {

    /**
     * @name GroupMembershipAllowedContactTypes
     * @servertype string array
     * @type {string[]}
     */
    GroupMembershipAllowedContactTypes: string[] = [];

    /**
     * @name MembersInheritRolesFromChildGroups
     * @servertype Boolean
     * @type {boolean}
     */
    MembersInheritRolesFromChildGroups: boolean = false;

    /**
     * @name MembersInheritScopeFromChildGroups
     * @servertype Boolean
     * @type {boolean}
     */
    MembersInheritScopeFromChildGroups: boolean = false;

    /**
     * @name JoiningGroup
     * @servertype IB.Web.API.Models.v5.GroupMembershipApproval Enum
     * @type {GroupMembershipApproval}
     */
    JoiningGroup: GroupMembershipApproval = null;

    /**
     * @name LeavingGroup
     * @servertype IB.Web.API.Models.v5.GroupMembershipApproval Enum
     * @type {GroupMembershipApproval}
     */
    LeavingGroup: GroupMembershipApproval = null;

    /**
     * @name MemberStatusDaysBeforeConsideredInactive
     * @servertype Int32
     * @type {number}
     */
    MemberStatusDaysBeforeConsideredInactive: number = 0;

    /**
     * @name GroupMembershipProperties
     * @servertype PropertyMetaDataViewModel array
     * @type {m.PropertyMetaDataViewModel[]}
     */
    GroupMembershipProperties: m.PropertyMetaDataViewModel[] = [];

}



/**
 * @name GroupMembershipApproval Enum
 * @readonly
 * @enum {number}
 */
export enum GroupMembershipApproval {

    /**
     * Anyone can join or leave the group.
     */
    Open = 0,

    /**
     * Members are only added or removed by group owners.
     */
    Closed = 1,

    /**
     * Anyone can request to join or leave but must be approved by group owners.
     */
    OwnerApproval = 2

}



/**
 * @name ContactGroupMembershipEditViewModel
 * @description
 * The Contact Group Membership table contains information about contacts who are members of contact groups.
 */
export class ContactGroupMembershipEditViewModel {

    /**
     * @name ContactGroupMembershipId
     * @description 
     * The id of the Contact Group Membership Record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactGroupMembershipId: number = null;

    /**
     * @name GroupContactId
     * @description 
     * The id of the contact group.
     * @servertype Int64
     * @type {number}
     */
    GroupContactId: number = null;

    /**
     * @name GroupContactName
     * @servertype String
     * @type {string}
     */
    GroupContactName: string = "";

    /**
     * @name MemberContactId
     * @description 
     * The id of the contact that is a member of the group.
     * @servertype Int64
     * @type {number}
     */
    MemberContactId: number = null;

    /**
     * @name MemberContactName
     * @servertype String
     * @type {string}
     */
    MemberContactName: string = "";

    /**
     * @name Description
     * @description 
     * The description for this group membership if needed.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Status
     * @description 
     * The status of this group member.  Possible values include: 
     * P = Pending (Request to join is pending group owner approval)
     * A = Active (Member is active in group)
     * I = Inactive (Member is defined as inactive based on group business rules)
     * H = Hold (Member is on hold and cannot access group resources until status is changed)
     * E = Expired (Member is out of valid date range for group membership)
     * L = Leave Requested (Member request to leave the group is pending group owner approval and is treated as active until request
     * is processed)
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when group membership is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when group membership is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name ValidDateRangeTriggersExecuted
     * @description 
     * When true any needed valid date range triggers have been executed.  For example, the valid date range will impact which groups
     * a contact is a member of which can in turn impact the scope for a contact which needs to be recorded in the life cycle table.
     *  An admin job takes care of doing that and then sets this flag true to indicate it's been completed.  Any valid date range
     * changes should set this flag to false.
     * @servertype Boolean
     * @type {boolean}
     */
    ValidDateRangeTriggersExecuted: boolean = false;

    /**
     * @name GroupOwner
     * @description 
     * When true the member is a group owner.
     * @servertype Boolean
     * @type {boolean}
     */
    GroupOwner: boolean = false;

    /**
     * @name CanImpersonateGroup
     * @description 
     * When true the member is allowed to impersonate the group and actions taken in the context of the group can appear to have
     * been done by the group as opposed to the member.
     * @servertype Boolean
     * @type {boolean}
     */
    CanImpersonateGroup: boolean = false;

    /**
     * @name Properties
     * @description 
     * Properties related to this group membership. The format dictated by the group contact's group membership properties property.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ContactRoleEditViewModel
 * @description
 * The Contact Role table contains roles for contacts.  Roles are used in the assignment of security credentials, role-based
 * tasks for issues, access to reports, etc.
 */
export class ContactRoleEditViewModel {

    /**
     * @name ContactRoleId
     * @description 
     * The id of the Contact Role.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactRoleId: number = null;

    /**
     * @name ContactId
     * @description 
     * The id of the contact this role record belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name RoleId
     * @description 
     * The id of the role assigned to the contact.
     * @servertype Int64
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name RoleDescription
     * @servertype String
     * @type {string}
     */
    RoleDescription: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the role is enabled.  Setting to false provides an easy way to cause a role to be temporarily ignored until later
     * enabled again.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this role assignment is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this role assignment is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name RoleRatio
     * @description 
     * Integer that represents the ratio of time spent in this role.  When doing role based assignments a person is chosen from
     * the role list using a load balancing method.  For individuals who have more than one role in an organization a ratio is used
     * to determine how much time is spent in any given role.  For example, an individual who had 2 roles, role A and role B but
     * spent 75% of his time doing work in role A would have his role A ratio set to 3 while his role B ratio was set to 1.  Note
     * that null and zero ratio values are treated as 1.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RoleRatio: number = 1;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Contact List
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class ContactListViewModel {

    /**
     * @name ContactId
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ExternalContactId
     * @servertype String
     * @type {string}
     */
    ExternalContactId: string = null;

    /**
     * @name ParentContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentContactId: number = null;

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name AddressType
     * @servertype String
     * @type {string}
     */
    AddressType: string = "";

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Flags
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

}



/**
 * @name Contact
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class ContactEditViewModel {

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ExternalContactId
     * @servertype String
     * @type {string}
     */
    ExternalContactId: string = null;

    /**
     * @name ParentContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentContactId: number = null;

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name AddressType
     * @servertype String
     * @type {string}
     */
    AddressType: string = "";

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name MiddleName
     * @servertype String
     * @type {string}
     */
    MiddleName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name OtherName
     * @servertype String
     * @type {string}
     */
    OtherName: string = "";

    /**
     * @name CourtesyTitle
     * @servertype String
     * @type {string}
     */
    CourtesyTitle: string = "";

    /**
     * @name PostNominalTitles
     * @servertype String
     * @type {string}
     */
    PostNominalTitles: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name County
     * @description 
     * County.
     * @servertype String
     * @type {string}
     */
    County: string = "";

    /**
     * @name Country
     * @description 
     * Country.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name AddressBlock
     * @servertype String
     * @type {string}
     */
    AddressBlock: string = "";

    /**
     * @name AddressSentence
     * @servertype String
     * @type {string}
     */
    AddressSentence: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Fax
     * @description 
     * Fax.
     * @servertype String
     * @type {string}
     */
    Fax: string = "";

    /**
     * @name HomePhone
     * @servertype String
     * @type {string}
     */
    HomePhone: string = "";

    /**
     * @name Cellular
     * @description 
     * Cellular.
     * @servertype String
     * @type {string}
     */
    Cellular: string = "";

    /**
     * @name AlternatePhone
     * @servertype String
     * @type {string}
     */
    AlternatePhone: string = "";

    /**
     * @name Extension
     * @description 
     * Extension.
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name AlternateEmail
     * @servertype String
     * @type {string}
     */
    AlternateEmail: string = "";

    /**
     * @name ImAddress
     * @servertype String
     * @type {string}
     */
    ImAddress: string = "";

    /**
     * @name ImNetwork
     * @servertype String
     * @type {string}
     */
    ImNetwork: string = "";

    /**
     * @name AlternateImAddress
     * @servertype String
     * @type {string}
     */
    AlternateImAddress: string = "";

    /**
     * @name AlternateImNetwork
     * @servertype String
     * @type {string}
     */
    AlternateImNetwork: string = "";

    /**
     * @name WebSite
     * @servertype String
     * @type {string}
     */
    WebSite: string = "";

    /**
     * @name FtpSite
     * @servertype String
     * @type {string}
     */
    FtpSite: string = "";

    /**
     * @name TaxId
     * @servertype String
     * @type {string}
     */
    TaxId: string = "";

    /**
     * @name TickerSymbol
     * @servertype String
     * @type {string}
     */
    TickerSymbol: string = "";

    /**
     * @name SicCode
     * @servertype String
     * @type {string}
     */
    SicCode: string = "";

    /**
     * @name Industry
     * @description 
     * Industry.
     * @servertype String
     * @type {string}
     */
    Industry: string = "";

    /**
     * @name ReferredBy
     * @servertype String
     * @type {string}
     */
    ReferredBy: string = "";

    /**
     * @name RepresentativeDirectoryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RepresentativeDirectoryId: number = 0;

    /**
     * @name RepresentativeDirectoryName
     * @servertype String
     * @type {string}
     */
    RepresentativeDirectoryName: string = "";

    /**
     * @name AgentId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AgentId: number = 0;

    /**
     * @name AgentName
     * @servertype String
     * @type {string}
     */
    AgentName: string = "";

    /**
     * @name WebLanguage
     * @servertype String
     * @type {string}
     */
    WebLanguage: string = "";

    /**
     * @name IvrLanguage
     * @servertype String
     * @type {string}
     */
    IvrLanguage: string = "";

    /**
     * @name TimeZoneId
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = "";

    /**
     * @name Salutation
     * @description 
     * Salutation for this contact.
     * @servertype String
     * @type {string}
     */
    Salutation: string = "";

    /**
     * @name AssistantName
     * @servertype String
     * @type {string}
     */
    AssistantName: string = "";

    /**
     * @name ManagerName
     * @servertype String
     * @type {string}
     */
    ManagerName: string = "";

    /**
     * @name SpouseName
     * @servertype String
     * @type {string}
     */
    SpouseName: string = "";

    /**
     * @name ChildrenNames
     * @servertype String
     * @type {string}
     */
    ChildrenNames: string = "";

    /**
     * @name DepartmentName
     * @servertype String
     * @type {string}
     */
    DepartmentName: string = "";

    /**
     * @name Office
     * @description 
     * Office.
     * @servertype String
     * @type {string}
     */
    Office: string = "";

    /**
     * @name Profession
     * @description 
     * Profession.
     * @servertype String
     * @type {string}
     */
    Profession: string = "";

    /**
     * @name Hobbies
     * @description 
     * Hobbies.
     * @servertype String
     * @type {string}
     */
    Hobbies: string = "";

    /**
     * @name Gender
     * @description 
     * Gender.  Possible values include:
     * M = Male
     * F = Female
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    Gender: string = "U";

    /**
     * @name BirthDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BirthDate: Date = null;

    /**
     * @name AnniversaryDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AnniversaryDate: Date = null;

    /**
     * @name NotifyAlert
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyAlert: boolean = false;

    /**
     * @name NotifyIssueChange
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChange: boolean = false;

    /**
     * @name NotifyIssueChangeGlobal
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChangeGlobal: boolean = false;

    /**
     * @name NotifyProductInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyProductInfo: boolean = false;

    /**
     * @name NotifyVendorInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyVendorInfo: boolean = false;

    /**
     * @name NotifyThirdPartyInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyThirdPartyInfo: boolean = false;

    /**
     * @name NotifyFormat
     * @servertype String
     * @type {string}
     */
    NotifyFormat: string = "";

    /**
     * @name NotifyStandardStartTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardStartTime: string = "";

    /**
     * @name NotifyStandardEndTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardEndTime: string = "";

    /**
     * @name NotifyUrgentStartTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentStartTime: string = "";

    /**
     * @name NotifyUrgentEndTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentEndTime: string = "";

    /**
     * @name Status
     * @description 
     * Status of the contact.  Possible values include: 
     * V = Invited (Contact was sent invitation to access the system but has not yet done so.)
     * P = Pending (Contact is pending until approved to be active.  While pending the contact has no access.)
     * A = Active (Contact is active.)
     * I = Inactive (Contact is inactive as determined by business rule with no special treatment.)
     * S = Suspended (Contact is suspended.)
     * C = Closed (Contact is closed or cancelled.)
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

    /**
     * @name StatusLocked
     * @servertype Boolean
     * @type {boolean}
     */
    StatusLocked: boolean = false;

    /**
     * @name CreateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CreateDateTime: Date = null;

    /**
     * @name SuspendDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendDateTime: Date = null;

    /**
     * @name CloseDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CloseDateTime: Date = null;

    /**
     * @name ReactivateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReactivateDateTime: Date = null;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name CurrencyFormat
     * @servertype String
     * @type {string}
     */
    CurrencyFormat: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Flags
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Properties
     * @description 
     * Properties for this contact.  The format is dependent on the contact type of the contact.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ContactBulkAddRequestViewModel
 */
export class ContactBulkAddRequestViewModel {

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactLevel
     * @servertype String
     * @type {string}
     */
    ContactLevel: string = "";

    /**
     * @name Groups
     * @servertype ContactGroupMembershipEditViewModel array
     * @type {ContactGroupMembershipEditViewModel[]}
     */
    Groups: ContactGroupMembershipEditViewModel[] = [];

    /**
     * @name EmailAddresses
     * @servertype String
     * @type {string}
     */
    EmailAddresses: string = "";

}



/**
 * @name ContactBulkAddResultViewModel
 */
export class ContactBulkAddResultViewModel {

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactLevel
     * @servertype String
     * @type {string}
     */
    ContactLevel: string = "";

    /**
     * @name Groups
     * @servertype ContactGroupMembershipEditViewModel array
     * @type {ContactGroupMembershipEditViewModel[]}
     */
    Groups: ContactGroupMembershipEditViewModel[] = [];

    /**
     * @name EmailAddressesAdded
     * @servertype string array
     * @type {string[]}
     */
    EmailAddressesAdded: string[] = [];

    /**
     * @name EmailAddressesRejected
     * @servertype string array
     * @type {string[]}
     */
    EmailAddressesRejected: string[] = [];

}



/**
 * @name ContactWorkScheduleExceptionEditViewModel
 * @description
 * The Contact Work Schedule Exception table contains information about holidays, vacations, etc. that alter the defined work
 * schedule.
 */
export class ContactWorkScheduleExceptionEditViewModel {

    /**
     * @name ContactWorkScheduleExceptionId
     * @description 
     * The id of the Contact Work Schedule Exception.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactWorkScheduleExceptionId: number = null;

    /**
     * @name ContactId
     * @description 
     * The id of the contact this work schedule exception record belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name Description
     * @description 
     * The description for this work schedule exception.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ExceptionStartDateTime
     * @description 
     * The start date and time of the exception.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExceptionStartDateTime: Date = null;

    /**
     * @name ExceptionEndDateTime
     * @description 
     * The end date and time of the exception.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExceptionEndDateTime: Date = null;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ContactWorkScheduleEditViewModel
 * @description
 * The Contact Work Schedule table contains work schedule information for contact entries.  Work schedules are used in the assignment
 * of tasks for issues, etc.
 */
export class ContactWorkScheduleEditViewModel {

    /**
     * @name ContactWorkScheduleId
     * @description 
     * The id of the Contact Work Schedule.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactWorkScheduleId: number = null;

    /**
     * @name ContactId
     * @description 
     * The id of the contact this work schedule record belongs to.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the work schedule is enabled.  Setting to false provides an easy way to cause a work schedule to be temporarily
     * ignored until later enabled again.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this work schedule assignment is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this work schedule assignment is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Days
     * @description 
     * A string of codes that represent the days included with this schedule where 
     * S = Sunday
     * M = Monday
     * T = Tuesday
     * W = Wednesday
     * R = Thursday
     * F = Friday
     * U = Saturday
     * H = Holiday
     * @servertype String
     * @type {string}
     */
    Days: string = "";

    /**
     * @name StartTime
     * @description 
     * A string in the format HH:MM:SS representing the start time.
     * @servertype String
     * @type {string}
     */
    StartTime: string = "";

    /**
     * @name EndTime
     * @description 
     * A string in the format HH:MM:SS representing the end time.
     * @servertype String
     * @type {string}
     */
    EndTime: string = "";

    /**
     * @name NumberOfMinutes
     * @description 
     * Number of minutes calculated from Start Time through End Time stored here as an integer for quick calculations when assigning
     * tasks for issues, etc.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NumberOfMinutes: number = 0;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Customer List
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class CustomerListViewModel {

    /**
     * @name CustomerId
     * @servertype Int64
     * @type {number}
     */
    CustomerId: number = 0;

    /**
     * @name ExternalCustomerId
     * @servertype String
     * @type {string}
     */
    ExternalCustomerId: string = "";

    /**
     * @name CustomerName
     * @servertype String
     * @type {string}
     */
    CustomerName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

}



/**
 * @name Group List
 */
export class GroupListViewModel {

    /**
     * @name GroupId
     * @servertype Int64
     * @type {number}
     */
    GroupId: number = 0;

    /**
     * @name ExternalGroupId
     * @servertype String
     * @type {string}
     */
    ExternalGroupId: string = "";

    /**
     * @name GroupName
     * @servertype String
     * @type {string}
     */
    GroupName: string = "";

    /**
     * @name Email
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name Scope
     * @servertype String
     * @type {string}
     */
    Scope: string = "";

}



/**
 * @name Group
 */
export class GroupEditViewModel {

    /**
     * @name GroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    GroupId: number = 0;

    /**
     * @name ExternalGroupId
     * @servertype String
     * @type {string}
     */
    ExternalGroupId: string = "";

    /**
     * @name ParentGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentGroupId: number = 0;

    /**
     * @name GroupName
     * @servertype String
     * @type {string}
     */
    GroupName: string = "";

    /**
     * @name Email
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name AlternateEmail
     * @servertype String
     * @type {string}
     */
    AlternateEmail: string = "";

    /**
     * @name WebSite
     * @servertype String
     * @type {string}
     */
    WebSite: string = "";

    /**
     * @name GroupMembershipAllowedContactTypes
     * @servertype string array
     * @type {string[]}
     */
    GroupMembershipAllowedContactTypes: string[] = [];

    /**
     * @name MembersInheritRolesFromChildGroups
     * @servertype Boolean
     * @type {boolean}
     */
    MembersInheritRolesFromChildGroups: boolean = false;

    /**
     * @name MembersInheritScopeFromChildGroups
     * @servertype Boolean
     * @type {boolean}
     */
    MembersInheritScopeFromChildGroups: boolean = false;

    /**
     * @name JoiningGroup
     * @servertype IB.Web.API.Models.v5.GroupMembershipApproval Enum
     * @type {GroupMembershipApproval}
     */
    JoiningGroup: GroupMembershipApproval = null;

    /**
     * @name LeavingGroup
     * @servertype IB.Web.API.Models.v5.GroupMembershipApproval Enum
     * @type {GroupMembershipApproval}
     */
    LeavingGroup: GroupMembershipApproval = null;

    /**
     * @name MemberStatusDaysBeforeConsideredInactive
     * @servertype Int32
     * @type {number}
     */
    MemberStatusDaysBeforeConsideredInactive: number = 0;

    /**
     * @name GroupMembershipProperties
     * @servertype PropertyMetaDataViewModel array
     * @type {m.PropertyMetaDataViewModel[]}
     */
    GroupMembershipProperties: m.PropertyMetaDataViewModel[] = [];

    /**
     * @name Scope
     * @servertype String
     * @type {string}
     */
    Scope: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Members
     * @servertype ContactGroupMembershipEditViewModel array
     * @type {ContactGroupMembershipEditViewModel[]}
     */
    Members: ContactGroupMembershipEditViewModel[] = [];

    /**
     * @name Roles
     * @servertype ContactRoleEditViewModel array
     * @type {ContactRoleEditViewModel[]}
     */
    Roles: ContactRoleEditViewModel[] = [];

    /**
     * @name Children
     * @servertype GroupEditViewModel array
     * @type {GroupEditViewModel[]}
     */
    Children: GroupEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Directory List
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class DirectoryListViewModel {

    /**
     * @name DirectoryId
     * @servertype Int64
     * @type {number}
     */
    DirectoryId: number = 0;

    /**
     * @name ExternalDirectoryId
     * @servertype String
     * @type {string}
     */
    ExternalDirectoryId: string = "";

    /**
     * @name DirectoryName
     * @servertype String
     * @type {string}
     */
    DirectoryName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Flags
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

}



/**
 * @name DirectoryEditViewModel
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class DirectoryEditViewModel {

    /**
     * @name DirectoryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DirectoryId: number = 0;

    /**
     * @name ExternalDirectoryId
     * @servertype String
     * @type {string}
     */
    ExternalDirectoryId: string = "";

    /**
     * @name DirectoryName
     * @servertype String
     * @type {string}
     */
    DirectoryName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name MiddleName
     * @servertype String
     * @type {string}
     */
    MiddleName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name OtherName
     * @servertype String
     * @type {string}
     */
    OtherName: string = "";

    /**
     * @name CourtesyTitle
     * @servertype String
     * @type {string}
     */
    CourtesyTitle: string = "";

    /**
     * @name PostNominalTitles
     * @servertype String
     * @type {string}
     */
    PostNominalTitles: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name County
     * @description 
     * County.
     * @servertype String
     * @type {string}
     */
    County: string = "";

    /**
     * @name Country
     * @description 
     * Country.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Fax
     * @description 
     * Fax.
     * @servertype String
     * @type {string}
     */
    Fax: string = "";

    /**
     * @name HomePhone
     * @servertype String
     * @type {string}
     */
    HomePhone: string = "";

    /**
     * @name Cellular
     * @description 
     * Cellular.
     * @servertype String
     * @type {string}
     */
    Cellular: string = "";

    /**
     * @name AlternatePhone
     * @servertype String
     * @type {string}
     */
    AlternatePhone: string = "";

    /**
     * @name Extension
     * @description 
     * Extension.
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name AlternateEmail
     * @servertype String
     * @type {string}
     */
    AlternateEmail: string = "";

    /**
     * @name ImAddress
     * @servertype String
     * @type {string}
     */
    ImAddress: string = "";

    /**
     * @name ImNetwork
     * @servertype String
     * @type {string}
     */
    ImNetwork: string = "";

    /**
     * @name AlternateImAddress
     * @servertype String
     * @type {string}
     */
    AlternateImAddress: string = "";

    /**
     * @name AlternateImNetwork
     * @servertype String
     * @type {string}
     */
    AlternateImNetwork: string = "";

    /**
     * @name WebSite
     * @servertype String
     * @type {string}
     */
    WebSite: string = "";

    /**
     * @name FtpSite
     * @servertype String
     * @type {string}
     */
    FtpSite: string = "";

    /**
     * @name TaxId
     * @servertype String
     * @type {string}
     */
    TaxId: string = "";

    /**
     * @name TickerSymbol
     * @servertype String
     * @type {string}
     */
    TickerSymbol: string = "";

    /**
     * @name SicCode
     * @servertype String
     * @type {string}
     */
    SicCode: string = "";

    /**
     * @name Industry
     * @description 
     * Industry.
     * @servertype String
     * @type {string}
     */
    Industry: string = "";

    /**
     * @name ReferredBy
     * @servertype String
     * @type {string}
     */
    ReferredBy: string = "";

    /**
     * @name RepresentativeDirectoryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RepresentativeDirectoryId: number = 0;

    /**
     * @name RepresentativeDirectoryName
     * @servertype String
     * @type {string}
     */
    RepresentativeDirectoryName: string = "";

    /**
     * @name WebLanguage
     * @servertype String
     * @type {string}
     */
    WebLanguage: string = "";

    /**
     * @name IvrLanguage
     * @servertype String
     * @type {string}
     */
    IvrLanguage: string = "";

    /**
     * @name TimeZoneId
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = "";

    /**
     * @name Salutation
     * @description 
     * Salutation for this contact.
     * @servertype String
     * @type {string}
     */
    Salutation: string = "";

    /**
     * @name AssistantName
     * @servertype String
     * @type {string}
     */
    AssistantName: string = "";

    /**
     * @name ManagerName
     * @servertype String
     * @type {string}
     */
    ManagerName: string = "";

    /**
     * @name SpouseName
     * @servertype String
     * @type {string}
     */
    SpouseName: string = "";

    /**
     * @name ChildrenNames
     * @servertype String
     * @type {string}
     */
    ChildrenNames: string = "";

    /**
     * @name DepartmentName
     * @servertype String
     * @type {string}
     */
    DepartmentName: string = "";

    /**
     * @name Office
     * @description 
     * Office.
     * @servertype String
     * @type {string}
     */
    Office: string = "";

    /**
     * @name Profession
     * @description 
     * Profession.
     * @servertype String
     * @type {string}
     */
    Profession: string = "";

    /**
     * @name Hobbies
     * @description 
     * Hobbies.
     * @servertype String
     * @type {string}
     */
    Hobbies: string = "";

    /**
     * @name Gender
     * @description 
     * Gender.  Possible values include:
     * M = Male
     * F = Female
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    Gender: string = "U";

    /**
     * @name BirthDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BirthDate: Date = null;

    /**
     * @name AnniversaryDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AnniversaryDate: Date = null;

    /**
     * @name NotifyAlert
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyAlert: boolean = false;

    /**
     * @name NotifyIssueChange
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChange: boolean = false;

    /**
     * @name NotifyIssueChangeGlobal
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChangeGlobal: boolean = false;

    /**
     * @name NotifyProductInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyProductInfo: boolean = false;

    /**
     * @name NotifyVendorInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyVendorInfo: boolean = false;

    /**
     * @name NotifyThirdPartyInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyThirdPartyInfo: boolean = false;

    /**
     * @name NotifyFormat
     * @servertype String
     * @type {string}
     */
    NotifyFormat: string = "";

    /**
     * @name NotifyStandardStartTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardStartTime: string = "";

    /**
     * @name NotifyStandardEndTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardEndTime: string = "";

    /**
     * @name NotifyUrgentStartTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentStartTime: string = "";

    /**
     * @name NotifyUrgentEndTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentEndTime: string = "";

    /**
     * @name Status
     * @description 
     * Status of the contact.  Possible values include: 
     * V = Invited (Contact was sent invitation to access the system but has not yet done so.)
     * P = Pending (Contact is pending until approved to be active.  While pending the contact has no access.)
     * A = Active (Contact is active.)
     * I = Inactive (Contact is inactive as determined by business rule with no special treatment.)
     * S = Suspended (Contact is suspended.)
     * C = Closed (Contact is closed or cancelled.)
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

    /**
     * @name StatusLocked
     * @servertype Boolean
     * @type {boolean}
     */
    StatusLocked: boolean = false;

    /**
     * @name CreateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CreateDateTime: Date = null;

    /**
     * @name SuspendDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendDateTime: Date = null;

    /**
     * @name CloseDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CloseDateTime: Date = null;

    /**
     * @name ReactivateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReactivateDateTime: Date = null;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name CurrencyFormat
     * @servertype String
     * @type {string}
     */
    CurrencyFormat: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Flags
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Location List
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class LocationListViewModel {

    /**
     * @name LocationId
     * @servertype Int64
     * @type {number}
     */
    LocationId: number = 0;

    /**
     * @name ExternalLocationId
     * @servertype String
     * @type {string}
     */
    ExternalLocationId: string = "";

    /**
     * @name LocationName
     * @servertype String
     * @type {string}
     */
    LocationName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

}



/**
 * @name LocationEditViewModel
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class LocationEditViewModel {

    /**
     * @name LocationId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LocationId: number = 0;

    /**
     * @name ExternalLocationId
     * @servertype String
     * @type {string}
     */
    ExternalLocationId: string = "";

    /**
     * @name LocationName
     * @servertype String
     * @type {string}
     */
    LocationName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name MiddleName
     * @servertype String
     * @type {string}
     */
    MiddleName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name OtherName
     * @servertype String
     * @type {string}
     */
    OtherName: string = "";

    /**
     * @name CourtesyTitle
     * @servertype String
     * @type {string}
     */
    CourtesyTitle: string = "";

    /**
     * @name PostNominalTitles
     * @servertype String
     * @type {string}
     */
    PostNominalTitles: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name County
     * @description 
     * County.
     * @servertype String
     * @type {string}
     */
    County: string = "";

    /**
     * @name Country
     * @description 
     * Country.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Fax
     * @description 
     * Fax.
     * @servertype String
     * @type {string}
     */
    Fax: string = "";

    /**
     * @name HomePhone
     * @servertype String
     * @type {string}
     */
    HomePhone: string = "";

    /**
     * @name Cellular
     * @description 
     * Cellular.
     * @servertype String
     * @type {string}
     */
    Cellular: string = "";

    /**
     * @name AlternatePhone
     * @servertype String
     * @type {string}
     */
    AlternatePhone: string = "";

    /**
     * @name Extension
     * @description 
     * Extension.
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name AlternateEmail
     * @servertype String
     * @type {string}
     */
    AlternateEmail: string = "";

    /**
     * @name ImAddress
     * @servertype String
     * @type {string}
     */
    ImAddress: string = "";

    /**
     * @name ImNetwork
     * @servertype String
     * @type {string}
     */
    ImNetwork: string = "";

    /**
     * @name AlternateImAddress
     * @servertype String
     * @type {string}
     */
    AlternateImAddress: string = "";

    /**
     * @name AlternateImNetwork
     * @servertype String
     * @type {string}
     */
    AlternateImNetwork: string = "";

    /**
     * @name WebSite
     * @servertype String
     * @type {string}
     */
    WebSite: string = "";

    /**
     * @name FtpSite
     * @servertype String
     * @type {string}
     */
    FtpSite: string = "";

    /**
     * @name TaxId
     * @servertype String
     * @type {string}
     */
    TaxId: string = "";

    /**
     * @name TickerSymbol
     * @servertype String
     * @type {string}
     */
    TickerSymbol: string = "";

    /**
     * @name SicCode
     * @servertype String
     * @type {string}
     */
    SicCode: string = "";

    /**
     * @name Industry
     * @description 
     * Industry.
     * @servertype String
     * @type {string}
     */
    Industry: string = "";

    /**
     * @name ReferredBy
     * @servertype String
     * @type {string}
     */
    ReferredBy: string = "";

    /**
     * @name RepresentativeDirectoryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RepresentativeDirectoryId: number = 0;

    /**
     * @name RepresentativeDirectoryName
     * @servertype String
     * @type {string}
     */
    RepresentativeDirectoryName: string = "";

    /**
     * @name WebLanguage
     * @servertype String
     * @type {string}
     */
    WebLanguage: string = "";

    /**
     * @name IvrLanguage
     * @servertype String
     * @type {string}
     */
    IvrLanguage: string = "";

    /**
     * @name TimeZoneId
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = "";

    /**
     * @name Salutation
     * @description 
     * Salutation for this contact.
     * @servertype String
     * @type {string}
     */
    Salutation: string = "";

    /**
     * @name AssistantName
     * @servertype String
     * @type {string}
     */
    AssistantName: string = "";

    /**
     * @name ManagerName
     * @servertype String
     * @type {string}
     */
    ManagerName: string = "";

    /**
     * @name SpouseName
     * @servertype String
     * @type {string}
     */
    SpouseName: string = "";

    /**
     * @name ChildrenNames
     * @servertype String
     * @type {string}
     */
    ChildrenNames: string = "";

    /**
     * @name DepartmentName
     * @servertype String
     * @type {string}
     */
    DepartmentName: string = "";

    /**
     * @name Office
     * @description 
     * Office.
     * @servertype String
     * @type {string}
     */
    Office: string = "";

    /**
     * @name Profession
     * @description 
     * Profession.
     * @servertype String
     * @type {string}
     */
    Profession: string = "";

    /**
     * @name Hobbies
     * @description 
     * Hobbies.
     * @servertype String
     * @type {string}
     */
    Hobbies: string = "";

    /**
     * @name Gender
     * @description 
     * Gender.  Possible values include:
     * M = Male
     * F = Female
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    Gender: string = "U";

    /**
     * @name BirthDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BirthDate: Date = null;

    /**
     * @name AnniversaryDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AnniversaryDate: Date = null;

    /**
     * @name NotifyAlert
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyAlert: boolean = false;

    /**
     * @name NotifyIssueChange
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChange: boolean = false;

    /**
     * @name NotifyIssueChangeGlobal
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChangeGlobal: boolean = false;

    /**
     * @name NotifyProductInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyProductInfo: boolean = false;

    /**
     * @name NotifyVendorInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyVendorInfo: boolean = false;

    /**
     * @name NotifyThirdPartyInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyThirdPartyInfo: boolean = false;

    /**
     * @name NotifyFormat
     * @servertype String
     * @type {string}
     */
    NotifyFormat: string = "";

    /**
     * @name NotifyStandardStartTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardStartTime: string = "";

    /**
     * @name NotifyStandardEndTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardEndTime: string = "";

    /**
     * @name NotifyUrgentStartTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentStartTime: string = "";

    /**
     * @name NotifyUrgentEndTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentEndTime: string = "";

    /**
     * @name Status
     * @description 
     * Status of the contact.  Possible values include: 
     * V = Invited (Contact was sent invitation to access the system but has not yet done so.)
     * P = Pending (Contact is pending until approved to be active.  While pending the contact has no access.)
     * A = Active (Contact is active.)
     * I = Inactive (Contact is inactive as determined by business rule with no special treatment.)
     * S = Suspended (Contact is suspended.)
     * C = Closed (Contact is closed or cancelled.)
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

    /**
     * @name StatusLocked
     * @servertype Boolean
     * @type {boolean}
     */
    StatusLocked: boolean = false;

    /**
     * @name CreateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CreateDateTime: Date = null;

    /**
     * @name SuspendDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendDateTime: Date = null;

    /**
     * @name CloseDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CloseDateTime: Date = null;

    /**
     * @name ReactivateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReactivateDateTime: Date = null;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name CurrencyFormat
     * @servertype String
     * @type {string}
     */
    CurrencyFormat: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name SalesOpportunityEditViewModel
 * @description
 * The Sales Opportunity table contains information about sales opportunities.
 */
export class SalesOpportunityEditViewModel {

    /**
     * @name SalesOpportunityId
     * @description 
     * Sales Opportunity Id uniquely identifies this sales opportunity.
     * @servertype Int64
     * @type {number}
     */
    SalesOpportunityId: number = null;

    /**
     * @name ContactId
     * @description 
     * The Contact Id the sales opportunity is for.  This is typically a customer or prospect.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name Description
     * @description 
     * A description of the sales opportunity.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name IsoCurrencyCode
     * @description 
     * ISO Currency Code for currency for the amount.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Amount
     * @description 
     * The amount of the sales opportunity.
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name CreatedDateTime
     * @description 
     * The date and time when the sales opportunity was created.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CreatedDateTime: Date = new Date();

    /**
     * @name ExpiresDateTime
     * @description 
     * The date and time when the sales opportunity expires.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpiresDateTime: Date = null;

    /**
     * @name ForecastedCloseDateTime
     * @description 
     * The date and time when the sales opportunity is forecasted to close.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ForecastedCloseDateTime: Date = null;

    /**
     * @name Probability
     * @description 
     * The probability for this sales opportunity to close from 0% to 100%.
     * @servertype Byte (nullable)
     * @type {number}
     */
    Probability: number = 0;

    /**
     * @name SalesStage
     * @description 
     * The sales stage for this opportunity.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    SalesStage: string = "";

    /**
     * @name Competitors
     * @description 
     * This is a list of competitors for this opportunity.  Values for this column are taken from a system pick list.
     * @servertype string array
     * @type {string[]}
     */
    Competitors: string[] = [];

    /**
     * @name RepresentativeContactId
     * @description 
     * The Contact Id that is the representative for this sales opportunity.  This is typically a directory or agent.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RepresentativeContactId: number = null;

    /**
     * @name RepresentativeContactName
     * @servertype String
     * @type {string}
     */
    RepresentativeContactName: string = "";

    /**
     * @name RegardingResourceType
     * @description 
     * The resource type (e.g. table, etc.) this sales opportunity is regarding (if any).
     * @servertype String
     * @type {string}
     */
    RegardingResourceType: string = "";

    /**
     * @name RegardingResourceId
     * @description 
     * The resource id is the key of the resource type this sales opportunity is regarding (if any).  For example, if the regarding
     * resource type is InventoryType this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RegardingResourceId: number = 0;

    /**
     * @name RegardingResourceId2
     * @description 
     * The resource id 2 is the key of the resource type this sales opportunity is regarding (if any) when that id is a string data
     * type.
     * @servertype String
     * @type {string}
     */
    RegardingResourceId2: string = "";

    /**
     * @name Result
     * @description 
     * The result for this sales opportunity.  Possible values include:
     * O = Opportunity (no result yet)
     * C = Closed (won)
     * L = Lost
     * @servertype String
     * @type {string}
     */
    Result: string = "O";

    /**
     * @name ResultReason
     * @description 
     * The reason for the result for this opportunity.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    ResultReason: string = "";

    /**
     * @name Tags
     * @description 
     * A comma separated list of tags for this sales opportunity.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name Comments
     * @description 
     * Comments for this sales opportunity.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Vendor List
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class VendorListViewModel {

    /**
     * @name VendorId
     * @servertype Int64
     * @type {number}
     */
    VendorId: number = 0;

    /**
     * @name ExternalVendorId
     * @servertype String
     * @type {string}
     */
    ExternalVendorId: string = "";

    /**
     * @name VendorName
     * @servertype String
     * @type {string}
     */
    VendorName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

}



/**
 * @name VendorEditViewModel
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class VendorEditViewModel {

    /**
     * @name VendorId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VendorId: number = 0;

    /**
     * @name ExternalVendorId
     * @servertype String
     * @type {string}
     */
    ExternalVendorId: string = "";

    /**
     * @name VendorName
     * @servertype String
     * @type {string}
     */
    VendorName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name MiddleName
     * @servertype String
     * @type {string}
     */
    MiddleName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name OtherName
     * @servertype String
     * @type {string}
     */
    OtherName: string = "";

    /**
     * @name CourtesyTitle
     * @servertype String
     * @type {string}
     */
    CourtesyTitle: string = "";

    /**
     * @name PostNominalTitles
     * @servertype String
     * @type {string}
     */
    PostNominalTitles: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name County
     * @description 
     * County.
     * @servertype String
     * @type {string}
     */
    County: string = "";

    /**
     * @name Country
     * @description 
     * Country.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Fax
     * @description 
     * Fax.
     * @servertype String
     * @type {string}
     */
    Fax: string = "";

    /**
     * @name HomePhone
     * @servertype String
     * @type {string}
     */
    HomePhone: string = "";

    /**
     * @name Cellular
     * @description 
     * Cellular.
     * @servertype String
     * @type {string}
     */
    Cellular: string = "";

    /**
     * @name AlternatePhone
     * @servertype String
     * @type {string}
     */
    AlternatePhone: string = "";

    /**
     * @name Extension
     * @description 
     * Extension.
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name AlternateEmail
     * @servertype String
     * @type {string}
     */
    AlternateEmail: string = "";

    /**
     * @name ImAddress
     * @servertype String
     * @type {string}
     */
    ImAddress: string = "";

    /**
     * @name ImNetwork
     * @servertype String
     * @type {string}
     */
    ImNetwork: string = "";

    /**
     * @name AlternateImAddress
     * @servertype String
     * @type {string}
     */
    AlternateImAddress: string = "";

    /**
     * @name AlternateImNetwork
     * @servertype String
     * @type {string}
     */
    AlternateImNetwork: string = "";

    /**
     * @name WebSite
     * @servertype String
     * @type {string}
     */
    WebSite: string = "";

    /**
     * @name FtpSite
     * @servertype String
     * @type {string}
     */
    FtpSite: string = "";

    /**
     * @name TaxId
     * @servertype String
     * @type {string}
     */
    TaxId: string = "";

    /**
     * @name TickerSymbol
     * @servertype String
     * @type {string}
     */
    TickerSymbol: string = "";

    /**
     * @name SicCode
     * @servertype String
     * @type {string}
     */
    SicCode: string = "";

    /**
     * @name Industry
     * @description 
     * Industry.
     * @servertype String
     * @type {string}
     */
    Industry: string = "";

    /**
     * @name ReferredBy
     * @servertype String
     * @type {string}
     */
    ReferredBy: string = "";

    /**
     * @name RepresentativeDirectoryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RepresentativeDirectoryId: number = 0;

    /**
     * @name RepresentativeDirectoryName
     * @servertype String
     * @type {string}
     */
    RepresentativeDirectoryName: string = "";

    /**
     * @name WebLanguage
     * @servertype String
     * @type {string}
     */
    WebLanguage: string = "";

    /**
     * @name IvrLanguage
     * @servertype String
     * @type {string}
     */
    IvrLanguage: string = "";

    /**
     * @name TimeZoneId
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = "";

    /**
     * @name Salutation
     * @description 
     * Salutation for this contact.
     * @servertype String
     * @type {string}
     */
    Salutation: string = "";

    /**
     * @name AssistantName
     * @servertype String
     * @type {string}
     */
    AssistantName: string = "";

    /**
     * @name ManagerName
     * @servertype String
     * @type {string}
     */
    ManagerName: string = "";

    /**
     * @name SpouseName
     * @servertype String
     * @type {string}
     */
    SpouseName: string = "";

    /**
     * @name ChildrenNames
     * @servertype String
     * @type {string}
     */
    ChildrenNames: string = "";

    /**
     * @name DepartmentName
     * @servertype String
     * @type {string}
     */
    DepartmentName: string = "";

    /**
     * @name Office
     * @description 
     * Office.
     * @servertype String
     * @type {string}
     */
    Office: string = "";

    /**
     * @name Profession
     * @description 
     * Profession.
     * @servertype String
     * @type {string}
     */
    Profession: string = "";

    /**
     * @name Hobbies
     * @description 
     * Hobbies.
     * @servertype String
     * @type {string}
     */
    Hobbies: string = "";

    /**
     * @name Gender
     * @description 
     * Gender.  Possible values include:
     * M = Male
     * F = Female
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    Gender: string = "U";

    /**
     * @name BirthDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BirthDate: Date = null;

    /**
     * @name AnniversaryDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AnniversaryDate: Date = null;

    /**
     * @name NotifyAlert
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyAlert: boolean = false;

    /**
     * @name NotifyIssueChange
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChange: boolean = false;

    /**
     * @name NotifyIssueChangeGlobal
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChangeGlobal: boolean = false;

    /**
     * @name NotifyProductInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyProductInfo: boolean = false;

    /**
     * @name NotifyVendorInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyVendorInfo: boolean = false;

    /**
     * @name NotifyThirdPartyInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyThirdPartyInfo: boolean = false;

    /**
     * @name NotifyFormat
     * @servertype String
     * @type {string}
     */
    NotifyFormat: string = "";

    /**
     * @name NotifyStandardStartTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardStartTime: string = "";

    /**
     * @name NotifyStandardEndTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardEndTime: string = "";

    /**
     * @name NotifyUrgentStartTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentStartTime: string = "";

    /**
     * @name NotifyUrgentEndTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentEndTime: string = "";

    /**
     * @name Status
     * @description 
     * Status of the contact.  Possible values include: 
     * V = Invited (Contact was sent invitation to access the system but has not yet done so.)
     * P = Pending (Contact is pending until approved to be active.  While pending the contact has no access.)
     * A = Active (Contact is active.)
     * I = Inactive (Contact is inactive as determined by business rule with no special treatment.)
     * S = Suspended (Contact is suspended.)
     * C = Closed (Contact is closed or cancelled.)
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

    /**
     * @name StatusLocked
     * @servertype Boolean
     * @type {boolean}
     */
    StatusLocked: boolean = false;

    /**
     * @name CreateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CreateDateTime: Date = null;

    /**
     * @name SuspendDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendDateTime: Date = null;

    /**
     * @name CloseDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CloseDateTime: Date = null;

    /**
     * @name ReactivateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReactivateDateTime: Date = null;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name CurrencyFormat
     * @servertype String
     * @type {string}
     */
    CurrencyFormat: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Warehouse List
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class WarehouseListViewModel {

    /**
     * @name WarehouseId
     * @servertype Int64
     * @type {number}
     */
    WarehouseId: number = 0;

    /**
     * @name ExternalWarehouseId
     * @servertype String
     * @type {string}
     */
    ExternalWarehouseId: string = "";

    /**
     * @name WarehouseName
     * @servertype String
     * @type {string}
     */
    WarehouseName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

}



/**
 * @name WarehouseEditViewModel
 * @description
 * The CONTACT table contains information about customers, agents, organizational units, directory entries, etc.
 */
export class WarehouseEditViewModel {

    /**
     * @name WarehouseId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WarehouseId: number = 0;

    /**
     * @name ExternalWarehouseId
     * @servertype String
     * @type {string}
     */
    ExternalWarehouseId: string = "";

    /**
     * @name WarehouseName
     * @servertype String
     * @type {string}
     */
    WarehouseName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name MiddleName
     * @servertype String
     * @type {string}
     */
    MiddleName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name OtherName
     * @servertype String
     * @type {string}
     */
    OtherName: string = "";

    /**
     * @name CourtesyTitle
     * @servertype String
     * @type {string}
     */
    CourtesyTitle: string = "";

    /**
     * @name PostNominalTitles
     * @servertype String
     * @type {string}
     */
    PostNominalTitles: string = "";

    /**
     * @name Title
     * @description 
     * Title, job position, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name County
     * @description 
     * County.
     * @servertype String
     * @type {string}
     */
    County: string = "";

    /**
     * @name Country
     * @description 
     * Country.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Fax
     * @description 
     * Fax.
     * @servertype String
     * @type {string}
     */
    Fax: string = "";

    /**
     * @name HomePhone
     * @servertype String
     * @type {string}
     */
    HomePhone: string = "";

    /**
     * @name Cellular
     * @description 
     * Cellular.
     * @servertype String
     * @type {string}
     */
    Cellular: string = "";

    /**
     * @name AlternatePhone
     * @servertype String
     * @type {string}
     */
    AlternatePhone: string = "";

    /**
     * @name Extension
     * @description 
     * Extension.
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name AlternateEmail
     * @servertype String
     * @type {string}
     */
    AlternateEmail: string = "";

    /**
     * @name ImAddress
     * @servertype String
     * @type {string}
     */
    ImAddress: string = "";

    /**
     * @name ImNetwork
     * @servertype String
     * @type {string}
     */
    ImNetwork: string = "";

    /**
     * @name AlternateImAddress
     * @servertype String
     * @type {string}
     */
    AlternateImAddress: string = "";

    /**
     * @name AlternateImNetwork
     * @servertype String
     * @type {string}
     */
    AlternateImNetwork: string = "";

    /**
     * @name WebSite
     * @servertype String
     * @type {string}
     */
    WebSite: string = "";

    /**
     * @name FtpSite
     * @servertype String
     * @type {string}
     */
    FtpSite: string = "";

    /**
     * @name TaxId
     * @servertype String
     * @type {string}
     */
    TaxId: string = "";

    /**
     * @name TickerSymbol
     * @servertype String
     * @type {string}
     */
    TickerSymbol: string = "";

    /**
     * @name SicCode
     * @servertype String
     * @type {string}
     */
    SicCode: string = "";

    /**
     * @name Industry
     * @description 
     * Industry.
     * @servertype String
     * @type {string}
     */
    Industry: string = "";

    /**
     * @name ReferredBy
     * @servertype String
     * @type {string}
     */
    ReferredBy: string = "";

    /**
     * @name RepresentativeDirectoryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RepresentativeDirectoryId: number = 0;

    /**
     * @name RepresentativeDirectoryName
     * @servertype String
     * @type {string}
     */
    RepresentativeDirectoryName: string = "";

    /**
     * @name WebLanguage
     * @servertype String
     * @type {string}
     */
    WebLanguage: string = "";

    /**
     * @name IvrLanguage
     * @servertype String
     * @type {string}
     */
    IvrLanguage: string = "";

    /**
     * @name TimeZoneId
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = "";

    /**
     * @name Salutation
     * @description 
     * Salutation for this contact.
     * @servertype String
     * @type {string}
     */
    Salutation: string = "";

    /**
     * @name AssistantName
     * @servertype String
     * @type {string}
     */
    AssistantName: string = "";

    /**
     * @name ManagerName
     * @servertype String
     * @type {string}
     */
    ManagerName: string = "";

    /**
     * @name SpouseName
     * @servertype String
     * @type {string}
     */
    SpouseName: string = "";

    /**
     * @name ChildrenNames
     * @servertype String
     * @type {string}
     */
    ChildrenNames: string = "";

    /**
     * @name DepartmentName
     * @servertype String
     * @type {string}
     */
    DepartmentName: string = "";

    /**
     * @name Office
     * @description 
     * Office.
     * @servertype String
     * @type {string}
     */
    Office: string = "";

    /**
     * @name Profession
     * @description 
     * Profession.
     * @servertype String
     * @type {string}
     */
    Profession: string = "";

    /**
     * @name Hobbies
     * @description 
     * Hobbies.
     * @servertype String
     * @type {string}
     */
    Hobbies: string = "";

    /**
     * @name Gender
     * @description 
     * Gender.  Possible values include:
     * M = Male
     * F = Female
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    Gender: string = "U";

    /**
     * @name BirthDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BirthDate: Date = null;

    /**
     * @name AnniversaryDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AnniversaryDate: Date = null;

    /**
     * @name NotifyAlert
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyAlert: boolean = false;

    /**
     * @name NotifyIssueChange
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChange: boolean = false;

    /**
     * @name NotifyIssueChangeGlobal
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyIssueChangeGlobal: boolean = false;

    /**
     * @name NotifyProductInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyProductInfo: boolean = false;

    /**
     * @name NotifyVendorInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyVendorInfo: boolean = false;

    /**
     * @name NotifyThirdPartyInfo
     * @servertype Boolean
     * @type {boolean}
     */
    NotifyThirdPartyInfo: boolean = false;

    /**
     * @name NotifyFormat
     * @servertype String
     * @type {string}
     */
    NotifyFormat: string = "";

    /**
     * @name NotifyStandardStartTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardStartTime: string = "";

    /**
     * @name NotifyStandardEndTime
     * @servertype String
     * @type {string}
     */
    NotifyStandardEndTime: string = "";

    /**
     * @name NotifyUrgentStartTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentStartTime: string = "";

    /**
     * @name NotifyUrgentEndTime
     * @servertype String
     * @type {string}
     */
    NotifyUrgentEndTime: string = "";

    /**
     * @name Status
     * @description 
     * Status of the contact.  Possible values include: 
     * V = Invited (Contact was sent invitation to access the system but has not yet done so.)
     * P = Pending (Contact is pending until approved to be active.  While pending the contact has no access.)
     * A = Active (Contact is active.)
     * I = Inactive (Contact is inactive as determined by business rule with no special treatment.)
     * S = Suspended (Contact is suspended.)
     * C = Closed (Contact is closed or cancelled.)
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

    /**
     * @name StatusLocked
     * @servertype Boolean
     * @type {boolean}
     */
    StatusLocked: boolean = false;

    /**
     * @name CreateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CreateDateTime: Date = null;

    /**
     * @name SuspendDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendDateTime: Date = null;

    /**
     * @name CloseDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CloseDateTime: Date = null;

    /**
     * @name ReactivateDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReactivateDateTime: Date = null;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name CurrencyFormat
     * @servertype String
     * @type {string}
     */
    CurrencyFormat: string = "";

    /**
     * @name Login
     * @description 
     * Login name.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ApiAccessClientEditViewModel
 * @description
 * The ApiAccessClient table contains ip address and domain names that are valid for a given api access.  If no clients are
 * available for a given api access only valid partition domains are granted access.
 */
export class ApiAccessClientEditViewModel {

    /**
     * @name ApiAccessClientId
     * @description 
     * Unique identifier for this api access client.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApiAccessClientId: number = null;

    /**
     * @name ApiAccessId
     * @description 
     * The api access id this client belongs to.
     * @servertype Int64
     * @type {number}
     */
    ApiAccessId: number = null;

    /**
     * @name Description
     * @description 
     * The description of this api access client.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ClientIdentifierType
     * @description 
     * The type of client identifier.  Possible values include: 
     * D = Domain Name
     * 4 = IP v4 Address
     * 6 = IP v6 Address
     * N = No Identifier Required (not recommended)
     * @servertype String
     * @type {string}
     */
    ClientIdentifierType: string = "D";

    /**
     * @name ClientIdentifier
     * @description 
     * The ip address or domain name of this api client.  API calls from clients not in this client table will be rejected unless
     * there is a row with client identifier type of not required.  Note that wildcard values are accepted as are ip address ranges
     * when separated by a hyphen.  When client identifier type is domain name an empty value here means all valid partition domains
     * are accepted for access.
     * @servertype String
     * @type {string}
     */
    ClientIdentifier: string = "";

    /**
     * @name ClientType
     * @description 
     * The type of client.  Possible values include: 
     * D = Development
     * T = Test
     * P = Production
     * M = Mixed Use
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    ClientType: string = "U";

    /**
     * @name DebugUntilDateTime
     * @description 
     * When not null include debug information in api responses.  Note that clients with client type of production will only be
     * allowed to set this for a limited period of time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DebugUntilDateTime: Date = null;

    /**
     * @name Flags
     * @description 
     * A list of flags regarding the api access.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name SuspendedOnDateTime
     * @description 
     * The date and time this api access was suspended.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedOnDateTime: Date = null;

    /**
     * @name SuspendedByContactId
     * @description 
     * The Contact Id of the contact suspending this api access.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SuspendedByContactId: number = null;

    /**
     * @name SuspendedByContactName
     * @servertype String
     * @type {string}
     */
    SuspendedByContactName: string = "";

    /**
     * @name SuspendedUntilDateTime
     * @description 
     * When not null the api key is suspended until this date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedUntilDateTime: Date = null;

    /**
     * @name SuspendedReason
     * @description 
     * When the suspended until date time is not null this is the reason.
     * @servertype String
     * @type {string}
     */
    SuspendedReason: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the api access is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this api access is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this api access is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Properties
     * @description 
     * Properties related to this api access client.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ApiAccessEditViewModel
 * @description
 * The ApiAccess table contains api keys and parameters regarding api access.
 */
export class ApiAccessEditViewModel {

    /**
     * @name ApiAccessId
     * @description 
     * Unique identifier for this api access.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApiAccessId: number = null;

    /**
     * @name ApiKey
     * @description 
     * The key used to access the application programming interface.  This is typically in the format x-y where x is the base36
     * encoded partition id and y is a unique random string.
     * @servertype String
     * @type {string}
     */
    ApiKey: string = "";

    /**
     * @name Description
     * @description 
     * The description of this api access.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ContactId
     * @description 
     * The Contact Id of the contact owning this API key.  It dictates the security context for the API calls unless a role id is
     * provided.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name RoleId
     * @description 
     * When specified this role id is used for security context instead of the roles defined by the contact id.  This is useful
     * for special purpose API keys for specific apps, anonymous access, etc.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name Anonymous
     * @description 
     * When true this api key is used when anonymous access is requested and grants the rights associated with the specified role
     * id or contact id when no role id is specified.  Anonymous access is needed for access to basic branding or anonymous sign
     * up, registration, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    Anonymous: boolean = false;

    /**
     * @name ShortTermMinutes
     * @description 
     * When not zero the api key is a short term api key valid for the specified number of minutes.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ShortTermMinutes: number = 0;

    /**
     * @name EncryptionType
     * @description 
     * The encryption type used for API calls that support encryption.  Possible values include: 
     * N = None
     * S = Symmetric
     * @servertype String
     * @type {string}
     */
    EncryptionType: string = "N";

    /**
     * @name EncryptionKey
     * @description 
     * The encryption key used for API calls that support encryption.
     * @servertype String
     * @type {string}
     */
    EncryptionKey: string = "";

    /**
     * @name Flags
     * @description 
     * A list of flags regarding the api access.  Possible uses include:
     * For api keys utilized in web hook targets the flags must to contain 'webhook' as well as the allowed webhook types. For example,
     * if using this for Amazon Payment API IPN the url for posts might look like this: '/webhooks/target/78-sapfvi-3f8sfz-sa8fubg8/amazon-ipn'
     * and the flags for api key 78-sapfvi-3f8sfz-sa8fubg8 would contain: 'webhook,amazon-ipn'.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name RateLimitPerMinute
     * @description 
     * The maximum number of API calls allowed per minute.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RateLimitPerMinute: number = 0;

    /**
     * @name RateLimitPerHour
     * @description 
     * The maximum number of API calls allowed per hour.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RateLimitPerHour: number = 0;

    /**
     * @name SuspendedOnDateTime
     * @description 
     * The date and time this api access was suspended.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedOnDateTime: Date = null;

    /**
     * @name SuspendedByContactId
     * @description 
     * The Contact Id of the contact suspending this api access.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SuspendedByContactId: number = null;

    /**
     * @name SuspendedByContactName
     * @servertype String
     * @type {string}
     */
    SuspendedByContactName: string = "";

    /**
     * @name SuspendedUntilDateTime
     * @description 
     * When not null the api key is suspended until this date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SuspendedUntilDateTime: Date = null;

    /**
     * @name SuspendedReason
     * @description 
     * When the suspended until date time is not null this is the reason.
     * @servertype String
     * @type {string}
     */
    SuspendedReason: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the api access is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this api access is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this api access is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Properties
     * @description 
     * Properties related to this api access.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Clients
     * @servertype ApiAccessClientEditViewModel array
     * @type {ApiAccessClientEditViewModel[]}
     */
    Clients: ApiAccessClientEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name EncryptionEditViewModel
 */
export class EncryptionEditViewModel {

    /**
     * @name Tag
     * @servertype String
     * @type {string}
     */
    Tag: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name KeyType
     * @servertype String
     * @type {string}
     */
    KeyType: string = "";

    /**
     * @name KeyLength
     * @servertype Int32 (nullable)
     * @type {number}
     */
    KeyLength: number = 0;

    /**
     * @name PublicKey
     * @servertype String
     * @type {string}
     */
    PublicKey: string = "";

    /**
     * @name PrivateKey
     * @servertype String
     * @type {string}
     */
    PrivateKey: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name AddedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AddedDateTime: Date = null;

    /**
     * @name AddedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AddedByContactId: number = null;

    /**
     * @name AddedByContactName
     * @servertype String
     * @type {string}
     */
    AddedByContactName: string = "";

    /**
     * @name LastEditedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastEditedDateTime: Date = null;

    /**
     * @name LastEditedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LastEditedByContactId: number = null;

    /**
     * @name LastEditedByContactName
     * @servertype String
     * @type {string}
     */
    LastEditedByContactName: string = "";

    /**
     * @name MarkedForDeletionDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    MarkedForDeletionDateTime: Date = null;

    /**
     * @name MarkedForDeletionByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    MarkedForDeletionByContactId: number = null;

    /**
     * @name MarkedForDeletionByContactName
     * @servertype String
     * @type {string}
     */
    MarkedForDeletionByContactName: string = "";

    /**
     * @name CurrentRowVersion
     * @servertype String
     * @type {string}
     */
    CurrentRowVersion: string = "";

    /**
     * @name UtcOffset
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

    /**
     * @name TimeZoneDescription
     * @servertype String
     * @type {string}
     */
    TimeZoneDescription: string = "";

}



/**
 * @name EncryptionEchoEditViewModel
 */
export class EncryptionEchoEditViewModel {

    /**
     * @name Tag
     * @servertype String
     * @type {string}
     */
    Tag: string = "";

    /**
     * @name PlainText
     * @servertype String
     * @type {string}
     */
    PlainText: string = "";

    /**
     * @name EncryptedText
     * @servertype String
     * @type {string}
     */
    EncryptedText: string = "";

}



/**
 * @name Review
 */
export class ReviewViewModel {

    /**
     * @name ReviewDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReviewDateTime: Date = null;

    /**
     * @name ReviewedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReviewedByContactId: number = null;

    /**
     * @name Successful
     * @servertype Boolean
     * @type {boolean}
     */
    Successful: boolean = false;

    /**
     * @name Note
     * @servertype String
     * @type {string}
     */
    Note: string = "";

    /**
     * @name UtcOffset
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

}



/**
 * @name MessageExchangeEditViewModel
 * @description
 * The message exchange table contains security protocols, keys, etc. used for message exchange.
 */
export class MessageExchangeEditViewModel {

    /**
     * @name MessageExchangeId
     * @description 
     * The id of the message exchange row.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    MessageExchangeId: number = null;

    /**
     * @name Tag
     * @description 
     * A short random string combined with the MessageExchangeId to create a message exchange tag that is used to identify this
     * row quickly (via id) with a random component (by verifying the tag once found via id).
     * @servertype String
     * @type {string}
     */
    Tag: string = "";

    /**
     * @name Role
     * @description 
     * The role of this message exchange row.  Possible values include: 
     * A = API
     * M = Message Exchange
     * @servertype String
     * @type {string}
     */
    Role: string = "M";

    /**
     * @name Description
     * @description 
     * Description for this message exchange data.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Protocol
     * @description 
     * Message exchange protocol.  Possible values include:
     * ENCRYPT = Encrypted Message
     * SIGN = Signed Message
     * @servertype String
     * @type {string}
     */
    Protocol: string = "";

    /**
     * @name Configuration
     * @description 
     * The configuration (if any) for the specified protocol.
     * For PGP this is a json representation of an EncryptionPgpConfigurationModel object.
     * @servertype String
     * @type {string}
     */
    Configuration: string = "";

    /**
     * @name KeyType
     * @description 
     * Encryption or signing key type.  Possible values include: 
     * RSA = RSA private/public key encryption.
     * AES = AES symmetric encryption.
     * SECRET = shared secret encryption with corresponding shared secret key.
     * PGP = OpenPGP encryption.
     * VisaCheckout = Visa Checkout api key / shared secret encryption. 
     * @servertype String
     * @type {string}
     */
    KeyType: string = "";

    /**
     * @name KeyLength
     * @description 
     * The length of the key (e.g. 2048).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    KeyLength: number = 0;

    /**
     * @name PublicKey
     * @description 
     * The public key.
     * For RSA the public key is stored in the format: <RSAKeyValue><Modulus>xyz/mod==</Modulus><Exponent>exp</Exponent></RSAKeyValue>.
     * For PGP the public key is stored in ASCII Armor format.
     * For key and shared secret combinations the key is stored here.
     * @servertype String
     * @type {string}
     */
    PublicKey: string = "";

    /**
     * @name PrivateKey
     * @description 
     * The private key stored as a base64 encoded string.
     * For symmetric encryption types the key is stored here.
     * For PGP the secret key is stored in ASCII Armor format.
     * For key and shared secret combinations the shared secret is stored here.
     * @servertype String
     * @type {string}
     */
    PrivateKey: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name NoteEditViewModel
 * @description
 * The Note table contains notes about another object.
 */
export class NoteEditViewModel {

    /**
     * @name NoteId
     * @description 
     * The id of the note.
     * @servertype Int64
     * @type {number}
     */
    NoteId: number = null;

    /**
     * @name NoteDateTime
     * @description 
     * The date and time when the note was entered.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NoteDateTime: Date = new Date();

    /**
     * @name EnteredByContactId
     * @description 
     * The Contact Id that represents who entered this note.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    EnteredByContactId: number = null;

    /**
     * @name EnteredByContactName
     * @servertype String
     * @type {string}
     */
    EnteredByContactName: string = "";

    /**
     * @name EnteredByContactEmail
     * @servertype String
     * @type {string}
     */
    EnteredByContactEmail: string = "";

    /**
     * @name NoteType
     * @description 
     * The note type.  This is taken from a owner resource type based system pick list.
     * @servertype String
     * @type {string}
     */
    NoteType: string = "";

    /**
     * @name Note
     * @description 
     * Actual text of the note entered.
     * @servertype String
     * @type {string}
     */
    Note: string = "";

    /**
     * @name Tags
     * @description 
     * A comma separated list of tags for this note.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) for this note.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this note.  For example, if the owner resource type is InventoryType
     * this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Changes
     * @servertype ChangedItemViewModel array
     * @type {ChangedItemViewModel[]}
     */
    Changes: ChangedItemViewModel[] = [];

    /**
     * @name ChangesOnly
     * @servertype Boolean
     * @type {boolean}
     */
    ChangesOnly: boolean = false;

    /**
     * @name AuditTrailId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AuditTrailId: number = 0;

    /**
     * @name Attachments
     * @servertype AssetListViewModel array
     * @type {AssetListViewModel[]}
     */
    Attachments: AssetListViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Changed Item
 */
export class ChangedItemViewModel {

    /**
     * @name Label
     * @servertype String
     * @type {string}
     */
    Label: string = "";

    /**
     * @name Property
     * @servertype String
     * @type {string}
     */
    Property: string = "";

    /**
     * @name OldValue
     * @servertype String
     * @type {string}
     */
    OldValue: string = "";

    /**
     * @name NewValue
     * @servertype String
     * @type {string}
     */
    NewValue: string = "";

    /**
     * @name Message
     * @servertype String
     * @type {string}
     */
    Message: string = "";

}



/**
 * @name PossibleDuplicateObjectEditViewModel
 */
export class PossibleDuplicateObjectEditViewModel {

    /**
     * @name ReasonSuspectedDuplicateRule
     * @servertype String
     * @type {string}
     */
    ReasonSuspectedDuplicateRule: string = "";

    /**
     * @name ReasonSuspectedDuplicateDescription
     * @servertype String
     * @type {string}
     */
    ReasonSuspectedDuplicateDescription: string = "";

    /**
     * @name ObjectType
     * @servertype String
     * @type {string}
     */
    ObjectType: string = "";

    /**
     * @name ObjectValue01Label
     * @servertype String
     * @type {string}
     */
    ObjectValue01Label: string = "";

    /**
     * @name ObjectValue02Label
     * @servertype String
     * @type {string}
     */
    ObjectValue02Label: string = "";

    /**
     * @name ObjectValue03Label
     * @servertype String
     * @type {string}
     */
    ObjectValue03Label: string = "";

    /**
     * @name ObjectValue04Label
     * @servertype String
     * @type {string}
     */
    ObjectValue04Label: string = "";

    /**
     * @name ObjectValue05Label
     * @servertype String
     * @type {string}
     */
    ObjectValue05Label: string = "";

    /**
     * @name ObjectValue06Label
     * @servertype String
     * @type {string}
     */
    ObjectValue06Label: string = "";

    /**
     * @name ObjectValue07Label
     * @servertype String
     * @type {string}
     */
    ObjectValue07Label: string = "";

    /**
     * @name ObjectValue08Label
     * @servertype String
     * @type {string}
     */
    ObjectValue08Label: string = "";

    /**
     * @name ObjectValue09Label
     * @servertype String
     * @type {string}
     */
    ObjectValue09Label: string = "";

    /**
     * @name ObjectValue10Label
     * @servertype String
     * @type {string}
     */
    ObjectValue10Label: string = "";

    /**
     * @name ObjectValue11Label
     * @servertype String
     * @type {string}
     */
    ObjectValue11Label: string = "";

    /**
     * @name ObjectValue12Label
     * @servertype String
     * @type {string}
     */
    ObjectValue12Label: string = "";

    /**
     * @name ObjectValue13Label
     * @servertype String
     * @type {string}
     */
    ObjectValue13Label: string = "";

    /**
     * @name ObjectValue14Label
     * @servertype String
     * @type {string}
     */
    ObjectValue14Label: string = "";

    /**
     * @name ObjectValue15Label
     * @servertype String
     * @type {string}
     */
    ObjectValue15Label: string = "";

    /**
     * @name ObjectValue16Label
     * @servertype String
     * @type {string}
     */
    ObjectValue16Label: string = "";

    /**
     * @name ObjectValue17Label
     * @servertype String
     * @type {string}
     */
    ObjectValue17Label: string = "";

    /**
     * @name ObjectValue18Label
     * @servertype String
     * @type {string}
     */
    ObjectValue18Label: string = "";

    /**
     * @name ObjectValue19Label
     * @servertype String
     * @type {string}
     */
    ObjectValue19Label: string = "";

    /**
     * @name ObjectValue20Label
     * @servertype String
     * @type {string}
     */
    ObjectValue20Label: string = "";

    /**
     * @name ObjectOneId
     * @servertype String
     * @type {string}
     */
    ObjectOneId: string = "";

    /**
     * @name ObjectOneValue01
     * @servertype String
     * @type {string}
     */
    ObjectOneValue01: string = "";

    /**
     * @name ObjectOneValue02
     * @servertype String
     * @type {string}
     */
    ObjectOneValue02: string = "";

    /**
     * @name ObjectOneValue03
     * @servertype String
     * @type {string}
     */
    ObjectOneValue03: string = "";

    /**
     * @name ObjectOneValue04
     * @servertype String
     * @type {string}
     */
    ObjectOneValue04: string = "";

    /**
     * @name ObjectOneValue05
     * @servertype String
     * @type {string}
     */
    ObjectOneValue05: string = "";

    /**
     * @name ObjectOneValue06
     * @servertype String
     * @type {string}
     */
    ObjectOneValue06: string = "";

    /**
     * @name ObjectOneValue07
     * @servertype String
     * @type {string}
     */
    ObjectOneValue07: string = "";

    /**
     * @name ObjectOneValue08
     * @servertype String
     * @type {string}
     */
    ObjectOneValue08: string = "";

    /**
     * @name ObjectOneValue09
     * @servertype String
     * @type {string}
     */
    ObjectOneValue09: string = "";

    /**
     * @name ObjectOneValue10
     * @servertype String
     * @type {string}
     */
    ObjectOneValue10: string = "";

    /**
     * @name ObjectOneValue11
     * @servertype String
     * @type {string}
     */
    ObjectOneValue11: string = "";

    /**
     * @name ObjectOneValue12
     * @servertype String
     * @type {string}
     */
    ObjectOneValue12: string = "";

    /**
     * @name ObjectOneValue13
     * @servertype String
     * @type {string}
     */
    ObjectOneValue13: string = "";

    /**
     * @name ObjectOneValue14
     * @servertype String
     * @type {string}
     */
    ObjectOneValue14: string = "";

    /**
     * @name ObjectOneValue15
     * @servertype String
     * @type {string}
     */
    ObjectOneValue15: string = "";

    /**
     * @name ObjectOneValue16
     * @servertype String
     * @type {string}
     */
    ObjectOneValue16: string = "";

    /**
     * @name ObjectOneValue17
     * @servertype String
     * @type {string}
     */
    ObjectOneValue17: string = "";

    /**
     * @name ObjectOneValue18
     * @servertype String
     * @type {string}
     */
    ObjectOneValue18: string = "";

    /**
     * @name ObjectOneValue19
     * @servertype String
     * @type {string}
     */
    ObjectOneValue19: string = "";

    /**
     * @name ObjectOneValue20
     * @servertype String
     * @type {string}
     */
    ObjectOneValue20: string = "";

    /**
     * @name ObjectTwoId
     * @servertype String
     * @type {string}
     */
    ObjectTwoId: string = "";

    /**
     * @name ObjectTwoValue01
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue01: string = "";

    /**
     * @name ObjectTwoValue02
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue02: string = "";

    /**
     * @name ObjectTwoValue03
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue03: string = "";

    /**
     * @name ObjectTwoValue04
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue04: string = "";

    /**
     * @name ObjectTwoValue05
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue05: string = "";

    /**
     * @name ObjectTwoValue06
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue06: string = "";

    /**
     * @name ObjectTwoValue07
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue07: string = "";

    /**
     * @name ObjectTwoValue08
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue08: string = "";

    /**
     * @name ObjectTwoValue09
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue09: string = "";

    /**
     * @name ObjectTwoValue10
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue10: string = "";

    /**
     * @name ObjectTwoValue11
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue11: string = "";

    /**
     * @name ObjectTwoValue12
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue12: string = "";

    /**
     * @name ObjectTwoValue13
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue13: string = "";

    /**
     * @name ObjectTwoValue14
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue14: string = "";

    /**
     * @name ObjectTwoValue15
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue15: string = "";

    /**
     * @name ObjectTwoValue16
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue16: string = "";

    /**
     * @name ObjectTwoValue17
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue17: string = "";

    /**
     * @name ObjectTwoValue18
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue18: string = "";

    /**
     * @name ObjectTwoValue19
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue19: string = "";

    /**
     * @name ObjectTwoValue20
     * @servertype String
     * @type {string}
     */
    ObjectTwoValue20: string = "";

}



/**
 * @name Server Resource Alarm Rule
 */
export class ServerResourceAlarmRuleViewModel {

    /**
     * @name ServerResourceAlarmRuleId
     * @servertype Int64
     * @type {number}
     */
    ServerResourceAlarmRuleId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name ServerName
     * @servertype String
     * @type {string}
     */
    ServerName: string = "";

    /**
     * @name Resource
     * @servertype String
     * @type {string}
     */
    Resource: string = "";

    /**
     * @name AlarmRule
     * @servertype String
     * @type {string}
     */
    AlarmRule: string = "";

    /**
     * @name AlarmMessage
     * @servertype String
     * @type {string}
     */
    AlarmMessage: string = "";

    /**
     * @name AlarmDescription
     * @servertype String
     * @type {string}
     */
    AlarmDescription: string = "";

    /**
     * @name AlarmNotificationGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AlarmNotificationGroupId: number = 0;

    /**
     * @name AlarmNotificationEmailAddresses
     * @servertype string array
     * @type {string[]}
     */
    AlarmNotificationEmailAddresses: string[] = [];

    /**
     * @name AlarmIntervalMinutes
     * @servertype Int32
     * @type {number}
     */
    AlarmIntervalMinutes: number = 0;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ServerResourceEditViewModel
 * @description
 * The ServerResource table contains information about application server resources.
 */
export class ServerResourceEditViewModel {

    /**
     * @name ServerResourceId
     * @description 
     * Server Resource Id uniquely identifies this server resource.
     * @servertype Int64
     * @type {number}
     */
    ServerResourceId: number = null;

    /**
     * @name ServerName
     * @description 
     * The name of the server the resources belong to.
     * @servertype String
     * @type {string}
     */
    ServerName: string = "";

    /**
     * @name Description
     * @description 
     * The description for this server resource.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Type
     * @description 
     * The type of server resource.  Possible values include: 
     * P = Printer
     * M = Modem
     * 4 = IP Address v4
     * 6 = IP Address v6
     * D = Disk
     * R = RAM
     * C = CPU
     * H = Hardware
     * S = Software
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Resource
     * @description 
     * The server resource.
     * @servertype String
     * @type {string}
     */
    Resource: string = "";

    /**
     * @name Available
     * @description 
     * The amount available for this resource (e.g. free disk space of hard disk, etc.).
     * @servertype Double (nullable)
     * @type {number}
     */
    Available: number = 0;

    /**
     * @name Used
     * @description 
     * The amount used for this resource (e.g. used disk space of hard disk, etc.).
     * @servertype Double (nullable)
     * @type {number}
     */
    Used: number = 0;

    /**
     * @name Total
     * @description 
     * The total value for this resource (e.g. size of hard disk, 100% for CPU, amount of RAM, etc.).
     * @servertype Double (nullable)
     * @type {number}
     */
    Total: number = 0;

    /**
     * @name Measurement
     * @description 
     * The measurement for this resource (e.g. GB for HDD).
     * @servertype String
     * @type {string}
     */
    Measurement: string = "";

    /**
     * @name PercentAvailable
     * @description 
     * The percent available for this resource (e.g. % free disk space of hard disk, etc.).
     * @servertype Byte (nullable)
     * @type {number}
     */
    PercentAvailable: number = 0;

    /**
     * @name PercentUsed
     * @description 
     * The percent used for this resource (e.g. % used disk space of hard disk, etc.).
     * @servertype Byte (nullable)
     * @type {number}
     */
    PercentUsed: number = 0;

    /**
     * @name AlarmEnabled
     * @description 
     * When true alarms are enabled for this resource.
     * @servertype Boolean
     * @type {boolean}
     */
    AlarmEnabled: boolean = false;

    /**
     * @name AlarmState
     * @description 
     * When true the resource is in an alarm state.
     * @servertype Boolean
     * @type {boolean}
     */
    AlarmState: boolean = false;

    /**
     * @name AlarmMessage
     * @description 
     * The alarm message for the current alarm state.
     * @servertype String
     * @type {string}
     */
    AlarmMessage: string = "";

    /**
     * @name AlarmIntervalMinutes
     * @description 
     * The number of minutes between alarms being raised.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AlarmIntervalMinutes: number = 0;

    /**
     * @name LastAlarmDeliveryDateTime
     * @description 
     * The date and time when the last alarm was raised.  Alarms will be raised at intervals specified in alarm interval minutes.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAlarmDeliveryDateTime: Date = null;

    /**
     * @name LastAlarmStateStartDateTime
     * @description 
     * The date and time when the resource last entered an alarm state.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAlarmStateStartDateTime: Date = null;

    /**
     * @name LastAlarmStateEndDateTime
     * @description 
     * The date and time when the resource last exited an alarm state.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAlarmStateEndDateTime: Date = null;

    /**
     * @name Properties
     * @description 
     * The properties for the server resource.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name SettingEditViewModel
 * @description
 * The Setting table contains system settings.
 */
export class SettingEditViewModel {

    /**
     * @name SettingId
     * @description 
     * Setting Id uniquely identifies this setting.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SettingId: number = null;

    /**
     * @name Category
     * @description 
     * Category.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name Attribute
     * @description 
     * The attribute.
     * @servertype String
     * @type {string}
     */
    Attribute: string = "";

    /**
     * @name Value
     * @description 
     * Value for this attribute.
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Protected
     * @description 
     * True indicates the setting is protected from anonymous reading.  Since settings are frequently needed for various configuration
     * settings including those needed by anonymous users for portal branding, etc. this table is frequently accessed without any
     * permission checks.  If Protected is true then the user is required to have specific read access before this setting value
     * can be returned.
     * @servertype Boolean
     * @type {boolean}
     */
    Protected: boolean = false;

    /**
     * @name Hidden
     * @description 
     * True indicates the value is hidden from setting configuration screens and reports.  This can also be used when the setting
     * is private to the user who added it.
     * @servertype Boolean
     * @type {boolean}
     */
    Hidden: boolean = false;

    /**
     * @name ReadOnly
     * @description 
     * True indicates the value is read-only and cannot be configured.
     * @servertype Boolean
     * @type {boolean}
     */
    ReadOnly: boolean = false;

    /**
     * @name PartitionZeroOnly
     * @description 
     * True indicates the value is a partition zero only setting and cannot be configured in other partitions.
     * @servertype Boolean
     * @type {boolean}
     */
    PartitionZeroOnly: boolean = false;

    /**
     * @name Encrypted
     * @description 
     * True indicates the value is encrypted.
     * @servertype Boolean
     * @type {boolean}
     */
    Encrypted: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name SynopsisObjectEditViewModel
 */
export class SynopsisObjectEditViewModel {

    /**
     * @name ObjectType
     * @servertype String
     * @type {string}
     */
    ObjectType: string = "";

    /**
     * @name ObjectId
     * @servertype String
     * @type {string}
     */
    ObjectId: string = "";

    /**
     * @name ObjectTypeLabel
     * @servertype String
     * @type {string}
     */
    ObjectTypeLabel: string = "";

    /**
     * @name ObjectValue01Label
     * @servertype String
     * @type {string}
     */
    ObjectValue01Label: string = "";

    /**
     * @name ObjectValue02Label
     * @servertype String
     * @type {string}
     */
    ObjectValue02Label: string = "";

    /**
     * @name ObjectValue03Label
     * @servertype String
     * @type {string}
     */
    ObjectValue03Label: string = "";

    /**
     * @name ObjectValue04Label
     * @servertype String
     * @type {string}
     */
    ObjectValue04Label: string = "";

    /**
     * @name ObjectValue05Label
     * @servertype String
     * @type {string}
     */
    ObjectValue05Label: string = "";

    /**
     * @name ObjectValue06Label
     * @servertype String
     * @type {string}
     */
    ObjectValue06Label: string = "";

    /**
     * @name ObjectValue07Label
     * @servertype String
     * @type {string}
     */
    ObjectValue07Label: string = "";

    /**
     * @name ObjectValue08Label
     * @servertype String
     * @type {string}
     */
    ObjectValue08Label: string = "";

    /**
     * @name ObjectValue09Label
     * @servertype String
     * @type {string}
     */
    ObjectValue09Label: string = "";

    /**
     * @name ObjectValue10Label
     * @servertype String
     * @type {string}
     */
    ObjectValue10Label: string = "";

    /**
     * @name ObjectValue11Label
     * @servertype String
     * @type {string}
     */
    ObjectValue11Label: string = "";

    /**
     * @name ObjectValue12Label
     * @servertype String
     * @type {string}
     */
    ObjectValue12Label: string = "";

    /**
     * @name ObjectValue13Label
     * @servertype String
     * @type {string}
     */
    ObjectValue13Label: string = "";

    /**
     * @name ObjectValue14Label
     * @servertype String
     * @type {string}
     */
    ObjectValue14Label: string = "";

    /**
     * @name ObjectValue15Label
     * @servertype String
     * @type {string}
     */
    ObjectValue15Label: string = "";

    /**
     * @name ObjectValue16Label
     * @servertype String
     * @type {string}
     */
    ObjectValue16Label: string = "";

    /**
     * @name ObjectValue17Label
     * @servertype String
     * @type {string}
     */
    ObjectValue17Label: string = "";

    /**
     * @name ObjectValue18Label
     * @servertype String
     * @type {string}
     */
    ObjectValue18Label: string = "";

    /**
     * @name ObjectValue19Label
     * @servertype String
     * @type {string}
     */
    ObjectValue19Label: string = "";

    /**
     * @name ObjectValue20Label
     * @servertype String
     * @type {string}
     */
    ObjectValue20Label: string = "";

    /**
     * @name ObjectValue01
     * @servertype String
     * @type {string}
     */
    ObjectValue01: string = "";

    /**
     * @name ObjectValue02
     * @servertype String
     * @type {string}
     */
    ObjectValue02: string = "";

    /**
     * @name ObjectValue03
     * @servertype String
     * @type {string}
     */
    ObjectValue03: string = "";

    /**
     * @name ObjectValue04
     * @servertype String
     * @type {string}
     */
    ObjectValue04: string = "";

    /**
     * @name ObjectValue05
     * @servertype String
     * @type {string}
     */
    ObjectValue05: string = "";

    /**
     * @name ObjectValue06
     * @servertype String
     * @type {string}
     */
    ObjectValue06: string = "";

    /**
     * @name ObjectValue07
     * @servertype String
     * @type {string}
     */
    ObjectValue07: string = "";

    /**
     * @name ObjectValue08
     * @servertype String
     * @type {string}
     */
    ObjectValue08: string = "";

    /**
     * @name ObjectValue09
     * @servertype String
     * @type {string}
     */
    ObjectValue09: string = "";

    /**
     * @name ObjectValue10
     * @servertype String
     * @type {string}
     */
    ObjectValue10: string = "";

    /**
     * @name ObjectValue11
     * @servertype String
     * @type {string}
     */
    ObjectValue11: string = "";

    /**
     * @name ObjectValue12
     * @servertype String
     * @type {string}
     */
    ObjectValue12: string = "";

    /**
     * @name ObjectValue13
     * @servertype String
     * @type {string}
     */
    ObjectValue13: string = "";

    /**
     * @name ObjectValue14
     * @servertype String
     * @type {string}
     */
    ObjectValue14: string = "";

    /**
     * @name ObjectValue15
     * @servertype String
     * @type {string}
     */
    ObjectValue15: string = "";

    /**
     * @name ObjectValue16
     * @servertype String
     * @type {string}
     */
    ObjectValue16: string = "";

    /**
     * @name ObjectValue17
     * @servertype String
     * @type {string}
     */
    ObjectValue17: string = "";

    /**
     * @name ObjectValue18
     * @servertype String
     * @type {string}
     */
    ObjectValue18: string = "";

    /**
     * @name ObjectValue19
     * @servertype String
     * @type {string}
     */
    ObjectValue19: string = "";

    /**
     * @name ObjectValue20
     * @servertype String
     * @type {string}
     */
    ObjectValue20: string = "";

}



/**
 * @name SystemSettings
 */
export class SystemSettings {

    /**
     * @name AsOf
     * @servertype DateTime
     * @type {Date}
     */
    AsOf: Date = null;

    /**
     * @name TimeZoneName
     * @servertype String
     * @type {string}
     */
    TimeZoneName: string = "";

    /**
     * @name Groups
     * @servertype SettingGroupModel<SettingEditViewModel> array
     * @type {SettingGroupModel<SettingEditViewModel>[]}
     */
    Groups: SettingGroupModel<SettingEditViewModel>[] = [];

}



/**
 * @name SettingGroupModel<TSettingModel>
 */
export class SettingGroupModel<TSettingModel> {

    /**
     * @name Group
     * @servertype String
     * @type {string}
     */
    Group: string = "";

    /**
     * @name NativeDescription
     * @servertype String
     * @type {string}
     */
    NativeDescription: string = "";

    /**
     * @name Language
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Visible
     * @servertype Boolean
     * @type {boolean}
     */
    Visible: boolean = false;

    /**
     * @name Categories
     * @servertype SettingCategoryModel<TSettingModel> array
     * @type {SettingCategoryModel<TSettingModel>[]}
     */
    Categories: SettingCategoryModel<TSettingModel>[] = [];

}



/**
 * @name SettingCategoryModel<TSettingModel>
 */
export class SettingCategoryModel<TSettingModel> {

    /**
     * @name Category
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name NativeDescription
     * @servertype String
     * @type {string}
     */
    NativeDescription: string = "";

    /**
     * @name Language
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Visible
     * @servertype Boolean
     * @type {boolean}
     */
    Visible: boolean = false;

    /**
     * @name Settings
     * @servertype SettingInformationModel<TSettingModel> array
     * @type {SettingInformationModel<TSettingModel>[]}
     */
    Settings: SettingInformationModel<TSettingModel>[] = [];

}



/**
 * @name SettingInformationModel<TSettingModel>
 */
export class SettingInformationModel<TSettingModel> {

    /**
     * @name Attribute
     * @servertype String
     * @type {string}
     */
    Attribute: string = "";

    /**
     * @name NativeDescription
     * @servertype String
     * @type {string}
     */
    NativeDescription: string = "";

    /**
     * @name Language
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DefaultSetting
     * @servertype String
     * @type {string}
     */
    DefaultSetting: string = "";

    /**
     * @name UserInputType
     * @servertype IB.Data.Model.SettingInputType Enum
     * @type {m.SettingInputType}
     */
    UserInputType: m.SettingInputType = null;

    /**
     * @name UserInputTypeString
     * @servertype String
     * @type {string}
     */
    UserInputTypeString: string = "";

    /**
     * @name InputPickList
     * @servertype ConstantInformation array
     * @type {m.ConstantInformation[]}
     */
    InputPickList: m.ConstantInformation[] = [];

    /**
     * @name Required
     * @servertype Boolean
     * @type {boolean}
     */
    Required: boolean = false;

    /**
     * @name Protected
     * @servertype Boolean
     * @type {boolean}
     */
    Protected: boolean = false;

    /**
     * @name SettingId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SettingId: number = 0;

    /**
     * @name Value
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Visible
     * @servertype Boolean
     * @type {boolean}
     */
    Visible: boolean = false;

    /**
     * @name Setting
     * @servertype 
     * @type {TSettingModel}
     */
    Setting: TSettingModel = null;

}



/**
 * @name TagEditViewModel
 * @description
 * The Tag table contains tags for an object.
 */
export class TagEditViewModel {

    /**
     * @name TagId
     * @description 
     * The id of the tag.
     * @servertype Int64
     * @type {number}
     */
    TagId: number = null;

    /**
     * @name Tag
     * @description 
     * The tag for the owner resource.
     * @servertype String
     * @type {string}
     */
    Tag: string = "";

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) for this tag.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this tag.  For example, if the owner resource type is InventoryType
     * this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Time Zone List
 * @description
 * The TimeZone table contains information related to time zones including UTC offset and daylight savings time.
 */
export class TimeZoneListViewModel {

    /**
     * @name TimeZoneId
     * @description 
     * Time Zone Id uniquely identifies the time zone.
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = null;

    /**
     * @name Description
     * @description 
     * Time Zone Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

}



/**
 * @name TimeZoneEditViewModel
 * @description
 * The TimeZone table contains information related to time zones including UTC offset and daylight savings time.
 */
export class TimeZoneEditViewModel {

    /**
     * @name TimeZoneId
     * @description 
     * Time Zone Id uniquely identifies the time zone.
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = null;

    /**
     * @name Description
     * @description 
     * Time Zone Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Name
     * @description 
     * Time Zone Name.
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name UtcOffset
     * @description 
     * UTC offset in format +99:99 where + is + or - and 9 is any digit 0-9.
     * @servertype String
     * @type {string}
     */
    UtcOffset: string = "";

    /**
     * @name UtcOffsetHours
     * @description 
     * UTC offset in hours format (positive or negative). For example: -5, 9.5, 0.
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffsetHours: number = 0;

    /**
     * @name AdjustForDst
     * @description 
     * When true the time zone adjusts for daylight savings time (DST).
     * @servertype Boolean
     * @type {boolean}
     */
    AdjustForDst: boolean = false;

    /**
     * @name DstStartDateTime
     * @description 
     * A date/time value that represents when day light savings time begins.  The year portion is not used and typically set to
     * a fixed meaningless value like 1970.  The time portion represents the time DST starts.  The month portion represents the
     * month DST starts.  The day portion represents the day DST starts when it is a fixed day of the month (DstStartWeek is 0)
     * or a meaningless value (like 1) when DST starts on a floating day of the month (DstStartWeek is greater than 0).
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DstStartDateTime: Date = null;

    /**
     * @name DstStartDayOfWeek
     * @description 
     * The day of the week when DST starts where 0=Sunday ... 6=Saturday.  Ignored when DST is a fixed day of the month (DstStartWeek=0).
     * @servertype Byte (nullable)
     * @type {number}
     */
    DstStartDayOfWeek: number = 0;

    /**
     * @name DstStartWeek
     * @description 
     * The week of the month when DST starts with possible values of 1-5 where 5 represents the last week of the month.  When DST
     * is a fixed day of the month this should be 0.
     * @servertype Byte (nullable)
     * @type {number}
     */
    DstStartWeek: number = 0;

    /**
     * @name DstStartConstraint
     * @description 
     * For floating DST start this can represent the day of a month that the DST start must happen after (for positive values) or
     * before (for negative values).  For example, if DST begins on the first Sunday after the 8th of the month this value would
     * be 8.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    DstStartConstraint: number = 0;

    /**
     * @name DstEndDateTime
     * @description 
     * A date/time value that represents when day light savings time ends.  The year portion is not used and typically set to a
     * fixed meaningless value like 1970.  The time portion represents the time DST ends.  The month portion represents the month
     * DST ends.  The day portion represents the day DST ends when it is a fixed day of the month (DstEndWeek is 0) or a meaningless
     * value (like 1) when DST ends on a floating day of the month (DstEndWeek is greater than 0).
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DstEndDateTime: Date = null;

    /**
     * @name DstEndDayOfWeek
     * @description 
     * The day of the week when DST ends where 0=Sunday ... 6=Saturday.  Ignored when DST is a fixed day of the month (DstEndWeek=0).
     * @servertype Byte (nullable)
     * @type {number}
     */
    DstEndDayOfWeek: number = 0;

    /**
     * @name DstEndWeek
     * @description 
     * The week of the month when DST ends with possible values of 1-5 where 5 represents the last week of the month.  When DST
     * is a fixed day of the month this should be 0.
     * @servertype Byte (nullable)
     * @type {number}
     */
    DstEndWeek: number = 0;

    /**
     * @name DstEndConstraint
     * @description 
     * For floating DST end this can represent the day of a month that the DST end must happen after (for positive values) or before
     * (for negative values).  For example, if DST ends on the first Sunday after the 8th of the month this value would be 8.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    DstEndConstraint: number = 0;

    /**
     * @name DstUtcOffset
     * @description 
     * UTC offset during DST in format +99:99 where + is + or - and 9 is any digit 0-9.
     * @servertype String
     * @type {string}
     */
    DstUtcOffset: string = "";

    /**
     * @name DstUtcOffsetHours
     * @description 
     * UTC offset during DST in hours format (positive or negative). For example: -5, 9.5, 0.
     * @servertype Double (nullable)
     * @type {number}
     */
    DstUtcOffsetHours: number = 0;

    /**
     * @name SystemTimeZoneId
     * @description 
     * Time Zone Id used by the host system (i.e. Windows).
     * @servertype String
     * @type {string}
     */
    SystemTimeZoneId: string = "";

    /**
     * @name SystemTimeZoneInformation
     * @description 
     * Time Zone information used by the host system (i.e. Windows).
     * @servertype String
     * @type {string}
     */
    SystemTimeZoneInformation: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name Asset License
 */
export class AssetLicenseViewModel {

    /**
     * @name AssetLicenseId
     * @servertype Int64
     * @type {number}
     */
    AssetLicenseId: number = 0;

    /**
     * @name ExternalAssetLicenseId
     * @servertype String
     * @type {string}
     */
    ExternalAssetLicenseId: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name LongDescription
     * @servertype String
     * @type {string}
     */
    LongDescription: string = "";

    /**
     * @name Icons
     * @servertype string array
     * @type {string[]}
     */
    Icons: string[] = [];

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name OverviewUrl
     * @servertype String
     * @type {string}
     */
    OverviewUrl: string = "";

    /**
     * @name LicenseUrl
     * @servertype String
     * @type {string}
     */
    LicenseUrl: string = "";

    /**
     * @name Urls
     * @servertype AssetLicenseUrlViewModel array
     * @type {AssetLicenseUrlViewModel[]}
     */
    Urls: AssetLicenseUrlViewModel[] = [];

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name AssetLicenseUrlViewModel
 */
export class AssetLicenseUrlViewModel {

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Url
     * @servertype String
     * @type {string}
     */
    Url: string = "";

}



/**
 * @name AssetAccessLogEditViewModel
 * @description
 * The Asset Access Log table contains information regarding asset use for assets that log access.
 */
export class AssetAccessLogEditViewModel {

    /**
     * @name AssetAccessLogId
     * @description 
     * The id of the asset access log entry.
     * @servertype Int64
     * @type {number}
     */
    AssetAccessLogId: number = null;

    /**
     * @name AssetId
     * @description 
     * The id of the asset this access log entry belongs to.
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = null;

    /**
     * @name AccessDateTime
     * @description 
     * The date and time when the asset was accessed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AccessDateTime: Date = null;

    /**
     * @name AccessedByContactId
     * @description 
     * The Contact Id that accessed this asset.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AccessedByContactId: number = null;

    /**
     * @name AccessedByContactName
     * @servertype String
     * @type {string}
     */
    AccessedByContactName: string = "";

    /**
     * @name AccessedByIpAddress
     * @description 
     * The IP address of this person accessing this asset.  This may include public and private ip address combinations when available.
     * @servertype String
     * @type {string}
     */
    AccessedByIpAddress: string = "";

    /**
     * @name SignatureType
     * @description 
     * For access that requires a signature this is the signature type that was provided.  Possible values include: 
     * N = None
     * S = Signed By Name
     * L = Signed By Login
     * @servertype String
     * @type {string}
     */
    SignatureType: string = "N";

    /**
     * @name Signature
     * @description 
     * For access that requires a signature this is the signature.  See signature type for more information.
     * @servertype String
     * @type {string}
     */
    Signature: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name AssetFileEditViewModel
 */
export class AssetFileEditViewModel {

    /**
     * @name AssetId
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name FriendlyName
     * @servertype String
     * @type {string}
     */
    FriendlyName: string = "";

    /**
     * @name FileType
     * @servertype String
     * @type {string}
     */
    FileType: string = "";

    /**
     * @name ContentType
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name FileName
     * @servertype String
     * @type {string}
     */
    FileName: string = "";

    /**
     * @name FileContentsBase64
     * @servertype String
     * @type {string}
     */
    FileContentsBase64: string = "";

    /**
     * @name SizeBytes
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SizeBytes: number = 0;

    /**
     * @name Height
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Height: number = 0;

    /**
     * @name Width
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Width: number = 0;

    /**
     * @name IsPublic
     * @servertype Boolean
     * @type {boolean}
     */
    IsPublic: boolean = false;

    /**
     * @name Created
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    Created: Date = null;

    /**
     * @name LastModified
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastModified: Date = null;

    /**
     * @name FileRole
     * @servertype String
     * @type {string}
     */
    FileRole: string = "";

    /**
     * @name ReadOnlyShareId
     * @servertype String
     * @type {string}
     */
    ReadOnlyShareId: string = "";

    /**
     * @name ReadOnlySharedResource
     * @servertype Boolean
     * @type {boolean}
     */
    ReadOnlySharedResource: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name AssetOptionsButtonEditViewModel
 */
export class AssetOptionsButtonEditViewModel {

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Text
     * @servertype String
     * @type {string}
     */
    Text: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name Color
     * @servertype String
     * @type {string}
     */
    Color: string = "";

}



/**
 * @name AssetOptionsNoticeEditViewModel
 */
export class AssetOptionsNoticeEditViewModel {

    /**
     * @name Required
     * @servertype Boolean
     * @type {boolean}
     */
    Required: boolean = false;

    /**
     * @name MaxPostponeDays
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaxPostponeDays: number = 0;

    /**
     * @name SignatureType
     * @servertype String
     * @type {string}
     */
    SignatureType: string = "";

    /**
     * @name DownloadFormat
     * @servertype String
     * @type {string}
     */
    DownloadFormat: string = "";

    /**
     * @name OkButton
     * @servertype IB.Web.API.Models.v5.AssetOptionsButtonEditViewModel
     * @type {AssetOptionsButtonEditViewModel}
     */
    OkButton: AssetOptionsButtonEditViewModel = null;

    /**
     * @name CancelButton
     * @servertype IB.Web.API.Models.v5.AssetOptionsButtonEditViewModel
     * @type {AssetOptionsButtonEditViewModel}
     */
    CancelButton: AssetOptionsButtonEditViewModel = null;

    /**
     * @name DownloadButton
     * @servertype IB.Web.API.Models.v5.AssetOptionsButtonEditViewModel
     * @type {AssetOptionsButtonEditViewModel}
     */
    DownloadButton: AssetOptionsButtonEditViewModel = null;

    /**
     * @name PrintButton
     * @servertype IB.Web.API.Models.v5.AssetOptionsButtonEditViewModel
     * @type {AssetOptionsButtonEditViewModel}
     */
    PrintButton: AssetOptionsButtonEditViewModel = null;

    /**
     * @name LaterButton
     * @servertype IB.Web.API.Models.v5.AssetOptionsButtonEditViewModel
     * @type {AssetOptionsButtonEditViewModel}
     */
    LaterButton: AssetOptionsButtonEditViewModel = null;

}



/**
 * @name AssetOptionsWidgetEditViewModel
 */
export class AssetOptionsWidgetEditViewModel {

    /**
     * @name Category
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name ValidForDirectory
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForDirectory: boolean = false;

    /**
     * @name ValidForCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForCustomer: boolean = false;

    /**
     * @name ValidForVendor
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForVendor: boolean = false;

    /**
     * @name ValidForAgent
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForAgent: boolean = false;

    /**
     * @name ValidForServiceProvider
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForServiceProvider: boolean = false;

    /**
     * @name ValidForRoles
     * @servertype long array
     * @type {number[]}
     */
    ValidForRoles: number[] = [];

    /**
     * @name Width
     * @servertype Int32
     * @type {number}
     */
    Width: number = 0;

    /**
     * @name Height
     * @servertype Int32
     * @type {number}
     */
    Height: number = 0;

    /**
     * @name MinWidth
     * @servertype Int32
     * @type {number}
     */
    MinWidth: number = 0;

    /**
     * @name MinHeight
     * @servertype Int32
     * @type {number}
     */
    MinHeight: number = 0;

    /**
     * @name MaxWidth
     * @servertype Int32
     * @type {number}
     */
    MaxWidth: number = 0;

    /**
     * @name MaxHeight
     * @servertype Int32
     * @type {number}
     */
    MaxHeight: number = 0;

    /**
     * @name Actions
     * @servertype DashboardWidgetActionEditViewModel array
     * @type {m5web.DashboardWidgetActionEditViewModel[]}
     */
    Actions: m5web.DashboardWidgetActionEditViewModel[] = [];

    /**
     * @name Dependencies
     * @servertype string array
     * @type {string[]}
     */
    Dependencies: string[] = [];

}



/**
 * @name AssetReferenceEditViewModel
 */
export class AssetReferenceEditViewModel {

    /**
     * @name AssetReferenceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetReferenceId: number = 0;

    /**
     * @name AssetId
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name ReferencedAssetId
     * @servertype Int64
     * @type {number}
     */
    ReferencedAssetId: number = 0;

    /**
     * @name ReferencedAssetTitle
     * @servertype String
     * @type {string}
     */
    ReferencedAssetTitle: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name AssetSelectionEditViewModel
 * @description
 * The Asset Selection table contains information to help in identification and selection of assets.
 */
export class AssetSelectionEditViewModel {

    /**
     * @name AssetSelectionId
     * @description 
     * The id of the asset selection item.
     * @servertype Int64
     * @type {number}
     */
    AssetSelectionId: number = null;

    /**
     * @name AssetId
     * @description 
     * The id of the asset this asset selection item belongs to.
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = null;

    /**
     * @name Description
     * @description 
     * A description for this asset selection item.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Property01
     * @description 
     * A property used with its corresponding value for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Property01: string = "";

    /**
     * @name Value01
     * @description 
     * A value used with its corresponding property for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Value01: string = "";

    /**
     * @name AllRequired01
     * @description 
     * True indicates that all provided property/value pairs are required for this asset to be selected.
     * This is only used when there are multiple values for a given property.
     * @servertype Boolean
     * @type {boolean}
     */
    AllRequired01: boolean = false;

    /**
     * @name Property02
     * @description 
     * A property used with its corresponding value for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Property02: string = "";

    /**
     * @name Value02
     * @description 
     * A value used with its corresponding property for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Value02: string = "";

    /**
     * @name AllRequired02
     * @description 
     * True indicates that all provided property/value pairs are required for this asset to be selected.
     * This is only used when there are multiple values for a given property.
     * @servertype Boolean
     * @type {boolean}
     */
    AllRequired02: boolean = false;

    /**
     * @name Property03
     * @description 
     * A property used with its corresponding value for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Property03: string = "";

    /**
     * @name Value03
     * @description 
     * A value used with its corresponding property for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Value03: string = "";

    /**
     * @name AllRequired03
     * @description 
     * True indicates that all provided property/value pairs are required for this asset to be selected.
     * This is only used when there are multiple values for a given property.
     * @servertype Boolean
     * @type {boolean}
     */
    AllRequired03: boolean = false;

    /**
     * @name Property04
     * @description 
     * A property used with its corresponding value for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Property04: string = "";

    /**
     * @name Value04
     * @description 
     * A value used with its corresponding property for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Value04: string = "";

    /**
     * @name AllRequired04
     * @description 
     * True indicates that all provided property/value pairs are required for this asset to be selected.
     * This is only used when there are multiple values for a given property.
     * @servertype Boolean
     * @type {boolean}
     */
    AllRequired04: boolean = false;

    /**
     * @name Property05
     * @description 
     * A property used with its corresponding value for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Property05: string = "";

    /**
     * @name Value05
     * @description 
     * A value used with its corresponding property for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Value05: string = "";

    /**
     * @name AllRequired05
     * @description 
     * True indicates that all provided property/value pairs are required for this asset to be selected.
     * This is only used when there are multiple values for a given property.
     * @servertype Boolean
     * @type {boolean}
     */
    AllRequired05: boolean = false;

    /**
     * @name Property06
     * @description 
     * A property used with its corresponding value for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Property06: string = "";

    /**
     * @name Value06
     * @description 
     * A value used with its corresponding property for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Value06: string = "";

    /**
     * @name AllRequired06
     * @description 
     * True indicates that all provided property/value pairs are required for this asset to be selected.
     * This is only used when there are multiple values for a given property.
     * @servertype Boolean
     * @type {boolean}
     */
    AllRequired06: boolean = false;

    /**
     * @name Property07
     * @description 
     * A property used with its corresponding value for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Property07: string = "";

    /**
     * @name Value07
     * @description 
     * A value used with its corresponding property for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Value07: string = "";

    /**
     * @name AllRequired07
     * @description 
     * True indicates that all provided property/value pairs are required for this asset to be selected.
     * This is only used when there are multiple values for a given property.
     * @servertype Boolean
     * @type {boolean}
     */
    AllRequired07: boolean = false;

    /**
     * @name Property08
     * @description 
     * A property used with its corresponding value for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Property08: string = "";

    /**
     * @name Value08
     * @description 
     * A value used with its corresponding property for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Value08: string = "";

    /**
     * @name AllRequired08
     * @description 
     * True indicates that all provided property/value pairs are required for this asset to be selected.
     * This is only used when there are multiple values for a given property.
     * @servertype Boolean
     * @type {boolean}
     */
    AllRequired08: boolean = false;

    /**
     * @name Property09
     * @description 
     * A property used with its corresponding value for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Property09: string = "";

    /**
     * @name Value09
     * @description 
     * A value used with its corresponding property for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Value09: string = "";

    /**
     * @name AllRequired09
     * @description 
     * True indicates that all provided property/value pairs are required for this asset to be selected.
     * This is only used when there are multiple values for a given property.
     * @servertype Boolean
     * @type {boolean}
     */
    AllRequired09: boolean = false;

    /**
     * @name Property10
     * @description 
     * A property used with its corresponding value for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Property10: string = "";

    /**
     * @name Value10
     * @description 
     * A value used with its corresponding property for selection of this asset.
     * @servertype String
     * @type {string}
     */
    Value10: string = "";

    /**
     * @name AllRequired10
     * @description 
     * True indicates that all provided property/value pairs are required for this asset to be selected.
     * This is only used when there are multiple values for a given property.
     * @servertype Boolean
     * @type {boolean}
     */
    AllRequired10: boolean = false;

    /**
     * @name SelectionType
     * @description 
     * The type of selection to perform when the asset is selected.  Possible values include:
     * S = Select (The asset should be selected.)
     * P = Proposed (The asset should be proposed as a possible selection.)
     * @servertype String
     * @type {string}
     */
    SelectionType: string = "S";

    /**
     * @name Inferred
     * @description 
     * True indicates the asset selection criteria were inferred as opposed to explicitly set.  Different machine learning scenarios
     * can result in selection criteria being inferred to help improve system usability.
     * @servertype Boolean
     * @type {boolean}
     */
    Inferred: boolean = false;

    /**
     * @name InferredFirstDateTime
     * @description 
     * The first date and time this asset selection was inferred.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    InferredFirstDateTime: Date = null;

    /**
     * @name InferredLastDateTime
     * @description 
     * The last date and time this asset selection was inferred.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    InferredLastDateTime: Date = null;

    /**
     * @name InferredCount
     * @description 
     * The number of times this asset selection was inferred.  This can be used with a threshold setting to control at what point
     * inferred asset selections should be used by the system.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    InferredCount: number = 0;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name AssetEditViewModel
 * @description
 * The Asset table contains asset text, links to resources like images, videos, web sites, downloads, etc.
 */
export class AssetEditViewModel {

    /**
     * @name AssetId
     * @description 
     * The id of the asset.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = null;

    /**
     * @name ParentAssetId
     * @description 
     * If the asset is owned by another asset this is the asset id of the parent asset.  This can be used to group assets for things
     * like images that appear in an html asset, files that are part of an asset collection for things like widgets that contain
     * html, css, javascript, etc. files.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentAssetId: number = null;

    /**
     * @name ParentAssetTitle
     * @servertype String
     * @type {string}
     */
    ParentAssetTitle: string = "";

    /**
     * @name ExternalAssetId
     * @description 
     * An optional external asset id for this asset.  For child assets that fill specific roles for widgets these may be explicitly
     * named id values like 'template', 'properties-template', 'properties-template-partial'.
     * Likewise notification messages can have specific values that identify messages intended to replace the default messages for
     * certain scenarios like 'New User Welcome', 'Password Recovery', 'Case Assignment', etc.
     * @servertype String
     * @type {string}
     */
    ExternalAssetId: string = "";

    /**
     * @name SystemAssetId
     * @description 
     * A system asset id for this asset.  Typically only assigned for system defined assets that need to be retrieved by this id.
     * @servertype String
     * @type {string}
     */
    SystemAssetId: string = "";

    /**
     * @name SystemAssetGroup
     * @description 
     * A system asset group for this asset.  These are used as system defined groups for picking assets that are valid for the specified
     * group context.  Possible values include:
     * Asset               = Asset (The asset is not part of a system asset group.)
     * Attachment          = Attachment (The asset is an attachment to the object specified as the owner or is an email attachment
     * for a NotificationMessage asset.)
     * NotificationMessage = Notification Message (The asset is a notification message.)
     * InvoiceMessage      = Invoice Message (The asset is an invoice message.)
     * Trigger             = Trigger (The asset is a trigger.)
     * Report              = Report (The asset is a report.)
     * ReportTemplate      = Report Template (The asset is a report template.)
     * Package             = Package (The asset is some type of package.)
     * Data                = Data (The asset is some type of data.)
     * Logo                = Logo (The asset is part of the logo group the file type and dimensions can be used to pick the desired
     * asset within this group.)
     * Article = Article (The asset is an article.)
     * ArticleGroup = Article Group (The asset is an table of contents for a group of articles.)
     * CodeSnippet = Code Snippet (The asset is a code snippet.)
     * WebPage             = Web Page (The asset is part of the web page group.)
     * @servertype String
     * @type {string}
     */
    SystemAssetGroup: string = "Asset";

    /**
     * @name Title
     * @description 
     * Title for this asset.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name ShortDescription
     * @description 
     * Short description for this asset.
     * @servertype String
     * @type {string}
     */
    ShortDescription: string = "";

    /**
     * @name FullDescription
     * @description 
     * Full description for this asset.
     * @servertype String
     * @type {string}
     */
    FullDescription: string = "";

    /**
     * @name Tags
     * @description 
     * A list of tags for this asset.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name AssetText
     * @description 
     * For assets that are text based this is the contents of the asset.  Other assets can be injected using the asset id like {{AssetId:45}}
     * or by using the named identifier like {{AssetName:Help}}.  Injecting assets allows assets to be kept in manageable chunks
     * or to be reused.
     * Other formatting information of possible interest: 
     * Notification messages can include extra queries for notification message context in the format {{!--query:ContextName:select
     * * from Inventory where ContactId = {{Contact.ContactId}} --}} where macros that can be used include those from contact model,
     * notification model, trigger source model, notification context model, PartitionId, ContactId, ContactIdLegacy, and any previously
     * declared query context models.  Another option is {{!-- query:ContextName:4884:1 --}} where the third part is the query id
     * of the query to execute and the optional forth part when set to 1 indicates the results should be a single object instead
     * of a collection of objects.
     * For program asset type this is a json representation of a script model object.
     * @servertype String
     * @type {string}
     */
    AssetText: string = "";

    /**
     * @name AssetTextFingerprint
     * @description 
     * A fingerprint (i.e. checksum or hash) of the asset text that can be used to check for changes.
     * @servertype String
     * @type {string}
     */
    AssetTextFingerprint: string = "";

    /**
     * @name AlternateAssetText
     * @description 
     * For assets that need alternate asset text this is that alternate value.  For example, assets that are notification messages
     * will have formatted asset text (e.g. html, md, etc.) in the asset text property and a plain text version in this alternate
     * asset text property.
     * @servertype String
     * @type {string}
     */
    AlternateAssetText: string = "";

    /**
     * @name AlternateAssetTextFingerprint
     * @description 
     * A fingerprint (i.e. checksum or hash) of the alternate asset text that can be used to check for changes.
     * @servertype String
     * @type {string}
     */
    AlternateAssetTextFingerprint: string = "";

    /**
     * @name AssetOptions
     * @description 
     * Options related to assets of certain types.  For example: 
     * For assets in the Notification Message system asset group this is used for the subject line.  
     * For assets in the Article system asset group this is an object that has article specific options.  
     * For assets of file type TypeScript (ts) this is the JavaScript (js) generated from the TypeScript.  
     * @servertype String
     * @type {string}
     */
    AssetOptions: string = "";

    /**
     * @name AssetOptionsFingerprint
     * @description 
     * A fingerprint (i.e. checksum or hash) of the asset options that can be used to check for changes.
     * @servertype String
     * @type {string}
     */
    AssetOptionsFingerprint: string = "";

    /**
     * @name ExternalUrl
     * @description 
     * For assets that are external assets (e.g. asset types web page and url) this is the URL for accessing that asset.  For external
     * files this is the UNC of the location of the file.
     * @servertype String
     * @type {string}
     */
    ExternalUrl: string = "";

    /**
     * @name FriendlyName
     * @description 
     * The friendly name of this asset.  For items being downloaded this download file name without file extension (the extension
     * is taken from the file type property).  For items not being downloaded this is used as a semantic URL so the URL for the
     * asset is more user friendly.
     * @servertype String
     * @type {string}
     */
    FriendlyName: string = "";

    /**
     * @name PhysicalOrganization
     * @description 
     * The physical path on disk (under the asset folder) where the file based asset is stored.  This is most often a simple yyyy-mm
     * based folder name and is an internal control mechanism not a user defined setting.
     * @servertype String
     * @type {string}
     */
    PhysicalOrganization: string = "";

    /**
     * @name VirtualOrganization
     * @description 
     * A virtual organization to apply to the asset.  This is formatted like a path on disk and is used for presenting assets like
     * attachments in an organized fashion.  For example: \folder 1\sub-folder 14\.
     * @servertype String
     * @type {string}
     */
    VirtualOrganization: string = "";

    /**
     * @name AssetType
     * @description 
     * Asset type.  See the File Type and Content Type properties for more details.  Possible values include:
     * D = Document (The asset is a document file.)
     * I = Image (The asset is an image file.)
     * V = Video (The asset is a video file.)
     * A = Audio (The asset is an audio file.)
     * T = Text (The asset is text.)
     * O = Object (The asset is an object.)
     * S = Settings (The asset is a settings/configuration object.)
     * W = Web page (The asset is a link to a web page.)
     * P = Program (The asset is a program, app, or script.)
     * U = URL (This asset is a link to an external asset of unspecified type.)
     * * = Variables (The asset is a list of variables and values in the format Variable=Value with each on a new line.)
     * ~ = Virtual Organization (The asset is a virtual path for organizing and sharing assets.)
     * C = Collection (The asset is a collection of child assets using the Parent Asset Id property and/or shared assets as referenced
     * in the asset reference table.)
     * @servertype String
     * @type {string}
     */
    AssetType: string = "D";

    /**
     * @name FileType
     * @description 
     * For assets that are files this is the file type for this asset.  It is used as the extension for the file without leading
     * period.  For example: html, jpg, mp4, xml, json, etc.
     * For assets that are programs, apps, or scripts this is the type of program.  Since programs are tied to a particular context
     * (e.g. Webhooks) this setting can be utilized to limit which programs can be selected in a given context.  Possible values
     * include: 
     * TextBuilder = Text Builder (Program can be triggered when custom text needs to be built)
     * WebhookProcessor = Webhook Processor (Program can be triggered by a webhook)
     * Other = Other (Other Program)
     * @servertype String
     * @type {string}
     */
    FileType: string = "";

    /**
     * @name ContentType
     * @description 
     * The content type for this asset.  For example: text/html or image/jpg.
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name ContentCategory
     * @description 
     * A content category for this asset.  Taken from pick list or entered text depending on the use context.  Often used in conjunction
     * with author information.
     * @servertype String
     * @type {string}
     */
    ContentCategory: string = "";

    /**
     * @name ActionType
     * @description 
     * The type of action to take when the asset is accessed.  Possible values include:
     * D = Download - asset should be downloaded.
     * V = View - the asset should be viewed.
     * S = Smart - the asset should be viewed when viewable; otherwise, downloaded.
     * @servertype String
     * @type {string}
     */
    ActionType: string = "S";

    /**
     * @name SizeBytes
     * @description 
     * Size (in bytes) of asset.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SizeBytes: number = 0;

    /**
     * @name SizeOther
     * @description 
     * Size of asset other than the number of bytes.  For example, pages for documents, seconds of duration for video and audio,
     * etc.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SizeOther: number = 0;

    /**
     * @name SizeInformation
     * @description 
     * Meta information about the size of the asset.  Since physical size can be represented by the Size Bytes property and page
     * count or duration can be represented by the Size Other property, this is typically meta about the asset size that cannot
     * otherwise be represented.
     * @servertype String
     * @type {string}
     */
    SizeInformation: string = "";

    /**
     * @name Height
     * @description 
     * The height of the asset.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Height: number = 0;

    /**
     * @name Width
     * @description 
     * The width of the asset.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Width: number = 0;

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the asset in when using a display ordered interface.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Priority
     * @description 
     * The priority for assets that need a priority.  A lower value has higher priority.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name AssetCategory
     * @description 
     * Category for this asset.  This is taken from system pick list.
     * @servertype String
     * @type {string}
     */
    AssetCategory: string = "";

    /**
     * @name AssetClass
     * @description 
     * Class for this asset.  This is taken from system pick list.
     * @servertype String
     * @type {string}
     */
    AssetClass: string = "";

    /**
     * @name AssetClassInferred
     * @description 
     * The inferred class for this asset.  This is used in conjunction with machine learning inference of asset class.
     * @servertype String
     * @type {string}
     */
    AssetClassInferred: string = "";

    /**
     * @name AssetClassInferredDateTime
     * @description 
     * The date and time this asset class was inferred.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AssetClassInferredDateTime: Date = null;

    /**
     * @name AssetClassInferredProbability
     * @description 
     * The probability associated with the inferred asset class as a decimal (i.e. 0.10 is 10%).
     * @servertype Double (nullable)
     * @type {number}
     */
    AssetClassInferredProbability: number = 0;

    /**
     * @name AssetGroup
     * @description 
     * Group for this asset.  This is taken from system pick list.
     * @servertype String
     * @type {string}
     */
    AssetGroup: string = "";

    /**
     * @name AssetScope
     * @description 
     * Setting that indicates the scope this asset is applicable to.  
     * For assets that are part of the notification message system asset group the possible values include:
     * Email = Email Message
     * SMS = SMS Message
     * Phone = Phone Message/Script
     * Fax = Fax Message
     *   = Not Specified
     * For assets that are part of the attachment system asset group the possible values include:
     * Primary = Primary
     *   = Not Specified
     * For assets that are part of the trigger system asset group the possible values include:
     * Before = Trigger is executed before the event happens
     * After = Trigger is executed after the event happens
     * For assets that are part of the article system asset group this is an optional see also group name that will result in articles
     * that have the same group name being included in a see also list of articles.  See asset options for more information on see
     * also list creation.
     * For other assets this could be any custom value that makes sense for the asset.
     * @servertype String
     * @type {string}
     */
    AssetScope: string = "";

    /**
     * @name LastObjectStatusAnalysisDateTime
     * @description 
     * The date and time this asset was last the subject of an object status analysis.  This can be combined with the last edit
     * date time to determine if a new analysis is warranted.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastObjectStatusAnalysisDateTime: Date = null;

    /**
     * @name Comments
     * @description 
     * Comments about the asset.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Searchable
     * @description 
     * True indicates the asset should be searchable.
     * @servertype Boolean
     * @type {boolean}
     */
    Searchable: boolean = false;

    /**
     * @name ReadOnly
     * @description 
     * True indicates the asset is considered read-only.  That does not prevent the asset record from being edited but is a logical
     * indicator used along with the asset.
     * @servertype Boolean
     * @type {boolean}
     */
    ReadOnly: boolean = false;

    /**
     * @name LogAccess
     * @description 
     * True indicates the access to this asset is logged in the asset access log.
     * @servertype Boolean
     * @type {boolean}
     */
    LogAccess: boolean = false;

    /**
     * @name MaximumAccessCount
     * @description 
     * When logging access this is the maximum number of times the asset can be accessed by a user.  For authenticated access this
     * is measured by user and for non-authenticated access this is measured by ip address.  A value of 0 means there is no maximum
     * access count enforced.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaximumAccessCount: number = 0;

    /**
     * @name RequiresRegistration
     * @description 
     * True indicates the asset can only be displayed to registered users.
     * @servertype Boolean
     * @type {boolean}
     */
    RequiresRegistration: boolean = false;

    /**
     * @name RequiresSupportPlan
     * @description 
     * True indicates the asset can only be displayed to users with an active support plan.
     * @servertype Boolean
     * @type {boolean}
     */
    RequiresSupportPlan: boolean = false;

    /**
     * @name RequiresContactType
     * @description 
     * A list of contact types that are allowed to access this asset (e.g. C,D,A).
     * @servertype string array
     * @type {string[]}
     */
    RequiresContactType: string[] = [];

    /**
     * @name Visibility
     * @description 
     * Asset visibility.  Possible values include:
     * P = Public (The asset is publicly available.)
     * V = Private (Private assets are only available to the person who created the asset.)
     * I = Internal (Internal assets are public but only for directory users.)
     * H = Hidden (Hidden is used to remove access to an asset without deleting the asset.)
     * D = Draft (Draft indicates the asset is a work in progress and is not completed and is only available to the person who created
     * the asset.)
     * @servertype String
     * @type {string}
     */
    Visibility: string = "I";

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this asset is visible.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this asset is visible.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) of this asset if the asset belongs to another resource.  This is most often used
     * for assets that are attachments to the resource stated in the owner resource type and id or for assets that are triggers
     * for the resource type stated here.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this asset.  For example, if the owner resource type is InventoryType
     * this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.  For assets that are triggers
     * this can be a CSV of event types that the trigger belongs to with possible values of: add,edit,delete
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name SecondaryOwnerResourceType
     * @description 
     * The secondary owner resource type (e.g. table, etc.) of this asset if the asset belongs to another resource.  An example
     * where this might be used is when the asset is an attachment to a note but that note is attached to a case.  The owner resource
     * type would be note and the secondary owner resource type would be case.
     * @servertype String
     * @type {string}
     */
    SecondaryOwnerResourceType: string = "";

    /**
     * @name SecondaryOwnerResourceId
     * @description 
     * The secondary owner resource id is the key of the owner resource type for this asset.  For example, if the owner resource
     * type is InventoryType this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SecondaryOwnerResourceId: number = 0;

    /**
     * @name SecondaryOwnerResourceId2
     * @description 
     * The secondary owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    SecondaryOwnerResourceId2: string = "";

    /**
     * @name ShowAddedByInformation
     * @description 
     * A list of information that can be displayed about the person who added this asset.  Possible values include: 
     * N = Name
     * E = Email
     * @servertype string array
     * @type {string[]}
     */
    ShowAddedByInformation: string[] = [];

    /**
     * @name AuthorLongName
     * @description 
     * The author long name for this asset.
     * @servertype String
     * @type {string}
     */
    AuthorLongName: string = "";

    /**
     * @name AuthorShortName
     * @description 
     * The author short name for this asset.
     * @servertype String
     * @type {string}
     */
    AuthorShortName: string = "";

    /**
     * @name AuthorContactInformation
     * @description 
     * Contact information for the author.
     * @servertype String
     * @type {string}
     */
    AuthorContactInformation: string = "";

    /**
     * @name Copyright
     * @description 
     * The copyright for this asset.
     * @servertype String
     * @type {string}
     */
    Copyright: string = "";

    /**
     * @name License
     * @description 
     * The license for this asset.
     * @servertype String
     * @type {string}
     */
    License: string = "";

    /**
     * @name LicenseUrl
     * @description 
     * The url for this asset license.
     * @servertype String
     * @type {string}
     */
    LicenseUrl: string = "";

    /**
     * @name LicenseAnonymous
     * @description 
     * True indicates the licensor waived attribution requirements and/or has exercised their right to be anonymous from any attribution.
     * @servertype Boolean
     * @type {boolean}
     */
    LicenseAnonymous: boolean = false;

    /**
     * @name LicenseInformation
     * @description 
     * Information about the license for this asset stored as collection of json objects that have the following properties: 
     * DatabaseId = The database id where the asset originated from.
     * PartitionId = The partition id where the asset originated from.
     * AssetId = The asset id where the asset originated from.
     * Title = The title of the work represented by the asset.
     * AttributedToName = The entity the work is attributed to.
     * AttributedToUrl = The url to reference for attribution.
     * LicenseUrls = A collection of key value pairs where the key is a label and the value is a url.
     * LicenseText = Text related to the license (if needed).
     * Modifications = A description of any modifications to the work.
     * AsOfDate = The as-of date when the work was copied for modification.
     * Anonymous = If true the author has exercised their right to anonymity.
     * @servertype AssetLicenseInformationViewModel array
     * @type {AssetLicenseInformationViewModel[]}
     */
    LicenseInformation: AssetLicenseInformationViewModel[] = [];

    /**
     * @name ReferenceUrl
     * @description 
     * The url to serve as a reference for this asset.
     * @servertype String
     * @type {string}
     */
    ReferenceUrl: string = "";

    /**
     * @name ReadOnlyShareId
     * @description 
     * A guid-style id that is used to reference this asset for read-only sharing purposes.  Note that anyone that has the URL that
     * references this value will be able to view the asset.
     * @servertype String
     * @type {string}
     */
    ReadOnlyShareId: string = "";

    /**
     * @name HitCount
     * @description 
     * Number of times the asset was accessed.  This is typically only used for searchable assets.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HitCount: number = 0;

    /**
     * @name LastHitDateTime
     * @description 
     * The date and time when the asset was last accessed.  This is typically only used for searchable assets.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastHitDateTime: Date = null;

    /**
     * @name PromptHelped
     * @description 
     * True indicates the user is prompted to indicate if this asset helped (yes, no, n/a).
     * @servertype Boolean
     * @type {boolean}
     */
    PromptHelped: boolean = false;

    /**
     * @name PromptRating
     * @description 
     * True indicates the user is prompted to provide a 1-5 rating for this asset.
     * @servertype Boolean
     * @type {boolean}
     */
    PromptRating: boolean = false;

    /**
     * @name PromptFeedback
     * @description 
     * True indicates the user is prompted to leave feedback regarding the asset.
     * @servertype Boolean
     * @type {boolean}
     */
    PromptFeedback: boolean = false;

    /**
     * @name AllowAnonymousFeedback
     * @description 
     * True indicates we accept anonymous feedback.  Some assets allow anonymous access but do not accept anonymous feedback.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousFeedback: boolean = false;

    /**
     * @name HelpedYes
     * @description 
     * Number of times user said the asset helped.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HelpedYes: number = 0;

    /**
     * @name HelpedYesPercent
     * @servertype Double
     * @type {number}
     */
    HelpedYesPercent: number = 0;

    /**
     * @name HelpedNo
     * @description 
     * Number of times user said the asset did not help.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HelpedNo: number = 0;

    /**
     * @name HelpedNoPercent
     * @servertype Double
     * @type {number}
     */
    HelpedNoPercent: number = 0;

    /**
     * @name HelpedNa
     * @description 
     * Number of times user said the asset was not applicable.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HelpedNa: number = 0;

    /**
     * @name HelpedNaPercent
     * @servertype Double
     * @type {number}
     */
    HelpedNaPercent: number = 0;

    /**
     * @name HelpedTotal
     * @servertype Int32
     * @type {number}
     */
    HelpedTotal: number = 0;

    /**
     * @name Rating01
     * @description 
     * Number of times user rated the asset as 1 on scale of 1-5.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating01: number = 0;

    /**
     * @name Rating01Percent
     * @servertype Double
     * @type {number}
     */
    Rating01Percent: number = 0;

    /**
     * @name Rating02
     * @description 
     * Number of times user rated the asset as 2 on scale of 1-5.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating02: number = 0;

    /**
     * @name Rating02Percent
     * @servertype Double
     * @type {number}
     */
    Rating02Percent: number = 0;

    /**
     * @name Rating03
     * @description 
     * Number of times user rated the asset as 3 on scale of 1-5.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating03: number = 0;

    /**
     * @name Rating03Percent
     * @servertype Double
     * @type {number}
     */
    Rating03Percent: number = 0;

    /**
     * @name Rating04
     * @description 
     * Number of times user rated the asset as 4 on scale of 1-5.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating04: number = 0;

    /**
     * @name Rating04Percent
     * @servertype Double
     * @type {number}
     */
    Rating04Percent: number = 0;

    /**
     * @name Rating05
     * @description 
     * Number of times user rated the asset as 5 on scale of 1-5.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Rating05: number = 0;

    /**
     * @name Rating05Percent
     * @servertype Double
     * @type {number}
     */
    Rating05Percent: number = 0;

    /**
     * @name RatingAverage
     * @servertype Double
     * @type {number}
     */
    RatingAverage: number = 0;

    /**
     * @name RatingTotal
     * @servertype Int32
     * @type {number}
     */
    RatingTotal: number = 0;

    /**
     * @name NeedsReview
     * @description 
     * True indicates this asset was flagged as needing review.  That could be because of a broken link, content that was tagged
     * as inappropriate, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    NeedsReview: boolean = false;

    /**
     * @name ReviewedDateTime
     * @description 
     * The date and time when the asset was last reviewed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReviewedDateTime: Date = null;

    /**
     * @name ReviewedByContactId
     * @description 
     * The contact id that represents who last reviewed this asset.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReviewedByContactId: number = null;

    /**
     * @name ReviewedByContactName
     * @servertype String
     * @type {string}
     */
    ReviewedByContactName: string = "";

    /**
     * @name ReviewComments
     * @description 
     * Comments entered about the review.  This can be used to explain why something was flagged for review or to add notes about
     * the review.
     * @servertype String
     * @type {string}
     */
    ReviewComments: string = "";

    /**
     * @name NextReviewDateTime
     * @description 
     * The date and time when the asset should next be reviewed.  NULL means no further review is needed.  This is frequently used
     * when the asset may become stale, out-of-date, or otherwise less useful over time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NextReviewDateTime: Date = null;

    /**
     * @name NextReviewContactId
     * @description 
     * The Contact Id that is assigned to perform the next review.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NextReviewContactId: number = null;

    /**
     * @name NextReviewContactName
     * @servertype String
     * @type {string}
     */
    NextReviewContactName: string = "";

    /**
     * @name ApprovalPending
     * @description 
     * True indicates this asset is ready for review and approval.
     * @servertype Boolean
     * @type {boolean}
     */
    ApprovalPending: boolean = false;

    /**
     * @name ApprovedDateTime
     * @description 
     * The date and time when the asset was approved.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ApprovedDateTime: Date = null;

    /**
     * @name ApprovedByContactId
     * @description 
     * The contact id that represents who approved this asset.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApprovedByContactId: number = null;

    /**
     * @name ApprovedByContactName
     * @servertype String
     * @type {string}
     */
    ApprovedByContactName: string = "";

    /**
     * @name ApprovalComments
     * @description 
     * Comments entered regarding the approval process.  This can be used to explain why something wasn't approved or to add notes
     * about the approval.
     * @servertype String
     * @type {string}
     */
    ApprovalComments: string = "";

    /**
     * @name AccessPassword
     * @description 
     * The password that is required for access to this asset (if any).
     * @servertype String
     * @type {string}
     */
    AccessPassword: string = "";

    /**
     * @name AssetPassword
     * @description 
     * The password for this asset.  This could be an encryption password, key password, etc. depending on how the asset was secured.
     * @servertype String
     * @type {string}
     */
    AssetPassword: string = "";

    /**
     * @name SourceAssetId
     * @description 
     * If the asset was originated as a copy of another asset this is the asset id of the source asset.  This can provide the benefit
     * of being able to know which assets from a central repository of assets (e.g. partition 0 assets) have already been copied
     * for local use.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceAssetId: number = null;

    /**
     * @name Language
     * @description 
     * Language for this asset.  Stored in format lg or lg-lo where lg is the 2 byte iso language identifier and lo is the 2 byte
     * iso locale identifier.  For example, English may be stored as 'en' or 'en-US' or 'en-UK'.
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name VersionId
     * @description 
     * A guid-style id that is used to indicate that this is a version of an existing asset.  All assets that are versions of the
     * same asset have the same version id value including versions that are targeting different languages or file types (e.g. jpg,
     * png, etc.).  Assets that are the only version of an asset should typically have this value empty.
     * @servertype String
     * @type {string}
     */
    VersionId: string = "";

    /**
     * @name VersionNumber
     * @description 
     * An incrementing value that indicates the version number of this asset.  This can be used in conjunction with VersionId when
     * keeping multiple versions of an asset or as the way of versioning a structured asset (e.g. xml) where that structure may
     * be changed for future versions of the asset.  Versions that are the same revision but targeting different languages or file
     * types should typically have the same version number for identification purposes.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    VersionNumber: number = 0;

    /**
     * @name VersionCurrent
     * @description 
     * True indicates this asset is the current version of the asset.  See Version Id and Version Number.  Note that multiple assets
     * with the same version id can be marked as current when they are for different languages, file types, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    VersionCurrent: boolean = false;

    /**
     * @name VersionFinal
     * @description 
     * True indicates this asset is the final version of the asset.  See Version Id and Version Number.  Note that multiple assets
     * with the same version id can be marked as current when they are for different languages, file types, etc.  This can also
     * be used for non-versioned assets to flag that the asset as been finalized.
     * @servertype Boolean
     * @type {boolean}
     */
    VersionFinal: boolean = false;

    /**
     * @name VersionInventoryTypeId
     * @description 
     * If this asset is linked to versions of a specific inventory type this the inventory type id it is linked to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VersionInventoryTypeId: number = null;

    /**
     * @name VersionStarting
     * @description 
     * For assets tied to versions this is the starting version that this asset is valid for.  When this is blank but an ending
     * version is specified than this is considered valid for all versions though that ending version.
     * @servertype String
     * @type {string}
     */
    VersionStarting: string = "";

    /**
     * @name VersionEnding
     * @description 
     * For assets tied to versions this is the ending version that this asset is valid for.  When this is blank but a staring version
     * is specified than this is considered valid for all versions starting with the starting version.
     * @servertype String
     * @type {string}
     */
    VersionEnding: string = "";

    /**
     * @name VersionStartingAllowClosest
     * @description 
     * When true and no asset can be found that includes the desired version because it is earlier than the earliest provided version
     * then allow selecting that asset as the closest match.  When false then no match will be found.  For articles text explaining
     * that a closest match was chosen may be injected at the top of the article.  See asset options for articles for more information.
     * @servertype Boolean
     * @type {boolean}
     */
    VersionStartingAllowClosest: boolean = false;

    /**
     * @name VersionEndingAllowClosest
     * @description 
     * When true and no asset can be found that includes the desired version because it is later than the latest provided version
     * then allow selecting that asset as the closest match.  When false then no match will be found.  For articles text explaining
     * that a closest match was chosen may be injected at the top of the article.  See asset options for articles for more information.
     * @servertype Boolean
     * @type {boolean}
     */
    VersionEndingAllowClosest: boolean = false;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Selections
     * @servertype AssetSelectionEditViewModel array
     * @type {AssetSelectionEditViewModel[]}
     */
    Selections: AssetSelectionEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name AssetLicenseInformationViewModel
 */
export class AssetLicenseInformationViewModel {

    /**
     * @name DatabaseId
     * @servertype String
     * @type {string}
     */
    DatabaseId: string = "";

    /**
     * @name PartitionId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name AssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name AttributedToName
     * @servertype String
     * @type {string}
     */
    AttributedToName: string = "";

    /**
     * @name AttributedToUrl
     * @servertype String
     * @type {string}
     */
    AttributedToUrl: string = "";

    /**
     * @name LicenseUrls
     * @servertype AssetLicenseUrlViewModel array
     * @type {AssetLicenseUrlViewModel[]}
     */
    LicenseUrls: AssetLicenseUrlViewModel[] = [];

    /**
     * @name LicenseText
     * @servertype String
     * @type {string}
     */
    LicenseText: string = "";

    /**
     * @name Modifications
     * @servertype String
     * @type {string}
     */
    Modifications: string = "";

    /**
     * @name AsOfDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AsOfDate: Date = null;

    /**
     * @name Anonymous
     * @servertype Boolean
     * @type {boolean}
     */
    Anonymous: boolean = false;

}



/**
 * @name AssetVisibilityEditViewModel
 */
export class AssetVisibilityEditViewModel {

    /**
     * @name AssetVisibilityId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetVisibilityId: number = 0;

    /**
     * @name AssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name VisibilityType
     * @servertype String
     * @type {string}
     */
    VisibilityType: string = "";

    /**
     * @name VisibilityTypeId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VisibilityTypeId: number = 0;

    /**
     * @name VisibilityTypeDescription
     * @servertype String
     * @type {string}
     */
    VisibilityTypeDescription: string = "";

    /**
     * @name RequireValidMaintenance
     * @servertype Boolean
     * @type {boolean}
     */
    RequireValidMaintenance: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name AttributeConfigurationEditViewModel
 * @description
 * The attribute configuration table contains configuration information for attributes that are part of an attribute set.
 */
export class AttributeConfigurationEditViewModel {

    /**
     * @name AttributeConfigurationId
     * @description 
     * The id of the attribute configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AttributeConfigurationId: number = null;

    /**
     * @name AttributeSetId
     * @description 
     * The id of the attribute set this belongs to.
     * @servertype Int64
     * @type {number}
     */
    AttributeSetId: number = null;

    /**
     * @name AttributeNumber
     * @description 
     * The attribute number that this configuration is for.  Possible values are 1-100 as contained in the attribute table.  If
     * the attribute set extends a table that has its own attribute columns there may be less than 100 columns available.  For attribute
     * sets using the attribute table note that the first 10 attributes are limited to 500 characters and are indexed for faster
     * lookup and reporting.
     * @servertype Int32
     * @type {number}
     */
    AttributeNumber: number = 0;

    /**
     * @name AttributeName
     * @description 
     * The name of the attribute.  This needs to be unique within the attribute set.
     * @servertype String
     * @type {string}
     */
    AttributeName: string = "";

    /**
     * @name Description
     * @description 
     * A description for this attribute configuration.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DataType
     * @description 
     * The data type of this attribute.  All attributes are stored as strings but this data type setting can be used to assist with
     * data input.  Possible values include: 
     * String = String
     * PickList = Pick List Value
     * Tags = Tags (stored as csv, this is also stored in Tag table with owner resource type of 'AttributeSet' + the attribute set
     * id and owner resource id of the attribute id)
     * Integer = Integer
     * Float = Float
     * DateTime = DateTime
     * Date = Date
     * Time = Time
     * Boolean = Boolean
     * ListString = List of Strings
     * ListObject = List of Objects
     * Object = Object
     * Other = Other
     * Unknown = Unknown
     * @servertype String
     * @type {string}
     */
    DataType: string = "String";

    /**
     * @name SharedPickListId
     * @description 
     * When the data type for this attribute is a pick list this is the name of the pick list to use.  Note that all attribute pick
     * lists will begin with 'AttributeSet.' to distinguish them from other system pick lists.
     * @servertype String
     * @type {string}
     */
    SharedPickListId: string = null;

    /**
     * @name DefaultValue
     * @description 
     * The default value for this attribute.
     * @servertype String
     * @type {string}
     */
    DefaultValue: string = "";

    /**
     * @name Mask
     * @description 
     * The mask used to apply to data input controls.  Possible values include:
     * A = Any alpha character.
     * 9 = Any numeric character.
     * * = Any alphanumeric character.
     * All other characters are treated as literals.
     * @servertype String
     * @type {string}
     */
    Mask: string = "";

    /**
     * @name Required
     * @description 
     * When true the attribute is required.
     * @servertype Boolean
     * @type {boolean}
     */
    Required: boolean = false;

    /**
     * @name Minimum
     * @description 
     * The minimum value (int) or length (string) of the value for this attribute.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Minimum: number = 0;

    /**
     * @name Maximum
     * @description 
     * The maximum value (int) or length (string) of the value for this attribute.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Maximum: number = 0;

    /**
     * @name Validation
     * @description 
     * Expressions used to validate input of the attribute value.  Regular expressions start with a 'REGEX:' prefix.  Possible values
     * include:
     * REGEX:[regular expression] where [regular expression] is any regular expression.
     * @servertype String
     * @type {string}
     */
    Validation: string = "";

    /**
     * @name ValidationFailedMessage
     * @description 
     * A message to display when the validation failed.
     * @servertype String
     * @type {string}
     */
    ValidationFailedMessage: string = "";

    /**
     * @name Encrypt
     * @description 
     * When true the attribute is encrypted when stored provided a message exchange id has been selected.
     * @servertype Boolean
     * @type {boolean}
     */
    Encrypt: boolean = false;

    /**
     * @name MessageExchangeId
     * @description 
     * The id of the message exchange to use for encrypting this value when stored.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    MessageExchangeId: number = null;

    /**
     * @name DescriptionAttribute
     * @description 
     * When true the attribute is considered the description for the attributes in the attribute set.  This is used for page titles,
     * links, etc. when representing the data.
     * @servertype Boolean
     * @type {boolean}
     */
    DescriptionAttribute: boolean = false;

    /**
     * @name PublicAttribute
     * @description 
     * When true the attribute is considered a public attribute that can be exposed to end users.
     * @servertype Boolean
     * @type {boolean}
     */
    PublicAttribute: boolean = true;

    /**
     * @name Properties
     * @description 
     * Properties related to this attribute configuration.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name AttributeSetEditViewModel
 * @description
 * The Attribute Set table contains a list of attribute sets.  An attribute set is like a table, collection, or bag of attributes
 * related to each other.  For example, an attribute set named Account History might have attributes like year, number of sales,
 * total of sales, etc.  A single attribute set can be defined as an extension of current tables like contact, billing account,
 * package, etc.
 */
export class AttributeSetEditViewModel {

    /**
     * @name AttributeSetId
     * @description 
     * The id of the attribute set.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AttributeSetId: number = null;

    /**
     * @name Name
     * @description 
     * The name of this attribute set.
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Description
     * @description 
     * The description of this attribute set.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name AttributeSetType
     * @description 
     * The type of attribute set.  Possible values include:
     * I = Independent Attribute Set
     * C = Child Attribute Set (attribute set child of the target - 1:M)
     * E = Extension Attribute Set (attribute set extends the target - 1:1)
     * Note that some tables have extension attributes stored in the table itself in ValueXXX or similar columns instead of in the
     * attribute table.
     * @servertype String
     * @type {string}
     */
    AttributeSetType: string = "I";

    /**
     * @name AttributeSetTarget
     * @description 
     * The table name that the attribute set is extending or child of based on attribute set type.  For example: Contact, BillingAccount,
     * etc.
     * For situations where the attribute set differs from one row to the next because the attributes are being applied to children
     * rows this value should include the primary key in TableName.PrimaryKey format.  For example, package attributes are applied
     * to the package occurrence table but defined in the package table.  Since attributes for each package may be different this
     * column would contain Package.PackageId (e.g. Package.47).
     * Other common uses include: 
     * Extension of contact table for different contact types - This column would contain Directory, Customer, Agent, ServiceProvider,
     * Warehouse, Vendor, or Contact so different contact types can have different attributes defined.
     * Task attributes - This column would contain TaskTemplate.TaskTemplateId (e.g. TaskTemplate.48481) and then all tasks that
     * implemented that task template would use the attributes defined.
     * Task List attributes - This column would contain TaskListTemplate.TaskListTemplateId (e.g. TaskListTemplate.1233) and then
     * all task lists that implemented that task list template would use the attributes defined.
     * Inventory attributes - This column would contain InventoryType.InventoryTypeId (e.g. InventoryType.15) and then all inventory
     * objects of inventory type 15 would use the attributes defined.
     * @servertype String
     * @type {string}
     */
    AttributeSetTarget: string = "";

    /**
     * @name AttributeSetScope
     * @description 
     * A scope to use along with the type when determining the attribute set to utilize.  This may be a foreign key reference (e.g.
     * Package:48481 for a Package Occurrence) or a pseudo foreign key reference (e.g. PackageCode:WiFi for a Package Occurrence),
     * etc.
     * @servertype String
     * @type {string}
     */
    AttributeSetScope: string = "";

    /**
     * @name RoleId
     * @description 
     * When specified this is the role id that is required for access to this attribute set.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name SecurityAreaType
     * @description 
     * This is the security area type.  Possible values include: 
     * TB = Table
     * TG = Table Group
     * RP = Report
     * RG = Report Group
     * PA = Permission Area
     * SI = Sensitive Information
     * @servertype String
     * @type {string}
     */
    SecurityAreaType: string = "TB";

    /**
     * @name SecurityArea
     * @description 
     * The security area that is used for this attribute set.
     * @servertype String
     * @type {string}
     */
    SecurityArea: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the attribute set is enabled.  Attribute sets that are disabled cannot have attributes added to them.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time.  When specified the attribute set is considered disabled prior to this time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time.  When specified the attribute set is considered disabled after this time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Properties
     * @description 
     * Properties related to this attribute set.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Details
     * @servertype AttributeConfigurationEditViewModel array
     * @type {AttributeConfigurationEditViewModel[]}
     */
    Details: AttributeConfigurationEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name AttributeEditViewModel
 * @description
 * The attribute table contains attribute values that are part of an attribute set.
 */
export class AttributeEditViewModel {

    /**
     * @name AttributeId
     * @description 
     * The id of the attribute.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AttributeId: number = null;

    /**
     * @name AttributeSetId
     * @description 
     * The id of the attribute set this belongs to.  Note that negative values represent reserved system attribute sets that have
     * system defined known mappings for attribute values for the stated purpose.  For example:
     * -1001 = Report Compiler Case Options
     * -1011 = Report Compiler Subject Matter
     * -1021 = Report Compiler Report Section Configuration
     * -1031 = Report Compiler Report Attachment Configuration
     * -1041 = Report Compiler Report Text
     * -1051 = Report Compiler Report Attachment
     * @servertype Int64
     * @type {number}
     */
    AttributeSetId: number = null;

    /**
     * @name AttributeSetTarget
     * @description 
     * The table name that the attribute set is extending or child of based on attribute set type.  This will be null for independent
     * attribute sets.  See attribute set table for more information.
     * @servertype String
     * @type {string}
     */
    AttributeSetTarget: string = null;

    /**
     * @name AttributeSetTargetId
     * @description 
     * The id of owner of this attribute set.  For example, the contact id, billing account id, etc.  This will be null for independent
     * attribute sets.
     * @servertype Int64
     * @type {number}
     */
    AttributeSetTargetId: number = null;

    /**
     * @name AttributeSetTargetExternalId
     * @servertype String
     * @type {string}
     */
    AttributeSetTargetExternalId: string = "";

    /**
     * @name AttributeSetTargetDescription
     * @servertype String
     * @type {string}
     */
    AttributeSetTargetDescription: string = "";

    /**
     * @name AttributeSetTargetName
     * @servertype String
     * @type {string}
     */
    AttributeSetTargetName: string = "";

    /**
     * @name Value001
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Value006
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value006: string = "";

    /**
     * @name Value007
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value007: string = "";

    /**
     * @name Value008
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value008: string = "";

    /**
     * @name Value009
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value009: string = "";

    /**
     * @name Value010
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value010: string = "";

    /**
     * @name Value011
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value011: string = "";

    /**
     * @name Value012
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value012: string = "";

    /**
     * @name Value013
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value013: string = "";

    /**
     * @name Value014
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value014: string = "";

    /**
     * @name Value015
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value015: string = "";

    /**
     * @name Value016
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value016: string = "";

    /**
     * @name Value017
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value017: string = "";

    /**
     * @name Value018
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value018: string = "";

    /**
     * @name Value019
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value019: string = "";

    /**
     * @name Value020
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value020: string = "";

    /**
     * @name Value021
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value021: string = "";

    /**
     * @name Value022
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value022: string = "";

    /**
     * @name Value023
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value023: string = "";

    /**
     * @name Value024
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value024: string = "";

    /**
     * @name Value025
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value025: string = "";

    /**
     * @name Value026
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value026: string = "";

    /**
     * @name Value027
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value027: string = "";

    /**
     * @name Value028
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value028: string = "";

    /**
     * @name Value029
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value029: string = "";

    /**
     * @name Value030
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value030: string = "";

    /**
     * @name Value031
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value031: string = "";

    /**
     * @name Value032
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value032: string = "";

    /**
     * @name Value033
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value033: string = "";

    /**
     * @name Value034
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value034: string = "";

    /**
     * @name Value035
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value035: string = "";

    /**
     * @name Value036
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value036: string = "";

    /**
     * @name Value037
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value037: string = "";

    /**
     * @name Value038
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value038: string = "";

    /**
     * @name Value039
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value039: string = "";

    /**
     * @name Value040
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value040: string = "";

    /**
     * @name Value041
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value041: string = "";

    /**
     * @name Value042
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value042: string = "";

    /**
     * @name Value043
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value043: string = "";

    /**
     * @name Value044
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value044: string = "";

    /**
     * @name Value045
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value045: string = "";

    /**
     * @name Value046
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value046: string = "";

    /**
     * @name Value047
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value047: string = "";

    /**
     * @name Value048
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value048: string = "";

    /**
     * @name Value049
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value049: string = "";

    /**
     * @name Value050
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value050: string = "";

    /**
     * @name Value051
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value051: string = "";

    /**
     * @name Value052
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value052: string = "";

    /**
     * @name Value053
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value053: string = "";

    /**
     * @name Value054
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value054: string = "";

    /**
     * @name Value055
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value055: string = "";

    /**
     * @name Value056
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value056: string = "";

    /**
     * @name Value057
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value057: string = "";

    /**
     * @name Value058
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value058: string = "";

    /**
     * @name Value059
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value059: string = "";

    /**
     * @name Value060
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value060: string = "";

    /**
     * @name Value061
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value061: string = "";

    /**
     * @name Value062
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value062: string = "";

    /**
     * @name Value063
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value063: string = "";

    /**
     * @name Value064
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value064: string = "";

    /**
     * @name Value065
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value065: string = "";

    /**
     * @name Value066
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value066: string = "";

    /**
     * @name Value067
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value067: string = "";

    /**
     * @name Value068
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value068: string = "";

    /**
     * @name Value069
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value069: string = "";

    /**
     * @name Value070
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value070: string = "";

    /**
     * @name Value071
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value071: string = "";

    /**
     * @name Value072
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value072: string = "";

    /**
     * @name Value073
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value073: string = "";

    /**
     * @name Value074
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value074: string = "";

    /**
     * @name Value075
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value075: string = "";

    /**
     * @name Value076
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value076: string = "";

    /**
     * @name Value077
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value077: string = "";

    /**
     * @name Value078
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value078: string = "";

    /**
     * @name Value079
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value079: string = "";

    /**
     * @name Value080
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value080: string = "";

    /**
     * @name Value081
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value081: string = "";

    /**
     * @name Value082
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value082: string = "";

    /**
     * @name Value083
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value083: string = "";

    /**
     * @name Value084
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value084: string = "";

    /**
     * @name Value085
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value085: string = "";

    /**
     * @name Value086
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value086: string = "";

    /**
     * @name Value087
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value087: string = "";

    /**
     * @name Value088
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value088: string = "";

    /**
     * @name Value089
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value089: string = "";

    /**
     * @name Value090
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value090: string = "";

    /**
     * @name Value091
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value091: string = "";

    /**
     * @name Value092
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value092: string = "";

    /**
     * @name Value093
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value093: string = "";

    /**
     * @name Value094
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value094: string = "";

    /**
     * @name Value095
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value095: string = "";

    /**
     * @name Value096
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value096: string = "";

    /**
     * @name Value097
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value097: string = "";

    /**
     * @name Value098
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value098: string = "";

    /**
     * @name Value099
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value099: string = "";

    /**
     * @name Value100
     * @description 
     * One attribute value.
     * @servertype String
     * @type {string}
     */
    Value100: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name AssetFeedbackEditViewModel
 * @description
 * The Feedback table contains feedback on various objects that solicit feedback like assets and cases.
 */
export class AssetFeedbackEditViewModel {

    /**
     * @name AssetFeedbackId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetFeedbackId: number = 0;

    /**
     * @name AssetId
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name FeedbackDateTime
     * @description 
     * The date and time when the feedback was submitted.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FeedbackDateTime: Date = null;

    /**
     * @name FeedbackByContactId
     * @description 
     * The Contact Id that added this feedback.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FeedbackByContactId: number = null;

    /**
     * @name FeedbackByContactName
     * @servertype String
     * @type {string}
     */
    FeedbackByContactName: string = "";

    /**
     * @name FeedbackIpAddress
     * @description 
     * The IP address and/or device id of this person submitting the feedback.  In cases where the feedback is anonymous (i.e. the
     * FeedbackByContactId is empty) this is used to assist in avoiding duplicate ratings by the same person.  The IP address may
     * be any combination of public ip address (always known), private ip address (not always known), and/or device id (app dependent).
     *  The specific value stored is less important than the fact that the same value is stored and checked for each use and the
     * understanding that since the feedback is anonymous this will be an imperfect method of avoiding duplicate ratings by the
     * same person.
     * @servertype String
     * @type {string}
     */
    FeedbackIpAddress: string = "";

    /**
     * @name Helped
     * @description 
     * An indicator if the feedback indicated the item helped.  Possible values include: 
     *   = Feedback did not indicate if item was helpful or not.
     * Y = Was helpful.
     * N = Was not helpful.
     * ? = Was not applicable.
     * @servertype String
     * @type {string}
     */
    Helped: string = "?";

    /**
     * @name Rating
     * @description 
     * Rating for this item between 1-5 where 0 is unrated.
     * @servertype Byte (nullable)
     * @type {number}
     */
    Rating: number = 0;

    /**
     * @name Feedback
     * @description 
     * Actual text of the feedback entered.
     * @servertype String
     * @type {string}
     */
    Feedback: string = "";

    /**
     * @name NeedsReview
     * @description 
     * True indicates this feedback was flagged as needing review.  That could be because of manual or automatic tagging.
     * @servertype Boolean
     * @type {boolean}
     */
    NeedsReview: boolean = false;

    /**
     * @name ReviewedByContactId
     * @description 
     * The Contact Id that reviewed this feedback.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReviewedByContactId: number = null;

    /**
     * @name ReviewedByContactName
     * @servertype String
     * @type {string}
     */
    ReviewedByContactName: string = "";

    /**
     * @name ReviewedDateTime
     * @description 
     * The date and time when the feedback was reviewed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReviewedDateTime: Date = null;

    /**
     * @name Tags
     * @description 
     * A comma separated list of tags for this feedback entry.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name HelpedInferred
     * @description 
     * An inferred indicator if the feedback indicated the item helped.  Possible values include: 
     *   = Feedback did not indicate if item was helpful or not.
     * Y = Was helpful.
     * N = Was not helpful.
     * ? = Was not applicable.
     * @servertype String
     * @type {string}
     */
    HelpedInferred: string = "";

    /**
     * @name HelpedInferredProbability
     * @description 
     * The probability associated with the inferred helped value as a decimal (i.e. 0.10 is 10%).
     * @servertype Double (nullable)
     * @type {number}
     */
    HelpedInferredProbability: number = 0;

    /**
     * @name RatingInferred
     * @description 
     * An inferred rating for this item between 1-5 where 0 is unrated.
     * @servertype Byte (nullable)
     * @type {number}
     */
    RatingInferred: number = 0;

    /**
     * @name RatingInferredProbability
     * @description 
     * The probability associated with the inferred rating as a decimal (i.e. 0.10 is 10%).
     * @servertype Double (nullable)
     * @type {number}
     */
    RatingInferredProbability: number = 0;

    /**
     * @name InferredDateTime
     * @description 
     * The date and time the helped and rating values were inferred.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    InferredDateTime: Date = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name FilterConditionGroupViewModel
 */
export class FilterConditionGroupViewModel {

    /**
     * @name ConditionBooleanOperator
     * @servertype String
     * @type {string}
     */
    ConditionBooleanOperator: string = "";

    /**
     * @name GroupBooleanOperator
     * @servertype String
     * @type {string}
     */
    GroupBooleanOperator: string = "";

    /**
     * @name Conditions
     * @servertype FilterConditionViewModel array
     * @type {FilterConditionViewModel[]}
     */
    Conditions: FilterConditionViewModel[] = [];

    /**
     * @name Groups
     * @servertype FilterConditionGroupViewModel array
     * @type {FilterConditionGroupViewModel[]}
     */
    Groups: FilterConditionGroupViewModel[] = [];

}



/**
 * @name FilterConditionViewModel
 */
export class FilterConditionViewModel {

    /**
     * @name PropertyName
     * @servertype String
     * @type {string}
     */
    PropertyName: string = "";

    /**
     * @name DataType
     * @servertype System.TypeCode Enum
     * @type {m.System.TypeCode}
     */
    DataType: m.System.TypeCode = null;

    /**
     * @name ComparisonOperator
     * @servertype String
     * @type {string}
     */
    ComparisonOperator: string = "";

    /**
     * @name Value
     * @servertype System.Object
     * @type {any}
     */
    Value: any = {};

    /**
     * @name ValueDescription
     * @servertype String
     * @type {string}
     */
    ValueDescription: string = "";

    /**
     * @name ValueMetaData
     * @servertype String
     * @type {string}
     */
    ValueMetaData: string = "";

}



/**
 * @name Free-Form Text Parser
 */
export class FreeFormTextParserViewModel {

    /**
     * @name FreeFormTextParserId
     * @servertype Int64
     * @type {number}
     */
    FreeFormTextParserId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Rules
     * @servertype FreeFormTextParserRuleViewModel array
     * @type {FreeFormTextParserRuleViewModel[]}
     */
    Rules: FreeFormTextParserRuleViewModel[] = [];

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

}



/**
 * @name Free-Form Text Parser Rule
 */
export class FreeFormTextParserRuleViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name DataType
     * @servertype String
     * @type {string}
     */
    DataType: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name Name
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name MultipleLinesAllowed
     * @servertype Boolean
     * @type {boolean}
     */
    MultipleLinesAllowed: boolean = false;

    /**
     * @name Mode
     * @servertype String
     * @type {string}
     */
    Mode: string = "";

    /**
     * @name AbsolutePositionStart
     * @servertype Int32
     * @type {number}
     */
    AbsolutePositionStart: number = 0;

    /**
     * @name AbsolutePositionEnd
     * @servertype Int32
     * @type {number}
     */
    AbsolutePositionEnd: number = 0;

    /**
     * @name LineStart
     * @servertype Int32
     * @type {number}
     */
    LineStart: number = 0;

    /**
     * @name LineEnd
     * @servertype Int32
     * @type {number}
     */
    LineEnd: number = 0;

    /**
     * @name LinePositionStart
     * @servertype Int32
     * @type {number}
     */
    LinePositionStart: number = 0;

    /**
     * @name LinePositionEnd
     * @servertype Int32
     * @type {number}
     */
    LinePositionEnd: number = 0;

    /**
     * @name ExtractRegExMatchPattern
     * @servertype String
     * @type {string}
     */
    ExtractRegExMatchPattern: string = "";

    /**
     * @name ExtractRegExFlags
     * @servertype String
     * @type {string}
     */
    ExtractRegExFlags: string = "";

    /**
     * @name ExtractRegExMatchInstance
     * @servertype Int32
     * @type {number}
     */
    ExtractRegExMatchInstance: number = 0;

    /**
     * @name ExtractAfterText
     * @servertype String
     * @type {string}
     */
    ExtractAfterText: string = "";

    /**
     * @name ExtractAfterTextInstance
     * @servertype Int32
     * @type {number}
     */
    ExtractAfterTextInstance: number = 0;

    /**
     * @name ExtractAfterTextUntilText
     * @servertype String
     * @type {string}
     */
    ExtractAfterTextUntilText: string = "";

    /**
     * @name ExtractAfterTextUntilEndOfLine
     * @servertype Boolean
     * @type {boolean}
     */
    ExtractAfterTextUntilEndOfLine: boolean = false;

    /**
     * @name ExtractAfterTextUntilCharacterCount
     * @servertype Int32
     * @type {number}
     */
    ExtractAfterTextUntilCharacterCount: number = 0;

    /**
     * @name ExtractMaximumCharacterCount
     * @servertype Int32
     * @type {number}
     */
    ExtractMaximumCharacterCount: number = 0;

}



/**
 * @name FilterEditViewModel
 * @description
 * The Filter table contains information about filters that can be applied to tables and other data stores.
 */
export class FilterEditViewModel {

    /**
     * @name FilterId
     * @description 
     * Filter Id uniquely identifies this filter.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FilterId: number = null;

    /**
     * @name ExternalFilterId
     * @description 
     * An optional external filter id for this filter.
     * @servertype String
     * @type {string}
     */
    ExternalFilterId: string = "";

    /**
     * @name Description
     * @description 
     * Description for this filter.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name LongDescription
     * @description 
     * A longer description of the filter.
     * @servertype String
     * @type {string}
     */
    LongDescription: string = "";

    /**
     * @name Tags
     * @description 
     * A list of tags for this filter.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name Category
     * @description 
     * A category for this filter.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name ObjectName
     * @description 
     * Name of the table or other object this filter applies to.
     * @servertype String
     * @type {string}
     */
    ObjectName: string = "";

    /**
     * @name DataStoreFilterExpression
     * @description 
     * Filter expression for data store queries.  Used in SQL select statement where clause.  If the filter expression is built
     * graphically it is stored as JSON objects and converted to SQL where clause and this value is left empty. If this sql where
     * clause value is provided to support advanced queries or hand queries then the filter JSON is not supported (i.e. they are
     * mutually exclusive).
     * @servertype String
     * @type {string}
     */
    DataStoreFilterExpression: string = "";

    /**
     * @name FilterConditions
     * @description 
     * A JSON object representing the filter expression.  When utilized it is converted to SQL where clause.  If a hand crafted
     * filter or advanced filter is needed it can be stored in the data store filter expression property in which case this JSON
     * is not utilized (i.e. they are mutually exclusive).  The JSON is formatted as follows:
     * { 
     *   "ConditionBooleanOperator": "And",
     *   "GroupBooleanOperator": "Or" ,
     *   "Conditions": [
     *     {
     *       "PropertyName": "CaseId",
     *       "DataType": "Int64",
     *       "ComparisonOperator": "Equals",
     *       "Value": 12345,
     *       "ValueMetaData": ""
     *     },
     *     {
     *       "PropertyName": "AddedDateTime",
     *       "DataType": "DateTime" ,
     *       "ComparisonOperator": "Between" ,
     *       "Value": null ,
     *       "ValueMetaData": "Last Week"
     *     }
     *   ],
     *   "Groups": []
     * } 
     * @servertype IB.Web.API.Models.v5.FilterConditionGroupViewModel
     * @type {FilterConditionGroupViewModel}
     */
    FilterConditions: FilterConditionGroupViewModel = null;

    /**
     * @name Enabled
     * @description 
     * True indicates the filter is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Shared
     * @description 
     * True indicates the filter is shared with other directory users.  Non-directory contact types cannot share filters.
     * @servertype Boolean
     * @type {boolean}
     */
    Shared: boolean = false;

    /**
     * @name ValidForReport
     * @description 
     * True indicates this filter is valid for reports.
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForReport: boolean = true;

    /**
     * @name ValidForAdHocQuery
     * @description 
     * True indicates this filter is valid for ad hoc queries.
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForAdHocQuery: boolean = true;

    /**
     * @name ValidForDataExport
     * @description 
     * True indicates this filter is valid for data export.
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForDataExport: boolean = true;

    /**
     * @name ValidForVisualComponent
     * @description 
     * True indicates this filter is valid for visual components like widgets, tables, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForVisualComponent: boolean = true;

    /**
     * @name FilterLocked
     * @description 
     * True indicates the filter itself is locked and cannot changed until unlocked first.  This provides a mechanism to prevent
     * casual changes to filters that drive processes like ETL feeds that cannot be changed without great care.
     * @servertype Boolean
     * @type {boolean}
     */
    FilterLocked: boolean = false;

    /**
     * @name ContactId
     * @description 
     * The contact id that represents who owns this filter.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ApprovalPending
     * @description 
     * True indicates this filter is ready for review and approval.
     * @servertype Boolean
     * @type {boolean}
     */
    ApprovalPending: boolean = false;

    /**
     * @name ApprovedDateTime
     * @description 
     * The date and time when the filter was approved.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ApprovedDateTime: Date = null;

    /**
     * @name ApprovedByContactId
     * @description 
     * The contact id that represents who approved this filter.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApprovedByContactId: number = null;

    /**
     * @name ApprovedByContactName
     * @servertype String
     * @type {string}
     */
    ApprovedByContactName: string = "";

    /**
     * @name ApprovedFilterFingerprint
     * @description 
     * A fingerprint of the filter that was approved.  This can be used to validate the filter has not changed since approval.
     * @servertype String
     * @type {string}
     */
    ApprovedFilterFingerprint: string = "";

    /**
     * @name ApprovalComments
     * @description 
     * Comments entered regarding the approval process.  This can be used to explain why something wasn't approved or to add notes
     * about the approval.
     * @servertype String
     * @type {string}
     */
    ApprovalComments: string = "";

    /**
     * @name FilterUseCount
     * @description 
     * The number of times this filter has been used.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FilterUseCount: number = 0;

    /**
     * @name LastUsedDateTime
     * @description 
     * Date and time when the filter was last used.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUsedDateTime: Date = null;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name MergeEditViewModel
 */
export class MergeEditViewModel {

    /**
     * @name ObjectType
     * @servertype String
     * @type {string}
     */
    ObjectType: string = "";

    /**
     * @name ObjectOneId
     * @servertype String
     * @type {string}
     */
    ObjectOneId: string = "";

    /**
     * @name ObjectOnePropertiesToKeep
     * @servertype string array
     * @type {string[]}
     */
    ObjectOnePropertiesToKeep: string[] = [];

    /**
     * @name ObjectOneChildrenToDiscard
     * @servertype MergeChildEditViewModel array
     * @type {MergeChildEditViewModel[]}
     */
    ObjectOneChildrenToDiscard: MergeChildEditViewModel[] = [];

    /**
     * @name ObjectTwoId
     * @servertype String
     * @type {string}
     */
    ObjectTwoId: string = "";

    /**
     * @name ObjectTwoPropertiesToKeep
     * @servertype string array
     * @type {string[]}
     */
    ObjectTwoPropertiesToKeep: string[] = [];

    /**
     * @name ObjectTwoChildrenToDiscard
     * @servertype MergeChildEditViewModel array
     * @type {MergeChildEditViewModel[]}
     */
    ObjectTwoChildrenToDiscard: MergeChildEditViewModel[] = [];

}



/**
 * @name MergeChildEditViewModel
 */
export class MergeChildEditViewModel {

    /**
     * @name ObjectType
     * @servertype String
     * @type {string}
     */
    ObjectType: string = "";

    /**
     * @name ObjectId
     * @servertype String
     * @type {string}
     */
    ObjectId: string = "";

}



/**
 * @name NotDuplicateObjectEditViewModel
 */
export class NotDuplicateObjectEditViewModel {

    /**
     * @name ObjectType
     * @servertype String
     * @type {string}
     */
    ObjectType: string = "";

    /**
     * @name ObjectOneId
     * @servertype String
     * @type {string}
     */
    ObjectOneId: string = "";

    /**
     * @name ObjectTwoId
     * @servertype String
     * @type {string}
     */
    ObjectTwoId: string = "";

}



/**
 * @name Query Execution Request
 */
export class QueryExecutionRequestOptionsViewModel {

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name QueryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    QueryId: number = 0;

    /**
     * @name FilterId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FilterId: number = 0;

    /**
     * @name FilterExpression
     * @servertype String
     * @type {string}
     */
    FilterExpression: string = "";

    /**
     * @name FilterConditions
     * @servertype IB.Web.API.Models.v5.FilterConditionGroupViewModel
     * @type {FilterConditionGroupViewModel}
     */
    FilterConditions: FilterConditionGroupViewModel = null;

    /**
     * @name DataSourceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DataSourceId: number = 0;

    /**
     * @name OutputConfigurationId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OutputConfigurationId: number = 0;

    /**
     * @name ReportTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportTemplateId: number = 0;

    /**
     * @name JobId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    JobId: number = 0;

    /**
     * @name RequestedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RequestedByContactId: number = null;

    /**
     * @name FileTypes
     * @servertype string array
     * @type {string[]}
     */
    FileTypes: string[] = [];

    /**
     * @name DateRange
     * @servertype String
     * @type {string}
     */
    DateRange: string = "";

    /**
     * @name BeginningDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BeginningDateTime: Date = null;

    /**
     * @name EndingDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EndingDateTime: Date = null;

    /**
     * @name AsOfDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AsOfDateTime: Date = null;

    /**
     * @name TimeZoneId
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = "";

    /**
     * @name TimeZoneName
     * @servertype String
     * @type {string}
     */
    TimeZoneName: string = "";

    /**
     * @name UserSystemTimeZoneId
     * @servertype String
     * @type {string}
     */
    UserSystemTimeZoneId: string = "";

    /**
     * @name ServerSystemTimeZoneId
     * @servertype String
     * @type {string}
     */
    ServerSystemTimeZoneId: string = "";

    /**
     * @name HasTimeZoneDifference
     * @servertype Boolean
     * @type {boolean}
     */
    HasTimeZoneDifference: boolean = false;

    /**
     * @name Variables
     * @servertype System.Object
     * @type {any}
     */
    Variables: any = {};

    /**
     * @name DuplicatePreventionType
     * @servertype IB.Web.API.Models.v5.DuplicatePreventionTypeOption Enum
     * @type {DuplicatePreventionTypeOption}
     */
    DuplicatePreventionType: DuplicatePreventionTypeOption = null;

    /**
     * @name DuplicatePreventionThresholdMinutes
     * @servertype Int32
     * @type {number}
     */
    DuplicatePreventionThresholdMinutes: number = 0;

    /**
     * @name Test
     * @servertype Boolean
     * @type {boolean}
     */
    Test: boolean = false;

    /**
     * @name SuppressQueryEventRecord
     * @servertype Boolean
     * @type {boolean}
     */
    SuppressQueryEventRecord: boolean = false;

    /**
     * @name RealTime
     * @servertype Boolean
     * @type {boolean}
     */
    RealTime: boolean = false;

    /**
     * @name Trace
     * @servertype Boolean
     * @type {boolean}
     */
    Trace: boolean = false;

    /**
     * @name QueryEventExpirationMinutes
     * @servertype Int32 (nullable)
     * @type {number}
     */
    QueryEventExpirationMinutes: number = 0;

    /**
     * @name IsDateRangeBasedDataFeed
     * @servertype Boolean
     * @type {boolean}
     */
    IsDateRangeBasedDataFeed: boolean = false;

    /**
     * @name IncludeCoverPage
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeCoverPage: boolean = false;

    /**
     * @name IncludeRowShading
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeRowShading: boolean = false;

    /**
     * @name IncludeDailySubtotal
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeDailySubtotal: boolean = false;

    /**
     * @name IncludeCharts
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeCharts: boolean = false;

    /**
     * @name ReinitializeSummaryData
     * @servertype Boolean
     * @type {boolean}
     */
    ReinitializeSummaryData: boolean = false;

    /**
     * @name NotificationContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationContactId: number = null;

    /**
     * @name NotificationContactType
     * @servertype String
     * @type {string}
     */
    NotificationContactType: string = "";

    /**
     * @name UploadTargets
     * @servertype QueryExecutionRequestUploadTargetViewModel array
     * @type {QueryExecutionRequestUploadTargetViewModel[]}
     */
    UploadTargets: QueryExecutionRequestUploadTargetViewModel[] = [];

    /**
     * @name EmailRecipients
     * @servertype QueryExecutionRequestEmailRecipientViewModel array
     * @type {QueryExecutionRequestEmailRecipientViewModel[]}
     */
    EmailRecipients: QueryExecutionRequestEmailRecipientViewModel[] = [];

    /**
     * @name EmailMessageAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    EmailMessageAssetId: number = 0;

    /**
     * @name EmailSubject
     * @servertype String
     * @type {string}
     */
    EmailSubject: string = "";

    /**
     * @name EmailBody
     * @servertype String
     * @type {string}
     */
    EmailBody: string = "";

    /**
     * @name UtcOffset
     * @servertype Double (nullable)
     * @type {number}
     */
    UtcOffset: number = 0;

}



/**
 * @name DuplicatePreventionTypeOption Enum
 * @readonly
 * @enum {number}
 */
export enum DuplicatePreventionTypeOption {

    /**
     * None
     */
    None = 0,

    /**
     * Same Parameters
     */
    SameParameters = 1,

    /**
     * Same Parameters Ignore As Of Date Time
     */
    SameParametersIgnoreAsOfDateTime = 2

}



/**
 * @name QueryExecutionRequestUploadTargetViewModel
 */
export class QueryExecutionRequestUploadTargetViewModel {

    /**
     * @name FileServerId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FileServerId: number = 0;

    /**
     * @name FileServerFolder
     * @servertype String
     * @type {string}
     */
    FileServerFolder: string = "";

}



/**
 * @name QueryExecutionRequestEmailRecipientViewModel
 */
export class QueryExecutionRequestEmailRecipientViewModel {

    /**
     * @name Name
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Email
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name Type
     * @servertype IB.Web.API.Models.v5.QueryExecutionRequestEmailRecipientType Enum
     * @type {QueryExecutionRequestEmailRecipientType}
     */
    Type: QueryExecutionRequestEmailRecipientType = null;

}



/**
 * @name QueryExecutionRequestEmailRecipientType Enum
 * @readonly
 * @enum {number}
 */
export enum QueryExecutionRequestEmailRecipientType {

    /**
     * To
     */
    To = 0,

    /**
     * Cc
     */
    Cc = 1,

    /**
     * Bcc
     */
    Bcc = 2

}



/**
 * @name Report Template
 */
export class ReportTemplateEditViewModel {

    /**
     * @name ReportTemplateId
     * @servertype Int64
     * @type {number}
     */
    ReportTemplateId: number = 0;

    /**
     * @name SystemReportTemplateId
     * @servertype String
     * @type {string}
     */
    SystemReportTemplateId: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Role
     * @servertype IB.Web.API.Models.v5.ReportTemplateRole Enum
     * @type {ReportTemplateRole}
     */
    Role: ReportTemplateRole = null;

    /**
     * @name Type
     * @servertype IB.Web.API.Models.v5.ReportTemplateType Enum
     * @type {ReportTemplateType}
     */
    Type: ReportTemplateType = null;

    /**
     * @name FileName
     * @servertype String
     * @type {string}
     */
    FileName: string = "";

    /**
     * @name Template
     * @servertype String
     * @type {string}
     */
    Template: string = "";

    /**
     * @name Default
     * @servertype Boolean
     * @type {boolean}
     */
    Default: boolean = false;

    /**
     * @name Language
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name Variables
     * @servertype ReportTemplateVariable array
     * @type {ReportTemplateVariable[]}
     */
    Variables: ReportTemplateVariable[] = [];

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name Report Template Role Enum
 * @readonly
 * @enum {number}
 */
export enum ReportTemplateRole {

    /**
     * Standard report formatted for reading.
     */
    Report = 0,

    /**
     * Chart based report.
     */
    Chart = 1,

    /**
     * Data only report without formatting.
     */
    DataOnly = 2

}



/**
 * @name Report Template Type Enum
 * @readonly
 * @enum {number}
 */
export enum ReportTemplateType {

    /**
     * MRT
     */
    MRT = 0,

    /**
     * XLSX
     */
    XLSX = 100,

    /**
     * DOCX
     */
    DOCX = 200

}



/**
 * @name Report Template Variable
 */
export class ReportTemplateVariable {

    /**
     * @name Name
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Value
     * @servertype String
     * @type {string}
     */
    Value: string = "";

}



/**
 * @name Query Variables
 */
export class QueryVariableEditViewModel {

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name InputFormId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InputFormId: number = 0;

    /**
     * @name SystemInputFormId
     * @servertype String
     * @type {string}
     */
    SystemInputFormId: string = "";

    /**
     * @name InputFormSplit
     * @servertype Boolean
     * @type {boolean}
     */
    InputFormSplit: boolean = false;

    /**
     * @name InputFormStacked
     * @servertype Boolean
     * @type {boolean}
     */
    InputFormStacked: boolean = false;

    /**
     * @name InputFormSize
     * @servertype String
     * @type {string}
     */
    InputFormSize: string = "";

    /**
     * @name Collapsed
     * @servertype Boolean
     * @type {boolean}
     */
    Collapsed: boolean = false;

    /**
     * @name Hidden
     * @servertype Boolean
     * @type {boolean}
     */
    Hidden: boolean = false;

    /**
     * @name DateType
     * @servertype IB.Web.API.Models.v5.QueryDateType Enum
     * @type {QueryDateType}
     */
    DateType: QueryDateType = null;

    /**
     * @name Variables
     * @servertype PropertyMetaDataViewModel array
     * @type {m.PropertyMetaDataViewModel[]}
     */
    Variables: m.PropertyMetaDataViewModel[] = [];

}



/**
 * @name QueryDateType Enum
 * @readonly
 * @enum {number}
 */
export enum QueryDateType {

    /**
     * None
     */
    None = 0,

    /**
     * As Of Date
     */
    AsOfDate = 10,

    /**
     * As Of Date Time
     */
    AsOfDateTime = 11,

    /**
     * Date Range
     */
    DateRange = 20,

    /**
     * Date Time Range
     */
    DateTimeRange = 21

}



/**
 * @name Query Output
 */
export class QueryOutputEditViewModel {

    /**
     * @name Configurations
     * @servertype QueryOutputConfigurationEditViewModel array
     * @type {QueryOutputConfigurationEditViewModel[]}
     */
    Configurations: QueryOutputConfigurationEditViewModel[] = [];

    /**
     * @name Templates
     * @servertype ReportTemplateEditViewModel array
     * @type {ReportTemplateEditViewModel[]}
     */
    Templates: ReportTemplateEditViewModel[] = [];

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name Query Output Configuration
 */
export class QueryOutputConfigurationEditViewModel {

    /**
     * @name QueryOutputConfigurationId
     * @servertype Int64
     * @type {number}
     */
    QueryOutputConfigurationId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Default
     * @servertype Boolean
     * @type {boolean}
     */
    Default: boolean = false;

    /**
     * @name QueryEventExpirationMinutes
     * @servertype Int32 (nullable)
     * @type {number}
     */
    QueryEventExpirationMinutes: number = 0;

    /**
     * @name IncludeCoverPage
     * @servertype IB.Web.API.Models.v5.QueryOutputSetting Enum
     * @type {QueryOutputSetting}
     */
    IncludeCoverPage: QueryOutputSetting = null;

    /**
     * @name IncludeRowShading
     * @servertype IB.Web.API.Models.v5.QueryOutputSetting Enum
     * @type {QueryOutputSetting}
     */
    IncludeRowShading: QueryOutputSetting = null;

    /**
     * @name IncludeDailySubtotal
     * @servertype IB.Web.API.Models.v5.QueryOutputSetting Enum
     * @type {QueryOutputSetting}
     */
    IncludeDailySubtotal: QueryOutputSetting = null;

    /**
     * @name IncludeCharts
     * @servertype IB.Web.API.Models.v5.QueryOutputSetting Enum
     * @type {QueryOutputSetting}
     */
    IncludeCharts: QueryOutputSetting = null;

    /**
     * @name ReinitializeSummaryData
     * @servertype IB.Web.API.Models.v5.QueryOutputSetting Enum
     * @type {QueryOutputSetting}
     */
    ReinitializeSummaryData: QueryOutputSetting = null;

    /**
     * @name SavePageViewImages
     * @servertype Boolean
     * @type {boolean}
     */
    SavePageViewImages: boolean = false;

    /**
     * @name SaveRawData
     * @servertype Boolean
     * @type {boolean}
     */
    SaveRawData: boolean = false;

    /**
     * @name PromptForReportTemplate
     * @servertype Boolean
     * @type {boolean}
     */
    PromptForReportTemplate: boolean = false;

    /**
     * @name ReportTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportTemplateId: number = 0;

    /**
     * @name SystemReportTemplateId
     * @servertype String
     * @type {string}
     */
    SystemReportTemplateId: string = "";

    /**
     * @name PromptForFileFormat
     * @servertype Boolean
     * @type {boolean}
     */
    PromptForFileFormat: boolean = false;

    /**
     * @name SupportedFileFormats
     * @servertype string array
     * @type {string[]}
     */
    SupportedFileFormats: string[] = [];

    /**
     * @name AlwaysGenerateFileFormats
     * @servertype string array
     * @type {string[]}
     */
    AlwaysGenerateFileFormats: string[] = [];

    /**
     * @name PropertyDelimiter
     * @servertype String
     * @type {string}
     */
    PropertyDelimiter: string = "";

    /**
     * @name StringQuoteDelimiter
     * @servertype String
     * @type {string}
     */
    StringQuoteDelimiter: string = "";

    /**
     * @name RowDelimiter
     * @servertype String
     * @type {string}
     */
    RowDelimiter: string = "";

    /**
     * @name RowTemplate
     * @servertype String
     * @type {string}
     */
    RowTemplate: string = "";

    /**
     * @name DateTimeFormat
     * @servertype String
     * @type {string}
     */
    DateTimeFormat: string = "";

    /**
     * @name PropertyNames
     * @servertype string array
     * @type {string[]}
     */
    PropertyNames: string[] = [];

    /**
     * @name Headers
     * @servertype string array
     * @type {string[]}
     */
    Headers: string[] = [];

    /**
     * @name FormatPropertyNameInHeader
     * @servertype Boolean
     * @type {boolean}
     */
    FormatPropertyNameInHeader: boolean = false;

    /**
     * @name PropertyFormatting
     * @servertype QueryOutputPropertyFormatEditViewModel array
     * @type {QueryOutputPropertyFormatEditViewModel[]}
     */
    PropertyFormatting: QueryOutputPropertyFormatEditViewModel[] = [];

    /**
     * @name ObjectName
     * @servertype String
     * @type {string}
     */
    ObjectName: string = "";

    /**
     * @name ObjectKeyLabel
     * @servertype String
     * @type {string}
     */
    ObjectKeyLabel: string = "";

    /**
     * @name ObjectKeyPropertyName
     * @servertype String
     * @type {string}
     */
    ObjectKeyPropertyName: string = "";

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Subtitle
     * @servertype String
     * @type {string}
     */
    Subtitle: string = "";

    /**
     * @name Details
     * @servertype String
     * @type {string}
     */
    Details: string = "";

    /**
     * @name IncludeAsOfTime
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeAsOfTime: boolean = false;

    /**
     * @name ExportFileName
     * @servertype String
     * @type {string}
     */
    ExportFileName: string = "";

    /**
     * @name ControlFileName
     * @servertype String
     * @type {string}
     */
    ControlFileName: string = "";

    /**
     * @name ControlFileFormat
     * @servertype String
     * @type {string}
     */
    ControlFileFormat: string = "";

    /**
     * @name ControlFileContents
     * @servertype String
     * @type {string}
     */
    ControlFileContents: string = "";

    /**
     * @name Compression
     * @servertype IB.Web.API.Models.v5.QueryOutputCompressionType Enum
     * @type {QueryOutputCompressionType}
     */
    Compression: QueryOutputCompressionType = null;

    /**
     * @name CompressionPassword
     * @servertype String
     * @type {string}
     */
    CompressionPassword: string = "";

    /**
     * @name EncryptionPublicKey
     * @servertype String
     * @type {string}
     */
    EncryptionPublicKey: string = "";

    /**
     * @name EncryptionKeyServerId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    EncryptionKeyServerId: number = 0;

    /**
     * @name EncryptionKeyTag
     * @servertype String
     * @type {string}
     */
    EncryptionKeyTag: string = "";

    /**
     * @name Trace
     * @servertype Boolean
     * @type {boolean}
     */
    Trace: boolean = false;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name Query Output Setting Enum
 * @readonly
 * @enum {number}
 */
export enum QueryOutputSetting {

    /**
     * Prompt
     */
    Prompt = 0,

    /**
     * Prompt Default On
     */
    PromptDefaultOn = 1,

    /**
     * Prompt Default Off
     */
    PromptDefaultOff = 2,

    /**
     * Always
     */
    Always = 10,

    /**
     * Never
     */
    Never = 20,

    /**
     * Smart
     */
    Smart = 90

}



/**
 * @name Query Output Property Format
 */
export class QueryOutputPropertyFormatEditViewModel {

    /**
     * @name PropertyName
     * @servertype String
     * @type {string}
     */
    PropertyName: string = "";

    /**
     * @name FormatSelectionExpression
     * @servertype String
     * @type {string}
     */
    FormatSelectionExpression: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DataType
     * @servertype System.TypeCode Enum
     * @type {m.System.TypeCode}
     */
    DataType: m.System.TypeCode = null;

    /**
     * @name IsHiddenAlways
     * @servertype Boolean
     * @type {boolean}
     */
    IsHiddenAlways: boolean = false;

    /**
     * @name IsHiddenIfRepeatedAbove
     * @servertype Boolean
     * @type {boolean}
     */
    IsHiddenIfRepeatedAbove: boolean = false;

    /**
     * @name IsHiddenIfRepeatedAboveWhenTrueForAllRepeatedProperties
     * @servertype Boolean
     * @type {boolean}
     */
    IsHiddenIfRepeatedAboveWhenTrueForAllRepeatedProperties: boolean = false;

    /**
     * @name Template
     * @servertype String
     * @type {string}
     */
    Template: string = "";

    /**
     * @name Filter
     * @servertype String
     * @type {string}
     */
    Filter: string = "";

    /**
     * @name IsGroupBy
     * @servertype Boolean
     * @type {boolean}
     */
    IsGroupBy: boolean = false;

    /**
     * @name IsGroupByCollapsed
     * @servertype Boolean
     * @type {boolean}
     */
    IsGroupByCollapsed: boolean = false;

    /**
     * @name StandardFormat
     * @servertype String
     * @type {string}
     */
    StandardFormat: string = "";

    /**
     * @name ExcelFormat
     * @servertype String
     * @type {string}
     */
    ExcelFormat: string = "";

    /**
     * @name FontSize
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FontSize: number = 0;

    /**
     * @name FontBold
     * @servertype Boolean
     * @type {boolean}
     */
    FontBold: boolean = false;

    /**
     * @name FontItalic
     * @servertype Boolean
     * @type {boolean}
     */
    FontItalic: boolean = false;

    /**
     * @name FontUnderline
     * @servertype Boolean
     * @type {boolean}
     */
    FontUnderline: boolean = false;

    /**
     * @name Color
     * @servertype String
     * @type {string}
     */
    Color: string = "";

    /**
     * @name BackgroundColor
     * @servertype String
     * @type {string}
     */
    BackgroundColor: string = "";

    /**
     * @name Border
     * @servertype String
     * @type {string}
     */
    Border: string = "";

    /**
     * @name StyleAppliesTo
     * @servertype String
     * @type {string}
     */
    StyleAppliesTo: string = "";

    /**
     * @name FooterType
     * @servertype String
     * @type {string}
     */
    FooterType: string = "";

}



/**
 * @name Query Output Compression Type Enum
 * @readonly
 * @enum {number}
 */
export enum QueryOutputCompressionType {

    /**
     * None
     */
    None = 0,

    /**
     * Zip
     */
    Zip = 1

}



/**
 * @name Query List
 * @description
 * The query table contains information about data queries.
 */
export class QueryListViewModel {

    /**
     * @name QueryId
     * @description 
     * The id of the query.
     * @servertype Int64
     * @type {number}
     */
    QueryId: number = null;

    /**
     * @name ExternalQueryId
     * @description 
     * An optional external query id for this query.
     * @servertype String
     * @type {string}
     */
    ExternalQueryId: string = "";

    /**
     * @name Category
     * @description 
     * A category for this query.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name Description
     * @description 
     * A description for this query.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name LongDescription
     * @description 
     * A longer description of the query.
     * @servertype String
     * @type {string}
     */
    LongDescription: string = "";

    /**
     * @name ContactId
     * @description 
     * The contact id that represents who created this query.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

}



/**
 * @name QueryEditViewModel
 */
export class QueryEditViewModel {

    /**
     * @name QueryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    QueryId: number = 0;

    /**
     * @name ParentQueryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentQueryId: number = 0;

    /**
     * @name ExternalQueryId
     * @servertype String
     * @type {string}
     */
    ExternalQueryId: string = "";

    /**
     * @name SystemQueryId
     * @servertype String
     * @type {string}
     */
    SystemQueryId: string = "";

    /**
     * @name ParentSystemQueryId
     * @servertype String
     * @type {string}
     */
    ParentSystemQueryId: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name LongDescription
     * @servertype String
     * @type {string}
     */
    LongDescription: string = "";

    /**
     * @name Tags
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name DataSourceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DataSourceId: number = 0;

    /**
     * @name DataSources
     * @servertype DataSourceEditViewModel array
     * @type {DataSourceEditViewModel[]}
     */
    DataSources: DataSourceEditViewModel[] = [];

    /**
     * @name Entities
     * @servertype String
     * @type {string}
     */
    Entities: string = "";

    /**
     * @name Columns
     * @servertype String
     * @type {string}
     */
    Columns: string = "";

    /**
     * @name Conditions
     * @servertype String
     * @type {string}
     */
    Conditions: string = "";

    /**
     * @name Sorting
     * @servertype String
     * @type {string}
     */
    Sorting: string = "";

    /**
     * @name Variables
     * @servertype IB.Web.API.Models.v5.QueryVariableEditViewModel
     * @type {QueryVariableEditViewModel}
     */
    Variables: QueryVariableEditViewModel = null;

    /**
     * @name Output
     * @servertype IB.Web.API.Models.v5.QueryOutputEditViewModel
     * @type {QueryOutputEditViewModel}
     */
    Output: QueryOutputEditViewModel = null;

    /**
     * @name CompiledQuery
     * @servertype String
     * @type {string}
     */
    CompiledQuery: string = "";

    /**
     * @name AdvancedQuery
     * @servertype String
     * @type {string}
     */
    AdvancedQuery: string = "";

    /**
     * @name SensitiveInformationCategories
     * @servertype string array
     * @type {string[]}
     */
    SensitiveInformationCategories: string[] = [];

    /**
     * @name FilterObjectName
     * @servertype String
     * @type {string}
     */
    FilterObjectName: string = "";

    /**
     * @name QueryLocked
     * @servertype Boolean
     * @type {boolean}
     */
    QueryLocked: boolean = false;

    /**
     * @name Visibility
     * @servertype String
     * @type {string}
     */
    Visibility: string = "";

    /**
     * @name ValidForReport
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForReport: boolean = false;

    /**
     * @name ValidForAdHocQuery
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForAdHocQuery: boolean = false;

    /**
     * @name ValidForDataExport
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForDataExport: boolean = false;

    /**
     * @name ValidForVisualComponent
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForVisualComponent: boolean = false;

    /**
     * @name ValidForDrillDownTarget
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForDrillDownTarget: boolean = false;

    /**
     * @name Restrictions
     * @servertype IB.Web.API.Models.v5.RestrictionsEditViewModel
     * @type {m5sec.RestrictionsEditViewModel}
     */
    Restrictions: m5sec.RestrictionsEditViewModel = null;

    /**
     * @name Category
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name ReadOnlyShareId
     * @servertype String
     * @type {string}
     */
    ReadOnlyShareId: string = "";

    /**
     * @name HitCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HitCount: number = 0;

    /**
     * @name LastHitDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastHitDateTime: Date = null;

    /**
     * @name LastRowCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LastRowCount: number = 0;

    /**
     * @name LastRunSeconds
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LastRunSeconds: number = 0;

    /**
     * @name LastResultMessage
     * @servertype String
     * @type {string}
     */
    LastResultMessage: string = "";

    /**
     * @name LastExecutionPlan
     * @servertype String
     * @type {string}
     */
    LastExecutionPlan: string = "";

    /**
     * @name ApprovalPending
     * @servertype Boolean
     * @type {boolean}
     */
    ApprovalPending: boolean = false;

    /**
     * @name ApprovedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ApprovedDateTime: Date = null;

    /**
     * @name ApprovedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApprovedByContactId: number = null;

    /**
     * @name ApprovedByContactName
     * @servertype String
     * @type {string}
     */
    ApprovedByContactName: string = "";

    /**
     * @name ApprovedQueryFingerprint
     * @servertype String
     * @type {string}
     */
    ApprovedQueryFingerprint: string = "";

    /**
     * @name ApprovalComments
     * @servertype String
     * @type {string}
     */
    ApprovalComments: string = "";

    /**
     * @name TimeoutSeconds
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TimeoutSeconds: number = 0;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Data Source
 */
export class DataSourceEditViewModel {

    /**
     * @name DataSourceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DataSourceId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DataFolder
     * @servertype String
     * @type {string}
     */
    DataFolder: string = "";

    /**
     * @name FailoverDataFolder
     * @servertype String
     * @type {string}
     */
    FailoverDataFolder: string = "";

    /**
     * @name ConnectionString
     * @servertype String
     * @type {string}
     */
    ConnectionString: string = "";

    /**
     * @name Users
     * @servertype DataSourceUserEditViewModel array
     * @type {DataSourceUserEditViewModel[]}
     */
    Users: DataSourceUserEditViewModel[] = [];

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name Type
     * @servertype IB.Web.API.Models.v5.DataSourceType Enum
     * @type {DataSourceType}
     */
    Type: DataSourceType = null;

}



/**
 * @name DataSourceUserEditViewModel
 */
export class DataSourceUserEditViewModel {

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Login
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Password
     * @servertype String
     * @type {string}
     */
    Password: string = "";

    /**
     * @name Token
     * @servertype String
     * @type {string}
     */
    Token: string = "";

    /**
     * @name ApiKey
     * @servertype String
     * @type {string}
     */
    ApiKey: string = "";

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

}



/**
 * @name DataSourceType Enum
 * @readonly
 * @enum {number}
 */
export enum DataSourceType {

    /**
     * Data Folder
     */
    DataFolder = 0,

    /**
     * Connection String
     */
    ConnectionString = 1

}



/**
 * @name Query Execution Request
 */
export class QueryExecutionRequestEditViewModel {

    /**
     * @name QueryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    QueryId: number = 0;

    /**
     * @name DataSourceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DataSourceId: number = 0;

    /**
     * @name Variables
     * @servertype Dictionary<string, object>
     * @type {{[key: string]:  any}}
     */
    Variables: {[key: string]:  any} = {};

    /**
     * @name Test
     * @servertype Boolean
     * @type {boolean}
     */
    Test: boolean = false;

    /**
     * @name ReturnResults
     * @servertype Boolean
     * @type {boolean}
     */
    ReturnResults: boolean = false;

    /**
     * @name ExportFormats
     * @servertype string array
     * @type {string[]}
     */
    ExportFormats: string[] = [];

    /**
     * @name ExportPropertyNames
     * @servertype string array
     * @type {string[]}
     */
    ExportPropertyNames: string[] = [];

    /**
     * @name ExportHeaders
     * @servertype string array
     * @type {string[]}
     */
    ExportHeaders: string[] = [];

    /**
     * @name ExportFileName
     * @servertype String
     * @type {string}
     */
    ExportFileName: string = "";

    /**
     * @name ExportTitle
     * @servertype String
     * @type {string}
     */
    ExportTitle: string = "";

    /**
     * @name ExportSubtitle
     * @servertype String
     * @type {string}
     */
    ExportSubtitle: string = "";

    /**
     * @name IncludeAsOfTime
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeAsOfTime: boolean = false;

}



/**
 * @name Query Execution Status
 */
export class QueryExecutionStatusViewModel {

    /**
     * @name Request
     * @servertype IB.Web.API.Models.v5.QueryExecutionRequestEditViewModel
     * @type {QueryExecutionRequestEditViewModel}
     */
    Request: QueryExecutionRequestEditViewModel = null;

    /**
     * @name ExportFilePickUpId
     * @servertype String
     * @type {string}
     */
    ExportFilePickUpId: string = "";

    /**
     * @name ExportDownloadFileName
     * @servertype String
     * @type {string}
     */
    ExportDownloadFileName: string = "";

    /**
     * @name Status
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name Messages
     * @servertype string array
     * @type {string[]}
     */
    Messages: string[] = [];

    /**
     * @name RequestReceivedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RequestReceivedDateTime: Date = null;

    /**
     * @name StartedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartedDateTime: Date = null;

    /**
     * @name FinishedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FinishedDateTime: Date = null;

    /**
     * @name AvailableUntilDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AvailableUntilDateTime: Date = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name TimeZoneDescription
     * @servertype String
     * @type {string}
     */
    TimeZoneDescription: string = "";

}



/**
 * @name InventoryEventEditViewModel
 * @description
 * The inventory event table contains a record of events that move quantities in and out of inventory for inventory items that
 * track quantities.
 */
export class InventoryEventEditViewModel {

    /**
     * @name InventoryEventId
     * @description 
     * Inventory Event Id uniquely identifies this inventory event.
     * @servertype String
     * @type {string}
     */
    InventoryEventId: string = null;

    /**
     * @name ExternalInventoryEventId
     * @description 
     * An external inventory event id.  If populated this is expected to be unique and can be used to prevent duplicate inventory
     * events from being posted for the same calendar month.
     * @servertype String
     * @type {string}
     */
    ExternalInventoryEventId: string = "";

    /**
     * @name InventoryEventType
     * @description 
     * The type for this inventory event.  Possible values include: 
     * T = Transfer Between Locations
     * P = Customer Purchase
     * R = Customer Return
     * V = Vendor Purchase
     * N = Vendor Return
     * A = Adjustment
     * O = Other
     * @servertype String
     * @type {string}
     */
    InventoryEventType: string = "O";

    /**
     * @name EventDateTime
     * @description 
     * The date and time of the inventory event.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EventDateTime: Date = new Date();

    /**
     * @name Description
     * @description 
     * An optional description for this inventory event.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name InventoryEventBatchId
     * @description 
     * An optional batch id for inventory events that are part of the same batch process.  For example, transfers may have one event
     * where inventory quantity is reduced in one location and another event where inventory quantity is increased in another location
     * that are both part of the same batch.
     * @servertype String
     * @type {string}
     */
    InventoryEventBatchId: string = "";

    /**
     * @name TrackingNumber
     * @description 
     * The tracking number for the inventory this event belongs to.  This may be determined from the inventory id if not provided.
     * @servertype String
     * @type {string}
     */
    TrackingNumber: string = "";

    /**
     * @name InventoryId
     * @description 
     * The id of the inventory this event belongs to.  This may be determined from the tracking number if not provided.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryId: number = null;

    /**
     * @name InventoryLocationId
     * @description 
     * The id of the inventory location this event belongs to.  This is optional but when provided will be used to track inventory
     * at the specified location.  This may be determined from the location contact id if not provided.  Note that this can be null
     * since not all inventory quantities are tracked by location.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryLocationId: number = null;

    /**
     * @name LocationContactId
     * @description 
     * The id of the location contact this event belongs to.  This is optional but when provided will be used to track inventory
     * at the specified location.  This may be determined from the inventory location id if not provided.  Note that this can be
     * null since not all inventory quantities are tracked by location.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LocationContactId: number = null;

    /**
     * @name LocationContactName
     * @servertype String
     * @type {string}
     */
    LocationContactName: string = "";

    /**
     * @name Quantity
     * @description 
     * If the quantity is positive the inventory quantity is going up.  This is typical for inventory purchased from a vendor, transferred
     * from another location, or customer return.  If the quantity is negative the inventory quantity is going down which is typical
     * for returns to a vendor, transfers to another location, or customer purchase.
     * @servertype Double (nullable)
     * @type {number}
     */
    Quantity: number = 0;

    /**
     * @name QuantityType
     * @description 
     * An optional quantity type for the quantity.  If not provided the quantity type attached to the inventory is assumed.  In
     * some scenarios a quantity type that differs from the inventory quantity type results in a quantity conversion when the event
     * is posted to inventory.
     * @servertype String
     * @type {string}
     */
    QuantityType: string = "";

    /**
     * @name PostedQuantity
     * @description 
     * The quantity of inventory posted from the event to the inventory record.  This may differ from the quantity expressed in
     * the event if the quantity type differs from that tracked with inventory and if a quantity conversion was available.
     * @servertype Double (nullable)
     * @type {number}
     */
    PostedQuantity: number = 0;

    /**
     * @name PostedQuantityType
     * @description 
     * The quantity type used when the quantity was posted to inventory.
     * @servertype String
     * @type {string}
     */
    PostedQuantityType: string = "";

    /**
     * @name PurchaseReferenceNumber
     * @description 
     * The reference number for this purchase (i.e. PO#, invoice #, etc.) if the event represents a purchase.
     * @servertype String
     * @type {string}
     */
    PurchaseReferenceNumber: string = "";

    /**
     * @name PurchasePrice
     * @description 
     * The purchased price if the event represents a purchase.
     * @servertype Double (nullable)
     * @type {number}
     */
    PurchasePrice: number = 0;

    /**
     * @name PurchaseIsoCurrencyCode
     * @description 
     * Purchase ISO Currency Code if the event represents a purchase.
     * @servertype String
     * @type {string}
     */
    PurchaseIsoCurrencyCode: string = "";

    /**
     * @name ContactId
     * @description 
     * The Contact Id for the contact the inventory event transferred inventory to or from.  The inventory location contact id represents
     * the location of the inventory so this contact id is used to represent the customer or vendor involved in the inventory event
     * (if any).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name BillingAccountId
     * @description 
     * An optional Billing Account Id for the inventory event.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = null;

    /**
     * @name PackageOccurrenceId
     * @description 
     * An optional Package Occurrence Id for the inventory event.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = null;

    /**
     * @name CaseId
     * @description 
     * An optional Case Id which identifies the case triggering this inventory event.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseId: number = null;

    /**
     * @name ProcessId
     * @description 
     * An optional Process Id which identifies the process triggering this inventory event.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessId: number = null;

    /**
     * @name ProcessDateTime
     * @description 
     * If a Process Id is provided this is the date and time when the process was created.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessDateTime: Date = null;

    /**
     * @name BillingTransactionId
     * @description 
     * An optional Billing Transaction Id this inventory event is invoiced on, excluded with, etc.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingTransactionId: number = null;

    /**
     * @name BillingTransactionDetailId
     * @description 
     * An optional Billing Transaction Detail Id this inventory event is invoiced on, excluded with, etc.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingTransactionDetailId: number = null;

    /**
     * @name RegardingResourceType
     * @description 
     * Other resource type (e.g. table, etc.) this inventory event is regarding (if any).
     * @servertype String
     * @type {string}
     */
    RegardingResourceType: string = "";

    /**
     * @name RegardingResourceId
     * @description 
     * The resource id is the key of the other resource type this inventory event is regarding (if any).  For example, if the regarding
     * resource type is InventoryType this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RegardingResourceId: number = 0;

    /**
     * @name RegardingResourceId2
     * @description 
     * The resource id 2 is the key of the other resource type this inventory event is regarding (if any) when that id is a string
     * data type.
     * @servertype String
     * @type {string}
     */
    RegardingResourceId2: string = "";

    /**
     * @name Status
     * @description 
     * Status of this inventory event.  Possible values are: 
     * P = Pending (event not started yet or is pending retry)
     * R = Running (event is running)
     * S = Success (event was successful)
     * F = Failure (event failed)
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name ResultCode
     * @description 
     * The result code for this event.  Possible values include standard result code plus these options:
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @description 
     * Result message for this event.  More details can be found the logs if needed.
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Inventory Expiring
 */
export class InventoryExpiringViewModel {

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name Phone
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name Email
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name InventoryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryId: number = 0;

    /**
     * @name TrackingNumber
     * @servertype String
     * @type {string}
     */
    TrackingNumber: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name InventoryTypeId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryTypeId: number = 0;

    /**
     * @name InventoryType
     * @servertype String
     * @type {string}
     */
    InventoryType: string = "";

    /**
     * @name ExpirationDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpirationDate: Date = null;

}



/**
 * @name InventoryHistoryEditViewModel
 * @description
 * The InventoryHistory table contains a record of who had possession of this inventory item at any given time.  Generally this
 * is only used for durable (i.e. non-quantity based) inventory items.
 */
export class InventoryHistoryEditViewModel {

    /**
     * @name InventoryHistoryId
     * @description 
     * The id of the inventory.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryHistoryId: number = null;

    /**
     * @name InventoryId
     * @description 
     * The id of the inventory this history belongs to.
     * @servertype Int64
     * @type {number}
     */
    InventoryId: number = null;

    /**
     * @name ParentInventoryId
     * @description 
     * The id of the inventory this inventory is a child record of.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentInventoryId: number = null;

    /**
     * @name PreviousParentInventoryId
     * @description 
     * The id of the inventory this inventory was previous a child record of.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PreviousParentInventoryId: number = null;

    /**
     * @name NextParentInventoryId
     * @description 
     * The id of the inventory this inventory is next a child record of.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NextParentInventoryId: number = null;

    /**
     * @name InventoryTypeVersionId
     * @description 
     * The id of the inventory type version for this inventory item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryTypeVersionId: number = null;

    /**
     * @name PreviousInventoryTypeVersionId
     * @description 
     * The id of the inventory type version this inventory was previously associated with.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PreviousInventoryTypeVersionId: number = null;

    /**
     * @name NextInventoryTypeVersionId
     * @description 
     * The id of the inventory type version this inventory is next associated with.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NextInventoryTypeVersionId: number = null;

    /**
     * @name ContactId
     * @description 
     * The Contact Id that owns this inventory.  This may differ from the billing entity found in PackageOccurrenceId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name PreviousContactId
     * @description 
     * The Contact Id that previously owned this inventory.  This may differ from the billing entity found in PackageOccurrenceId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PreviousContactId: number = null;

    /**
     * @name PreviousContactName
     * @servertype String
     * @type {string}
     */
    PreviousContactName: string = "";

    /**
     * @name NextContactId
     * @description 
     * The Contact Id that next owned this inventory.  This may differ from the billing entity found in PackageOccurrenceId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NextContactId: number = null;

    /**
     * @name NextContactName
     * @servertype String
     * @type {string}
     */
    NextContactName: string = "";

    /**
     * @name PackageOccurrenceId
     * @description 
     * The Package Occurrence Id that is held by the billing entity for this inventory.  This may be the same or differ from the
     * owner of the inventory found in the Contact Id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = null;

    /**
     * @name PreviousPackageOccurrenceId
     * @description 
     * The Package Occurrence Id that is held by the previous billing entity for this inventory.  This may be the same or differ
     * from the owner of the inventory found in the Contact Id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PreviousPackageOccurrenceId: number = null;

    /**
     * @name NextPackageOccurrenceId
     * @description 
     * The Package Occurrence Id that is held by the next billing entity for this inventory.  This may be the same or differ from
     * the owner of the inventory found in the Contact Id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NextPackageOccurrenceId: number = null;

    /**
     * @name BeginningDateTime
     * @description 
     * The beginning date and time when this inventory has noted parent inventory, version, contact or package occurrence.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BeginningDateTime: Date = null;

    /**
     * @name EndingDateTime
     * @description 
     * The ending date and time when this inventory has noted parent inventory, version, contact or package occurrence.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EndingDateTime: Date = null;

    /**
     * @name ExpectedEndingDateTime
     * @description 
     * The expected ending date and time when this inventory has noted parent inventory, version, contact or package occurrence.
     *  Note when inventory is assigned to item noted in the history record the actual ending date time may be unknown and in those
     * cases the expected ending date and time is all that is known.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpectedEndingDateTime: Date = null;

    /**
     * @name BeginningDateTimeCondition
     * @description 
     * The condition of this item at beginning date time.
     * @servertype String
     * @type {string}
     */
    BeginningDateTimeCondition: string = "";

    /**
     * @name EndingDateTimeCondition
     * @description 
     * The condition of this item at ending date time.
     * @servertype String
     * @type {string}
     */
    EndingDateTimeCondition: string = "";

    /**
     * @name AssociationType
     * @description 
     * The type of association.  Possible values include: 
     * EXT = Extension
     * TRE = Transferred Extension
     * ACC = Account Code
     * AUT = Authorization Code
     * SNO = Source Number
     * TNO = Target Number
     * DNO = DNIS Number
     * ON1 = Other Number 1
     * ON2 = Other Number 2
     * ANO = All Numbers
     * SWH = Switch
     * HOP = Next Hop Switch
     * SSW = Source Switch
     * SGI = Source Trunk Group In
     * STI = Source Trunk Number In
     * SGO = Source Trunk Group Out
     * STO = Source Trunk Number Out
     * TSW = Target Switch
     * TGI = Target Trunk Group In
     * TTI = Target Trunk Number In
     * TGO = Target Trunk Group Out
     * TTO = Target Trunk Number Out
     * NET = Network Id
     * SUB = Subscriber Id
     * @servertype String
     * @type {string}
     */
    AssociationType: string = "";

    /**
     * @name AssociationValue
     * @description 
     * The value for this association.  See association value in the association table.
     * @servertype String
     * @type {string}
     */
    AssociationValue: string = "";

    /**
     * @name AssociationSwitchId
     * @description 
     * The switch id for this association.  See switch id in the association table.
     * @servertype String
     * @type {string}
     */
    AssociationSwitchId: string = "";

    /**
     * @name AssociationDescription
     * @description 
     * The description for this association.  See description in the association table.
     * @servertype String
     * @type {string}
     */
    AssociationDescription: string = "";

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when the association is valid.  Inventory assignment
     * to contact or package occurrence may or may not be identical to the desired association date time range.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when the association is valid.  Inventory assignment to contact
     * or package occurrence may or may not be identical to the desired association date time range.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name ExpectedValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when the association is valid.  Inventory assignment
     * to contact or package occurrence may or may not be identical to the desired association date time range.  Since the actual
     * association date range may change this value represents the original expected start date time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpectedValidStartDateTime: Date = null;

    /**
     * @name ExpectedValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when the association is valid.  Inventory assignment to contact
     * or package occurrence may or may not be identical to the desired association date time range.  Since the actual association
     * date range may change this value represents the original expected end date time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpectedValidEndDateTime: Date = null;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name InventoryLocationEditViewModel
 * @description
 * The Inventory Location table contains quantity information for inventory locations.  This is typically only used for quantity
 * based (i.e. non-durable) inventory items.  For durable inventory items the location of the item is stored in the inventory
 * contact id.
 */
export class InventoryLocationEditViewModel {

    /**
     * @name InventoryLocationId
     * @description 
     * The id of the inventory location.
     * @servertype Int64
     * @type {number}
     */
    InventoryLocationId: number = null;

    /**
     * @name InventoryId
     * @description 
     * The id of the inventory for this inventory location.
     * @servertype Int64
     * @type {number}
     */
    InventoryId: number = null;

    /**
     * @name Description
     * @description 
     * Description for this inventory location.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name LocationContactId
     * @description 
     * The Contact Id of the location for this inventory.  Since this is for quantity based inventory it is typically only contact
     * types like warehouse, retail location, or location not contact types like customer.
     * @servertype Int64
     * @type {number}
     */
    LocationContactId: number = null;

    /**
     * @name LocationContactName
     * @servertype String
     * @type {string}
     */
    LocationContactName: string = "";

    /**
     * @name Quantity
     * @description 
     * The quantity of inventory at this location.
     * @servertype Double (nullable)
     * @type {number}
     */
    Quantity: number = 0;

    /**
     * @name QuantityType
     * @description 
     * The quantity type for the quantity of inventory at this location.
     * @servertype String
     * @type {string}
     */
    QuantityType: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name InventoryQuantityTypeEditViewModel
 * @description
 * The Inventory Quantity Type table contains information about quantity types including how to convert to other quantity types.
 */
export class InventoryQuantityTypeEditViewModel {

    /**
     * @name InventoryQuantityTypeId
     * @description 
     * The id for the inventory quantity type.  The quantity type value is used as the primary quantity type reference.
     * @servertype Int64
     * @type {number}
     */
    InventoryQuantityTypeId: number = null;

    /**
     * @name QuantityType
     * @description 
     * The quantity type.  For example: Ton, Pound, Kilogram, Case, Item, Serving, Yard, etc.
     * @servertype String
     * @type {string}
     */
    QuantityType: string = "";

    /**
     * @name Description
     * @description 
     * Description for this pick list.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name UnitOfMeasureType
     * @description 
     * A unit of measure type for this quantity type.  This can be used to group quantity types that use the same unit of measure
     * type.  For example, Ton, Pound, Kilogram may all be unit of measure type 'Weight'.
     * @servertype String
     * @type {string}
     */
    UnitOfMeasureType: string = "";

    /**
     * @name Enabled
     * @description 
     * When true this quantity type is enabled and, therefore, visible in the ui.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the quantity types in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Icon
     * @description 
     * If the quantity type has an icon associated with it this is the icon name.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name Conversion
     * @description 
     * A JSON list of objects that hold conversion information from this quantity type to other quantity types.  For example, a
     * quantity type of 'Pound' might have these conversion values: 
     * [
     *    {
     *       "TargetQuantityType": "Kilogram",
     *       "Multiplier": 0.45359237
     *    },
     *    {
     *       "TargetQuantityType": "Ton",
     *       "Multiplier": 0.0005
     *    }
     * ]
     * @servertype InventoryQuantityTypeConversionViewModel array
     * @type {InventoryQuantityTypeConversionViewModel[]}
     */
    Conversion: InventoryQuantityTypeConversionViewModel[] = [];

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name InventoryQuantityTypeConversionViewModel
 */
export class InventoryQuantityTypeConversionViewModel {

    /**
     * @name TargetQuantityType
     * @servertype String
     * @type {string}
     */
    TargetQuantityType: string = "";

    /**
     * @name Multiplier
     * @servertype Double
     * @type {number}
     */
    Multiplier: number = 0;

}



/**
 * @name Inventory Type Version Release Note Create Request
 */
export class InventoryTypeVersionReleaseNoteCreateRequestModel {

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name InventoryTypeVersionIds
     * @servertype long array
     * @type {number[]}
     */
    InventoryTypeVersionIds: number[] = [];

    /**
     * @name Groups
     * @servertype string array
     * @type {string[]}
     */
    Groups: string[] = [];

    /**
     * @name Sort
     * @servertype string array
     * @type {string[]}
     */
    Sort: string[] = [];

    /**
     * @name UseCaseDescriptionWhenResolutionIsEmpty
     * @servertype Boolean
     * @type {boolean}
     */
    UseCaseDescriptionWhenResolutionIsEmpty: boolean = false;

    /**
     * @name OutputArticle
     * @servertype Boolean
     * @type {boolean}
     */
    OutputArticle: boolean = false;

    /**
     * @name OutputDocx
     * @servertype Boolean
     * @type {boolean}
     */
    OutputDocx: boolean = false;

    /**
     * @name OutputPdf
     * @servertype Boolean
     * @type {boolean}
     */
    OutputPdf: boolean = false;

    /**
     * @name ReleaseNotesTemplateAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReleaseNotesTemplateAssetId: number = 0;

    /**
     * @name OwnerInventoryTypeVersionId
     * @servertype Int64
     * @type {number}
     */
    OwnerInventoryTypeVersionId: number = 0;

}



/**
 * @name InventoryTypeVersionEditViewModel
 * @description
 * The InventoryTypeVersion table contains a list of versions that are valid for an inventory type.
 */
export class InventoryTypeVersionEditViewModel {

    /**
     * @name InventoryTypeVersionId
     * @description 
     * The id of the inventory type version row.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryTypeVersionId: number = null;

    /**
     * @name InventoryTypeId
     * @description 
     * The id of the inventory type this version belongs to.
     * @servertype Int64
     * @type {number}
     */
    InventoryTypeId: number = null;

    /**
     * @name Version
     * @description 
     * Version.
     * @servertype String
     * @type {string}
     */
    Version: string = "";

    /**
     * @name Description
     * @description 
     * Description of this version.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ReleaseType
     * @description 
     * Release type of this version.  Possible values include: 
     * F = Future
     * I = Internal
     * S = Semi-public (Semi-public releases like beta, CTP, etc.)
     * P = Public
     * @servertype String
     * @type {string}
     */
    ReleaseType: string = "I";

    /**
     * @name ReleaseDate
     * @description 
     * The date when the specified version was released.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReleaseDate: Date = null;

    /**
     * @name DiscontinueDate
     * @description 
     * The date when the specified version was discontinued.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DiscontinueDate: Date = null;

    /**
     * @name SupportEndDate
     * @description 
     * The date when support ends for the specified version.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SupportEndDate: Date = null;

    /**
     * @name ExtendedSupportEndDate
     * @description 
     * The date when extended support ends for the specified version.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExtendedSupportEndDate: Date = null;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Inventory Type List
 * @description
 * The InventoryType table contains a list of inventory types and default values for each inventory type used in the inventory
 * table.
 */
export class InventoryTypeListViewModel {

    /**
     * @name InventoryTypeId
     * @description 
     * The id of the inventory type.
     * @servertype Int64
     * @type {number}
     */
    InventoryTypeId: number = null;

    /**
     * @name Type
     * @description 
     * Inventory Type.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Class
     * @description 
     * Class for this inventory type.  This value is not a default value for inventory records of this inventory type.  It is a
     * classification for this type.
     * @servertype String
     * @type {string}
     */
    Class: string = "";

    /**
     * @name Category
     * @description 
     * Category for this inventory type.  This value is not a default value for inventory records of this inventory type.  It is
     * a category for this type.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name Description
     * @description 
     * Description for this inventory type.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

}



/**
 * @name InventoryTypeEditViewModel
 * @description
 * The InventoryType table contains a list of inventory types and default values for each inventory type used in the inventory
 * table.
 */
export class InventoryTypeEditViewModel {

    /**
     * @name InventoryTypeId
     * @description 
     * The id of the inventory type.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryTypeId: number = null;

    /**
     * @name Type
     * @description 
     * Inventory Type.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Class
     * @description 
     * Class for this inventory type.  This value is not a default value for inventory records of this inventory type.  It is a
     * classification for this type.
     * @servertype String
     * @type {string}
     */
    Class: string = "";

    /**
     * @name Category
     * @description 
     * Category for this inventory type.  This value is not a default value for inventory records of this inventory type.  It is
     * a category for this type.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name Description
     * @description 
     * Description for this inventory type.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name QuantityBasedInventory
     * @description 
     * When true inventory using this inventory type is tracked in quantity instead of by item.  This is typically for non-durable
     * inventory items.
     * @servertype Boolean
     * @type {boolean}
     */
    QuantityBasedInventory: boolean = false;

    /**
     * @name QuantityLocationTracking
     * @description 
     * When true inventory quantities are tracked by location.  When false they are tracked in total by the inventory item.
     * @servertype Boolean
     * @type {boolean}
     */
    QuantityLocationTracking: boolean = false;

    /**
     * @name QuantityTypes
     * @description 
     * A list of quantity types that are valid for this inventory type.
     * @servertype string array
     * @type {string[]}
     */
    QuantityTypes: string[] = [];

    /**
     * @name QuantityTypeDefault
     * @description 
     * The quantity type to use as the default value for inventory of this type.
     * @servertype String
     * @type {string}
     */
    QuantityTypeDefault: string = "";

    /**
     * @name ManufacturerContactId
     * @description 
     * The Contact Id of the manufacturer.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ManufacturerContactId: number = null;

    /**
     * @name ManufacturerContactName
     * @servertype String
     * @type {string}
     */
    ManufacturerContactName: string = "";

    /**
     * @name ManagementVendorContactId
     * @description 
     * The Contact Id of the management vendor.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ManagementVendorContactId: number = null;

    /**
     * @name ManagementVendorContactName
     * @servertype String
     * @type {string}
     */
    ManagementVendorContactName: string = "";

    /**
     * @name PurchaseVendorContactId
     * @description 
     * The Contact Id of the vendor purchased from.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PurchaseVendorContactId: number = null;

    /**
     * @name PurchaseVendorContactName
     * @servertype String
     * @type {string}
     */
    PurchaseVendorContactName: string = "";

    /**
     * @name PurchasePrice
     * @description 
     * The purchased price.
     * @servertype Double (nullable)
     * @type {number}
     */
    PurchasePrice: number = 0;

    /**
     * @name PurchaseIsoCurrencyCode
     * @description 
     * Purchase ISO Currency Code.
     * @servertype String
     * @type {string}
     */
    PurchaseIsoCurrencyCode: string = "";

    /**
     * @name LeaseItem
     * @description 
     * When true this item is leased.  In this case all purchase fields reflect lease information and the lease expiration date
     * column is used.
     * @servertype Boolean
     * @type {boolean}
     */
    LeaseItem: boolean = false;

    /**
     * @name LeaseDays
     * @description 
     * The number of days from lease start condition until the lease expires.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LeaseDays: number = 0;

    /**
     * @name LeaseStart
     * @description 
     * The condition that represents when the lease starts.  Possible values include: 
     * I = Lease starts when inventory record of this type is created.
     * T = Lease starts when inventory record is first transferred.
     * W = Lease starts when inventory record is first transferred to a non-warehouse entity.
     * C = Lease starts when inventory record is first transferred to a customer-level entity.
     * @servertype String
     * @type {string}
     */
    LeaseStart: string = "I";

    /**
     * @name InstalledByContactId
     * @description 
     * The Contact Id of entity who installed this item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InstalledByContactId: number = null;

    /**
     * @name InstalledByContactName
     * @servertype String
     * @type {string}
     */
    InstalledByContactName: string = "";

    /**
     * @name WarrantyDays
     * @description 
     * The number of days from warranty start condition until the warranty expires.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    WarrantyDays: number = 0;

    /**
     * @name WarrantyStart
     * @description 
     * The condition that represents when the warranty starts.  Possible values include: 
     * I = Warranty starts when inventory record of this type is created.
     * T = Warranty starts when inventory record is first transferred.
     * W = Warranty starts when inventory record is first transferred to a non-warehouse entity.
     * C = Warranty starts when inventory record is first transferred to a customer-level entity.
     * @servertype String
     * @type {string}
     */
    WarrantyStart: string = "I";

    /**
     * @name MaintenanceVendorContactId
     * @description 
     * The Contact Id of the vendor providing maintenance for this item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    MaintenanceVendorContactId: number = null;

    /**
     * @name MaintenanceVendorContactName
     * @servertype String
     * @type {string}
     */
    MaintenanceVendorContactName: string = "";

    /**
     * @name MaintenanceDays
     * @description 
     * The number of days from maintenance start condition until the maintenance expires.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaintenanceDays: number = 0;

    /**
     * @name MaintenanceStart
     * @description 
     * The condition that represents when the maintenance starts.  Possible values include: 
     * I = Maintenance starts when inventory record of this type is created.
     * T = Maintenance starts when inventory record is first transferred.
     * W = Maintenance starts when inventory record is first transferred to a non-warehouse entity.
     * C = Maintenance starts when inventory record is first transferred to a customer-level entity.
     * @servertype String
     * @type {string}
     */
    MaintenanceStart: string = "I";

    /**
     * @name MaintenanceType
     * @description 
     * The type of maintenance agreement on this item.
     * @servertype String
     * @type {string}
     */
    MaintenanceType: string = "";

    /**
     * @name MaintenancePrice
     * @description 
     * The maintenance price.
     * @servertype Double (nullable)
     * @type {number}
     */
    MaintenancePrice: number = 0;

    /**
     * @name MaintenanceIsoCurrencyCode
     * @description 
     * Maintenance ISO Currency Code.
     * @servertype String
     * @type {string}
     */
    MaintenanceIsoCurrencyCode: string = "";

    /**
     * @name Condition
     * @description 
     * The condition of this item.
     * @servertype String
     * @type {string}
     */
    Condition: string = "";

    /**
     * @name AssociationType
     * @description 
     * The type of association.  Possible values include: 
     * EXT = Extension
     * TRE = Transferred Extension
     * ACC = Account Code
     * AUT = Authorization Code
     * SID = Source Identifier
     * TID = Target Identifier
     * OTI = Original Target Identifier
     * OI1 = Other Identifier 1
     * OI2 = Other Identifier 2
     * SNE = Source Network Element
     * SGI = Source Port Group In
     * SPI = Source Port Number In
     * SGO = Source Port Group Out
     * SPO = Source Port Number Out
     * TNE = Target Network Element
     * TGI = Target Port Group In
     * TPI = Target Port Number In
     * TGO = Target Port Group Out
     * TPO = Target Port Number Out
     * NET = Network Id
     * SUB = Subscriber Id
     *     = N/A
     * @servertype String
     * @type {string}
     */
    AssociationType: string = "";

    /**
     * @name SupportVersions
     * @description 
     * When true this inventory type supports versions.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportVersions: boolean = false;

    /**
     * @name VersionNumberTemplate
     * @description 
     * A template to use for version number.  Supported macros include {{ReleaseType}}, {{ReleaseTypeDescription}}, {{ReleaseDate}},
     * {{ReleaseYear}}, {{ReleaseMonth}}, {{ReleaseDay}}.
     * @servertype String
     * @type {string}
     */
    VersionNumberTemplate: string = "";

    /**
     * @name VersionDescriptionTemplate
     * @description 
     * A template to use for version description.  Supported macros include {{ReleaseType}}, {{ReleaseTypeDescription}}, {{ReleaseDate}},
     * {{ReleaseYear}}, {{ReleaseMonth}}, {{ReleaseDay}}.
     * @servertype String
     * @type {string}
     */
    VersionDescriptionTemplate: string = "";

    /**
     * @name VersionReleaseFrequency
     * @description 
     * Frequency with which to expect version releases.  Possible values include:
     * D = Daily
     * W = Weekly
     * M = Monthly
     * Q = Quarterly
     * A = Semi-Annually
     * Y = Yearly
     * I = Interval
     * N = None
     * @servertype String
     * @type {string}
     */
    VersionReleaseFrequency: string = "N";

    /**
     * @name VersionReleaseFrequencyInterval
     * @description 
     * The interval to use for the version release frequency.
     * For daily frequency this one or more days of the week (SMTWRFU).
     * For weekly frequency this is a specified day of week (SMTWRFU).
     * For monthly frequency this is a specified day of month (1-31).
     * For quarterly frequency this is a specified day of quarter (1-90).
     * For semi-annual frequency this is a specified day of half year (1-180).
     * For yearly frequency this is a specified day of year (1-365).
     * For interval frequency this is an interval number of days.
     * @servertype String
     * @type {string}
     */
    VersionReleaseFrequencyInterval: string = "";

    /**
     * @name InventoryTypeVersionInstructions
     * @description 
     * Instructions for inventory type versions.  This is used to help with version release management, deployments, etc.
     * @servertype String
     * @type {string}
     */
    InventoryTypeVersionInstructions: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Inventory List
 * @description
 * The Inventory table contains a list of inventory items owned by or assigned to an entity.
 */
export class InventoryListViewModel {

    /**
     * @name InventoryId
     * @description 
     * The id of the inventory.
     * @servertype Int64
     * @type {number}
     */
    InventoryId: number = null;

    /**
     * @name TrackingNumber
     * @description 
     * Tracking number should uniquely represent this inventory item.
     * @servertype String
     * @type {string}
     */
    TrackingNumber: string = "";

    /**
     * @name SerialNumber
     * @description 
     * Serial number for this item.
     * @servertype String
     * @type {string}
     */
    SerialNumber: string = "";

    /**
     * @name Description
     * @description 
     * Description for this item.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ContactId
     * @description 
     * The Contact Id that owns this inventory.  This may differ from the billing entity found in PackageOccurrenceId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

}



/**
 * @name InventoryEditViewModel
 * @description
 * The Inventory table contains a list of inventory items owned by or assigned to an entity.
 */
export class InventoryEditViewModel {

    /**
     * @name InventoryId
     * @description 
     * The id of the inventory.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryId: number = null;

    /**
     * @name InventoryTypeId
     * @description 
     * The id of the inventory type for this inventory.
     * @servertype Int64
     * @type {number}
     */
    InventoryTypeId: number = null;

    /**
     * @name ParentInventoryId
     * @description 
     * The id of the inventory this inventory is a child record of.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentInventoryId: number = null;

    /**
     * @name TrackingNumber
     * @description 
     * Tracking number should uniquely represent this inventory item.
     * @servertype String
     * @type {string}
     */
    TrackingNumber: string = "";

    /**
     * @name Description
     * @description 
     * Description for this item.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Quantity
     * @description 
     * The quantity of inventory for this item when tracked by quantity and not by location.
     * @servertype Double (nullable)
     * @type {number}
     */
    Quantity: number = 0;

    /**
     * @name QuantityType
     * @description 
     * The quantity type for the quantity when tracked by quantity and not by location.
     * @servertype String
     * @type {string}
     */
    QuantityType: string = "";

    /**
     * @name ContactId
     * @description 
     * The Contact Id that owns this inventory.  This may differ from the billing entity found in PackageOccurrenceId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name PackageOccurrenceId
     * @description 
     * The Package Occurrence Id that is held by the billing entity for this inventory.  This may be the same or differ from the
     * owner of the inventory found in the Contact Id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = null;

    /**
     * @name BillingContract
     * @description 
     * The billing contract for this item.
     * @servertype String
     * @type {string}
     */
    BillingContract: string = "";

    /**
     * @name ManufacturerContactId
     * @description 
     * The Contact Id of the item's manufacturer.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ManufacturerContactId: number = null;

    /**
     * @name ManufacturerContactName
     * @servertype String
     * @type {string}
     */
    ManufacturerContactName: string = "";

    /**
     * @name SerialNumber
     * @description 
     * Serial number for this item.
     * @servertype String
     * @type {string}
     */
    SerialNumber: string = "";

    /**
     * @name InventoryTypeVersionId
     * @description 
     * The id of the inventory type version for this inventory.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InventoryTypeVersionId: number = null;

    /**
     * @name ManagementVendorContactId
     * @description 
     * The Contact Id of the management vendor.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ManagementVendorContactId: number = null;

    /**
     * @name ManagementVendorContactName
     * @servertype String
     * @type {string}
     */
    ManagementVendorContactName: string = "";

    /**
     * @name PurchaseVendorContactId
     * @description 
     * The Contact Id of the vendor this item was purchased from.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PurchaseVendorContactId: number = null;

    /**
     * @name PurchaseVendorContactName
     * @servertype String
     * @type {string}
     */
    PurchaseVendorContactName: string = "";

    /**
     * @name PurchaseDate
     * @description 
     * The date when the item was purchased.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    PurchaseDate: Date = null;

    /**
     * @name PurchaseReferenceNumber
     * @description 
     * The reference number for this purchase (i.e. PO#, invoice #, etc.)
     * @servertype String
     * @type {string}
     */
    PurchaseReferenceNumber: string = "";

    /**
     * @name PurchasePrice
     * @description 
     * The purchased price.
     * @servertype Double (nullable)
     * @type {number}
     */
    PurchasePrice: number = 0;

    /**
     * @name PurchaseIsoCurrencyCode
     * @description 
     * Purchase ISO Currency Code.
     * @servertype String
     * @type {string}
     */
    PurchaseIsoCurrencyCode: string = "";

    /**
     * @name LeaseItem
     * @description 
     * When true this item is leased.  In this case all purchase fields reflect lease information and the lease expiration date
     * column is used.
     * @servertype Boolean
     * @type {boolean}
     */
    LeaseItem: boolean = false;

    /**
     * @name LeaseExpirationDate
     * @description 
     * The date when the lease expires.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LeaseExpirationDate: Date = null;

    /**
     * @name InstalledDate
     * @description 
     * The date when the item was installed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    InstalledDate: Date = null;

    /**
     * @name InstalledByContactId
     * @description 
     * The Contact Id of entity who installed this item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    InstalledByContactId: number = null;

    /**
     * @name InstalledByContactName
     * @servertype String
     * @type {string}
     */
    InstalledByContactName: string = "";

    /**
     * @name WarrantyExpirationDate
     * @description 
     * The date when the item warranty expires.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    WarrantyExpirationDate: Date = null;

    /**
     * @name MaintenanceVendorContactId
     * @description 
     * The Contact Id of the vendor providing maintenance for this item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    MaintenanceVendorContactId: number = null;

    /**
     * @name MaintenanceVendorContactName
     * @servertype String
     * @type {string}
     */
    MaintenanceVendorContactName: string = "";

    /**
     * @name MaintenanceExpirationDate
     * @description 
     * The date when the maintenance expires.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    MaintenanceExpirationDate: Date = null;

    /**
     * @name MaintenanceReferenceNumber
     * @description 
     * The reference number for the maintenance agreement on this item.
     * @servertype String
     * @type {string}
     */
    MaintenanceReferenceNumber: string = "";

    /**
     * @name MaintenanceType
     * @description 
     * The type of maintenance agreement on this item.
     * @servertype String
     * @type {string}
     */
    MaintenanceType: string = "";

    /**
     * @name MaintenancePrice
     * @description 
     * The maintenance price.
     * @servertype Double (nullable)
     * @type {number}
     */
    MaintenancePrice: number = 0;

    /**
     * @name MaintenanceIsoCurrencyCode
     * @description 
     * Maintenance ISO Currency Code.
     * @servertype String
     * @type {string}
     */
    MaintenanceIsoCurrencyCode: string = "";

    /**
     * @name Condition
     * @description 
     * The condition of this item.
     * @servertype String
     * @type {string}
     */
    Condition: string = "";

    /**
     * @name ConditionDescription
     * @description 
     * Description of the condition of this item.
     * @servertype String
     * @type {string}
     */
    ConditionDescription: string = "";

    /**
     * @name ConditionUpdatedByContactId
     * @description 
     * User name who last updated this condition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ConditionUpdatedByContactId: number = null;

    /**
     * @name ConditionUpdatedByContactName
     * @servertype String
     * @type {string}
     */
    ConditionUpdatedByContactName: string = "";

    /**
     * @name ConditionUpdatedDateTime
     * @description 
     * The date and time when the condition was last updated.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ConditionUpdatedDateTime: Date = null;

    /**
     * @name AssociationType
     * @description 
     * The type of association.  Possible values include: 
     * EXT = Extension
     * TRE = Transferred Extension
     * ACC = Account Code
     * AUT = Authorization Code
     * SNO = Source Number
     * TNO = Target Number
     * DNO = DNIS Number
     * ON1 = Other Number 1
     * ON2 = Other Number 2
     * ANO = All Numbers
     * SWH = Switch
     * HOP = Next Hop Switch
     * SSW = Source Switch
     * SGI = Source Trunk Group In
     * STI = Source Trunk Number In
     * SGO = Source Trunk Group Out
     * STO = Source Trunk Number Out
     * TSW = Target Switch
     * TGI = Target Trunk Group In
     * TTI = Target Trunk Number In
     * TGO = Target Trunk Group Out
     * TTO = Target Trunk Number Out
     * NET = Network Id
     * SUB = Subscriber Id
     *     = N/A
     * @servertype String
     * @type {string}
     */
    AssociationType: string = "";

    /**
     * @name AssociationValue
     * @description 
     * The value for this association.  See association value in the association table.
     * @servertype String
     * @type {string}
     */
    AssociationValue: string = "";

    /**
     * @name AssociationSwitchId
     * @description 
     * The switch id for this association.  See switch id in the association table.
     * @servertype String
     * @type {string}
     */
    AssociationSwitchId: string = "";

    /**
     * @name AssociationDescription
     * @description 
     * The description for this association.  See description in the association table.
     * @servertype String
     * @type {string}
     */
    AssociationDescription: string = "";

    /**
     * @name AvailableDateTime
     * @description 
     * The date and time when the inventory will become available.  This can be used to prevent inventory from being automatically
     * assigned as part of a package activation process, etc. because it (and related association) may need to sit in unassigned
     * pool for a certain number of days after last use.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AvailableDateTime: Date = null;

    /**
     * @name ProcessingStatus
     * @description 
     * Processing status of this inventory item.  This can be used to prevent the same inventory item from being grabbed for key
     * actions by more than one process.  For example, when assigning inventory from an available inventory pool this status prevents
     * the same item from being assigned more than once.  Possible values are: 
     * P = Pending (no inventory action happening)
     * R = Running (inventory action in progress)
     * @servertype String
     * @type {string}
     */
    ProcessingStatus: string = "P";

    /**
     * @name ProcessingServer
     * @description 
     * The server processing the inventory item.
     * @servertype String
     * @type {string}
     */
    ProcessingServer: string = "";

    /**
     * @name ProcessingDateTime
     * @description 
     * Date and time when the inventory processing started.  This can be used to determine if an inventory processing action had
     * a failure that prevented completion of processing.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessingDateTime: Date = null;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name JobTypeViewModel
 */
export class JobTypeViewModel {

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name LongDescription
     * @servertype String
     * @type {string}
     */
    LongDescription: string = "";

    /**
     * @name Category
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name CategoryDescription
     * @servertype String
     * @type {string}
     */
    CategoryDescription: string = "";

    /**
     * @name DefaultProperties
     * @servertype String
     * @type {string}
     */
    DefaultProperties: string = "";

    /**
     * @name SampleProperties
     * @servertype String
     * @type {string}
     */
    SampleProperties: string = "";

    /**
     * @name SystemAdministratorOnly
     * @servertype Boolean
     * @type {boolean}
     */
    SystemAdministratorOnly: boolean = false;

    /**
     * @name UnmanagedServiceOnly
     * @servertype Boolean
     * @type {boolean}
     */
    UnmanagedServiceOnly: boolean = false;

    /**
     * @name SystemRequestedOnly
     * @servertype Boolean
     * @type {boolean}
     */
    SystemRequestedOnly: boolean = false;

}



/**
 * @name JobEditViewModel
 * @description
 * The Job table contains information about both real-time and scheduled jobs that run on the application server.
 */
export class JobEditViewModel {

    /**
     * @name JobId
     * @description 
     * Job Id uniquely identifies this job.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    JobId: number = null;

    /**
     * @name Description
     * @description 
     * The description for this job.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Tags
     * @description 
     * A list of tags for this job.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name Type
     * @description 
     * The type of job.  Note that certain jobs are suggested to be restricted to administrator access only.  Note also that certain
     * jobs are expected to be real-time and will not relinquish the thread they are running in so they should be given ScheduleType
     * is R to prevent the activity from consuming a thread intended for non-real-time jobs.  Other jobs may run real-time or on-demand
     * based on desired configuration.  Possible values include: 
     * Report = Report
     * Query = Query [unmanaged service only]
     * ImportEngine = Import Engine (Usage Data Source)
     * RatingEngine = Rating Engine
     * NotificationEngine = Notification Engine [unmanaged service only]
     * NotificationTrigger = Notification Trigger
     * InboundMailProcessing = Inbound Mail Processing [unmanaged service only]
     * SystemWatchEventProcessing = System Watch Event Processing [unmanaged service only]
     * ProcessEngine = Process Engine [unmanaged service only]
     * WebhookDelivery = Webhook Delivery [unmanaged service only]
     * ProcessCreate = Create Process
     * CaseCreate = Case Create
     * SubscriptionSync = Subscription Synchronization [admin unmanaged service only]
     * AlarmRule = Execute Alarm Rule
     * DirectorySync = Directory Synchronization [admin unmanaged service only]
     * DataCollection = Data Collection
     * DataTransmission = Data Transmission
     * Copy = Copy Files
     * Ftp = FTP Session
     * Http = HTTP Session
     * ModemDownload = Modem Download
     * PurchaseCheckout = Purchase Checkout [unmanaged service only]
     * TaxationEngine = Taxation Engine [unmanaged service only]
     * EpaymentCreate = Payment Creation
     * EPaymentProcess = Payment Processing Engine
     * DbaBackup = Backup Database [admin unmanaged service only]
     * DbaRestore = Restore Database [admin unmanaged service only]
     * DbaIndex = Index Maintenance [admin unmanaged service only]
     * CheckPingFailure = Check Ping Failure
     * CheckPingLatency = Check Ping Latency
     * CheckHttpResponse = Check Http Response
     * CheckHttpLatency = Check Http Latency
     * CheckHttpKeyword = Check Http Keyword
     * CheckFileExists = Check File Exists
     * CheckDiskSpaceAvailable = Check Disk Space Available
     * CheckDatabaseSpaceAvailable = Check Database Space Available
     * CheckProcessRunning = Check Process Running
     * CheckRadiusServer = Check Radius Server
     * Run = Run Application or Script [admin unmanaged service only]
     * Sql = Execute SQL Statement [admin unmanaged service only]
     * SystemAdminWeekly = System Administration - Weekly [admin unmanaged service only]
     * SystemAdminDaily = System Administration - Daily [admin unmanaged service only]
     * SystemAdminHourly = System Administration - Hourly [admin unmanaged service only]
     * Custom = Custom [admin unmanaged service only]
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name TypeDescription
     * @servertype String
     * @type {string}
     */
    TypeDescription: string = "";

    /**
     * @name Priority
     * @description 
     * The priority for this job.  A lower value represents a higher priority.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name Scope
     * @description 
     * Job scope controls job visibility.  Possible values include:
     * S = System (not shown in non-admin displays)
     * U = User (scheduled by user and shown in display)
     * H = Hidden (scheduled by user and shown only in display for that user or admin display)
     * @servertype String
     * @type {string}
     */
    Scope: string = "U";

    /**
     * @name Flags
     * @description 
     * A list of job flags.  Possible values include: 
     * 32BIT = Job must run as a 32-bit process.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name ScheduleType
     * @description 
     * Schedule type for the job.  Possible values include:
     * R = Real-Time Job (runs in dedicated thread and runs continuously)
     * S = Scheduled Job (runs in scheduled thread pool at requested frequency and schedule)
     * O = One-Time Request (runs in one-time request thread pool in FIFO manner for queued report request, etc.)
     * T = Triggered Job (runs in scheduled thread pool after the triggered by job id completes)
     * @servertype String
     * @type {string}
     */
    ScheduleType: string = "S";

    /**
     * @name RealTimeInterval
     * @description 
     * The interval (in milliseconds) real-time jobs should be pausing between executions.  Most real-time jobs should have some
     * pause between executions to avoid hogging CPU resources.  Note that jobs are not required to honor this setting as given
     * since the job may have other requirements.  For example, a job that copies files may choose to ignore pause settings of less
     * than 30 seconds while a job that performs real-time epayment processing may choose to ignore pause settings of more than
     * 5 seconds because of its own requirements.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RealTimeInterval: number = 0;

    /**
     * @name TriggeredByJobId
     * @description 
     * When the ScheduledType is T (i.e. triggered job), this field represents the JobId that upon completion will trigger this
     * scheduled job.  Note that more than one job can be triggered by a single job and that triggered jobs can trigger additional
     * jobs.  There is no guarantee, however, that triggered jobs will execute in any particular order other than that they will
     * execute after the job that triggered them.  If order of execution is important then triggered jobs should be chained rather
     * than all base off of the same triggering job.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggeredByJobId: number = null;

    /**
     * @name TriggeredByJobWhen
     * @description 
     * Flag indicating when triggered job should fire.  Possible values include:
     * S = Success (when job triggering this was successful)
     * F = Failure (when job triggering this was unsuccessful)
     * A = Always (when job triggering this completed whether successful or not)
     * @servertype String
     * @type {string}
     */
    TriggeredByJobWhen: string = "A";

    /**
     * @name Status
     * @description 
     * Status of this job.  Possible values include:
     * P = Pending (empty and null also treated as pending)
     * S = Started
     * R = Running (step past started)
     * D = Delivering (for some job types like reports, etc.)
     * C = Closing (in process of stopping)
     * F = Finished (most jobs are reset to pending unless they are scheduled for one-time execution)
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name Properties
     * @description 
     * Properties for this scheduled job.  The values typically contained here are dictated by the Type field.  Data is stored in
     * INI, XML, JSON, or other format depending upon the expectations of the module handling the specified Type.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name RunAsApiKey
     * @description 
     * Run the job as this api key.  When not specified the run as contact or added by contact will be utilized.
     * @servertype String
     * @type {string}
     */
    RunAsApiKey: string = "";

    /**
     * @name RunAsContactId
     * @description 
     * Run the job as this contact id.  When not specified the added by contact will be utilized.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RunAsContactId: number = null;

    /**
     * @name RunAsDomain
     * @description 
     * Run the job under an account using this domain.  When not specified will use the default for this application server and/or
     * database.
     * @servertype String
     * @type {string}
     */
    RunAsDomain: string = "";

    /**
     * @name RunAsUserName
     * @description 
     * Run the job under an account using this user name.  When not specified will use the default for this application server and/or
     * database.
     * @servertype String
     * @type {string}
     */
    RunAsUserName: string = "";

    /**
     * @name RunAsPassword
     * @description 
     * Run the job under an account using this password.  When not specified will use the default for this application server and/or
     * database.
     * @servertype String
     * @type {string}
     */
    RunAsPassword: string = "";

    /**
     * @name TargetDatabaseServer
     * @description 
     * The target database server that must be used to service this request.  If not specified then any database server will work.
     *  Note that unlike, TargetApplicationServer, which may simply represent an affinity (when combined with a WatchDogInterval),
     * this TargetDatabaseServer setting when specified means the job must run on only that database server.  This is typically
     * done for certain real-time database jobs that run on all servers but are only active on one server or for database backup
     * jobs that must run on a certain server.
     * @servertype String
     * @type {string}
     */
    TargetDatabaseServer: string = "";

    /**
     * @name TargetApplicationServer
     * @description 
     * The target application server that should service this request.  If not specified then any application server running will
     * service this request.  Note that the watchdog thread of any application server instance on any server that is monitoring
     * this database may decide to execute this job if it determines that the target server is off-line or not responding.
     * @servertype String
     * @type {string}
     */
    TargetApplicationServer: string = "";

    /**
     * @name RunningApplicationServer
     * @description 
     * The application server running this job.  Frequently this is the same as TargetApplicationServer but if the machine specified
     * in TargetApplicationServer does not run the job then the watchdog thread on another machine may decide to run it.
     * @servertype String
     * @type {string}
     */
    RunningApplicationServer: string = "";

    /**
     * @name RunningPingDateTime
     * @description 
     * Date and time when the scheduled job last pinged the table to communicate that it was still working.  This may be used internally
     * by the watchdog thread of the scheduler service to determine if a process has stopped responding and if the thread handling
     * that process should be killed and restarted.  Jobs are responsible for setting this value so jobs that have high latency
     * should have equally high settings for WatchDogInterval.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RunningPingDateTime: Date = null;

    /**
     * @name WaitingApplicationServer
     * @description 
     * The application server waiting to run this job.  If the TargetApplicationServer was not available or went off line, the watchdog
     * thread of another server would take over running this job until the TargetApplicationServer was back on-line at which point
     * it should update this field and frequently update the WaitingPingDateTime field.
     * @servertype String
     * @type {string}
     */
    WaitingApplicationServer: string = "";

    /**
     * @name WaitingPingDateTime
     * @description 
     * Date and time when the WaitingApplicationServer (aka TargetApplicationServer) last pinged to indicate that it was ready to
     * take over its responsibilities to service this job.  When the watchdog thread of another server took control of this job
     * because it detected that the TargetApplicationServer was unavailable (based on WatchDogInterval) it started monitoring this
     * value to determine if the desired TargetApplicationServer comes back on-line.  Note that for scheduled jobs the job should
     * typically complete on the server it started on but for real-time jobs the RunningApplicationServer will yield to the WaitingApplicationServer
     * when it determines the WaitingApplicationServer is back on-line based on the WaitingPingDateTime being within the WatchDogInterval.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    WaitingPingDateTime: Date = null;

    /**
     * @name WatchDogInterval
     * @description 
     * The interval (in seconds) used by the watchdog thread to determine that a job (a) is not being properly serviced by the RunningApplicationServer
     * (see RunningPingDateTime); (b) the WaitingApplicationServer is ready to resume responsibility (see WaitingPingDateTime);
     * or (c) the TargetApplicationServer is not going to start the scheduled job (see NextRunDateTIme).
     * Set WatchDogInterval to 0 for (a) items that have an unknown update interval or which have no update interval (i.e. the RunningPingDateTime
     * is not going to be accurate); or (b) items that the watchdog does not need to worry about (i.e. two or more real-time jobs
     * are load-balanced on different machines and no fail-over is needed because the machine that is still active will carry the
     * load for both.); or (c) items that must run on TargetApplicationServer only because of required access to local resources
     * (e.g. local files, modem pools, etc.).
     * Note that non-real-time jobs (i.e. scheduled or one-time -- see Type) should have a non-zero value in this field unless the
     * scheduled job requires resources local to TargetApplicationServer (e.g. local files, modem pools, etc.).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    WatchDogInterval: number = 0;

    /**
     * @name Frequency
     * @description 
     * Frequency with which to run the specified job.  Possible values include:
     * H-xx -- Hourly at xx minutes after the hour.
     * D-SMTWRFU~HH:MM:SS -- Daily on specified days of week (SMTWRFU) at specified time (HH:MM:SS).
     * W-x~HH:MM:SS -- Weekly on specified day of week (SMTWRFU) at specified time (HH:MM:SS).
     * M-x~HH:MM:SS -- Monthly on specified day of month (1-31) at specified time (HH:MM:SS).
     * Q-x~HH:MM:SS -- Quarterly on specified day of quarter (1-90) at specified time (HH:MM:SS).
     * A-x~HH:MM:SS -- Semi-annually on specified day of half year (1-180) at specified time (HH:MM:SS).
     * Y-x~HH:MM:SS -- Yearly on specified day of year (1-365) at specified time (HH:MM:SS).
     * I-w,x,y,z -- Interval of every w days, x hours, y minutes, and z seconds.
     * O-YYYYMMDD~HH:MM:SS -- One time on YYYYMMDD at specified time (HH:MM:SS).
     * S- -- On start up of scheduler service.
     * N- -- N/A -- no specified frequency only executed on demand.
     * @servertype String
     * @type {string}
     */
    Frequency: string = "";

    /**
     * @name FrequencyDescription
     * @servertype String
     * @type {string}
     */
    FrequencyDescription: string = "";

    /**
     * @name LastRunDateTime
     * @description 
     * Date and time when the scheduled job was last run.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastRunDateTime: Date = null;

    /**
     * @name NextRunDateTime
     * @description 
     * Date and time when the scheduled job should run next.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NextRunDateTime: Date = null;

    /**
     * @name NotBeforeTime
     * @description 
     * A string in the format HH:MM representing the time before which this job will not run or if it is started will not execute
     * its tasks.
     * @servertype String
     * @type {string}
     */
    NotBeforeTime: string = "";

    /**
     * @name NotAfterTime
     * @description 
     * A string in the format HH:MM representing the time after which this job will not run or if it is started will not execute
     * its tasks.
     * @servertype String
     * @type {string}
     */
    NotAfterTime: string = "";

    /**
     * @name RunNow
     * @description 
     * When true, the scheduled job was requested to be run now.  When serviced this will reset to false.
     * @servertype Boolean
     * @type {boolean}
     */
    RunNow: boolean = false;

    /**
     * @name CancelNow
     * @description 
     * When true, the scheduled job was requested to be cancelled now.  When serviced this will reset to false.
     * @servertype Boolean
     * @type {boolean}
     */
    CancelNow: boolean = false;

    /**
     * @name TriggeredByResourceType
     * @description 
     * The resource type (e.g. table, etc.) that triggered this job.
     * @servertype String
     * @type {string}
     */
    TriggeredByResourceType: string = "";

    /**
     * @name TriggeredByResourceId
     * @description 
     * The triggered by resource id is the key of the triggered by resource type for this item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggeredByResourceId: number = null;

    /**
     * @name TriggeredByResourceId2
     * @description 
     * The triggered by resource id 2 is the key of the triggered by resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    TriggeredByResourceId2: string = null;

    /**
     * @name LastResultCode
     * @description 
     * Code of the last result of the scheduled job execution.  Possible values include:
     * 0 = Success
     * 1 = Unspecified Failure
     * 2 = Unknown Job Id
     * 3 = Unknown Job Type
     * 4 = Unable to create instance of module for specified Job Type
     * 11 = Properties missing (when properties are required)
     * 12 = Properties missing required section
     * 13 = Properties setting missing required value
     * 21 = Cancelled at service or user request
     * 22 = Cancelled at service request
     * 23 = Cancelled at user request
     * 31 = Recycled due to run duration
     * 32 = Recycled due to memory use
     * 33 = Recycled due to time of day
     * 41 = Requeued due to watchdog timeout
     * 99 = Exception
     * >100 = Job Specific Failure
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LastResultCode: number = 0;

    /**
     * @name LastResultMessage
     * @description 
     * Message of the last result of the job execution.
     * @servertype String
     * @type {string}
     */
    LastResultMessage: string = "";

    /**
     * @name Locked
     * @description 
     * When true, the scheduled job can only be modified by AddedByContactId or a system administrator.
     * @servertype Boolean
     * @type {boolean}
     */
    Locked: boolean = false;

    /**
     * @name Disabled
     * @description 
     * When true, the scheduled job is currently disabled.  Note that real-time jobs that should be stopped should be flagged as
     * disabled to prevent the watchdog from restarting them.
     * @servertype Boolean
     * @type {boolean}
     */
    Disabled: boolean = false;

    /**
     * @name AllowLateExecution
     * @description 
     * When true, allow late execution for this scheduled job.  When false, if more than 1 hour has passed since NextRunDateTime
     * before the scheduled job is able to be executed then reschedule instead of running.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowLateExecution: boolean = false;

    /**
     * @name AutoDelete
     * @description 
     * When true, automatically delete this entry once the scheduled job has completed and is not scheduled to run again.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoDelete: boolean = false;

    /**
     * @name LogType
     * @description 
     * Flag that indicates what type of logging to do for this job.  Possible values include: 
     * T = Trace
     * D = Debug
     * I = Info
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    LogType: string = "E";

    /**
     * @name StatusIntervalMinutes
     * @description 
     * Job status is updated in a job-specific pseudo-real-time basis.  For scheduled jobs those stats are cumulative for the life
     * of the job session.  For real-time jobs that status can be for a given interval using this column.  When set to 0, stats
     * are for the life-time of the job.  Other settings are not moving windows but are fixed windows based on the start of the
     * month, week, day or hour depending on if the value is in range for a month, week, day, or hour.  Examples: When set to 60,
     * stats are for the hour (e.g. 08:00:00).  When set to 15, stats are for the 15 minute window (e.g. 12:15:00).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatusIntervalMinutes: number = 0;

    /**
     * @name RetryMinutes
     * @description 
     * When >0 any scheduled job that ends in failure is automatically rescheduled to run X minutes later.  This provides the ability
     * to retry a failed job sooner than typically scheduled which is helpful for jobs that may have to contend for scarce resources
     * (e.g. modem download sessions that can't secure an available communications port, etc.).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetryMinutes: number = 0;

    /**
     * @name RetryMaximumCount
     * @description 
     * The maximum number of times a job can be retried using RetryMinutes.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetryMaximumCount: number = 0;

    /**
     * @name RetryCount
     * @description 
     * The current number of times a job has been retried.  When no longer retrying because of rescheduling or upon successful completion
     * this should be set to zero.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetryCount: number = 0;

    /**
     * @name MinimumMemoryAvailableMb
     * @description 
     * The minimum amount of memory in MB which an application server needs available before it will launch this job.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MinimumMemoryAvailableMb: number = 0;

    /**
     * @name MinimumCpuAvailable
     * @description 
     * The minimum % of CPU which an application server needs available before it will launch this job.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MinimumCpuAvailable: number = 0;

    /**
     * @name RecycleMinutes
     * @description 
     * The number of minutes after which the job is recycled.  Only applicable for real-time jobs.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RecycleMinutes: number = 0;

    /**
     * @name RecycleMemoryMb
     * @description 
     * The memory footprint in MB which, when reached, will recycle the job.  Only applicable for real-time jobs.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RecycleMemoryMb: number = 0;

    /**
     * @name RecycleTimeOfDay
     * @description 
     * A comma delimited list of times (e.g. 08:00:00,17:30:00) at which time the job will be recycled.  Only applicable for real-time
     * jobs.
     * @servertype String
     * @type {string}
     */
    RecycleTimeOfDay: string = "";

    /**
     * @name RecyclePauseSeconds
     * @description 
     * The number of seconds to pause before a recycled job will restart.  Can be used to provide system resources opportunity to
     * be released before trying to reclaim them.  Only applicable for real-time jobs.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RecyclePauseSeconds: number = 0;

    /**
     * @name RecycleLastDateTime
     * @description 
     * The last date and time when the job was recycled.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RecycleLastDateTime: Date = null;

    /**
     * @name LoadBalanceNumber
     * @description 
     * For load-balanced jobs this represents an incrementing value starting at 1 which is unique per job type that is keeping an
     * active connection to the database and can be used by the job for load-balancing purposes.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LoadBalanceNumber: number = 0;

    /**
     * @name LoadBalanceTotal
     * @description 
     * For load-balanced jobs this represents the total number of instances of this particular job type that are keeping an active
     * connection to the database and can be used by the job for load-balancing purposes.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LoadBalanceTotal: number = 0;

    /**
     * @name DatabaseLoadBalanceNumber
     * @description 
     * This represents an incrementing value starting at 1 which is unique for each database server referenced in the Service table.
     *  Can be used for load-balancing purposes where database server affinity is an issue.  For example, certain jobs that process
     * accounting messages into activity tables should only be running on one database server so duplicate rows are not created
     * and/or so unnecessary mediation is not performed.  In these cases the job that has a DatabaseLoadBalanceNumber of 1 should
     * be processing data and the others should be idle waiting to fail-over.  Note that this may not match the LoadBalanceNumber
     * column in the Service table for the database server in question since we are specifically concerned with database servers
     * running at least one instance of this job type.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DatabaseLoadBalanceNumber: number = 0;

    /**
     * @name DatabaseLoadBalanceTotal
     * @description 
     * This represents an the total number of instances of database servers referenced in the Service table.  Can be used for load-balancing
     * purposes where database server affinity is an issue.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DatabaseLoadBalanceTotal: number = 0;

    /**
     * @name CountStart
     * @description 
     * Count of the number of times this job has been started since scheduled.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CountStart: number = 0;

    /**
     * @name CountSuccess
     * @description 
     * Count of the number of times this job has been finished successfully since scheduled.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CountSuccess: number = 0;

    /**
     * @name CountFailure
     * @description 
     * Count of the number of times this job has been finished unsuccessfully since scheduled.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CountFailure: number = 0;

    /**
     * @name NotificationGroupIdStart
     * @description 
     * Notification Group Id to notify on scheduled job start.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStart: number = null;

    /**
     * @name NotificationStartFlags
     * @description 
     * A list of flags that indicate when the notification group outlined in NotificationGroupIdStart should receive notification.
     *  Possible values include: 
     * Always = Always provide notification of start
     * Late:xx = Provide notification of start when starting more than xx minutes late
     * OffTarget = Provide notification of start when starting on server other than the specified TargetApplicationServer.
     * @servertype String
     * @type {string}
     */
    NotificationStartFlags: string = "";

    /**
     * @name NotificationGroupIdProgress
     * @description 
     * Notification Group Id to notify of scheduled job progress.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdProgress: number = null;

    /**
     * @name NotificationProgressFlags
     * @description 
     * A list of flags that indicate when the notification group outlined in NotificationGroupIdProgress should receive notification.
     *  This is utilized by the job and, therefore, the possible values are job dependent.
     * @servertype String
     * @type {string}
     */
    NotificationProgressFlags: string = "";

    /**
     * @name NotificationProgressMinutes
     * @description 
     * Number of minutes after which the notification group outlined in NotificationGroupIdProgress should be given notification
     * of progress.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationProgressMinutes: number = 0;

    /**
     * @name NotificationProgressDateTime
     * @description 
     * The last date and time stamp when the notification group outlined in NotificationGroupIdProgress was given notification of
     * progress.  When sending progress notifications the next notification will be NotificationProgressMinutes minutes after NotificationProgressDateTime.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NotificationProgressDateTime: Date = null;

    /**
     * @name NotificationProgressEvents
     * @description 
     * Number of events after which the notification group outlined in NotificationGroupIdProgress should be given notification
     * of progress.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationProgressEvents: number = 0;

    /**
     * @name NotificationProgressCounter
     * @description 
     * Number of events that have been posted since the notification group outlined in NotificationGroupIdProgress was given notification
     * of progress.  When sending progress notifications the next notification will be when NotificationProgressCounter reaches
     * the threshold set by NotificationProgressEvents.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationProgressCounter: number = 0;

    /**
     * @name NotificationGroupIdNoProgress
     * @description 
     * Notification Group Id to notify when there is no scheduled job progress.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdNoProgress: number = null;

    /**
     * @name NotificationNoProgressFlags
     * @description 
     * A list of flags that indicate when the notification group outlined in NotificationGroupIdNoProgress should receive notification.
     *  This is utilized by the job and, therefore, the possible values are job dependent.
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressFlags: string = "";

    /**
     * @name NotificationNoProgressDays1
     * @description 
     * A string of codes that represent the days where this no progress notification rule is valid.  Where 
     * S = Sunday
     * M = Monday
     * T = Tuesday
     * W = Wednesday
     * R = Thursday
     * F = Friday
     * U = Saturday
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressDays1: string = "";

    /**
     * @name NotificationNoProgressStartTime1
     * @description 
     * A string in the format HH:MM representing the start time when this no progress notification rule is valid.
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressStartTime1: string = "";

    /**
     * @name NotificationNoProgressEndTime1
     * @description 
     * A string in the format HH:MM representing the end time when this no progress notification rule is valid.
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressEndTime1: string = "";

    /**
     * @name NotificationNoProgressMinutes1
     * @description 
     * Number of minutes during the specified days and times after which the notification group outlined in NotificationGroupIdNoProgress
     * should be given notification of the lack of progress.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationNoProgressMinutes1: number = 0;

    /**
     * @name NotificationNoProgressDays2
     * @description 
     * A string of codes that represent the days where this no progress notification rule is valid.  Where 
     * S = Sunday
     * M = Monday
     * T = Tuesday
     * W = Wednesday
     * R = Thursday
     * F = Friday
     * U = Saturday
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressDays2: string = "";

    /**
     * @name NotificationNoProgressStartTime2
     * @description 
     * A string in the format HH:MM representing the start time when this no progress notification rule is valid.
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressStartTime2: string = "";

    /**
     * @name NotificationNoProgressEndTime2
     * @description 
     * A string in the format HH:MM representing the end time when this no progress notification rule is valid.
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressEndTime2: string = "";

    /**
     * @name NotificationNoProgressMinutes2
     * @description 
     * Number of minutes during the specified days and times after which the notification group outlined in NotificationGroupIdNoProgress
     * should be given notification of the lack of progress.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationNoProgressMinutes2: number = 0;

    /**
     * @name NotificationNoProgressDays3
     * @description 
     * A string of codes that represent the days where this no progress notification rule is valid.  Where 
     * S = Sunday
     * M = Monday
     * T = Tuesday
     * W = Wednesday
     * R = Thursday
     * F = Friday
     * U = Saturday
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressDays3: string = "";

    /**
     * @name NotificationNoProgressStartTime3
     * @description 
     * A string in the format HH:MM representing the start time when this no progress notification rule is valid.
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressStartTime3: string = "";

    /**
     * @name NotificationNoProgressEndTime3
     * @description 
     * A string in the format HH:MM representing the end time when this no progress notification rule is valid.
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressEndTime3: string = "";

    /**
     * @name NotificationNoProgressMinutes3
     * @description 
     * Number of minutes during the specified days and times after which the notification group outlined in NotificationGroupIdNoProgress
     * should be given notification of the lack of progress.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationNoProgressMinutes3: number = 0;

    /**
     * @name NotificationNoProgressDays4
     * @description 
     * A string of codes that represent the days where this no progress notification rule is valid.  Where 
     * S = Sunday
     * M = Monday
     * T = Tuesday
     * W = Wednesday
     * R = Thursday
     * F = Friday
     * U = Saturday
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressDays4: string = "";

    /**
     * @name NotificationNoProgressStartTime4
     * @description 
     * A string in the format HH:MM representing the start time when this no progress notification rule is valid.
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressStartTime4: string = "";

    /**
     * @name NotificationNoProgressEndTime4
     * @description 
     * A string in the format HH:MM representing the end time when this no progress notification rule is valid.
     * @servertype String
     * @type {string}
     */
    NotificationNoProgressEndTime4: string = "";

    /**
     * @name NotificationNoProgressMinutes4
     * @description 
     * Number of minutes during the specified days and times after which the notification group outlined in NotificationGroupIdNoProgress
     * should be given notification of the lack of progress.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationNoProgressMinutes4: number = 0;

    /**
     * @name NotificationNoProgressDateTime
     * @description 
     * The last date and time stamp when the notification group outlined in NotificationGroupIdNoProgress was given notification
     * of the lack of progress.  When sending no progress notifications the next notification will be NotificationNoProgressMinutesX
     * minutes after NotificationNoProgressDateTime.  Note that when there is progress this NotificationNoProgressDateTime is set
     * to the current time preventing notification until at least NotificationNoProgressMinutesX has elapsed without progress.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NotificationNoProgressDateTime: Date = null;

    /**
     * @name NotificationGroupIdLogEvent
     * @description 
     * Notification Group Id to notify on scheduled job triggering a log event.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdLogEvent: number = null;

    /**
     * @name NotificationLogEventKeywords
     * @description 
     * A comma delimited list of keywords that when found in a log entry from the job will indicate when the notification group
     * outlined in NotificationGroupIdLogEvent should receive notification.
     * @servertype String
     * @type {string}
     */
    NotificationLogEventKeywords: string = "";

    /**
     * @name NotificationGroupIdError
     * @description 
     * Notification Group Id to notify on scheduled job having an error.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdError: number = null;

    /**
     * @name NotificationGroupIdFatalError
     * @description 
     * Notification Group Id to notify on scheduled job having a fatal error.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdFatalError: number = null;

    /**
     * @name NotificationGroupIdSuccess
     * @description 
     * Notification Group Id to notify on scheduled job success.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdSuccess: number = null;

    /**
     * @name NotificationGroupIdFailure
     * @description 
     * Notification Group Id to notify on scheduled job failure.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdFailure: number = null;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name AuditTrailEditViewModel
 * @description
 * The Audit Trail table contains information related to database activity.  Insert, update and delete activity for certain
 * tables are logged in this table for audit purposes.  A table is created for each calendar month using the template AuditTrailYYYYMM
 * as table name.
 */
export class AuditTrailEditViewModel {

    /**
     * @name AuditTrailId
     * @description 
     * When combined with the table name this is a unique value that represents this audit trail record.
     * @servertype String
     * @type {string}
     */
    AuditTrailId: string = null;

    /**
     * @name TransactionDateTime
     * @description 
     * Date and time when the database transaction occurred.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TransactionDateTime: Date = new Date();

    /**
     * @name TransactionType
     * @description 
     * Transaction type identifier.  Possible values include:
     * I = Insert
     * U = Update
     * D = Delete
     * E = Execute
     * R = Report
     * S = Security
     * C = Confidential Data Access
     * @servertype String
     * @type {string}
     */
    TransactionType: string = "";

    /**
     * @name ContactId
     * @description 
     * Contact Id of the user that executed the transaction triggering this audit record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name DatabaseObject
     * @description 
     * The database object for this transaction.  Typically a table name, view name or stored procedure name.
     * @servertype String
     * @type {string}
     */
    DatabaseObject: string = "";

    /**
     * @name PrimaryKey
     * @description 
     * The primary key for DatabaseObject.
     * @servertype String
     * @type {string}
     */
    PrimaryKey: string = "";

    /**
     * @name OwnerDatabaseObject
     * @description 
     * The database object that owns the database object for this transaction.  Typically a table name, view name or stored procedure
     * name.
     * @servertype String
     * @type {string}
     */
    OwnerDatabaseObject: string = "";

    /**
     * @name OwnerForeignKey
     * @description 
     * The owner foreign key for DatabaseObject.  This is the primary key to OwnerDatabaseObject.
     * @servertype String
     * @type {string}
     */
    OwnerForeignKey: string = "";

    /**
     * @name ApplicationName
     * @description 
     * Application name that executed this database transaction.
     * @servertype String
     * @type {string}
     */
    ApplicationName: string = "";

    /**
     * @name MachineName
     * @description 
     * Machine name that executed this database transaction.
     * @servertype String
     * @type {string}
     */
    MachineName: string = "";

    /**
     * @name SessionId
     * @description 
     * The session process id of the session that caused this update.  This value can be used to help identify changes that occurred
     * as part of the same session process.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SessionId: number = 0;

    /**
     * @name AuditInformation
     * @description 
     * The information being audited in either XML or JSON format as follows: 
     * <audit>
     * 	<column name="DateOfBirth" old="1972-04-15 00:00:00" new="1971-04-15 00:00:00" />
     * 	<column name="Age" old="41" new="42" />
     * </audit>
     * OR
     * {
     * 	"Action":  "Insert|Update|Delete", 
     * 	"ActionMessage":  "Assigned to Fred.", 
     * 	"Old": { "DateOfBirth":, "1972-04-15 00:00:00", "Age": "41" } , 
     * 	"New": { "DateOfBirth":, "1971-04-15 00:00:00", "Age": "42" } , 
     * 	"Messages": [ 
     * 		"Did one certain thing." , 
     * 		"Did another thing as well." 
     * 	]
     * }
     * @servertype String
     * @type {string}
     */
    AuditInformation: string = "";

    /**
     * @name Alarm
     * @description 
     * True indicates the audit trail entry has triggered an alarm.
     * @servertype Boolean
     * @type {boolean}
     */
    Alarm: boolean = false;

    /**
     * @name AlarmAcknowledged
     * @description 
     * True indicates the alarm triggered by the audit trail entry has been acknowledged.
     * @servertype Boolean
     * @type {boolean}
     */
    AlarmAcknowledged: boolean = false;

    /**
     * @name AutomatedFlag
     * @description 
     * When true the audit record has been flagged by an automated process.
     * @servertype Boolean
     * @type {boolean}
     */
    AutomatedFlag: boolean = false;

    /**
     * @name ManualFlag
     * @description 
     * When true the audit record has been manually flagged.
     * @servertype Boolean
     * @type {boolean}
     */
    ManualFlag: boolean = false;

    /**
     * @name Reviewed
     * @description 
     * When true the audit record has been reviewed.
     * @servertype Boolean
     * @type {boolean}
     */
    Reviewed: boolean = false;

    /**
     * @name Details
     * @servertype AuditTrailDetailViewModel array
     * @type {AuditTrailDetailViewModel[]}
     */
    Details: AuditTrailDetailViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name AuditTrailDetailViewModel
 */
export class AuditTrailDetailViewModel {

    /**
     * @name PropertyName
     * @servertype String
     * @type {string}
     */
    PropertyName: string = "";

    /**
     * @name DisplayName
     * @servertype String
     * @type {string}
     */
    DisplayName: string = "";

    /**
     * @name OldValue
     * @servertype String
     * @type {string}
     */
    OldValue: string = "";

    /**
     * @name NewValue
     * @servertype String
     * @type {string}
     */
    NewValue: string = "";

}



/**
 * @name LifeCycleEditViewModel
 * @description
 * The life cycle table contains life cycle information of various items like contact, billing account, packages occurrence,
 * etc.
 */
export class LifeCycleEditViewModel {

    /**
     * @name LifeCycleId
     * @description 
     * Life Cycle Id uniquely identifies this life cycle record.
     * @servertype Int64
     * @type {number}
     */
    LifeCycleId: number = null;

    /**
     * @name LifeCycleEventDateTime
     * @description 
     * The date and time of the life cycle event.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LifeCycleEventDateTime: Date = new Date();

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) of this life cycle event.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type.  For example, if the owner resource type is Asset this value
     * would be the AssetId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name LifeCycleType
     * @description 
     * The life cycle type is typically the property name of the owner resource property that is having a life cycle change.
     * @servertype String
     * @type {string}
     */
    LifeCycleType: string = "";

    /**
     * @name OldValue
     * @description 
     * The old (previous) value of the life cycle type for this event.  For example, if a contact scope is changing from N (normal)
     * to T (terminated) the old value is N and the new value is T.
     * @servertype String
     * @type {string}
     */
    OldValue: string = "";

    /**
     * @name NewValue
     * @description 
     * The new (current) value of the life cycle type for this event.  For example, if a contact scope is changing from N (normal)
     * to T (terminated) the old value is N and the new value is T.
     * @servertype String
     * @type {string}
     */
    NewValue: string = "";

    /**
     * @name Cascading
     * @description 
     * When true the life cycle change resulted in cascading changes.  For example, changing the status of of a billing account
     * may flow down to package occurrences under the billing account.
     * @servertype Boolean
     * @type {boolean}
     */
    Cascading: boolean = false;

    /**
     * @name TriggeredByContactId
     * @description 
     * The contact id of the contact triggering this life cycle event.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggeredByContactId: number = null;

    /**
     * @name TriggeredByContactName
     * @servertype String
     * @type {string}
     */
    TriggeredByContactName: string = "";

    /**
     * @name TriggeredByResourceType
     * @description 
     * The resource type (e.g. table, etc.) that triggered this life cycle event.
     * @servertype String
     * @type {string}
     */
    TriggeredByResourceType: string = "";

    /**
     * @name TriggeredByResourceId
     * @description 
     * The triggered by resource id is the key of the triggered by resource type for this item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggeredByResourceId: number = null;

    /**
     * @name TriggeredByResourceId2
     * @description 
     * The triggered by resource id 2 is the key of the triggered by resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    TriggeredByResourceId2: string = null;

    /**
     * @name TriggeredByDescription
     * @description 
     * The description of who/what/etc. triggered this life cycle event.
     * @servertype String
     * @type {string}
     */
    TriggeredByDescription: string = "";

    /**
     * @name Properties
     * @description 
     * Custom properties for this life cycle record stored as json object.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name LogListViewModel
 * @description
 * The log table contains logs about services, processes, jobs, errors, etc.  A table is created for each calendar month using
 * the template LogYYYYMM as table name.
 */
export class LogListViewModel {

    /**
     * @name LogId
     * @description 
     * Log Id uniquely identifies this log entry.
     * @servertype String
     * @type {string}
     */
    LogId: string = null;

    /**
     * @name SourceType
     * @description 
     * The source type of this log entry.
     * @servertype String
     * @type {string}
     */
    SourceType: string = "";

    /**
     * @name SourceId
     * @description 
     * The source id is the key of the source type for this log entry.  For example, if the source type is Job this value would
     * be the JobId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceId: number = null;

    /**
     * @name SourceId2
     * @description 
     * The source id 2 is the key of the source type when that id is a string data type or as a secondary id when child logs are
     * stored.  For example, process steps may post logs with a source type 'ProcessStep' with the process step id as the source
     * id and then post the process id here.
     * @servertype String
     * @type {string}
     */
    SourceId2: string = null;

    /**
     * @name SessionId
     * @description 
     * The session id identifies log entries belonging to the same session.
     * @servertype String
     * @type {string}
     */
    SessionId: string = "";

    /**
     * @name ContactId
     * @description 
     * The contact id of the contact associated with this log entry.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name LogDateTime
     * @description 
     * The date and time of this log entry.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LogDateTime: Date = new Date();

    /**
     * @name LogType
     * @description 
     * Flag that indicates what type of log entry this is.  Possible values include: 
     * T = Trace
     * D = Debug
     * I = Info
     * W = Warning
     * E = Error
     * F = Fatal Error
     * A = Analytics
     * M = Message
     * O = Output Data
     * P = Partial Result
     * R = Result
     * @servertype String
     * @type {string}
     */
    LogType: string = "I";

    /**
     * @name Category
     * @description 
     * Category.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name Process
     * @description 
     * Process.
     * @servertype String
     * @type {string}
     */
    Process: string = "";

    /**
     * @name Subprocess
     * @description 
     * Subprocess.
     * @servertype String
     * @type {string}
     */
    Subprocess: string = "";

    /**
     * @name LogInformation
     * @description 
     * Log entry.  Typically plain text but the format can vary depending on the source type and id.
     * @servertype String
     * @type {string}
     */
    LogInformation: string = "";

    /**
     * @name ResultCode
     * @description 
     * The result code associated with the log entry (if any).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name Reviewed
     * @description 
     * True indicates the log entry has been reviewed.
     * @servertype Boolean
     * @type {boolean}
     */
    Reviewed: boolean = false;

    /**
     * @name Alarm
     * @description 
     * True indicates the log entry has triggered an alarm.
     * @servertype Boolean
     * @type {boolean}
     */
    Alarm: boolean = false;

    /**
     * @name AlarmAcknowledged
     * @description 
     * True indicates the alarm triggered by the log entry has been acknowledged.
     * @servertype Boolean
     * @type {boolean}
     */
    AlarmAcknowledged: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name LogEditViewModel
 * @description
 * The log table contains logs about services, processes, jobs, errors, etc.  A table is created for each calendar month using
 * the template LogYYYYMM as table name.
 */
export class LogEditViewModel {

    /**
     * @name LogId
     * @description 
     * Log Id uniquely identifies this log entry.
     * @servertype String
     * @type {string}
     */
    LogId: string = null;

    /**
     * @name SourceType
     * @description 
     * The source type of this log entry.
     * @servertype String
     * @type {string}
     */
    SourceType: string = "";

    /**
     * @name SourceId
     * @description 
     * The source id is the key of the source type for this log entry.  For example, if the source type is Job this value would
     * be the JobId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceId: number = null;

    /**
     * @name SourceId2
     * @description 
     * The source id 2 is the key of the source type when that id is a string data type or as a secondary id when child logs are
     * stored.  For example, process steps may post logs with a source type 'ProcessStep' with the process step id as the source
     * id and then post the process id here.
     * @servertype String
     * @type {string}
     */
    SourceId2: string = null;

    /**
     * @name SessionId
     * @description 
     * The session id identifies log entries belonging to the same session.
     * @servertype String
     * @type {string}
     */
    SessionId: string = "";

    /**
     * @name ContactId
     * @description 
     * The contact id of the contact associated with this log entry.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name LogDateTime
     * @description 
     * The date and time of this log entry.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LogDateTime: Date = new Date();

    /**
     * @name LogType
     * @description 
     * Flag that indicates what type of log entry this is.  Possible values include: 
     * T = Trace
     * D = Debug
     * I = Info
     * W = Warning
     * E = Error
     * F = Fatal Error
     * A = Analytics
     * M = Message
     * O = Output Data
     * P = Partial Result
     * R = Result
     * @servertype String
     * @type {string}
     */
    LogType: string = "I";

    /**
     * @name Category
     * @description 
     * Category.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name Process
     * @description 
     * Process.
     * @servertype String
     * @type {string}
     */
    Process: string = "";

    /**
     * @name Subprocess
     * @description 
     * Subprocess.
     * @servertype String
     * @type {string}
     */
    Subprocess: string = "";

    /**
     * @name LogInformation
     * @description 
     * Log entry.  Typically plain text but the format can vary depending on the source type and id.
     * @servertype String
     * @type {string}
     */
    LogInformation: string = "";

    /**
     * @name LogDetail
     * @description 
     * Log detail beyond that reported in the LogInformation.  Typically plain text but the format can vary depending on the source
     * type and id.
     * @servertype String
     * @type {string}
     */
    LogDetail: string = "";

    /**
     * @name ResultCode
     * @description 
     * The result code associated with the log entry (if any).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name Reviewed
     * @description 
     * True indicates the log entry has been reviewed.
     * @servertype Boolean
     * @type {boolean}
     */
    Reviewed: boolean = false;

    /**
     * @name Alarm
     * @description 
     * True indicates the log entry has triggered an alarm.
     * @servertype Boolean
     * @type {boolean}
     */
    Alarm: boolean = false;

    /**
     * @name AlarmAcknowledged
     * @description 
     * True indicates the alarm triggered by the log entry has been acknowledged.
     * @servertype Boolean
     * @type {boolean}
     */
    AlarmAcknowledged: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name ProcessStatusDetailEditViewModel
 * @description
 * The ProcessStatusDetailYYYYMM table contains detailed status information about processes.  A table is created for each calendar
 * month using the template ProcessStatusDetailYYYYMM as table name.
 */
export class ProcessStatusDetailEditViewModel {

    /**
     * @name ProcessStatusDetailId
     * @description 
     * Process Status Detail Id uniquely identifies this process status row.
     * @servertype Int64
     * @type {number}
     */
    ProcessStatusDetailId: number = null;

    /**
     * @name ProcessStatusId
     * @description 
     * The process status id this detail belongs to.
     * @servertype Int64
     * @type {number}
     */
    ProcessStatusId: number = null;

    /**
     * @name StartDateTime
     * @description 
     * The process status start date and time.  See process status interval minutes for more information.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name Description
     * @description 
     * The description for this process status detail item.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Value
     * @description 
     * Value for this process status detail item it typically (but not always) equates to a subset of the process count value in
     * the process status table.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    Value: number = 0;

    /**
     * @name Information
     * @description 
     * Information about the value.
     * @servertype String
     * @type {string}
     */
    Information: string = "";

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the value in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name DetailGroup
     * @description 
     * An optional group for displaying process status details.
     * @servertype String
     * @type {string}
     */
    DetailGroup: string = "";

    /**
     * @name DetailGroupDisplayOrder
     * @description 
     * The order to display the group in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DetailGroupDisplayOrder: number = 0;

    /**
     * @name ValueIsDenominator
     * @description 
     * The value is denominator and represents 100% of the other values in the group.  This is used to aid in rendering other values
     * a percentage of the total either in grid or chart form.
     * @servertype Boolean
     * @type {boolean}
     */
    ValueIsDenominator: boolean = false;

    /**
     * @name LastUpdateDateTime
     * @description 
     * The date and time when the process status detail was last updated.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUpdateDateTime: Date = null;

    /**
     * @name TimerCount
     * @description 
     * The number of timed events available.  Typically the same as the process count.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerCount: number = 0;

    /**
     * @name TimerTotalMilliseconds
     * @description 
     * The total number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerTotalMilliseconds: number = 0;

    /**
     * @name TimerMaximumMilliseconds
     * @description 
     * The maximum number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMaximumMilliseconds: number = 0;

    /**
     * @name TimerMinimumMilliseconds
     * @description 
     * The minimum number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMinimumMilliseconds: number = 0;

    /**
     * @name TimerAverageMilliseconds
     * @description 
     * The average number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerAverageMilliseconds: number = 0;

    /**
     * @name TimerMedianMilliseconds
     * @description 
     * The median number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMedianMilliseconds: number = 0;

    /**
     * @name TimerPercentile90Milliseconds
     * @description 
     * The 90th percentile number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerPercentile90Milliseconds: number = 0;

    /**
     * @name TimerStandardDeviationMilliseconds
     * @description 
     * The standard deviation number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerStandardDeviationMilliseconds: number = 0;

    /**
     * @name TimerEventsMilliseconds
     * @description 
     * A CSV of timer events in milliseconds.  This is the data source for other timer information provided here.
     * @servertype String
     * @type {string}
     */
    TimerEventsMilliseconds: string = "";

    /**
     * @name TimerStatsAsOfDateTime
     * @description 
     * The date and time the timer stats were last updated.  For performance reasons these stats are not updated real-time and if
     * this is earlier than the last update date time value the timer stats may be stale.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TimerStatsAsOfDateTime: Date = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name ProcessStatusEditViewModel
 * @description
 * The ProcessStatusYYYYMM table contains status information used by processes to convey information about their status.  A
 * table is created for each calendar month using the template ProcessStatusYYYYMM as table name.
 */
export class ProcessStatusEditViewModel {

    /**
     * @name ProcessStatusId
     * @description 
     * Process Status Id uniquely identifies this process status row.
     * @servertype Int64
     * @type {number}
     */
    ProcessStatusId: number = null;

    /**
     * @name Description
     * @description 
     * The description for this process status.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ProcessType
     * @description 
     * The process type for this status.  This will frequently be 'Job' or 'Server' but can be other types.
     * @servertype String
     * @type {string}
     */
    ProcessType: string = "";

    /**
     * @name ProcessTypeId
     * @description 
     * The process type id is the key of the process type for this process.  For example, if the process type is Job this value
     * would be the JobId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTypeId: number = null;

    /**
     * @name ProcessTypeId2
     * @description 
     * The process type id 2 is the key of the process type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    ProcessTypeId2: string = null;

    /**
     * @name SessionId
     * @description 
     * The session id identifies the process type session.  For jobs this is the start count.
     * @servertype String
     * @type {string}
     */
    SessionId: string = "";

    /**
     * @name StatusIntervalMinutes
     * @description 
     * The number of minutes after which a process will spin off a new process status record.  A value of 0 means never.  Most non-real-time
     * jobs will have a value of 0 and an incrementing SessionId value which results in one process status record for each execution
     * of the job.  Most real-time jobs or other processes will a value that will cause process status information to be recorded
     * for given time intervals.  For example, a value of 60 would result in a new process status record being generated every hour
     * on the hour.  Settings other than zero are not moving windows but are fixed windows based on the start of the month, week,
     * day or hour depending on if the value is in range for a month, week, day, or hour.  Examples: When set to 60, stats are for
     * the hour (e.g. 08:00:00).  When set to 15, stats are for the 15 minute window (e.g. 12:15:00).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatusIntervalMinutes: number = 0;

    /**
     * @name ServerName
     * @description 
     * The name of the server running this process.
     * @servertype String
     * @type {string}
     */
    ServerName: string = "";

    /**
     * @name ServerProcessId
     * @description 
     * The server process id is the process identifier assigned by the server.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ServerProcessId: number = 0;

    /**
     * @name ProcessCount
     * @description 
     * Process count represents the portion of ProcessTotal that has been processed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessCount: number = 0;

    /**
     * @name ProcessTotal
     * @description 
     * Process total represents the total number of items to be processed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTotal: number = 0;

    /**
     * @name ProcessTime
     * @description 
     * Number of seconds this process has been actively processing data.  This is different than simply the number of seconds from
     * the start time until now since there could be long periods of inactivity while waiting for data to process.  This number
     * more accurately represents the actual processing time that is involved and when combined with ProcessCount can be used to
     * calculate speed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTime: number = 0;

    /**
     * @name StartDateTime
     * @description 
     * The process status start date and time.  See status interval minutes for more information.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name LastUpdateDateTime
     * @description 
     * The date and time when the process status was last updated.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUpdateDateTime: Date = null;

    /**
     * @name DataProcessedDateTime
     * @description 
     * The date and time of the last time data was processed by the process.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DataProcessedDateTime: Date = null;

    /**
     * @name FinishDateTime
     * @description 
     * The process status finish date and time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FinishDateTime: Date = null;

    /**
     * @name CancelNow
     * @description 
     * When true the process has been requested to cancel itself.  It's up to the actual process to handle this request.
     * @servertype Boolean
     * @type {boolean}
     */
    CancelNow: boolean = false;

    /**
     * @name Status
     * @description 
     * Status of the process.  Possible values include: 
     * R = Running
     * C = Cancelled
     * F = Finished
     * M = Moved to new Process Status table and/or row
     * S = Suspect (was running but not updated in a timely manner)
     * U = Unknown
     * N = N/A
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name ProcessMemoryUsedKb
     * @description 
     * Amount of memory used by this process (expressed in KB).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ProcessMemoryUsedKb: number = 0;

    /**
     * @name ServerMemoryFreeKb
     * @description 
     * Amount of memory free on the server (expressed in KB).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ServerMemoryFreeKb: number = 0;

    /**
     * @name ServerMemoryTotalKb
     * @description 
     * Total amount of memory on the server (expressed in KB).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ServerMemoryTotalKb: number = 0;

    /**
     * @name ErrorCount
     * @description 
     * Count of errors.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ErrorCount: number = 0;

    /**
     * @name ErrorMessage
     * @description 
     * Error Message (typically only the most recent).
     * @servertype String
     * @type {string}
     */
    ErrorMessage: string = "";

    /**
     * @name WarningCount
     * @description 
     * Count of warnings.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WarningCount: number = 0;

    /**
     * @name WarningMessage
     * @description 
     * Warning Message (typically only the most recent).
     * @servertype String
     * @type {string}
     */
    WarningMessage: string = "";

    /**
     * @name OmittedCount
     * @description 
     * Count of omissions.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OmittedCount: number = 0;

    /**
     * @name OmittedMessage
     * @description 
     * Omitted Message (typically only the most recent).
     * @servertype String
     * @type {string}
     */
    OmittedMessage: string = "";

    /**
     * @name AlarmCount
     * @description 
     * Count of alarms.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AlarmCount: number = 0;

    /**
     * @name AlarmMessage
     * @description 
     * Alarm Message (typically only the most recent).
     * @servertype String
     * @type {string}
     */
    AlarmMessage: string = "";

    /**
     * @name Feedback
     * @description 
     * Feedback from process (typically only the most recent).
     * @servertype String
     * @type {string}
     */
    Feedback: string = "";

    /**
     * @name ResultCode
     * @description 
     * Result code for completed processes.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @description 
     * Result message for completed processes.
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name TimerCount
     * @description 
     * The number of timed events available.  Typically the same as the process count.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerCount: number = 0;

    /**
     * @name TimerTotalMilliseconds
     * @description 
     * The total number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerTotalMilliseconds: number = 0;

    /**
     * @name TimerMaximumMilliseconds
     * @description 
     * The maximum number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMaximumMilliseconds: number = 0;

    /**
     * @name TimerMinimumMilliseconds
     * @description 
     * The minimum number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMinimumMilliseconds: number = 0;

    /**
     * @name TimerAverageMilliseconds
     * @description 
     * The average number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerAverageMilliseconds: number = 0;

    /**
     * @name TimerMedianMilliseconds
     * @description 
     * The median number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerMedianMilliseconds: number = 0;

    /**
     * @name TimerPercentile90Milliseconds
     * @description 
     * The 90th percentile number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerPercentile90Milliseconds: number = 0;

    /**
     * @name TimerStandardDeviationMilliseconds
     * @description 
     * The standard deviation number of milliseconds for timed events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimerStandardDeviationMilliseconds: number = 0;

    /**
     * @name TimerEventsMilliseconds
     * @description 
     * A CSV of timer events in milliseconds.  This is the data source for other timer information provided here.
     * @servertype String
     * @type {string}
     */
    TimerEventsMilliseconds: string = "";

    /**
     * @name TimerStatsAsOfDateTime
     * @description 
     * The date and time the timer stats were last updated.  For performance reasons these stats are not updated real-time and if
     * this is earlier than the last update date time value the timer stats may be stale.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TimerStatsAsOfDateTime: Date = null;

    /**
     * @name Details
     * @servertype IProcessStatusDetailModel array
     * @type {m.IProcessStatusDetailModel[]}
     */
    Details: m.IProcessStatusDetailModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name QueryEventFileDeliveryViewModel
 */
export class QueryEventFileDeliveryViewModel {

    /**
     * @name QueryEventFileDeliveryId
     * @servertype String
     * @type {string}
     */
    QueryEventFileDeliveryId: string = "";

    /**
     * @name QueryEventFileId
     * @servertype String
     * @type {string}
     */
    QueryEventFileId: string = "";

    /**
     * @name QueryEventId
     * @servertype String
     * @type {string}
     */
    QueryEventId: string = "";

    /**
     * @name EventDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EventDateTime: Date = null;

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name FileServerId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FileServerId: number = 0;

    /**
     * @name DeliveryDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DeliveryDateTime: Date = null;

    /**
     * @name DeliveryType
     * @servertype String
     * @type {string}
     */
    DeliveryType: string = "";

    /**
     * @name DeliveryDetail
     * @servertype String
     * @type {string}
     */
    DeliveryDetail: string = "";

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name ResultCode
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name Trace
     * @servertype String
     * @type {string}
     */
    Trace: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name QueryEventFileViewModel
 */
export class QueryEventFileViewModel {

    /**
     * @name QueryEventFileId
     * @servertype String
     * @type {string}
     */
    QueryEventFileId: string = "";

    /**
     * @name QueryEventId
     * @servertype String
     * @type {string}
     */
    QueryEventId: string = "";

    /**
     * @name EventDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EventDateTime: Date = null;

    /**
     * @name Tag
     * @servertype String
     * @type {string}
     */
    Tag: string = "";

    /**
     * @name QueryOutputConfigurationId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    QueryOutputConfigurationId: number = 0;

    /**
     * @name FileRole
     * @servertype String
     * @type {string}
     */
    FileRole: string = "";

    /**
     * @name HasFilePerPage
     * @servertype Boolean
     * @type {boolean}
     */
    HasFilePerPage: boolean = false;

    /**
     * @name FriendlyName
     * @servertype String
     * @type {string}
     */
    FriendlyName: string = "";

    /**
     * @name FileType
     * @servertype String
     * @type {string}
     */
    FileType: string = "";

    /**
     * @name ContentType
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name SizeBytes
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SizeBytes: number = 0;

    /**
     * @name SizePages
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SizePages: number = 0;

    /**
     * @name SizeRows
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SizeRows: number = 0;

    /**
     * @name HitCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    HitCount: number = 0;

    /**
     * @name LastHitDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastHitDateTime: Date = null;

    /**
     * @name Properties
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name ResultCode
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name Trace
     * @servertype String
     * @type {string}
     */
    Trace: string = "";

    /**
     * @name Deliveries
     * @servertype QueryEventFileDeliveryViewModel array
     * @type {QueryEventFileDeliveryViewModel[]}
     */
    Deliveries: QueryEventFileDeliveryViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name QueryEventViewModel
 */
export class QueryEventViewModel {

    /**
     * @name QueryEventId
     * @servertype String
     * @type {string}
     */
    QueryEventId: string = "";

    /**
     * @name EventDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EventDateTime: Date = null;

    /**
     * @name Tag
     * @servertype String
     * @type {string}
     */
    Tag: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RequestedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RequestedByContactId: number = null;

    /**
     * @name RequestedByContactName
     * @servertype String
     * @type {string}
     */
    RequestedByContactName: string = "";

    /**
     * @name DataSourceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DataSourceId: number = 0;

    /**
     * @name QueryId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    QueryId: number = 0;

    /**
     * @name ReportId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportId: number = 0;

    /**
     * @name ReportTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportTemplateId: number = 0;

    /**
     * @name JobId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    JobId: number = 0;

    /**
     * @name ExpirationDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpirationDateTime: Date = null;

    /**
     * @name CanDownloadAuthenticated
     * @servertype Boolean
     * @type {boolean}
     */
    CanDownloadAuthenticated: boolean = false;

    /**
     * @name CanDownloadAnonymous
     * @servertype Boolean
     * @type {boolean}
     */
    CanDownloadAnonymous: boolean = false;

    /**
     * @name LogAccess
     * @servertype Boolean
     * @type {boolean}
     */
    LogAccess: boolean = false;

    /**
     * @name IsDateRangeBasedDataFeed
     * @servertype Boolean
     * @type {boolean}
     */
    IsDateRangeBasedDataFeed: boolean = false;

    /**
     * @name AsOfDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AsOfDateTime: Date = null;

    /**
     * @name BeginningDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    BeginningDateTime: Date = null;

    /**
     * @name EndingDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EndingDateTime: Date = null;

    /**
     * @name TimeZoneId
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = "";

    /**
     * @name TimeZoneName
     * @servertype String
     * @type {string}
     */
    TimeZoneName: string = "";

    /**
     * @name ServerAsOfDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ServerAsOfDateTime: Date = null;

    /**
     * @name ServerBeginningDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ServerBeginningDateTime: Date = null;

    /**
     * @name ServerEndingDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ServerEndingDateTime: Date = null;

    /**
     * @name Properties
     * @servertype IB.Web.API.Models.v5.QueryEventPropertiesViewModel
     * @type {QueryEventPropertiesViewModel}
     */
    Properties: QueryEventPropertiesViewModel = null;

    /**
     * @name PercentDone
     * @servertype Byte (nullable)
     * @type {number}
     */
    PercentDone: number = 0;

    /**
     * @name Phase
     * @servertype String
     * @type {string}
     */
    Phase: string = "";

    /**
     * @name RunningApplicationServer
     * @servertype String
     * @type {string}
     */
    RunningApplicationServer: string = "";

    /**
     * @name RunningPingDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RunningPingDateTime: Date = null;

    /**
     * @name ResultCode
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name Trace
     * @servertype String
     * @type {string}
     */
    Trace: string = "";

    /**
     * @name Files
     * @servertype QueryEventFileViewModel array
     * @type {QueryEventFileViewModel[]}
     */
    Files: QueryEventFileViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name QueryEventPropertiesViewModel
 */
export class QueryEventPropertiesViewModel {

    /**
     * @name Request
     * @servertype IB.Web.API.Models.v5.QueryExecutionRequestOptionsViewModel
     * @type {QueryExecutionRequestOptionsViewModel}
     */
    Request: QueryExecutionRequestOptionsViewModel = null;

    /**
     * @name Information
     * @servertype IB.Web.API.Models.v5.QueryEventInformationViewModel
     * @type {QueryEventInformationViewModel}
     */
    Information: QueryEventInformationViewModel = null;

    /**
     * @name Variables
     * @servertype System.Object
     * @type {any}
     */
    Variables: any = {};

    /**
     * @name Results
     * @servertype System.Object
     * @type {any}
     */
    Results: any = {};

}



/**
 * @name QueryEventInformationViewModel
 */
export class QueryEventInformationViewModel {

    /**
     * @name QueryDescription
     * @servertype String
     * @type {string}
     */
    QueryDescription: string = "";

    /**
     * @name FilterDescription
     * @servertype String
     * @type {string}
     */
    FilterDescription: string = "";

    /**
     * @name DataSourceDescription
     * @servertype String
     * @type {string}
     */
    DataSourceDescription: string = "";

    /**
     * @name OutputConfigurationDescription
     * @servertype String
     * @type {string}
     */
    OutputConfigurationDescription: string = "";

    /**
     * @name ReportTemplateDescription
     * @servertype String
     * @type {string}
     */
    ReportTemplateDescription: string = "";

    /**
     * @name JobDescription
     * @servertype String
     * @type {string}
     */
    JobDescription: string = "";

    /**
     * @name IncludesRawData
     * @servertype Boolean
     * @type {boolean}
     */
    IncludesRawData: boolean = false;

    /**
     * @name IncludesPageViewImages
     * @servertype Boolean
     * @type {boolean}
     */
    IncludesPageViewImages: boolean = false;

}



/**
 * @name QueryEventStatus Enum
 * @readonly
 * @enum {number}
 */
export enum QueryEventStatus {

    /**
     * Success
     */
    Success = 0,

    /**
     * Error
     */
    Error = 2147483647,

    /**
     * Pending
     */
    Pending = -99,

    /**
     * Started
     */
    Started = -98,

    /**
     * Querying
     */
    Querying = -97,

    /**
     * Exporting
     */
    Exporting = -96,

    /**
     * Delivering
     */
    Delivering = -95

}



/**
 * @name WatchEventListViewModel
 * @description
 * The Watch Event table contains information related to items being watched.  This is a log table to provide quick access for
 * a contact to see changes in items they are watching and, therefore, is not fully normalized.  A table is created for each
 * calendar month using the template WatchEventYYYYMM as table name.
 */
export class WatchEventListViewModel {

    /**
     * @name WatchEventId
     * @description 
     * When combined with the table name this is a unique value that represents this watch event record.
     * @servertype String
     * @type {string}
     */
    WatchEventId: string = null;

    /**
     * @name EventDateTime
     * @description 
     * Date and time when the watched event occurred.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EventDateTime: Date = new Date();

    /**
     * @name ContactId
     * @description 
     * Contact Id of the contact that is watching this event.  This may be null for system watch events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name WatchId
     * @description 
     * Watch Id of the watch configuration for this event.  This may be null for system watch events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WatchId: number = null;

    /**
     * @name AuditTrailId
     * @description 
     * When available this points to the audit trail record related to this watch event.
     * @servertype String
     * @type {string}
     */
    AuditTrailId: string = null;

    /**
     * @name NotificationEventId
     * @description 
     * When available this points to the notification event related to this watch event.
     * @servertype String
     * @type {string}
     */
    NotificationEventId: string = null;

    /**
     * @name NotificationId
     * @description 
     * When available this points to one of the notifications related to this watch event (there may be more than one depending
     * on notification methods requested).
     * @servertype String
     * @type {string}
     */
    NotificationId: string = null;

    /**
     * @name WatchedResourceType
     * @description 
     * The watched resource type (e.g. table, etc.) for this watch event.  Note that this may also be a system process or resource
     * name for system watch events.  Supported system watch events include: 
     * ObjectStatusAnalysis
     * RatingEngine
     * Watchdog-x (where x is the machine name the watch dog is running on or * if allowable on any machine)
     * @servertype String
     * @type {string}
     */
    WatchedResourceType: string = "";

    /**
     * @name WatchedResourceId
     * @description 
     * The watched resource id is the key of the watched resource type for this watch.  For example, if the watched resource type
     * is InventoryType this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WatchedResourceId: number = 0;

    /**
     * @name WatchedResourceId2
     * @description 
     * The watched resource id 2 is the key of the watched resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    WatchedResourceId2: string = "";

    /**
     * @name Message
     * @description 
     * The message for this watch event.  To facilitate translation this should use {{macros}} as placeholders for values contained
     * in the properties.
     * @servertype String
     * @type {string}
     */
    Message: string = "";

    /**
     * @name PayloadId
     * @description 
     * An id that represents the type of payload.
     * @servertype String
     * @type {string}
     */
    PayloadId: string = "";

    /**
     * @name PayloadVersion
     * @description 
     * A version number for the payload.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PayloadVersion: number = 0;

    /**
     * @name Status
     * @description 
     * Status of this watch event.  Possible values are: 
     * P = Pending
     * D = Delivered
     * S = Success
     * F = Failure
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name DeliveredDateTime
     * @description 
     * Date and time when the watch event was delivered.  Note that some long running processes may occasionally refresh this value
     * so the event is not suspected of being orphaned.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DeliveredDateTime: Date = null;

    /**
     * @name ResponseProgressPercentDone
     * @description 
     * The current percent done (0-100) working on the response for this system watch event.
     * @servertype Byte (nullable)
     * @type {number}
     */
    ResponseProgressPercentDone: number = 0;

    /**
     * @name ResponseProgressMessage
     * @description 
     * A message about the current response progress.  Long running system watch events may periodically update this message so
     * others can know the progress.
     * @servertype String
     * @type {string}
     */
    ResponseProgressMessage: string = "";

    /**
     * @name ResponsePayloadId
     * @description 
     * An id that represents the type of response payload.
     * @servertype String
     * @type {string}
     */
    ResponsePayloadId: string = "";

    /**
     * @name ResponsePayloadVersion
     * @description 
     * A version number for the response payload.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResponsePayloadVersion: number = 0;

    /**
     * @name Flagged
     * @description 
     * When true the watch event has been flagged.
     * @servertype Boolean
     * @type {boolean}
     */
    Flagged: boolean = false;

}



/**
 * @name WatchEventEditViewModel
 * @description
 * The Watch Event table contains information related to items being watched.  This is a log table to provide quick access for
 * a contact to see changes in items they are watching and, therefore, is not fully normalized.  A table is created for each
 * calendar month using the template WatchEventYYYYMM as table name.
 */
export class WatchEventEditViewModel {

    /**
     * @name WatchEventId
     * @description 
     * When combined with the table name this is a unique value that represents this watch event record.
     * @servertype String
     * @type {string}
     */
    WatchEventId: string = null;

    /**
     * @name EventDateTime
     * @description 
     * Date and time when the watched event occurred.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EventDateTime: Date = new Date();

    /**
     * @name ContactId
     * @description 
     * Contact Id of the contact that is watching this event.  This may be null for system watch events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name WatchId
     * @description 
     * Watch Id of the watch configuration for this event.  This may be null for system watch events.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WatchId: number = null;

    /**
     * @name AuditTrailId
     * @description 
     * When available this points to the audit trail record related to this watch event.
     * @servertype String
     * @type {string}
     */
    AuditTrailId: string = null;

    /**
     * @name NotificationEventId
     * @description 
     * When available this points to the notification event related to this watch event.
     * @servertype String
     * @type {string}
     */
    NotificationEventId: string = null;

    /**
     * @name NotificationId
     * @description 
     * When available this points to one of the notifications related to this watch event (there may be more than one depending
     * on notification methods requested).
     * @servertype String
     * @type {string}
     */
    NotificationId: string = null;

    /**
     * @name WatchedResourceType
     * @description 
     * The watched resource type (e.g. table, etc.) for this watch event.  Note that this may also be a system process or resource
     * name for system watch events.  Supported system watch events include: 
     * ObjectStatusAnalysis
     * RatingEngine
     * Watchdog-x (where x is the machine name the watch dog is running on or * if allowable on any machine)
     * @servertype String
     * @type {string}
     */
    WatchedResourceType: string = "";

    /**
     * @name WatchedResourceId
     * @description 
     * The watched resource id is the key of the watched resource type for this watch.  For example, if the watched resource type
     * is InventoryType this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WatchedResourceId: number = 0;

    /**
     * @name WatchedResourceId2
     * @description 
     * The watched resource id 2 is the key of the watched resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    WatchedResourceId2: string = "";

    /**
     * @name Message
     * @description 
     * The message for this watch event.  To facilitate translation this should use {{macros}} as placeholders for values contained
     * in the properties.
     * @servertype String
     * @type {string}
     */
    Message: string = "";

    /**
     * @name Properties
     * @description 
     * A dynamic object used as the source for values in message {{macros}}.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name PayloadId
     * @description 
     * An id that represents the type of payload.
     * @servertype String
     * @type {string}
     */
    PayloadId: string = "";

    /**
     * @name PayloadVersion
     * @description 
     * A version number for the payload.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PayloadVersion: number = 0;

    /**
     * @name Payload
     * @description 
     * The payload in the format documented by the payload id and version.
     * @servertype String
     * @type {string}
     */
    Payload: string = "";

    /**
     * @name Status
     * @description 
     * Status of this watch event.  Possible values are: 
     * P = Pending
     * D = Delivered
     * S = Success
     * F = Failure
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name DeliveredServiceList
     * @description 
     * For system watch events that need to be delivered to multiple services this is a comma delimited list of unique service identifiers
     * that have received the event and is used to help determine if a given service still needs the event delivered.
     * @servertype string array
     * @type {string[]}
     */
    DeliveredServiceList: string[] = [];

    /**
     * @name DeliveredDateTime
     * @description 
     * Date and time when the watch event was delivered.  Note that some long running processes may occasionally refresh this value
     * so the event is not suspected of being orphaned.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DeliveredDateTime: Date = null;

    /**
     * @name ResponseProgressPercentDone
     * @description 
     * The current percent done (0-100) working on the response for this system watch event.
     * @servertype Byte (nullable)
     * @type {number}
     */
    ResponseProgressPercentDone: number = 0;

    /**
     * @name ResponseProgressMessage
     * @description 
     * A message about the current response progress.  Long running system watch events may periodically update this message so
     * others can know the progress.
     * @servertype String
     * @type {string}
     */
    ResponseProgressMessage: string = "";

    /**
     * @name ResponsePayloadId
     * @description 
     * An id that represents the type of response payload.
     * @servertype String
     * @type {string}
     */
    ResponsePayloadId: string = "";

    /**
     * @name ResponsePayloadVersion
     * @description 
     * A version number for the response payload.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResponsePayloadVersion: number = 0;

    /**
     * @name ResponsePayload
     * @description 
     * The response payload in the format documented by the response payload id and version.
     * @servertype String
     * @type {string}
     */
    ResponsePayload: string = "";

    /**
     * @name Flagged
     * @description 
     * When true the watch event has been flagged.
     * @servertype Boolean
     * @type {boolean}
     */
    Flagged: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name WatchEventSystemAddViewModel
 * @description
 * The Watch Event table contains information related to items being watched.  This is a log table to provide quick access for
 * a contact to see changes in items they are watching and, therefore, is not fully normalized.  A table is created for each
 * calendar month using the template WatchEventYYYYMM as table name.
 */
export class WatchEventSystemAddViewModel {

    /**
     * @name WatchedResourceType
     * @description 
     * The watched resource type (e.g. table, etc.) for this watch event.  Note that this may also be a system process or resource
     * name for system watch events.  Supported system watch events include: 
     * ObjectStatusAnalysis
     * RatingEngine
     * Watchdog-x (where x is the machine name the watch dog is running on or * if allowable on any machine)
     * @servertype String
     * @type {string}
     */
    WatchedResourceType: string = "";

    /**
     * @name PayloadId
     * @description 
     * An id that represents the type of payload.
     * @servertype String
     * @type {string}
     */
    PayloadId: string = "";

    /**
     * @name PayloadVersion
     * @description 
     * A version number for the payload.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PayloadVersion: number = 0;

    /**
     * @name Payload
     * @description 
     * The payload in the format documented by the payload id and version.
     * @servertype System.Object
     * @type {any}
     */
    Payload: any = {};

}



/**
 * @name ActionLinkEditViewModel
 * @description
 * The action link table contains a key that can be sent via link, SMS, or other to authorize an action.  For example: a link
 * that can be used to change a password or a code that can be sent via SMS to authenticate a user, etc.  A table is created
 * for each calendar month using the template ActionLinkYYYYMM as table name.  Since action links have a short life-time the
 * current month is most often the only table needed for lookup unless it's the beginning of the month in which case the previous
 * month may need to be searched.
 */
export class ActionLinkEditViewModel {

    /**
     * @name ActionLinkId
     * @description 
     * The id of the action link.
     * @servertype String
     * @type {string}
     */
    ActionLinkId: string = null;

    /**
     * @name TagType
     * @description 
     * The type of this action link row.  Possible values include: 
     * L = Link - short random string used for user action typically delivered via email.
     * C = Code - short numeric code for user authentication typically delivered via SMS.
     * @servertype String
     * @type {string}
     */
    TagType: string = "L";

    /**
     * @name TagLength
     * @description 
     * The length of the tag for this action link.  If not specified, defaults to 6 for tag type of code (C) and defaults to 15
     * for tag type of link (L).
     * @servertype Byte (nullable)
     * @type {number}
     */
    TagLength: number = 0;

    /**
     * @name Tag
     * @description 
     * When type is code (C) this is a short numeric code for user authentication.  When type is link (L) this is a short random
     * string combined with the add date's month and year along with ActionLinkId to create a action link tag that is used to identify
     * this row quickly (via id) with a random component (by verifying the tag once found via id).
     * @servertype String
     * @type {string}
     */
    Tag: string = "";

    /**
     * @name Role
     * @description 
     * The role of this action link row.  Possible values include: 
     * V = Verification (verifies the user at least has possession of the delivery mechanism)
     * P = Password Reset (allows password reset)
     * L = Login (provides link based login)
     * ? = Custom (custom role not otherwise defined)
     * C = Click Event (allows short url click tracking)
     * O = Open Event (allows notification open tracking)
     * Typically a notification will contain one open event tied to the URL of an image.  Since some email clients suppress image
     * download any click event on the notification will also flag the notification as being opened since it must be opened to be
     * clicked.
     * @servertype String
     * @type {string}
     */
    Role: string = "?";

    /**
     * @name Url
     * @description 
     * When role is click event (C) this is the url that the short url is redirected to.  For other roles this url may be the base
     * url which the action link id is appended to.
     * @servertype String
     * @type {string}
     */
    Url: string = "";

    /**
     * @name LinkType
     * @description 
     * For click events this value provides insight on the type of link being clicked so we can report on what types of links are
     * getting clicked (e.g. web site, map, facebook, twitter, etc.) for link types that have subtypes use colons to separate those
     * types (e.g. social:facebook, social:twitter, etc.) then reporting can be done on types (e.g. social clicks as well as facebook
     * and twitter clicks).
     * @servertype String
     * @type {string}
     */
    LinkType: string = "";

    /**
     * @name Value001
     * @description 
     * Customizable meta data about the action link.
     * @servertype String
     * @type {string}
     */
    Value001: string = "";

    /**
     * @name Value002
     * @description 
     * Customizable meta data about the action link.
     * @servertype String
     * @type {string}
     */
    Value002: string = "";

    /**
     * @name Value003
     * @description 
     * Customizable meta data about the action link.
     * @servertype String
     * @type {string}
     */
    Value003: string = "";

    /**
     * @name Value004
     * @description 
     * Customizable meta data about the action link.
     * @servertype String
     * @type {string}
     */
    Value004: string = "";

    /**
     * @name Value005
     * @description 
     * Customizable meta data about the action link.
     * @servertype String
     * @type {string}
     */
    Value005: string = "";

    /**
     * @name Properties
     * @description 
     * Properties related to this action link.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name NotificationTriggeredDateTime
     * @description 
     * The date and time when the notification for this action was triggered.  This helps identify the notification table (yyyymm
     * format) where NotificationId is found.  This does not represent notification delivery.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NotificationTriggeredDateTime: Date = null;

    /**
     * @name NotificationId
     * @description 
     * The notification id that represents the delivery of this information or for click events the notification that the click
     * is found in.  This is found in the NotificationYYYYMM table for NotificationTriggeredDateTime.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationId: number = null;

    /**
     * @name DeliveryMethod
     * @description 
     * The delivery method for this action link.  Possible values include: 
     * E = Email
     * S = SMS
     * P = Phone
     * @servertype String
     * @type {string}
     */
    DeliveryMethod: string = "E";

    /**
     * @name ContactId
     * @description 
     * The contact id that this action link is intended for.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name AuthenticationRequired
     * @description 
     * When true the user must be authenticated before the action can proceed.
     * @servertype Boolean
     * @type {boolean}
     */
    AuthenticationRequired: boolean = false;

    /**
     * @name AnonymousAccessApiKey
     * @servertype String
     * @type {string}
     */
    AnonymousAccessApiKey: string = "";

    /**
     * @name RegardingResourceType
     * @description 
     * The resource type (e.g. table, etc.) this action link is regarding (if any).
     * @servertype String
     * @type {string}
     */
    RegardingResourceType: string = "";

    /**
     * @name RegardingResourceId
     * @description 
     * The resource id is the key of the resource type this action link is regarding (if any).  For example, if the regarding resource
     * type is InventoryType this value would be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RegardingResourceId: number = 0;

    /**
     * @name RegardingResourceId2
     * @description 
     * The resource id 2 is the key of the resource type this action link is regarding (if any) when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    RegardingResourceId2: string = "";

    /**
     * @name NotificationGroupId
     * @description 
     * Notification Group Id to notify when this action occurs.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = null;

    /**
     * @name CaseTemplateId
     * @description 
     * Case Template Id to use for creating case when this action occurs.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = null;

    /**
     * @name TriggerAssetId
     * @description 
     * The Asset Id to use for trigger to execute when this action occurs.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggerAssetId: number = null;

    /**
     * @name FormId
     * @description 
     * The Form Id of the form to route to when this action occurs.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FormId: number = null;

    /**
     * @name ValidMinutes
     * @description 
     * The number of minutes this action link is valid for.  This is N/A for click events.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValidMinutes: number = 0;

    /**
     * @name ValidCount
     * @description 
     * The number of times the action link can be accessed.  This is N/A for click events.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ValidCount: number = 0;

    /**
     * @name ActionCount
     * @description 
     * The number of times the action was performed.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ActionCount: number = 0;

    /**
     * @name ActionDoneDateTime
     * @description 
     * The date and time when the action was done.  If an action is allowed to be performed more than once like click events this
     * is the first action date time.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActionDoneDateTime: Date = null;

    /**
     * @name ActionDoneInformation
     * @description 
     * Any information available about the action being done.  This might be the originating ip address for a link action type,
     * the SMS number for a code action type, etc.
     * @servertype String
     * @type {string}
     */
    ActionDoneInformation: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name AlarmRuleNotificationEditViewModel
 * @description
 * The Alarm Rule Notification table contains a list of entities that have been notified of a certain rule violation, when they
 * were notified, etc.  Provides the ability to notify someone of a certain situation without over notifying them.  For example,
 * every day at 3am we run a rule to notify customers who are within 80% of their credit limit.  Jon Doe gets an email Monday
 * morning alerting him to this fact.  We don't want him to be notified every day of the same situation.  He should only be
 * notified if the situation arises again after he has made a payment.  Our payment routine can clear the active flag in this
 * table to take care of that.
 */
export class AlarmRuleNotificationEditViewModel {

    /**
     * @name AlarmRuleId
     * @description 
     * Alarm Rule Id uniquely identifies this alarm rule.
     * @servertype Int64
     * @type {number}
     */
    AlarmRuleId: number = null;

    /**
     * @name ContactId
     * @description 
     * Contact Id notified when alarm was violated.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name FirstNotifiedDateTime
     * @description 
     * Date and time when the contact was first notified of this alarm rule violation.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FirstNotifiedDateTime: Date = null;

    /**
     * @name LastNotifiedDateTime
     * @description 
     * Date and time when the contact was most recently notified of an alarm rule violation.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastNotifiedDateTime: Date = null;

    /**
     * @name ExpirationDateTime
     * @description 
     * Date and time when the active notification status will expire.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpirationDateTime: Date = null;

    /**
     * @name NotificationCounter
     * @description 
     * Count of how many times the contact was notified of an alarm rule violation.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationCounter: number = 0;

    /**
     * @name ActiveNotification
     * @description 
     * When active notification is true the contact will not be notified again about the same alarm rule.  This is typically cleared
     * to false by expiration or event trigger so the contact can be notified of a future rule violation.
     * @servertype Boolean
     * @type {boolean}
     */
    ActiveNotification: boolean = true;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name AlarmRuleEditViewModel
 * @description
 * The Alarm Rule table contains rules that, when violated, will result in an alarm being triggered.
 */
export class AlarmRuleEditViewModel {

    /**
     * @name AlarmRuleId
     * @description 
     * Alarm Rule Id uniquely identifies this alarm rule.
     * @servertype Int64
     * @type {number}
     */
    AlarmRuleId: number = null;

    /**
     * @name Description
     * @description 
     * Description for this alarm rule.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RuleType
     * @description 
     * The type of alarm rule.  Possible values are:
     * O = Other (The rule is processed by an external process.)
     * I = Import Usage (The rule is processed upon import of each usage record.)
     * R = Rate Usage (The rule is processed for upon rating of each usage record.)
     * S = Scheduled (The rule is processed by the application server on a schedule.)
     * @servertype String
     * @type {string}
     */
    RuleType: string = "O";

    /**
     * @name NotificationResetEventType
     * @description 
     * For any alarm rule that triggers notification to a specific entity (i.e. customer notified of low balance, etc.) the Alarm
     * Rule Notification table tracks this notification so the entity is not repeatedly notified of the same event.  A notification
     * reset event type can be used to clear the active notification flag so the contact can be notified again.  Possible values
     * are:
     * N = None  (The rule notification is not reset by an event.)
     * P = Payment  (The rule notification is reset by payment.)
     * C = Payment Method Change  (The rule notification is reset by a change in payment method information (e.g. change expiration
     * date, add new credit card, etc.)).
     * M = Maintenance  (The rule notification is reset by renewing maintenance on an inventory item.)
     * W = Warranty  (The rule notification is reset by renewing warranty on an inventory item.)
     * T = Time  (The rule notification is reset after the specified number of minutes elapses.)
     * @servertype String
     * @type {string}
     */
    NotificationResetEventType: string = "N";

    /**
     * @name NotificationResetMinutes
     * @description 
     * The number of minutes after which the notification will reset which will allow it to be re-triggered the next time the alarm
     * rule is violated.  In addition to being used for scenarios where the reset event type is time this can be used to set a firm
     * time-based reset for other reset event types.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationResetMinutes: number = 0;

    /**
     * @name NotificationGroupId
     * @description 
     * Notification Group to notify when rule is violated.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = null;

    /**
     * @name RuleFormat
     * @description 
     * The format of the alarm rule.  Possible values are:
     * Other = Other (The rule is processed by an external process which will understand the format.)
     * Sql-Filter = SQL Filter (The rule is a sql filter which when returning data means the alarm rule is violated.)
     * Sql-Query = SQL Query (The rule is a sql query which when returning one or more rows means the alarm rule is violated.  The
     * query results are passed through as the context object for the notification event.)
     * Sql-Query-Empty = SQL Query Empty (The rule is a sql query which when returning 0 rows means the alarm rule is violated.)
     * Sql-Script = SQL Script (The rule is a sql script which is entirely self contained and will trigger alarms as appropriate.)
     * Sql-Contact-List = SQL Contact List (The rule is a sql query which returns contacts the alarm rule is violated for.  The
     * following columns are supported: 
     * ContactId - the contact to notify.  Used when checking the Alarm Rule Notification table to prevent over notification of
     * a contact.  Also passed through as TriggeredForContactId for the notification event (unless a shared event) and as the ContactId
     * for the notification.
     * Message - a message to use.
     * SharedEvent - when true all contacts in the list will be notified on the same notification event.
     * TriggeredByDataSource - passed through to notification event.
     * TriggeredByDataKey - passed through to notification event.
     * TriggeredByDescription - passed through to notification event.
     * All columns are passed through as the context object for the notification event so they can be used for macro variable substitution
     * in the notification message.)
     * @servertype String
     * @type {string}
     */
    RuleFormat: string = "Other";

    /**
     * @name RuleText
     * @description 
     * The text of the alarm rule.
     * @servertype String
     * @type {string}
     */
    RuleText: string = "";

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) of this alarm rule if the rule belongs to another resource.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this alarm rule.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name Enabled
     * @description 
     * True indicates the alarm rule is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this alarm rule can be used.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this alarm rule can be used.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Trace
     * @description 
     * True indicates to save trace information with the alarm process for debugging.
     * @servertype Boolean
     * @type {boolean}
     */
    Trace: boolean = false;

    /**
     * @name AlarmExecutionCount
     * @description 
     * The number of times this alarm rule has been executed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AlarmExecutionCount: number = 0;

    /**
     * @name AlarmViolationCount
     * @description 
     * The number of times this alarm rule has been violated.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AlarmViolationCount: number = 0;

    /**
     * @name LastUsedDateTime
     * @description 
     * Date and time when the alarm rule was last executed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUsedDateTime: Date = null;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Notifications
     * @servertype AlarmRuleNotificationEditViewModel array
     * @type {AlarmRuleNotificationEditViewModel[]}
     */
    Notifications: AlarmRuleNotificationEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name NotificationContactEditViewModel
 * @description
 * The notification contact table contains information describing notification contacts.  These are records of contact information
 * used when sending notifications and are associated with entities like customers, agents, or the system.  For example, it
 * may be desired that a certain notification being sent to customers appears to come from the customer's agent.  Configuring
 * a notification contact record for an agent with information like the agent name, address, mail server, etc. will allow the
 * notification sent to the customer to appear to have originated from that customer's agent.  Multiple notification contacts
 * are support for each entity (i.e. customer, agent, or system) to be used for different notification contact points.  For
 * example, one notification may come from a service department while another notification may come from an accounting department.
 */
export class NotificationContactEditViewModel {

    /**
     * @name NotificationContactId
     * @description 
     * Notification Contact Id uniquely identifies this notification contact.
     * @servertype Int64
     * @type {number}
     */
    NotificationContactId: number = null;

    /**
     * @name ContactId
     * @description 
     * The contact id that owns this notification contact.  This is null for the system.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name OwnerContactName
     * @servertype String
     * @type {string}
     */
    OwnerContactName: string = "";

    /**
     * @name OwnerContactType
     * @servertype String
     * @type {string}
     */
    OwnerContactType: string = "";

    /**
     * @name ContactName
     * @description 
     * Contact name.
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name FirstName
     * @description 
     * First name.
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name MiddleName
     * @description 
     * Middle name.
     * @servertype String
     * @type {string}
     */
    MiddleName: string = "";

    /**
     * @name LastName
     * @description 
     * Last name.
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

    /**
     * @name OtherName
     * @description 
     * Other name.
     * @servertype String
     * @type {string}
     */
    OtherName: string = "";

    /**
     * @name Title
     * @description 
     * Title.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Address1
     * @description 
     * Address 1.
     * @servertype String
     * @type {string}
     */
    Address1: string = "";

    /**
     * @name Address2
     * @description 
     * Address 2.
     * @servertype String
     * @type {string}
     */
    Address2: string = "";

    /**
     * @name Address3
     * @description 
     * Address 3.
     * @servertype String
     * @type {string}
     */
    Address3: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name PostalCode
     * @description 
     * Postal Code.
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name Country
     * @description 
     * Country.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name Phone
     * @description 
     * Phone.
     * @servertype String
     * @type {string}
     */
    Phone: string = "";

    /**
     * @name AlternatePhone
     * @description 
     * Alternate Phone.
     * @servertype String
     * @type {string}
     */
    AlternatePhone: string = "";

    /**
     * @name Fax
     * @description 
     * Fax.
     * @servertype String
     * @type {string}
     */
    Fax: string = "";

    /**
     * @name Cellular
     * @description 
     * Cellular.
     * @servertype String
     * @type {string}
     */
    Cellular: string = "";

    /**
     * @name Email
     * @description 
     * Email address.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name AlternateEmail
     * @description 
     * Alternate Email address.
     * @servertype String
     * @type {string}
     */
    AlternateEmail: string = "";

    /**
     * @name ImAddress
     * @description 
     * Instant Message Address.
     * @servertype String
     * @type {string}
     */
    ImAddress: string = "";

    /**
     * @name ImNetwork
     * @description 
     * An identifier for which network IM_ADDRESS is valid for.  Possible values include:
     * M = Microsoft
     * A = AOL
     * Y = Yahoo
     * G = Google
     * I = ICQ
     * O = Other
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    ImNetwork: string = "U";

    /**
     * @name AlternateImAddress
     * @description 
     * Alternate Instant Message Address.
     * @servertype String
     * @type {string}
     */
    AlternateImAddress: string = "";

    /**
     * @name AlternateImNetwork
     * @description 
     * An identifier for which network ALTERNATE_IM_ADDRESS is valid for.  Possible values include:
     * M = Microsoft
     * A = AOL
     * Y = Yahoo
     * G = Google
     * I = ICQ
     * O = Other
     * U = unknown
     * @servertype String
     * @type {string}
     */
    AlternateImNetwork: string = "U";

    /**
     * @name WebSite
     * @description 
     * Web site.
     * @servertype String
     * @type {string}
     */
    WebSite: string = "";

    /**
     * @name FtpSite
     * @description 
     * FTP site.
     * @servertype String
     * @type {string}
     */
    FtpSite: string = "";

    /**
     * @name NotificationContactType
     * @description 
     * The notification contact type for this record.  This allows us to know what type of notification contact this record is for.
     *  For example: SALES, SUPPORT, ACCOUNTING, etc.
     * @servertype String
     * @type {string}
     */
    NotificationContactType: string = "";

    /**
     * @name DefaultNotificationContact
     * @description 
     * True indicates this is the default notification contact for the specified contact id.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultNotificationContact: boolean = false;

    /**
     * @name SmtpServer
     * @description 
     * SMTP server for sending email.
     * @servertype String
     * @type {string}
     */
    SmtpServer: string = "";

    /**
     * @name SmtpServerPort
     * @description 
     * SMTP server port number for sending email.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    SmtpServerPort: number = 25;

    /**
     * @name SmtpTimeoutSeconds
     * @description 
     * The number of seconds before SMTP connections to this server will timeout.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    SmtpTimeoutSeconds: number = 120;

    /**
     * @name SmtpUseSsl
     * @description 
     * True indicates use SSL for connections to SMTP server.
     * @servertype Boolean
     * @type {boolean}
     */
    SmtpUseSsl: boolean = false;

    /**
     * @name SmtpAuthenticate
     * @description 
     * True indicates SMTP server requires authentication.
     * @servertype Boolean
     * @type {boolean}
     */
    SmtpAuthenticate: boolean = false;

    /**
     * @name SmtpUserName
     * @description 
     * SMTP user name for server authentication.
     * @servertype String
     * @type {string}
     */
    SmtpUserName: string = "";

    /**
     * @name SmtpPassword
     * @description 
     * SMTP password for server authentication.
     * @servertype String
     * @type {string}
     */
    SmtpPassword: string = "";

    /**
     * @name SmtpReplyToName
     * @description 
     * SMTP reply to name for sending email.
     * @servertype String
     * @type {string}
     */
    SmtpReplyToName: string = "";

    /**
     * @name SmtpReplyToAddress
     * @description 
     * SMTP reply to address for sending email.
     * @servertype String
     * @type {string}
     */
    SmtpReplyToAddress: string = "";

    /**
     * @name SmtpServer2
     * @description 
     * SMTP server for sending email.
     * @servertype String
     * @type {string}
     */
    SmtpServer2: string = "";

    /**
     * @name SmtpServerPort2
     * @description 
     * SMTP server port number for sending email.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    SmtpServerPort2: number = 25;

    /**
     * @name SmtpTimeoutSeconds2
     * @description 
     * The number of seconds before SMTP connections to this server will timeout.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    SmtpTimeoutSeconds2: number = 120;

    /**
     * @name SmtpUseSsl2
     * @description 
     * True indicates use SSL for connections to SMTP server.
     * @servertype Boolean
     * @type {boolean}
     */
    SmtpUseSsl2: boolean = false;

    /**
     * @name SmtpAuthenticate2
     * @description 
     * True indicates SMTP server requires authentication.
     * @servertype Boolean
     * @type {boolean}
     */
    SmtpAuthenticate2: boolean = false;

    /**
     * @name SmtpUserName2
     * @description 
     * SMTP user name for server authentication.
     * @servertype String
     * @type {string}
     */
    SmtpUserName2: string = "";

    /**
     * @name SmtpPassword2
     * @description 
     * SMTP password for server authentication.
     * @servertype String
     * @type {string}
     */
    SmtpPassword2: string = "";

    /**
     * @name SmtpReplyToName2
     * @description 
     * SMTP reply to name for sending email.
     * @servertype String
     * @type {string}
     */
    SmtpReplyToName2: string = "";

    /**
     * @name SmtpReplyToAddress2
     * @description 
     * SMTP reply to address for sending email.
     * @servertype String
     * @type {string}
     */
    SmtpReplyToAddress2: string = "";

    /**
     * @name SmsGateway
     * @description 
     * SMS gateway for sending sms messages.
     * @servertype String
     * @type {string}
     */
    SmsGateway: string = "";

    /**
     * @name SmsTimeoutSeconds
     * @description 
     * The number of seconds before SMS connections to this gateway will timeout.  Zero means no timeout.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    SmsTimeoutSeconds: number = 0;

    /**
     * @name SmsInterface
     * @description 
     * SMS interface for sending sms messages.
     * @servertype String
     * @type {string}
     */
    SmsInterface: string = "";

    /**
     * @name SmsParameters
     * @description 
     * SMS parameters for sending sms messages.
     * @servertype String
     * @type {string}
     */
    SmsParameters: string = "";

    /**
     * @name SmsUserName
     * @description 
     * SMS user name for authentication.
     * @servertype String
     * @type {string}
     */
    SmsUserName: string = "";

    /**
     * @name SmsPassword
     * @description 
     * SMS password for authentication.
     * @servertype String
     * @type {string}
     */
    SmsPassword: string = "";

    /**
     * @name SmsFromName
     * @description 
     * SMS from name for sending sms messages.
     * @servertype String
     * @type {string}
     */
    SmsFromName: string = "";

    /**
     * @name SmsFromAddress
     * @description 
     * SMS from address for sending sms messages.
     * @servertype String
     * @type {string}
     */
    SmsFromAddress: string = "";

    /**
     * @name SmsGateway2
     * @description 
     * SMS gateway for sending sms messages.
     * @servertype String
     * @type {string}
     */
    SmsGateway2: string = "";

    /**
     * @name SmsTimeoutSeconds2
     * @description 
     * The number of seconds before SMS connections to this gateway will timeout.  Zero means no timeout.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    SmsTimeoutSeconds2: number = 0;

    /**
     * @name SmsInterface2
     * @description 
     * SMS interface for sending sms messages.
     * @servertype String
     * @type {string}
     */
    SmsInterface2: string = "";

    /**
     * @name SmsParameters2
     * @description 
     * SMS parameters for sending sms messages.
     * @servertype String
     * @type {string}
     */
    SmsParameters2: string = "";

    /**
     * @name SmsUserName2
     * @description 
     * SMS user name for authentication.
     * @servertype String
     * @type {string}
     */
    SmsUserName2: string = "";

    /**
     * @name SmsPassword2
     * @description 
     * SMS password for authentication.
     * @servertype String
     * @type {string}
     */
    SmsPassword2: string = "";

    /**
     * @name SmsFromName2
     * @description 
     * SMS from name for sending sms messages.
     * @servertype String
     * @type {string}
     */
    SmsFromName2: string = "";

    /**
     * @name SmsFromAddress2
     * @description 
     * SMS from address for sending sms messages.
     * @servertype String
     * @type {string}
     */
    SmsFromAddress2: string = "";

    /**
     * @name FaxGateway
     * @description 
     * Fax gateway for sending fax messages.
     * @servertype String
     * @type {string}
     */
    FaxGateway: string = "";

    /**
     * @name FaxTimeoutSeconds
     * @description 
     * The number of seconds before fax connections to this gateway will timeout.  Zero means no timeout.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    FaxTimeoutSeconds: number = 0;

    /**
     * @name FaxInterface
     * @description 
     * Fax interface for sending fax messages.
     * @servertype String
     * @type {string}
     */
    FaxInterface: string = "";

    /**
     * @name FaxParameters
     * @description 
     * Fax parameters for sending fax messages.
     * @servertype String
     * @type {string}
     */
    FaxParameters: string = "";

    /**
     * @name FaxUserName
     * @description 
     * Fax user name for authentication.
     * @servertype String
     * @type {string}
     */
    FaxUserName: string = "";

    /**
     * @name FaxPassword
     * @description 
     * Fax password for authentication.
     * @servertype String
     * @type {string}
     */
    FaxPassword: string = "";

    /**
     * @name FaxFromName
     * @description 
     * Fax from name for sending fax messages.
     * @servertype String
     * @type {string}
     */
    FaxFromName: string = "";

    /**
     * @name FaxFromAddress
     * @description 
     * Fax from address for sending fax messages.
     * @servertype String
     * @type {string}
     */
    FaxFromAddress: string = "";

    /**
     * @name FaxGateway2
     * @description 
     * Fax gateway for sending fax messages.
     * @servertype String
     * @type {string}
     */
    FaxGateway2: string = "";

    /**
     * @name FaxTimeoutSeconds2
     * @description 
     * The number of seconds before fax connections to this gateway will timeout.  Zero means no timeout.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    FaxTimeoutSeconds2: number = 0;

    /**
     * @name FaxInterface2
     * @description 
     * Fax interface for sending fax messages.
     * @servertype String
     * @type {string}
     */
    FaxInterface2: string = "";

    /**
     * @name FaxParameters2
     * @description 
     * Fax parameters for sending fax messages.
     * @servertype String
     * @type {string}
     */
    FaxParameters2: string = "";

    /**
     * @name FaxUserName2
     * @description 
     * Fax user name for authentication.
     * @servertype String
     * @type {string}
     */
    FaxUserName2: string = "";

    /**
     * @name FaxPassword2
     * @description 
     * Fax password for authentication.
     * @servertype String
     * @type {string}
     */
    FaxPassword2: string = "";

    /**
     * @name FaxFromName2
     * @description 
     * Fax from name for sending fax messages.
     * @servertype String
     * @type {string}
     */
    FaxFromName2: string = "";

    /**
     * @name FaxFromAddress2
     * @description 
     * Fax from address for sending fax messages.
     * @servertype String
     * @type {string}
     */
    FaxFromAddress2: string = "";

    /**
     * @name VoiceGateway
     * @description 
     * Voice gateway for sending voice messages.
     * @servertype String
     * @type {string}
     */
    VoiceGateway: string = "";

    /**
     * @name VoiceTimeoutSeconds
     * @description 
     * The number of seconds before voice connections to this gateway will timeout.  Zero means no timeout.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    VoiceTimeoutSeconds: number = 0;

    /**
     * @name VoiceInterface
     * @description 
     * Voice interface for sending voice messages.
     * @servertype String
     * @type {string}
     */
    VoiceInterface: string = "";

    /**
     * @name VoiceParameters
     * @description 
     * Voice parameters for sending voice messages.
     * @servertype String
     * @type {string}
     */
    VoiceParameters: string = "";

    /**
     * @name VoiceUserName
     * @description 
     * Voice user name for authentication.
     * @servertype String
     * @type {string}
     */
    VoiceUserName: string = "";

    /**
     * @name VoicePassword
     * @description 
     * Voice password for authentication.
     * @servertype String
     * @type {string}
     */
    VoicePassword: string = "";

    /**
     * @name VoiceFromName
     * @description 
     * Voice from name for sending voice messages.
     * @servertype String
     * @type {string}
     */
    VoiceFromName: string = "";

    /**
     * @name VoiceFromAddress
     * @description 
     * Voice from address for sending voice messages.
     * @servertype String
     * @type {string}
     */
    VoiceFromAddress: string = "";

    /**
     * @name VoiceGateway2
     * @description 
     * Voice gateway for sending voice messages.
     * @servertype String
     * @type {string}
     */
    VoiceGateway2: string = "";

    /**
     * @name VoiceTimeoutSeconds2
     * @description 
     * The number of seconds before voice connections to this gateway will timeout.  Zero means no timeout.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    VoiceTimeoutSeconds2: number = 0;

    /**
     * @name VoiceInterface2
     * @description 
     * Voice interface for sending voice messages.
     * @servertype String
     * @type {string}
     */
    VoiceInterface2: string = "";

    /**
     * @name VoiceParameters2
     * @description 
     * Voice parameters for sending voice messages.
     * @servertype String
     * @type {string}
     */
    VoiceParameters2: string = "";

    /**
     * @name VoiceUserName2
     * @description 
     * Voice user name for authentication.
     * @servertype String
     * @type {string}
     */
    VoiceUserName2: string = "";

    /**
     * @name VoicePassword2
     * @description 
     * Voice password for authentication.
     * @servertype String
     * @type {string}
     */
    VoicePassword2: string = "";

    /**
     * @name VoiceFromName2
     * @description 
     * Voice from name for sending voice messages.
     * @servertype String
     * @type {string}
     */
    VoiceFromName2: string = "";

    /**
     * @name VoiceFromAddress2
     * @description 
     * Voice from address for sending voice messages.
     * @servertype String
     * @type {string}
     */
    VoiceFromAddress2: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name NotificationEventEditViewModel
 * @description
 * The Notification Event table contains information on notification events triggered during the YYYYMM month.
 */
export class NotificationEventEditViewModel {

    /**
     * @name NotificationEventId
     * @description 
     * Unique value that represents this notification event record.
     * @servertype String
     * @type {string}
     */
    NotificationEventId: string = null;

    /**
     * @name Description
     * @description 
     * The description of this notification.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Type
     * @description 
     * Flag that indicates the type of notification for possible filtering against the users requested notification settings.  Normal
     * notifications and monitored item updates are typically subject to opt outs while other types are not usually filtered against
     * opt out lists.  Possible values include:
     * N = Normal Notification
     * A = Alert or Alarm
     * M = Monitored Item Update
     * B = Billing Notification (Invoice, Receipt, etc.)
     * R = Report
     * S = Security Information
     * ! = Forced Delivery
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name EventTag
     * @description 
     * An event tag that can be used in combination with the event key to validate external links to the event for things like confirmation,
     * etc. to verify that a link wasn't guessed.
     * @servertype String
     * @type {string}
     */
    EventTag: string = "";

    /**
     * @name Tags
     * @description 
     * A list of tags for this notification event.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name NotificationGroupId
     * @description 
     * Notification Group Id identifies the notification group this event triggered.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = null;

    /**
     * @name FilterId
     * @description 
     * Filter Id identifies an optional filter to apply against notification group details (if notification group provided) or against
     * contacts (if no notification group provided).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FilterId: number = null;

    /**
     * @name ContactType
     * @description 
     * When no notification group is provided this is the contact type to use for notifications.
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name SeedListContactInformation
     * @description 
     * A CSV of contact information to use for a seed list for the notification event.
     * @servertype String
     * @type {string}
     */
    SeedListContactInformation: string = "";

    /**
     * @name AssetId
     * @description 
     * The asset id used for notifications text.  This is typically null for automatic notifications where the notification group
     * details define the message to be sent.  This is typically populated for notification events sent out manually where the message
     * to send is part of the event creation process.  Note that the notification may have a different message selected due to newer
     * versions or foreign language translations of a message that are selected for a given contact.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = null;

    /**
     * @name ExternalAssetId
     * @description 
     * As an alternative to specifying an asset id for the notification text an external asset id can be selected which allows for
     * substitution of notification text on a partition basis using assets that share the same external asset id.
     * @servertype String
     * @type {string}
     */
    ExternalAssetId: string = "";

    /**
     * @name NotificationContactId
     * @description 
     * The notification contact id representing the notification data source used for things like email from name, address, etc.
     *  If not specified we try to determine this at notification time.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationContactId: number = null;

    /**
     * @name NotificationContactName
     * @servertype String
     * @type {string}
     */
    NotificationContactName: string = "";

    /**
     * @name TriggeredForContactId
     * @description 
     * The contact id that this notification is intended for.  This is used for contact list scoping.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggeredForContactId: number = null;

    /**
     * @name TriggeredForContactName
     * @servertype String
     * @type {string}
     */
    TriggeredForContactName: string = "";

    /**
     * @name TriggeredByDataSource
     * @description 
     * The data source (e.g. table, etc.) that triggered this notification.
     * @servertype String
     * @type {string}
     */
    TriggeredByDataSource: string = "";

    /**
     * @name TriggeredByDataKey
     * @description 
     * The data key (e.g. primary key, etc.) that triggered this notification.
     * @servertype String
     * @type {string}
     */
    TriggeredByDataKey: string = "";

    /**
     * @name TriggeredByDescription
     * @description 
     * The description of who/what/etc. triggered this notification.
     * @servertype String
     * @type {string}
     */
    TriggeredByDescription: string = "";

    /**
     * @name Attachments
     * @description 
     * A collection of objects to use for attachments for the event notifications.  Each object could specify a physical file or
     * an asset id for the file along with a friendly name to use for the attachment name.  Stored in JSON format as follows: [
     *   {
     *      "AssetId": 44821,
     *      "FileName": "\\file-share\files\invite-2017-12-31.pdf",
     *      "FriendlyName": "Invitation.pdf"
     *   }
     * ]
     * @servertype NotificationEventAttachmentViewModel array
     * @type {NotificationEventAttachmentViewModel[]}
     */
    Attachments: NotificationEventAttachmentViewModel[] = [];

    /**
     * @name NotificationData
     * @description 
     * Data used for notification variables, etc. other than what is found in TriggeredByDataSource and TriggeredByDataKey.  
     * May include these properties: 
     * Data = A string that represents simple data for the notification.
     * Context = A dynamic object used for notification macro context.
     * TriggeredByDataSource = The TriggeredByDataSource if this needs to be set outside of the normal event creation methods (e.g.
     * when testing, etc.).
     * TriggeredByDataKey = The TriggeredByDataKey if this needs to be set outside of the normal event creation methods (e.g. when
     * testing, etc.).
     * MessageSubject = The notification message subject when forced due to lack of notification message asset or for other reason.
     * MessageBody = The notification message body when forced due to lack of notification message asset or for other reason.
     * Method = The notification method to use when forced due to lack of notification group to define this.
     * Language = The language to use.
     * To = A comma delimited list of contact information for delivery.
     * Cc = A comma delimited list of contact information to CC on delivery.
     * Bcc = A comma delimited list of contact information to BCC on delivery.
     * ConsolidateNotifications = A bit if notifications should be consolidated to a single message.
     * {
     *    "Data": "" , 
     *    "Context": { -some dynamic object used for notification macro context- },
     *    "TriggeredByDataSource": "PaymentTransaction201609" , 
     *    "TriggeredByDataKey": "132562"
     *    "MessageSubject": "Login Recovery" , 
     *    "MessageBody": "Your login: xyz" , 
     *    "Method": "E" , 
     *    "Language": "en" , 
     *    "To": [ "jon.doe@test.null" ] , 
     *    "Cc": [] , 
     *    "Bcc": [ "test1@test.null" , "test2@test.null" ] , 
     *    "ConsolidateNotifications": true , 
     * }
     * @servertype IB.Web.API.Models.v5.NotificationEventDataViewModel
     * @type {NotificationEventDataViewModel}
     */
    NotificationData: NotificationEventDataViewModel = null;

    /**
     * @name HoldDelivery
     * @description 
     * True indicates that notifications for this event are on delivery hold.  This can be used if you want to build out a notification
     * list and review it before releasing notifications for delivery.
     * @servertype Boolean
     * @type {boolean}
     */
    HoldDelivery: boolean = false;

    /**
     * @name Status
     * @description 
     * Status of this notification event list building process.  Possible values are: 
     * H = Hold (notification list is on hold)
     * P = Pending (notification list is not built yet)
     * R = Running (notification list is being built)
     * S = Success (notification list is ready to use)
     * F = Failure (notification list failed)
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name ProcessingServer
     * @description 
     * The server processing this notification request.  If a notification engine starts processing a request but never updates
     * to reflect the results there was most likely a serious failure that should have been logged on the processing server.
     * @servertype String
     * @type {string}
     */
    ProcessingServer: string = "";

    /**
     * @name ProcessingDateTime
     * @description 
     * Date and time when the notification engine started processing this notification request.  This can be used to help determine
     * if there are any notifications that should have posted success or failure but haven't and may be orphaned.  In that scenario
     * the processing server should be checked for logged messages.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessingDateTime: Date = null;

    /**
     * @name TestRun
     * @description 
     * True indicates the notification event is a test run.  The number of targets specified in the test run count will be included
     * but all notifications will be routed to the contact information specified in the test run contact information.
     * @servertype Boolean
     * @type {boolean}
     */
    TestRun: boolean = false;

    /**
     * @name TestRunCount
     * @description 
     * When a test run this is the number of notification targets to include in the test.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TestRunCount: number = 0;

    /**
     * @name TestRunContactInformation
     * @description 
     * A CSV of contact information to use for the test run instead of the contact information associated with the targets that
     * would have been used if not a test run.
     * @servertype String
     * @type {string}
     */
    TestRunContactInformation: string = "";

    /**
     * @name SplitTestingId
     * @description 
     * An identifier that is used to indicate that this is an event of a larger split test.  All events that are part of the split
     * test have the same split testing id value.  Events that are not part of a split test typically have this value empty.
     * @servertype String
     * @type {string}
     */
    SplitTestingId: string = "";

    /**
     * @name SplitNumber
     * @description 
     * An incrementing value that indicates the split number of this event.  This can be used in conjunction with SplitTestingId
     * for the various events that are part of the test.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SplitNumber: number = 0;

    /**
     * @name SplitDescription
     * @description 
     * A description of this split in a split test.  For example, this might say 'call to action top' for one event and 'call to
     * action bottom' for another event when split testing placement of a call to action message in notifications.
     * @servertype String
     * @type {string}
     */
    SplitDescription: string = "";

    /**
     * @name SplitPercent
     * @description 
     * When splitting contacts across a split test this is the percent to include in this event.
     * @servertype Byte (nullable)
     * @type {number}
     */
    SplitPercent: number = 0;

    /**
     * @name SetupSuccessCount
     * @description 
     * Count of setup successes for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SetupSuccessCount: number = 0;

    /**
     * @name SetupErrorCount
     * @description 
     * Count of setup errors for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SetupErrorCount: number = 0;

    /**
     * @name EmailSuccessCount
     * @description 
     * Count of successful emails for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    EmailSuccessCount: number = 0;

    /**
     * @name EmailErrorCount
     * @description 
     * Count of error emails for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    EmailErrorCount: number = 0;

    /**
     * @name EmailOpenedCount
     * @description 
     * Count of opened emails for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    EmailOpenedCount: number = 0;

    /**
     * @name EmailClickedCount
     * @description 
     * Count of clicked emails for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    EmailClickedCount: number = 0;

    /**
     * @name EmailCausedOptOutCount
     * @description 
     * Count of caused opt out emails for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    EmailCausedOptOutCount: number = 0;

    /**
     * @name SmsSuccessCount
     * @description 
     * Count of successful SMS for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SmsSuccessCount: number = 0;

    /**
     * @name SmsErrorCount
     * @description 
     * Count of error SMS for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SmsErrorCount: number = 0;

    /**
     * @name SmsCausedOptOutCount
     * @description 
     * Count of caused opt out SMS for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SmsCausedOptOutCount: number = 0;

    /**
     * @name PhoneSuccessCount
     * @description 
     * Count of successful phone calls for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PhoneSuccessCount: number = 0;

    /**
     * @name PhoneErrorCount
     * @description 
     * Count of error phone calls for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PhoneErrorCount: number = 0;

    /**
     * @name PhoneCausedOptOutCount
     * @description 
     * Count of caused opt out phone calls for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PhoneCausedOptOutCount: number = 0;

    /**
     * @name FaxSuccessCount
     * @description 
     * Count of successful faxes for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FaxSuccessCount: number = 0;

    /**
     * @name FaxErrorCount
     * @description 
     * Count of error faxes for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FaxErrorCount: number = 0;

    /**
     * @name FaxCausedOptOutCount
     * @description 
     * Count of caused opt out faxes for this notification event.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FaxCausedOptOutCount: number = 0;

    /**
     * @name Comments
     * @description 
     * Comments provided when notification was confirmed for notifications that require confirmation.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Trace
     * @description 
     * Any error messages plus verbose trace information populated when requested.
     * @servertype String
     * @type {string}
     */
    Trace: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name NotificationEventAttachmentViewModel
 */
export class NotificationEventAttachmentViewModel {

    /**
     * @name AssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name FileName
     * @servertype String
     * @type {string}
     */
    FileName: string = "";

    /**
     * @name FriendlyName
     * @servertype String
     * @type {string}
     */
    FriendlyName: string = "";

}



/**
 * @name NotificationEventDataViewModel
 */
export class NotificationEventDataViewModel {

    /**
     * @name Data
     * @servertype String
     * @type {string}
     */
    Data: string = "";

    /**
     * @name Context
     * @servertype System.Object
     * @type {any}
     */
    Context: any = {};

    /**
     * @name TriggeredByDataSource
     * @servertype String
     * @type {string}
     */
    TriggeredByDataSource: string = "";

    /**
     * @name TriggeredByDataKey
     * @servertype String
     * @type {string}
     */
    TriggeredByDataKey: string = "";

    /**
     * @name TriggeredByDescription
     * @servertype String
     * @type {string}
     */
    TriggeredByDescription: string = "";

    /**
     * @name MessageId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    MessageId: number = 0;

    /**
     * @name ExternalMessageId
     * @servertype String
     * @type {string}
     */
    ExternalMessageId: string = "";

    /**
     * @name MessageSubject
     * @servertype String
     * @type {string}
     */
    MessageSubject: string = "";

    /**
     * @name MessageBody
     * @servertype String
     * @type {string}
     */
    MessageBody: string = "";

    /**
     * @name Method
     * @servertype String
     * @type {string}
     */
    Method: string = "";

    /**
     * @name To
     * @servertype string array
     * @type {string[]}
     */
    To: string[] = [];

    /**
     * @name Cc
     * @servertype string array
     * @type {string[]}
     */
    Cc: string[] = [];

    /**
     * @name Bcc
     * @servertype string array
     * @type {string[]}
     */
    Bcc: string[] = [];

    /**
     * @name ConsolidateNotifications
     * @servertype Boolean
     * @type {boolean}
     */
    ConsolidateNotifications: boolean = false;

    /**
     * @name Priority
     * @servertype Int16
     * @type {number}
     */
    Priority: number = 0;

}



/**
 * @name NotificationGroupDetailEditViewModel
 * @description
 * The Notification Group Detail table contains information detailing notifications to be generated when a particular notification
 * group is activated/triggered/etc.
 */
export class NotificationGroupDetailEditViewModel {

    /**
     * @name NotificationGroupDetailId
     * @description 
     * Notification Group Detail Id uniquely identifies this notification group detail.
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupDetailId: number = null;

    /**
     * @name NotificationGroupId
     * @description 
     * Notification Group Id identifies the notification group this belongs to.
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupId: number = null;

    /**
     * @name NotificationOrder
     * @description 
     * Order dictating the order of various notification detail steps.  This is useful for alarm triggered notifications where a
     * person 1 should be notified and have a chance to respond to and confirm the alarm before it is escalated and person 2 is
     * notified.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    NotificationOrder: number = 0;

    /**
     * @name Description
     * @description 
     * Description for this notification group detail.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Method
     * @description 
     * Flag that indicates the notification method.  Possible values include:
     * E = Email
     *   = Not Specified
     * @servertype String
     * @type {string}
     */
    Method: string = "";

    /**
     * @name Type
     * @description 
     * Flag that indicates the type.  Possible values include:
     * N = Normal
     * S = Seed List
     * @servertype String
     * @type {string}
     */
    Type: string = "N";

    /**
     * @name NotificationRecipientType
     * @description 
     * Flag that indicates the notification recipient type.  Possible values include:
     * T = Triggered For Contact Id
     * C = Specified Contact Id
     * E = Entered Contact Information
     * B = Broadcast Group
     * N = Notification Group
     * @servertype String
     * @type {string}
     */
    NotificationRecipientType: string = "T";

    /**
     * @name SourceNotificationGroupId
     * @description 
     * A notification group to inherit notification group details from.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceNotificationGroupId: number = null;

    /**
     * @name ContactId
     * @description 
     * The contact id that this notification is intended for.  This is null if the notification is not intended for a specific contact.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactInformation
     * @description 
     * The email address, phone number, etc. used for notification delivery.  This can include macros if the needed information
     * is reachable via the data supplied at notification time.  For example: {{Case.Attributes.EmailAddress}}.  If the macro is
     * prefixed with 'ContactId:' the result is treated as the contact id to be notified.  For example: ContactId:{{Case.OwnerContactId}}.
     * @servertype String
     * @type {string}
     */
    ContactInformation: string = "";

    /**
     * @name ContactInformationCopied
     * @description 
     * The email address (etc?) being copied on the notification.
     * @servertype String
     * @type {string}
     */
    ContactInformationCopied: string = "";

    /**
     * @name ContactInformationBlindCopied
     * @description 
     * The email address (etc?) being blind copied on the notification.
     * @servertype String
     * @type {string}
     */
    ContactInformationBlindCopied: string = "";

    /**
     * @name ConsolidateNotifications
     * @description 
     * True indicates notifications will attempt to be consolidated.  For example, sending notifications to group of email users
     * instead of each user separately.  Not all methods support consolidation and for methods that do support consolidation error
     * reporting, tracking of opens, clicks, etc. can be inhibited since multiple users got the same notification.
     * @servertype Boolean
     * @type {boolean}
     */
    ConsolidateNotifications: boolean = false;

    /**
     * @name BroadcastGroup
     * @description 
     * A group to broadcast notification to.  Possible values include: 
     * N = None
     * D = Directory Entries
     * C1 = Customers
     * LINKED-CLOSE = Contacts closely linked to the data source and key
     * LINKED-ALL = Contacts any way linked to the data source and key
     * WATCHERS = Contacts watching the data source and key
     * @servertype String
     * @type {string}
     */
    BroadcastGroup: string = "N";

    /**
     * @name ContactAddressType
     * @description 
     * The contact address type to use for notification.
     * @servertype String
     * @type {string}
     */
    ContactAddressType: string = "";

    /**
     * @name IncludeAllAddressTypes
     * @description 
     * True indicates notification goes to all address types for a contact.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeAllAddressTypes: boolean = false;

    /**
     * @name IncludeAlternateAddressesForMethod
     * @description 
     * True indicates notification also goes to alternate addresses for a method (e.g. alternate email address) in addition to the
     * standard address for the method.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeAlternateAddressesForMethod: boolean = false;

    /**
     * @name FilterId
     * @description 
     * The Filter Id identifies the filter that is applied to the broadcast group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FilterId: number = null;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name ConfirmationRequired
     * @description 
     * Confirmation required flag that indicates if confirmation is required.  Possible values include:
     * True = This notification requires confirmation before it will stop looping.
     * False = This notification does not require confirmation and will only loop the number of times specified by Notification
     * Maximum Count.
     * @servertype Boolean
     * @type {boolean}
     */
    ConfirmationRequired: boolean = false;

    /**
     * @name ConfirmSingleNotificationOnly
     * @description 
     * Confirmation action flag that indicates how confirmation functions.  Possible values include:
     * True = Confirmation only confirms this one notification record.
     * False = Confirmation of any notification triggered by the same event will serve as confirmation for all notifications triggered
     * by the event.  For example, if an event triggers notification to 3 different pagers and 5 different email accounts any one
     * of the people responding to the event can provide confirmation for everyone which will stop any further notifications for
     * the event from occurring.
     * @servertype Boolean
     * @type {boolean}
     */
    ConfirmSingleNotificationOnly: boolean = false;

    /**
     * @name ConfirmationOptions
     * @description 
     * Optional pipe delimited list of confirmation options that are presented to the user confirming the notification.
     * @servertype String
     * @type {string}
     */
    ConfirmationOptions: string = "";

    /**
     * @name Enabled
     * @description 
     * True indicates the notification group detail is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name NotificationMaximumCount
     * @description 
     * The maximum number of notifications that will be sent.  Note that if confirmation is required then this value is not used
     * and notifications are sent until confirmed.
     * @servertype Byte (nullable)
     * @type {number}
     */
    NotificationMaximumCount: number = 0;

    /**
     * @name InitialDelay
     * @description 
     * Initial delay (in minutes) before notification is sent.  This is useful for alarm triggered notifications where a person
     * 1 should be notified and have a chance to respond to and confirm the alarm before it is escalated and person 2 is notified.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    InitialDelay: number = 0;

    /**
     * @name LoopDelay
     * @description 
     * Loop delay (in minutes) between notifications being sent.  Only used when confirmation is required or when the notification
     * maximum count is greater than one.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    LoopDelay: number = 0;

    /**
     * @name StartTime
     * @description 
     * Start time when notification is allowed.  Can be used to prevent 2am sms notifications, etc.
     * @servertype String
     * @type {string}
     */
    StartTime: string = "";

    /**
     * @name EndTime
     * @description 
     * End time when notification is allowed.  Can be used to prevent 2am sms notifications, etc.
     * @servertype String
     * @type {string}
     */
    EndTime: string = "";

    /**
     * @name AssetId
     * @description 
     * The asset id used for notifications text.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = null;

    /**
     * @name ExternalAssetId
     * @description 
     * As an alternative to specifying an asset id for the notification text an external asset id can be selected which allows for
     * substitution of notification text on a partition basis using assets that share the same external asset id.
     * @servertype String
     * @type {string}
     */
    ExternalAssetId: string = "";

    /**
     * @name NotificationContactId
     * @description 
     * The notification contact id representing the notification data source used for things like email from name, address, etc.
     *  This is frequently null and determined at the time of the event.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationContactId: number = null;

    /**
     * @name NotificationContactName
     * @servertype String
     * @type {string}
     */
    NotificationContactName: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name NotificationGroupEditViewModel
 * @description
 * The Notification Group table contains information detailing notifications to be generated when a particular notification
 * group is activated/triggered/etc.
 */
export class NotificationGroupEditViewModel {

    /**
     * @name NotificationGroupId
     * @description 
     * Notification Group Id uniquely identifies this notification group.
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupId: number = null;

    /**
     * @name Description
     * @description 
     * Description for this notification group.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name NotificationContactType
     * @description 
     * The notification contact type for this notification group.  This allows us to know what type of notification contact is desired
     * for this notification group.  For example: SALES, SUPPORT, ACCOUNTING, etc. taken from the notification contact table.  When
     * multiple notification contacts exist with the same contact type the notification contact that is 'closest' to the contact
     * being notified is used.  For example, notification contacts with the following ownership is searched: contact, agent, primary
     * customer, agent hierarchy, system.
     * @servertype String
     * @type {string}
     */
    NotificationContactType: string = "";

    /**
     * @name NotificationContactMethod
     * @description 
     * Flag that indicates the method to use if the desired notification contact record cannot be found.  Possible values include:
     * I = Ignore - Proceed with notification
     * W = Warning - Proceed with notification log warning
     * E = Error - Cancel notification
     * @servertype String
     * @type {string}
     */
    NotificationContactMethod: string = "W";

    /**
     * @name Category
     * @description 
     * The category for this notification group.  This allows us to group notification groups of the same category for reporting,
     * presentation on the contact preferences screen, etc.  This is taken from a pick list.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name DisplayOrder
     * @description 
     * The display order to use when presenting the notification group in reporting, contact preferences screen, etc.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Title
     * @description 
     * The title to use for this notification group for presentation on the contact preferences screen, etc.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Summary
     * @description 
     * The summary to use for this notification group for presentation on the contact preferences screen, etc.
     * @servertype String
     * @type {string}
     */
    Summary: string = "";

    /**
     * @name SourceNotificationGroupId
     * @description 
     * If this notification group is a notification list built from another Notification Group Id this identifies that notification
     * group that has the rules that built this list.  This can be used to see a materialized list of exactly who will be notified
     * based on the rules in the source notification group id.  Note that the list built is static and is not updated when contacts
     * enter or leave the filters used in the group that defined the rules.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceNotificationGroupId: number = null;

    /**
     * @name ManualBroadcast
     * @description 
     * True indicates notification group is valid for manual broadcasts.  Many notification groups are used for automated notifications
     * like receipts, error alerts, etc. and are not appropriate for selection when sending out notifications to groups.  Only groups
     * where this setting is true are typically displayed as possible selections when choosing to manually trigger notifications.
     * @servertype Boolean
     * @type {boolean}
     */
    ManualBroadcast: boolean = false;

    /**
     * @name Enabled
     * @description 
     * True indicates the notification group is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this notification group can be used.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this notification group can be used.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name Trace
     * @description 
     * True indicates to save trace information with the notification record for debugging.
     * @servertype Boolean
     * @type {boolean}
     */
    Trace: boolean = false;

    /**
     * @name NotificationCount
     * @description 
     * The number of times this notification group has been triggered.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationCount: number = 0;

    /**
     * @name LastUsedDateTime
     * @description 
     * Date and time when the notification group was last triggered.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUsedDateTime: Date = null;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name NotificationOptOutEditViewModel
 * @description
 * The Notification Opt Out table contains opt out information used to prevent sending notifications to contacts that have asked
 * not to receive them.
 */
export class NotificationOptOutEditViewModel {

    /**
     * @name NotificationOptOutId
     * @description 
     * Notification Opt Out Id uniquely identifies this notification opt out.
     * @servertype Int64
     * @type {number}
     */
    NotificationOptOutId: number = null;

    /**
     * @name ContactId
     * @description 
     * The contact id that opted out of notifications.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactInformation
     * @description 
     * The email address, phone number, etc. used for notification opt out.
     * @servertype String
     * @type {string}
     */
    ContactInformation: string = "";

    /**
     * @name Category
     * @description 
     * When the opt out is specific to a notification category this is the category.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name NotificationGroupId
     * @description 
     * When the opt out is specific to a Notification Group this is the Id of that group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = null;

    /**
     * @name MethodList
     * @description 
     * When we have a contact id this is a CSV list of notification methods included in the opt out.  Possible values include:
     * E = Email
     * S = SMS
     * P = Phone Call
     * F = Fax
     * @servertype String
     * @type {string}
     */
    MethodList: string = "";

    /**
     * @name Method
     * @description 
     * When we do not have a contact id and only have contact information this indicates the notification method for the opt out.
     *  Possible values include:
     * E = Email
     * S = SMS
     * P = Phone Call
     * F = Fax
     *   = Not Specified
     * @servertype String
     * @type {string}
     */
    Method: string = "";

    /**
     * @name Source
     * @description 
     * The source of the opt out.  Possible values include:
     * E = Email
     * S = SMS
     * P = Phone Call
     * F = Fax
     * C = Configuration
     * G = Government List
     * M = Manual Entry
     * A = Analysis Result
     * @servertype String
     * @type {string}
     */
    Source: string = "";

    /**
     * @name ReasonCode
     * @description 
     * The reason for the opt out.  Possible values include:
     * N = Never Requested
     * D = Don't Want Any Longer
     * M = Too Many Notifications
     * O = Other
     * @servertype String
     * @type {string}
     */
    ReasonCode: string = "";

    /**
     * @name Reason
     * @description 
     * The reason for the opt out.
     * @servertype String
     * @type {string}
     */
    Reason: string = "";

    /**
     * @name NotificationEventDateTime
     * @description 
     * The date and time of the notification event that triggered the opt out.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NotificationEventDateTime: Date = null;

    /**
     * @name NotificationEventId
     * @description 
     * The Notification Event Id of the notification event that triggered the opt out.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationEventId: number = null;

    /**
     * @name NotificationId
     * @description 
     * The Notification Id of the notification that triggered the opt out.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationId: number = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name NotificationEditViewModel
 * @description
 * The Notification table contains information detailing notifications triggered during the YYYYMM month.
 */
export class NotificationEditViewModel {

    /**
     * @name NotificationId
     * @description 
     * Unique value that represents this notification record.
     * @servertype String
     * @type {string}
     */
    NotificationId: string = null;

    /**
     * @name NotificationEventId
     * @description 
     * Notification Event Id identifies the event this notification belongs to.
     * @servertype String
     * @type {string}
     */
    NotificationEventId: string = null;

    /**
     * @name Description
     * @description 
     * The description of this notification.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ContactId
     * @description 
     * The contact id that this notification is intended for.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name Method
     * @description 
     * Flag that indicates the notification method.  Possible values include:
     * E = Email
     * S = SMS
     * P = Phone Call
     * F = Fax
     *   = Not Specified
     * @servertype String
     * @type {string}
     */
    Method: string = "";

    /**
     * @name Type
     * @description 
     * Flag that indicates the type.  Possible values include:
     * N = Normal
     * S = Seed List
     * T = Test Run
     * @servertype String
     * @type {string}
     */
    Type: string = "N";

    /**
     * @name ContactInformation
     * @description 
     * The email address, phone number, etc. used for notification delivery.
     * @servertype String
     * @type {string}
     */
    ContactInformation: string = "";

    /**
     * @name ContactInformationCopied
     * @description 
     * The email address (etc?) being copied on the notification.
     * @servertype String
     * @type {string}
     */
    ContactInformationCopied: string = "";

    /**
     * @name ContactInformationBlindCopied
     * @description 
     * The email address (etc?) being blind copied on the notification.
     * @servertype String
     * @type {string}
     */
    ContactInformationBlindCopied: string = "";

    /**
     * @name ConsolidateNotifications
     * @description 
     * True indicates notifications will attempt to be consolidated.  For example, sending notifications to group of email users
     * instead of each user separately.  Not all methods support consolidation and for methods that do support consolidation error
     * reporting, tracking of opens, clicks, etc. can be inhibited since multiple users got the same notification.
     * @servertype Boolean
     * @type {boolean}
     */
    ConsolidateNotifications: boolean = false;

    /**
     * @name NotificationGroupId
     * @description 
     * Notification Group Id identifies the notification group this notification is defined in.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = null;

    /**
     * @name NotificationGroupDetailId
     * @description 
     * Notification Group Detail Id identifies the notification group detail this notification is defined in.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupDetailId: number = null;

    /**
     * @name AssetId
     * @description 
     * The asset id used for notifications text.  This may be different than the asset configured based on method, version and/or
     * language selection.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = null;

    /**
     * @name ExternalAssetId
     * @description 
     * As an alternative to specifying an asset id for the notification text an external asset id can be selected which allows for
     * substitution of notification text on a partition basis using assets that share the same external asset id.
     * @servertype String
     * @type {string}
     */
    ExternalAssetId: string = "";

    /**
     * @name NotificationContactId
     * @description 
     * The notification contact id representing the notification data source used for things like email from name, address, etc.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationContactId: number = null;

    /**
     * @name NotificationContactName
     * @servertype String
     * @type {string}
     */
    NotificationContactName: string = "";

    /**
     * @name NotificationMaximumCount
     * @description 
     * The maximum number of notifications that will be sent.  Note that if confirmation is required then this value is not used
     * and notifications are sent until confirmed.
     * @servertype Byte (nullable)
     * @type {number}
     */
    NotificationMaximumCount: number = 0;

    /**
     * @name InitialDelay
     * @description 
     * Initial delay (in minutes) before notification is sent.  This is useful for alarm triggered notifications where a person
     * 1 should be notified and have a chance to respond to and confirm the alarm before it is escalated and person 2 is notified.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    InitialDelay: number = 0;

    /**
     * @name LoopDelay
     * @description 
     * Loop delay (in minutes) between notifications being sent.  Only used when confirmation is required or when the notification
     * maximum count is greater than one.
     * @servertype Int16 (nullable)
     * @type {number}
     */
    LoopDelay: number = 0;

    /**
     * @name StartTime
     * @description 
     * Start time when notification is allowed.  Can be used to prevent 2am sms notifications, etc.
     * @servertype String
     * @type {string}
     */
    StartTime: string = "";

    /**
     * @name EndTime
     * @description 
     * End time when notification is allowed.  Can be used to prevent 2am sms notifications, etc.
     * @servertype String
     * @type {string}
     */
    EndTime: string = "";

    /**
     * @name QueueDateTime
     * @description 
     * The date and time this notification is queued for.  This is based on when the notification is triggered but factors in delays
     * for notification delivery, etc.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    QueueDateTime: Date = null;

    /**
     * @name Status
     * @description 
     * Status of this notification event.  Possible values are: 
     * H = Hold
     * P = Pending
     * R = Running
     * S = Success
     * F = Failure
     * C = Confirmed
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name ProcessingServer
     * @description 
     * The server processing this notification request.  If a notification engine starts processing a request but never updates
     * to reflect the results there was most likely a serious failure that should have been logged on the processing server.
     * @servertype String
     * @type {string}
     */
    ProcessingServer: string = "";

    /**
     * @name ProcessingDateTime
     * @description 
     * Date and time when the notification engine started processing this notification request.  This can be used to help determine
     * if there are any notifications that should have posted success or failure but haven't and may be orphaned.  In that scenario
     * the processing server should be checked for logged messages.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessingDateTime: Date = null;

    /**
     * @name SetupResultCode
     * @description 
     * Setup result code for this notification event.  Possible values include:
     * 1000-1999 = Success Codes
     * 2000-2999 = Warning Codes
     * 2901 = Could not use preferred notification contact id
     * 2911 = Could not use preferred notification message
     * 3000-3999 = Error Codes
     * 3101 = No contact information available for requested method
     * 3111 = Unknown method.
     * 3201 = Contact has opt-out request for the specified group
     * 3202 = Contact has opt-out request for the specified category
     * 3209 = Contact has opt-out request for all notifications
     * 3301 = Notification contact is missing required configuration
     * 3401 = Error preparing the notification message
     * 3402 = Macro error while preparing the notification message
     * 3901 = Could not use preferred notification contact id
     * 3911 = Could not use preferred notification message
     * 3999 = Exception occurred while attempting setup
     * @servertype Int16 (nullable)
     * @type {number}
     */
    SetupResultCode: number = 0;

    /**
     * @name DeliveryResultCode
     * @description 
     * Delivery result code for this notification event.  Possible values include:
     * 1000-1999 = Success Codes
     * 1101 = In Process
     * 1201 = Done/Finished
     * 1301 = Confirmed
     * 2000-2999 = Warning Codes
     * 3000-3999 = Error Codes
     * 3001 = Delivery not attempted because of setup failure, see setup result code
     * 3101 = Requested file attachment could not be found
     * 3501 = Voice gateway reported command problem
     * 3601 = Fax gateway reported command problem
     * 3701 = SMS gateway reported command problem
     * 3891 = Email server reported command problem
     * 3892 = Email server reported login or permission problem
     * 3893 = Email server reported encryption required
     * 3894 = Message is too large
     * 3895 = Mailbox problem
     * 3899 = Email server error
     * 3999 = Exception occurred while attempting delivery
     * @servertype Int16 (nullable)
     * @type {number}
     */
    DeliveryResultCode: number = 0;

    /**
     * @name NotificationCount
     * @description 
     * Count of the number of times this notification has been done.  This is typically 0 when pending or 1 when sent but for alarm
     * type notifications that repeat a certain number of times or until confirmed this value can be higher than 1.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationCount: number = 0;

    /**
     * @name FinishedDateTime
     * @description 
     * Date and time when this notification finished.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FinishedDateTime: Date = null;

    /**
     * @name ConfirmationRequired
     * @description 
     * Confirmation required flag that indicates if confirmation is required.  When true this notification requires confirmation
     * before it will stop looping.  When false this notification does not require confirmation and will only loop the number of
     * times specified by Notification Maximum Count.
     * @servertype Boolean
     * @type {boolean}
     */
    ConfirmationRequired: boolean = false;

    /**
     * @name ConfirmSingleNotificationOnly
     * @description 
     * Confirmation action flag that indicates how confirmation functions.  When true the confirmation only confirms this one notification
     * record.  When false the confirmation of any notification triggered by the same event will serve as confirmation for all notifications
     * triggered by the event.  For example, if an event triggers notification to 3 different pagers and 5 different email accounts
     * any one of the people responding to the event can provide confirmation for everyone which will stop any further notifications
     * for the event from occurring.
     * @servertype Boolean
     * @type {boolean}
     */
    ConfirmSingleNotificationOnly: boolean = false;

    /**
     * @name ConfirmationOptions
     * @description 
     * Optional pipe delimited list of confirmation options that are presented to the user confirming the notification.
     * @servertype String
     * @type {string}
     */
    ConfirmationOptions: string = "";

    /**
     * @name ConfirmedDateTime
     * @description 
     * Date and time when this notification was confirmed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ConfirmedDateTime: Date = null;

    /**
     * @name ConfirmationOption
     * @description 
     * When confirmation options are presented this is the option that was selected.
     * @servertype String
     * @type {string}
     */
    ConfirmationOption: string = "";

    /**
     * @name Comments
     * @description 
     * Comments provided when notification was confirmed for notifications that require confirmation.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Opened
     * @description 
     * Notification was opened.
     * @servertype Boolean
     * @type {boolean}
     */
    Opened: boolean = false;

    /**
     * @name Clicked
     * @description 
     * Notification was clicked.
     * @servertype Boolean
     * @type {boolean}
     */
    Clicked: boolean = false;

    /**
     * @name CausedOptOut
     * @description 
     * Notification caused an opt out.
     * @servertype Boolean
     * @type {boolean}
     */
    CausedOptOut: boolean = false;

    /**
     * @name NotDelivered
     * @description 
     * A not-delivered response (e.g. SMTP error, NDR, etc.) was received for this notification.
     * @servertype Boolean
     * @type {boolean}
     */
    NotDelivered: boolean = false;

    /**
     * @name NotDeliveredCode
     * @description 
     * The code provided as part of the not-delivered response.
     * @servertype String
     * @type {string}
     */
    NotDeliveredCode: string = "";

    /**
     * @name NotDeliveredMessage
     * @description 
     * The message provided as part of the not-delivered response.
     * @servertype String
     * @type {string}
     */
    NotDeliveredMessage: string = "";

    /**
     * @name NotDeliveredType
     * @description 
     * The not-delivered report type.  Possible options include:
     * SMTP = SMTP error occurred
     * NDR-HARD = NDR indicated hard unrecoverable failure
     * NDR-SOFT = NDR indicated soft error that could be retried
     * NDR-DNS = NDR indicated DNS error
     *  = Unknown
     * @servertype String
     * @type {string}
     */
    NotDeliveredType: string = "";

    /**
     * @name Trace
     * @description 
     * Any error messages plus verbose trace information populated when requested.
     * @servertype String
     * @type {string}
     */
    Trace: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name VoucherBatchEditViewModel
 */
export class VoucherBatchEditViewModel {

    /**
     * @name VoucherBatchId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VoucherBatchId: number = 0;

    /**
     * @name ExternalVoucherBatchId
     * @servertype String
     * @type {string}
     */
    ExternalVoucherBatchId: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name VoucherCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    VoucherCount: number = 0;

    /**
     * @name VoucherTemplate
     * @servertype String
     * @type {string}
     */
    VoucherTemplate: string = "";

    /**
     * @name VoucherSerialNumberTemplate
     * @servertype String
     * @type {string}
     */
    VoucherSerialNumberTemplate: string = "";

    /**
     * @name StartingSerialNumber
     * @servertype Int64 (nullable)
     * @type {number}
     */
    StartingSerialNumber: number = 0;

    /**
     * @name EndingSerialNumber
     * @servertype Int64 (nullable)
     * @type {number}
     */
    EndingSerialNumber: number = 0;

    /**
     * @name VoucherUseCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    VoucherUseCount: number = 0;

    /**
     * @name AllowPreapprovedVoucherOveruse
     * @servertype Boolean
     * @type {boolean}
     */
    AllowPreapprovedVoucherOveruse: boolean = false;

    /**
     * @name PromoteVoucherReservations
     * @servertype Boolean
     * @type {boolean}
     */
    PromoteVoucherReservations: boolean = false;

    /**
     * @name SuppressBillingTransaction
     * @servertype Boolean
     * @type {boolean}
     */
    SuppressBillingTransaction: boolean = false;

    /**
     * @name LinkToAuthorizationCode
     * @servertype Boolean
     * @type {boolean}
     */
    LinkToAuthorizationCode: boolean = false;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name VoucherAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    VoucherAmount: number = 0;

    /**
     * @name VoucherPercent
     * @servertype Double (nullable)
     * @type {number}
     */
    VoucherPercent: number = 0;

    /**
     * @name AverageDiscountApplied
     * @servertype Double (nullable)
     * @type {number}
     */
    AverageDiscountApplied: number = 0;

    /**
     * @name VoucherMinimum
     * @servertype Double (nullable)
     * @type {number}
     */
    VoucherMinimum: number = 0;

    /**
     * @name VoucherMaximum
     * @servertype Double (nullable)
     * @type {number}
     */
    VoucherMaximum: number = 0;

    /**
     * @name VoucherType
     * @servertype String
     * @type {string}
     */
    VoucherType: string = "";

    /**
     * @name CreatedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CreatedByContactId: number = null;

    /**
     * @name CreatedByContactName
     * @servertype String
     * @type {string}
     */
    CreatedByContactName: string = "";

    /**
     * @name CreatedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CreatedDateTime: Date = null;

    /**
     * @name OwnerContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerContactId: number = null;

    /**
     * @name OwnerContactName
     * @servertype String
     * @type {string}
     */
    OwnerContactName: string = "";

    /**
     * @name NotificationGroupIdApproved
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdApproved: number = 0;

    /**
     * @name NotificationGroupIdDenied
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdDenied: number = 0;

    /**
     * @name DaysBeforeInactive
     * @servertype Double (nullable)
     * @type {number}
     */
    DaysBeforeInactive: number = 0;

    /**
     * @name DaysBeforeLapse
     * @servertype Double (nullable)
     * @type {number}
     */
    DaysBeforeLapse: number = 0;

    /**
     * @name LapseDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LapseDateTime: Date = null;

    /**
     * @name DaysBeforeExpire
     * @servertype Double (nullable)
     * @type {number}
     */
    DaysBeforeExpire: number = 0;

    /**
     * @name ExpireDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpireDateTime: Date = null;

    /**
     * @name Status
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name MarketingMessageStatusHold
     * @servertype String
     * @type {string}
     */
    MarketingMessageStatusHold: string = "";

    /**
     * @name MarketingMessageStatusPending
     * @servertype String
     * @type {string}
     */
    MarketingMessageStatusPending: string = "";

    /**
     * @name MarketingMessageStatusReserved
     * @servertype String
     * @type {string}
     */
    MarketingMessageStatusReserved: string = "";

    /**
     * @name MarketingMessageStatusActive
     * @servertype String
     * @type {string}
     */
    MarketingMessageStatusActive: string = "";

    /**
     * @name MarketingMessageStatusInactive
     * @servertype String
     * @type {string}
     */
    MarketingMessageStatusInactive: string = "";

    /**
     * @name MarketingMessageStatusExpired
     * @servertype String
     * @type {string}
     */
    MarketingMessageStatusExpired: string = "";

    /**
     * @name MarketingMessageStatusLapsed
     * @servertype String
     * @type {string}
     */
    MarketingMessageStatusLapsed: string = "";

    /**
     * @name MarketingMessageStatusSuspended
     * @servertype String
     * @type {string}
     */
    MarketingMessageStatusSuspended: string = "";

    /**
     * @name MarketingMessageStatusUsed
     * @servertype String
     * @type {string}
     */
    MarketingMessageStatusUsed: string = "";

    /**
     * @name StatAsOfDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StatAsOfDateTime: Date = null;

    /**
     * @name StatHoldCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatHoldCount: number = 0;

    /**
     * @name StatHoldBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatHoldBalance: number = 0;

    /**
     * @name StatPendingCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatPendingCount: number = 0;

    /**
     * @name StatPendingBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatPendingBalance: number = 0;

    /**
     * @name StatReservedCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatReservedCount: number = 0;

    /**
     * @name StatReservedBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatReservedBalance: number = 0;

    /**
     * @name StatActiveCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatActiveCount: number = 0;

    /**
     * @name StatActiveBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatActiveBalance: number = 0;

    /**
     * @name StatInactiveCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatInactiveCount: number = 0;

    /**
     * @name StatInactiveBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatInactiveBalance: number = 0;

    /**
     * @name StatExpiredCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatExpiredCount: number = 0;

    /**
     * @name StatExpiredBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatExpiredBalance: number = 0;

    /**
     * @name StatLapsedCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatLapsedCount: number = 0;

    /**
     * @name StatLapsedBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatLapsedBalance: number = 0;

    /**
     * @name StatSuspendedCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatSuspendedCount: number = 0;

    /**
     * @name StatSuspendedBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatSuspendedBalance: number = 0;

    /**
     * @name StatUsedCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatUsedCount: number = 0;

    /**
     * @name StatUsedBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatUsedBalance: number = 0;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Lots
     * @servertype VoucherLotEditViewModel array
     * @type {VoucherLotEditViewModel[]}
     */
    Lots: VoucherLotEditViewModel[] = [];

    /**
     * @name Visibility
     * @servertype VoucherBatchVisibilityEditViewModel array
     * @type {VoucherBatchVisibilityEditViewModel[]}
     */
    Visibility: VoucherBatchVisibilityEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name VoucherLotEditViewModel
 */
export class VoucherLotEditViewModel {

    /**
     * @name VoucherLotId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VoucherLotId: number = 0;

    /**
     * @name VoucherBatchId
     * @servertype Int64
     * @type {number}
     */
    VoucherBatchId: number = 0;

    /**
     * @name ExternalVoucherLotId
     * @servertype String
     * @type {string}
     */
    ExternalVoucherLotId: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ManufacturerContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ManufacturerContactId: number = null;

    /**
     * @name ManufacturerContactName
     * @servertype String
     * @type {string}
     */
    ManufacturerContactName: string = "";

    /**
     * @name Manufacturer
     * @servertype String
     * @type {string}
     */
    Manufacturer: string = "";

    /**
     * @name ManufacturerOrder
     * @servertype String
     * @type {string}
     */
    ManufacturerOrder: string = "";

    /**
     * @name ManufacturerOrderDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ManufacturerOrderDateTime: Date = null;

    /**
     * @name DistributorContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DistributorContactId: number = null;

    /**
     * @name DistributorContactName
     * @servertype String
     * @type {string}
     */
    DistributorContactName: string = "";

    /**
     * @name Distributor
     * @servertype String
     * @type {string}
     */
    Distributor: string = "";

    /**
     * @name DistributorOrder
     * @servertype String
     * @type {string}
     */
    DistributorOrder: string = "";

    /**
     * @name DistributorOrderDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DistributorOrderDateTime: Date = null;

    /**
     * @name VoucherCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    VoucherCount: number = 0;

    /**
     * @name StartingSerialNumber
     * @servertype Int64 (nullable)
     * @type {number}
     */
    StartingSerialNumber: number = 0;

    /**
     * @name EndingSerialNumber
     * @servertype Int64 (nullable)
     * @type {number}
     */
    EndingSerialNumber: number = 0;

    /**
     * @name CreatedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CreatedByContactId: number = null;

    /**
     * @name CreatedByContactName
     * @servertype String
     * @type {string}
     */
    CreatedByContactName: string = "";

    /**
     * @name CreatedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    CreatedDateTime: Date = null;

    /**
     * @name ManufacturingDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ManufacturingDateTime: Date = null;

    /**
     * @name DistributionDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DistributionDateTime: Date = null;

    /**
     * @name ActivatedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActivatedDateTime: Date = null;

    /**
     * @name ActivationStatus
     * @servertype String
     * @type {string}
     */
    ActivationStatus: string = "";

    /**
     * @name StatAsOfDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StatAsOfDateTime: Date = null;

    /**
     * @name StatHoldCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatHoldCount: number = 0;

    /**
     * @name StatHoldBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatHoldBalance: number = 0;

    /**
     * @name StatPendingCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatPendingCount: number = 0;

    /**
     * @name StatPendingBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatPendingBalance: number = 0;

    /**
     * @name StatReservedCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatReservedCount: number = 0;

    /**
     * @name StatReservedBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatReservedBalance: number = 0;

    /**
     * @name StatActiveCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatActiveCount: number = 0;

    /**
     * @name StatActiveBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatActiveBalance: number = 0;

    /**
     * @name StatInactiveCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatInactiveCount: number = 0;

    /**
     * @name StatInactiveBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatInactiveBalance: number = 0;

    /**
     * @name StatExpiredCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatExpiredCount: number = 0;

    /**
     * @name StatExpiredBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatExpiredBalance: number = 0;

    /**
     * @name StatLapsedCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatLapsedCount: number = 0;

    /**
     * @name StatLapsedBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatLapsedBalance: number = 0;

    /**
     * @name StatSuspendedCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatSuspendedCount: number = 0;

    /**
     * @name StatSuspendedBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatSuspendedBalance: number = 0;

    /**
     * @name StatUsedCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StatUsedCount: number = 0;

    /**
     * @name StatUsedBalance
     * @servertype Double (nullable)
     * @type {number}
     */
    StatUsedBalance: number = 0;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name VoucherBatchVisibilityEditViewModel
 * @description
 * The Visibility table contains look up information to restrict visibility of various items like assets, location profiles,
 * rating profiles, packages, etc.  For example, assets may be restricted by thinks like roles, inventory types covered by maintenance,
 * etc.  Another example is valid profiles may be restricted by an agent id, primary customer, id, etc.
 */
export class VoucherBatchVisibilityEditViewModel {

    /**
     * @name VisibilityId
     * @description 
     * The id of the visibility item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VisibilityId: number = null;

    /**
     * @name VoucherBatchId
     * @servertype Int64
     * @type {number}
     */
    VoucherBatchId: number = 0;

    /**
     * @name Enabled
     * @description 
     * When true this visibility item is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name VisibilityType
     * @description 
     * An indicator of what visibility list type this entry is for.  The most common types are: 
     * Role = Role (visibility type id is the role id)
     * Group = Group (visibility type id is the contact id of the group)
     * Agent = Agent (visibility type id is the contact id of the agent)
     * PrimaryCustomer = Primary Customer (visibility type id is the contact id of the primary customer)
     * In addition to various tables, other possible values include:
     * PostalCode = Postal Code
     * State = State
     * CountryCode = Country Code
     * CountryCodeNpa = Country Code + NPA
     * CountryCodeNpaNxx = Country Code + NPA + NXX
     * LATA = LATA
     * Module = Module
     * Expression = A more complex expression with boolean logic where the type value list a list of expressions
     * Additionally, some custom types can be used in certain scenarios.  In situations where custom types are provided the routines
     * that test visibility scope need to be handed all possible type-value pairs since the custom type cannot be processed the
     * same as known types.
     * When the type is use constraint this is a template to use for building the string to check to make sure use is limited to
     * the use limit count value.  The template accepts typical values submitted for visibility checks plus: Date, Month, Year.
     *  For example: '{{Date}}', '{{Year}}-{{Month}}-{{FlightNumber}}', etc.
     * @servertype String
     * @type {string}
     */
    VisibilityType: string = "";

    /**
     * @name VisibilityTypeId
     * @description 
     * The visibility type id is the key of the visibility type for this visibility item.  For example, if the visibility type is
     * Role this value would be the RoleId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VisibilityTypeId: number = 0;

    /**
     * @name VisibilityTypeId2
     * @description 
     * The visibility type id 2 is the key of the visibility type when that id is a string data type.  If the visibility type is
     * not mapped to a table (e.g. state, postal code, etc.) then this is the value for that type.
     * @servertype String
     * @type {string}
     */
    VisibilityTypeId2: string = "";

    /**
     * @name VisibilityTypeId2EndRange
     * @description 
     * The end of a visibility type id range when a range of values is being used.  Can be used to restrict items to a range of
     * db versions, postal codes, etc.
     * @servertype String
     * @type {string}
     */
    VisibilityTypeId2EndRange: string = "";

    /**
     * @name VisibilityTypeValueList
     * @description 
     * A comma separated list of values that are valid for the specified visibility type.
     * @servertype string array
     * @type {string[]}
     */
    VisibilityTypeValueList: string[] = [];

    /**
     * @name VisibilityTypeScope
     * @description 
     * Any additional scope that is applied to the visibility type.  For example:
     * ValidMaintenance = The inventory type specified in the visibility type also must have a valid maintenance plan.
     * @servertype String
     * @type {string}
     */
    VisibilityTypeScope: string = "";

    /**
     * @name IncludeSubvalues
     * @description 
     * When true items that are considered subvalues are also in scope for visibility (e.g. sub-agents).
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeSubvalues: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name VoucherBatchRestrictionRuleEditViewModel
 * @description
 * The Restriction Rule table contains restriction rules for things like use count limits based on a restriction template.
 */
export class VoucherBatchRestrictionRuleEditViewModel {

    /**
     * @name RestrictionRuleId
     * @description 
     * The id of the restriction rule.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RestrictionRuleId: number = null;

    /**
     * @name VoucherBatchId
     * @servertype Int64
     * @type {number}
     */
    VoucherBatchId: number = 0;

    /**
     * @name Enabled
     * @description 
     * When true this restriction is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name RestrictionRuleTemplate
     * @description 
     * A template to use for building the string to utilize as a restriction rule that is checked to make sure use is restricted
     * as prescribed by the restriction rule.  The template accepts typical values submitted for restriction checks plus: NowIsoDate,
     * Now.Month, Now.Year.  For example: a template of '{{NowIsoDate}}', would restrict the number of times an item could be used
     * for a given date.  A template of '{{Now.Year}}-{{Now.Month}}-{{FlightNumber}}' would restrict the number of times an item
     * could be used for a given year, month, and flight number.
     * @servertype String
     * @type {string}
     */
    RestrictionRuleTemplate: string = "";

    /**
     * @name LimitUseCount
     * @description 
     * The number of times the restricted value can be used with a given restriction.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LimitUseCount: number = 0;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name VoucherCheckRequestViewModel
 */
export class VoucherCheckRequestViewModel {

    /**
     * @name VoucherCode
     * @servertype String
     * @type {string}
     */
    VoucherCode: string = "";

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Amount
     * @servertype Double
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name Visibility
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Visibility: {[key: string]:  string} = {};

}



/**
 * @name VoucherAddFromListAddViewModel
 */
export class VoucherAddFromListAddViewModel {

    /**
     * @name VoucherBatchId
     * @servertype Int64
     * @type {number}
     */
    VoucherBatchId: number = 0;

    /**
     * @name VoucherLotId
     * @servertype Int64
     * @type {number}
     */
    VoucherLotId: number = 0;

    /**
     * @name VoucherCodes
     * @servertype string array
     * @type {string[]}
     */
    VoucherCodes: string[] = [];

}



/**
 * @name VoucherEditViewModel
 */
export class VoucherEditViewModel {

    /**
     * @name VoucherId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VoucherId: number = 0;

    /**
     * @name VoucherLotId
     * @servertype Int64
     * @type {number}
     */
    VoucherLotId: number = 0;

    /**
     * @name VoucherBatchId
     * @servertype Int64
     * @type {number}
     */
    VoucherBatchId: number = 0;

    /**
     * @name VoucherCode
     * @servertype String
     * @type {string}
     */
    VoucherCode: string = "";

    /**
     * @name VoucherSerialNumber
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VoucherSerialNumber: number = 0;

    /**
     * @name Status
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name ActivatedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActivatedDateTime: Date = null;

    /**
     * @name LapseDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LapseDateTime: Date = null;

    /**
     * @name ExpireDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ExpireDateTime: Date = null;

    /**
     * @name LastUseDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUseDateTime: Date = null;

    /**
     * @name Balance
     * @servertype Double (nullable)
     * @type {number}
     */
    Balance: number = 0;

    /**
     * @name DiscountApplied
     * @servertype Double (nullable)
     * @type {number}
     */
    DiscountApplied: number = 0;

    /**
     * @name VoucherUseCount
     * @servertype Int32 (nullable)
     * @type {number}
     */
    VoucherUseCount: number = 0;

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name BillingAccountId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = 0;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ExternalServiceInformationSharingLevel Enum
 * @readonly
 * @enum {number}
 */
export enum ExternalServiceInformationSharingLevel {

    /**
     * None
     */
    None = 0,

    /**
     * Anonymous
     */
    Anonymous = 1,

    /**
     * Identifier
     */
    Identifier = 2

}



/**
 * @name AliasEditViewModel
 * @description
 * The Alias table contains a list of aliases that can be used to identify a specific item.  This could be third-party ids like
 * manufacturer part numbers, customer identifiers, etc.
 */
export class AliasEditViewModel {

    /**
     * @name AliasId
     * @description 
     * Alias Id uniquely identifies this alias record.
     * @servertype Int64
     * @type {number}
     */
    AliasId: number = null;

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) of this alias item.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this alias item.  For example, if the owner resource type
     * is Asset this value would be the AssetId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name AliasType
     * @description 
     * The type of alias used to provide more context for what type of value is stored in the value property.
     * @servertype String
     * @type {string}
     */
    AliasType: string = "";

    /**
     * @name Value
     * @description 
     * Value for this alias that represents the alias for the resource.
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Flags
     * @description 
     * A list of flags for this alias.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description 
     * Custom properties for this alias beyond the user-defined values.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name FeedbackEditViewModel
 * @description
 * The Feedback table contains feedback on various objects that solicit feedback like assets and cases.
 */
export class FeedbackEditViewModel {

    /**
     * @name FeedbackId
     * @description 
     * The id of the feedback entry.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FeedbackId: number = null;

    /**
     * @name FeedbackDateTime
     * @description 
     * The date and time when the feedback was submitted.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FeedbackDateTime: Date = null;

    /**
     * @name FeedbackByContactId
     * @description 
     * The Contact Id that added this feedback.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FeedbackByContactId: number = null;

    /**
     * @name FeedbackByContactName
     * @servertype String
     * @type {string}
     */
    FeedbackByContactName: string = "";

    /**
     * @name FeedbackIpAddress
     * @description 
     * The IP address and/or device id of this person submitting the feedback.  In cases where the feedback is anonymous (i.e. the
     * FeedbackByContactId is empty) this is used to assist in avoiding duplicate ratings by the same person.  The IP address may
     * be any combination of public ip address (always known), private ip address (not always known), and/or device id (app dependent).
     *  The specific value stored is less important than the fact that the same value is stored and checked for each use and the
     * understanding that since the feedback is anonymous this will be an imperfect method of avoiding duplicate ratings by the
     * same person.
     * @servertype String
     * @type {string}
     */
    FeedbackIpAddress: string = "";

    /**
     * @name Helped
     * @description 
     * An indicator if the feedback indicated the item helped.  Possible values include: 
     *   = Feedback did not indicate if item was helpful or not.
     * Y = Was helpful.
     * N = Was not helpful.
     * ? = Was not applicable.
     * @servertype String
     * @type {string}
     */
    Helped: string = "?";

    /**
     * @name Rating
     * @description 
     * Rating for this item between 1-5 where 0 is unrated.
     * @servertype Byte (nullable)
     * @type {number}
     */
    Rating: number = 0;

    /**
     * @name Feedback
     * @description 
     * Actual text of the feedback entered.
     * @servertype String
     * @type {string}
     */
    Feedback: string = "";

    /**
     * @name NeedsReview
     * @description 
     * True indicates this feedback was flagged as needing review.  That could be because of manual or automatic tagging.
     * @servertype Boolean
     * @type {boolean}
     */
    NeedsReview: boolean = false;

    /**
     * @name ReviewedByContactId
     * @description 
     * The Contact Id that reviewed this feedback.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReviewedByContactId: number = null;

    /**
     * @name ReviewedByContactName
     * @servertype String
     * @type {string}
     */
    ReviewedByContactName: string = "";

    /**
     * @name ReviewedDateTime
     * @description 
     * The date and time when the feedback was reviewed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReviewedDateTime: Date = null;

    /**
     * @name Tags
     * @description 
     * A comma separated list of tags for this feedback entry.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name HelpedInferred
     * @description 
     * An inferred indicator if the feedback indicated the item helped.  Possible values include: 
     *   = Feedback did not indicate if item was helpful or not.
     * Y = Was helpful.
     * N = Was not helpful.
     * ? = Was not applicable.
     * @servertype String
     * @type {string}
     */
    HelpedInferred: string = "";

    /**
     * @name HelpedInferredProbability
     * @description 
     * The probability associated with the inferred helped value as a decimal (i.e. 0.10 is 10%).
     * @servertype Double (nullable)
     * @type {number}
     */
    HelpedInferredProbability: number = 0;

    /**
     * @name RatingInferred
     * @description 
     * An inferred rating for this item between 1-5 where 0 is unrated.
     * @servertype Byte (nullable)
     * @type {number}
     */
    RatingInferred: number = 0;

    /**
     * @name RatingInferredProbability
     * @description 
     * The probability associated with the inferred rating as a decimal (i.e. 0.10 is 10%).
     * @servertype Double (nullable)
     * @type {number}
     */
    RatingInferredProbability: number = 0;

    /**
     * @name InferredDateTime
     * @description 
     * The date and time the helped and rating values were inferred.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    InferredDateTime: Date = null;

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) for this feedback entry.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this feedback entry.  For example, if the owner resource
     * type is Cases this value would be the CaseId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ReferenceEditViewModel
 * @description
 * The Reference table contains a list of objects that are referenced by another object.  This can be used to link arbitrary
 * objects like two assets, etc.
 */
export class ReferenceEditViewModel {

    /**
     * @name ReferenceId
     * @description 
     * Reference Id uniquely identifies this reference record.
     * @servertype Int64
     * @type {number}
     */
    ReferenceId: number = null;

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) of this reference item.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this reference item.  For example, if the owner resource
     * type is Asset this value would be the AssetId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.  This can also be used
     * as an owner resource subtype.  For example, an owner resource type of Cases might have subtypes of 'DependsOn' and 'SeeAlso'
     * to provide different lists of items being referenced.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name ReferencedResourceType
     * @description 
     * The referenced resource type (e.g. table, etc.) of the item being referenced.
     * @servertype String
     * @type {string}
     */
    ReferencedResourceType: string = "";

    /**
     * @name ReferencedResourceId
     * @description 
     * The referenced resource id is the key of the referenced resource type for the item being referenced.  For example, if the
     * referenced resource type is Asset this value would be the AssetId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReferencedResourceId: number = null;

    /**
     * @name ReferencedResourceId2
     * @description 
     * The referenced resource id 2 is the key of the referenced resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    ReferencedResourceId2: string = null;

    /**
     * @name Flags
     * @description 
     * A list of flags for this reference.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description 
     * Custom properties for this reference beyond the user-defined values.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name RestrictionCheckEditViewModel
 * @description
 * The Restriction Check table contains measurements for restrictions defined in the restriction rule table.
 */
export class RestrictionCheckEditViewModel {

    /**
     * @name RestrictionCheckId
     * @description 
     * The id of the restriction check.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RestrictionCheckId: number = null;

    /**
     * @name RestrictionRuleId
     * @description 
     * The id of the restriction rule this check belongs to.
     * @servertype Int64
     * @type {number}
     */
    RestrictionRuleId: number = null;

    /**
     * @name RestrictedResourceType
     * @description 
     * The restricted resource type (e.g. table, etc.).
     * @servertype String
     * @type {string}
     */
    RestrictedResourceType: string = "";

    /**
     * @name RestrictedResourceId
     * @description 
     * The restricted resource id is the key of the restricted resource type for this check.  For example, if the restricted resource
     * type is Asset this value would be the AssetId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RestrictedResourceId: number = null;

    /**
     * @name RestrictedResourceId2
     * @description 
     * The restricted resource id 2 is the key of the restricted resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    RestrictedResourceId2: string = null;

    /**
     * @name RestrictedCheck
     * @description 
     * The restricted check string built from the template provided in the restriction rule table.
     * @servertype String
     * @type {string}
     */
    RestrictedCheck: string = "";

    /**
     * @name UseCount
     * @description 
     * The current use count for the specified resource and restriction.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    UseCount: number = 0;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name RestrictionRuleEditViewModel
 * @description
 * The Restriction Rule table contains restriction rules for things like use count limits based on a restriction template.
 */
export class RestrictionRuleEditViewModel {

    /**
     * @name RestrictionRuleId
     * @description 
     * The id of the restriction rule.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RestrictionRuleId: number = null;

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) of this restriction item.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this restriction item.  For example, if the owner resource
     * type is Asset this value would be the AssetId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name Enabled
     * @description 
     * When true this restriction is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name RestrictionRuleTemplate
     * @description 
     * A template to use for building the string to utilize as a restriction rule that is checked to make sure use is restricted
     * as prescribed by the restriction rule.  The template accepts typical values submitted for restriction checks plus: NowIsoDate,
     * Now.Month, Now.Year.  For example: a template of '{{NowIsoDate}}', would restrict the number of times an item could be used
     * for a given date.  A template of '{{Now.Year}}-{{Now.Month}}-{{FlightNumber}}' would restrict the number of times an item
     * could be used for a given year, month, and flight number.
     * @servertype String
     * @type {string}
     */
    RestrictionRuleTemplate: string = "";

    /**
     * @name LimitUseCount
     * @description 
     * The number of times the restricted value can be used with a given restriction.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LimitUseCount: number = 0;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TaskListTemplateEditViewModel
 * @description
 * The TaskListTemplate table contains template information for tasks lists.
 */
export class TaskListTemplateEditViewModel {

    /**
     * @name TaskListTemplateId
     * @description 
     * Uniquely identifies this task list template.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskListTemplateId: number = null;

    /**
     * @name AlternateTaskListTemplateId
     * @description 
     * An alternate task list template id.  This alternate id is used for references that need to be portable when lists are copied
     * like with depends on task list references.
     * @servertype String
     * @type {string}
     */
    AlternateTaskListTemplateId: string = "";

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the task list in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @description 
     * Description for this task list.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Comments
     * @description 
     * Comments for this task list.  Allows more detailed information than provided for in the description.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Enabled
     * @description 
     * Flag that indicates if the task list is enabled.  Possible values include:
     * E = Enabled
     * D = Disabled
     * R = Enabled On Request (The task list can be requested to be added.)
     * W = Enabled When (The task list is enabled if the enabled when expression resolves to true.)
     * @servertype String
     * @type {string}
     */
    Enabled: string = "E";

    /**
     * @name EnabledWhen
     * @description 
     * An expression that resolves to either true or false and is used to determine if the task list is enabled.
     * @servertype String
     * @type {string}
     */
    EnabledWhen: string = "";

    /**
     * @name EnabledReason
     * @description 
     * An explanation for why the task list is either enabled or disabled.
     * @servertype String
     * @type {string}
     */
    EnabledReason: string = "";

    /**
     * @name Required
     * @description 
     * Flag that indicates if tasks in this task list are required.  This can also be set at the task level but tasks default to
     * inheriting the required setting from the task list.  Possible values include:
     * N = Not Required (The task is not required.)
     * B = Required Before Work Begins (The task is required to be done before work begins.  The context of what work beginning
     * means is dependent on the owning object.  For cases that would mean before completing forms related to documenting the case.)
     * O = Required Before Work Output (The task is required to be done before work output.  The context of what work output means
     * is dependent on the owning object.  For cases that would mean before creating a report for the case.)
     * R = Required Before Review (The task is required to be done before review.  The context of what review means is dependent
     * on the owning object.  For cases that would mean before submitting for review.)
     * S = Required Before Review Success (The task is required to be done before review marked as successful.  The context of what
     * review means is dependent on the owning object but implies only the reviewer can complete this task.  For cases that would
     * mean before the reviewer marks the case as reviewed with success outcome.)
     * F = Required Before Finish (The task is required to be done before finish.  The context of what finish means is dependent
     * on the owning object.  For cases that would mean before closing the case.)
     * @servertype String
     * @type {string}
     */
    Required: string = "N";

    /**
     * @name RequiredReason
     * @description 
     * An explanation for why the task list is required.  This might reference company policy, industry standards, etc.
     * @servertype String
     * @type {string}
     */
    RequiredReason: string = "";

    /**
     * @name RequiredWaivedForContacts
     * @servertype ContactReferenceViewModel array
     * @type {ContactReferenceViewModel[]}
     */
    RequiredWaivedForContacts: ContactReferenceViewModel[] = [];

    /**
     * @name DependsOnTaskLists
     * @description 
     * Task lists that can have their tasks performed independently have this value empty.  Task lists that contain tasks that cannot
     * be started until one or more task lists have been completed will have the other task list's alternate id entered here which
     * will require those lists to be completed before tasks in this list can be completed.  
     * In its simplest form this value is a comma delimited list of alternate task list ids that this task list depends on.  In
     * more complex dependency scenarios this value can contain the following characters: &|().  The & character is used to mean
     * 'and'.  The | character is used to mean 'or'.  The () characters are used for logical grouping of multiple values.  For example:
     * ( ab414 | cd415 ) & ( ef416 | gh417 ) means this task list depends on either alternate task list id ab414 or cd415 being
     * completed and it also depends on either alternate task list id ef416 or gh417 being completed.
     * @servertype String
     * @type {string}
     */
    DependsOnTaskLists: string = "";

    /**
     * @name MilestoneCompletion
     * @description 
     * Completion of all tasks in this task list is a milestone.
     * @servertype Boolean
     * @type {boolean}
     */
    MilestoneCompletion: boolean = false;

    /**
     * @name CompletedTaskMode
     * @description 
     * Flag that indicates how completed tasks are displayed.  Possible values include:
     * K = Keep completed tasks in the task list.
     * H = Hide completed tasks from the task list.  Note this prevents tasks from being marked incomplete.
     * B = Show completed tasks at the bottom of the task list.
     * M = Show completed tasks at the bottom of the task list in a collapsed or minimized group.
     * @servertype String
     * @type {string}
     */
    CompletedTaskMode: string = "K";

    /**
     * @name DefaultTaskList
     * @description 
     * When true the task list is used as the default for system generated tasks when the task list is unknown.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultTaskList: boolean = false;

    /**
     * @name OwnerCanEstimate
     * @description 
     * When true the owner of the task can change the estimated close date time for the task to a value different than that assigned
     * to the task.  This can be enabled at either the task or task list level.
     * @servertype Boolean
     * @type {boolean}
     */
    OwnerCanEstimate: boolean = true;

    /**
     * @name OwnerCanReassign
     * @description 
     * When true the owner of the task can reassign the task to a new owner.  This can be enabled at either the task or task list
     * level.
     * @servertype Boolean
     * @type {boolean}
     */
    OwnerCanReassign: boolean = true;

    /**
     * @name OwnerCanEdit
     * @description 
     * When true the owner of the task can edit the description and comments.  This can be enabled at either the task or task list
     * level.
     * @servertype Boolean
     * @type {boolean}
     */
    OwnerCanEdit: boolean = true;

    /**
     * @name SupportWorkFlow
     * @description 
     * When true the tasks will support work flow settings.  See the task template table.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportWorkFlow: boolean = false;

    /**
     * @name SupportSubtasks
     * @description 
     * When true the tasks will allow subtasks to be created.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportSubtasks: boolean = true;

    /**
     * @name SupportNotes
     * @description 
     * When true the tasks will allow notes to be created.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportNotes: boolean = true;

    /**
     * @name SupportTimeLogs
     * @description 
     * When true the tasks will allow time logs to be created.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportTimeLogs: boolean = true;

    /**
     * @name SupportFreeForm
     * @description 
     * Free form flag for tasks in this list.  When false all predefined tasks are assigned.  When true only the first task is assigned
     * to the list and all other predefined tasks are treated as suggested possible tasks that can be manually added later.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportFreeForm: boolean = false;

    /**
     * @name SupportAddingTasks
     * @description 
     * When true the task list will allow new tasks to be assigned to it.  This differs from SupportFreeForm as it will allow all
     * predefined tasks to be added and still allow adding of other tasks.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportAddingTasks: boolean = true;

    /**
     * @name SupportSystemTasks
     * @description 
     * When true the task list provides support for system tasks.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportSystemTasks: boolean = false;

    /**
     * @name SupportTriggers
     * @description 
     * When true the task list provides support for triggers.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportTriggers: boolean = false;

    /**
     * @name SupportAdvancedSettings
     * @description 
     * When true the task list provides options for various advanced settings
     * @servertype Boolean
     * @type {boolean}
     */
    SupportAdvancedSettings: boolean = false;

    /**
     * @name SupportExtendedAttributes
     * @description 
     * When true the task list provides support for extended attributes.
     * @servertype Boolean
     * @type {boolean}
     */
    SupportExtendedAttributes: boolean = false;

    /**
     * @name HasExtendedAttributes
     * @description 
     * When true the template task list has extended attributes that applies to all tasks in the list.
     * @servertype Boolean
     * @type {boolean}
     */
    HasExtendedAttributes: boolean = false;

    /**
     * @name CloseRuleNoteRequired
     * @description 
     * When true the task cannot be closed unless at least one note has been provided.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleNoteRequired: boolean = false;

    /**
     * @name CloseRuleTimeLogRequired
     * @description 
     * When true the task cannot be closed unless at least one time log has been provided.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleTimeLogRequired: boolean = false;

    /**
     * @name CloseRuleTimeLogsAllClosed
     * @description 
     * When true the task cannot be closed unless all time logs have been closed.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleTimeLogsAllClosed: boolean = false;

    /**
     * @name CloseRuleAllSubtasksCompleted
     * @description 
     * When true the task cannot be closed unless all subtasks have first been completed.  Typically closing a task will automatically
     * complete all open subtasks.  This rule prevents being able to close a task with open subtasks.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleAllSubtasksCompleted: boolean = false;

    /**
     * @name Private
     * @description 
     * When true the task list is treated as private.  This controls if users scoped to see all non-private lists will have this
     * list visible or not.  This has no impact on users who are participants in specific tasks from seeing those tasks or from
     * users with share information from being able to see the list.
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name Icon
     * @description 
     * If the task list has an icon associated with it this is the icon name.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name DisplayColor
     * @description 
     * The color used to display this item on calendars or other multi-item displays.
     * @servertype String
     * @type {string}
     */
    DisplayColor: string = "";

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) for this task list.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this task list.  For example, if the owner resource type
     * is Case this value would be the CaseId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name Tasks
     * @servertype TaskTemplateEditViewModel array
     * @type {TaskTemplateEditViewModel[]}
     */
    Tasks: TaskTemplateEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TaskTemplateEditViewModel
 * @description
 * The Task Template table contains information regarding tasks.
 */
export class TaskTemplateEditViewModel {

    /**
     * @name TaskTemplateId
     * @description 
     * Uniquely identifies this task template.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskTemplateId: number = null;

    /**
     * @name AlternateTaskTemplateId
     * @description 
     * An alternate task template id.  This alternate id is used for references that need to be portable when lists are copied like
     * with starting task, depends on task references, etc.
     * @servertype String
     * @type {string}
     */
    AlternateTaskTemplateId: string = "";

    /**
     * @name TaskListTemplateId
     * @description 
     * The id of the task list template this task belongs to.
     * @servertype Int64
     * @type {number}
     */
    TaskListTemplateId: number = null;

    /**
     * @name TaskType
     * @description 
     * Task type.  Possible values include:
     * T = Task / To-do Item
     * E = Event / Meeting / Appointment
     * W = Workflow Task
     * The behavior and features available to tasks will differ based on the task type assigned.  Tasks (e.g. to-do items) have
     * no dependency on other tasks for start but can be tied to another task for when it is due to be completed.
     * Events (e.g. meetings or appointments) are treated like calendar events but can be tied to another task for when it is due
     * to be started.
     * Workflow tasks are part of a scheduled workflow and can have dependency on other tasks for start as well as being tied to
     * another task for start and completion.
     * @servertype String
     * @type {string}
     */
    TaskType: string = "T";

    /**
     * @name Category
     * @description 
     * Category of this task.  Options provided by system pick lists based on TaskType.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the task in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @description 
     * Description for this task.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Comments
     * @description 
     * Comments for this task.  Allows more detailed information than provided for in the description.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Priority
     * @description 
     * The default priority of this task.  Possible values include:
     * -1 = Low
     *  0 = Normal
     *  1 = High
     * @servertype Int16 (nullable)
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name Status
     * @description 
     * Default status for this task.  Values for this column are taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name Required
     * @description 
     * Flag that indicates the task is required.  Possible values include:
     * I = Inherited From Task List (The required setting is inherited from the task list.)
     * N = Not Required (The task is not required.)
     * B = Required Before Work Begins (The task is required to be done before work begins.  The context of what work beginning
     * means is dependent on the owning object.  For cases that would mean before completing forms related to documenting the case.)
     * O = Required Before Work Output (The task is required to be done before work output.  The context of what work output means
     * is dependent on the owning object.  For cases that would mean before creating a report for the case.)
     * R = Required Before Review (The task is required to be done before review.  The context of what review means is dependent
     * on the owning object.  For cases that would mean before submitting for review.)
     * S = Required Before Review Success (The task is required to be done before review marked as successful.  The context of what
     * review means is dependent on the owning object but implies only the reviewer can complete this task.  For cases that would
     * mean before the reviewer marks the case as reviewed with success outcome.)
     * F = Required Before Finish (The task is required to be done before finish.  The context of what finish means is dependent
     * on the owning object.  For cases that would mean before closing the case.)
     * @servertype String
     * @type {string}
     */
    Required: string = "I";

    /**
     * @name RequiredReason
     * @description 
     * An explanation for why the task is required.  This might reference company policy, industry standards, etc.
     * @servertype String
     * @type {string}
     */
    RequiredReason: string = "";

    /**
     * @name RequiredWaivedForContacts
     * @servertype ContactReferenceViewModel array
     * @type {ContactReferenceViewModel[]}
     */
    RequiredWaivedForContacts: ContactReferenceViewModel[] = [];

    /**
     * @name StartLagTimeMinutes
     * @description 
     * The number of minutes to lag workflow task start from its activating event.  For tasks that depend on other tasks (see DependsOnTasks)
     * this value is added to the time when those tasks are completed.  For tasks that have no dependencies this is added to the
     * time that is appropriate for the owner.  For example, with cases this time would be when the case was started.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    StartLagTimeMinutes: number = 0;

    /**
     * @name EstimatedTimeMinutes
     * @description 
     * Estimated time in minutes needed to complete this task.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    EstimatedTimeMinutes: number = 0;

    /**
     * @name EstimatedCloseMinutes
     * @description 
     * Estimated time in minutes to add to the workflow task activation date time to calculate the estimated close date time.  Note
     * that this is different than estimated time.  Estimated time is the estimated number of minutes to complete the task while
     * estimated close is the estimated time before the task should be closed.  For example, a task may only take 10 minutes to
     * complete but because of work loads, etc. we may allocate 120 minutes before it needs to be closed.  When estimated close
     * alternate task id is assigned then this value is an offset from that milestone.  In that case a negative value here is the
     * number of minutes prior to that milestone for this workflow task to close.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    EstimatedCloseMinutes: number = 0;

    /**
     * @name DependsOnTasks
     * @description 
     * Tasks that can be performed independently have this value empty.  
     * Workflow tasks that cannot be started until one or more tasks have finished will have the other task's alternate task id
     * entered here which will allow this task to be activated automatically when the tasks it depends on have completed.  Only
     * valid for workflow tasks.
     * Non-workflow tasks that should not be completed before other tasks have been completed can also have values assigned here.
     * In its simplest form this value is a comma delimited list of alternate task ids that this task depends on.  In more complex
     * dependency scenarios this value can contain the following characters: &|().  The & character is used to mean 'and'.  The
     * | character is used to mean 'or'.  The () characters are used for logical grouping of multiple values.  For example: ( ab414
     * | cd415 ) & ( ef416 | gh417 ) means this task depends on either alternate task id ab414 or cd415 being completed and it also
     * depends on either alternate task id ef416 or gh417 being completed.
     * @servertype String
     * @type {string}
     */
    DependsOnTasks: string = "";

    /**
     * @name EstimatedStartAlternateTaskId
     * @description 
     * When specified the start or close (based on estimated start alternate task id trigger) of this alternate task id represents
     * the time when this workflow task should be started.  This allows workflow tasks start dates to be tied to milestones of other
     * tasks being started or completed.  Use the start lag time to offset this task start date to be before or after this milestone.
     * @servertype String
     * @type {string}
     */
    EstimatedStartAlternateTaskId: string = "";

    /**
     * @name EstimatedStartAlternateTaskIdTrigger
     * @description 
     * A flag indicating how estimated start alternate task id should be handled.  Possible values include:
     * C = Close (The estimated start is tied to estimated close of estimated start alternate task id.)
     * S = Start (The estimated start is tied to start of estimated start alternate task id.)
     * @servertype String
     * @type {string}
     */
    EstimatedStartAlternateTaskIdTrigger: string = "S";

    /**
     * @name EstimatedCloseAlternateTaskId
     * @description 
     * When specified the start or close (based on estimated close alternate task id trigger) of this alternate task id represents
     * the time when this workflow task should be completed.  This allows workflow tasks due dates to be tied to milestones of other
     * tasks being started or completed.  Use estimated close time to offset this task due date to be before or after this milestone.
     * @servertype String
     * @type {string}
     */
    EstimatedCloseAlternateTaskId: string = "";

    /**
     * @name EstimatedCloseAlternateTaskIdTrigger
     * @description 
     * A flag indicating how estimated close alternate task id should be handled.  Possible values include:
     * C = Close (The estimated close is tied to estimated close of estimated close alternate task id.)
     * S = Start (The estimated close is tied to start of estimated close alternate task id.)
     * @servertype String
     * @type {string}
     */
    EstimatedCloseAlternateTaskIdTrigger: string = "C";

    /**
     * @name EndDateTreatment
     * @description 
     * A flag indicating how workflow task end dates are to be handled.  Possible values include:
     * H = Hidden End Date (No end date is specified and end date option is hidden.)
     * N = No End Date (No end date is specified but can be assigned.)
     * F = Fixed End Date (End date does not change if estimated close changes.)
     * M = Moving End Date (End date changes as estimated close date changes.)
     * @servertype String
     * @type {string}
     */
    EndDateTreatment: string = "M";

    /**
     * @name AutoClose
     * @description 
     * When true this task is automatically closed once past the end date time.  Typically only used for event task types where
     * the conclusion of the event can automatically clear the item.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoClose: boolean = false;

    /**
     * @name Announce
     * @description 
     * When true send an announcement to all task participants.
     * @servertype Boolean
     * @type {boolean}
     */
    Announce: boolean = true;

    /**
     * @name ReminderStartMinutes
     * @description 
     * A comma delimited list of minutes before expected start that a reminder should be sent to the assigned to contact.  For example,
     * to send a reminder 1 week and 1 day prior to task start this value would be set to 10080,1440.
     * @servertype int array
     * @type {number[]}
     */
    ReminderStartMinutes: number[] = [];

    /**
     * @name ReminderEndMinutes
     * @description 
     * A comma delimited list of minutes before expected end that a reminder should be sent to the assigned to contact.  For example,
     * to send a reminder 1 week and 1 day prior to task end this value would be set to 10080,1440.
     * @servertype int array
     * @type {number[]}
     */
    ReminderEndMinutes: number[] = [];

    /**
     * @name StartedProgress
     * @description 
     * Assigned as the current progress of the task owner when this task is started.  This value is used for showing the current
     * progress of the task owner.  Only valid for work flow tasks when the owner supports a progress indicator.
     * @servertype String
     * @type {string}
     */
    StartedProgress: string = "";

    /**
     * @name CompletedPercentDone
     * @description 
     * Assigned as the percent completed (0-100) for the task list ownership (e.g. case) when this task is completed.  This value
     * is used for showing the current progress.  Only valid for work flow tasks.
     * @servertype Byte (nullable)
     * @type {number}
     */
    CompletedPercentDone: number = 0;

    /**
     * @name Billable
     * @description 
     * When true the task is considered billable.
     * @servertype Boolean
     * @type {boolean}
     */
    Billable: boolean = true;

    /**
     * @name BillingRate
     * @description 
     * The hourly billing rate for this task.
     * @servertype Double (nullable)
     * @type {number}
     */
    BillingRate: number = 0;

    /**
     * @name Budget
     * @description 
     * The budget for this task in fees or hours depending on the budget type.
     * @servertype Double (nullable)
     * @type {number}
     */
    Budget: number = 0;

    /**
     * @name MilestoneCompletion
     * @description 
     * Completion of this task is a milestone.
     * @servertype Boolean
     * @type {boolean}
     */
    MilestoneCompletion: boolean = false;

    /**
     * @name OwnerCanEstimate
     * @description 
     * When true the owner of this task can change the estimated close date time for the task to a value different than that assigned
     * to the task.  This can be enabled at either the task or task list level.
     * @servertype Boolean
     * @type {boolean}
     */
    OwnerCanEstimate: boolean = true;

    /**
     * @name OwnerCanReassign
     * @description 
     * When true the owner of this task can reassign the task to a new owner.  This can be enabled at either the task or task list
     * level.
     * @servertype Boolean
     * @type {boolean}
     */
    OwnerCanReassign: boolean = true;

    /**
     * @name OwnerCanEdit
     * @description 
     * When true the owner of this task can edit the description and comments.  This can be enabled at either the task or task list
     * level.
     * @servertype Boolean
     * @type {boolean}
     */
    OwnerCanEdit: boolean = true;

    /**
     * @name CloseRuleNoteRequired
     * @description 
     * When true the task cannot be closed unless at least one note has been provided.  This can be enabled at either the task or
     * task list level.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleNoteRequired: boolean = false;

    /**
     * @name CloseRuleTimeLogRequired
     * @description 
     * When true the task cannot be closed unless at least one time log has been provided.  This can be enabled at either the task
     * or task list level.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleTimeLogRequired: boolean = false;

    /**
     * @name CloseRuleTimeLogsAllClosed
     * @description 
     * When true the task cannot be closed unless all time logs have been closed.  This can be enabled at either the task or task
     * list level.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleTimeLogsAllClosed: boolean = false;

    /**
     * @name CloseRuleAllSubtasksCompleted
     * @description 
     * When true the task cannot be closed unless all subtasks have first been completed.  Typically closing a task will automatically
     * complete all open subtasks.  This rule prevents being able to close a task with open subtasks.  This can be enabled at either
     * the task or task list level.
     * @servertype Boolean
     * @type {boolean}
     */
    CloseRuleAllSubtasksCompleted: boolean = false;

    /**
     * @name AssignmentFlag
     * @description 
     * Flag of possible method used for assignment of task to an owner.  Possible values include:
     * S = System (The task is always assigned to the system.  Only valid when the task can be performed by the system.)
     * A = Assigned To (The task, if open, is always assigned to the person the task list ownership - e.g. case - is currently assigned
     * to.)
     * F = Fixed (The task is always assigned to the value in assign to contact id.)
     * O = Owner (The task is always assigned to the owner of the task list ownership - e.g. case.)
     * W = Workload (The task is assigned based on a work load calculation for all people who belong to the group specified in the
     * assign to contact id.)
     * T = Target (The task is always assigned to the target of the task list ownership - e.g. case contact id.)
     * U = Unassigned (The task will need to be assigned to someone upon creation.)
     * @servertype String
     * @type {string}
     */
    AssignmentFlag: string = "O";

    /**
     * @name AssignToContactId
     * @description 
     * The Contact Id that should always be assigned to this task when the assignment flag is fixed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssignToContactId: number = null;

    /**
     * @name AssignToContactName
     * @servertype String
     * @type {string}
     */
    AssignToContactName: string = "";

    /**
     * @name AssignedByFlag
     * @description 
     * Flag of possible method used to determine who to show as the person assigning the task.  Possible values include:
     * V = Supervisor (The task is always assigned by the supervisor - see the supervisor flag.)
     * S = System (The task is always assigned by the system.)
     * F = Fixed (The task is always assigned by the value in assigned by contact id.)
     * O = Owner (The task is always assigned by the owner of the task list ownership - e.g. case owner.)
     * T = Target (The task is always assigned by the target of the task list ownership - e.g. case contact.)
     * U = Unassigned (The task will need to have assigned by value set to someone upon creation.)
     * @servertype String
     * @type {string}
     */
    AssignedByFlag: string = "V";

    /**
     * @name AssignedByContactId
     * @description 
     * The Contact Id that should always be the assigned by person for this task when the assigned by flag is fixed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssignedByContactId: number = null;

    /**
     * @name AssignedByContactName
     * @servertype String
     * @type {string}
     */
    AssignedByContactName: string = "";

    /**
     * @name SupervisorFlag
     * @description 
     * Flag of possible method used for assignment of task to a supervisor.  Possible values include:
     * S = Self (The task supervisor is always the person assigned as the owner of the task.)
     * A = Assigned (The task supervisor is always the supervisor of the owner the task was assigned to.)
     * F = Fixed (The task supervisor is always assigned to the value in supervisor contact id.)
     * O = Owner (The task supervisor is always assigned to the owner of the task list ownership - e.g. case owner.)
     * W = Workload (The task is assigned based on a work load calculation for all people in the who belong to the group specified
     * in the supervisor contact id.)
     * T = Target (The task is always assigned to the target of the task list ownership - e.g. case contact.)
     * C = Creator (The task supervisor is always the person who created the task - i.e. assigned by.)
     * @servertype String
     * @type {string}
     */
    SupervisorFlag: string = "W";

    /**
     * @name SupervisorContactId
     * @description 
     * The Contact Id that should always be assigned as supervisor for this task when the supervisor flag is fixed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SupervisorContactId: number = null;

    /**
     * @name SupervisorContactName
     * @servertype String
     * @type {string}
     */
    SupervisorContactName: string = "";

    /**
     * @name Participants
     * @description 
     * A list of participants for this task.  These are secondary to who the task is assigned to.
     * @servertype ContactReferenceViewModel array
     * @type {ContactReferenceViewModel[]}
     */
    Participants: ContactReferenceViewModel[] = [];

    /**
     * @name CanCompleteFlags
     * @description 
     * Flag of possible task roles that can mark a task as completed.  When empty anyone with write access can mark the task as
     * completed.  Possible values include:
     * A = Assigned To (The person the task is assigned to.)
     * B = Assigned By (The person who assigned the task.)
     * S = Supervisor (The task supervisor.)
     * P = Participant (Any task participant.)
     * O = Owner (The owner of the object the task belongs to - e.g. case owner.)
     * R = Reviewer (The reviewer of the object the task belongs to - e.g. case reviewer.)
     * N = Nexus (Anyone with nexus to the task including those with nexus to the object owning the task.)
     * @servertype string array
     * @type {string[]}
     */
    CanCompleteFlags: string[] = [];

    /**
     * @name TaskStartNotificationGroupId
     * @description 
     * Notification group to notify when a task using this template starts.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskStartNotificationGroupId: number = null;

    /**
     * @name TaskStartProcessTemplateId
     * @description 
     * Process template for process to trigger when a task using this template starts. Unlike SystemTaskProcessTemplateId, the status
     * of the process is not tracked with the task.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskStartProcessTemplateId: number = null;

    /**
     * @name TaskStartProcessPeerId
     * @description 
     * For processes that must execute peer operations chronologically this is a peer id used to identify peers.  When not specified
     * the process template id will be used to identify peers but providing a value here allows more definitive identification of
     * peers for scenarios where a template is used by non-peers.
     * @servertype String
     * @type {string}
     */
    TaskStartProcessPeerId: string = "";

    /**
     * @name TaskStartTriggerAssetId
     * @description 
     * The Asset Id to use for trigger to execute when a task using this template starts.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskStartTriggerAssetId: number = null;

    /**
     * @name TaskCompleteNotificationGroupId
     * @description 
     * Notification group to notify when a task using this template is completed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskCompleteNotificationGroupId: number = null;

    /**
     * @name TaskCompleteProcessTemplateId
     * @description 
     * Process template for process to trigger when a task using this template is completed.  Unlike SystemTaskProcessTemplateId,
     * the status of the process is not tracked with the task.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskCompleteProcessTemplateId: number = null;

    /**
     * @name TaskCompleteProcessPeerId
     * @description 
     * For processes that must execute peer operations chronologically this is a peer id used to identify peers.  When not specified
     * the process template id will be used to identify peers but providing a value here allows more definitive identification of
     * peers for scenarios where a template is used by non-peers.
     * @servertype String
     * @type {string}
     */
    TaskCompleteProcessPeerId: string = "";

    /**
     * @name TaskCompleteTriggerAssetId
     * @description 
     * The Asset Id to use for trigger to execute when a task using this template is completed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskCompleteTriggerAssetId: number = null;

    /**
     * @name RelatedToAlternateTaskId
     * @description 
     * The alternate task id that this task is related to.  This value accomplishes the following: 
     * For tasks this allows tasks to be guaranteed to be assigned to the same individual for efficiency or other reasons regardless
     * of any task work load calculations that might be otherwise applied.
     * @servertype String
     * @type {string}
     */
    RelatedToAlternateTaskId: string = "";

    /**
     * @name HasExtendedAttributes
     * @description 
     * When true the task template has extended attributes.
     * @servertype Boolean
     * @type {boolean}
     */
    HasExtendedAttributes: boolean = false;

    /**
     * @name Private
     * @description 
     * When true the task is treated as private.  This controls if users scoped to see all non-private tasks will have this task
     * visible or not.  This has no impact on users who are participants in this task from seeing the task or from users with share
     * information from being able to see the list containing this task.
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name Icon
     * @description 
     * If the task list has an icon associated with it this is the icon name.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name DisplayColor
     * @description 
     * The color used to display this item on calendars or other multi-item displays.
     * @servertype String
     * @type {string}
     */
    DisplayColor: string = "";

    /**
     * @name SystemTaskProcessTemplateId
     * @description 
     * Process template for process to trigger for system task.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SystemTaskProcessTemplateId: number = null;

    /**
     * @name SystemTaskProcessPeerId
     * @description 
     * For processes that must execute peer operations chronologically this is a peer id used to identify peers.  When not specified
     * the process template id will be used to identify peers but providing a value here allows more definitive identification of
     * peers for scenarios where a template is used by non-peers.
     * @servertype String
     * @type {string}
     */
    SystemTaskProcessPeerId: string = "";

    /**
     * @name SystemTaskProcessWait
     * @description 
     * When true the task waits for the system task process to complete.  When false the system task is marked as done when the
     * process has been enqueued.
     * @servertype Boolean
     * @type {boolean}
     */
    SystemTaskProcessWait: boolean = true;

    /**
     * @name SystemTaskId
     * @description 
     * A system task identifier to indicate what type of system task or structured task needs to be performed.  When AssignmentFlag
     * is set to 'S' this is a system task performed by the system.  When the task is assigned to a contact this can be used to
     * identify a structured task that is performed by the assigned contact but with structured aids provided by the system.  
     * Some examples of system tasks include:
     * Sql-* = Execute sql script found in SystemTaskAction.  The * can represent any value to uniquely identify this system task.
     * Some examples of structured tasks include:
     * AssetRequestPublic = Asset was requested to be made public and needs approval.
     * AssetRequestAccess = Asset was requested to be made available for access.
     * AssetRequestApproval = Asset was requested to be approved.
     * @servertype String
     * @type {string}
     */
    SystemTaskId: string = "";

    /**
     * @name SystemTaskAction
     * @description 
     * A JSON object outlining the action to perform for a system task or assist with for a structured task.  The JSON format varies
     * by system task id, type and version but have some properties in common.  Properties that are custom are stored in the dynamic
     * properties child object.  For example: 
     * {
     *    "SystemTaskId": "BillingProfile"
     *    "Type": "BillingProfile"
     *    "Version": 1
     *    "Value": ""
     *    "Properties": {}
     * }
     * @servertype IB.Web.API.Models.v5.TaskSystemTaskActionEditViewModel
     * @type {TaskSystemTaskActionEditViewModel}
     */
    SystemTaskAction: TaskSystemTaskActionEditViewModel = null;

    /**
     * @name SystemTaskCompleteFlag
     * @description 
     * A flag to indicate when this system task can be completed.  Only valid when the task template assignment flag is 'S'.  Possible
     * values include:
     * D = Complete based on effective date
     * A = Complete based on action defined in SystemTaskCompleteAction
     * B = Complete based on both effective date and action
     * @servertype String
     * @type {string}
     */
    SystemTaskCompleteFlag: string = "B";

    /**
     * @name SystemTaskCompleteAction
     * @description 
     * A comma delimited list of actions that can trigger the task to be completed.  Only valid when task template assignment flag
     * is 'S'.  Possible values include:
     * @servertype string array
     * @type {string[]}
     */
    SystemTaskCompleteAction: string[] = [];

    /**
     * @name Subtasks
     * @servertype TaskSubtaskTemplateEditViewModel array
     * @type {TaskSubtaskTemplateEditViewModel[]}
     */
    Subtasks: TaskSubtaskTemplateEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TaskSubtaskTemplateEditViewModel
 * @description
 * The Task Subtask Template table contains information regarding subtasks.
 */
export class TaskSubtaskTemplateEditViewModel {

    /**
     * @name TaskSubtaskTemplateId
     * @description 
     * Uniquely identifies this subtask template.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskSubtaskTemplateId: number = null;

    /**
     * @name TaskTemplateId
     * @description 
     * The id of the task template this subtask belongs to.
     * @servertype Int64
     * @type {number}
     */
    TaskTemplateId: number = null;

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the subtask in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @description 
     * Description for this subtask.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Comments
     * @description 
     * Comments for this subtask.  Allows more detailed information than provided for in the description.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TaskStatusWhenComplete
     * @description 
     * Value to set task status to when this subtask is completed.
     * @servertype String
     * @type {string}
     */
    TaskStatusWhenComplete: string = "";

    /**
     * @name TaskPercentDoneWhenComplete
     * @description 
     * Value to set task progress (percent completed 0%-100%) when this subtask is completed.
     * @servertype Byte (nullable)
     * @type {number}
     */
    TaskPercentDoneWhenComplete: number = 0;

    /**
     * @name AutoComplete
     * @description 
     * When true this subtask is automatically completed if the task the subtask belongs to is marked as completed.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoComplete: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TaskListEditViewModel
 * @description
 * The task list table contains information regarding tasks lists.
 */
export class TaskListEditViewModel {

    /**
     * @name TaskListId
     * @description 
     * Uniquely identifies this task list.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskListId: number = null;

    /**
     * @name TaskListTemplateId
     * @description 
     * The id of the task list template used for this list (if any).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskListTemplateId: number = null;

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the task list in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @description 
     * Description for this task list.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Comments
     * @description 
     * Comments for this task list.  Allows more detailed information than provided for in the description.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name EndDateTime
     * @description 
     * The date and time when the task list is to end.  This is a roll-up of the latest task end date time value for this list.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EndDateTime: Date = null;

    /**
     * @name EstimatedCloseDateTime
     * @description 
     * The date and time when the task list is estimated to close.  This is a roll-up of the latest task estimated close date time
     * value for this list.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EstimatedCloseDateTime: Date = null;

    /**
     * @name ActualCloseDateTime
     * @description 
     * The date and time when the task list is actually closed.  This is a roll-up of the latest task actual close date time value
     * for this list.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActualCloseDateTime: Date = null;

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) for this task list.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this task list.  For example, if the owner resource type
     * is Case this value would be the CaseId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name Tasks
     * @servertype TaskEditViewModel array
     * @type {TaskEditViewModel[]}
     */
    Tasks: TaskEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TaskEditViewModel
 * @description
 * The task table contains tasks for task lists in the task list table.
 */
export class TaskEditViewModel {

    /**
     * @name TaskId
     * @description 
     * Uniquely identifies this task.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskId: number = null;

    /**
     * @name TaskListId
     * @description 
     * The id of the task list this task belongs to.
     * @servertype Int64
     * @type {number}
     */
    TaskListId: number = null;

    /**
     * @name TaskType
     * @description 
     * Task type.  Possible values include:
     * T = Task / To-do Item
     * E = Event / Meeting / Appointment
     * W = Workflow Task
     * The behavior and features available to tasks will differ based on the task type assigned.  Tasks (e.g. to-do items) have
     * no dependency on other tasks for start but can be tied to another task for when it is due to be completed.
     * Events (e.g. meetings or appointments) are treated like calendar events but can be tied to another task for when it is due
     * to be started.
     * Workflow tasks are part of a scheduled workflow and can have dependency on other tasks for start as well as being tied to
     * another task for start and completion.
     * @servertype String
     * @type {string}
     */
    TaskType: string = "T";

    /**
     * @name Category
     * @description 
     * Category of this task.  Options provided by system pick lists based on TaskType.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the task in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @description 
     * Description for this task or subtask.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Comments
     * @description 
     * Comments for this task or subtask.  Allows more detailed information than provided for in the description.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Priority
     * @description 
     * The default priority of this task.  Possible values include:
     * -1 = Low
     *  0 = Normal
     *  1 = High
     * @servertype Int16 (nullable)
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name Status
     * @description 
     * Current status of this task.  Options provided by system pick list.
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name PercentDone
     * @description 
     * Progress of this task stated as percent completed 0%-100%.
     * @servertype Byte (nullable)
     * @type {number}
     */
    PercentDone: number = 0;

    /**
     * @name NextReminderDateTime
     * @description 
     * The date and time when the next reminder is to be triggered.  See template task ReminderStart and ReminderEnd columns for
     * more information.  When no more reminders are scheduled this value will be null.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NextReminderDateTime: Date = null;

    /**
     * @name EstimatedStartDateTime
     * @description 
     * The date and time when the workflow task is estimated to be started.  Will normally be a task related event date or the actual
     * close date of the task this task is dependent on with any lag factored in.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EstimatedStartDateTime: Date = null;

    /**
     * @name StartDateTime
     * @description 
     * The date and time when the workflow task was started.  Will normally be a task related event date or the actual close date
     * of the task this task is dependent on with any lag factored in.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name EndDateTime
     * @description 
     * The date and time when the task is to end.  For event task type this is when the event is scheduled to end.  For workflow
     * task types see the end date treatment set in the task template.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EndDateTime: Date = null;

    /**
     * @name EstimatedCloseDateTime
     * @description 
     * The date and time when the task is estimated to close.  This can be considered the due date for the task and may be a calculated
     * value for workflow tasks.  This value can be combined with the actual close date time field to help identify problems with
     * workflow or estimated workflow.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EstimatedCloseDateTime: Date = null;

    /**
     * @name ActualCloseDateTime
     * @description 
     * The date and time when the task is actually closed.  This value can be combined with the estimated close date time field
     * to help identify problems with workflow or estimated workflow.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ActualCloseDateTime: Date = null;

    /**
     * @name ActualTimeMinutes
     * @description 
     * Actual time in minutes needed to complete this task.  This is a computed value which rolls up from the time log.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ActualTimeMinutes: number = 0;

    /**
     * @name RelatedToTaskId
     * @description 
     * Task id that this task is related to.  This value accomplishes the following: 
     * For tasks this allows tasks to be guaranteed to be assigned to the same individual for efficiency or other reasons regardless
     * of any task work load calculations that might be otherwise applied.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RelatedToTaskId: number = null;

    /**
     * @name AssignedToContactId
     * @description 
     * The id of the contact that is assigned as owner of this task.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssignedToContactId: number = null;

    /**
     * @name AssignedToContactName
     * @servertype String
     * @type {string}
     */
    AssignedToContactName: string = "";

    /**
     * @name AssignedByContactId
     * @description 
     * The id of the contact that assigned the task.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssignedByContactId: number = null;

    /**
     * @name AssignedByContactName
     * @servertype String
     * @type {string}
     */
    AssignedByContactName: string = "";

    /**
     * @name SupervisorContactId
     * @description 
     * The id of the contact that has supervisor responsibility for this task.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SupervisorContactId: number = null;

    /**
     * @name SupervisorContactName
     * @servertype String
     * @type {string}
     */
    SupervisorContactName: string = "";

    /**
     * @name Participants
     * @description 
     * A list of participants for this task.  These are secondary to who the task is assigned to.
     * @servertype ContactReferenceViewModel array
     * @type {ContactReferenceViewModel[]}
     */
    Participants: ContactReferenceViewModel[] = [];

    /**
     * @name Billable
     * @description 
     * When true the task is considered billable.
     * @servertype Boolean
     * @type {boolean}
     */
    Billable: boolean = true;

    /**
     * @name BillingRate
     * @description 
     * The hourly billing rate for this task.
     * @servertype Double (nullable)
     * @type {number}
     */
    BillingRate: number = 0;

    /**
     * @name Budget
     * @description 
     * The budget for this task in fees or hours depending on the budget type.
     * @servertype Double (nullable)
     * @type {number}
     */
    Budget: number = 0;

    /**
     * @name TaskTemplateId
     * @description 
     * For tasks that are derived from a template this value identifies the task template.  This is used to facilitate mapping of
     * relationships between tasks so dependencies declared in a template can be implemented correctly since task id values used
     * in the template will not be the same as the task id values used in the owning object that implements that template.  This
     * Can also be used when extended attributes are present.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskTemplateId: number = null;

    /**
     * @name SystemTaskProcessDateTime
     * @description 
     * If a system task process was triggered this is the date and time when the process was created.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SystemTaskProcessDateTime: Date = null;

    /**
     * @name SystemTaskProcessId
     * @description 
     * If a system task process was triggered this is the process id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SystemTaskProcessId: number = null;

    /**
     * @name SystemTaskProcessStatus
     * @description 
     * Status of the system task process (if any).  Possible values are: 
     * H = Hold (Process is not ready to begin)
     * U = Unknown (Process is status is unknown)
     * P = Pending (Process is Pending)
     * R = Running (Process has Started)
     * S = Success (Process was Successful)
     * F = Failure (Process has Failed)
     * @servertype String
     * @type {string}
     */
    SystemTaskProcessStatus: string = "H";

    /**
     * @name SystemTaskId
     * @description 
     * A system task identifier to indicate what type of system task or structured task needs to be performed.  When task template
     * assignment flag is set to 'S' this is a system task performed by the system.  When the task is assigned to a contact this
     * can be used to identify a structured task that is performed by the assigned contact but with structured aids provided by
     * the system.  
     * Sql-* = Execute sql script found in SystemTaskAction.  The * can represent any value to uniquely identify this system task.
     * Process-[TemplateId]-[PeerId] = Process with noted template and peer id values.
     * Some examples of structured tasks include:
     * ViewUrl = View a URL.  This may be release notes for a new version, updated EULA, etc.
     * AssetRequestPublic-* = Asset was requested to be made public and needs approval.  Here * represents the AssetId to help prevent
     * requests from being repeated.
     * AssetRequestPublicAccept = Accept - Asset was requested to be made public and needs approval.
     * AssetRequestPublicReject = Reject - Asset was requested to be made public and needs approval.
     * AssetRequestAccess-* = Asset was requested to be made available for access.  Here * represents the AssetId to help prevent
     * requests from being repeated.
     * AssetRequestAccessAccept = Accept - Asset was requested to be made available for access.
     * AssetRequestAccessReject = Reject - Asset was requested to be made available for access.
     * AssetRequestApproval-* = Asset was requested to be approved.  Here * represents the AssetId to help prevent requests from
     * being repeated.
     * @servertype String
     * @type {string}
     */
    SystemTaskId: string = "";

    /**
     * @name SystemTaskAction
     * @description 
     * A JSON object outlining the action to perform for a system task or assist with for a structured task.  The JSON format varies
     * by system task id, type and version but have some properties in common.  Properties that are custom are stored in the dynamic
     * properties child object.  For example: 
     * {
     *    "SystemTaskId": "BillingProfile"
     *    "Type": "BillingProfile"
     *    "Version": 1
     *    "Value": ""
     *    "Properties": {}
     * }
     * @servertype IB.Web.API.Models.v5.TaskSystemTaskActionEditViewModel
     * @type {TaskSystemTaskActionEditViewModel}
     */
    SystemTaskAction: TaskSystemTaskActionEditViewModel = null;

    /**
     * @name SystemTaskCompleteFlag
     * @description 
     * A flag to indicate when this system task can be completed.  Only valid when task template assignment flag is 'S'.  Possible
     * values include:
     * D = Complete based on effective date
     * A = Complete based on action defined in SystemTaskCompleteAction
     * B = Complete based on both effective date and action
     * @servertype String
     * @type {string}
     */
    SystemTaskCompleteFlag: string = "B";

    /**
     * @name SystemTaskCompleteAction
     * @description 
     * A comma delimited list of actions that can trigger the task to be completed.  Only valid when task template assignment flag
     * is 'S'.  Possible values include:
     * @servertype String
     * @type {string}
     */
    SystemTaskCompleteAction: string = "";

    /**
     * @name SystemTaskCompleteDate
     * @description 
     * The date when the task can be completed by the system.  Only valid when task template assignment flag is 'S'.  Note that
     * this date must be provided before a system task will be completed even if SystemTaskCompleteFlag is 'A' since each system
     * task requires additional settings that are assigned after the owning object is created and the task activated (i.e. we don't
     * want to try to complete the task before we've been told what to do).
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SystemTaskCompleteDate: Date = null;

    /**
     * @name SystemTaskParameters
     * @description 
     * A JSON object outlining the parameters needed to perform a system task or assist with a structured task.  The JSON format
     * varies by system task id, type, and version but have some properties in common.  Properties that are custom are stored in
     * the dynamic properties child object.  For example: 
     * {
     *    "SystemTaskId": "BillingProfile"
     *    "Type": "BillingProfile"
     *    "Version": 1
     *    "CanReassign": true
     *    "CanEdit": true
     *    "CanCopy": true
     *    "CanChooseCompletionDate": true
     *    "CanDelete": true
     *    "Properties": {
     *       "Value": ""
     *       "TargetId": 12345
     *       "BillingProfileId": 12345
     *       "PackageId": 12345
     *       "PackageOccurrenceId": 12345
     *       "PackageType": "Voice"
     *       "PackageClass": "Cisco"
     *       "RequestedByContactId": 112233
     *       "RequestedByContactName": "Jon Doe"
     *    }
     * }
     * The properties child object will vary based on system task id.
     * The ViewUrl system tasks will have properties that include:
     * UrlLink = The url to view.
     * UrlText = The text to show for the url link.
     * UrlType = Possible values include 'Information', 'LicenseAgreement', 'PrivacyPolicy' the value here may result in additional
     * steps happening when the task is completed.
     * OpenInNewWindow = When true the link will open in a new window.
     * SignatureRequired = When true completing the task will require an electronic signature.
     * SignatureMessage = When a signature is required this is the message to show with it.
     * The AssetRequestPublic and AssetRequestAccess system tasks will have properties that include:
     * AssetId = The id of the asset this request is about.
     * AssetTitle = The title of the asset this request is about.
     * RequestedByContactId = The id of the contact making the request.
     * RequestedByContactName = The name of the contact making the request.
     * The AssetRequestAccess system task may have the same asset requested multiple times so it also has these properties: 
     * RequestedByContactIdList = A list of id values for contacts that have made this same request.
     * RequestedByContactNameList = A list of names for contacts that have made this same request.
     * @servertype IB.Web.API.Models.v5.TaskSystemTaskParametersEditViewModel
     * @type {TaskSystemTaskParametersEditViewModel}
     */
    SystemTaskParameters: TaskSystemTaskParametersEditViewModel = null;

    /**
     * @name SystemTaskResults
     * @description 
     * A JSON object outlining the results generated by a system task or structured task.  The JSON format varies by system task
     * id, type, and version but have some properties in common.  Properties that are custom are stored in the dynamic properties
     * child object.  For example: 
     * {
     *    "SystemTaskId": "BillingProfile"
     *    "Type": "BillingProfile"
     *    "Version": 1
     *    "ResultCode": 0
     *    "ResultMessage": "Success"
     *    "Properties": {
     *       "ResultObjectId": 123482281
     *    }
     * }
     * The properties child object will vary based on system task id.
     * Most system tasks will have properties that include:
     * ResponseByContactId = The id of the contact responding to the task.
     * ResponseByContactName = The name of the contact responding to the task.
     * ResponseDateTime = The date and time the response was submitted.
     * ResponseFromIpAddress = The ip address for the user responding to the task.
     * The ViewUrl system task will also have this property: 
     * SignatureTyped = The typed signature provided by the user when completing task.
     * @servertype IB.Web.API.Models.v5.TaskSystemTaskResultsEditViewModel
     * @type {TaskSystemTaskResultsEditViewModel}
     */
    SystemTaskResults: TaskSystemTaskResultsEditViewModel = null;

    /**
     * @name Subtasks
     * @servertype TaskSubtaskEditViewModel array
     * @type {TaskSubtaskEditViewModel[]}
     */
    Subtasks: TaskSubtaskEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TaskSubtaskEditViewModel
 * @description
 * The task subtask table contains subtasks for tasks.
 */
export class TaskSubtaskEditViewModel {

    /**
     * @name TaskSubtaskId
     * @description 
     * Uniquely identifies this subtask.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskSubtaskId: number = null;

    /**
     * @name TaskId
     * @description 
     * The id of the task this subtask belongs to.
     * @servertype Int64
     * @type {number}
     */
    TaskId: number = null;

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the subtask in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @description 
     * Description for this subtask.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Comments
     * @description 
     * Comments for this subtask.  Allows more detailed information than provided for in the description.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name ClosedDateTime
     * @description 
     * The date and time when the subtask was closed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ClosedDateTime: Date = null;

    /**
     * @name ClosedByContactId
     * @description 
     * The Contact Id of the contact who, either directly or indirectly, closed this subtask.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ClosedByContactId: number = null;

    /**
     * @name ClosedByContactName
     * @servertype String
     * @type {string}
     */
    ClosedByContactName: string = "";

    /**
     * @name TaskSubtaskTemplateId
     * @description 
     * For subtasks that are derived from a template this value identifies the task subtask template.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaskSubtaskTemplateId: number = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TimeLogEditViewModel
 * @description
 * The TimeLog table contains log of time spent for another object.
 */
export class TimeLogEditViewModel {

    /**
     * @name TimeLogId
     * @description 
     * The id of the time log.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TimeLogId: number = null;

    /**
     * @name EnteredByContactId
     * @description 
     * The Contact Id that represents who entered this time log entry.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    EnteredByContactId: number = null;

    /**
     * @name EnteredByContactName
     * @servertype String
     * @type {string}
     */
    EnteredByContactName: string = "";

    /**
     * @name Type
     * @description 
     * The type for this time log entry.  Options provided by system pick list.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Code
     * @description 
     * The code for this time log entry.  Options provided by system pick list.
     * @servertype String
     * @type {string}
     */
    Code: string = "";

    /**
     * @name Class
     * @description 
     * The class for this time log entry.  Options provided by system pick list.
     * @servertype String
     * @type {string}
     */
    Class: string = "";

    /**
     * @name Category
     * @description 
     * The category for this time log entry.  Options provided by system pick list.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name StartDateTime
     * @description 
     * The date and time when the work started.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name EndDateTime
     * @description 
     * The date and time when the work ended.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EndDateTime: Date = null;

    /**
     * @name Minutes
     * @description 
     * The number of minutes for this time log entry.  This may be calculated based on StartDateTime and EndDateTime or may be user
     * entered in situations where no start and end date time are available.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Minutes: number = 0;

    /**
     * @name Description
     * @description 
     * A description for this time log entry.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Tags
     * @description 
     * A comma separated list of tags for this time log entry.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name Billable
     * @description 
     * True indicates the time log entry is billable.
     * @servertype Boolean
     * @type {boolean}
     */
    Billable: boolean = false;

    /**
     * @name ItemId
     * @description 
     * Item Id to use for billing this time log entry.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ItemId: number = null;

    /**
     * @name RateId
     * @description 
     * The rate id is used to identify the rate to be used.
     * @servertype String
     * @type {string}
     */
    RateId: string = "";

    /**
     * @name AdjustedMinutes
     * @description 
     * The adjusted number of minutes for this time log entry.  When time log is processed for billing there may be a minimum, maximum,
     * or billing duration increment that is applied to the time log.  The time log minutes after these adjustments are applied
     * is recorded here.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AdjustedMinutes: number = 0;

    /**
     * @name BillingTransactionId
     * @description 
     * The billing transaction id of the invoice this time log was invoiced on.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingTransactionId: number = null;

    /**
     * @name InvoiceNumber
     * @description 
     * An invoice number this time log was invoiced on.  Note that external invoicing systems will have TransactionId null.
     * @servertype String
     * @type {string}
     */
    InvoiceNumber: string = "";

    /**
     * @name InvoiceDate
     * @description 
     * The invoice date this time log was invoiced.  Note that external invoicing systems will have TransactionId null.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    InvoiceDate: Date = null;

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) for this time log entry.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this time log entry.  For example, if the owner resource
     * type is Cases this value would be the CaseId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name VisibilityEditViewModel
 * @description
 * The Visibility table contains look up information to restrict visibility of various items like assets, location profiles,
 * rating profiles, packages, etc.  For example, assets may be restricted by thinks like roles, inventory types covered by maintenance,
 * etc.  Another example is valid profiles may be restricted by an agent id, primary customer, id, etc.
 */
export class VisibilityEditViewModel {

    /**
     * @name VisibilityId
     * @description 
     * The id of the visibility item.
     * @servertype Int64
     * @type {number}
     */
    VisibilityId: number = null;

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) of this visibility item.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this visibility item.  For example, if the owner resource
     * type is Asset this value would be the AssetId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name Enabled
     * @description 
     * When true this visibility item is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name VisibilityType
     * @description 
     * An indicator of what visibility list type this entry is for.  The most common types are: 
     * Role = Role (visibility type id is the role id)
     * Group = Group (visibility type id is the contact id of the group)
     * Agent = Agent (visibility type id is the contact id of the agent)
     * PrimaryCustomer = Primary Customer (visibility type id is the contact id of the primary customer)
     * In addition to various tables, other possible values include:
     * PostalCode = Postal Code
     * State = State
     * CountryCode = Country Code
     * CountryCodeNpa = Country Code + NPA
     * CountryCodeNpaNxx = Country Code + NPA + NXX
     * LATA = LATA
     * Module = Module
     * Expression = A more complex expression with boolean logic where the type value list a list of expressions
     * Additionally, some custom types can be used in certain scenarios.  In situations where custom types are provided the routines
     * that test visibility scope need to be handed all possible type-value pairs since the custom type cannot be processed the
     * same as known types.
     * When the type is use constraint this is a template to use for building the string to check to make sure use is limited to
     * the use limit count value.  The template accepts typical values submitted for visibility checks plus: Date, Month, Year.
     *  For example: '{{Date}}', '{{Year}}-{{Month}}-{{FlightNumber}}', etc.
     * @servertype String
     * @type {string}
     */
    VisibilityType: string = "";

    /**
     * @name VisibilityTypeId
     * @description 
     * The visibility type id is the key of the visibility type for this visibility item.  For example, if the visibility type is
     * Role this value would be the RoleId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VisibilityTypeId: number = 0;

    /**
     * @name VisibilityTypeId2
     * @description 
     * The visibility type id 2 is the key of the visibility type when that id is a string data type.  If the visibility type is
     * not mapped to a table (e.g. state, postal code, etc.) then this is the value for that type.
     * @servertype String
     * @type {string}
     */
    VisibilityTypeId2: string = "";

    /**
     * @name VisibilityTypeId2EndRange
     * @description 
     * The end of a visibility type id range when a range of values is being used.  Can be used to restrict items to a range of
     * db versions, postal codes, etc.
     * @servertype String
     * @type {string}
     */
    VisibilityTypeId2EndRange: string = "";

    /**
     * @name VisibilityTypeValueList
     * @description 
     * A comma separated list of values that are valid for the specified visibility type.
     * @servertype string array
     * @type {string[]}
     */
    VisibilityTypeValueList: string[] = [];

    /**
     * @name VisibilityTypeScope
     * @description 
     * Any additional scope that is applied to the visibility type.  For example:
     * ValidMaintenance = The inventory type specified in the visibility type also must have a valid maintenance plan.
     * @servertype String
     * @type {string}
     */
    VisibilityTypeScope: string = "";

    /**
     * @name IncludeSubvalues
     * @description 
     * When true items that are considered subvalues are also in scope for visibility (e.g. sub-agents).
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeSubvalues: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name SyncArticleDetailEditViewModel
 * @description
 * The Sync Article Detail table contains information about the details of articles being synced in non-pointer mode.
 */
export class SyncArticleDetailEditViewModel {

    /**
     * @name SyncArticleDetailId
     * @description 
     * The id of the sync article detail item.
     * @servertype Int64
     * @type {number}
     */
    SyncArticleDetailId: number = null;

    /**
     * @name SyncArticleId
     * @description 
     * The id of the sync article this detail belongs to.
     * @servertype Int64
     * @type {number}
     */
    SyncArticleId: number = null;

    /**
     * @name PublishedDateTime
     * @description 
     * The date and time when the article was published.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    PublishedDateTime: Date = new Date();

    /**
     * @name Property
     * @description 
     * The property name of the article detail being published.
     * @servertype String
     * @type {string}
     */
    Property: string = "";

    /**
     * @name Value
     * @description 
     * The value of the article detail being published.
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name SyncArticleSubscriptionEditViewModel
 * @description
 * The Sync Article Subscription table contains information about the state of each subscription to each article published.
 */
export class SyncArticleSubscriptionEditViewModel {

    /**
     * @name SyncArticleSubscriptionId
     * @description 
     * The id of the sync article subscription.
     * @servertype Int64
     * @type {number}
     */
    SyncArticleSubscriptionId: number = null;

    /**
     * @name SyncArticleId
     * @description 
     * The id of the sync article being delivered to the subscriber.
     * @servertype Int64
     * @type {number}
     */
    SyncArticleId: number = null;

    /**
     * @name PublishedDateTime
     * @description 
     * The date and time when the article was published.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    PublishedDateTime: Date = new Date();

    /**
     * @name SyncSubscriptionId
     * @description 
     * The id of the sync subscription.
     * @servertype Int64
     * @type {number}
     */
    SyncSubscriptionId: number = null;

    /**
     * @name SyncDataStoreId
     * @description 
     * The id of the sync data store that is the subscriber.
     * @servertype Int64
     * @type {number}
     */
    SyncDataStoreId: number = null;

    /**
     * @name Status
     * @description 
     * The article subscription status.  Possible values include:
     * W = Waiting for Approval
     * P = Pending
     * R = Running
     * S = Success
     * F = Failure
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name Result
     * @description 
     * A result message.  Typically null unless the article subscription failed.
     * @servertype String
     * @type {string}
     */
    Result: string = "";

    /**
     * @name AutoApproved
     * @description 
     * When true this article subscription was automatically approved.
     * @servertype Boolean
     * @type {boolean}
     */
    AutoApproved: boolean = false;

    /**
     * @name ApprovedDateTime
     * @description 
     * The date and time when the article was approved.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ApprovedDateTime: Date = null;

    /**
     * @name ApprovedBy
     * @description 
     * The name of who approved this row.
     * @servertype String
     * @type {string}
     */
    ApprovedBy: string = "";

    /**
     * @name ApprovedProperties
     * @description 
     * A comma separated list of properties that were approved.  If null then all were approved.
     * @servertype String
     * @type {string}
     */
    ApprovedProperties: string = "";

    /**
     * @name ExcludedProperties
     * @description 
     * A comma separated list of properties that were excluded.  If null then all were approved.
     * @servertype String
     * @type {string}
     */
    ExcludedProperties: string = "";

    /**
     * @name LastUpdateDateTime
     * @description 
     * The date and time when the process status was last updated.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUpdateDateTime: Date = null;

    /**
     * @name FinishedDateTime
     * @description 
     * The date and time when the article subscription was finished.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FinishedDateTime: Date = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name SyncArticleEditViewModel
 * @description
 * The Sync Article table contains information about articles being synced.
 */
export class SyncArticleEditViewModel {

    /**
     * @name SyncArticleId
     * @description 
     * The id of the sync article.
     * @servertype Int64
     * @type {number}
     */
    SyncArticleId: number = null;

    /**
     * @name SyncPublisherArticleTypeId
     * @description 
     * The sync publisher article type id this article belongs to.
     * @servertype Int64
     * @type {number}
     */
    SyncPublisherArticleTypeId: number = null;

    /**
     * @name ArticleKeyId
     * @description 
     * The key id of the article being published (e.g. ContactId of Contact table).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ArticleKeyId: number = 0;

    /**
     * @name ArticleKeyId2
     * @description 
     * The non-integer id of the article being published.
     * @servertype String
     * @type {string}
     */
    ArticleKeyId2: string = "";

    /**
     * @name EventType
     * @description 
     * The type of event being published.  Possible values include:
     * A = Add
     * E = Edit
     * D = Delete
     * @servertype String
     * @type {string}
     */
    EventType: string = "";

    /**
     * @name PublishedDateTime
     * @description 
     * The date and time when the article was published.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    PublishedDateTime: Date = new Date();

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name SyncDataStoreEditViewModel
 * @description
 * The Sync Data Store table contains information about databases or other data stores related to syncing.  For publisher databases
 * both publishers and subscribers should be defined in this table.  For subscriber only databases only the publisher should
 * be defined in this table.
 */
export class SyncDataStoreEditViewModel {

    /**
     * @name SyncDataStoreId
     * @description 
     * The id of the sync data store.
     * @servertype Int64
     * @type {number}
     */
    SyncDataStoreId: number = null;

    /**
     * @name Description
     * @description 
     * The description for this sync data store.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DatabaseId
     * @description 
     * The database id of the data store.
     * @servertype String
     * @type {string}
     */
    DatabaseId: string = "";

    /**
     * @name PartitionId
     * @description 
     * If null all partitions are included in the sync.  Otherwise, only the specified partition is included in the sync.  If not
     * all partitions are included then each partition included in the sync needs its own sync data store record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name Region
     * @description 
     * If the sync data store is related to a specific region this is the region.
     * @servertype String
     * @type {string}
     */
    Region: string = "";

    /**
     * @name DataFolder
     * @description 
     * The data folder of the data store.
     * @servertype String
     * @type {string}
     */
    DataFolder: string = "";

    /**
     * @name Login
     * @description 
     * The login name of the operator context for the data store.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name ApiKey
     * @description 
     * The api key context for the data store.
     * @servertype String
     * @type {string}
     */
    ApiKey: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name SyncPublisherArticleTypeEditViewModel
 * @description
 * The Sync Publisher Article Type table contains information about article types being published.
 */
export class SyncPublisherArticleTypeEditViewModel {

    /**
     * @name SyncPublisherArticleTypeId
     * @description 
     * The id of the sync publisher article type.
     * @servertype Int64
     * @type {number}
     */
    SyncPublisherArticleTypeId: number = null;

    /**
     * @name SyncDataStoreId
     * @description 
     * The id of the sync data store that is the publisher.
     * @servertype Int64
     * @type {number}
     */
    SyncDataStoreId: number = null;

    /**
     * @name Description
     * @description 
     * The description for this sync publisher article type.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * When true this publisher article type is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ArticleType
     * @description 
     * The article type being published.  This is typically the table name of the table that holds the data to publish.
     * @servertype String
     * @type {string}
     */
    ArticleType: string = "";

    /**
     * @name ArticleFilter
     * @description 
     * A filter applied to the article to determine if it should be published or not.  If not specified no filter is applied.  If
     * specified the filter conditions must return true for the article to be published.
     * @servertype String
     * @type {string}
     */
    ArticleFilter: string = "";

    /**
     * @name IdentityRangeStart
     * @description 
     * If only a range of id values are being synced this is the starting value in that range.  Using a range of id values allows
     * the publisher to have both private and synced values if needed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    IdentityRangeStart: number = 0;

    /**
     * @name IdentityRangeEnd
     * @description 
     * If only a range of id values are being synced this is the ending value in that range.  Using a range of id values allows
     * the publisher to have both private and synced values if needed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    IdentityRangeEnd: number = 0;

    /**
     * @name SyncMode
     * @description 
     * Flag used to indicate the mode to use for syncing.  Possible values include:
     * P = Pointer
     * @servertype String
     * @type {string}
     */
    SyncMode: string = "P";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name SyncSubscriptionEditViewModel
 * @description
 * The Sync Subscription table contains information about subscriptions.
 */
export class SyncSubscriptionEditViewModel {

    /**
     * @name SyncSubscriptionId
     * @description 
     * The id of the sync subscription.
     * @servertype Int64
     * @type {number}
     */
    SyncSubscriptionId: number = null;

    /**
     * @name SyncPublisherArticleTypeId
     * @description 
     * The sync publisher article type id being subscribed to.
     * @servertype Int64
     * @type {number}
     */
    SyncPublisherArticleTypeId: number = null;

    /**
     * @name SyncDataStoreId
     * @description 
     * The id of the sync data store that is the subscriber.
     * @servertype Int64
     * @type {number}
     */
    SyncDataStoreId: number = null;

    /**
     * @name Description
     * @description 
     * The description for this sync subscription.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * When true this subscription is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name SubscribeToAddEvents
     * @description 
     * When true this subscription includes add events.
     * @servertype Boolean
     * @type {boolean}
     */
    SubscribeToAddEvents: boolean = true;

    /**
     * @name SubscribeToEditEvents
     * @description 
     * When true this subscription includes edit events.
     * @servertype Boolean
     * @type {boolean}
     */
    SubscribeToEditEvents: boolean = true;

    /**
     * @name SubscribeToDeleteEvents
     * @description 
     * When true this subscription includes delete events.
     * @servertype Boolean
     * @type {boolean}
     */
    SubscribeToDeleteEvents: boolean = true;

    /**
     * @name RealTimeDelivery
     * @description 
     * True indicates the subscription requests real-time delivery when possible.
     * @servertype Boolean
     * @type {boolean}
     */
    RealTimeDelivery: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Data Source
 */
export class DataSourceListViewModel {

    /**
     * @name DataSourceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DataSourceId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DataFolder
     * @servertype String
     * @type {string}
     */
    DataFolder: string = "";

    /**
     * @name FailoverDataFolder
     * @servertype String
     * @type {string}
     */
    FailoverDataFolder: string = "";

    /**
     * @name ConnectionString
     * @servertype String
     * @type {string}
     */
    ConnectionString: string = "";

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name Type
     * @servertype IB.Web.API.Models.v5.DataSourceType Enum
     * @type {DataSourceType}
     */
    Type: DataSourceType = null;

}



/**
 * @name Currency List
 * @description
 * The currency table contains information related to currency and display formatting for currency.
 */
export class CurrencyListViewModel {

    /**
     * @name IsoCurrencyCode
     * @description 
     * ISO Currency Code for this currency.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Description
     * @description 
     * Currency Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

}



/**
 * @name CurrencyEditViewModel
 * @description
 * The currency table contains information related to currency and display formatting for currency.
 */
export class CurrencyEditViewModel {

    /**
     * @name CurrencyId
     * @description 
     * Currency Id uniquely identifies this currency instance.
     * @servertype Int64
     * @type {number}
     */
    CurrencyId: number = null;

    /**
     * @name IsoCurrencyCode
     * @description 
     * ISO Currency Code for this currency.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Description
     * @description 
     * Currency Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DecimalSeparator
     * @description 
     * Decimal separator.
     * @servertype String
     * @type {string}
     */
    DecimalSeparator: string = "";

    /**
     * @name Decimals
     * @description 
     * Number of decimals.
     * @servertype Byte (nullable)
     * @type {number}
     */
    Decimals: number = 0;

    /**
     * @name DetailDecimals
     * @description 
     * Number of decimals for usage or other fine detail.  This is frequently higher than the standard number of decimals for a
     * currency.  For example, USD is 2 decimals but for usage detail we may want to display 4 decimals because of sub-cent rating.
     * @servertype Byte (nullable)
     * @type {number}
     */
    DetailDecimals: number = 0;

    /**
     * @name GroupSeparator
     * @description 
     * Group separator.
     * @servertype String
     * @type {string}
     */
    GroupSeparator: string = "";

    /**
     * @name DigitsInGroup
     * @description 
     * Number of digits in group.
     * @servertype Byte (nullable)
     * @type {number}
     */
    DigitsInGroup: number = 0;

    /**
     * @name CurrencyMarker
     * @description 
     * Currency marker ($, etc.).
     * @servertype String
     * @type {string}
     */
    CurrencyMarker: string = "";

    /**
     * @name CurrencyMarkerAfterAmount
     * @description 
     * When true the currency marker goes after the amount.
     * @servertype Boolean
     * @type {boolean}
     */
    CurrencyMarkerAfterAmount: boolean = false;

    /**
     * @name CurrencyMarkerSpaceSeparated
     * @description 
     * When true the currency marker has a space between it and the amount.
     * @servertype Boolean
     * @type {boolean}
     */
    CurrencyMarkerSpaceSeparated: boolean = false;

    /**
     * @name NegativeMarkerFlag
     * @description 
     * Flag that indicates how negative numbers should be displayed.  Possible values include: 
     * - = Negative marker (-) before the number - i.e. -$999,999.99
     * + = Negative marker (-) after the number - i.e. $999,999.99-
     * ( = Negative number displayed in parenthesis - i.e. ($999,999.99)
     * @servertype String
     * @type {string}
     */
    NegativeMarkerFlag: string = "-";

    /**
     * @name NegativeMarkerSpaceSeparated
     * @description 
     * When true the negative marker has a space between it and the amount.
     * @servertype Boolean
     * @type {boolean}
     */
    NegativeMarkerSpaceSeparated: boolean = false;

    /**
     * @name NegativeMarkerOutsideCurrencyMarker
     * @description 
     * When true the negative marker is outside of the currency marker (e.g. -$8.99).  When false the negative marker is inside
     * the currency marker (e.g. $-8.99).
     * @servertype Boolean
     * @type {boolean}
     */
    NegativeMarkerOutsideCurrencyMarker: boolean = true;

    /**
     * @name Enabled
     * @description 
     * When true the currency is enabled.  Setting to false will hide this currency in the system.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Properties
     * @description 
     * Additional properties relevant to the currency.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name IDataImportResultModel
 */
export class IDataImportResultModel {

    /**
     * @name OriginalFileName
     * @servertype String
     * @type {string}
     */
    OriginalFileName: string = "";

    /**
     * @name MimeFileName
     * @servertype String
     * @type {string}
     */
    MimeFileName: string = "";

    /**
     * @name FinalFileName
     * @servertype String
     * @type {string}
     */
    FinalFileName: string = "";

    /**
     * @name FinalFileFullPath
     * @servertype String
     * @type {string}
     */
    FinalFileFullPath: string = "";

    /**
     * @name Extension
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name ContentType
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name DryRun
     * @servertype Boolean
     * @type {boolean}
     */
    DryRun: boolean = false;

    /**
     * @name ExcelMetaData
     * @servertype IB.Web.API.Models.v5.IDataImportExportMetaDataModel
     * @type {IDataImportExportMetaDataModel}
     */
    ExcelMetaData: IDataImportExportMetaDataModel = null;

    /**
     * @name DataEntryCountParsed
     * @servertype Int32
     * @type {number}
     */
    DataEntryCountParsed: number = 0;

    /**
     * @name DataEntryCountAdded
     * @servertype Int32
     * @type {number}
     */
    DataEntryCountAdded: number = 0;

    /**
     * @name DataEntryCountAlreadyAdded
     * @servertype Int32
     * @type {number}
     */
    DataEntryCountAlreadyAdded: number = 0;

    /**
     * @name DataEntryCountUpdated
     * @servertype Int32
     * @type {number}
     */
    DataEntryCountUpdated: number = 0;

    /**
     * @name DataEntryCountDeleted
     * @servertype Int32
     * @type {number}
     */
    DataEntryCountDeleted: number = 0;

    /**
     * @name DataEntryCountAlreadyDeleted
     * @servertype Int32
     * @type {number}
     */
    DataEntryCountAlreadyDeleted: number = 0;

    /**
     * @name DataEntryCountNotChanged
     * @servertype Int32
     * @type {number}
     */
    DataEntryCountNotChanged: number = 0;

    /**
     * @name DataEntryCountWarning
     * @servertype Int32
     * @type {number}
     */
    DataEntryCountWarning: number = 0;

    /**
     * @name DataEntryCountError
     * @servertype Int32
     * @type {number}
     */
    DataEntryCountError: number = 0;

    /**
     * @name DataEntriesAdded
     * @servertype object array
     * @type {any[]}
     */
    DataEntriesAdded: any[] = [];

    /**
     * @name DataEntriesAlreadyAdded
     * @servertype object array
     * @type {any[]}
     */
    DataEntriesAlreadyAdded: any[] = [];

    /**
     * @name DataEntriesUpdated
     * @servertype object array
     * @type {any[]}
     */
    DataEntriesUpdated: any[] = [];

    /**
     * @name DataEntriesUpdatedReferences
     * @servertype object array
     * @type {any[]}
     */
    DataEntriesUpdatedReferences: any[] = [];

    /**
     * @name DataEntriesDeleted
     * @servertype object array
     * @type {any[]}
     */
    DataEntriesDeleted: any[] = [];

    /**
     * @name DataEntriesAlreadyDeleted
     * @servertype object array
     * @type {any[]}
     */
    DataEntriesAlreadyDeleted: any[] = [];

    /**
     * @name DataEntriesNotChanged
     * @servertype object array
     * @type {any[]}
     */
    DataEntriesNotChanged: any[] = [];

    /**
     * @name DataEntriesWarnings
     * @servertype object array
     * @type {any[]}
     */
    DataEntriesWarnings: any[] = [];

    /**
     * @name DataEntriesErrors
     * @servertype object array
     * @type {any[]}
     */
    DataEntriesErrors: any[] = [];

    /**
     * @name Notices
     * @servertype IDataImportNoticeModel array
     * @type {IDataImportNoticeModel[]}
     */
    Notices: IDataImportNoticeModel[] = [];

    /**
     * @name Warnings
     * @servertype IDataImportNoticeModel array
     * @type {IDataImportNoticeModel[]}
     */
    Warnings: IDataImportNoticeModel[] = [];

    /**
     * @name Errors
     * @servertype IDataImportNoticeModel array
     * @type {IDataImportNoticeModel[]}
     */
    Errors: IDataImportNoticeModel[] = [];

    /**
     * @name Trace
     * @servertype string array
     * @type {string[]}
     */
    Trace: string[] = [];

}



/**
 * @name IDataImportExportMetaDataModel
 */
export class IDataImportExportMetaDataModel {

    /**
     * @name TableName
     * @servertype String
     * @type {string}
     */
    TableName: string = "";

    /**
     * @name DateBasedTableBeginningDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DateBasedTableBeginningDate: Date = null;

    /**
     * @name DateBasedTableEndingDate
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DateBasedTableEndingDate: Date = null;

    /**
     * @name FilterId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FilterId: number = 0;

    /**
     * @name Filter
     * @servertype String
     * @type {string}
     */
    Filter: string = "";

    /**
     * @name FreeFormQueryText
     * @servertype String
     * @type {string}
     */
    FreeFormQueryText: string = "";

    /**
     * @name ComputedFilterExpression
     * @servertype String
     * @type {string}
     */
    ComputedFilterExpression: string = "";

    /**
     * @name Sort
     * @servertype String
     * @type {string}
     */
    Sort: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name PartitionIdPropertyName
     * @servertype String
     * @type {string}
     */
    PartitionIdPropertyName: string = "";

    /**
     * @name AddedDateTimePropertyName
     * @servertype String
     * @type {string}
     */
    AddedDateTimePropertyName: string = "";

    /**
     * @name AddedByContactIdPropertyName
     * @servertype String
     * @type {string}
     */
    AddedByContactIdPropertyName: string = "";

    /**
     * @name LastEditedDateTimePropertyName
     * @servertype String
     * @type {string}
     */
    LastEditedDateTimePropertyName: string = "";

    /**
     * @name LastEditedByContactIdPropertyName
     * @servertype String
     * @type {string}
     */
    LastEditedByContactIdPropertyName: string = "";

    /**
     * @name DeletedDateTimePropertyName
     * @servertype String
     * @type {string}
     */
    DeletedDateTimePropertyName: string = "";

    /**
     * @name DeletedByContactIdPropertyName
     * @servertype String
     * @type {string}
     */
    DeletedByContactIdPropertyName: string = "";

    /**
     * @name PrimaryKey
     * @servertype String
     * @type {string}
     */
    PrimaryKey: string = "";

    /**
     * @name PrimaryKeyDataType
     * @servertype String
     * @type {string}
     */
    PrimaryKeyDataType: string = "";

    /**
     * @name PrimaryKeyLabel
     * @servertype String
     * @type {string}
     */
    PrimaryKeyLabel: string = "";

    /**
     * @name DeleteFlagLabel
     * @servertype String
     * @type {string}
     */
    DeleteFlagLabel: string = "";

    /**
     * @name ColumnLabelMap
     * @servertype object array
     * @type {any[]}
     */
    ColumnLabelMap: any[] = [];

}



/**
 * @name IDataImportNoticeModel
 */
export class IDataImportNoticeModel {

    /**
     * @name Id
     * @servertype System.Object
     * @type {any}
     */
    Id: any = {};

    /**
     * @name OperationType
     * @servertype String
     * @type {string}
     */
    OperationType: string = "";

    /**
     * @name ResultCode
     * @servertype IB.Core.StandardResultCode Enum
     * @type {m.StandardResultCode}
     */
    ResultCode: m.StandardResultCode = null;

    /**
     * @name Message
     * @servertype String
     * @type {string}
     */
    Message: string = "";

    /**
     * @name CurrentValues
     * @servertype Dictionary<string, object>
     * @type {{[key: string]:  any}}
     */
    CurrentValues: {[key: string]:  any} = {};

    /**
     * @name RequestedValues
     * @servertype Dictionary<string, object>
     * @type {{[key: string]:  any}}
     */
    RequestedValues: {[key: string]:  any} = {};

    /**
     * @name Details
     * @servertype String
     * @type {string}
     */
    Details: string = "";

}



/**
 * @name DataImportDefinitionViewModelBase
 */
export class DataImportDefinitionViewModelBase {

    /**
     * @name DataImportDefinitionId
     * @servertype Int64
     * @type {number}
     */
    DataImportDefinitionId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Version
     * @servertype Int32
     * @type {number}
     */
    Version: number = 0;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Data Import Definition
 */
export class DataImportDefinitionViewModel extends DataImportDefinitionViewModelBase {

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name Data Import Definition Data Update Properties
 */
export class DataImportDefinitionDataUpdatePropertiesViewModel {

    /**
     * @name DataSource
     * @servertype String
     * @type {string}
     */
    DataSource: string = "";

    /**
     * @name DataTargets
     * @servertype DataImportDefinitionDataUpdateTargetPropertiesViewModel array
     * @type {DataImportDefinitionDataUpdateTargetPropertiesViewModel[]}
     */
    DataTargets: DataImportDefinitionDataUpdateTargetPropertiesViewModel[] = [];

}



/**
 * @name Data Import Definition Data Update Target Properties
 */
export class DataImportDefinitionDataUpdateTargetPropertiesViewModel {

    /**
     * @name DataTarget
     * @servertype String
     * @type {string}
     */
    DataTarget: string = "";

    /**
     * @name IgnoreDataUpdateSafeguards
     * @servertype Boolean
     * @type {boolean}
     */
    IgnoreDataUpdateSafeguards: boolean = false;

    /**
     * @name Properties
     * @servertype PropertyMetaDataViewModel array
     * @type {m.PropertyMetaDataViewModel[]}
     */
    Properties: m.PropertyMetaDataViewModel[] = [];

}



/**
 * @name Data Table Support
 */
export class DataTableSupportViewModel {

    /**
     * @name DataTableSupportId
     * @servertype Int64
     * @type {number}
     */
    DataTableSupportId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name TableName
     * @servertype String
     * @type {string}
     */
    TableName: string = "";

    /**
     * @name TableScope
     * @servertype String
     * @type {string}
     */
    TableScope: string = "";

    /**
     * @name FilterExpression
     * @servertype String
     * @type {string}
     */
    FilterExpression: string = "";

    /**
     * @name SensitiveInformationHandlerEnabled
     * @servertype Boolean
     * @type {boolean}
     */
    SensitiveInformationHandlerEnabled: boolean = false;

    /**
     * @name SensitiveInformationHandlers
     * @servertype DataTableSupportSensitiveInformationHandlerViewModel array
     * @type {DataTableSupportSensitiveInformationHandlerViewModel[]}
     */
    SensitiveInformationHandlers: DataTableSupportSensitiveInformationHandlerViewModel[] = [];

    /**
     * @name AuditTrailEnabled
     * @servertype Boolean
     * @type {boolean}
     */
    AuditTrailEnabled: boolean = false;

    /**
     * @name AuditTrailCustomization
     * @servertype IB.Web.API.Models.v5.ScriptViewModel
     * @type {m5core.ScriptViewModel}
     */
    AuditTrailCustomization: m5core.ScriptViewModel = null;

    /**
     * @name TriggersEnabled
     * @servertype Boolean
     * @type {boolean}
     */
    TriggersEnabled: boolean = false;

    /**
     * @name TriggerOnAddBefore
     * @servertype IB.Web.API.Models.v5.DataTableSupportTriggerViewModel
     * @type {DataTableSupportTriggerViewModel}
     */
    TriggerOnAddBefore: DataTableSupportTriggerViewModel = null;

    /**
     * @name TriggerOnAddSuccess
     * @servertype IB.Web.API.Models.v5.DataTableSupportTriggerViewModel
     * @type {DataTableSupportTriggerViewModel}
     */
    TriggerOnAddSuccess: DataTableSupportTriggerViewModel = null;

    /**
     * @name TriggerOnAddFail
     * @servertype IB.Web.API.Models.v5.DataTableSupportTriggerViewModel
     * @type {DataTableSupportTriggerViewModel}
     */
    TriggerOnAddFail: DataTableSupportTriggerViewModel = null;

    /**
     * @name TriggerOnEditBefore
     * @servertype IB.Web.API.Models.v5.DataTableSupportTriggerViewModel
     * @type {DataTableSupportTriggerViewModel}
     */
    TriggerOnEditBefore: DataTableSupportTriggerViewModel = null;

    /**
     * @name TriggerOnEditSuccess
     * @servertype IB.Web.API.Models.v5.DataTableSupportTriggerViewModel
     * @type {DataTableSupportTriggerViewModel}
     */
    TriggerOnEditSuccess: DataTableSupportTriggerViewModel = null;

    /**
     * @name TriggerOnEditFail
     * @servertype IB.Web.API.Models.v5.DataTableSupportTriggerViewModel
     * @type {DataTableSupportTriggerViewModel}
     */
    TriggerOnEditFail: DataTableSupportTriggerViewModel = null;

    /**
     * @name TriggerOnDeleteBefore
     * @servertype IB.Web.API.Models.v5.DataTableSupportTriggerViewModel
     * @type {DataTableSupportTriggerViewModel}
     */
    TriggerOnDeleteBefore: DataTableSupportTriggerViewModel = null;

    /**
     * @name TriggerOnDeleteSuccess
     * @servertype IB.Web.API.Models.v5.DataTableSupportTriggerViewModel
     * @type {DataTableSupportTriggerViewModel}
     */
    TriggerOnDeleteSuccess: DataTableSupportTriggerViewModel = null;

    /**
     * @name TriggerOnDeleteFail
     * @servertype IB.Web.API.Models.v5.DataTableSupportTriggerViewModel
     * @type {DataTableSupportTriggerViewModel}
     */
    TriggerOnDeleteFail: DataTableSupportTriggerViewModel = null;

    /**
     * @name TriggerOnReadBefore
     * @servertype IB.Web.API.Models.v5.DataTableSupportTriggerViewModel
     * @type {DataTableSupportTriggerViewModel}
     */
    TriggerOnReadBefore: DataTableSupportTriggerViewModel = null;

    /**
     * @name TriggerOnReadSuccess
     * @servertype IB.Web.API.Models.v5.DataTableSupportTriggerViewModel
     * @type {DataTableSupportTriggerViewModel}
     */
    TriggerOnReadSuccess: DataTableSupportTriggerViewModel = null;

    /**
     * @name TriggerOnReadFail
     * @servertype IB.Web.API.Models.v5.DataTableSupportTriggerViewModel
     * @type {DataTableSupportTriggerViewModel}
     */
    TriggerOnReadFail: DataTableSupportTriggerViewModel = null;

    /**
     * @name Triggers
     * @servertype IB.Web.API.Models.v5.ScriptViewModel
     * @type {m5core.ScriptViewModel}
     */
    Triggers: m5core.ScriptViewModel = null;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name PartitionId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Data Table Support Sensitive Information Handler
 */
export class DataTableSupportSensitiveInformationHandlerViewModel {

    /**
     * @name PropertyName
     * @servertype String
     * @type {string}
     */
    PropertyName: string = "";

    /**
     * @name Category
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name MaskType
     * @servertype String
     * @type {string}
     */
    MaskType: string = "";

    /**
     * @name MaskReplacementValue
     * @servertype String
     * @type {string}
     */
    MaskReplacementValue: string = "";

    /**
     * @name MaskCharactersToShowPrefix
     * @servertype Int32
     * @type {number}
     */
    MaskCharactersToShowPrefix: number = 0;

    /**
     * @name MaskCharactersToShowSuffix
     * @servertype Int32
     * @type {number}
     */
    MaskCharactersToShowSuffix: number = 0;

    /**
     * @name MaskRemovalRequestAudited
     * @servertype Boolean
     * @type {boolean}
     */
    MaskRemovalRequestAudited: boolean = false;

    /**
     * @name SanitizeType
     * @servertype String
     * @type {string}
     */
    SanitizeType: string = "";

    /**
     * @name SanitizeReplacementValue
     * @servertype String
     * @type {string}
     */
    SanitizeReplacementValue: string = "";

    /**
     * @name SanitizeCharactersToKeepPrefix
     * @servertype Int32
     * @type {number}
     */
    SanitizeCharactersToKeepPrefix: number = 0;

    /**
     * @name SanitizeCharactersToKeepSuffix
     * @servertype Int32
     * @type {number}
     */
    SanitizeCharactersToKeepSuffix: number = 0;

    /**
     * @name SanitizeRequestAudited
     * @servertype Boolean
     * @type {boolean}
     */
    SanitizeRequestAudited: boolean = false;

}



/**
 * @name Data Table Support Trigger
 */
export class DataTableSupportTriggerViewModel {

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name NotificationGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = 0;

    /**
     * @name ProcessTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTemplateId: number = 0;

    /**
     * @name ProcessPeerId
     * @servertype String
     * @type {string}
     */
    ProcessPeerId: string = "";

    /**
     * @name CaseTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = 0;

    /**
     * @name WebhookId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WebhookId: number = 0;

    /**
     * @name TriggerAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggerAssetId: number = 0;

    /**
     * @name Triggers
     * @servertype IB.Web.API.Models.v5.ScriptViewModel
     * @type {m5core.ScriptViewModel}
     */
    Triggers: m5core.ScriptViewModel = null;

}



/**
 * @name SensitiveInformationMaskType Enum
 * @readonly
 * @enum {number}
 */
export enum SensitiveInformationMaskType {

    /**
     * Default
     */
    Default = 0,

    /**
     * Email
     */
    Email = 1,

    /**
     * None
     */
    None = 2

}



/**
 * @name SensitiveInformationSanitizeType Enum
 * @readonly
 * @enum {number}
 */
export enum SensitiveInformationSanitizeType {

    /**
     * Default
     */
    Default = 0,

    /**
     * Email
     */
    Email = 1,

    /**
     * Clear
     */
    Clear = 2,

    /**
     * Replace
     */
    Replace = 3,

    /**
     * None
     */
    None = 4

}



/**
 * @name ReferenceTriggerType Enum
 * @readonly
 * @enum {number}
 */
export enum ReferenceTriggerType {

    /**
     * Notification
     */
    Notification = 0,

    /**
     * Case
     */
    Case = 1,

    /**
     * Asset Trigger
     */
    AssetTrigger = 2

}



/**
 * @name File Server
 */
export class FileServerEditViewModel {

    /**
     * @name FileServerId
     * @servertype Int64
     * @type {number}
     */
    FileServerId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Server
     * @servertype String
     * @type {string}
     */
    Server: string = "";

    /**
     * @name Port
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Port: number = 0;

    /**
     * @name Login
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Password
     * @servertype String
     * @type {string}
     */
    Password: string = "";

    /**
     * @name ProxyServerAutoDetect
     * @servertype Boolean
     * @type {boolean}
     */
    ProxyServerAutoDetect: boolean = false;

    /**
     * @name ProxyServerId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProxyServerId: number = 0;

    /**
     * @name InitialDirectory
     * @servertype String
     * @type {string}
     */
    InitialDirectory: string = "";

    /**
     * @name Ssl
     * @servertype IB.Web.API.Models.v5.SslSettingsEditViewModel
     * @type {SslSettingsEditViewModel}
     */
    Ssl: SslSettingsEditViewModel = null;

    /**
     * @name Ssh
     * @servertype IB.Web.API.Models.v5.SshSettingsEditViewModel
     * @type {SshSettingsEditViewModel}
     */
    Ssh: SshSettingsEditViewModel = null;

    /**
     * @name Ftp
     * @servertype IB.Web.API.Models.v5.FtpSettingsEditViewModel
     * @type {FtpSettingsEditViewModel}
     */
    Ftp: FtpSettingsEditViewModel = null;

    /**
     * @name InactivityTimeoutSeconds
     * @servertype Int32 (nullable)
     * @type {number}
     */
    InactivityTimeoutSeconds: number = 0;

    /**
     * @name AbsoluteTimeoutSeconds
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AbsoluteTimeoutSeconds: number = 0;

    /**
     * @name Encryption
     * @servertype Int32 (nullable)
     * @type {EncryptionAlgorithm}
     */
    Encryption: EncryptionAlgorithm = 0;

    /**
     * @name EncryptionPassword
     * @servertype String
     * @type {string}
     */
    EncryptionPassword: string = "";

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name ServerType
     * @servertype IB.Web.API.Models.v5.FileServerType Enum
     * @type {FileServerType}
     */
    ServerType: FileServerType = null;

}



/**
 * @name SslSettingsEditViewModel
 */
export class SslSettingsEditViewModel {

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name AcceptServerCertificate
     * @servertype Boolean
     * @type {boolean}
     */
    AcceptServerCertificate: boolean = false;

    /**
     * @name StartMode
     * @servertype Int32 (nullable)
     * @type {SslStartMode}
     */
    StartMode: SslStartMode = 0;

    /**
     * @name CertificateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CertificateId: number = 0;

}



/**
 * @name SshSettingsEditViewModel
 */
export class SshSettingsEditViewModel {

    /**
     * @name AcceptServerKey
     * @servertype Boolean
     * @type {boolean}
     */
    AcceptServerKey: boolean = false;

    /**
     * @name AcceptServerCertificatesFromIssuerPublicKey
     * @servertype String
     * @type {string}
     */
    AcceptServerCertificatesFromIssuerPublicKey: string = "";

    /**
     * @name AcceptedServerKeys
     * @servertype string array
     * @type {string[]}
     */
    AcceptedServerKeys: string[] = [];

    /**
     * @name AcceptedServerFingerprints
     * @servertype string array
     * @type {string[]}
     */
    AcceptedServerFingerprints: string[] = [];

    /**
     * @name AcceptedServerCertificates
     * @servertype long array
     * @type {number[]}
     */
    AcceptedServerCertificates: number[] = [];

    /**
     * @name AuthorizationMode
     * @servertype IB.Web.API.Models.v5.SshAuthorizationMode Enum
     * @type {SshAuthorizationMode}
     */
    AuthorizationMode: SshAuthorizationMode = null;

    /**
     * @name CertificateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CertificateId: number = 0;

    /**
     * @name CompressionAlgorithms
     * @servertype string array
     * @type {string[]}
     */
    CompressionAlgorithms: string[] = [];

    /**
     * @name EncryptionAlgorithms
     * @servertype string array
     * @type {string[]}
     */
    EncryptionAlgorithms: string[] = [];

}



/**
 * @name SshAuthorizationMode Enum
 * @readonly
 * @enum {number}
 */
export enum SshAuthorizationMode {

    /**
     * None
     */
    None = 0,

    /**
     * Multi Factor
     */
    MultiFactor = 1,

    /**
     * Password
     */
    Password = 2,

    /**
     * Public Key
     */
    PublicKey = 3,

    /**
     * Keyboard Interactive
     */
    KeyboardInteractive = 4,

    /**
     * G S S A P I With Mic
     */
    GSSAPIWithMic = 5,

    /**
     * Custom
     */
    Custom = 6

}



/**
 * @name FtpSettingsEditViewModel
 */
export class FtpSettingsEditViewModel {

    /**
     * @name PassiveMode
     * @servertype Boolean
     * @type {boolean}
     */
    PassiveMode: boolean = false;

}



/**
 * @name FileServerType Enum
 * @readonly
 * @enum {number}
 */
export enum FileServerType {

    /**
     * Local
     */
    Local = 0,

    /**
     * FTP
     */
    FTP = 2,

    /**
     * SFTP
     */
    SFTP = 3,

    /**
     * FTPS
     */
    FTPS = 4,

    /**
     * HTTP
     */
    HTTP = 5,

    /**
     * Azure File Share
     */
    AzureFileShare = 15

}



/**
 * @name SslStartMode Enum
 * @readonly
 * @enum {number}
 */
export enum SslStartMode {

    /**
     * Automatic
     */
    Automatic = 0,

    /**
     * Implicit
     */
    Implicit = 1,

    /**
     * Explicit
     */
    Explicit = 2,

    /**
     * None
     */
    None = 3

}



/**
 * @name EncryptionAlgorithm Enum
 * @readonly
 * @enum {number}
 */
export enum EncryptionAlgorithm {

    /**
     * AES
     */
    AES = 0,

    /**
     * Blowfish
     */
    Blowfish = 1,

    /**
     * CAST
     */
    CAST = 2,

    /**
     * DES
     */
    DES = 3,

    /**
     * IDEA
     */
    IDEA = 4,

    /**
     * RC2
     */
    RC2 = 5,

    /**
     * RC4
     */
    RC4 = 6,

    /**
     * TEA
     */
    TEA = 7,

    /**
     * Triple D E S
     */
    TripleDES = 8,

    /**
     * Twofish
     */
    Twofish = 9

}



/**
 * @name Mail Address Action
 */
export class MailAddressActionViewModel {

    /**
     * @name MailAddressActionId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    MailAddressActionId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name MailAddress
     * @servertype String
     * @type {string}
     */
    MailAddress: string = "";

    /**
     * @name MailAddressAliases
     * @servertype string array
     * @type {string[]}
     */
    MailAddressAliases: string[] = [];

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name LogType
     * @servertype String
     * @type {string}
     */
    LogType: string = "";

    /**
     * @name IgnoredSenders
     * @servertype string array
     * @type {string[]}
     */
    IgnoredSenders: string[] = [];

    /**
     * @name IgnoreWhenSubjectContains
     * @servertype string array
     * @type {string[]}
     */
    IgnoreWhenSubjectContains: string[] = [];

    /**
     * @name IgnoreWhenBodyContains
     * @servertype string array
     * @type {string[]}
     */
    IgnoreWhenBodyContains: string[] = [];

    /**
     * @name CaseTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = 0;

    /**
     * @name CaseCreateWhenSenderUnknown
     * @servertype Boolean
     * @type {boolean}
     */
    CaseCreateWhenSenderUnknown: boolean = false;

    /**
     * @name CaseOwnedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseOwnedByContactId: number = null;

    /**
     * @name CaseOwnedByContactName
     * @servertype String
     * @type {string}
     */
    CaseOwnedByContactName: string = "";

    /**
     * @name CaseOwnedByContactType
     * @servertype String
     * @type {string}
     */
    CaseOwnedByContactType: string = "";

    /**
     * @name CaseAssignedToContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseAssignedToContactId: number = null;

    /**
     * @name CaseAssignedToContactName
     * @servertype String
     * @type {string}
     */
    CaseAssignedToContactName: string = "";

    /**
     * @name CaseAssignedToContactType
     * @servertype String
     * @type {string}
     */
    CaseAssignedToContactType: string = "";

    /**
     * @name CaseSupervisorContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseSupervisorContactId: number = null;

    /**
     * @name CaseSupervisorContactName
     * @servertype String
     * @type {string}
     */
    CaseSupervisorContactName: string = "";

    /**
     * @name CaseSupervisorContactType
     * @servertype String
     * @type {string}
     */
    CaseSupervisorContactType: string = "";

    /**
     * @name CasePriority
     * @servertype String
     * @type {string}
     */
    CasePriority: string = "";

    /**
     * @name CaseTags
     * @servertype string array
     * @type {string[]}
     */
    CaseTags: string[] = [];

    /**
     * @name CaseMatchUsingHeaderMessageId
     * @servertype Boolean
     * @type {boolean}
     */
    CaseMatchUsingHeaderMessageId: boolean = false;

    /**
     * @name CaseMatchUsingHeaderMessageIdTemplate
     * @servertype String
     * @type {string}
     */
    CaseMatchUsingHeaderMessageIdTemplate: string = "";

    /**
     * @name CaseMatchUsingSubjectLine
     * @servertype Boolean
     * @type {boolean}
     */
    CaseMatchUsingSubjectLine: boolean = false;

    /**
     * @name CaseMatchUsingSubjectLineTemplate
     * @servertype String
     * @type {string}
     */
    CaseMatchUsingSubjectLineTemplate: string = "";

    /**
     * @name CaseMatchUsingBody
     * @servertype Boolean
     * @type {boolean}
     */
    CaseMatchUsingBody: boolean = false;

    /**
     * @name CaseMatchUsingBodyTemplate
     * @servertype String
     * @type {string}
     */
    CaseMatchUsingBodyTemplate: string = "";

    /**
     * @name NoReplyNeededSenders
     * @servertype string array
     * @type {string[]}
     */
    NoReplyNeededSenders: string[] = [];

    /**
     * @name SuppressNotification
     * @servertype Boolean
     * @type {boolean}
     */
    SuppressNotification: boolean = false;

    /**
     * @name PublicCorrespondence
     * @servertype Boolean
     * @type {boolean}
     */
    PublicCorrespondence: boolean = false;

    /**
     * @name ProcessTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTemplateId: number = 0;

    /**
     * @name ProcessCreateWhenSenderUnknown
     * @servertype Boolean
     * @type {boolean}
     */
    ProcessCreateWhenSenderUnknown: boolean = false;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Mail Server
 */
export class MailServerViewModel {

    /**
     * @name MailServerId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    MailServerId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name IncomingMailProtocol
     * @servertype String
     * @type {string}
     */
    IncomingMailProtocol: string = "";

    /**
     * @name PopServer
     * @servertype String
     * @type {string}
     */
    PopServer: string = "";

    /**
     * @name PopServerPort
     * @servertype Int16 (nullable)
     * @type {number}
     */
    PopServerPort: number = 0;

    /**
     * @name PopTimeoutSeconds
     * @servertype Int16 (nullable)
     * @type {number}
     */
    PopTimeoutSeconds: number = 0;

    /**
     * @name PopUseSsl
     * @servertype Boolean
     * @type {boolean}
     */
    PopUseSsl: boolean = false;

    /**
     * @name PopUserName
     * @servertype String
     * @type {string}
     */
    PopUserName: string = "";

    /**
     * @name PopPassword
     * @servertype String
     * @type {string}
     */
    PopPassword: string = "";

    /**
     * @name ImapServer
     * @servertype String
     * @type {string}
     */
    ImapServer: string = "";

    /**
     * @name ImapServerPort
     * @servertype Int16 (nullable)
     * @type {number}
     */
    ImapServerPort: number = 0;

    /**
     * @name ImapTimeoutSeconds
     * @servertype Int16 (nullable)
     * @type {number}
     */
    ImapTimeoutSeconds: number = 0;

    /**
     * @name ImapUseSsl
     * @servertype Boolean
     * @type {boolean}
     */
    ImapUseSsl: boolean = false;

    /**
     * @name ImapUserName
     * @servertype String
     * @type {string}
     */
    ImapUserName: string = "";

    /**
     * @name ImapPassword
     * @servertype String
     * @type {string}
     */
    ImapPassword: string = "";

    /**
     * @name ImapMailboxName
     * @servertype String
     * @type {string}
     */
    ImapMailboxName: string = "";

    /**
     * @name SmtpServer
     * @servertype String
     * @type {string}
     */
    SmtpServer: string = "";

    /**
     * @name SmtpServerPort
     * @servertype Int16 (nullable)
     * @type {number}
     */
    SmtpServerPort: number = 0;

    /**
     * @name SmtpTimeoutSeconds
     * @servertype Int16 (nullable)
     * @type {number}
     */
    SmtpTimeoutSeconds: number = 0;

    /**
     * @name SmtpUseSsl
     * @servertype Boolean
     * @type {boolean}
     */
    SmtpUseSsl: boolean = false;

    /**
     * @name SmtpAuthenticate
     * @servertype Boolean
     * @type {boolean}
     */
    SmtpAuthenticate: boolean = false;

    /**
     * @name SmtpUserName
     * @servertype String
     * @type {string}
     */
    SmtpUserName: string = "";

    /**
     * @name SmtpPassword
     * @servertype String
     * @type {string}
     */
    SmtpPassword: string = "";

    /**
     * @name SmtpReplyToName
     * @servertype String
     * @type {string}
     */
    SmtpReplyToName: string = "";

    /**
     * @name SmtpReplyToAddress
     * @servertype String
     * @type {string}
     */
    SmtpReplyToAddress: string = "";

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Process Step List
 * @description
 * The Process Step table contains information on steps for system processes.
 */
export class ProcessStepListViewModel {

    /**
     * @name ProcessStepId
     * @description 
     * Unique value that represents this process step record.
     * @servertype String
     * @type {string}
     */
    ProcessStepId: string = null;

    /**
     * @name ProcessId
     * @description 
     * The id of the process this step belongs to.
     * @servertype String
     * @type {string}
     */
    ProcessId: string = null;

    /**
     * @name ProcessTemplateId
     * @description 
     * The process template id this process step is part of.
     * @servertype Int64
     * @type {number}
     */
    ProcessTemplateId: number = null;

    /**
     * @name ProcessTemplateStepId
     * @description 
     * The process template step id this process step follows.
     * @servertype Int64
     * @type {number}
     */
    ProcessTemplateStepId: number = null;

    /**
     * @name ProcessStepTag
     * @description 
     * A tag that can be used in combination with the process step key to validate external links to the process step were known
     * and not guessed.
     * @servertype String
     * @type {string}
     */
    ProcessStepTag: string = "";

    /**
     * @name ProcessDateTime
     * @description 
     * Date and time when the process was created.  Note this differ from when the step started.  This is used as the monthly table
     * reference date so process and process step share the same month for the references.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessDateTime: Date = new Date();

    /**
     * @name StepNumber
     * @description 
     * The step number for this process step.
     * @servertype Int32
     * @type {number}
     */
    StepNumber: number = 0;

    /**
     * @name Description
     * @description 
     * A description for this process step as defined by the process template step.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Priority
     * @description 
     * The priority of this step.  Possible values include:
     * -1 = Low
     *  0 = Normal
     *  1 = High
     * @servertype Int16 (nullable)
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name StepActionId
     * @description 
     * An id used for this step action.  Not required but can be used to uniquely identify a step by external processes to find
     * steps of a certain type to execute or as a key to input and output properties for the step when referencing those elsewhere.
     *  When no step action id is specified Step1, Step2, etc. will be used which will require updates if step numbers change.
     * @servertype String
     * @type {string}
     */
    StepActionId: string = "";

    /**
     * @name Status
     * @description 
     * Status of this process step.  Possible values are: 
     * H = Hold (step on hold until ready for start)
     * P = Pending (step not started yet or is pending retry)
     * R = Running (step is running)
     * W = Waiting (step is waiting)
     * K = Skipped (step was skipped)
     * S = Success (step was successful)
     * F = Failure (step failed and will not be retried)
     * @servertype String
     * @type {string}
     */
    Status: string = "H";

    /**
     * @name ResultCode
     * @description 
     * The result code for this step.  Possible values include standard result code plus these options:
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @description 
     * Result message for this step.  More details can be found the logs if needed.
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name PercentDone
     * @description 
     * The percent done for this step.  Not all step types are able to update this value and updates may represent percent done
     * with parts of the step and not necessarily percent done from a time to complete perspective.
     * @servertype Byte (nullable)
     * @type {number}
     */
    PercentDone: number = 0;

    /**
     * @name FirstAttemptDateTime
     * @description 
     * The date and time this process step first attempt started.  This is used to help determine when maximum retry time is reached.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FirstAttemptDateTime: Date = null;

    /**
     * @name StartDateTime
     * @description 
     * The date and time this process step started.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name EnteredWaitStateDateTime
     * @description 
     * The date and time this process step last entered a wait state.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EnteredWaitStateDateTime: Date = null;

    /**
     * @name FinishDateTime
     * @description 
     * The date and time this process step finished.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FinishDateTime: Date = null;

    /**
     * @name QueueDateTime
     * @description 
     * The date and time this record is queued for.  This defaults to the same as the step start date and time but can be adjusted
     * in situations where the step needs to be processed later.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    QueueDateTime: Date = new Date();

    /**
     * @name AttemptCount
     * @description 
     * Number of times the step was attempted.  This increments with each retry (but not wait).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AttemptCount: number = 0;

    /**
     * @name LastAttemptDateTime
     * @description 
     * Date and time when the step was last attempted.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAttemptDateTime: Date = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

}



/**
 * @name ProcessStepEditViewModel
 * @description
 * The Process Step table contains information on steps for system processes.
 */
export class ProcessStepEditViewModel {

    /**
     * @name ProcessStepId
     * @description 
     * Unique value that represents this process step record.
     * @servertype String
     * @type {string}
     */
    ProcessStepId: string = null;

    /**
     * @name ProcessId
     * @description 
     * The id of the process this step belongs to.
     * @servertype String
     * @type {string}
     */
    ProcessId: string = null;

    /**
     * @name ProcessTemplateId
     * @description 
     * The process template id this process step is part of.
     * @servertype Int64
     * @type {number}
     */
    ProcessTemplateId: number = null;

    /**
     * @name ProcessTemplateStepId
     * @description 
     * The process template step id this process step follows.
     * @servertype Int64
     * @type {number}
     */
    ProcessTemplateStepId: number = null;

    /**
     * @name ProcessStepTag
     * @description 
     * A tag that can be used in combination with the process step key to validate external links to the process step were known
     * and not guessed.
     * @servertype String
     * @type {string}
     */
    ProcessStepTag: string = "";

    /**
     * @name ProcessDateTime
     * @description 
     * Date and time when the process was created.  Note this differ from when the step started.  This is used as the monthly table
     * reference date so process and process step share the same month for the references.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessDateTime: Date = new Date();

    /**
     * @name StepNumber
     * @description 
     * The step number for this process step.
     * @servertype Int32
     * @type {number}
     */
    StepNumber: number = 0;

    /**
     * @name Description
     * @description 
     * A description for this process step as defined by the process template step.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Priority
     * @description 
     * The priority of this step.  Possible values include:
     * -1 = Low
     *  0 = Normal
     *  1 = High
     * @servertype Int16 (nullable)
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name StepActionId
     * @description 
     * An id used for this step action.  Not required but can be used to uniquely identify a step by external processes to find
     * steps of a certain type to execute or as a key to input and output properties for the step when referencing those elsewhere.
     *  When no step action id is specified Step1, Step2, etc. will be used which will require updates if step numbers change.
     * @servertype String
     * @type {string}
     */
    StepActionId: string = "";

    /**
     * @name InputProperties
     * @description 
     * The input properties for this process step.
     * @servertype String
     * @type {string}
     */
    InputProperties: string = "";

    /**
     * @name OutputProperties
     * @description 
     * The output properties for this process step.  This will include things like: 
     * A Case Id when a case was created.
     * A Notification Event Date Time and Id when a notification is triggered.
     * A Job Id when a job is created.
     * A Result Code from plugin or script execution.
     * @servertype String
     * @type {string}
     */
    OutputProperties: string = "";

    /**
     * @name Status
     * @description 
     * Status of this process step.  Possible values are: 
     * H = Hold (step on hold until ready for start)
     * P = Pending (step not started yet or is pending retry)
     * R = Running (step is running)
     * W = Waiting (step is waiting)
     * K = Skipped (step was skipped)
     * S = Success (step was successful)
     * F = Failure (step failed and will not be retried)
     * @servertype String
     * @type {string}
     */
    Status: string = "H";

    /**
     * @name ResultCode
     * @description 
     * The result code for this step.  Possible values include standard result code plus these options:
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @description 
     * Result message for this step.  More details can be found the logs if needed.
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name PercentDone
     * @description 
     * The percent done for this step.  Not all step types are able to update this value and updates may represent percent done
     * with parts of the step and not necessarily percent done from a time to complete perspective.
     * @servertype Byte (nullable)
     * @type {number}
     */
    PercentDone: number = 0;

    /**
     * @name FirstAttemptDateTime
     * @description 
     * The date and time this process step first attempt started.  This is used to help determine when maximum retry time is reached.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FirstAttemptDateTime: Date = null;

    /**
     * @name StartDateTime
     * @description 
     * The date and time this process step started.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name EnteredWaitStateDateTime
     * @description 
     * The date and time this process step last entered a wait state.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    EnteredWaitStateDateTime: Date = null;

    /**
     * @name FinishDateTime
     * @description 
     * The date and time this process step finished.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FinishDateTime: Date = null;

    /**
     * @name QueueDateTime
     * @description 
     * The date and time this record is queued for.  This defaults to the same as the step start date and time but can be adjusted
     * in situations where the step needs to be processed later.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    QueueDateTime: Date = new Date();

    /**
     * @name AttemptCount
     * @description 
     * Number of times the step was attempted.  This increments with each retry (but not wait).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    AttemptCount: number = 0;

    /**
     * @name LastAttemptDateTime
     * @description 
     * Date and time when the step was last attempted.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastAttemptDateTime: Date = null;

    /**
     * @name TargetServers
     * @description 
     * A list of target servers that are allowed to service processes based on this template.  Typically not specified but can be
     * used if a process requires local resources only available on one of the specified servers.  When needed this can be specified
     * at the process or process step.
     * @servertype string array
     * @type {string[]}
     */
    TargetServers: string[] = [];

    /**
     * @name ProcessingServer
     * @description 
     * The server processing this step request.  If a process engine starts processing a step but never updates to reflect the results
     * there was most likely a serious failure that should have been logged on the processing server.
     * @servertype String
     * @type {string}
     */
    ProcessingServer: string = "";

    /**
     * @name ProcessingDateTime
     * @description 
     * Date and time when the process engine started processing this step or last refreshed that work is still in process.  This
     * can be used to help determine if there are any steps that should have posted success or failure but haven't and may be orphaned.
     *  In that scenario the processing server should be checked for logged messages.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessingDateTime: Date = null;

    /**
     * @name NotificationRetryDateTime
     * @description 
     * The last date and time stamp when the retry notification group was triggered.  This is used along with the process template
     * step notification retry minutes to prevent over notification.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NotificationRetryDateTime: Date = null;

    /**
     * @name NotificationWaitDateTime
     * @description 
     * The last date and time stamp when the wait notification group was triggered.  This is used along with the process template
     * step notification wait minutes to prevent over notification.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    NotificationWaitDateTime: Date = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name ProcessTemplateStepActionPropertiesEditViewModel<T>
 */
export class ProcessTemplateStepActionPropertiesEditViewModel<T> {

    /**
     * @name StepActionType
     * @servertype String
     * @type {string}
     */
    StepActionType: string = "";

    /**
     * @name Version
     * @servertype Int32
     * @type {number}
     */
    Version: number = 0;

    /**
     * @name Properties
     * @servertype 
     * @type {T}
     */
    Properties: T = null;

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

}



/**
 * @name ProcessTemplateStepActionChildProcessPropertiesModel
 */
export class ProcessTemplateStepActionChildProcessPropertiesModel {

    /**
     * @name ProcessTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTemplateId: number = 0;

    /**
     * @name ProcessPeerId
     * @servertype String
     * @type {string}
     */
    ProcessPeerId: string = "";

    /**
     * @name Wait
     * @servertype Boolean
     * @type {boolean}
     */
    Wait: boolean = false;

}



/**
 * @name ProcessTemplateStepActionCasePropertiesEditViewModel
 */
export class ProcessTemplateStepActionCasePropertiesEditViewModel {

    /**
     * @name CaseTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = 0;

    /**
     * @name CaseDescription
     * @servertype String
     * @type {string}
     */
    CaseDescription: string = "";

    /**
     * @name Wait
     * @servertype Boolean
     * @type {boolean}
     */
    Wait: boolean = false;

    /**
     * @name CaseSuccessOutcome
     * @servertype String
     * @type {string}
     */
    CaseSuccessOutcome: string = "";

    /**
     * @name CaseFailureOutcome
     * @servertype String
     * @type {string}
     */
    CaseFailureOutcome: string = "";

    /**
     * @name CaseIndeterminateOutcome
     * @servertype String
     * @type {string}
     */
    CaseIndeterminateOutcome: string = "";

}



/**
 * @name ProcessTemplateStepActionScriptPropertiesEditViewModel
 */
export class ProcessTemplateStepActionScriptPropertiesEditViewModel {

    /**
     * @name ScriptAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ScriptAssetId: number = 0;

    /**
     * @name ScriptSuccessResultCodes
     * @servertype int array
     * @type {number[]}
     */
    ScriptSuccessResultCodes: number[] = [];

    /**
     * @name ScriptWaitingResultCodes
     * @servertype int array
     * @type {number[]}
     */
    ScriptWaitingResultCodes: number[] = [];

}



/**
 * @name ProcessTemplateStepActionJobPropertiesEditViewModel
 */
export class ProcessTemplateStepActionJobPropertiesEditViewModel {

    /**
     * @name JobType
     * @servertype String
     * @type {string}
     */
    JobType: string = "";

    /**
     * @name JobProperties
     * @servertype System.Object
     * @type {any}
     */
    JobProperties: any = {};

    /**
     * @name Wait
     * @servertype Boolean
     * @type {boolean}
     */
    Wait: boolean = false;

}



/**
 * @name ProcessTemplateStepActionPluginPropertiesEditViewModel
 */
export class ProcessTemplateStepActionPluginPropertiesEditViewModel {

    /**
     * @name Plugin
     * @servertype String
     * @type {string}
     */
    Plugin: string = "";

}



/**
 * @name ProcessTemplateStepActionNotificationPropertiesEditViewModel
 */
export class ProcessTemplateStepActionNotificationPropertiesEditViewModel {

    /**
     * @name NotificationGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = 0;

    /**
     * @name Wait
     * @servertype Boolean
     * @type {boolean}
     */
    Wait: boolean = false;

}



/**
 * @name ProcessTemplateStepActionStepPropertiesEditViewModel
 */
export class ProcessTemplateStepActionStepPropertiesEditViewModel {

    /**
     * @name StartStepActionId
     * @servertype String
     * @type {string}
     */
    StartStepActionId: string = "";

    /**
     * @name StartStepProcessTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    StartStepProcessTemplateId: number = 0;

    /**
     * @name StartStepNumbers
     * @servertype int array
     * @type {number[]}
     */
    StartStepNumbers: number[] = [];

    /**
     * @name MaximumSteps
     * @servertype Int32
     * @type {number}
     */
    MaximumSteps: number = 0;

    /**
     * @name Chronological
     * @servertype Boolean
     * @type {boolean}
     */
    Chronological: boolean = false;

}



/**
 * @name ProcessTemplateStepActionDatabaseActionPropertiesEditViewModel
 */
export class ProcessTemplateStepActionDatabaseActionPropertiesEditViewModel {

    /**
     * @name DataFolder
     * @servertype String
     * @type {string}
     */
    DataFolder: string = "";

    /**
     * @name DataFolderFailover
     * @servertype String
     * @type {string}
     */
    DataFolderFailover: string = "";

    /**
     * @name Family
     * @servertype String
     * @type {string}
     */
    Family: string = "";

    /**
     * @name Region
     * @servertype String
     * @type {string}
     */
    Region: string = "";

    /**
     * @name Server
     * @servertype String
     * @type {string}
     */
    Server: string = "";

    /**
     * @name ServerFailover
     * @servertype String
     * @type {string}
     */
    ServerFailover: string = "";

    /**
     * @name Database
     * @servertype String
     * @type {string}
     */
    Database: string = "";

}



/**
 * @name ProcessTemplateStepActionSqlPropertiesEditViewModel
 */
export class ProcessTemplateStepActionSqlPropertiesEditViewModel extends ProcessTemplateStepActionDatabaseActionPropertiesEditViewModel {

    /**
     * @name SqlStatement
     * @servertype String
     * @type {string}
     */
    SqlStatement: string = "";

    /**
     * @name SqlWriteOperation
     * @servertype Boolean
     * @type {boolean}
     */
    SqlWriteOperation: boolean = false;

    /**
     * @name SqlStatementRowsAffected
     * @servertype String
     * @type {string}
     */
    SqlStatementRowsAffected: string = "";

    /**
     * @name SqlStatementNoRowsAffected
     * @servertype String
     * @type {string}
     */
    SqlStatementNoRowsAffected: string = "";

}



/**
 * @name ProcessTemplateStepActionDbBackupPropertiesEditViewModel
 */
export class ProcessTemplateStepActionDbBackupPropertiesEditViewModel extends ProcessTemplateStepActionDatabaseActionPropertiesEditViewModel {

    /**
     * @name BackupType
     * @servertype String
     * @type {string}
     */
    BackupType: string = "";

    /**
     * @name CompressionType
     * @servertype String
     * @type {string}
     */
    CompressionType: string = "";

    /**
     * @name OverwriteExistingBackups
     * @servertype Boolean
     * @type {boolean}
     */
    OverwriteExistingBackups: boolean = false;

    /**
     * @name FileGroup
     * @servertype String
     * @type {string}
     */
    FileGroup: string = "";

    /**
     * @name BackupName
     * @servertype String
     * @type {string}
     */
    BackupName: string = "";

    /**
     * @name BackupDescription
     * @servertype String
     * @type {string}
     */
    BackupDescription: string = "";

    /**
     * @name Password
     * @servertype String
     * @type {string}
     */
    Password: string = "";

    /**
     * @name CopyOnly
     * @servertype Boolean
     * @type {boolean}
     */
    CopyOnly: boolean = false;

    /**
     * @name FileName
     * @servertype String
     * @type {string}
     */
    FileName: string = "";

}



/**
 * @name ProcessTemplateStepActionDbRestorePropertiesEditViewModel
 */
export class ProcessTemplateStepActionDbRestorePropertiesEditViewModel {

    /**
     * @name RestoreType
     * @servertype String
     * @type {string}
     */
    RestoreType: string = "";

    /**
     * @name Server
     * @servertype String
     * @type {string}
     */
    Server: string = "";

    /**
     * @name Database
     * @servertype String
     * @type {string}
     */
    Database: string = "";

    /**
     * @name FileGroup
     * @servertype String
     * @type {string}
     */
    FileGroup: string = "";

    /**
     * @name FileName
     * @servertype String
     * @type {string}
     */
    FileName: string = "";

    /**
     * @name Folder
     * @servertype String
     * @type {string}
     */
    Folder: string = "";

    /**
     * @name SearchPattern
     * @servertype String
     * @type {string}
     */
    SearchPattern: string = "";

    /**
     * @name Sort
     * @servertype String
     * @type {string}
     */
    Sort: string = "";

    /**
     * @name OnSuccessfulRestore
     * @servertype String
     * @type {string}
     */
    OnSuccessfulRestore: string = "";

    /**
     * @name OnSuccessfulRestoreTarget
     * @servertype String
     * @type {string}
     */
    OnSuccessfulRestoreTarget: string = "";

    /**
     * @name MaximumFileCount
     * @servertype Int32
     * @type {number}
     */
    MaximumFileCount: number = 0;

    /**
     * @name MaximumSessionSeconds
     * @servertype Int32
     * @type {number}
     */
    MaximumSessionSeconds: number = 0;

    /**
     * @name NoRecovery
     * @servertype Boolean
     * @type {boolean}
     */
    NoRecovery: boolean = false;

}



/**
 * @name ProcessTemplateStepActionCopyFilesPropertiesEditViewModel
 */
export class ProcessTemplateStepActionCopyFilesPropertiesEditViewModel {

    /**
     * @name SourceFileServerId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceFileServerId: number = 0;

    /**
     * @name SourceFolder
     * @servertype String
     * @type {string}
     */
    SourceFolder: string = "";

    /**
     * @name SourceFiles
     * @servertype String
     * @type {string}
     */
    SourceFiles: string = "";

    /**
     * @name IncludeSubfolders
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeSubfolders: boolean = false;

    /**
     * @name SourceAssetIdMap
     * @servertype String
     * @type {string}
     */
    SourceAssetIdMap: string = "";

    /**
     * @name SourceAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SourceAssetId: number = 0;

    /**
     * @name SourceAssetDescription
     * @servertype String
     * @type {string}
     */
    SourceAssetDescription: string = "";

    /**
     * @name TargetFileServerId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TargetFileServerId: number = 0;

    /**
     * @name TargetFolder
     * @servertype String
     * @type {string}
     */
    TargetFolder: string = "";

    /**
     * @name TargetFileName
     * @servertype String
     * @type {string}
     */
    TargetFileName: string = "";

    /**
     * @name Overwrite
     * @servertype Boolean
     * @type {boolean}
     */
    Overwrite: boolean = false;

    /**
     * @name ExtractArchive
     * @servertype Boolean
     * @type {boolean}
     */
    ExtractArchive: boolean = false;

}



/**
 * @name ProcessTemplateStepActionDeleteFilesPropertiesEditViewModel
 */
export class ProcessTemplateStepActionDeleteFilesPropertiesEditViewModel {

    /**
     * @name FileServerId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FileServerId: number = 0;

    /**
     * @name Folder
     * @servertype String
     * @type {string}
     */
    Folder: string = "";

    /**
     * @name Files
     * @servertype String
     * @type {string}
     */
    Files: string = "";

    /**
     * @name IncludeSubfolders
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeSubfolders: boolean = false;

    /**
     * @name Exclude
     * @servertype string array
     * @type {string[]}
     */
    Exclude: string[] = [];

}



/**
 * @name ProcessTemplateStepActionRunExecutablePropertiesModel
 */
export class ProcessTemplateStepActionRunExecutablePropertiesModel {

    /**
     * @name FileName
     * @servertype String
     * @type {string}
     */
    FileName: string = "";

    /**
     * @name Arguments
     * @servertype String
     * @type {string}
     */
    Arguments: string = "";

    /**
     * @name Script
     * @servertype String
     * @type {string}
     */
    Script: string = "";

    /**
     * @name ScriptAssetIdMap
     * @servertype String
     * @type {string}
     */
    ScriptAssetIdMap: string = "";

    /**
     * @name ScriptAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ScriptAssetId: number = 0;

    /**
     * @name ScriptAssetDescription
     * @servertype String
     * @type {string}
     */
    ScriptAssetDescription: string = "";

    /**
     * @name WorkingDirectory
     * @servertype String
     * @type {string}
     */
    WorkingDirectory: string = "";

    /**
     * @name WindowStyle
     * @servertype String
     * @type {string}
     */
    WindowStyle: string = "";

    /**
     * @name MaximumRunMinutes
     * @servertype Int32
     * @type {number}
     */
    MaximumRunMinutes: number = 0;

    /**
     * @name ShellExecute
     * @servertype Boolean
     * @type {boolean}
     */
    ShellExecute: boolean = false;

    /**
     * @name WaitForExit
     * @servertype Boolean
     * @type {boolean}
     */
    WaitForExit: boolean = false;

    /**
     * @name TreatErrorOutputAsFailure
     * @servertype Boolean
     * @type {boolean}
     */
    TreatErrorOutputAsFailure: boolean = false;

    /**
     * @name ExecutableSuccessResultCodes
     * @servertype int array
     * @type {number[]}
     */
    ExecutableSuccessResultCodes: number[] = [];

}



/**
 * @name ProcessTemplateStepActionConvertFilePropertiesModel
 */
export class ProcessTemplateStepActionConvertFilePropertiesModel {

    /**
     * @name SourceFileName
     * @servertype String
     * @type {string}
     */
    SourceFileName: string = "";

    /**
     * @name TargetFileName
     * @servertype String
     * @type {string}
     */
    TargetFileName: string = "";

    /**
     * @name Overwrite
     * @servertype Boolean
     * @type {boolean}
     */
    Overwrite: boolean = false;

    /**
     * @name PaperKind
     * @servertype String
     * @type {string}
     */
    PaperKind: string = "";

    /**
     * @name Landscape
     * @servertype Boolean
     * @type {boolean}
     */
    Landscape: boolean = false;

}



/**
 * @name ProcessTemplateStepActionAttachFilePropertiesModel
 */
export class ProcessTemplateStepActionAttachFilePropertiesModel {

    /**
     * @name FileName
     * @servertype String
     * @type {string}
     */
    FileName: string = "";

    /**
     * @name FriendlyFileName
     * @servertype String
     * @type {string}
     */
    FriendlyFileName: string = "";

    /**
     * @name FileType
     * @servertype String
     * @type {string}
     */
    FileType: string = "";

    /**
     * @name OwnerResourceType
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = 0;

    /**
     * @name OwnerResourceId2
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = "";

    /**
     * @name SecondaryOwnerResourceType
     * @servertype String
     * @type {string}
     */
    SecondaryOwnerResourceType: string = "";

    /**
     * @name SecondaryOwnerResourceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SecondaryOwnerResourceId: number = 0;

    /**
     * @name SecondaryOwnerResourceId2
     * @servertype String
     * @type {string}
     */
    SecondaryOwnerResourceId2: string = "";

    /**
     * @name MoveFile
     * @servertype Boolean
     * @type {boolean}
     */
    MoveFile: boolean = false;

}



/**
 * @name ProcessTemplateStepActionSystemWatchEventPropertiesModel
 */
export class ProcessTemplateStepActionSystemWatchEventPropertiesModel {

    /**
     * @name WatchedResourceType
     * @servertype String
     * @type {string}
     */
    WatchedResourceType: string = "";

    /**
     * @name Wait
     * @servertype Boolean
     * @type {boolean}
     */
    Wait: boolean = false;

    /**
     * @name PayloadId
     * @servertype String
     * @type {string}
     */
    PayloadId: string = "";

    /**
     * @name PayloadVersion
     * @servertype Int32
     * @type {number}
     */
    PayloadVersion: number = 0;

    /**
     * @name Payload
     * @servertype System.Object
     * @type {any}
     */
    Payload: any = {};

}



/**
 * @name ProcessTemplateStepEditViewModel
 * @description
 * The Process Template Step table contains information regarding process steps.  When processes are created they are assigned
 * steps based on thee process template.
 */
export class ProcessTemplateStepEditViewModel {

    /**
     * @name ProcessTemplateStepId
     * @description 
     * Uniquely identifies this template step.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTemplateStepId: number = null;

    /**
     * @name ProcessTemplateId
     * @description 
     * The id of the process template this step belongs to.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTemplateId: number = null;

    /**
     * @name StepNumber
     * @description 
     * The step number for this process step.
     * @servertype Int32
     * @type {number}
     */
    StepNumber: number = 0;

    /**
     * @name Description
     * @description 
     * Description for this step.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Priority
     * @description 
     * The priority of this step.  Possible values include:
     * -1 = Low
     *  0 = Normal
     *  1 = High
     * @servertype Int16 (nullable)
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name State
     * @description 
     * State of this process template step.  Possible values are: 
     * E = Enabled
     * K = Disabled as Skipped (disabled and treat step as skipped)
     * S = Disabled as Success (disabled and treat step as success)
     * F = Disabled as Failure (disabled and treat step as failed)
     * @servertype String
     * @type {string}
     */
    State: string = "E";

    /**
     * @name ProcessStepDescriptionTemplate
     * @description 
     * A template to use for the description for process steps created from this template.  The template can use {{macro}} placeholders
     * with handlebars (e.g. double curly braces) and has the following object properties available:
     * Template - the process template object
     * ProcessInputProperties - the input properties for the process
     * StepInputProperties - the input properties for the process step
     * @servertype String
     * @type {string}
     */
    ProcessStepDescriptionTemplate: string = "";

    /**
     * @name InputPropertiesDocumentation
     * @description 
     * A JSON collection of documentation objects for process input properties.
     * @servertype PropertyMetaDataViewModel array
     * @type {m.PropertyMetaDataViewModel[]}
     */
    InputPropertiesDocumentation: m.PropertyMetaDataViewModel[] = [];

    /**
     * @name OutputPropertiesDocumentation
     * @description 
     * A JSON collection of documentation objects for process output properties.
     * @servertype PropertyMetaDataViewModel array
     * @type {m.PropertyMetaDataViewModel[]}
     */
    OutputPropertiesDocumentation: m.PropertyMetaDataViewModel[] = [];

    /**
     * @name InputPropertiesTemplate
     * @description 
     * A JSON template to use for input properties.  The template can use {{macro}} placeholders with handlebars (e.g. double curly
     * braces) and has the following object properties available:
     * Process - the process object
     * Step - the process step object
     * Steps - a collection of step objects for the process
     * Template - the process template object
     * TemplateStep - the process template step object
     * ProcessInputProperties - the input properties for the process
     * ProcessOutputProperties - the output properties for the process (rarely fully populated since the process is in progress)
     * Step#InputProperties - the input properties for the step number specified by the # placeholder (e.g. Step3InputProperties)
     * Step#OutputProperties - the output properties for the step number specified by the # placeholder (e.g. Step3OutputProperties)
     * Step#TemplateActionProperties - the step template action properties for the step number specified by the # placeholder (e.g.
     * Step3TemplateActionProperties)
     * Step#TemplateActionAttributes - the step template action attributes for the step number specified by the # placeholder (e.g.
     * Step3TemplateActionAttributes)
     * Note that when a step has a step action id that can be used as an alias for the step number.  For example, 
     * if the step action id is 'Backup' then StepBackupInputProperties is an alias for Step#InputProperties (where # is the 
     * step number for the backup step).  Only alphanumeric values are utilized for the alias (e.g. Backup-File becomes BackupFile).
     * @servertype String
     * @type {string}
     */
    InputPropertiesTemplate: string = "";

    /**
     * @name OutputPropertiesTemplate
     * @description 
     * A JSON template to use for output properties.  The template can use {{macro}} placeholders with handlebars (e.g. double curly
     * braces) and has the following object properties available:
     * Process - the process object
     * Step - the process step object
     * Steps - a collection of step objects for the process
     * Template - the process template object
     * TemplateStep - the process template step object
     * ProcessInputProperties - the input properties for the process
     * ProcessOutputProperties - the output properties for the process (rarely fully populated since the process is in progress)
     * Step#InputProperties - the input properties for the step number specified by the # placeholder (e.g. Step3InputProperties)
     * Step#OutputProperties - the output properties for the step number specified by the # placeholder (e.g. Step3OutputProperties)
     * Step#TemplateActionProperties - the step template action properties for the step number specified by the # placeholder (e.g.
     * Step3TemplateActionProperties)
     * Step#TemplateActionAttributes - the step template action attributes for the step number specified by the # placeholder (e.g.
     * Step3TemplateActionAttributes)
     * Note that when a step has a step action id that can be used as an alias for the step number.  For example, 
     * if the step action id is 'Backup' then StepBackupInputProperties is an alias for Step#InputProperties (where # is the 
     * step number for the backup step).  Only alphanumeric values are utilized for the alias (e.g. Backup-File becomes BackupFile).
     * Note that some step action types automatically output certain properties even if not called for in the template as noted
     * here:
     * Case action type includes CaseId and CaseNumber properties.
     * Sql action type includes RowCount property.
     * Step action type includes ProcessedStepCount property.
     * DbBackup action type includes BackupFileName property.
     * @servertype String
     * @type {string}
     */
    OutputPropertiesTemplate: string = "";

    /**
     * @name DependsOnSteps
     * @description 
     * Steps that can be performed independently have this value empty.  Steps that cannot be started until one or more conditions
     * are met outline those conditions here.  Conditions are in this format:
     * Step# = Indicates a step was successful where # is the step number.
     * Step#Success = Indicates a step was successful where # is the step number.
     * Step#Failure = Indicates a step was a failure where # is the step number.
     * Step#Hold = Indicates a step is on hold where # is the step number.
     * Step#Pending = Indicates a step is pending where # is the step number.
     * Step#Running = Indicates a step is running where # is the step number.
     * Step#Waiting = Indicates a step is waiting where # is the step number.
     * Step#Skipped = Indicates a step was skipped where # is the step number.
     * Step#Active = Indicates a step is active (hold, waiting, pending, running) where # is the step number.
     * Step#Done = Indicates a step is done (skipped, successful, failed) where # is the step number.
     * External = Indicates a step needs to be started by an external process.
     * Note that when a step has a step action id that can be used as an alias for the step number.  For example, 
     * if the step action id is 'Backup' then StepBackupSuccess is an alias for Step#Success (where # is the 
     * step number for the backup step).  Only alphanumeric values are utilized for the alias (e.g. Backup-File becomes BackupFile).
     * In its simplest form this value is a comma delimited list of step values that this step depends on (e.g. 'Step4,Step5').
     *  In more complex dependency scenarios this value can contain the following characters: &|().  The & or && character is used
     * to mean 'and'.  The | or || character is used to mean 'or'.  The () characters are used for logical grouping of multiple
     * values.  For example: ( Step5 | Step6 ) & ( Step7 | Step8 ) means this step depends on either step number 5 or 6 being completed
     * and it also depends on either step number 7 or 8 being completed.  A value of Step10Failure | Step11Failure means this step
     * depend on step number 10 failing or step number 11 failing.  If an external process starts this step but only when other
     * steps are completed then the value 'External' would be used with other steps.  For example: Step5,Step6,External means an
     * external process can start this step but only when steps 5 and 6 are completed.
     * @servertype String
     * @type {string}
     */
    DependsOnSteps: string = "";

    /**
     * @name SkipWhen
     * @description 
     * Steps that cannot be skipped have this value empty.  Steps that can be skipped when one or more conditions are met outline
     * those conditions here.  Conditions are in this format:
     * Step# = Indicates a step was successful where # is the step number.
     * Step#Success = Indicates a step was successful where # is the step number.
     * Step#Failure = Indicates a step was a failure where # is the step number.
     * Step#Hold = Indicates a step is on hold where # is the step number.
     * Step#Pending = Indicates a step is pending where # is the step number.
     * Step#Running = Indicates a step is running where # is the step number.
     * Step#Waiting = Indicates a step is waiting where # is the step number.
     * Step#Skipped = Indicates a step was skipped where # is the step number.
     * Step#Active = Indicates a step is active (hold, waiting, pending, running) where # is the step number.
     * Step#Done = Indicates a step is done (skipped, successful, failed) where # is the step number.
     * Note that when a step has a step action id that can be used as an alias for the step number.  For example, 
     * if the step action id is 'Backup' then StepBackupSuccess is an alias for Step#Success (where # is the 
     * step number for the backup step).  Only alphanumeric values are utilized for the alias (e.g. Backup-File becomes BackupFile).
     * In its simplest form this value is a comma delimited list of step values would trigger this step to be skipped (e.g. 'Step4,Step5').
     *  In more complex dependency scenarios this value can contain the following characters: &|().  The & or && character is used
     * to mean 'and'.  The | or || character is used to mean 'or'.  The () characters are used for logical grouping of multiple
     * values.  For example: ( Step5 | Step6 ) & ( Step7 | Step8 ) means this step is skipped when either step number 5 or 6 are
     * completed and also when either step number 7 or 8 are completed.
     * This feature is most frequently used for things like rollback steps that need to be completed when previous steps fail as
     * provided for in the depends on steps property.  Once those previous steps succeed then the rollback step should be skipped.
     *  By default the step will get requeued as waiting when the condition provided for in depends on steps property is never true.
     *  By specifying skip conditions here then this step will be skipped instead of requeued as waiting.
     * @servertype String
     * @type {string}
     */
    SkipWhen: string = "";

    /**
     * @name RepeatStepForEach
     * @description 
     * A list of input property names that are collections for which this step should be repeated.  The step is repeated for each
     * combination.  For example, if two properties are listed and for a process one has 10 items in the list and the other has
     * 3 items in the list the step will then be repeated 30 times (10x3).  Each step will have input properties that match the
     * properties in this list with 'CurrentInstance' suffix representing the current iteration from the lists for the given step.
     * @servertype string array
     * @type {string[]}
     */
    RepeatStepForEach: string[] = [];

    /**
     * @name OnStepSuccess
     * @description 
     * The action to take when the step is successful.  Possible values include:
     * C = Continue the process until all steps completed
     * S = Set the process result to success
     * F = Set the process result to failure
     * @servertype String
     * @type {string}
     */
    OnStepSuccess: string = "C";

    /**
     * @name OnStepFailure
     * @description 
     * The action to take when the step fails.  Possible values include:
     * C = Continue the process until all steps completed
     * S = Set the process result to success
     * F = Set the process result to failure
     * @servertype String
     * @type {string}
     */
    OnStepFailure: string = "F";

    /**
     * @name StepActionType
     * @description 
     * The action to take for this step.  Possible values include:
     * Process = Create a child process
     * Case = Create a case
     * Script = Execute a script
     * Job = Create a job
     * RunExecutable = Run an executable
     * RunPowerShell = Run a PowerShell script
     * Plugin = Execute a plugin
     * ConvertFile = Convert a file
     * AttachFile = Attach a file
     * Notification = Deliver a notification
     * Step = Start steps from other processes
     * Sql = Execute sql
     * DbBackup = Database backup
     * DbRestore = Database restore
     * CopyFiles = Copy files
     * DeleteFiles = Delete files
     * Webhook = Webhook
     * SystemWatchEvent = Create System Watch Event
     * Wait = Wait
     * @servertype String
     * @type {string}
     */
    StepActionType: string = "";

    /**
     * @name StepActionId
     * @description 
     * An id used for this step action.  This does not need to be unique and carries no intrinsic meaning but can be used by external
     * processes to find steps of a certain type to execute.  For example, depends on step value of 'E' means an external process
     * needs to start the step and this value may be how that external process finds steps to start.  When unique within the process
     * template this is used for a key to the input and output properties for the step which can then be utilized within other steps
     * in the process.  When no step action id is specified Step1, Step2, etc. will be used which will require updates if step numbers
     * change.
     * @servertype String
     * @type {string}
     */
    StepActionId: string = "";

    /**
     * @name StepActionProperties
     * @description 
     * A JSON object outlining the properties of the action to perform.  The JSON format varies by step action type and version
     * but have some properties in common like step action type and version to help the step process to know how to interpret the
     * properties which are specific to the step action type stored in the dynamic properties child object.  Attributes is a purely
     * dynamic object for user defined values.  Note that {{macros}} can be used to reference input and output properties of various
     * steps.  For example: 
     * {
     *    "StepActionType": "Case"
     *    "Version": 1
     *    "Properties": {}
     *    "Attributes": {}
     * }
     * Step action type 'Case' has the following properties:
     * CaseTemplateId - The id for the case template to use for creating a case for the process step.
     * CaseDescription - The description for the case created for the process step.
     * Wait - When true the step waits until the case created has been closed before continuing.  When false the fact that that
     * case was created is interpreted as step success.
     * CaseSuccessOutcome - The interpretation to make when case closed with success outcome.  Possible values include:
     * S = Step Successful
     * F = Step Failed
     * CaseFailureOutcome - The interpretation to make when case closed with failure outcome.  Possible values include:
     * S = Step Successful
     * F = Step Failed
     * CaseIndeterminateOutcome - The interpretation to make when case closed with indeterminate outcome.  Possible values include:
     * S = Step Successful
     * F = Step Failed
     * Step action type 'Script' has the following properties:
     * ScriptAssetId - The Asset Id of the script to execute for the process step.
     * ScriptSuccessResultCodes - A collection of script result codes that will be interpreted as success.  If none specified then
     * only 0 (success) will be interpret as a successful result.
     * ScriptWaitingResultCodes - A collection of script result codes that will be interpreted as waiting.  If none specified then
     * only 52 (waiting) will be interpret as a waiting result.
     * Step action type 'Job' has the following properties:
     * JobType - A job type of job to create as one time execution job for the process step.
     * JobProperties - A dynamic object of properties for the job.
     * Wait - When true the step waits until the job created has finished before continuing.  The result of the job will be treated
     * as the result of this step.
     * Step action type 'Plugin' has the following properties:
     * Plugin - A plugin to execute for the process step.  An exit code of 0 will be interpreted as step success while any other
     * exit code will be interpret as step failure.
     * Step action type 'Notification' has the following properties:
     * NotificationGroupId - The id of the notification group to trigger for the process step.
     * Wait - When true the step waits until the notification created has finished before continuing.  The result of the notification
     * will be treated as the result of this step.
     * Step action type 'Step' has the following properties:
     * StartStepActionId - The step action id of steps that should be started.
     * StartStepProcessTemplateId - When no start step action id is defined this is the id of the process template containing steps
     * that should be started.
     * StartStepNumbers - When no start step action id this is a collection of step numbers for the specified start step process
     * template id of steps that should be started.
     * MaximumSteps - The maximum number of steps to process that match the step action id or template id and step numbers.  Zero
     * means no maximum.
     * Chronological - True indicates the steps are dequeued chronologically.
     * Step action type 'Sql' has the following properties:
     * DataFolder - An optional data folder for the database for sql execution.
     * DataFolderFailover - An optional failover data folder for the database for sql execution.
     * Family - An optional database family for sql execution.
     * Region - An optional database region for sql execution.
     * Server - An optional database server location for database for sql execution.
     * ServerFailover - An optional failover database server location for database for sql execution.
     * Database - An optional database name for sql execution.
     * SqlStatement - This is the sql statement to execute.
     * SqlWriteOperation - True indicates the sql statement includes a write operation.
     * SqlStatementRowsAffected - The interpretation to make when rows are affected by the sql statement.  Possible values include:
     * N = No Meaning
     * S = Step Successful
     * W = Step Waiting
     * F = Step Failed
     * SqlStatementNoRowsAffected - The interpretation to make when no rows are affected by the sql statement.  Possible values
     * include:
     * N = No Meaning
     * S = Step Successful
     * W = Step Waiting
     * F = Step Failed
     * Step action type 'DbBackup' has the following properties:
     * BackupType - Either Full, Differential, or Log.
     * CompressionType - Either None, Zip, Gzip, or DbServer.
     * OverwriteExistingBackups - True indicates existing backup file of the same name should be overwritten.
     * DataFolder - An optional data folder for the database to backup.
     * DataFolderFailover - An optional failover data folder for the database to backup.
     * Family - An optional database family to backup.
     * Region - An optional database region to backup.
     * Server - An optional database server location for database to backup.
     * ServerFailover - An optional failover database server location for database for sql execution.
     * Database - An optional database name to backup.
     * FileGroup - An optional file group to backup.
     * BackupName - The backup name.
     * BackupDescription - The backup description.
     * Password - The password for the backup.  This is only value when zip compression is being used.
     * CopyOnly - The backup is a copy only backup not impacting the normal sequence of backups.
     * FileName - The backup file name.
     * Step action type 'DbRestore' has the following properties:
     * RestoreType - Either Database or Log.
     * Server - An optional database server location for database to restore.
     * Database - An optional database name to restore.
     * FileGroup - An optional file group to restore.
     * FileName - The backup file name to restore.
     * OnSuccessfulRestore - The action to take upon successful restore.  Possible values include:
     * N = No Action
     * D = Delete File
     * R = Rename File
     * M = Move File
     * OnSuccessfulRestoreTarget - When renaming or moving after successful restore this is either the target file name or folder
     * for that action.
     * Folder - When doing log restore for multiple log files this is the folder where the log backups are located.
     * SearchPattern - When doing log restore for multiple log files this is the search pattern to use for selecting files (e.g.
     * *.bak).
     * Sort - When doing log restore for multiple log files this is the sort order to use for restoring those files.  Possible values
     * include:
     * N = Name
     * C = Create Date Time
     * M = Modified Date Time
     * If the sort should be descending then prefix the value with a negative sign (e.g. -N for name descending)
     * MaximumFileCount - The maximum number of log files to restore.
     * MaximumSessionSeconds - The maximum number of seconds to for the restore session.
     * NoRecovery - When true the restore is done with no recovery so additional logs can be restored.
     * Step action type 'CopyFiles' has the following properties:
     * SourceFileServerId - The id of the file server that is the source of the copy.
     * SourceFolder - The source folder on the file server for the source of the copy.
     * SourceFiles - Source files to copy using optional wildcards.
     * TargetFileServerId - The id of the file server that is the target of the copy.
     * TargetFolder - The target folder on the file server for the target of the copy.
     * Overwrite - True means existing files will be overwritten.
     * Step action type 'DeleteFiles' has the following properties:
     * FileServerId - The id of the file server that is the location to delete from.
     * Folder - The folder on the file server for the location to delete from.
     * Files - Files to delete using optional wildcards.
     * Step action type 'Webhook' has the following properties:
     * {
     *   "Url": "",
     *   "Method": "HttpGet",
     *   "MethodCustom": "",
     *   "Headers": [],
     *   "Body": "",
     *   "ContentType": "",
     *   "Accept": "",
     *   "UserAgent": "",
     *   "UserName": "",
     *   "Password": "",
     *   "SourceIpAddress": "",
     *   "Protocol": "Tls12",
     *   "AlwaysTrustCertificate": false,
     *   "TimeoutMilliseconds": null,
     *   "ExceptionHandling": "Log",
     *   "ErrorResponseWhenBodyContains": "",
     *   "ErrorResponseWhenBodyMatchesRegex": ""
     * }
     * Step action type 'Wait' has no properties.  It waits for the timeout seconds to elapse.
     * @servertype IB.Web.API.Models.v5.ProcessTemplateStepActionPropertiesEditViewModel`1[[System.Object, mscorlib, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089]]
     * @type {ProcessTemplateStepActionPropertiesEditViewModel<any>}
     */
    StepActionProperties: ProcessTemplateStepActionPropertiesEditViewModel<any> = null;

    /**
     * @name TimeoutSeconds
     * @description 
     * Number of seconds after which the step execution will timeout and fail or get requeued for retry.  0 means no timeout.  Note
     * that the system may impose it's own minimum or maximum timeouts.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    TimeoutSeconds: number = 0;

    /**
     * @name OrphanedSeconds
     * @description 
     * Number of seconds after which the step is considered orphaned and available for a watchdog thread to either fail or retry.
     *  This should not be less than the timeout seconds setting or a step could be treated as orphaned before the step itself could
     * detect and handle a timeout which is always preferred over orphaned treatment.  0 means cannot be orphaned.  Note that the
     * system may impose it's own minimum or maximum orphan setting.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    OrphanedSeconds: number = 0;

    /**
     * @name ApprovalPending
     * @description 
     * True indicates this step is ready for review and approval.
     * @servertype Boolean
     * @type {boolean}
     */
    ApprovalPending: boolean = false;

    /**
     * @name ApprovedDateTime
     * @description 
     * The date and time when the step was approved.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ApprovedDateTime: Date = null;

    /**
     * @name ApprovedByContactId
     * @description 
     * The contact id that represents who approved this step.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApprovedByContactId: number = null;

    /**
     * @name ApprovedByContactName
     * @servertype String
     * @type {string}
     */
    ApprovedByContactName: string = "";

    /**
     * @name ApprovedFingerprint
     * @description 
     * A fingerprint of the step that was approved.  This can be used to validate the step action has not changed since approval.
     * @servertype String
     * @type {string}
     */
    ApprovedFingerprint: string = "";

    /**
     * @name ApprovalComments
     * @description 
     * Comments entered regarding the approval process.  This can be used to explain why something wasn't approved or to add notes
     * about the approval.
     * @servertype String
     * @type {string}
     */
    ApprovalComments: string = "";

    /**
     * @name NotificationGroupIdStepStart
     * @description 
     * Notification Group Id to notify on process step start.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepStart: number = null;

    /**
     * @name NotificationGroupIdStepSuccess
     * @description 
     * Notification Group Id to notify on process step success.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepSuccess: number = null;

    /**
     * @name NotificationGroupIdStepFailure
     * @description 
     * Notification Group Id to notify on process step failure.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepFailure: number = null;

    /**
     * @name NotificationGroupIdStepSkipped
     * @description 
     * Notification Group Id to notify on process step skipped.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepSkipped: number = null;

    /**
     * @name NotificationGroupIdStepRetry
     * @description 
     * Notification Group Id to notify on process step retry.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepRetry: number = null;

    /**
     * @name NotificationStepRetryMinutes
     * @description 
     * Number of minutes after which the retry notification group should be triggered.  This can be used to prevent over notification
     * of retries.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationStepRetryMinutes: number = 0;

    /**
     * @name NotificationGroupIdStepWait
     * @description 
     * Notification Group Id to notify on process step wait state.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepWait: number = null;

    /**
     * @name NotificationStepWaitMinutes
     * @description 
     * Number of minutes after which the wait notification group should be triggered.  This can be used to prevent over notification
     * of wait states.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationStepWaitMinutes: number = 0;

    /**
     * @name NotificationGroupIdStepWarning
     * @description 
     * Notification Group Id to notify on process step having a warning.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepWarning: number = null;

    /**
     * @name NotificationGroupIdStepError
     * @description 
     * Notification Group Id to notify on process step having an error.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepError: number = null;

    /**
     * @name NotificationStepErrorAfterAttemptCount
     * @description 
     * The process step attempt count that must be reached before error notifications will be triggered.  This can be used to prevent
     * over notification of errors that are likely to succeed on retry.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationStepErrorAfterAttemptCount: number = 0;

    /**
     * @name WaitStateDequeueMinutes
     * @description 
     * When a step enters (or remains in) a wait state this is the number of minutes to wait for the step to next dequeue.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    WaitStateDequeueMinutes: number = 0;

    /**
     * @name WaitStateLimitMinutes
     * @description 
     * The maximum number of minutes to allow remaining in a wait state.  0 means no maximum.  When the maximum is reached the step
     * will report failure.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    WaitStateLimitMinutes: number = 0;

    /**
     * @name WaitStateAction
     * @description 
     * The action to take when entering (or remaining in) wait state.  Possible values include:
     * N = No Action
     * W = Log Warning
     * E = Log Error
     * @servertype String
     * @type {string}
     */
    WaitStateAction: string = "N";

    /**
     * @name RetryIntervalMinutes
     * @description 
     * A CSV of minutes to use for retry of failed process step.  This supports varying retry intervals.  For example, 5, 30, 60
     * would retry after 5 minutes, then 30 minutes, then every 60 minutes until the retry limit minutes has been reached.
     * @servertype int array
     * @type {number[]}
     */
    RetryIntervalMinutes: number[] = [];

    /**
     * @name RetryLimitMinutes
     * @description 
     * The maximum number of minutes to attempt step completion.  0 means no retries are allowed.  When the maximum is reached the
     * step will report failure.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetryLimitMinutes: number = 0;

    /**
     * @name RetryLimitCount
     * @description 
     * The maximum number of times to attempt step completion.  0 means unlimited retries are allowed.  -1 means no retries are
     * allowed.  When the maximum is reached the step will report failure.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RetryLimitCount: number = 0;

    /**
     * @name RetryAction
     * @description 
     * The action to take when retrying a failed process step.  Possible values include:
     * N = No Action
     * W = Log Warning
     * E = Log Error
     * @servertype String
     * @type {string}
     */
    RetryAction: string = "N";

    /**
     * @name LogType
     * @description 
     * Flag that indicates what type of logging to do for this process step.  This can be more inclusive than the log type set for
     * the process but never less inclusive.  Possible values include: 
     * T = Trace
     * D = Debug
     * I = Info
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    LogType: string = "E";

    /**
     * @name TargetServers
     * @description 
     * A list of target servers that are allowed to service processes based on this template.  Typically not specified but can be
     * used if a process requires local resources only available on one of the specified servers.  When needed this can be specified
     * at the process or process step.
     * @servertype string array
     * @type {string[]}
     */
    TargetServers: string[] = [];

    /**
     * @name CountUsed
     * @description 
     * Count of the number of times this process template step has been used.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CountUsed: number = 0;

    /**
     * @name CountSuccess
     * @description 
     * Count of the number of times processes using this process template step have finished successfully.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CountSuccess: number = 0;

    /**
     * @name CountFailure
     * @description 
     * Count of the number of times processes using this process template step have finished unsuccessfully.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CountFailure: number = 0;

    /**
     * @name CountSkipped
     * @description 
     * Count of the number of times processes using this process template step have skipped the step.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CountSkipped: number = 0;

    /**
     * @name LastUsedDateTime
     * @description 
     * Date and time when the template step was last used.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUsedDateTime: Date = null;

    /**
     * @name LastSuccessDateTime
     * @description 
     * Date and time when the template step was last used successfully.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastSuccessDateTime: Date = null;

    /**
     * @name LastFailureDateTime
     * @description 
     * Date and time when the template step was last used unsuccessfully.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastFailureDateTime: Date = null;

    /**
     * @name LastSkippedDateTime
     * @description 
     * Date and time when the template step was last skipped.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastSkippedDateTime: Date = null;

    /**
     * @name Properties
     * @description 
     * Properties.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ProcessTemplateEditViewModel
 * @description
 * The Process Template table contains settings and default information for processes that use this template.
 */
export class ProcessTemplateEditViewModel {

    /**
     * @name ProcessTemplateId
     * @description 
     * Unique value that represents this process template.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTemplateId: number = null;

    /**
     * @name ExternalProcessTemplateId
     * @description 
     * An optional external process template id for this template.
     * @servertype String
     * @type {string}
     */
    ExternalProcessTemplateId: string = "";

    /**
     * @name Description
     * @description 
     * Description for this process template.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Tags
     * @description 
     * A list of tags for this process template.
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name Enabled
     * @description 
     * When true the process template is enabled and can be used for new processes.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Type
     * @description 
     * Process type taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Category
     * @description 
     * Process category taken from a system pick list.
     * @servertype String
     * @type {string}
     */
    Category: string = "";

    /**
     * @name Priority
     * @description 
     * The priority of processes that use this template.  Possible values include:
     * -1 = Low
     *  0 = Normal
     *  1 = High
     * @servertype Int16 (nullable)
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name ProcessDescriptionTemplate
     * @description 
     * A template to use for the description for processes created from this template.  The template can use {{macro}} placeholders
     * with handlebars (e.g. double curly braces) and has the following object properties available:
     * Template - the process template object
     * ProcessInputProperties - the input properties for the process
     * @servertype String
     * @type {string}
     */
    ProcessDescriptionTemplate: string = "";

    /**
     * @name InputPropertiesDocumentation
     * @description 
     * A JSON collection of documentation objects for process input properties.
     * @servertype PropertyMetaDataViewModel array
     * @type {m.PropertyMetaDataViewModel[]}
     */
    InputPropertiesDocumentation: m.PropertyMetaDataViewModel[] = [];

    /**
     * @name OutputPropertiesDocumentation
     * @description 
     * A JSON collection of documentation objects for process output properties.
     * @servertype PropertyMetaDataViewModel array
     * @type {m.PropertyMetaDataViewModel[]}
     */
    OutputPropertiesDocumentation: m.PropertyMetaDataViewModel[] = [];

    /**
     * @name InputPropertiesTemplate
     * @description 
     * A JSON template to use for input properties.  These typically match the input properties of the first step.
     * @servertype String
     * @type {string}
     */
    InputPropertiesTemplate: string = "";

    /**
     * @name OutputPropertiesTemplate
     * @description 
     * A JSON template to use for output properties.  If not specified this will match the output properties of the last step. 
     * The template can use {{macro}} placeholders with handlebars (e.g. double curly braces) and has the following object properties
     * available:
     * Process - the process object
     * Steps - a collection of step objects for the process
     * Template - the process template object
     * ProcessInputProperties - the input properties for the process
     * Step#InputProperties - the input properties for the step number specified by the # placeholder (e.g. Step3InputProperties)
     * Step#OutputProperties - the output properties for the step number specified by the # placeholder (e.g. Step3OutputProperties)
     * Step#TemplateActionProperties - the step template action properties for the step number specified by the # placeholder (e.g.
     * Step3TemplateActionProperties)
     * Step#TemplateActionAttributes - the step template action attributes for the step number specified by the # placeholder (e.g.
     * Step3TemplateActionAttributes)
     * Note that when a step has a step action id that can be used as an alias for the step number.  For example, 
     * if the step action id is 'Backup' then StepBackupInputProperties is an alias for Step#InputProperties (where # is the 
     * step number for the backup step).  Only alphanumeric values are utilized for the alias (e.g. Backup-File becomes BackupFile).
     * @servertype String
     * @type {string}
     */
    OutputPropertiesTemplate: string = "";

    /**
     * @name ValidProcessTriggers
     * @description 
     * A JSON list of strings that represent valid triggers for this process template.  This list can be used to limit the number
     * of process templates presented for selection in various trigger scenarios.  Note that if this list is empties then all options
     * are considered valid.  Possible values include: 
     * Manual = Manual
     * Webhook = Webhook
     * CaseWorkflow = Case Task
     * DataTableTrigger = Data Table Trigger
     * Job = Job
     * MailAction = Mail Action
     * Billing = Billing
     * Provisioning = Provisioning
     * @servertype string array
     * @type {string[]}
     */
    ValidProcessTriggers: string[] = [];

    /**
     * @name ViewRequiresRoleId
     * @description 
     * Specifies the required role id required to access processes using this process template.  This can be used for scenarios
     * where only select groups should have access to a process.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ViewRequiresRoleId: number = null;

    /**
     * @name CreateRequiresRoleId
     * @description 
     * Specifies the required role id required to create processes using this template.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CreateRequiresRoleId: number = null;

    /**
     * @name ViewRequiresGroupId
     * @description 
     * Specifies the required group id required to access processes using this process template.  This can be used for scenarios
     * where only select groups should have access to a process.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ViewRequiresGroupId: number = null;

    /**
     * @name CreateRequiresGroupId
     * @description 
     * Specifies the required group id required to create processes using this process template.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CreateRequiresGroupId: number = null;

    /**
     * @name ApprovalRequiredFromContactIdList
     * @description 
     * A list of contact id values that can approve processes created from this template.  If the list is empty then no approval
     * is required for processes create from this template.
     * @servertype long array
     * @type {number[]}
     */
    ApprovalRequiredFromContactIdList: number[] = [];

    /**
     * @name ApprovalRequiredFromContactList
     * @servertype ContactReferenceViewModel array
     * @type {ContactReferenceViewModel[]}
     */
    ApprovalRequiredFromContactList: ContactReferenceViewModel[] = [];

    /**
     * @name NotificationGroupIdProcessStart
     * @description 
     * Notification Group Id to notify on process start.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdProcessStart: number = null;

    /**
     * @name NotificationGroupIdProcessSuccess
     * @description 
     * Notification Group Id to notify on process success.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdProcessSuccess: number = null;

    /**
     * @name NotificationGroupIdProcessFailure
     * @description 
     * Notification Group Id to notify on process failure.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdProcessFailure: number = null;

    /**
     * @name NotificationGroupIdStepStart
     * @description 
     * Notification Group Id to notify on process step start.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepStart: number = null;

    /**
     * @name NotificationGroupIdStepSuccess
     * @description 
     * Notification Group Id to notify on process step success.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepSuccess: number = null;

    /**
     * @name NotificationGroupIdStepFailure
     * @description 
     * Notification Group Id to notify on process step failure.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepFailure: number = null;

    /**
     * @name NotificationGroupIdStepSkipped
     * @description 
     * Notification Group Id to notify on process step skipped.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepSkipped: number = null;

    /**
     * @name NotificationGroupIdStepRetry
     * @description 
     * Notification Group Id to notify on process step retry.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepRetry: number = null;

    /**
     * @name NotificationStepRetryMinutes
     * @description 
     * Number of minutes after which the retry notification group should be triggered.  This can be used to prevent over notification
     * of retries.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationStepRetryMinutes: number = 0;

    /**
     * @name NotificationGroupIdStepWait
     * @description 
     * Notification Group Id to notify on process step wait state.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepWait: number = null;

    /**
     * @name NotificationStepWaitMinutes
     * @description 
     * Number of minutes after which the wait notification group should be triggered.  This can be used to prevent over notification
     * of wait states.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationStepWaitMinutes: number = 0;

    /**
     * @name NotificationGroupIdStepWarning
     * @description 
     * Notification Group Id to notify on process step having a warning.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepWarning: number = null;

    /**
     * @name NotificationGroupIdStepError
     * @description 
     * Notification Group Id to notify on process step having an error.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupIdStepError: number = null;

    /**
     * @name NotificationStepErrorAfterAttemptCount
     * @description 
     * The process step attempt count that must be reached before error notifications will be triggered.  This can be used to prevent
     * over notification of errors that are likely to succeed on retry.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NotificationStepErrorAfterAttemptCount: number = 0;

    /**
     * @name ChronologicalPeerProcessing
     * @description 
     * True indicates all peer processes must processed chronologically so they execute in the order they were created This is important
     * for some processes like database log backup and restore operations where logs must be restored in the order they were taken
     * regardless of where they are in the queue.  If the process has a process peer id set that is used to determine peers otherwise
     * the process template id is used to identify peers.
     * @servertype Boolean
     * @type {boolean}
     */
    ChronologicalPeerProcessing: boolean = true;

    /**
     * @name LogType
     * @description 
     * Flag that indicates what type of logging to do for this process.  Possible values include: 
     * T = Trace
     * D = Debug
     * I = Info
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    LogType: string = "E";

    /**
     * @name TargetServers
     * @description 
     * A list of target servers that are allowed to service processes based on this template.  Typically not specified but can be
     * used if a process requires local resources only available on one of the specified servers.  When needed this can be specified
     * at the process or process step.
     * @servertype string array
     * @type {string[]}
     */
    TargetServers: string[] = [];

    /**
     * @name CountUsed
     * @description 
     * Count of the number of times this process template has been used.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CountUsed: number = 0;

    /**
     * @name CountSuccess
     * @description 
     * Count of the number of times processes using this process template have finished successfully.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CountSuccess: number = 0;

    /**
     * @name CountFailure
     * @description 
     * Count of the number of times processes using this process template have finished unsuccessfully.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CountFailure: number = 0;

    /**
     * @name LastUsedDateTime
     * @description 
     * Date and time when the template was last used.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUsedDateTime: Date = null;

    /**
     * @name LastSuccessDateTime
     * @description 
     * Date and time when the template was last used successfully.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastSuccessDateTime: Date = null;

    /**
     * @name LastFailureDateTime
     * @description 
     * Date and time when the template was last used unsuccessfully.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastFailureDateTime: Date = null;

    /**
     * @name Properties
     * @description 
     * Properties.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name Steps
     * @servertype ProcessTemplateStepEditViewModel array
     * @type {ProcessTemplateStepEditViewModel[]}
     */
    Steps: ProcessTemplateStepEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ProcessEditViewModel
 * @description
 * The Process table contains information on system processes.
 */
export class ProcessEditViewModel {

    /**
     * @name ProcessId
     * @description 
     * Unique value that represents this process record.
     * @servertype String
     * @type {string}
     */
    ProcessId: string = null;

    /**
     * @name ProcessTemplateId
     * @description 
     * The process template id this process follows.
     * @servertype Int64
     * @type {number}
     */
    ProcessTemplateId: number = null;

    /**
     * @name ProcessTag
     * @description 
     * A tag that can be used in combination with the process key to validate external links to the process were known and not guessed.
     * @servertype String
     * @type {string}
     */
    ProcessTag: string = "";

    /**
     * @name ProcessDateTime
     * @description 
     * Date and time when the process was created.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ProcessDateTime: Date = new Date();

    /**
     * @name Description
     * @description 
     * A description for this process as defined by the process template.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Priority
     * @description 
     * The priority of this process.  Possible values include:
     * -1 = Low
     *  0 = Normal
     *  1 = High
     * @servertype Int16 (nullable)
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name ChronologicalPeerProcessing
     * @description 
     * True indicates all peer processes must processed chronologically so they execute in the order they were created This is important
     * for some processes like database log backup and restore operations where logs must be restored in the order they were taken
     * regardless of where they are in the queue.  If the process has a process peer id set that is used to determine peers otherwise
     * the process template id is used to identify peers.
     * @servertype Boolean
     * @type {boolean}
     */
    ChronologicalPeerProcessing: boolean = true;

    /**
     * @name ProcessPeerId
     * @description 
     * For processes that must execute peer operations chronologically this is a peer id used to identify peers.  When not specified
     * the process template id will be used to identify peers but providing a value here allows more definitive identification of
     * peers for scenarios where a template is used by non-peers.
     * @servertype String
     * @type {string}
     */
    ProcessPeerId: string = "";

    /**
     * @name InputProperties
     * @description 
     * The input properties for this process.
     * @servertype String
     * @type {string}
     */
    InputProperties: string = "";

    /**
     * @name OutputProperties
     * @description 
     * The output properties for this process.
     * @servertype String
     * @type {string}
     */
    OutputProperties: string = "";

    /**
     * @name TriggeredByContactId
     * @description 
     * The contact id of the contact triggering this process.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggeredByContactId: number = null;

    /**
     * @name TriggeredByContactName
     * @servertype String
     * @type {string}
     */
    TriggeredByContactName: string = "";

    /**
     * @name TriggeredByResourceType
     * @description 
     * The resource type (e.g. table, etc.) that triggered this process.
     * @servertype String
     * @type {string}
     */
    TriggeredByResourceType: string = "";

    /**
     * @name TriggeredByResourceId
     * @description 
     * The triggered by resource id is the key of the triggered by resource type.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggeredByResourceId: number = null;

    /**
     * @name TriggeredByResourceId2
     * @description 
     * The triggered by resource id 2 is the key of the triggered by resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    TriggeredByResourceId2: string = null;

    /**
     * @name Approved
     * @description 
     * True indicates process execution has been approved.
     * @servertype Boolean
     * @type {boolean}
     */
    Approved: boolean = false;

    /**
     * @name ApprovedDateTime
     * @description 
     * The date and time when the process was approved.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ApprovedDateTime: Date = null;

    /**
     * @name ApprovedByContactId
     * @description 
     * The contact id that represents who approved this process.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApprovedByContactId: number = null;

    /**
     * @name ApprovedByContactName
     * @servertype String
     * @type {string}
     */
    ApprovedByContactName: string = "";

    /**
     * @name ApprovalComments
     * @description 
     * Comments entered regarding the approval process.  This can be used to explain why something wasn't approved or to add notes
     * about the approval.
     * @servertype String
     * @type {string}
     */
    ApprovalComments: string = "";

    /**
     * @name ReferencedResourceType
     * @description 
     * The referenced resource type (e.g. table, etc.) of the item being referenced.
     * @servertype String
     * @type {string}
     */
    ReferencedResourceType: string = "";

    /**
     * @name ReferencedResourceId
     * @description 
     * The referenced resource id is the key of the referenced resource type for the item being referenced.  For example, if the
     * referenced resource type is Asset this value would be the AssetId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReferencedResourceId: number = null;

    /**
     * @name ReferencedResourceId2
     * @description 
     * The referenced resource id 2 is the key of the referenced resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    ReferencedResourceId2: string = null;

    /**
     * @name Status
     * @description 
     * Status of this process.  Possible values are: 
     * P = Pending (Process is Pending)
     * R = Running (Process has Started)
     * S = Success (Process was Successful)
     * F = Failure (Process has Failed)
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name ResultCode
     * @description 
     * The result code for this process.  Possible values include standard result code plus these options:
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultMessage
     * @description 
     * Result message for this process.  More details can be found the steps and logs if needed.
     * @servertype String
     * @type {string}
     */
    ResultMessage: string = "";

    /**
     * @name RequestedStartEventType
     * @description 
     * If the process start is triggered by an event this is the event type.
     * @servertype String
     * @type {string}
     */
    RequestedStartEventType: string = "";

    /**
     * @name RequestedStartEventVersion
     * @description 
     * A version number for the requested start event properties.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    RequestedStartEventVersion: number = 0;

    /**
     * @name RequestedStartEventProperties
     * @description 
     * The properties for the requested start event.
     * @servertype String
     * @type {string}
     */
    RequestedStartEventProperties: string = "";

    /**
     * @name RequestedStartDateTime
     * @description 
     * The date and time this process was requested to start on.  This will frequently be null but if a process should not start
     * until a specific time that is specified here.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RequestedStartDateTime: Date = null;

    /**
     * @name StartDateTime
     * @description 
     * The date and time this process started.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    StartDateTime: Date = null;

    /**
     * @name FinishDateTime
     * @description 
     * The date and time this process finished.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    FinishDateTime: Date = null;

    /**
     * @name TargetServers
     * @description 
     * A list of target servers that are allowed to service processes based on this template.  Typically not specified but can be
     * used if a process requires local resources only available on one of the specified servers.  When needed this can be specified
     * at the process or process step.
     * @servertype string array
     * @type {string[]}
     */
    TargetServers: string[] = [];

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name TaxConfigurationEditViewModel
 * @description
 * The Tax Configuration table contains tax configuration information that gets utilized by the tax engine to decide how taxes
 * are found, calculated, applied, etc.
 */
export class TaxConfigurationEditViewModel {

    /**
     * @name TaxConfigurationId
     * @description 
     * Tax Configuration Id uniquely identifies this tax configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaxConfigurationId: number = null;

    /**
     * @name Description
     * @description 
     * A description for this tax configuration.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * True indicates the tax configuration is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name DefaultConfiguration
     * @description 
     * True indicates the tax configuration is a default configuration.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultConfiguration: boolean = false;

    /**
     * @name Priority
     * @description 
     * The priority for this tax configuration.  Lower values have a higher priority.  If more than one tax configuration is suggested
     * via different lookup methods the one with the lowest priority will be selected.  If more than one have the same priority
     * the tax engine will select one.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Priority: number = 100;

    /**
     * @name CompanyHeadquartersLocationId
     * @description 
     * If the company headquarters location plays a role in tax selection this is the location id for the company headquarters location.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CompanyHeadquartersLocationId: number = null;

    /**
     * @name CompanyHeadquartersLocationName
     * @servertype String
     * @type {string}
     */
    CompanyHeadquartersLocationName: string = "";

    /**
     * @name GeocodeLookupLimitedToOneGeocodePerJurisdictionLevel
     * @description 
     * When true and doing geocode lookup each jurisdiction level (defined by how many hyphen delimiters are found in the geocode)
     * can only have one geocode in the results for each geocode source category (location, account, source, target).  If one is
     * marked as default that is the geocode used; otherwise, any one is selected.  Note that it's strongly suggested that only
     * geocodes with the highest number of jurisdiction levels is findable via lookup leaving the tax engine to roll up to higher
     * level jurisdictions automatically. (e.g. Define lookup for US-MI-Oakland-Troy and let the tax engine handle extracting US-MI-Oakland,
     * US-MI, and US from that geocode automatically.
     * @servertype Boolean
     * @type {boolean}
     */
    GeocodeLookupLimitedToOneGeocodePerJurisdictionLevel: boolean = true;

    /**
     * @name TaxableEventDetermination
     * @description 
     * This value is used to indicate if there is any special handling for determining if a transaction represents a taxable event.
     *  Possible values include: 
     * S = Standard
     * A = All Sources Taxable
     * M = Multiple Sources Taxable
     * O = One Source Taxable
     * The 'Standard' method is to assume the event is taxable if the geocode sources found result in tax rates being identified.
     *  
     * The 'All Sources Taxable' method requires that all geocode sources specified in taxable event sources have a tax rate before
     * the event is considered taxable.  Note that those tax rates are not necessarily all used for taxation but must be present
     * for the event to be taxable.
     * The 'Multiple Sources Taxable' method requires that more than one of the geocode sources specified in taxable event sources
     * has a tax rate before the event is considered taxable.  Note that those tax rates are not necessarily the ones used for taxation
     * but must be present for the event to be taxable.
     * The 'One Source Taxable' method requires that one of the geocode sources specified in taxable event sources has a tax rate
     * before the event is considered taxable.  Note that those tax rates are not necessarily the ones used for taxation but must
     * be present for the event to be taxable.
     * @servertype String
     * @type {string}
     */
    TaxableEventDetermination: string = "S";

    /**
     * @name TaxableEventSources
     * @description 
     * When the taxable event determination setting requires geocode sources be taxable for the event to be taxable this is a list
     * of geocode sources to be used for that purpose.  The settings is similar to the geocode sources property but in this case
     * only used to determining taxability not to determine the taxes themselves.  Possible values include: 
     * LocationHq = Company Headquarters Location
     * LocationRetail = All Company Retail Locations
     * LocationWarehouse = All Company Warehouse Locations
     * LocationOther = All Company Other Locations
     * Location = All Company Locations
     * Account = Billing Account
     * CcNpaNxxSource = E.164 Country Code plus NPA plus NXX for Source
     * CcNpaNxxTarget = E.164 Country Code plus NPA plus NXX for Target
     * CcNpaSource = E.164 Country Code plus NPA for Source
     * CcNpaTarget = E.164 Country Code plus NPA for Target
     * CcNpaPair = E.164 Country Code plus NPA for Source and Target separated by a hyphen
     * CcSource = E.164 Country Code for Source
     * CcTarget = E.164 Country Code for Target
     * CcPair = E.164 Country Code for Source and Target separated by a hyphen
     * LataSource = LATA Number for Source
     * LataTarget = LATA Number for Target
     * LataPair = LATA Number for Source and Target separated by a hyphen
     * IataSource = IATA Airport Code for Source
     * IataTarget = IATA Airport Code for Target
     * IataPair = IATA Airport Code for Source and Target separated by a hyphen
     * FallbackSource = Allow Fallback Lookup for Source
     * FallbackTarget = Allow Fallback Lookup for Target
     * Taxable event sources are grouped into four categories:
     * Location - Company locations of various types provided for in the geocode sources list.  If company locations do not have
     * geocode(s) assigned to them various lookup attempts will be made based on country, postal code, phone number parts, etc.
     * in an attempt to find one or more geocodes for the location.
     * Account - Billing account for the entity being billed.  If the billing account does not have geocode(s) assigned to it various
     * lookup attempts will be made based on country, postal code, phone number parts, etc. in an attempt to find one or more geocodes
     * for the account.
     * Source - The taxable event source (not to be confused with a geocode source of which it is one of several).  This may be
     * the source of a phone call, source location of a purchase event, etc.  Taxable event sources do not have geocodes directly
     * assigned to them and are always found via a lookup based on other source location information.  When found via a source/target
     * pair the geocodes are applied to both source and target categories.
     * Target - The taxable event target.  This may be the target of a phone call, target location of a purchase event, etc.  Taxable
     * event targets do not have geocodes directly assigned to them and are always found via a lookup based on other target location
     * information.  When found via a source/target pair the geocodes are applied to both source and target categories.
     * @servertype string array
     * @type {string[]}
     */
    TaxableEventSources: string[] = [];

    /**
     * @name GeocodeSources
     * @description 
     * A list of geocode sources used for taxation.  Possible values include: 
     * LocationHq = Company Headquarters Location
     * LocationRetail = All Company Retail Locations
     * LocationWarehouse = All Company Warehouse Locations
     * LocationOther = All Company Other Locations
     * Location = All Company Locations
     * Account = Billing Account
     * CcNpaNxxSource = E.164 Country Code plus NPA plus NXX for Source
     * CcNpaNxxTarget = E.164 Country Code plus NPA plus NXX for Target
     * CcNpaSource = E.164 Country Code plus NPA for Source
     * CcNpaTarget = E.164 Country Code plus NPA for Target
     * CcNpaPair = E.164 Country Code plus NPA for Source and Target separated by a hyphen
     * CcSource = E.164 Country Code for Source
     * CcTarget = E.164 Country Code for Target
     * CcPair = E.164 Country Code for Source and Target separated by a hyphen
     * LataSource = LATA Number for Source
     * LataTarget = LATA Number for Target
     * LataPair = LATA Number for Source and Target separated by a hyphen
     * IataSource = IATA Airport Code for Source
     * IataTarget = IATA Airport Code for Target
     * IataPair = IATA Airport Code for Source and Target separated by a hyphen
     * FallbackSource = Allow Fallback Lookup for Source
     * FallbackTarget = Allow Fallback Lookup for Target
     * Geocode sources are grouped into four categories:
     * Location - Company locations of various types provided for in the geocode sources list.  If company locations do not have
     * geocode(s) assigned to them various lookup attempts will be made based on country, postal code, phone number parts, etc.
     * in an attempt to find one or more geocodes for the location.
     * Account - Billing account for the entity being billed.  If the billing account does not have geocode(s) assigned to it various
     * lookup attempts will be made based on country, postal code, phone number parts, etc. in an attempt to find one or more geocodes
     * for the account.
     * Source - The taxable event source (not to be confused with a geocode source of which it is one of several).  This may be
     * the source of a phone call, source location of a purchase event, etc.  Taxable event sources do not have geocodes directly
     * assigned to them and are always found via a lookup based on other source location information.  When found via a source/target
     * pair the geocodes are applied to both source and target categories.
     * Target - The taxable event target.  This may be the target of a phone call, target location of a purchase event, etc.  Taxable
     * event targets do not have geocodes directly assigned to them and are always found via a lookup based on other target location
     * information.  When found via a source/target pair the geocodes are applied to both source and target categories.
     * @servertype string array
     * @type {string[]}
     */
    GeocodeSources: string[] = [];

    /**
     * @name GeocodeSourcesWildcardSupportForSource
     * @description 
     * True will result in source category searches that do not find any geocodes having a subsequent search performed using a '*'
     * wildcard instead of the source input data.
     * @servertype Boolean
     * @type {boolean}
     */
    GeocodeSourcesWildcardSupportForSource: boolean = false;

    /**
     * @name GeocodeSourcesWildcardSupportForTarget
     * @description 
     * True will result in target category searches that do not find any geocodes having a subsequent search performed using a '*'
     * wildcard instead of the target input data.
     * @servertype Boolean
     * @type {boolean}
     */
    GeocodeSourcesWildcardSupportForTarget: boolean = false;

    /**
     * @name GeocodeSourcesWildcardSupportForPair
     * @description 
     * True will result in source-target pair searches that do not find any geocodes having a subsequent search performed using
     * a '*' wildcard instead of the source input data.  If no geocodes are found then another search will be performed using a
     * '*' wildcard instead of the target input data.
     * @servertype Boolean
     * @type {boolean}
     */
    GeocodeSourcesWildcardSupportForPair: boolean = false;

    /**
     * @name GeocodeSourcesFallbackSearchRaisesWarning
     * @description 
     * True will result in a warning being raised if the fallback geocode source is ever used.  Unlike other geocode sources the
     * fallback source is only checked when no geocodes have been found from previous geocode sources.
     * @servertype Boolean
     * @type {boolean}
     */
    GeocodeSourcesFallbackSearchRaisesWarning: boolean = true;

    /**
     * @name GeocodeSourcesWildcardSearchRaisesWarning
     * @description 
     * True will result in a warning being raised if a wildcard is utilized in geocode source searches.  Wildcards are only used
     * when configured and only when a non-wildcard search for a given geocode source did not result in any geocodes being found.
     * @servertype Boolean
     * @type {boolean}
     */
    GeocodeSourcesWildcardSearchRaisesWarning: boolean = true;

    /**
     * @name GeocodeJurisdictionMinimumMatchCount
     * @description 
     * When geocode sources has more than one category listed (e.g. location, account, source, and/or target) this is the number
     * of matches used to determine geocode(s) for taxation.  If one or zero then all are included.  Otherwise, the tax engine will
     * look at all geocode source categories and use the geocodes that are found to exist at least the number of times specified
     * by this match count.  This is frequently used in 2/3 roaming tax scenarios where the billing account location, source location
     * of the event, and target location of the event are used to find geocodes and then any where two of the three geocode sources
     * match are used as tax jurisdictions.
     * @servertype Byte (nullable)
     * @type {number}
     */
    GeocodeJurisdictionMinimumMatchCount: number = 1;

    /**
     * @name RoamingTaxes
     * @description 
     * Flag that indicates how tax jurisdiction is determined.  Possible values include: 
     * L = Customer location defines jurisdiction. (This is always true for non-usage items.)
     * S = Source number defines jurisdiction.
     * T = Target number defines jurisdiction.
     * 2 = 2 out of 3 rule defines jurisdiction.  (Under this rule if any tax jurisdiction covers 2 of customer location, source
     * location, and target location then taxes for that jurisdiction apply.)
     * Fixed wire accounts where customer location is always the source location should have this property set to L.
     * @servertype String
     * @type {string}
     */
    RoamingTaxes: string = "L";

    /**
     * @name PaymentTransactionIndependentTaxation
     * @description 
     * The method to use for payment transaction taxation.  When set to disabled payment processing will not trigger any taxation.
     *  Unlike the invoice engine where taxation is assumed the payment processing engine only triggers taxation when enabled. 
     * Possible values include:
     * D = Disabled (No taxation for payment transactions)
     * S = Synchronous (Taxation performed during payment transaction processing)
     * A = Asynchronous (Taxation performed as separate process after payment transaction processing)
     * N = Asynchronous with Delayed Notifications (Taxation performed as separate process and receipt is delayed until completed)
     * Note that when tax information is included in email receipts either 'Synchronous' or 'Asynchronous With Delayed Notifications'
     * needs to be selected.  Since decisions about things like timing of email receipts may need to be made independently from
     * the taxation engine processing all configurations must use the most conservative setting so if any need delayed notification
     * they all should have delayed notification, etc.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionIndependentTaxation: string = "D";

    /**
     * @name IncludedTaxAmountDecimalCount
     * @description 
     * The number of decimals to include in the tax amount when calculating taxes included in an amount (e.g. 2 is pennies).  When
     * the tax calculation method is set to calculated rounding is done to the currency precision (e.g. 2) as that value is added
     * to the invoice or payment amount.  This setting is only used when the tax calculation method is set to included.
     * @servertype Byte (nullable)
     * @type {number}
     */
    IncludedTaxAmountDecimalCount: number = 6;

    /**
     * @name LogType
     * @description 
     * Flag that indicates what type of logging to do for the tax engine.  Possible values include: 
     * T = Trace
     * D = Debug
     * I = Info
     * W = Warning
     * E = Error
     * @servertype String
     * @type {string}
     */
    LogType: string = "W";

    /**
     * @name AllowTaxConfigurationChangeFromTaxableEventDeterminationTaxable
     * @description 
     * True indicates the tax configuration is allowed to change during the taxable event determination process if determined to
     * be taxable.  Geocodes and geocode lookup pointers have a setting that can trigger a tax configuration change and if this
     * is true that setting will be respected.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowTaxConfigurationChangeFromTaxableEventDeterminationTaxable: boolean = true;

    /**
     * @name AllowTaxConfigurationChangeFromTaxableEventDeterminationNotTaxable
     * @description 
     * True indicates the tax configuration is allowed to change during the taxable event determination process if determined to
     * be not taxable.  Geocodes and geocode lookup pointers have a setting that can trigger a tax configuration change and if this
     * is true that setting will be respected.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowTaxConfigurationChangeFromTaxableEventDeterminationNotTaxable: boolean = true;

    /**
     * @name AllowTaxConfigurationChangeFromGeocodeSourcesSearch
     * @description 
     * True indicates the tax configuration is allowed to change during the geocode sources search process.  Geocodes and geocode
     * lookup pointers have a setting that can trigger a tax configuration change and if this is true that setting will be respected.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowTaxConfigurationChangeFromGeocodeSourcesSearch: boolean = true;

    /**
     * @name DefaultTaxCalculationMethod
     * @description 
     * Default tax calculation method to use when not identifiable from a tax processing request.  Possible values include: 
     * C = Calculated (Calculated from Item Price)
     * I = Included (Included in Item Price)
     * @servertype String
     * @type {string}
     */
    DefaultTaxCalculationMethod: string = "C";

    /**
     * @name DefaultTaxCalculationMethodRaisesWarning
     * @description 
     * When true a warning will be raised if the default tax calculation method setting has to be used.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultTaxCalculationMethodRaisesWarning: boolean = true;

    /**
     * @name DefaultTaxItemCode
     * @description 
     * Default tax item code used when no item code is submitted with a tax processing request.
     * @servertype String
     * @type {string}
     */
    DefaultTaxItemCode: string = "";

    /**
     * @name DefaultTaxItemCodeRaisesWarning
     * @description 
     * When true a warning will be raised if the default tax item code setting has to be used.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultTaxItemCodeRaisesWarning: boolean = true;

    /**
     * @name DefaultTaxCustomerType
     * @description 
     * Default tax customer type used when no type is defined for the contact.
     * @servertype String
     * @type {string}
     */
    DefaultTaxCustomerType: string = "";

    /**
     * @name DefaultTaxCustomerTypeRaisesWarning
     * @description 
     * When true a warning will be raised if the default tax customer type setting has to be used.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultTaxCustomerTypeRaisesWarning: boolean = true;

    /**
     * @name DefaultTaxServiceProviderType
     * @description 
     * Default tax service provider type used when no type is defined for the contact.
     * @servertype String
     * @type {string}
     */
    DefaultTaxServiceProviderType: string = "";

    /**
     * @name DefaultTaxServiceProviderTypeRaisesWarning
     * @description 
     * When true a warning will be raised if the default tax service provider type setting has to be used.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultTaxServiceProviderTypeRaisesWarning: boolean = true;

    /**
     * @name DefaultTaxVendorType
     * @description 
     * Default tax vendor type used when no type is defined for the contact.
     * @servertype String
     * @type {string}
     */
    DefaultTaxVendorType: string = "";

    /**
     * @name DefaultTaxVendorTypeRaisesWarning
     * @description 
     * When true a warning will be raised if the default tax vendor type setting has to be used.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultTaxVendorTypeRaisesWarning: boolean = true;

    /**
     * @name DefaultIsoCurrencyCode
     * @description 
     * Default tax ISO currency code used when no currency is submitted with a tax processing request.
     * @servertype String
     * @type {string}
     */
    DefaultIsoCurrencyCode: string = "";

    /**
     * @name DefaultIsoCurrencyCodeRaisesWarning
     * @description 
     * When true a warning will be raised if the default ISO currency code setting has to be used.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultIsoCurrencyCodeRaisesWarning: boolean = true;

    /**
     * @name PaymentTransactionAttributePropertyNamePackageCode
     * @description 
     * When tax identification requires Package Code this is the attribute name of the attribute that holds that value.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionAttributePropertyNamePackageCode: string = "";

    /**
     * @name PaymentTransactionAttributePropertyNameSourceIata
     * @description 
     * When tax identification requires Source IATA this is the attribute name of the attribute that holds that value.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionAttributePropertyNameSourceIata: string = "";

    /**
     * @name PaymentTransactionAttributePropertyNameTargetIata
     * @description 
     * When tax identification requires Target IATA this is the attribute name of the attribute that holds that value.
     * @servertype String
     * @type {string}
     */
    PaymentTransactionAttributePropertyNameTargetIata: string = "";

    /**
     * @name Properties
     * @description 
     * Properties.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TaxGeocodeLookupEditViewModel
 * @description
 * The Tax Geocode Lookup Table contains a cross reference of location criteria to Geocodes.  Common location criteria includes
 * postal codes, IATA city codes, etc.  The location criteria are repeated as necessary to cover all geocode locations served.
 */
export class TaxGeocodeLookupEditViewModel {

    /**
     * @name TaxGeocodeLookupId
     * @description 
     * Tax Geocode Lookup Id uniquely identifies this tax geocode lookup record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaxGeocodeLookupId: number = null;

    /**
     * @name Type
     * @description 
     * The look up type.  Common values include: 
     * Cc3Pc = ISO 3166-1 alpha-3 Country Code plus Postal Code
     * Cc3 = ISO 3166-1 alpha-3 Country Code
     * Pc = Postal Code
     * CcNpaNxx = E.164 Country Code plus NPA plus NXX
     * CcNpa = E.164 Country Code plus NPA
     * CcNpaPair = E.164 Country Code plus NPA for Source and Target separated by a hyphen
     * Cc = E.164 Country Code
     * CcPair = E.164 Country Code for Source and Target separated by a hyphen
     * Lata = LATA Number
     * LataPair = LATA Number for Source and Target separated by a hyphen
     * Iata = IATA Airport Code
     * IataPair = IATA Airport Code for Source and Target separated by a hyphen
     * Fallback = Fallback Geocode
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Value
     * @description 
     * The look up value for the specified type.  For example, if the type is PostalCode this value is the postal code to use for
     * the look up.  For Fallback type this value is empty as that is a fallback to use as last resort when a geocode cannot otherwise
     * be found.
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name ValueEndRange
     * @description 
     * The look up value end range is the ending value when a range of values are used to specify this location.
     * @servertype String
     * @type {string}
     */
    ValueEndRange: string = "";

    /**
     * @name DefaultGeocode
     * @description 
     * When true this is the default (typically the most populated) geocode to use when there are multiple geocodes for the same
     * look up value.  If the tax engine is configured to only allow one geocode then when true this would be geocode selected.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultGeocode: boolean = false;

    /**
     * @name Geocode
     * @description 
     * Geographic code representing a tax jurisdiction.  The format depends on design of the tax system data but should use hyphen
     * delimiters and contain enough information to uniquely identify a jurisdiction.  So taxes applicable in larger jurisdictions
     * can be found using as few geocodes as needed they should start with the larger geographic identifier and work down to smaller
     * geographies (e.g. US, US-MI, US-MI-Oakland, US-MI-Oakland-Troy, etc.).  The geocode is combined with other information provided
     * for in the tax matrix table to fully qualify taxing jurisdictions and which items and scenarios are taxable in those jurisdictions.
     * @servertype String
     * @type {string}
     */
    Geocode: string = "";

    /**
     * @name InOutCity
     * @description 
     * A location attribute that further qualifies a jurisdiction with respect to certain taxes applied completely inside or completely
     * outside incorporated city boundaries.  This indicates for this location if it is:
     * I = Inside
     * O = Outside
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    InOutCity: string = "U";

    /**
     * @name InOutLocal
     * @description 
     * The same as In/Out City except it applies to local taxing districts such as school or water districts.
     * I = Inside
     * O = Outside
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    InOutLocal: string = "U";

    /**
     * @name TaxConfigurationId
     * @description 
     * If this tax geocode lookup should trigger a specific tax configuration to be used this is the id of that tax configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaxConfigurationId: number = null;

    /**
     * @name DataStatus
     * @description 
     * Data status used for data import control purposes.  Possible values include:
     * A = Active
     * I = Inactive
     * When doing data import existing rows have status set to inactive.  Then as data is added or updated the status is set to
     * active.  After mass updates if inactive records are no longer desired they can be deleted.  Note that inactive data is still
     * used by the tax engine until deleted.
     * @servertype String
     * @type {string}
     */
    DataStatus: string = "A";

    /**
     * @name Properties
     * @description 
     * Properties.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TaxGeocodeEditViewModel
 * @description
 * The Tax Geocode table contains a geographic code list with unique corresponding place names.
 */
export class TaxGeocodeEditViewModel {

    /**
     * @name TaxGeocodeId
     * @description 
     * Tax Geocode Id uniquely identifies this tax geocode record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaxGeocodeId: number = null;

    /**
     * @name Geocode
     * @description 
     * Geographic code representing a tax jurisdiction.  The format depends on design of the tax system data but should use hyphen
     * delimiters and contain enough information to uniquely identify a jurisdiction.  So taxes applicable in larger jurisdictions
     * can be found using as few geocodes as needed they should start with the larger geographic identifier and work down to smaller
     * geographies (e.g. US, US-MI, US-MI-Oakland, US-MI-Oakland-Troy, etc.).  The geocode is combined with other information provided
     * for in the tax matrix table to fully qualify taxing jurisdictions and which items and scenarios are taxable in those jurisdictions.
     * @servertype String
     * @type {string}
     */
    Geocode: string = "";

    /**
     * @name Description
     * @description 
     * A description for this geocode.  Note that some geocodes are not very user friendly (e.g. may contain numeric identifiers
     * for different jurisdictions, etc.) so a description is sometimes helpful.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Country
     * @description 
     * The country (if any) for this geocode.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name State
     * @description 
     * The state (if any) for this geocode.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name County
     * @description 
     * The county (if any) for this geocode.
     * @servertype String
     * @type {string}
     */
    County: string = "";

    /**
     * @name CountyCode
     * @description 
     * A county code like the Federal Information Processing Standard (FIPS) code that uniquely identifies a county in the USA.
     * @servertype String
     * @type {string}
     */
    CountyCode: string = "";

    /**
     * @name City
     * @description 
     * The city (if any) for this geocode.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name Locality
     * @description 
     * The locality (if any) for this geocode.  This may be a school district, etc. that is part of the geocode.
     * @servertype String
     * @type {string}
     */
    Locality: string = "";

    /**
     * @name Other
     * @description 
     * The other tax location (if any) for this geocode.
     * @servertype String
     * @type {string}
     */
    Other: string = "";

    /**
     * @name TaxConfigurationId
     * @description 
     * If this tax geocode should trigger a specific tax configuration to be used this is the id of that tax configuration.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaxConfigurationId: number = null;

    /**
     * @name DataStatus
     * @description 
     * Data status used for data import control purposes.  Possible values include:
     * A = Active
     * I = Inactive
     * When doing data import existing rows have status set to inactive.  Then as data is added or updated the status is set to
     * active.  After mass updates if inactive records are no longer desired they can be deleted.  Note that inactive data is still
     * used by the tax engine until deleted.
     * @servertype String
     * @type {string}
     */
    DataStatus: string = "A";

    /**
     * @name Properties
     * @description 
     * Properties.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TaxJurisdictionEditViewModel
 * @description
 * The Tax Jurisdiction table contains a geographic code list of taxable jurisdictions for a given object (e.g. billing account,
 * corporate headquarters, etc.).
 */
export class TaxJurisdictionEditViewModel {

    /**
     * @name TaxJurisdictionId
     * @description 
     * Tax Jurisdiction Id uniquely identifies this tax jurisdiction record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaxJurisdictionId: number = null;

    /**
     * @name Geocode
     * @description 
     * Geographic code representing a tax jurisdiction.  The format depends on design of the tax system data but should use hyphen
     * delimiters and contain enough information to uniquely identify a jurisdiction.  So taxes applicable in larger jurisdictions
     * can be found using as few geocodes as needed they should start with the larger geographic identifier and work down to smaller
     * geographies (e.g. US, US-MI, US-MI-Oakland, US-MI-Oakland-Troy, etc.).  The geocode is combined with other information provided
     * for in the tax matrix table to fully qualify taxing jurisdictions and which items and scenarios are taxable in those jurisdictions.
     * @servertype String
     * @type {string}
     */
    Geocode: string = "";

    /**
     * @name InOutCity
     * @description 
     * A location attribute that further qualifies a jurisdiction with respect to certain taxes applied completely inside or completely
     * outside incorporated city boundaries.  This indicates for this location if it is:
     * I = Inside
     * O = Outside
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    InOutCity: string = "U";

    /**
     * @name InOutLocal
     * @description 
     * The same as In/Out City except it applies to local taxing districts such as school or water districts.
     * I = Inside
     * O = Outside
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    InOutLocal: string = "U";

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) for this tax jurisdiction.  The most common types are: 
     * BillingAccount - geocodes that are valid for the billing account
     * VendorContact - geocodes that are valid for the vendor
     * LocationContact - geocodes that are valid for the company location
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this tax jurisdiction.  For example, if the owner resource
     * type is BillingAccount this value would be the BillingAccountId; LocationContact this value would be the ContactId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name Properties
     * @description 
     * Properties.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Tax Calculation
 */
export class TaxCalculationViewModel {

    /**
     * @name ResultCode
     * @servertype Int16 (nullable)
     * @type {number}
     */
    ResultCode: number = 0;

    /**
     * @name ResultCodeType
     * @servertype String
     * @type {string}
     */
    ResultCodeType: string = "";

    /**
     * @name ResultCodeDescription
     * @servertype String
     * @type {string}
     */
    ResultCodeDescription: string = "";

    /**
     * @name TaxScenarioId
     * @servertype String
     * @type {string}
     */
    TaxScenarioId: string = "";

    /**
     * @name TaxAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxAmount: number = 0;

    /**
     * @name Context
     * @servertype System.Object
     * @type {any}
     */
    Context: any = {};

    /**
     * @name Result
     * @servertype System.Object
     * @type {any}
     */
    Result: any = {};

}



/**
 * @name TaxMatrixEditViewModel
 * @description
 * The TaxMatrix table contains information used to identify possible taxes based on inputs like: Geocode, InOutCity flag, InOutLocal
 * flag, Tax Item Code, Customer Tax Type, Service Provider or Vendor Tax Type, and optional information known about a taxable
 * event (e.g. Value001, Value002, etc.) along with the taxes that are applicable.
 */
export class TaxMatrixEditViewModel {

    /**
     * @name TaxMatrixId
     * @description 
     * Tax Matrix Id uniquely identifies this tax matrix record.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaxMatrixId: number = null;

    /**
     * @name Description
     * @description 
     * A description for the tax matrix entry.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Geocode
     * @description 
     * Geographic code representing a tax jurisdiction.  The format depends on design of the tax system data but should use hyphen
     * delimiters and contain enough information to uniquely identify a jurisdiction.  So taxes applicable in larger jurisdictions
     * can be found using as few geocodes as needed they should start with the larger geographic identifier and work down to smaller
     * geographies (e.g. US, US-MI, US-MI-Oakland, US-MI-Oakland-Troy, etc.).  The geocode is combined with other information provided
     * for in the tax matrix table to fully qualify taxing jurisdictions and which items and scenarios are taxable in those jurisdictions.
     * @servertype String
     * @type {string}
     */
    Geocode: string = "";

    /**
     * @name InOutCity
     * @description 
     * A location attribute that further qualifies a jurisdiction with respect to certain taxes applied completely inside, completely
     * outside, or inside and outside incorporated city boundaries.  This field indicates where such taxes apply, in areas having
     * a distinction, using the codes listed here:
     * I = Inside only
     * O = Outside only
     * B = Both inside and outside
     * @servertype String
     * @type {string}
     */
    InOutCity: string = "B";

    /**
     * @name InOutLocal
     * @description 
     * The same as In/Out City except it applies to local taxing districts such as school or water districts.
     * I = Inside only
     * O = Outside only
     * B = Both inside and outside
     * @servertype String
     * @type {string}
     */
    InOutLocal: string = "B";

    /**
     * @name TaxItemCode
     * @description 
     * An item code defining an item for tax purposes taken from system pick list.
     * @servertype String
     * @type {string}
     */
    TaxItemCode: string = "";

    /**
     * @name TaxCustomerType
     * @description 
     * A tax customer type taken from system pick list when a tax applies to a specific customer type.  If not specified then the
     * tax applies to all customer types.
     * @servertype String
     * @type {string}
     */
    TaxCustomerType: string = "";

    /**
     * @name TaxServiceProviderType
     * @description 
     * A tax service provider type taken from system pick list when a tax applies to a specific service provider type.  If not specified
     * then the tax applies to all service provider types.
     * @servertype String
     * @type {string}
     */
    TaxServiceProviderType: string = "";

    /**
     * @name TaxVendorType
     * @description 
     * A tax vendor type taken from system pick list when a tax applies to a specific vendor type.  If not specified then the tax
     * applies to all vendor types.
     * @servertype String
     * @type {string}
     */
    TaxVendorType: string = "";

    /**
     * @name IsoCurrencyCode
     * @description 
     * ISO Currency Code.  If taxable amount ranges, minimums, maximums, or flat fees are not used this can be left empty; otherwise,
     * it needs to be set to the currency used for those amounts.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name TaxAuthorityContactId
     * @description 
     * Tax Authority Contact Id of the tax authority the tax matrix data belongs to (if specified).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaxAuthorityContactId: number = null;

    /**
     * @name TaxAuthorityContactName
     * @servertype String
     * @type {string}
     */
    TaxAuthorityContactName: string = "";

    /**
     * @name TaxAuthorityExternalContactId
     * @description 
     * Tax Authority External Contact Id of the tax authority the tax matrix data belongs to (if specified).  Use of an external
     * contact id may be preferred when shared tax data sources are used and would have a different contact id depending on database.
     * @servertype String
     * @type {string}
     */
    TaxAuthorityExternalContactId: string = null;

    /**
     * @name Taxable
     * @description 
     * True indicates the combination of inputs is taxable.  Being able to flag tax inputs as not taxable is sometimes preferred
     * over not having them in the table to avoid missing tax rule alarms, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    Taxable: boolean = false;

    /**
     * @name TaxOnTax
     * @description 
     * True indicates the tax matrix entry represents a tax on tax.  All of the same is combination of inputs is used to find the
     * taxes but the amount to be taxed is previously calculated taxes instead of the typical input amount.
     * @servertype Boolean
     * @type {boolean}
     */
    TaxOnTax: boolean = false;

    /**
     * @name TaxCategory
     * @description 
     * A tax category taken from system pick list.  This can be used to facilitate tax reporting and/or remittance.
     * @servertype String
     * @type {string}
     */
    TaxCategory: string = "";

    /**
     * @name TaxType
     * @description 
     * A tax type taken from system pick list.  This can be used to facilitate tax reporting and/or remittance.
     * @servertype String
     * @type {string}
     */
    TaxType: string = "";

    /**
     * @name TaxAuthorityType
     * @description 
     * A tax authority type taken from system pick list.  This can be used to facilitate tax reporting and/or remittance.
     * @servertype String
     * @type {string}
     */
    TaxAuthorityType: string = "";

    /**
     * @name TaxLocation
     * @description 
     * A tax location taken from system pick list.  This can be used to facilitate tax reporting and/or remittance by some taxing
     * authorities who process returns for multiple jurisdictions.
     * @servertype String
     * @type {string}
     */
    TaxLocation: string = "";

    /**
     * @name MaximumTaxMethod
     * @description 
     * The method to use when determining how revenue and tax threshold are accumulated.  Possible values are:
     * I = Item Amount
     * T = Transaction Amount
     * @servertype String
     * @type {string}
     */
    MaximumTaxMethod: string = "I";

    /**
     * @name DisplayRequirement
     * @description 
     * The requirement indicating whether a tax must, may, or cannot be passed on to consumers as a tax line item.  Taxes authorities
     * set forth regulations stipulating whether sellers or purchasers pay a tax.  Sales tax, for example, is imposed on purchasers
     * with the seller acting as collector for the tax authority.  The seller is therefore required to collect sales tax via a separate
     * line item on the bill using approved wording.  Excise tax on the other hand, is ordinarily imposed on sellers, but sellers
     * usually have the option of including it in their rates or passing it on to consumers as a separate line item.  Some taxes
     * are prohibited from being passed on to consumers.  Possible values are:
     * O = Optional
     * R = Required
     * P = Prohibited
     * @servertype String
     * @type {string}
     */
    DisplayRequirement: string = "O";

    /**
     * @name DisplayMethod
     * @description 
     * The method defining how the tax authority imposing a tax requires their tax to appear on invoices.  Possible values are:
     * T = Tax Line Item
     * S = Surcharge Line Item
     * E = Embedded Surcharge instead of a separate line item
     * I = Included in item cost
     * @servertype String
     * @type {string}
     */
    DisplayMethod: string = "T";

    /**
     * @name BasisType
     * @description 
     * The basis on which the tax is calculated.  Sales taxes are usually based on sale price (i.e. transaction amount).  Seller-imposed
     * taxes may be based on a variety of elements, such as gross receipts, gross operating revenue, or receipts subject to income
     * tax.  Seller imposed taxes cannot always be directly passed on to consumers.  Possible values are:
     * P = Price (price is typical for consumer taxes)
     * R = Receipts (gross receipts is typical for seller taxes)
     * @servertype String
     * @type {string}
     */
    BasisType: string = "P";

    /**
     * @name TaxRateSelection
     * @description 
     * Flag that indicates how the tax rate is selected in relation to the taxable amount.  Possible values include: 
     * S = Step (The tax amount and rate at each step applies to the taxable amount specified in that step - i.e. TaxStep1FlatAmount
     * and TaxStep1Rate applies for TaxStep1BeginningAmount up to TaxStep1EndingAmount).
     * T = Total (The tax amount and rate at the step matching the taxable amount applies to the full amount - i.e. TaxStep1FlatAmount
     * and TaxStep1Rate applies when the taxable amount is between TaxStep1BeginningAmount and TaxStep1EndingAmount).
     * @servertype String
     * @type {string}
     */
    TaxRateSelection: string = "S";

    /**
     * @name TaxStep1BeginningAmount
     * @description 
     * The beginning amount of a taxable amount range that is used for selecting tax flat amount and rate 1.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep1BeginningAmount: number = 0;

    /**
     * @name TaxStep1EndingAmount
     * @description 
     * The ending amount of a taxable amount range that is used for selecting tax flat amount and rate 1.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep1EndingAmount: number = 0;

    /**
     * @name TaxStep1FlatAmount
     * @description 
     * A flat amount to apply as tax at this tax step.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep1FlatAmount: number = 0;

    /**
     * @name TaxStep1Rate
     * @description 
     * A tax rate to apply as tax at this rate step.  This is stored as a decimal value (i.e. 5% is 0.05 not 5).
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep1Rate: number = 0;

    /**
     * @name TaxStep2BeginningAmount
     * @description 
     * The beginning amount of a taxable amount range that is used for selecting tax flat amount and rate 2.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep2BeginningAmount: number = 0;

    /**
     * @name TaxStep2EndingAmount
     * @description 
     * The ending amount of a taxable amount range that is used for selecting tax flat amount and rate 2.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep2EndingAmount: number = 0;

    /**
     * @name TaxStep2FlatAmount
     * @description 
     * A flat amount to apply as tax at this tax step.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep2FlatAmount: number = 0;

    /**
     * @name TaxStep2Rate
     * @description 
     * A tax rate to apply as tax at this rate step.  This is stored as a decimal value (i.e. 5% is 0.05 not 5).
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep2Rate: number = 0;

    /**
     * @name TaxStep3BeginningAmount
     * @description 
     * The beginning amount of a taxable amount range that is used for selecting tax flat amount and rate 3.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep3BeginningAmount: number = 0;

    /**
     * @name TaxStep3EndingAmount
     * @description 
     * The ending amount of a taxable amount range that is used for selecting tax flat amount and rate 3.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep3EndingAmount: number = 0;

    /**
     * @name TaxStep3FlatAmount
     * @description 
     * A flat amount to apply as tax at this tax step.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep3FlatAmount: number = 0;

    /**
     * @name TaxStep3Rate
     * @description 
     * A tax rate to apply as tax at this rate step.  This is stored as a decimal value (i.e. 5% is 0.05 not 5).
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep3Rate: number = 0;

    /**
     * @name TaxStep4BeginningAmount
     * @description 
     * The beginning amount of a taxable amount range that is used for selecting tax flat amount and rate 4.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep4BeginningAmount: number = 0;

    /**
     * @name TaxStep4EndingAmount
     * @description 
     * The ending amount of a taxable amount range that is used for selecting tax flat amount and rate 4.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep4EndingAmount: number = 0;

    /**
     * @name TaxStep4FlatAmount
     * @description 
     * A flat amount to apply as tax at this tax step.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep4FlatAmount: number = 0;

    /**
     * @name TaxStep4Rate
     * @description 
     * A tax rate to apply as tax at this rate step.  This is stored as a decimal value (i.e. 5% is 0.05 not 5).
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxStep4Rate: number = 0;

    /**
     * @name TaxFlatAmount
     * @description 
     * A flat amount to apply outside of tax steps.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxFlatAmount: number = 0;

    /**
     * @name TaxRate
     * @description 
     * A tax rate to apply outside of tax steps.  This is stored as a decimal value (i.e. 5% is 0.05 not 5).
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxRate: number = 0;

    /**
     * @name TaxMinimum
     * @description 
     * A minimum to apply to the calculated tax.  If calculated tax is less than this minimum then the minimum will be used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxMinimum: number = 0;

    /**
     * @name TaxMaximum
     * @description 
     * A maximum to apply to the calculated tax.  If calculated tax is more than this maximum then the maximum will be used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxMaximum: number = 0;

    /**
     * @name TaxPerUnitRateSelection
     * @description 
     * Flag that indicates how the unit tax is selected in relation to the quantity.  Possible values include: 
     * S = Step (The tax per unit amount at each step applies to the unit quantity specified in that step - i.e. TaxPerUnitStep1FlatAmount
     * applied per unit for TaxPerUnitStep1BeginningQuantity up to TaxPerUnitStep1EndingQuantity).
     * T = Total (The tax per unit amount at the step matching the unit quantity applies to the full quantity - i.e. TaxPerUnitStep1FlatAmount
     * applied per unit when the unit quantity is between TaxPerUnitStep1BeginningQuantity and TaxPerUnitStep1EndingQuantity).
     * @servertype String
     * @type {string}
     */
    TaxPerUnitRateSelection: string = "S";

    /**
     * @name TaxPerUnitStep1BeginningQuantity
     * @description 
     * The beginning quantity of a unit quantity range that is used for selecting tax per unit flat amount 1.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitStep1BeginningQuantity: number = 0;

    /**
     * @name TaxPerUnitStep1EndingQuantity
     * @description 
     * The ending quantity of a unit quantity range that is used for selecting tax per unit flat amount 1.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitStep1EndingQuantity: number = 0;

    /**
     * @name TaxPerUnitStep1FlatAmount
     * @description 
     * A flat amount per unit to apply as tax at this Tax Per Unit Step.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitStep1FlatAmount: number = 0;

    /**
     * @name TaxPerUnitStep2BeginningQuantity
     * @description 
     * The beginning quantity of a unit quantity range that is used for selecting tax per unit flat amount 2.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitStep2BeginningQuantity: number = 0;

    /**
     * @name TaxPerUnitStep2EndingQuantity
     * @description 
     * The ending quantity of a unit quantity range that is used for selecting tax per unit flat amount 2.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitStep2EndingQuantity: number = 0;

    /**
     * @name TaxPerUnitStep2FlatAmount
     * @description 
     * A flat amount per unit to apply as tax at this Tax Per Unit Step.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitStep2FlatAmount: number = 0;

    /**
     * @name TaxPerUnitStep3BeginningQuantity
     * @description 
     * The beginning quantity of a unit quantity range that is used for selecting tax per unit flat amount 3.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitStep3BeginningQuantity: number = 0;

    /**
     * @name TaxPerUnitStep3EndingQuantity
     * @description 
     * The ending quantity of a unit quantity range that is used for selecting tax per unit flat amount 3.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitStep3EndingQuantity: number = 0;

    /**
     * @name TaxPerUnitStep3FlatAmount
     * @description 
     * A flat amount per unit to apply as tax at this Tax Per Unit Step.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitStep3FlatAmount: number = 0;

    /**
     * @name TaxPerUnitStep4BeginningQuantity
     * @description 
     * The beginning quantity of a unit quantity range that is used for selecting tax per unit flat amount 4.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitStep4BeginningQuantity: number = 0;

    /**
     * @name TaxPerUnitStep4EndingQuantity
     * @description 
     * The ending quantity of a unit quantity range that is used for selecting tax per unit flat amount 4.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitStep4EndingQuantity: number = 0;

    /**
     * @name TaxPerUnitStep4FlatAmount
     * @description 
     * A flat amount per unit to apply as tax at this Tax Per Unit Step.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitStep4FlatAmount: number = 0;

    /**
     * @name TaxPerUnitMinimum
     * @description 
     * A minimum to apply to the calculated unit tax.  If calculated unit tax is less than this minimum then the minimum will be
     * used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitMinimum: number = 0;

    /**
     * @name TaxPerUnitMaximum
     * @description 
     * A maximum to apply to the calculated unit tax.  If calculated unit tax is more than this maximum then the maximum will be
     * used instead.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxPerUnitMaximum: number = 0;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this tax matrix entry can be used.  This can be
     * used to exclude taxes that have not reached their effective start date.  Accurate tax reporting and analysis requires that
     * changes in tax rates be handled by creating new tax matrix entries and using the valid date range to dictate when tax rates
     * are valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this tax matrix entry can be used.  This can be used
     * to exclude taxes that expire on a certain date.  Accurate tax reporting and analysis requires that changes in tax rates be
     * handled by creating new tax matrix entries and using the valid date range to dictate when tax rates are valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name DataStatus
     * @description 
     * Data status used for data import control purposes.  Possible values include:
     * A = Active
     * I = Inactive
     * When doing data import existing rows have status set to inactive.  Then as data is added or updated the status is set to
     * active.  After mass updates if inactive records are no longer desired they can be soft-deleted or expired via the valid end
     * date time.  Note that inactive data is still used by the tax engine until deleted or expired via the valid end date time.
     *  Accurate tax reporting and analysis requires that changes in tax rates be handled by creating new tax matrix entries and
     * using the valid date range to dictate when tax rates are valid.  If deleting of tax matrix data is desired it should only
     * be soft-deleted so the TaxMatrixId can be used to access details for reporting.
     * @servertype String
     * @type {string}
     */
    DataStatus: string = "A";

    /**
     * @name Properties
     * @description 
     * Properties.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TaxTransactionDetailEditViewModel
 * @description
 * The Tax Transaction Detail table contains tax details for a billing or payment transaction.
 */
export class TaxTransactionDetailEditViewModel {

    /**
     * @name TaxTransactionDetailId
     * @description 
     * The Tax Transaction Detail Id uniquely identifies this tax transaction detail record.
     * @servertype String
     * @type {string}
     */
    TaxTransactionDetailId: string = null;

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type for this tax transaction detail item.  Possible values include: 
     * B = Billing Transaction
     * P = Payment Transaction
     * C = Context submitted to taxation engine
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this tax transaction detail item.  For example, if the owner
     * resource type is B (billing transaction) this value is the BillingTransactionId.  For owner resource type P (payment transaction)
     * this is the payment transaction id and the transaction date time matches the month of the payment transaction monthly table.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceDetailId
     * @description 
     * If the tax transaction detail matches a detail object belonging to the owner resource id this is that detail id.  For example,
     * for billing transactions this is the Billing Transaction Detail Id of the invoice or adjustment transaction line item for
     * this tax.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceDetailId: number = null;

    /**
     * @name TransactionDateTime
     * @description 
     * The date and time of the transaction the tax details belong to.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TransactionDateTime: Date = new Date();

    /**
     * @name TaxConfigurationId
     * @description 
     * The id of the tax configuration this tax transaction detail row utilized.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaxConfigurationId: number = null;

    /**
     * @name TaxMatrixId
     * @description 
     * The id of the tax matrix entity this tax transaction detail row utilized.  This can be used to determine details like tax
     * rates tax selection criteria, tax authority and reporting parameters, etc.  Those details are not duplicated here which is
     * why tax matrix entities should be expired rather than being deleted when they are no longer applicable.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaxMatrixId: number = null;

    /**
     * @name Quantity
     * @description 
     * The quantity submitted to the taxation engine for possible use with per unit taxes.
     * @servertype Double (nullable)
     * @type {number}
     */
    Quantity: number = 0;

    /**
     * @name IsoCurrencyCode
     * @description 
     * The ISO Currency Code for the taxable and tax amounts.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name TaxableAmount
     * @description 
     * The taxable amount submitted to the taxation engine.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxableAmount: number = 0;

    /**
     * @name TaxAmount
     * @description 
     * The amount of calculated tax.
     * @servertype Double (nullable)
     * @type {number}
     */
    TaxAmount: number = 0;

    /**
     * @name TaxOnTax
     * @description 
     * True indicates the tax amount is a tax-on-tax value.
     * @servertype Boolean
     * @type {boolean}
     */
    TaxOnTax: boolean = false;

    /**
     * @name TaxCalculationMethod
     * @description 
     * The calculation method to use for calculating the tax.  This is noted here to assist with how the tax matrix display method
     * is handled.  Possible values include: 
     * C = Calculated (Calculated from Item Price)
     * I = Included (Included in Item Price)
     * @servertype String
     * @type {string}
     */
    TaxCalculationMethod: string = "C";

    /**
     * @name TaxInformation
     * @description 
     * A csv of tax information for the transaction.  This could include things like: 
     * 1. Taxes that are precalculated and then submitted with a transaction may include a tax scenario id that can be used to recreate
     * the same tax details for the transaction.
     * 2. A Taxable true/false indicator.
     * 3. The id of the tax configuration that was used.
     * 4. A list of tax matrix identifiers (pipe delimited) from the taxation engine.
     * 5. A list of result codes (pipe delimited) from the taxation engine.
     * 6. A list of simple tax rates (pipe delimited) used for calculating taxes (more complex scenarios found only in tax matrix
     * and/or logs).
     * 7. A list of tax display requirement flags (pipe delimited) used for displaying taxes.
     * For example: TaxScenarioId:1q8-u72-7sl-y9ze,Taxable:true,Config:1,Matrices:2240|39134|10101|1599242,ResultCodes:101|282|283,Rates:0.07,DisplayRequirements:O|O|P|O
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    TaxInformation: {[key: string]:  string} = {};

    /**
     * @name TaxItemId
     * @description 
     * Item Id used for this tax.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TaxItemId: number = null;

    /**
     * @name ResultCode
     * @description 
     * Result code for this tax transaction detail.  Success codes are in the 100-199 range; Warning codes are in the 200-299 range;
     * Error codes are in the 300-399 range.  Possible values include:
     * 99 = InProcess
     * 101 = TaxCalculatedSuccessfully
     * 111 = TaxMatrixIndicatedNotTaxable
     * 121 = NothingToTaxZeroQuantityAndAmount
     * 122 = NothingToTaxTransactionFailed
     * 123 = NothingToTaxTransactionNotTaxableType
     * 124 = NothingToTaxTransactionNotTaxablePerRules
     * 198 = OtherSuccess
     * 199 = MultipleSuccessResultsLogged
     * 201 = FallbackGeocodeWasUsed
     * 202 = WildcardGeocodeLookupWasUsed
     * 211 = TaxMatrixNotFound
     * 221 = SuggestedInformationNotFound
     * 281 = DefaultTaxCalculationMethodWasUsed
     * 282 = DefaultTaxItemCodeWasUsed
     * 283 = DefaultIsoCurrencyCodeWasUsed
     * 284 = DefaultTaxCustomerTypeWasUsed
     * 285 = DefaultTaxServiceProvidereTypeWasUsed
     * 286 = DefaultTaxVendorTypeWasUsed
     * 298 = OtherWarning
     * 299 = MultipleWarningsLogged
     * 301 = NoGeocodeFound
     * 311 = NoSuitableTaxMatrixFound
     * 321 = RequiredInformationNotFound
     * 391 = SettingsError
     * 396 = ScenarioNotSupported
     * 397 = UnexpectedExceptionOccurred
     * 398 = OtherError
     * 399 = MultipleErrorsLogged
     * -1 = Pending
     * @servertype Int16 (nullable)
     * @type {number}
     */
    ResultCode: number = -1;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Voucher Check Response
 * @description
 * The voucher table contains vouchers.
 */
export class VoucherCheckResponseModel {

    /**
     * @name ResponseCode
     * @servertype IB.Web.API.Models.v5.VoucherCheckResponseCode Enum
     * @type {VoucherCheckResponseCode}
     */
    ResponseCode: VoucherCheckResponseCode = null;

    /**
     * @name ResponseMessage
     * @servertype String
     * @type {string}
     */
    ResponseMessage: string = "";

    /**
     * @name VoucherCode
     * @description 
     * The voucher code for this voucher.
     * @servertype String
     * @type {string}
     */
    VoucherCode: string = "";

    /**
     * @name Status
     * @description 
     * Status of the voucher.  Possible values include: 
     * H = Hold (Voucher is on hold and not in distribution channel yet.)
     * P = Pending (Voucher is pending.  The lot is activated and voucher ready for use but not used yet.)
     * R = Reserved (Voucher is reserved.  Functionally same as pending except flagged as reserved or distributed to someone.)
     * A = Active (Voucher is active.  It has been used once and is still available for future use.)
     * I = Inactive (Voucher is inactive.  It has been active but not used in last X days.)
     * E = Expired (Voucher is expired by reaching the expiration date from first use.)
     * L = Lapsed (Voucher shelf-life has lapsed by reaching the lapsed date from lot activation.)
     * S = Suspended (Voucher is manually suspended.)
     * U = Used (Voucher has been used and has no remaining uses left.)
     * @servertype IB.Data.VoucherStatus Enum
     * @type {m.VoucherStatus}
     */
    Status: m.VoucherStatus = m.VoucherStatus.Pending;

    /**
     * @name StatusMessage
     * @servertype String
     * @type {string}
     */
    StatusMessage: string = "";

    /**
     * @name MarketingMessage
     * @servertype String
     * @type {string}
     */
    MarketingMessage: string = "";

    /**
     * @name VoucherAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    VoucherAmount: number = 0;

    /**
     * @name VoucherPercent
     * @servertype Double (nullable)
     * @type {number}
     */
    VoucherPercent: number = 0;

    /**
     * @name VoucherMinimum
     * @servertype Double (nullable)
     * @type {number}
     */
    VoucherMinimum: number = 0;

    /**
     * @name VoucherMaximum
     * @servertype Double (nullable)
     * @type {number}
     */
    VoucherMaximum: number = 0;

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Amount
     * @servertype Double (nullable)
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name DiscountAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    DiscountAmount: number = 0;

    /**
     * @name NetAmount
     * @servertype Double (nullable)
     * @type {number}
     */
    NetAmount: number = 0;

}



/**
 * @name VoucherCheckResponseCode Enum
 * @readonly
 * @enum {number}
 */
export enum VoucherCheckResponseCode {

    /**
     * Valid
     */
    Valid = 0,

    /**
     * Voucher Not Found
     */
    VoucherNotFound = 710,

    /**
     * Voucher On Hold
     */
    VoucherOnHold = 711,

    /**
     * Voucher Expired
     */
    VoucherExpired = 712,

    /**
     * Voucher Lapsed
     */
    VoucherLapsed = 713,

    /**
     * Voucher Suspended
     */
    VoucherSuspended = 714,

    /**
     * Vourcher Used
     */
    VourcherUsed = 715,

    /**
     * Voucher Status Unknown
     */
    VoucherStatusUnknown = 720,

    /**
     * Voucher Not Valid Iso Currency Code
     */
    VoucherNotValidIsoCurrencyCode = 721,

    /**
     * Voucher Not Valid Visibility
     */
    VoucherNotValidVisibility = 722,

    /**
     * Voucher Lot Not Pending Activation
     */
    VoucherLotNotPendingActivation = 730,

    /**
     * Unknown
     */
    Unknown = -1

}



