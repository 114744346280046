// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';


/**
 * @name Partition Route
 */
export class PartitionRouteEditViewModel {

    /**
     * @name PartitionRouteId
     * @servertype Int64
     * @type {number}
     */
    PartitionRouteId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RouteUrl
     * @servertype String
     * @type {string}
     */
    RouteUrl: string = "";

    /**
     * @name RedirectToUrl
     * @servertype String
     * @type {string}
     */
    RedirectToUrl: string = "";

    /**
     * @name AssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name AssetTitle
     * @servertype String
     * @type {string}
     */
    AssetTitle: string = "";

    /**
     * @name FormId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FormId: number = 0;

    /**
     * @name FormDescription
     * @servertype String
     * @type {string}
     */
    FormDescription: string = "";

    /**
     * @name FormContext
     * @servertype String
     * @type {string}
     */
    FormContext: string = "";

    /**
     * @name AllowAnonymousAccess
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousAccess: boolean = false;

    /**
     * @name HideStandardSiteElements
     * @servertype Boolean
     * @type {boolean}
     */
    HideStandardSiteElements: boolean = false;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ApplicationInformationModel
 */
export class ApplicationInformationModel {

    /**
     * @name PartitionId
     * @servertype Int64
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name PartitionDescription
     * @servertype String
     * @type {string}
     */
    PartitionDescription: string = "";

    /**
     * @name Partition
     * @servertype IB.Web.API.Models.v5.PartitionEditViewModel
     * @type {PartitionEditViewModel}
     */
    Partition: PartitionEditViewModel = null;

    /**
     * @name Domain
     * @servertype IB.Web.API.Models.v5.PartitionDomainEditViewModel
     * @type {PartitionDomainEditViewModel}
     */
    Domain: PartitionDomainEditViewModel = null;

    /**
     * @name Branding
     * @servertype IB.Data.Model.PartitionBrandModel
     * @type {m.PartitionBrandModel}
     */
    Branding: m.PartitionBrandModel = null;

    /**
     * @name Tags
     * @servertype Dictionary<string, List<string>>
     * @type {{[key: string]:  string[]}}
     */
    Tags: {[key: string]:  string[]} = {};

    /**
     * @name AllowAnonymousAccess
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousAccess: boolean = false;

    /**
     * @name AnonymousApiKey
     * @servertype String
     * @type {string}
     */
    AnonymousApiKey: string = "";

    /**
     * @name Theme
     * @servertype String
     * @type {string}
     */
    Theme: string = "";

    /**
     * @name LogoUrl
     * @servertype String
     * @type {string}
     */
    LogoUrl: string = "";

    /**
     * @name DomainName
     * @servertype String
     * @type {string}
     */
    DomainName: string = "";

    /**
     * @name SslEnabled
     * @servertype Boolean
     * @type {boolean}
     */
    SslEnabled: boolean = false;

    /**
     * @name SslRequired
     * @servertype Boolean
     * @type {boolean}
     */
    SslRequired: boolean = false;

    /**
     * @name Services
     * @servertype ApplicationInformationServiceModel array
     * @type {ApplicationInformationServiceModel[]}
     */
    Services: ApplicationInformationServiceModel[] = [];

    /**
     * @name Modules
     * @servertype string array
     * @type {string[]}
     */
    Modules: string[] = [];

    /**
     * @name Routes
     * @servertype ApplicationInformationRouteModel array
     * @type {ApplicationInformationRouteModel[]}
     */
    Routes: ApplicationInformationRouteModel[] = [];

    /**
     * @name Logos
     * @servertype ApplicationInformationLogoModel array
     * @type {ApplicationInformationLogoModel[]}
     */
    Logos: ApplicationInformationLogoModel[] = [];

    /**
     * @name Settings
     * @servertype IB.Web.API.Models.v5.AppSettingsEditViewModel
     * @type {AppSettingsEditViewModel}
     */
    Settings: AppSettingsEditViewModel = null;

}



/**
 * @name PartitionEditViewModel
 * @description
 * The Partition table contains information about table partitions typically done in a multi-company scenario where multiple
 * databases is not desired but data needs to be partitioned or in a multi-tenant environment where each partition represents
 * a client.  Note that this represents a logical and not necessarily a physical partition.
 */
export class PartitionEditViewModel {

    /**
     * @name PartitionId
     * @description 
     * Unique value that represents this partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name Description
     * @description 
     * The description of this partition.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DisplayName
     * @servertype String
     * @type {string}
     */
    DisplayName: string = "";

    /**
     * @name Brand
     * @description 
     * The brand for this partition.
     * @servertype String
     * @type {string}
     */
    Brand: string = "";

    /**
     * @name Branding
     * @servertype IB.Data.Model.PartitionBrandModel
     * @type {m.PartitionBrandModel}
     */
    Branding: m.PartitionBrandModel = null;

    /**
     * @name PrivateBranded
     * @servertype Boolean
     * @type {boolean}
     */
    PrivateBranded: boolean = false;

    /**
     * @name CustomerName
     * @description 
     * The customer name for this partition.
     * @servertype String
     * @type {string}
     */
    CustomerName: string = "";

    /**
     * @name ContactName
     * @description 
     * The contact name for this partition.
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactPhone
     * @description 
     * The contact phone for this partition.
     * @servertype String
     * @type {string}
     */
    ContactPhone: string = "";

    /**
     * @name ContactEmail
     * @description 
     * The contact email for this partition.
     * @servertype String
     * @type {string}
     */
    ContactEmail: string = "";

    /**
     * @name ContactAlternateEmail
     * @description 
     * The contact alternate email for this partition.
     * @servertype String
     * @type {string}
     */
    ContactAlternateEmail: string = "";

    /**
     * @name ContactId
     * @description 
     * A Contact Id associated with this partition with contact information for the partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactInformationName
     * @servertype String
     * @type {string}
     */
    ContactInformationName: string = "";

    /**
     * @name ProductionPartitionId
     * @description 
     * The partition id used as production partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProductionPartitionId: number = null;

    /**
     * @name SandboxPartitionId
     * @description 
     * The partition id used as sandbox partition for testing.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SandboxPartitionId: number = null;

    /**
     * @name PrimaryShardId
     * @description 
     * The shard id for the primary table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PrimaryShardId: number = 0;

    /**
     * @name ReportShardId
     * @description 
     * The shard id for the report table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ReportShardId: number = 0;

    /**
     * @name CacheShardId
     * @description 
     * The cache id for the cache table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CacheShardId: number = 0;

    /**
     * @name LogShardId
     * @description 
     * The shard id for the log table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LogShardId: number = 0;

    /**
     * @name ActivityShardId
     * @description 
     * The shard id for the activity table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ActivityShardId: number = 0;

    /**
     * @name ArchiveActivityShardId
     * @description 
     * The shard id for the archive activity table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ArchiveActivityShardId: number = 0;

    /**
     * @name ArchiveShardId
     * @description 
     * The shard id for the archive table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ArchiveShardId: number = 0;

    /**
     * @name Status
     * @description 
     * Status of this partition.  Possible values are: 
     * P = Pending
     * H = Hold
     * A = Active
     * D = Disabled
     * C = Closed
     * E = Expired
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

    /**
     * @name StatusMessage
     * @description 
     * A status message for this partition.
     * @servertype String
     * @type {string}
     */
    StatusMessage: string = "";

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this partition is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this partition is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name ValidDateRangeBeforeMessage
     * @description 
     * A message to display before the valid date range.
     * @servertype String
     * @type {string}
     */
    ValidDateRangeBeforeMessage: string = "";

    /**
     * @name ValidDateRangeAfterMessage
     * @description 
     * A message to display after the valid date range.
     * @servertype String
     * @type {string}
     */
    ValidDateRangeAfterMessage: string = "";

    /**
     * @name Features
     * @description 
     * A json object of features.  Object includes these properties:
     * InAppUpgradeAdvertising - When true the partition allows in app upgrade advertising.
     * @servertype IB.Web.API.Models.v5.PartitionFeaturesEditViewModel
     * @type {PartitionFeaturesEditViewModel}
     */
    Features: PartitionFeaturesEditViewModel = null;

    /**
     * @name Billing
     * @description 
     * A json object of billing properties.  Object includes these properties:
     * ContactId - A billing contact id for this partition.
     * BillingAccountId - A billing account id for this partition.
     * @servertype IB.Web.API.Models.v5.PartitionBillingEditViewModel
     * @type {PartitionBillingEditViewModel}
     */
    Billing: PartitionBillingEditViewModel = null;

    /**
     * @name AllowAnonymousAccess
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousAccess: boolean = false;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Services
     * @servertype PartitionServiceEditViewModel array
     * @type {PartitionServiceEditViewModel[]}
     */
    Services: PartitionServiceEditViewModel[] = [];

    /**
     * @name Domains
     * @servertype PartitionDomainEditViewModel array
     * @type {PartitionDomainEditViewModel[]}
     */
    Domains: PartitionDomainEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PartitionFeaturesEditViewModel
 */
export class PartitionFeaturesEditViewModel {

    /**
     * @name InAppUpgradeAdvertising
     * @servertype Boolean
     * @type {boolean}
     */
    InAppUpgradeAdvertising: boolean = false;

}



/**
 * @name PartitionBillingEditViewModel
 */
export class PartitionBillingEditViewModel {

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name BillingAccountId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = 0;

}



/**
 * @name PartitionServiceEditViewModel
 * @description
 * The Partition Service table contains a list services available for a partition.
 */
export class PartitionServiceEditViewModel {

    /**
     * @name PartitionServiceId
     * @description 
     * Unique value that represents this partition service.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionServiceId: number = null;

    /**
     * @name PartitionId
     * @description 
     * The partition this row belongs to.
     * @servertype Int64
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name Description
     * @description 
     * The description of this partition service.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ServiceName
     * @description 
     * The service name.
     * @servertype String
     * @type {string}
     */
    ServiceName: string = "";

    /**
     * @name ServiceClass
     * @description 
     * The service class.
     * @servertype String
     * @type {string}
     */
    ServiceClass: string = "";

    /**
     * @name ServiceType
     * @description 
     * The service type.
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name ServiceModules
     * @description 
     * The modules included with service.
     * @servertype string array
     * @type {string[]}
     */
    ServiceModules: string[] = [];

    /**
     * @name Status
     * @description 
     * Status of this partition.  Possible values are: 
     * P = Pending
     * H = Hold
     * A = Active
     * D = Disabled
     * C = Closed
     * E = Expired
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

    /**
     * @name StatusMessage
     * @description 
     * A status message for this partition.
     * @servertype String
     * @type {string}
     */
    StatusMessage: string = "";

    /**
     * @name StatusInformation
     * @description 
     * Information about the current status of the service.
     * @servertype String
     * @type {string}
     */
    StatusInformation: string = "";

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this service is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this service is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name ValidDateRangeBeforeMessage
     * @description 
     * A message to display before the valid date range.
     * @servertype String
     * @type {string}
     */
    ValidDateRangeBeforeMessage: string = "";

    /**
     * @name ValidDateRangeAfterMessage
     * @description 
     * A message to display after the valid date range.
     * @servertype String
     * @type {string}
     */
    ValidDateRangeAfterMessage: string = "";

    /**
     * @name Features
     * @description 
     * A json object of features.  Object includes these properties:
     * FreeTrial - When true the partition is for a free trial.
     * FreeTrialDays - The number of days for the free trial.
     * @servertype IB.Web.API.Models.v5.PartitionServiceFeaturesEditViewModel
     * @type {PartitionServiceFeaturesEditViewModel}
     */
    Features: PartitionServiceFeaturesEditViewModel = null;

    /**
     * @name Billing
     * @description 
     * A json object of billing properties.  Object includes these properties:
     * PackageCode - A billing package code for this partition service.
     * PackageType - A billing package type for this partition service.
     * PackageId - A billing package id for this partition service.
     * PackageOccurrenceId - A billing package occurrence id for this partition service.
     * @servertype IB.Web.API.Models.v5.PartitionServiceBillingEditViewModel
     * @type {PartitionServiceBillingEditViewModel}
     */
    Billing: PartitionServiceBillingEditViewModel = null;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PartitionServiceFeaturesEditViewModel
 */
export class PartitionServiceFeaturesEditViewModel {

    /**
     * @name FreeTrial
     * @servertype Boolean
     * @type {boolean}
     */
    FreeTrial: boolean = false;

    /**
     * @name FreeTrialDays
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FreeTrialDays: number = 0;

}



/**
 * @name PartitionServiceBillingEditViewModel
 */
export class PartitionServiceBillingEditViewModel {

    /**
     * @name PackageCode
     * @servertype String
     * @type {string}
     */
    PackageCode: string = "";

    /**
     * @name PackageType
     * @servertype String
     * @type {string}
     */
    PackageType: string = "";

    /**
     * @name PackageId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name PackageOccurrenceId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = 0;

}



/**
 * @name PartitionDomainEditViewModel
 * @description
 * The Partition Domain table contains a list domain names available for a partition.
 */
export class PartitionDomainEditViewModel {

    /**
     * @name PartitionDomainId
     * @description 
     * Unique value that represents this partition domain.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionDomainId: number = null;

    /**
     * @name PartitionId
     * @description 
     * The partition this row belongs to.
     * @servertype Int64
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name PartitionServiceId
     * @description 
     * If this domain is tied to one service this is the partition service id for that service.  Otherwise, all services defined
     * for the partition are considered to be in scope.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionServiceId: number = null;

    /**
     * @name Description
     * @description 
     * The description of this partition domain.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DisplayName
     * @servertype String
     * @type {string}
     */
    DisplayName: string = "";

    /**
     * @name DomainName
     * @description 
     * The domain name.  For example: abc.myservice.com.
     * @servertype String
     * @type {string}
     */
    DomainName: string = "";

    /**
     * @name IpAddress
     * @description 
     * The ip address the specified domain name resolves to.
     * @servertype String
     * @type {string}
     */
    IpAddress: string = "";

    /**
     * @name CustomDomainName
     * @description 
     * When true the domain name is a custom domain provided by the client who is then responsible for DNS and SSL certificates.
     * @servertype Boolean
     * @type {boolean}
     */
    CustomDomainName: boolean = false;

    /**
     * @name SslEnabled
     * @description 
     * When true SSL is enabled for the domain.
     * @servertype Boolean
     * @type {boolean}
     */
    SslEnabled: boolean = false;

    /**
     * @name SslRequired
     * @description 
     * When true SSL is required for the domain.
     * @servertype Boolean
     * @type {boolean}
     */
    SslRequired: boolean = false;

    /**
     * @name SslExpirationDateTime
     * @description 
     * The date and time when the SSL certificate expires.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SslExpirationDateTime: Date = null;

    /**
     * @name Enabled
     * @description 
     * When true the domain is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this domain is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this domain is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name SiteTheme
     * @description 
     * Site theme for the domain.
     * @servertype String
     * @type {string}
     */
    SiteTheme: string = "";

    /**
     * @name AllowAnonymousAccess
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousAccess: boolean = false;

    /**
     * @name LogoAssets
     * @servertype long array
     * @type {number[]}
     */
    LogoAssets: number[] = [];

    /**
     * @name BrandId
     * @servertype IB.Data.Model.BrandId Enum
     * @type {m.BrandId}
     */
    BrandId: m.BrandId = null;

    /**
     * @name Brand
     * @servertype String
     * @type {string}
     */
    Brand: string = "";

    /**
     * @name Branding
     * @servertype IB.Data.Model.PartitionBrandModel
     * @type {m.PartitionBrandModel}
     */
    Branding: m.PartitionBrandModel = null;

    /**
     * @name PrivateBranded
     * @servertype Boolean
     * @type {boolean}
     */
    PrivateBranded: boolean = false;

    /**
     * @name ShowCopyrightMessage
     * @servertype Boolean
     * @type {boolean}
     */
    ShowCopyrightMessage: boolean = false;

    /**
     * @name ShowPoweredByMessage
     * @servertype Boolean
     * @type {boolean}
     */
    ShowPoweredByMessage: boolean = false;

    /**
     * @name AssetFilterTags
     * @servertype string array
     * @type {string[]}
     */
    AssetFilterTags: string[] = [];

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ApplicationInformationServiceModel
 */
export class ApplicationInformationServiceModel {

    /**
     * @name Name
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Class
     * @servertype String
     * @type {string}
     */
    Class: string = "";

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Status
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name Modules
     * @servertype string array
     * @type {string[]}
     */
    Modules: string[] = [];

}



/**
 * @name ApplicationInformationRouteModel
 */
export class ApplicationInformationRouteModel {

    /**
     * @name PartitionRouteId
     * @servertype Int64
     * @type {number}
     */
    PartitionRouteId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RouteUrl
     * @servertype String
     * @type {string}
     */
    RouteUrl: string = "";

    /**
     * @name RedirectToUrl
     * @servertype String
     * @type {string}
     */
    RedirectToUrl: string = "";

    /**
     * @name AssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name FormId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FormId: number = 0;

    /**
     * @name FormContext
     * @servertype String
     * @type {string}
     */
    FormContext: string = "";

    /**
     * @name AllowAnonymousAccess
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousAccess: boolean = false;

    /**
     * @name HideStandardSiteElements
     * @servertype Boolean
     * @type {boolean}
     */
    HideStandardSiteElements: boolean = false;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name ApplicationInformationLogoModel
 */
export class ApplicationInformationLogoModel {

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name FriendlyName
     * @servertype String
     * @type {string}
     */
    FriendlyName: string = "";

    /**
     * @name AssetId
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name Url
     * @servertype String
     * @type {string}
     */
    Url: string = "";

    /**
     * @name FileType
     * @servertype String
     * @type {string}
     */
    FileType: string = "";

    /**
     * @name ContentType
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name Height
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Height: number = 0;

    /**
     * @name Width
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Width: number = 0;

}



/**
 * @name AppSettingsEditViewModel
 */
export class AppSettingsEditViewModel {

    /**
     * @name CompanyLongName
     * @servertype String
     * @type {string}
     */
    CompanyLongName: string = "";

    /**
     * @name CompanyShortName
     * @servertype String
     * @type {string}
     */
    CompanyShortName: string = "";

    /**
     * @name SystemAdminGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SystemAdminGroupId: number = 0;

    /**
     * @name AssetAdminGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetAdminGroupId: number = 0;

    /**
     * @name PortalHomePage
     * @servertype String
     * @type {string}
     */
    PortalHomePage: string = "";

    /**
     * @name PortalHomePageCanBeCustomized
     * @servertype Boolean
     * @type {boolean}
     */
    PortalHomePageCanBeCustomized: boolean = false;

    /**
     * @name LanguageNative
     * @servertype String
     * @type {string}
     */
    LanguageNative: string = "";

    /**
     * @name LanguageDefault
     * @servertype String
     * @type {string}
     */
    LanguageDefault: string = "";

    /**
     * @name LanguagesSupported
     * @servertype string array
     * @type {string[]}
     */
    LanguagesSupported: string[] = [];

    /**
     * @name DefaultCaseTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DefaultCaseTemplateId: number = 0;

    /**
     * @name UseInternalCaseNumber
     * @servertype Boolean
     * @type {boolean}
     */
    UseInternalCaseNumber: boolean = false;

    /**
     * @name UseExternalCaseNumber
     * @servertype Boolean
     * @type {boolean}
     */
    UseExternalCaseNumber: boolean = false;

    /**
     * @name Labels
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Labels: {[key: string]:  string} = {};

    /**
     * @name Menu
     * @servertype MenuItem array
     * @type {m.MenuItem[]}
     */
    Menu: m.MenuItem[] = [];

}



/**
 * @name PickListExcelMetaData
 */
export class PickListExcelMetaData {

    /**
     * @name PickListId
     * @servertype String
     * @type {string}
     */
    PickListId: string = "";

    /**
     * @name WorksheetName
     * @servertype String
     * @type {string}
     */
    WorksheetName: string = "";

    /**
     * @name WorksheetExists
     * @servertype Boolean
     * @type {boolean}
     */
    WorksheetExists: boolean = false;

    /**
     * @name ColumnOffsetPickListValueId
     * @servertype Int32
     * @type {number}
     */
    ColumnOffsetPickListValueId: number = 0;

    /**
     * @name ColumnOffsetDisplayText
     * @servertype Int32
     * @type {number}
     */
    ColumnOffsetDisplayText: number = 0;

    /**
     * @name ColumnOffsetValue
     * @servertype Int32
     * @type {number}
     */
    ColumnOffsetValue: number = 0;

    /**
     * @name ColumnOffsetDisplayOrder
     * @servertype Int32
     * @type {number}
     */
    ColumnOffsetDisplayOrder: number = 0;

    /**
     * @name ColumnOffsetGroupText
     * @servertype Int32
     * @type {number}
     */
    ColumnOffsetGroupText: number = 0;

    /**
     * @name ColumnCount
     * @servertype Int32
     * @type {number}
     */
    ColumnCount: number = 0;

    /**
     * @name PickListHierarchy
     * @servertype string array
     * @type {string[]}
     */
    PickListHierarchy: string[] = [];

    /**
     * @name PickListHeaders
     * @servertype string array
     * @type {string[]}
     */
    PickListHeaders: string[] = [];

}



/**
 * @name PickListImportResult
 */
export class PickListImportResult {

    /**
     * @name OriginalFileName
     * @servertype String
     * @type {string}
     */
    OriginalFileName: string = "";

    /**
     * @name MimeFileName
     * @servertype String
     * @type {string}
     */
    MimeFileName: string = "";

    /**
     * @name FinalFileName
     * @servertype String
     * @type {string}
     */
    FinalFileName: string = "";

    /**
     * @name FinalFileFullPath
     * @servertype String
     * @type {string}
     */
    FinalFileFullPath: string = "";

    /**
     * @name Extension
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name ContentType
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name DryRun
     * @servertype Boolean
     * @type {boolean}
     */
    DryRun: boolean = false;

    /**
     * @name PickLists
     * @servertype PickListImportSingleResult array
     * @type {PickListImportSingleResult[]}
     */
    PickLists: PickListImportSingleResult[] = [];

}



/**
 * @name PickListImportSingleResult
 */
export class PickListImportSingleResult {

    /**
     * @name PickListType
     * @servertype String
     * @type {string}
     */
    PickListType: string = "";

    /**
     * @name PickListId
     * @servertype String
     * @type {string}
     */
    PickListId: string = "";

    /**
     * @name ExcelMetaData
     * @servertype IB.Web.API.Models.v5.PickListExcelMetaData
     * @type {PickListExcelMetaData}
     */
    ExcelMetaData: PickListExcelMetaData = null;

    /**
     * @name PickListCount
     * @servertype Int32
     * @type {number}
     */
    PickListCount: number = 0;

    /**
     * @name PickListHierarchy
     * @servertype string array
     * @type {string[]}
     */
    PickListHierarchy: string[] = [];

    /**
     * @name PickListEntryCountParsed
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountParsed: number = 0;

    /**
     * @name PickListEntryCountAdded
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountAdded: number = 0;

    /**
     * @name PickListEntryCountAlreadyAdded
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountAlreadyAdded: number = 0;

    /**
     * @name PickListEntryCountUpdated
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountUpdated: number = 0;

    /**
     * @name PickListEntryCountUpdatedReferences
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountUpdatedReferences: number = 0;

    /**
     * @name PickListEntryCountDeleted
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountDeleted: number = 0;

    /**
     * @name PickListEntryCountAlreadyDeleted
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountAlreadyDeleted: number = 0;

    /**
     * @name PickListEntryCountNotChanged
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountNotChanged: number = 0;

    /**
     * @name PickListEntryCountWarning
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountWarning: number = 0;

    /**
     * @name PickListEntryCountError
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountError: number = 0;

    /**
     * @name PickListEntriesAdded
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesAdded: number[] = [];

    /**
     * @name PickListEntriesAlreadyAdded
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesAlreadyAdded: number[] = [];

    /**
     * @name PickListEntriesUpdated
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesUpdated: number[] = [];

    /**
     * @name PickListEntriesUpdatedReferences
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesUpdatedReferences: number[] = [];

    /**
     * @name PickListEntriesDeleted
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesDeleted: number[] = [];

    /**
     * @name PickListEntriesAlreadyDeleted
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesAlreadyDeleted: number[] = [];

    /**
     * @name PickListEntriesNotChanged
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesNotChanged: number[] = [];

    /**
     * @name PickListEntriesWarnings
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesWarnings: number[] = [];

    /**
     * @name PickListEntriesErrors
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesErrors: number[] = [];

    /**
     * @name Notices
     * @servertype PickListImportNotice array
     * @type {PickListImportNotice[]}
     */
    Notices: PickListImportNotice[] = [];

    /**
     * @name Warnings
     * @servertype PickListImportNotice array
     * @type {PickListImportNotice[]}
     */
    Warnings: PickListImportNotice[] = [];

    /**
     * @name Errors
     * @servertype PickListImportNotice array
     * @type {PickListImportNotice[]}
     */
    Errors: PickListImportNotice[] = [];

    /**
     * @name Trace
     * @servertype string array
     * @type {string[]}
     */
    Trace: string[] = [];

}



/**
 * @name PickListImportNotice
 */
export class PickListImportNotice {

    /**
     * @name PickListId
     * @servertype String
     * @type {string}
     */
    PickListId: string = "";

    /**
     * @name PickListValueId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PickListValueId: number = 0;

    /**
     * @name OperationType
     * @servertype String
     * @type {string}
     */
    OperationType: string = "";

    /**
     * @name ResultCode
     * @servertype IB.Core.StandardResultCode Enum
     * @type {m.StandardResultCode}
     */
    ResultCode: m.StandardResultCode = null;

    /**
     * @name Message
     * @servertype String
     * @type {string}
     */
    Message: string = "";

    /**
     * @name CurrentValue
     * @servertype String
     * @type {string}
     */
    CurrentValue: string = "";

    /**
     * @name RequestedValue
     * @servertype String
     * @type {string}
     */
    RequestedValue: string = "";

    /**
     * @name Details
     * @servertype String
     * @type {string}
     */
    Details: string = "";

}



/**
 * @name PickListEditViewModel
 * @description
 * The Pick List table contains a list of pick lists used in the system.
 */
export class PickListEditViewModel {

    /**
     * @name PickListSurrogateId
     * @description 
     * A surrogate id for the pick list.  The PickListId value is used as the primary pick list reference.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PickListSurrogateId: number = null;

    /**
     * @name PickListId
     * @description 
     * The id of the pick list.  For example: ItemType, CaseType, etc.
     * @servertype String
     * @type {string}
     */
    PickListId: string = "";

    /**
     * @name PickListCategoryId
     * @description 
     * The id of the pick list category for this pick list.
     * @servertype String
     * @type {string}
     */
    PickListCategoryId: string = null;

    /**
     * @name ParentPickListId
     * @description 
     * If this is part of a cascading pick list this is the id of the pick list which is the parent of this pick list.
     * @servertype String
     * @type {string}
     */
    ParentPickListId: string = null;

    /**
     * @name Description
     * @description 
     * Description for this pick list.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * When true this pick list is enabled and, therefore, visible in the ui.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the pick list in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name PickListSort
     * @description 
     * The sorting for this pick list.  Possible values include: 
     * D = Display Order Explicitly Set
     * T = Text Order (alphabetical)
     * V = Value Order
     * @servertype String
     * @type {string}
     */
    PickListSort: string = "T";

    /**
     * @name HasDisplayText
     * @description 
     * When true this pick list has display text that may differ from the pick list value.
     * @servertype Boolean
     * @type {boolean}
     */
    HasDisplayText: boolean = false;

    /**
     * @name HasGrouping
     * @description 
     * When true this pick list has grouping supported and pick list values can be assigned group text that will control what groups
     * the pick list values appear under.
     * @servertype Boolean
     * @type {boolean}
     */
    HasGrouping: boolean = false;

    /**
     * @name Scope
     * @description 
     * For pick list that have values that are scoped this defines the scoping options for the pick list values.  For example: 
     * OwnerResourceType=CaseTemplate will cause values to have option to be scoped to a specific case template.
     * @servertype String
     * @type {string}
     */
    Scope: string = "";

    /**
     * @name Icon
     * @description 
     * If the list has an icon associated with it this is the icon name.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name SystemPickListId
     * @description 
     * An optional system pick list id for this pick list.  This is used for custom pick lists that need to be known for system
     * purposes.  For example, Report Compiler allows custom pick lists for various template types but if the pick list is used
     * with structural subject matter we need to know which custom pick list maps to 'Structural.ExternalSubject', etc.
     * @servertype String
     * @type {string}
     */
    SystemPickListId: string = "";

    /**
     * @name RequiredModules
     * @description 
     * A list of modules required for showing this pick list.  If empty the pick list is displayed; otherwise, at least one of the
     * list modules is required for the list to be displayed.
     * @servertype string array
     * @type {string[]}
     */
    RequiredModules: string[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PickListCategoryEditViewModel
 * @description
 * The Pick List Category table contains a list of categories for various pick lists used in the system.
 */
export class PickListCategoryEditViewModel {

    /**
     * @name PickListCategorySurrogateId
     * @description 
     * A surrogate id for the pick list category.  The PickListCategoryId value is used as the primary pick list category reference.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PickListCategorySurrogateId: number = null;

    /**
     * @name PickListCategoryId
     * @description 
     * Identifier for this pick list category.
     * @servertype String
     * @type {string}
     */
    PickListCategoryId: string = "";

    /**
     * @name Description
     * @description 
     * Description for this pick list category.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * When true this category is enabled and, therefore, visible in the ui.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the pick list categories in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Icon
     * @description 
     * If the category has an icon associated with it this is the icon name.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name RequiredModules
     * @description 
     * A list of modules required for showing this pick list category.  If empty the category is displayed; otherwise, at least
     * one of the list modules is required for the category to be displayed.
     * @servertype string array
     * @type {string[]}
     */
    RequiredModules: string[] = [];

    /**
     * @name ParentPickListCategoryId
     * @description 
     * The parent pick list category (if any) for this category.
     * @servertype String
     * @type {string}
     */
    ParentPickListCategoryId: string = "";

    /**
     * @name PickLists
     * @servertype PickListEditViewModel array
     * @type {PickListEditViewModel[]}
     */
    PickLists: PickListEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PickListSelectionViewModel
 */
export class PickListSelectionViewModel {

    /**
     * @name PickListId
     * @servertype String
     * @type {string}
     */
    PickListId: string = "";

    /**
     * @name DisplayOrder
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name GroupText
     * @servertype String
     * @type {string}
     */
    GroupText: string = "";

    /**
     * @name DisplayText
     * @servertype String
     * @type {string}
     */
    DisplayText: string = "";

    /**
     * @name Value
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name IconColor
     * @servertype String
     * @type {string}
     */
    IconColor: string = "";

    /**
     * @name TextColor
     * @servertype String
     * @type {string}
     */
    TextColor: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name IsDefaultValue
     * @servertype Boolean
     * @type {boolean}
     */
    IsDefaultValue: boolean = false;

    /**
     * @name IsMutuallyExclusive
     * @servertype Boolean
     * @type {boolean}
     */
    IsMutuallyExclusive: boolean = false;

    /**
     * @name Children
     * @servertype PickListSelectionViewModel array
     * @type {PickListSelectionViewModel[]}
     */
    Children: PickListSelectionViewModel[] = [];

    /**
     * @name PickListValueId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PickListValueId: number = 0;

    /**
     * @name ParentPickListValueId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentPickListValueId: number = 0;

}



/**
 * @name PickListValueEditViewModel
 * @description
 * The Pick List Value table contains a list of values for various pick lists used in the system.
 */
export class PickListValueEditViewModel {

    /**
     * @name PickListValueId
     * @description 
     * The id of the pick list value.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PickListValueId: number = null;

    /**
     * @name PickListId
     * @description 
     * The id of the pick list for this pick list value.  There are a few special pick list value categories not found in the pick
     * list table as follows:
     * 1. For pick lists that are used to populate drop down lists for attributes this value is in the format AttributeConfiguration.AttributeConfigurationId.
     *  For example: AttributeConfiguration.95487.
     * 2. For shared pick lists that are used to populate drop down lists for attributes this value is in the format AttributeConfiguration.Shared.ListName.
     *  For example: AttributeConfiguration.Shared.RoomNumber.
     * @servertype String
     * @type {string}
     */
    PickListId: string = null;

    /**
     * @name ParentPickListId
     * @description 
     * If this is part of a cascading pick list this is the id of the pick list which is the parent of this pick list.
     * @servertype String
     * @type {string}
     */
    ParentPickListId: string = null;

    /**
     * @name ParentPickListValueId
     * @description 
     * If this is part of a cascading pick list this is the id of the parent pick list value for which this pick list value is a
     * valid option.  This is the actual value utilized for matching pick list values.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentPickListValueId: number = null;

    /**
     * @name ParentPickListValue
     * @description 
     * If this is part of a cascading pick list this is the value of the parent pick list value for which this pick list value is
     * a valid option.  This value is used for picking cascading pick list value that are valid based on the parent value.
     * @servertype String
     * @type {string}
     */
    ParentPickListValue: string = null;

    /**
     * @name DisplayOrder
     * @description 
     * The order to display the pick list values in when the pick list is configured to sort by declared sort order.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name GroupText
     * @description 
     * For pick lists that support grouping options this is optional group text.  All pick list values that have the same group
     * text are grouped together and sorting is applied within the groups.  Alternate translations can be found in the translation
     * table.
     * @servertype String
     * @type {string}
     */
    GroupText: string = "";

    /**
     * @name DisplayText
     * @description 
     * The text to display when this pick list value is presented for selection.  Alternate translations can be found in the translation
     * table.
     * @servertype String
     * @type {string}
     */
    DisplayText: string = "";

    /**
     * @name Value
     * @description 
     * Value to be stored when this pick list value is selected.
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Icon
     * @description 
     * If the pick list value has an icon associated with it this is the icon name.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name IconColor
     * @description 
     * The color to use for the pick list value icon (if any).
     * @servertype String
     * @type {string}
     */
    IconColor: string = "";

    /**
     * @name TextColor
     * @description 
     * The color to use for the pick list value text (if any).
     * @servertype String
     * @type {string}
     */
    TextColor: string = "";

    /**
     * @name Properties
     * @description 
     * Properties related to this pick list value (if needed).
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name MutuallyExclusive
     * @description 
     * When true this pick list value can only be selected once when presented multiple times in a group of pick lists.
     * @servertype Boolean
     * @type {boolean}
     */
    MutuallyExclusive: boolean = false;

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type (e.g. table, etc.) for this pick list value allows filtering values to specific scenarios like a
     * specific case template, etc.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this pick list value allows filtering values to specific
     * scenarios like a specific case template, etc.  For example, if the owner resource type is InventoryType this value would
     * be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description 
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name Scope
     * @description 
     * For pick list values that are only valid options in certain scenarios the scope provides the ability to filter for those
     * scenarios to present valid pick list options.
     * @servertype String
     * @type {string}
     */
    Scope: string = "";

    /**
     * @name ContactId
     * @description 
     * For pick list values that are custom for a user this is the contact id of that user.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name Enabled
     * @description 
     * When true this pick list value is enabled and, therefore, visible in the ui.  If disabled for a specific contact id it will
     * suppress any appearance of pick list values that have the same value for this contact.  For example, if there is a pick list
     * value of TUESDAY with a null contact id (i.e. visible for everyone) and contact id 4431 would like to hide that from his
     * list a new entry would be created with contact id 4431, value TUESDAY with enabled false.  That would hide TUESDAY for contact
     * id 4431 but leave it visible for others.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Children
     * @servertype PickListValueEditViewModel array
     * @type {PickListValueEditViewModel[]}
     */
    Children: PickListValueEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Script
 */
export class ScriptViewModel {

    /**
     * @name Name
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Version
     * @servertype Int32
     * @type {number}
     */
    Version: number = 0;

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Code
     * @servertype ScriptSourceViewModel array
     * @type {ScriptSourceViewModel[]}
     */
    Code: ScriptSourceViewModel[] = [];

    /**
     * @name FullyQualifiedTypeName
     * @servertype String
     * @type {string}
     */
    FullyQualifiedTypeName: string = "";

    /**
     * @name Interface
     * @servertype String
     * @type {string}
     */
    Interface: string = "";

    /**
     * @name InstanceTypedAs
     * @servertype String
     * @type {string}
     */
    InstanceTypedAs: string = "";

    /**
     * @name EntryPoint
     * @servertype String
     * @type {string}
     */
    EntryPoint: string = "";

    /**
     * @name EntryPointParameters
     * @servertype System.Object
     * @type {any}
     */
    EntryPointParameters: any = {};

    /**
     * @name PartialSourceCodeWrappers
     * @servertype ScriptSourceViewModel array
     * @type {ScriptSourceViewModel[]}
     */
    PartialSourceCodeWrappers: ScriptSourceViewModel[] = [];

    /**
     * @name Snippets
     * @servertype ScriptSourceViewModel array
     * @type {ScriptSourceViewModel[]}
     */
    Snippets: ScriptSourceViewModel[] = [];

    /**
     * @name Language
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name SupportedLanguages
     * @servertype string array
     * @type {string[]}
     */
    SupportedLanguages: string[] = [];

    /**
     * @name AllowMultipleLanguages
     * @servertype Boolean
     * @type {boolean}
     */
    AllowMultipleLanguages: boolean = false;

    /**
     * @name ReferencedAssemblyDefaultFolder
     * @servertype String
     * @type {string}
     */
    ReferencedAssemblyDefaultFolder: string = "";

    /**
     * @name ReferencedAssemblies
     * @servertype string array
     * @type {string[]}
     */
    ReferencedAssemblies: string[] = [];

    /**
     * @name CompiledCode
     * @servertype ScriptSourceViewModel array
     * @type {ScriptSourceViewModel[]}
     */
    CompiledCode: ScriptSourceViewModel[] = [];

    /**
     * @name UseCache
     * @servertype Boolean
     * @type {boolean}
     */
    UseCache: boolean = false;

    /**
     * @name CompilerOptions
     * @servertype System.Object
     * @type {any}
     */
    CompilerOptions: any = {};

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name ApprovalPending
     * @servertype Boolean
     * @type {boolean}
     */
    ApprovalPending: boolean = false;

    /**
     * @name ApprovedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ApprovedDateTime: Date = null;

    /**
     * @name ApprovedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApprovedByContactId: number = null;

    /**
     * @name ApprovedByContactName
     * @servertype String
     * @type {string}
     */
    ApprovedByContactName: string = "";

    /**
     * @name ApprovedScriptFingerprint
     * @servertype String
     * @type {string}
     */
    ApprovedScriptFingerprint: string = "";

    /**
     * @name ApprovalComments
     * @servertype String
     * @type {string}
     */
    ApprovalComments: string = "";

}



/**
 * @name Script Source Code
 */
export class ScriptSourceViewModel {

    /**
     * @name Group
     * @servertype String
     * @type {string}
     */
    Group: string = "";

    /**
     * @name Order
     * @servertype Int32
     * @type {number}
     */
    Order: number = 0;

    /**
     * @name Name
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name SourceCode
     * @servertype String
     * @type {string}
     */
    SourceCode: string = "";

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Partial
     * @servertype Boolean
     * @type {boolean}
     */
    Partial: boolean = false;

    /**
     * @name Language
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name Usings
     * @servertype string array
     * @type {string[]}
     */
    Usings: string[] = [];

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name ScriptLanguage Enum
 * @readonly
 * @enum {number}
 */
export enum ScriptLanguage {

    /**
     * C#
     */
    CSharp = 0,

    /**
     * Visual Basic
     */
    VisualBasic = 10,

    /**
     * C++
     */
    CPlusPlus = 20,

    /**
     * JavaScript (on server)
     */
    JavaScriptOnServer = 30,

    /**
     * JavaScript
     */
    JavaScript = 1100,

    /**
     * TypeScript
     */
    TypeScript = 1101,

    /**
     * Html
     */
    Html = 1200,

    /**
     * CSS
     */
    Css = 1300,

    /**
     * Inherited from parent object language
     */
    Inherited = 2147483647

}



/**
 * @name ScriptLanguageServerCommon Enum
 * @readonly
 * @enum {number}
 */
export enum ScriptLanguageServerCommon {

    /**
     * C#
     */
    CSharp = 0,

    /**
     * Inherited from parent object language
     */
    Inherited = 2147483647

}



/**
 * @name ScriptLanguageClientCommon Enum
 * @readonly
 * @enum {number}
 */
export enum ScriptLanguageClientCommon {

    /**
     * JavaScript
     */
    JavaScript = 1100,

    /**
     * Html
     */
    Html = 1200,

    /**
     * CSS
     */
    Css = 1300,

    /**
     * Inherited from parent object language
     */
    Inherited = 2147483647

}



/**
 * @name TranslationAddMissingViewModel
 */
export class TranslationAddMissingViewModel {

    /**
     * @name NativeText
     * @servertype String
     * @type {string}
     */
    NativeText: string = "";

    /**
     * @name DesiredLanguage
     * @servertype String
     * @type {string}
     */
    DesiredLanguage: string = "";

    /**
     * @name Scope
     * @servertype String
     * @type {string}
     */
    Scope: string = "";

}



/**
 * @name TranslationResponseViewModel
 */
export class TranslationResponseViewModel {

    /**
     * @name TranslatedText
     * @servertype String
     * @type {string}
     */
    TranslatedText: string = "";

    /**
     * @name Language
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name Information
     * @servertype Dictionary<string, object>
     * @type {{[key: string]:  any}}
     */
    Information: {[key: string]:  any} = {};

}



/**
 * @name TranslationRequestViewModel
 */
export class TranslationRequestViewModel {

    /**
     * @name NativeText
     * @servertype String
     * @type {string}
     */
    NativeText: string = "";

    /**
     * @name DesiredLanguage
     * @servertype String
     * @type {string}
     */
    DesiredLanguage: string = "";

    /**
     * @name Scope
     * @servertype String
     * @type {string}
     */
    Scope: string = "";

    /**
     * @name Parameters
     * @servertype System.Object
     * @type {any}
     */
    Parameters: any = {};

    /**
     * @name AddIfMissing
     * @servertype Boolean
     * @type {boolean}
     */
    AddIfMissing: boolean = false;

    /**
     * @name IgnoreCache
     * @servertype Boolean
     * @type {boolean}
     */
    IgnoreCache: boolean = false;

}



/**
 * @name TranslationEditViewModel
 * @description
 * The translation table contains a list of translation for things like pick list values, extended attributes, etc.
 */
export class TranslationEditViewModel {

    /**
     * @name TranslationId
     * @description 
     * The id of the translation item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TranslationId: number = null;

    /**
     * @name NativeLanguage
     * @description 
     * Language of the native text.
     * @servertype String
     * @type {string}
     */
    NativeLanguage: string = "";

    /**
     * @name TranslatedLanguage
     * @description 
     * Language identifier that represents the language used for this translation.
     * @servertype String
     * @type {string}
     */
    TranslatedLanguage: string = "";

    /**
     * @name NativeText
     * @description 
     * Native text to be translated.  This is the look up value and can also be a key that is unique within the scope to identify
     * the text to be translated.
     * @servertype String
     * @type {string}
     */
    NativeText: string = "";

    /**
     * @name FullNativeText
     * @description 
     * Full native text to be translated.  Since native text is a lookup key it is limited to 500 characters but for text longer
     * than that the full text is found here.
     * @servertype String
     * @type {string}
     */
    FullNativeText: string = "";

    /**
     * @name TranslatedText
     * @description 
     * Translated text for the native text in the specified language.
     * @servertype String
     * @type {string}
     */
    TranslatedText: string = "";

    /**
     * @name TranslatedDateTime
     * @description 
     * The date and time when the translation was done.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TranslatedDateTime: Date = null;

    /**
     * @name TranslatedByContactId
     * @description 
     * The contact id that represents who performed the last translation.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TranslatedByContactId: number = null;

    /**
     * @name TranslatedByContactName
     * @servertype String
     * @type {string}
     */
    TranslatedByContactName: string = "";

    /**
     * @name ReviewedDateTime
     * @description 
     * The date and time when the translation was last reviewed.  The status will reflect the outcome of the review.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReviewedDateTime: Date = null;

    /**
     * @name ReviewedByContactId
     * @description 
     * The contact id that represents who last reviewed this translation.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReviewedByContactId: number = null;

    /**
     * @name ReviewedByContactName
     * @servertype String
     * @type {string}
     */
    ReviewedByContactName: string = "";

    /**
     * @name ReviewComments
     * @description 
     * Comments entered about the review.  This can be used to explain why something was flagged for review or to add notes about
     * the review.
     * @servertype String
     * @type {string}
     */
    ReviewComments: string = "";

    /**
     * @name Status
     * @description 
     * The current status of the translation.  Possible values include: 
     * P = Pending Translation
     * A = Automatic Translation
     * M = Manual Translation
     * N = Not Approved
     * R = Reviewed
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name Scope
     * @description 
     * A list of scopes for this translation.  When empty the same translation is used anywhere needed.  When there is a scope provided
     * the translation is limited to that scope.  Possible values include: UI = User Interface (This is for standard UI elements
     * shared across all partitions.)
     * UI-Custom = Custom User Interface
     * Setting = Setting Value
     * Message = Message for Results, Errors, etc.
     * ReportTemplate = Report Template
     * ReportTemplate-[ReportTemplateId] = Report Template with specified id
     * PickList = Pick List
     * PickList-[PickListId] = Pick List with specified id
     * Asset = Asset
     * Asset-[AssetId] = Asset with specified id (internal or external)
     * Form = Form
     * Form-[FormId] = Form with specified id (internal or external)
     * [Custom] = Custom Scope
     * @servertype string array
     * @type {string[]}
     */
    Scope: string[] = [];

    /**
     * @name IncludeInExport
     * @description 
     * When true this translation is included in language file exports.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeInExport: boolean = true;

    /**
     * @name LastUsedDateTime
     * @description 
     * Date and time when the translation was last used.  This can be used to identify abandoned translations provided appropriate
     * care is taken to remember that translations that have been exported to external files may be actively used without this value
     * being updated.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUsedDateTime: Date = new Date();

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PartitionListViewModel
 * @description
 * The Partition table contains information about table partitions typically done in a multi-company scenario where multiple
 * databases is not desired but data needs to be partitioned or in a multi-tenant environment where each partition represents
 * a client.  Note that this represents a logical and not necessarily a physical partition.
 */
export class PartitionListViewModel {

    /**
     * @name PartitionId
     * @description 
     * Unique value that represents this partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name Tag
     * @description 
     * A short random string combined with the partition id to create a unique reference that includes the partition id so the object
     * can be found quickly (via id) with a random component (by verifying the tag once found via id).  This may be desired to minimize
     * guessing or war dialing of partition id values especially when one needs to be shared like in partner json tokens.
     * @servertype String
     * @type {string}
     */
    Tag: string = "";

    /**
     * @name Description
     * @description 
     * The description of this partition.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Brand
     * @description 
     * The brand for this partition.
     * @servertype String
     * @type {string}
     */
    Brand: string = "";

    /**
     * @name CustomerName
     * @description 
     * The customer name for this partition.
     * @servertype String
     * @type {string}
     */
    CustomerName: string = "";

    /**
     * @name ContactName
     * @description 
     * The contact name for this partition.
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactPhone
     * @description 
     * The contact phone for this partition.
     * @servertype String
     * @type {string}
     */
    ContactPhone: string = "";

    /**
     * @name ContactEmail
     * @description 
     * The contact email for this partition.
     * @servertype String
     * @type {string}
     */
    ContactEmail: string = "";

    /**
     * @name ContactAlternateEmail
     * @description 
     * The contact alternate email for this partition.
     * @servertype String
     * @type {string}
     */
    ContactAlternateEmail: string = "";

    /**
     * @name ContactId
     * @description 
     * A Contact Id associated with this partition with contact information for the partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactInformationName
     * @servertype String
     * @type {string}
     */
    ContactInformationName: string = "";

    /**
     * @name ProductionPartitionId
     * @description 
     * The partition id used as production partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProductionPartitionId: number = null;

    /**
     * @name SandboxPartitionId
     * @description 
     * The partition id used as sandbox partition for testing.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SandboxPartitionId: number = null;

    /**
     * @name PrimaryShardId
     * @description 
     * The shard id for the primary table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PrimaryShardId: number = 0;

    /**
     * @name ReportShardId
     * @description 
     * The shard id for the report table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ReportShardId: number = 0;

    /**
     * @name CacheShardId
     * @description 
     * The cache id for the cache table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CacheShardId: number = 0;

    /**
     * @name LogShardId
     * @description 
     * The shard id for the log table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LogShardId: number = 0;

    /**
     * @name ActivityShardId
     * @description 
     * The shard id for the activity table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ActivityShardId: number = 0;

    /**
     * @name ArchiveActivityShardId
     * @description 
     * The shard id for the archive activity table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ArchiveActivityShardId: number = 0;

    /**
     * @name ArchiveShardId
     * @description 
     * The shard id for the archive table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ArchiveShardId: number = 0;

    /**
     * @name Status
     * @description 
     * Status of this partition.  Possible values are: 
     * P = Pending
     * H = Hold
     * A = Active
     * D = Disabled
     * C = Closed
     * E = Expired
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

}



/**
 * @name Subscription Config
 */
export class SubscriptionConfigViewModel {

    /**
     * @name SubscriptionConfigId
     * @servertype Int64
     * @type {number}
     */
    SubscriptionConfigId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ExistingOrganizationHideAllElements
     * @servertype Boolean
     * @type {boolean}
     */
    ExistingOrganizationHideAllElements: boolean = false;

    /**
     * @name SignUpAlwaysTrialMode
     * @servertype Boolean
     * @type {boolean}
     */
    SignUpAlwaysTrialMode: boolean = false;

    /**
     * @name DataSourcePartitionId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DataSourcePartitionId: number = 0;

    /**
     * @name AdminRoleId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AdminRoleId: number = 0;

    /**
     * @name AdminGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AdminGroupId: number = 0;

    /**
     * @name NotificationGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = 0;

    /**
     * @name ProcessTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTemplateId: number = 0;

    /**
     * @name ProcessPeerId
     * @servertype String
     * @type {string}
     */
    ProcessPeerId: string = "";

    /**
     * @name CaseTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = 0;

    /**
     * @name WebhookId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WebhookId: number = 0;

    /**
     * @name TriggerAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggerAssetId: number = 0;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name StepNew
     * @servertype IB.Web.API.Models.v5.SubscriptionConfigStepViewModel
     * @type {SubscriptionConfigStepViewModel}
     */
    StepNew: SubscriptionConfigStepViewModel = null;

    /**
     * @name StepSubscription
     * @servertype IB.Web.API.Models.v5.SubscriptionConfigStepViewModel
     * @type {SubscriptionConfigStepViewModel}
     */
    StepSubscription: SubscriptionConfigStepViewModel = null;

    /**
     * @name StepOrganization
     * @servertype IB.Web.API.Models.v5.SubscriptionConfigStepViewModel
     * @type {SubscriptionConfigStepViewModel}
     */
    StepOrganization: SubscriptionConfigStepViewModel = null;

    /**
     * @name StepSecurity
     * @servertype IB.Web.API.Models.v5.SubscriptionConfigStepViewModel
     * @type {SubscriptionConfigStepViewModel}
     */
    StepSecurity: SubscriptionConfigStepViewModel = null;

    /**
     * @name StepFinalize
     * @servertype IB.Web.API.Models.v5.SubscriptionConfigStepViewModel
     * @type {SubscriptionConfigStepViewModel}
     */
    StepFinalize: SubscriptionConfigStepViewModel = null;

    /**
     * @name StepDone
     * @servertype IB.Web.API.Models.v5.SubscriptionConfigStepViewModel
     * @type {SubscriptionConfigStepViewModel}
     */
    StepDone: SubscriptionConfigStepViewModel = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Subscription Config Step
 */
export class SubscriptionConfigStepViewModel {

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name InstructionsTop
     * @servertype String
     * @type {string}
     */
    InstructionsTop: string = "";

    /**
     * @name InstructionsTopStyle
     * @servertype String
     * @type {string}
     */
    InstructionsTopStyle: string = "";

    /**
     * @name InstructionsBottom
     * @servertype String
     * @type {string}
     */
    InstructionsBottom: string = "";

    /**
     * @name InstructionsBottomStyle
     * @servertype String
     * @type {string}
     */
    InstructionsBottomStyle: string = "";

    /**
     * @name PreviousButtonText
     * @servertype String
     * @type {string}
     */
    PreviousButtonText: string = "";

    /**
     * @name PreviousButtonIcon
     * @servertype String
     * @type {string}
     */
    PreviousButtonIcon: string = "";

    /**
     * @name PreviousButtonColor
     * @servertype String
     * @type {string}
     */
    PreviousButtonColor: string = "";

    /**
     * @name NextButtonText
     * @servertype String
     * @type {string}
     */
    NextButtonText: string = "";

    /**
     * @name NextButtonIcon
     * @servertype String
     * @type {string}
     */
    NextButtonIcon: string = "";

    /**
     * @name NextButtonColor
     * @servertype String
     * @type {string}
     */
    NextButtonColor: string = "";

    /**
     * @name CancelButtonText
     * @servertype String
     * @type {string}
     */
    CancelButtonText: string = "";

    /**
     * @name CancelButtonIcon
     * @servertype String
     * @type {string}
     */
    CancelButtonIcon: string = "";

    /**
     * @name CancelButtonColor
     * @servertype String
     * @type {string}
     */
    CancelButtonColor: string = "";

}



/**
 * @name Subscription Sign Up
 */
export class SubscriptionSignUpRequestViewModel {

    /**
     * @name SubscriptionOptionId
     * @servertype Int64
     * @type {number}
     */
    SubscriptionOptionId: number = 0;

    /**
     * @name SubscriptionOptionName
     * @servertype String
     * @type {string}
     */
    SubscriptionOptionName: string = "";

    /**
     * @name SubscriptionOptionPriceId
     * @servertype String
     * @type {string}
     */
    SubscriptionOptionPriceId: string = "";

    /**
     * @name SubscriptionOptionPriceDescription
     * @servertype String
     * @type {string}
     */
    SubscriptionOptionPriceDescription: string = "";

    /**
     * @name Trial
     * @servertype Boolean
     * @type {boolean}
     */
    Trial: boolean = false;

    /**
     * @name CustomerName
     * @servertype String
     * @type {string}
     */
    CustomerName: string = "";

    /**
     * @name AdminFirstName
     * @servertype String
     * @type {string}
     */
    AdminFirstName: string = "";

    /**
     * @name AdminLastName
     * @servertype String
     * @type {string}
     */
    AdminLastName: string = "";

    /**
     * @name AdminPhone
     * @servertype String
     * @type {string}
     */
    AdminPhone: string = "";

    /**
     * @name AdminEmail
     * @servertype String
     * @type {string}
     */
    AdminEmail: string = "";

    /**
     * @name AdminLogin
     * @servertype String
     * @type {string}
     */
    AdminLogin: string = "";

    /**
     * @name AdminPassword
     * @servertype String
     * @type {string}
     */
    AdminPassword: string = "";

}



/**
 * @name Subscription Option
 */
export class SubscriptionOptionViewModel {

    /**
     * @name SubscriptionOptionId
     * @servertype Int64
     * @type {number}
     */
    SubscriptionOptionId: number = 0;

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Group
     * @servertype String
     * @type {string}
     */
    Group: string = "";

    /**
     * @name TargetAudienceDescription
     * @servertype String
     * @type {string}
     */
    TargetAudienceDescription: string = "";

    /**
     * @name Name
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DescriptionTitle
     * @servertype String
     * @type {string}
     */
    DescriptionTitle: string = "";

    /**
     * @name IsFeatured
     * @servertype Boolean
     * @type {boolean}
     */
    IsFeatured: boolean = false;

    /**
     * @name FeaturedDescription
     * @servertype String
     * @type {string}
     */
    FeaturedDescription: string = "";

    /**
     * @name Brand
     * @servertype IB.Data.Model.BrandId Enum
     * @type {m.BrandId}
     */
    Brand: m.BrandId = null;

    /**
     * @name ServiceName
     * @servertype String
     * @type {string}
     */
    ServiceName: string = "";

    /**
     * @name ServiceClass
     * @servertype String
     * @type {string}
     */
    ServiceClass: string = "";

    /**
     * @name ServiceType
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name Modules
     * @servertype string array
     * @type {string[]}
     */
    Modules: string[] = [];

    /**
     * @name PackageType
     * @servertype String
     * @type {string}
     */
    PackageType: string = "";

    /**
     * @name PackageCode
     * @servertype String
     * @type {string}
     */
    PackageCode: string = "";

    /**
     * @name PackageId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name ButtonSignUpLabel
     * @servertype String
     * @type {string}
     */
    ButtonSignUpLabel: string = "";

    /**
     * @name ButtonSignUpIcon
     * @servertype String
     * @type {string}
     */
    ButtonSignUpIcon: string = "";

    /**
     * @name ButtonTrialLabel
     * @servertype String
     * @type {string}
     */
    ButtonTrialLabel: string = "";

    /**
     * @name ButtonTrialIcon
     * @servertype String
     * @type {string}
     */
    ButtonTrialIcon: string = "";

    /**
     * @name TrialDays
     * @servertype Int32
     * @type {number}
     */
    TrialDays: number = 0;

    /**
     * @name DataSourcePartitionId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DataSourcePartitionId: number = 0;

    /**
     * @name AdminRoleId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AdminRoleId: number = 0;

    /**
     * @name AdminGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AdminGroupId: number = 0;

    /**
     * @name NotificationGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = 0;

    /**
     * @name ProcessTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTemplateId: number = 0;

    /**
     * @name ProcessPeerId
     * @servertype String
     * @type {string}
     */
    ProcessPeerId: string = "";

    /**
     * @name CaseTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = 0;

    /**
     * @name WebhookId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WebhookId: number = 0;

    /**
     * @name TriggerAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggerAssetId: number = 0;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Features
     * @servertype SubscriptionOptionFeatureViewModel array
     * @type {SubscriptionOptionFeatureViewModel[]}
     */
    Features: SubscriptionOptionFeatureViewModel[] = [];

    /**
     * @name Prices
     * @servertype SubscriptionOptionPriceViewModel array
     * @type {SubscriptionOptionPriceViewModel[]}
     */
    Prices: SubscriptionOptionPriceViewModel[] = [];

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Subscription Option Feature
 */
export class SubscriptionOptionFeatureViewModel {

    /**
     * @name Name
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DescriptionTitle
     * @servertype String
     * @type {string}
     */
    DescriptionTitle: string = "";

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

}



/**
 * @name Subscription Option Price
 */
export class SubscriptionOptionPriceViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name PriceDescription
     * @servertype String
     * @type {string}
     */
    PriceDescription: string = "";

    /**
     * @name PriceUnitDescription
     * @servertype String
     * @type {string}
     */
    PriceUnitDescription: string = "";

    /**
     * @name IsoCurrencyCode
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Amount
     * @servertype Double
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name IsPerUser
     * @servertype Boolean
     * @type {boolean}
     */
    IsPerUser: boolean = false;

    /**
     * @name TimePeriod
     * @servertype IB.Web.API.Models.v5.SubscriptionOptionPriceTimePeriod Enum
     * @type {SubscriptionOptionPriceTimePeriod}
     */
    TimePeriod: SubscriptionOptionPriceTimePeriod = null;

    /**
     * @name Modules
     * @servertype string array
     * @type {string[]}
     */
    Modules: string[] = [];

    /**
     * @name PackageType
     * @servertype String
     * @type {string}
     */
    PackageType: string = "";

    /**
     * @name PackageCode
     * @servertype String
     * @type {string}
     */
    PackageCode: string = "";

    /**
     * @name PackageId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

}



/**
 * @name SubscriptionOptionPriceTimePeriod Enum
 * @readonly
 * @enum {number}
 */
export enum SubscriptionOptionPriceTimePeriod {

    /**
     * Month
     */
    Month = 0,

    /**
     * Year
     */
    Year = 1

}



