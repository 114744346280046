// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';


/**
 * @name Industry Library Approval
 */
export class ReportCompilerIndustryLibraryApprovalViewModel {

    /**
     * @name VisibilityId
     * @servertype Int64
     * @type {number}
     */
    VisibilityId: number = 0;

    /**
     * @name AssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name Status
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name DeterminedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DeterminedByContactId: number = null;

    /**
     * @name DeterminedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    DeterminedDateTime: Date = null;

    /**
     * @name RequestedByContactId
     * @servertype long array
     * @type {number[]}
     */
    RequestedByContactId: number[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Industry Library Type Information
 */
export class ReportCompilerIndustryLibraryTypeInformationModel {

    /**
     * @name IndustryLibraryTypeInformationId
     * @servertype Int64
     * @type {number}
     */
    IndustryLibraryTypeInformationId: number = 0;

    /**
     * @name IndustryLibraryType
     * @servertype String
     * @type {string}
     */
    IndustryLibraryType: string = "";

    /**
     * @name IndustryLibraryPartitionId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    IndustryLibraryPartitionId: number = 0;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Library Text Reason Suggested
 */
export class ReportCompilerLibraryEntryReasonSuggestedModel {

    /**
     * @name KeyCode
     * @servertype IB.Web.API.Models.v5.ReportCompilerLibraryEntryReasonSuggestedKey Enum
     * @type {ReportCompilerLibraryEntryReasonSuggestedKey}
     */
    KeyCode: ReportCompilerLibraryEntryReasonSuggestedKey = null;

    /**
     * @name KeyText
     * @servertype String
     * @type {string}
     */
    KeyText: string = "";

    /**
     * @name Value
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Wildcard
     * @servertype IB.Web.API.Models.v5.ReportCompilerLibraryEntryReasonSuggestedWildcardType Enum
     * @type {ReportCompilerLibraryEntryReasonSuggestedWildcardType}
     */
    Wildcard: ReportCompilerLibraryEntryReasonSuggestedWildcardType = null;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name ReportCompilerLibraryEntryReasonSuggestedKey Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerLibraryEntryReasonSuggestedKey {

    /**
     * Unknown
     */
    Unknown = 0,

    /**
     * Other
     */
    Other = 1,

    /**
     * Selection
     */
    Selection = 100,

    /**
     * Selection Property 01
     */
    SelectionProperty01 = 101,

    /**
     * Selection Property 02
     */
    SelectionProperty02 = 102,

    /**
     * Selection Property 03
     */
    SelectionProperty03 = 103,

    /**
     * Selection Property 04
     */
    SelectionProperty04 = 104,

    /**
     * Selection Property 05
     */
    SelectionProperty05 = 105,

    /**
     * Selection Property 06
     */
    SelectionProperty06 = 106,

    /**
     * Selection Property 07
     */
    SelectionProperty07 = 107,

    /**
     * Selection Property 08
     */
    SelectionProperty08 = 108,

    /**
     * Selection Property 09
     */
    SelectionProperty09 = 109,

    /**
     * Selection Property 10
     */
    SelectionProperty10 = 110,

    /**
     * Query
     */
    Query = 201,

    /**
     * Category
     */
    Category = 301,

    /**
     * Group
     */
    Group = 401,

    /**
     * Tags
     */
    Tags = 501

}



/**
 * @name ReportCompilerLibraryEntryReasonSuggestedWildcardType Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerLibraryEntryReasonSuggestedWildcardType {

    /**
     * None
     */
    None = 0,

    /**
     * Case Selection Had Wildcards
     */
    CaseSelectionHadWildcards = 10,

    /**
     * Library Selection Had Wildcards
     */
    LibrarySelectionHadWildcards = 20,

    /**
     * Library Selection All Wildcards
     */
    LibrarySelectionAllWildcards = 30

}



/**
 * @name Report Attachment Document
 */
export class ReportCompilerReportAttachmentDocumentModel {

    /**
     * @name ReportAttachmentDocumentId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportAttachmentDocumentId: number = 0;

    /**
     * @name ReportAttachmentConfigurationId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportAttachmentConfigurationId: number = 0;

    /**
     * @name CaseId
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name AssetId
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name CaseAttachment
     * @servertype Boolean
     * @type {boolean}
     */
    CaseAttachment: boolean = false;

    /**
     * @name Selected
     * @servertype Boolean
     * @type {boolean}
     */
    Selected: boolean = false;

    /**
     * @name AutoSelect
     * @servertype Boolean
     * @type {boolean}
     */
    AutoSelect: boolean = false;

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name InternalDisplayOrder
     * @servertype Int32
     * @type {number}
     */
    InternalDisplayOrder: number = 0;

    /**
     * @name ExternalDocumentId
     * @servertype String
     * @type {string}
     */
    ExternalDocumentId: string = "";

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name FriendlyName
     * @servertype String
     * @type {string}
     */
    FriendlyName: string = "";

    /**
     * @name FileType
     * @servertype String
     * @type {string}
     */
    FileType: string = "";

    /**
     * @name AssetType
     * @servertype String
     * @type {string}
     */
    AssetType: string = "";

    /**
     * @name AssetClass
     * @servertype String
     * @type {string}
     */
    AssetClass: string = "";

    /**
     * @name AssetClassInferred
     * @servertype String
     * @type {string}
     */
    AssetClassInferred: string = "";

    /**
     * @name SizeBytes
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SizeBytes: number = 0;

    /**
     * @name SizeOther
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SizeOther: number = 0;

    /**
     * @name SizeInformation
     * @servertype String
     * @type {string}
     */
    SizeInformation: string = "";

    /**
     * @name Width
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Width: number = 0;

    /**
     * @name Height
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Height: number = 0;

    /**
     * @name ContentCategory
     * @servertype String
     * @type {string}
     */
    ContentCategory: string = "";

    /**
     * @name Visibility
     * @servertype String
     * @type {string}
     */
    Visibility: string = "";

    /**
     * @name AuthorLongName
     * @servertype String
     * @type {string}
     */
    AuthorLongName: string = "";

    /**
     * @name AuthorShortName
     * @servertype String
     * @type {string}
     */
    AuthorShortName: string = "";

    /**
     * @name Tags
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name Property01
     * @servertype String
     * @type {string}
     */
    Property01: string = "";

    /**
     * @name Value01
     * @servertype String
     * @type {string}
     */
    Value01: string = "";

    /**
     * @name Property02
     * @servertype String
     * @type {string}
     */
    Property02: string = "";

    /**
     * @name Value02
     * @servertype String
     * @type {string}
     */
    Value02: string = "";

    /**
     * @name Property03
     * @servertype String
     * @type {string}
     */
    Property03: string = "";

    /**
     * @name Value03
     * @servertype String
     * @type {string}
     */
    Value03: string = "";

    /**
     * @name Property04
     * @servertype String
     * @type {string}
     */
    Property04: string = "";

    /**
     * @name Value04
     * @servertype String
     * @type {string}
     */
    Value04: string = "";

    /**
     * @name Property05
     * @servertype String
     * @type {string}
     */
    Property05: string = "";

    /**
     * @name Value05
     * @servertype String
     * @type {string}
     */
    Value05: string = "";

    /**
     * @name Property06
     * @servertype String
     * @type {string}
     */
    Property06: string = "";

    /**
     * @name Value06
     * @servertype String
     * @type {string}
     */
    Value06: string = "";

    /**
     * @name Property07
     * @servertype String
     * @type {string}
     */
    Property07: string = "";

    /**
     * @name Value07
     * @servertype String
     * @type {string}
     */
    Value07: string = "";

    /**
     * @name Property08
     * @servertype String
     * @type {string}
     */
    Property08: string = "";

    /**
     * @name Value08
     * @servertype String
     * @type {string}
     */
    Value08: string = "";

    /**
     * @name Property09
     * @servertype String
     * @type {string}
     */
    Property09: string = "";

    /**
     * @name Value09
     * @servertype String
     * @type {string}
     */
    Value09: string = "";

    /**
     * @name Property10
     * @servertype String
     * @type {string}
     */
    Property10: string = "";

    /**
     * @name Value10
     * @servertype String
     * @type {string}
     */
    Value10: string = "";

    /**
     * @name FavoriteId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FavoriteId: number = 0;

    /**
     * @name FavoriteRank
     * @servertype Int32
     * @type {number}
     */
    FavoriteRank: number = 0;

    /**
     * @name MatchRank
     * @servertype Int32
     * @type {number}
     */
    MatchRank: number = 0;

    /**
     * @name MatchRankReason
     * @servertype String
     * @type {string}
     */
    MatchRankReason: string = "";

    /**
     * @name Score
     * @servertype Int32
     * @type {number}
     */
    Score: number = 0;

    /**
     * @name ScoreCalculation
     * @servertype String
     * @type {string}
     */
    ScoreCalculation: string = "";

    /**
     * @name ScoreCalculationInformation
     * @servertype ReportCompilerLibraryEntryScoreCalculationModel array
     * @type {ReportCompilerLibraryEntryScoreCalculationModel[]}
     */
    ScoreCalculationInformation: ReportCompilerLibraryEntryScoreCalculationModel[] = [];

    /**
     * @name ReasonSuggested
     * @servertype String
     * @type {string}
     */
    ReasonSuggested: string = "";

    /**
     * @name ReasonSuggestedInformation
     * @servertype ReportCompilerLibraryEntryReasonSuggestedModel array
     * @type {ReportCompilerLibraryEntryReasonSuggestedModel[]}
     */
    ReasonSuggestedInformation: ReportCompilerLibraryEntryReasonSuggestedModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Library Entry Score Calculation
 */
export class ReportCompilerLibraryEntryScoreCalculationModel {

    /**
     * @name Score
     * @servertype Int32
     * @type {number}
     */
    Score: number = 0;

    /**
     * @name CategoryCode
     * @servertype IB.Web.API.Models.v5.ReportCompilerLibraryEntryScoreCategory Enum
     * @type {ReportCompilerLibraryEntryScoreCategory}
     */
    CategoryCode: ReportCompilerLibraryEntryScoreCategory = null;

    /**
     * @name CategoryText
     * @servertype String
     * @type {string}
     */
    CategoryText: string = "";

    /**
     * @name TypeCode
     * @servertype IB.Web.API.Models.v5.ReportCompilerLibraryEntryScoreType Enum
     * @type {ReportCompilerLibraryEntryScoreType}
     */
    TypeCode: ReportCompilerLibraryEntryScoreType = null;

    /**
     * @name TypeText
     * @servertype String
     * @type {string}
     */
    TypeText: string = "";

    /**
     * @name ReasonCode
     * @servertype IB.Web.API.Models.v5.ReportCompilerLibraryEntryScoreImpactReason Enum
     * @type {ReportCompilerLibraryEntryScoreImpactReason}
     */
    ReasonCode: ReportCompilerLibraryEntryScoreImpactReason = null;

    /**
     * @name ReasonText
     * @servertype String
     * @type {string}
     */
    ReasonText: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name MaxScore
     * @servertype Int32
     * @type {number}
     */
    MaxScore: number = 0;

    /**
     * @name Weight
     * @servertype Double
     * @type {number}
     */
    Weight: number = 0;

    /**
     * @name ScoreValueReset
     * @servertype Boolean
     * @type {boolean}
     */
    ScoreValueReset: boolean = false;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name ReportCompilerLibraryEntryScoreCategory Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerLibraryEntryScoreCategory {

    /**
     * Unknown
     */
    Unknown = 1,

    /**
     * Library
     */
    Library = 11,

    /**
     * Match
     */
    Match = 21,

    /**
     * Preference
     */
    Preference = 31,

    /**
     * Total
     */
    Total = 999999

}



/**
 * @name ReportCompilerLibraryEntryScoreType Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerLibraryEntryScoreType {

    /**
     * Unknown
     */
    Unknown = 1,

    /**
     * Item
     */
    Item = 11,

    /**
     * Category Subtotal
     */
    CategorySubtotal = 21,

    /**
     * Reset
     */
    Reset = 91,

    /**
     * Total
     */
    Total = 999999

}



/**
 * @name ReportCompilerLibraryEntryScoreImpactReason Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerLibraryEntryScoreImpactReason {

    /**
     * Unknown
     */
    Unknown = 0,

    /**
     * Other
     */
    Other = 1,

    /**
     * Source Library
     */
    SourceLibrary = 20,

    /**
     * Source Library Personal
     */
    SourceLibraryPersonal = 21,

    /**
     * Source Library Organization
     */
    SourceLibraryOrganization = 22,

    /**
     * Source Library Industry
     */
    SourceLibraryIndustry = 23,

    /**
     * Match
     */
    Match = 30,

    /**
     * Match Exact
     */
    MatchExact = 31,

    /**
     * Match Partial
     */
    MatchPartial = 32,

    /**
     * Match Always
     */
    MatchAlways = 33,

    /**
     * Match Search Found In Tags
     */
    MatchSearchFoundInTags = 34,

    /**
     * Match Search Found In Library
     */
    MatchSearchFoundInLibrary = 35,

    /**
     * Preference
     */
    Preference = 40,

    /**
     * Explicit Personal Preference
     */
    ExplicitPersonalPreference = 41,

    /**
     * Inferred Personal Preference
     */
    InferredPersonalPreference = 42,

    /**
     * Inferred Organization Preference
     */
    InferredOrganizationPreference = 43,

    /**
     * Inferred Industry Preference
     */
    InferredIndustryPreference = 44,

    /**
     * Observed Personal Past Use
     */
    ObservedPersonalPastUse = 48,

    /**
     * Library Content Feedback Sentiment
     */
    LibraryContentFeedbackSentiment = 49,

    /**
     * Love
     */
    Love = 1100,

    /**
     * Like
     */
    Like = 1200,

    /**
     * Dislike
     */
    Dislike = 1300,

    /**
     * Block
     */
    Block = 1400

}



/**
 * @name Subject Matter Structural Subject/Element/Damage
 */
export class ReportCompilerSubjectMatterBaseForStructuralSubjectElementDamageModel {

    /**
     * @name SubjectMatterSubjectElementDamageId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterSubjectElementDamageId: number = 0;

    /**
     * @name SubjectMatterSubjectElementDamageLocalId
     * @servertype String
     * @type {string}
     */
    SubjectMatterSubjectElementDamageLocalId: string = "";

    /**
     * @name SubjectMatterAreaLocalId
     * @servertype String
     * @type {string}
     */
    SubjectMatterAreaLocalId: string = "";

    /**
     * @name SubjectMatterStructureLocalId
     * @servertype String
     * @type {string}
     */
    SubjectMatterStructureLocalId: string = "";

    /**
     * @name SubjectMatterPropertyLocalId
     * @servertype String
     * @type {string}
     */
    SubjectMatterPropertyLocalId: string = "";

    /**
     * @name CaseId
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Subject
     * @servertype String
     * @type {string}
     */
    Subject: string = "";

    /**
     * @name Element
     * @servertype String
     * @type {string}
     */
    Element: string = "";

    /**
     * @name Damages
     * @servertype string array
     * @type {string[]}
     */
    Damages: string[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Subject Matter Structural Property
 */
export class ReportCompilerSubjectMatterStructuralPropertyModel {

    /**
     * @name SubjectMatterPropertyId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterPropertyId: number = 0;

    /**
     * @name SubjectMatterPropertyLocalId
     * @servertype String
     * @type {string}
     */
    SubjectMatterPropertyLocalId: string = "";

    /**
     * @name CaseId
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name PropertyType
     * @servertype String
     * @type {string}
     */
    PropertyType: string = "";

    /**
     * @name Structures
     * @servertype ReportCompilerSubjectMatterStructuralStructureModel array
     * @type {ReportCompilerSubjectMatterStructuralStructureModel[]}
     */
    Structures: ReportCompilerSubjectMatterStructuralStructureModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Subject Matter Structural Structure
 */
export class ReportCompilerSubjectMatterStructuralStructureModel {

    /**
     * @name SubjectMatterStructureId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterStructureId: number = 0;

    /**
     * @name SubjectMatterStructureLocalId
     * @servertype String
     * @type {string}
     */
    SubjectMatterStructureLocalId: string = "";

    /**
     * @name SubjectMatterPropertyLocalId
     * @servertype String
     * @type {string}
     */
    SubjectMatterPropertyLocalId: string = "";

    /**
     * @name CaseId
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name TrueFrontDirection
     * @servertype String
     * @type {string}
     */
    TrueFrontDirection: string = "";

    /**
     * @name ReportFrontDirection
     * @servertype String
     * @type {string}
     */
    ReportFrontDirection: string = "";

    /**
     * @name Areas
     * @servertype ReportCompilerSubjectMatterStructuralAreaModel array
     * @type {ReportCompilerSubjectMatterStructuralAreaModel[]}
     */
    Areas: ReportCompilerSubjectMatterStructuralAreaModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Subject Matter Structural Area
 */
export class ReportCompilerSubjectMatterStructuralAreaModel {

    /**
     * @name SubjectMatterAreaId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterAreaId: number = 0;

    /**
     * @name SubjectMatterAreaLocalId
     * @servertype String
     * @type {string}
     */
    SubjectMatterAreaLocalId: string = "";

    /**
     * @name SubjectMatterStructureLocalId
     * @servertype String
     * @type {string}
     */
    SubjectMatterStructureLocalId: string = "";

    /**
     * @name SubjectMatterPropertyLocalId
     * @servertype String
     * @type {string}
     */
    SubjectMatterPropertyLocalId: string = "";

    /**
     * @name CaseId
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Stories
     * @servertype String
     * @type {string}
     */
    Stories: string = "";

    /**
     * @name OverallFraming
     * @servertype String
     * @type {string}
     */
    OverallFraming: string = "";

    /**
     * @name OverallFoundation
     * @servertype String
     * @type {string}
     */
    OverallFoundation: string = "";

    /**
     * @name RoofFraming
     * @servertype string array
     * @type {string[]}
     */
    RoofFraming: string[] = [];

    /**
     * @name WallFraming
     * @servertype string array
     * @type {string[]}
     */
    WallFraming: string[] = [];

    /**
     * @name FloorFraming
     * @servertype string array
     * @type {string[]}
     */
    FloorFraming: string[] = [];

    /**
     * @name Foundation
     * @servertype string array
     * @type {string[]}
     */
    Foundation: string[] = [];

    /**
     * @name Roofing
     * @servertype string array
     * @type {string[]}
     */
    Roofing: string[] = [];

    /**
     * @name ExteriorCladding
     * @servertype string array
     * @type {string[]}
     */
    ExteriorCladding: string[] = [];

    /**
     * @name FloorCovering
     * @servertype string array
     * @type {string[]}
     */
    FloorCovering: string[] = [];

    /**
     * @name InteriorCladding
     * @servertype string array
     * @type {string[]}
     */
    InteriorCladding: string[] = [];

    /**
     * @name ReportedCauses
     * @servertype string array
     * @type {string[]}
     */
    ReportedCauses: string[] = [];

    /**
     * @name FoundCauses
     * @servertype string array
     * @type {string[]}
     */
    FoundCauses: string[] = [];

    /**
     * @name Tags
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name InteriorDamages
     * @servertype ReportCompilerSubjectMatterStructuralInteriorSubjectElementDamageModel array
     * @type {ReportCompilerSubjectMatterStructuralInteriorSubjectElementDamageModel[]}
     */
    InteriorDamages: ReportCompilerSubjectMatterStructuralInteriorSubjectElementDamageModel[] = [];

    /**
     * @name ExteriorDamages
     * @servertype ReportCompilerSubjectMatterStructuralExteriorSubjectElementDamageModel array
     * @type {ReportCompilerSubjectMatterStructuralExteriorSubjectElementDamageModel[]}
     */
    ExteriorDamages: ReportCompilerSubjectMatterStructuralExteriorSubjectElementDamageModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Subject Matter Structural Interior Subject/Element/Damage
 */
export class ReportCompilerSubjectMatterStructuralInteriorSubjectElementDamageModel extends ReportCompilerSubjectMatterBaseForStructuralSubjectElementDamageModel {

}



/**
 * @name Subject Matter Structural Exterior Subject/Element/Damage
 */
export class ReportCompilerSubjectMatterStructuralExteriorSubjectElementDamageModel extends ReportCompilerSubjectMatterBaseForStructuralSubjectElementDamageModel {

}



/**
 * @name Subject Matter Other
 */
export class ReportCompilerSubjectMatterOtherModel {

    /**
     * @name SubjectMatterId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterId: number = 0;

    /**
     * @name CaseId
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name LibrarySelectionRuleValue01
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue01: string = "";

    /**
     * @name LibrarySelectionRuleValue02
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue02: string = "";

    /**
     * @name LibrarySelectionRuleValue03
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue03: string = "";

    /**
     * @name LibrarySelectionRuleValue04
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue04: string = "";

    /**
     * @name LibrarySelectionRuleValue05
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue05: string = "";

    /**
     * @name LibrarySelectionRuleValue06
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue06: string = "";

    /**
     * @name LibrarySelectionRuleValue07
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue07: string = "";

    /**
     * @name LibrarySelectionRuleValue08
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue08: string = "";

    /**
     * @name LibrarySelectionRuleValue09
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue09: string = "";

    /**
     * @name LibrarySelectionRuleValue10
     * @servertype String
     * @type {string}
     */
    LibrarySelectionRuleValue10: string = "";

    /**
     * @name Tags
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name SubjectMatter
     * @servertype System.Object
     * @type {any}
     */
    SubjectMatter: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Report Section Text
 */
export class ReportCompilerReportSectionTextModel {

    /**
     * @name ReportSectionTextId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportSectionTextId: number = 0;

    /**
     * @name ReportSectionConfigurationId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportSectionConfigurationId: number = 0;

    /**
     * @name CaseId
     * @servertype Int64
     * @type {number}
     */
    CaseId: number = 0;

    /**
     * @name AssetId
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name Selected
     * @servertype Boolean
     * @type {boolean}
     */
    Selected: boolean = false;

    /**
     * @name AutoSelect
     * @servertype Boolean
     * @type {boolean}
     */
    AutoSelect: boolean = false;

    /**
     * @name LocalOnlyText
     * @servertype Boolean
     * @type {boolean}
     */
    LocalOnlyText: boolean = false;

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name InternalDisplayOrder
     * @servertype Int32
     * @type {number}
     */
    InternalDisplayOrder: number = 0;

    /**
     * @name ExternalTextId
     * @servertype String
     * @type {string}
     */
    ExternalTextId: string = "";

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name FriendlyName
     * @servertype String
     * @type {string}
     */
    FriendlyName: string = "";

    /**
     * @name FileType
     * @servertype String
     * @type {string}
     */
    FileType: string = "";

    /**
     * @name SizeBytes
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SizeBytes: number = 0;

    /**
     * @name SizeOther
     * @servertype Int32 (nullable)
     * @type {number}
     */
    SizeOther: number = 0;

    /**
     * @name SizeInformation
     * @servertype String
     * @type {string}
     */
    SizeInformation: string = "";

    /**
     * @name ContentCategory
     * @servertype String
     * @type {string}
     */
    ContentCategory: string = "";

    /**
     * @name Visibility
     * @servertype String
     * @type {string}
     */
    Visibility: string = "";

    /**
     * @name AuthorLongName
     * @servertype String
     * @type {string}
     */
    AuthorLongName: string = "";

    /**
     * @name AuthorShortName
     * @servertype String
     * @type {string}
     */
    AuthorShortName: string = "";

    /**
     * @name AuthorContactInformation
     * @servertype String
     * @type {string}
     */
    AuthorContactInformation: string = "";

    /**
     * @name Copyright
     * @servertype String
     * @type {string}
     */
    Copyright: string = "";

    /**
     * @name License
     * @servertype String
     * @type {string}
     */
    License: string = "";

    /**
     * @name LicenseUrl
     * @servertype String
     * @type {string}
     */
    LicenseUrl: string = "";

    /**
     * @name LicenseAnonymous
     * @servertype Boolean
     * @type {boolean}
     */
    LicenseAnonymous: boolean = false;

    /**
     * @name Tags
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name Property01
     * @servertype String
     * @type {string}
     */
    Property01: string = "";

    /**
     * @name Value01
     * @servertype String
     * @type {string}
     */
    Value01: string = "";

    /**
     * @name Property02
     * @servertype String
     * @type {string}
     */
    Property02: string = "";

    /**
     * @name Value02
     * @servertype String
     * @type {string}
     */
    Value02: string = "";

    /**
     * @name Property03
     * @servertype String
     * @type {string}
     */
    Property03: string = "";

    /**
     * @name Value03
     * @servertype String
     * @type {string}
     */
    Value03: string = "";

    /**
     * @name Property04
     * @servertype String
     * @type {string}
     */
    Property04: string = "";

    /**
     * @name Value04
     * @servertype String
     * @type {string}
     */
    Value04: string = "";

    /**
     * @name Property05
     * @servertype String
     * @type {string}
     */
    Property05: string = "";

    /**
     * @name Value05
     * @servertype String
     * @type {string}
     */
    Value05: string = "";

    /**
     * @name Property06
     * @servertype String
     * @type {string}
     */
    Property06: string = "";

    /**
     * @name Value06
     * @servertype String
     * @type {string}
     */
    Value06: string = "";

    /**
     * @name Property07
     * @servertype String
     * @type {string}
     */
    Property07: string = "";

    /**
     * @name Value07
     * @servertype String
     * @type {string}
     */
    Value07: string = "";

    /**
     * @name Property08
     * @servertype String
     * @type {string}
     */
    Property08: string = "";

    /**
     * @name Value08
     * @servertype String
     * @type {string}
     */
    Value08: string = "";

    /**
     * @name Property09
     * @servertype String
     * @type {string}
     */
    Property09: string = "";

    /**
     * @name Value09
     * @servertype String
     * @type {string}
     */
    Value09: string = "";

    /**
     * @name Property10
     * @servertype String
     * @type {string}
     */
    Property10: string = "";

    /**
     * @name Value10
     * @servertype String
     * @type {string}
     */
    Value10: string = "";

    /**
     * @name FavoriteId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FavoriteId: number = 0;

    /**
     * @name FavoriteRank
     * @servertype Int32
     * @type {number}
     */
    FavoriteRank: number = 0;

    /**
     * @name FavoriteRankMaxNatural
     * @servertype Int32
     * @type {number}
     */
    FavoriteRankMaxNatural: number = 0;

    /**
     * @name FavoriteRankOrganization
     * @servertype Int32
     * @type {number}
     */
    FavoriteRankOrganization: number = 0;

    /**
     * @name FavoriteRankMaxNaturalOrganization
     * @servertype Int32
     * @type {number}
     */
    FavoriteRankMaxNaturalOrganization: number = 0;

    /**
     * @name FavoriteRankIndustry
     * @servertype Int32
     * @type {number}
     */
    FavoriteRankIndustry: number = 0;

    /**
     * @name FavoriteRankMaxNaturalIndustry
     * @servertype Int32
     * @type {number}
     */
    FavoriteRankMaxNaturalIndustry: number = 0;

    /**
     * @name MatchRank
     * @servertype Int32
     * @type {number}
     */
    MatchRank: number = 0;

    /**
     * @name MatchRankReason
     * @servertype String
     * @type {string}
     */
    MatchRankReason: string = "";

    /**
     * @name Score
     * @servertype Int32
     * @type {number}
     */
    Score: number = 0;

    /**
     * @name ScoreCalculation
     * @servertype String
     * @type {string}
     */
    ScoreCalculation: string = "";

    /**
     * @name ScoreCalculationInformation
     * @servertype ReportCompilerLibraryEntryScoreCalculationModel array
     * @type {ReportCompilerLibraryEntryScoreCalculationModel[]}
     */
    ScoreCalculationInformation: ReportCompilerLibraryEntryScoreCalculationModel[] = [];

    /**
     * @name ReasonSuggested
     * @servertype String
     * @type {string}
     */
    ReasonSuggested: string = "";

    /**
     * @name ReasonSuggestedInformation
     * @servertype ReportCompilerLibraryEntryReasonSuggestedModel array
     * @type {ReportCompilerLibraryEntryReasonSuggestedModel[]}
     */
    ReasonSuggestedInformation: ReportCompilerLibraryEntryReasonSuggestedModel[] = [];

    /**
     * @name Approval
     * @servertype String
     * @type {string}
     */
    Approval: string = "";

    /**
     * @name Text
     * @servertype String
     * @type {string}
     */
    Text: string = "";

    /**
     * @name PreviewText
     * @servertype String
     * @type {string}
     */
    PreviewText: string = "";

    /**
     * @name TextBookmarkStart
     * @servertype String
     * @type {string}
     */
    TextBookmarkStart: string = "";

    /**
     * @name TextBookmarkEnd
     * @servertype String
     * @type {string}
     */
    TextBookmarkEnd: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Report Attachment Configuration
 */
export class ReportCompilerReportAttachmentConfigurationModel {

    /**
     * @name ReportAttachmentConfigurationId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportAttachmentConfigurationId: number = 0;

    /**
     * @name OwnerResourceType
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @servertype Int64
     * @type {number}
     */
    OwnerResourceId: number = 0;

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name AttachmentHeading
     * @servertype String
     * @type {string}
     */
    AttachmentHeading: string = "";

    /**
     * @name AttachmentDescription
     * @servertype String
     * @type {string}
     */
    AttachmentDescription: string = "";

    /**
     * @name AttachmentSectionHeadingType
     * @servertype String
     * @type {string}
     */
    AttachmentSectionHeadingType: string = "";

    /**
     * @name AttachmentRole
     * @servertype String
     * @type {string}
     */
    AttachmentRole: string = "";

    /**
     * @name ReportAttachmentImageLayoutTemplateAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportAttachmentImageLayoutTemplateAssetId: number = 0;

    /**
     * @name AllowUploadedAttachments
     * @servertype Boolean
     * @type {boolean}
     */
    AllowUploadedAttachments: boolean = false;

    /**
     * @name AllowDocumentLibraryAttachments
     * @servertype Boolean
     * @type {boolean}
     */
    AllowDocumentLibraryAttachments: boolean = false;

    /**
     * @name UseLibrarySelectionCriteria
     * @servertype Boolean
     * @type {boolean}
     */
    UseLibrarySelectionCriteria: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Report Section Configuration
 */
export class ReportCompilerReportSectionConfigurationModel {

    /**
     * @name ReportSectionConfigurationId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportSectionConfigurationId: number = 0;

    /**
     * @name OwnerResourceType
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @servertype Int64
     * @type {number}
     */
    OwnerResourceId: number = 0;

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name SectionHeading
     * @servertype String
     * @type {string}
     */
    SectionHeading: string = "";

    /**
     * @name SubsectionHeadingType
     * @servertype String
     * @type {string}
     */
    SubsectionHeadingType: string = "";

    /**
     * @name SectionRole
     * @servertype String
     * @type {string}
     */
    SectionRole: string = "";

    /**
     * @name LibraryCategory
     * @servertype String
     * @type {string}
     */
    LibraryCategory: string = "";

    /**
     * @name UseLibraryTextSelectionCriteria
     * @servertype Boolean
     * @type {boolean}
     */
    UseLibraryTextSelectionCriteria: boolean = false;

    /**
     * @name AutomaticallySuggestLibraryText
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticallySuggestLibraryText: boolean = false;

    /**
     * @name AutomaticallySelectLibraryText
     * @servertype String
     * @type {string}
     */
    AutomaticallySelectLibraryText: string = "";

    /**
     * @name LibraryTextColumnsUseDefaultConfiguration
     * @servertype Boolean
     * @type {boolean}
     */
    LibraryTextColumnsUseDefaultConfiguration: boolean = false;

    /**
     * @name LibraryTextSort
     * @servertype String
     * @type {string}
     */
    LibraryTextSort: string = "";

    /**
     * @name LibraryTextColumns
     * @servertype ReportCompilerReportSectionConfigurationTextColumnModel array
     * @type {ReportCompilerReportSectionConfigurationTextColumnModel[]}
     */
    LibraryTextColumns: ReportCompilerReportSectionConfigurationTextColumnModel[] = [];

    /**
     * @name ParagraphNumberingStyle
     * @servertype String
     * @type {string}
     */
    ParagraphNumberingStyle: string = "";

    /**
     * @name OpeningParagraphs
     * @servertype string array
     * @type {string[]}
     */
    OpeningParagraphs: string[] = [];

    /**
     * @name OpeningParagraphsUseParagraphNumbering
     * @servertype Boolean
     * @type {boolean}
     */
    OpeningParagraphsUseParagraphNumbering: boolean = false;

    /**
     * @name ClosingParagraphs
     * @servertype string array
     * @type {string[]}
     */
    ClosingParagraphs: string[] = [];

    /**
     * @name ClosingParagraphsUseParagraphNumbering
     * @servertype Boolean
     * @type {boolean}
     */
    ClosingParagraphsUseParagraphNumbering: boolean = false;

    /**
     * @name SectionEnableTriggers
     * @servertype string array
     * @type {string[]}
     */
    SectionEnableTriggers: string[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Report Section Configuration Text Column
 */
export class ReportCompilerReportSectionConfigurationTextColumnModel {

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name DisplayOrder
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name HeadingText
     * @servertype String
     * @type {string}
     */
    HeadingText: string = "";

    /**
     * @name HeadingIcon
     * @servertype String
     * @type {string}
     */
    HeadingIcon: string = "";

    /**
     * @name PropertyName
     * @servertype String
     * @type {string}
     */
    PropertyName: string = "";

}



/**
 * @name Case Template Contact
 */
export class ReportCompilerCaseTemplateContactModel {

    /**
     * @name CaseContactRole
     * @servertype String
     * @type {string}
     */
    CaseContactRole: string = "";

    /**
     * @name ContactAddressTypeLink
     * @servertype String
     * @type {string}
     */
    ContactAddressTypeLink: string = "";

    /**
     * @name ContactAddressTypeCandidates
     * @servertype string array
     * @type {string[]}
     */
    ContactAddressTypeCandidates: string[] = [];

    /**
     * @name CaseContactLink
     * @servertype String
     * @type {string}
     */
    CaseContactLink: string = "";

    /**
     * @name Required
     * @servertype Boolean
     * @type {boolean}
     */
    Required: boolean = false;

    /**
     * @name CanBeCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    CanBeCustomer: boolean = false;

    /**
     * @name CanBeCustomerContact
     * @servertype Boolean
     * @type {boolean}
     */
    CanBeCustomerContact: boolean = false;

    /**
     * @name CanBeDirectory
     * @servertype Boolean
     * @type {boolean}
     */
    CanBeDirectory: boolean = false;

    /**
     * @name CanBeGroup
     * @servertype Boolean
     * @type {boolean}
     */
    CanBeGroup: boolean = false;

    /**
     * @name CanBeLocation
     * @servertype Boolean
     * @type {boolean}
     */
    CanBeLocation: boolean = false;

    /**
     * @name DefaultContactType
     * @servertype String
     * @type {string}
     */
    DefaultContactType: string = "";

    /**
     * @name OnCaseCloseSanitizeIfCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    OnCaseCloseSanitizeIfCustomer: boolean = false;

    /**
     * @name OnCaseCloseSanitizeIfCustomerContact
     * @servertype Boolean
     * @type {boolean}
     */
    OnCaseCloseSanitizeIfCustomerContact: boolean = false;

    /**
     * @name PromptForContactCaseNumber
     * @servertype Boolean
     * @type {boolean}
     */
    PromptForContactCaseNumber: boolean = false;

}



/**
 * @name ReportCompilerCaseContactLinkType Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerCaseContactLinkType {

    /**
     * No case contact link.
     */
    Empty = 0,

    /**
     * This is the main case contact point.  Typically the customer for the case.
     */
    Contact = 1,

    /**
     * This is the case site contact.
     */
    Site = 2,

    /**
     * This is the case opened by contact.  When there is no opened by contact new cases are opened by the main contact.
     */
    OpenedBy = 3,

    /**
     * This is the case owned by contact.  When there is no owned by contact new cases are owned by the opener.
     */
    OwnedBy = 4,

    /**
     * This is the case assigned to contact.  When there is no assigned to contact new cases are assigned to the owner.
     */
    AssignedTo = 5,

    /**
     * This is the case supervisor.  When there is no supervisor contact new cases are assigned to the owner's supervisor.
     */
    Supervisor = 6

}



/**
 * @name Case Customization
 */
export class ReportCompilerCaseCustomizationModel {

    /**
     * @name ReportTemplateCanChange
     * @servertype Boolean
     * @type {boolean}
     */
    ReportTemplateCanChange: boolean = false;

    /**
     * @name ReportAttachmentCoverPageTemplateCanChange
     * @servertype Boolean
     * @type {boolean}
     */
    ReportAttachmentCoverPageTemplateCanChange: boolean = false;

    /**
     * @name AttachmentNumberingStyleCanChange
     * @servertype Boolean
     * @type {boolean}
     */
    AttachmentNumberingStyleCanChange: boolean = false;

    /**
     * @name ReportSectionCanChangeOptions
     * @servertype Boolean
     * @type {boolean}
     */
    ReportSectionCanChangeOptions: boolean = false;

    /**
     * @name ReportSectionCanAdd
     * @servertype Boolean
     * @type {boolean}
     */
    ReportSectionCanAdd: boolean = false;

    /**
     * @name ReportSectionCanReorder
     * @servertype Boolean
     * @type {boolean}
     */
    ReportSectionCanReorder: boolean = false;

    /**
     * @name ReportAttachmentCanChangeOptions
     * @servertype Boolean
     * @type {boolean}
     */
    ReportAttachmentCanChangeOptions: boolean = false;

    /**
     * @name ReportAttachmentCanAdd
     * @servertype Boolean
     * @type {boolean}
     */
    ReportAttachmentCanAdd: boolean = false;

    /**
     * @name ReportAttachmentCanReorder
     * @servertype Boolean
     * @type {boolean}
     */
    ReportAttachmentCanReorder: boolean = false;

}



/**
 * @name PDF Options
 */
export class ReportCompilerPdfOptionsModel {

    /**
     * @name BookmarkLevel
     * @servertype Int32
     * @type {number}
     */
    BookmarkLevel: number = 0;

    /**
     * @name SecurityEnforcedByTemplate
     * @servertype Boolean
     * @type {boolean}
     */
    SecurityEnforcedByTemplate: boolean = false;

    /**
     * @name ApplyDigitalSignature
     * @servertype Boolean
     * @type {boolean}
     */
    ApplyDigitalSignature: boolean = false;

    /**
     * @name UseUserCertificateForDigitalSignature
     * @servertype Boolean
     * @type {boolean}
     */
    UseUserCertificateForDigitalSignature: boolean = false;

    /**
     * @name DigitalSignatureAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DigitalSignatureAssetId: number = 0;

    /**
     * @name OpenPassword
     * @servertype String
     * @type {string}
     */
    OpenPassword: string = "";

    /**
     * @name PermissionsPassword
     * @servertype String
     * @type {string}
     */
    PermissionsPassword: string = "";

    /**
     * @name EncryptionLevel
     * @servertype Int32
     * @type {number}
     */
    EncryptionLevel: number = 0;

    /**
     * @name PermitAccessibilityExtractContent
     * @servertype Boolean
     * @type {boolean}
     */
    PermitAccessibilityExtractContent: boolean = false;

    /**
     * @name PermitAnnotations
     * @servertype Boolean
     * @type {boolean}
     */
    PermitAnnotations: boolean = false;

    /**
     * @name PermitAssembleDocument
     * @servertype Boolean
     * @type {boolean}
     */
    PermitAssembleDocument: boolean = false;

    /**
     * @name PermitExtractContent
     * @servertype Boolean
     * @type {boolean}
     */
    PermitExtractContent: boolean = false;

    /**
     * @name PermitModifyDocument
     * @servertype Boolean
     * @type {boolean}
     */
    PermitModifyDocument: boolean = false;

    /**
     * @name PermitFormsFill
     * @servertype Boolean
     * @type {boolean}
     */
    PermitFormsFill: boolean = false;

    /**
     * @name PermitFullQualityPrint
     * @servertype Boolean
     * @type {boolean}
     */
    PermitFullQualityPrint: boolean = false;

    /**
     * @name PermitPrint
     * @servertype Boolean
     * @type {boolean}
     */
    PermitPrint: boolean = false;

}



/**
 * @name ReportCompilerConstants
 */
export module ReportCompilerConstants {
    export var SanitizeCaseRemoveSubjectMatter: string = "SanitizeCaseRemoveSubjectMatter";
    export var SanitizeCaseRemoveReportSections: string = "SanitizeCaseRemoveReportSections";
    export var SanitizeCaseRemoveReportAttachments: string = "SanitizeCaseRemoveReportAttachments";
    export var SanitizeCaseLearnFavorites: string = "SanitizeCaseLearnFavorites";
    export var SupportCaseFeatureRespectCaseReviewResultPermissions: string = "RespectCaseReviewResultPermissions";
    export var SubjectMatterTypeLibrarySelectionRules: string = "LibrarySelectionRules";
    export var SubjectMatterTypeStructuralProperty: string = "StructuralProperty";
    export var LibraryTypeOther: string = "Other";
    export var LibraryTypeStructural: string = "Structural";
    export var ModuleLibraryPersonal: string = "rc-library-personal";
    export var ModuleLibraryIndustry: string = "rc-library-industry";
    export var ModuleLibraryOrganization: string = "rc-library-organization";
    export var ModuleLibraryInsights: string = "rc-library-insights";
    export var ModuleLibraryExport: string = "rc-library-export";
    export var ModuleManagementInsights: string = "rc-management-insights";
    export var ModuleReportParser: string = "rc-report-parser";
    export var ModuleReportFinalPdf: string = "rc-report-final-pdf";
    export var ModuleWorkflow: string = "rc-workflow";
    export var ScoreMatchExact: string = "100";
    export var ScoreMatchPartial: string = "50";
    export var ScoreMatchAlways: string = "25";
    export var ScoreMatchSearchFoundInTag: string = "10";
    export var ScoreMatchSearchFoundInLibrary: string = "5";
    export var ScoreFavLove: string = "100";
    export var ScoreFavLike: string = "50";
    export var ScoreFavDislike: string = "0";
    export var ScoreFavBlock: string = "-100";
    export var ScoreFavNaturalDefault: string = "5";
    export var ScoreFavNaturalMax: string = "50";
}



/**
 * @name Case Options
 */
export class ReportCompilerCaseOptionsModel {

    /**
     * @name CaseOptionsId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseOptionsId: number = 0;

    /**
     * @name OwnerResourceType
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @servertype Int64
     * @type {number}
     */
    OwnerResourceId: number = 0;

    /**
     * @name ReportTemplateAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportTemplateAssetId: number = 0;

    /**
     * @name ReportAttachmentCoverPageTemplateAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReportAttachmentCoverPageTemplateAssetId: number = 0;

    /**
     * @name AttachmentNumberingStyle
     * @servertype String
     * @type {string}
     */
    AttachmentNumberingStyle: string = "";

    /**
     * @name PdfOptions
     * @servertype IB.Web.API.Models.v5.ReportCompilerPdfOptionsModel
     * @type {ReportCompilerPdfOptionsModel}
     */
    PdfOptions: ReportCompilerPdfOptionsModel = null;

    /**
     * @name TemplateTypeId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TemplateTypeId: number = 0;

    /**
     * @name CaseCustomization
     * @servertype IB.Web.API.Models.v5.ReportCompilerCaseCustomizationModel
     * @type {ReportCompilerCaseCustomizationModel}
     */
    CaseCustomization: ReportCompilerCaseCustomizationModel = null;

    /**
     * @name CaseContacts
     * @servertype ReportCompilerCaseTemplateContactModel array
     * @type {ReportCompilerCaseTemplateContactModel[]}
     */
    CaseContacts: ReportCompilerCaseTemplateContactModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Template Type Configuration
 */
export class ReportCompilerTemplateTypeConfigurationModel {

    /**
     * @name TemplateTypeId
     * @servertype Int64
     * @type {number}
     */
    TemplateTypeId: number = 0;

    /**
     * @name TemplateType
     * @servertype String
     * @type {string}
     */
    TemplateType: string = "";

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name LibraryGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LibraryGroupId: number = 0;

    /**
     * @name SubjectMatterLabel
     * @servertype String
     * @type {string}
     */
    SubjectMatterLabel: string = "";

    /**
     * @name SubjectMatterType
     * @servertype String
     * @type {string}
     */
    SubjectMatterType: string = "";

    /**
     * @name SubjectMatterMinimumItemCount
     * @servertype Int32
     * @type {number}
     */
    SubjectMatterMinimumItemCount: number = 0;

    /**
     * @name SubjectMatterMaximumItemCount
     * @servertype Int32
     * @type {number}
     */
    SubjectMatterMaximumItemCount: number = 0;

    /**
     * @name IncludeTags
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeTags: boolean = false;

    /**
     * @name SubjectMatterPickListIds
     * @servertype string array
     * @type {string[]}
     */
    SubjectMatterPickListIds: string[] = [];

    /**
     * @name SubjectMatterAttributeSetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterAttributeSetId: number = 0;

    /**
     * @name SubjectMatterFormId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterFormId: number = 0;

    /**
     * @name SubjectMatterAssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SubjectMatterAssetId: number = 0;

    /**
     * @name LearnImageClassificationLastTrainingDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LearnImageClassificationLastTrainingDateTime: Date = null;

    /**
     * @name LearnImageClassificationLastTrainingResultCode
     * @servertype Int32
     * @type {number}
     */
    LearnImageClassificationLastTrainingResultCode: number = 0;

    /**
     * @name LearnImageClassificationLastTrainingResultMessage
     * @servertype String
     * @type {string}
     */
    LearnImageClassificationLastTrainingResultMessage: string = "";

    /**
     * @name LearnImageClassificationLastTrainingExampleCount
     * @servertype ReportCompilerImageClassificationExampleModel array
     * @type {ReportCompilerImageClassificationExampleModel[]}
     */
    LearnImageClassificationLastTrainingExampleCount: ReportCompilerImageClassificationExampleModel[] = [];

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Image Classification Example Data
 */
export class ReportCompilerImageClassificationExampleModel {

    /**
     * @name Classification
     * @servertype String
     * @type {string}
     */
    Classification: string = "";

    /**
     * @name ExampleCount
     * @servertype Int64
     * @type {number}
     */
    ExampleCount: number = 0;

}



/**
 * @name Library Group Configuration
 */
export class ReportCompilerLibraryGroupConfigurationModel {

    /**
     * @name LibraryGroupId
     * @servertype Int64
     * @type {number}
     */
    LibraryGroupId: number = 0;

    /**
     * @name LibraryGroup
     * @servertype String
     * @type {string}
     */
    LibraryGroup: string = "";

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name LibraryCategoryPickListId
     * @servertype String
     * @type {string}
     */
    LibraryCategoryPickListId: string = "";

    /**
     * @name IndustryLibraryContentAllowed
     * @servertype Boolean
     * @type {boolean}
     */
    IndustryLibraryContentAllowed: boolean = false;

    /**
     * @name IndustryLibraryType
     * @servertype String
     * @type {string}
     */
    IndustryLibraryType: string = "";

    /**
     * @name IndustryLibraryContentRequiresApproval
     * @servertype Boolean
     * @type {boolean}
     */
    IndustryLibraryContentRequiresApproval: boolean = false;

    /**
     * @name IndustryLibraryContentCanSeeAndRequestApproval
     * @servertype Boolean
     * @type {boolean}
     */
    IndustryLibraryContentCanSeeAndRequestApproval: boolean = false;

    /**
     * @name IndustryLibraryContentAllowedLicenses
     * @servertype string array
     * @type {string[]}
     */
    IndustryLibraryContentAllowedLicenses: string[] = [];

    /**
     * @name IndustryLibraryContentSubmissionAllowed
     * @servertype Boolean
     * @type {boolean}
     */
    IndustryLibraryContentSubmissionAllowed: boolean = false;

    /**
     * @name IndustryLibraryContentSubmissionRequiresApproval
     * @servertype Boolean
     * @type {boolean}
     */
    IndustryLibraryContentSubmissionRequiresApproval: boolean = false;

    /**
     * @name IndustryLibraryContentSubmissionAllowedLicenses
     * @servertype string array
     * @type {string[]}
     */
    IndustryLibraryContentSubmissionAllowedLicenses: string[] = [];

    /**
     * @name IndustryLibraryContentSubmissionDefaultLicense
     * @servertype String
     * @type {string}
     */
    IndustryLibraryContentSubmissionDefaultLicense: string = "";

    /**
     * @name LearnLibraryContentReviewSentimentLastTrainingDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LearnLibraryContentReviewSentimentLastTrainingDateTime: Date = null;

    /**
     * @name LearnLibraryContentReviewSentimentLastTrainingResultCode
     * @servertype Int32
     * @type {number}
     */
    LearnLibraryContentReviewSentimentLastTrainingResultCode: number = 0;

    /**
     * @name LearnLibraryContentReviewSentimentLastTrainingResultMessage
     * @servertype String
     * @type {string}
     */
    LearnLibraryContentReviewSentimentLastTrainingResultMessage: string = "";

    /**
     * @name LearnLibraryContentReviewSentimentLastTrainingPositiveExampleCount
     * @servertype Int64
     * @type {number}
     */
    LearnLibraryContentReviewSentimentLastTrainingPositiveExampleCount: number = 0;

    /**
     * @name LearnLibraryContentReviewSentimentLastTrainingNegativeExampleCount
     * @servertype Int64
     * @type {number}
     */
    LearnLibraryContentReviewSentimentLastTrainingNegativeExampleCount: number = 0;

    /**
     * @name LearnLibraryContentPersonalPreferences
     * @servertype Boolean
     * @type {boolean}
     */
    LearnLibraryContentPersonalPreferences: boolean = false;

    /**
     * @name LearnLibraryContentPersonalPreferencesUseThreshold
     * @servertype Int32
     * @type {number}
     */
    LearnLibraryContentPersonalPreferencesUseThreshold: number = 0;

    /**
     * @name LearnLibraryContentOrganizationPreferences
     * @servertype Boolean
     * @type {boolean}
     */
    LearnLibraryContentOrganizationPreferences: boolean = false;

    /**
     * @name LearnLibraryContentOrganizationPreferencesUseThreshold
     * @servertype Int32
     * @type {number}
     */
    LearnLibraryContentOrganizationPreferencesUseThreshold: number = 0;

    /**
     * @name LearnLibraryContentIndustryPreferences
     * @servertype Boolean
     * @type {boolean}
     */
    LearnLibraryContentIndustryPreferences: boolean = false;

    /**
     * @name LearnLibraryContentIndustryPreferencesUseThreshold
     * @servertype Int32
     * @type {number}
     */
    LearnLibraryContentIndustryPreferencesUseThreshold: number = 0;

    /**
     * @name LearnLibraryContentSelectionRulesPersonal
     * @servertype Boolean
     * @type {boolean}
     */
    LearnLibraryContentSelectionRulesPersonal: boolean = false;

    /**
     * @name LearnLibraryContentSelectionRulesOrganization
     * @servertype Boolean
     * @type {boolean}
     */
    LearnLibraryContentSelectionRulesOrganization: boolean = false;

    /**
     * @name LearnLibraryContentSelectionRulesIndustry
     * @servertype Boolean
     * @type {boolean}
     */
    LearnLibraryContentSelectionRulesIndustry: boolean = false;

    /**
     * @name LearnLibraryContentSelectionRulesNarrowSuggestionThreshold
     * @servertype Int32
     * @type {number}
     */
    LearnLibraryContentSelectionRulesNarrowSuggestionThreshold: number = 0;

    /**
     * @name LearnLibraryContentSelectionRulesWideSuggestionThreshold
     * @servertype Int32
     * @type {number}
     */
    LearnLibraryContentSelectionRulesWideSuggestionThreshold: number = 0;

    /**
     * @name ScoreWeightCategoryLibraryVisibility
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightCategoryLibraryVisibility: number = 0;

    /**
     * @name ScoreWeightCategorySelectionRules
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightCategorySelectionRules: number = 0;

    /**
     * @name ScoreWeightCategoryPreferences
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightCategoryPreferences: number = 0;

    /**
     * @name ScoreWeightLibraryPersonal
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightLibraryPersonal: number = 0;

    /**
     * @name ScoreWeightLibraryOrganization
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightLibraryOrganization: number = 0;

    /**
     * @name ScoreWeightLibraryIndustry
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightLibraryIndustry: number = 0;

    /**
     * @name ScoreWeightSelectionRulesExactMatch
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightSelectionRulesExactMatch: number = 0;

    /**
     * @name ScoreWeightSelectionRulesPartialMatch
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightSelectionRulesPartialMatch: number = 0;

    /**
     * @name ScoreWeightSelectionRulesAlwaysMatch
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightSelectionRulesAlwaysMatch: number = 0;

    /**
     * @name ScoreWeightExplicitPersonalPreference
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightExplicitPersonalPreference: number = 0;

    /**
     * @name ScoreWeightInferredPersonalPreference
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightInferredPersonalPreference: number = 0;

    /**
     * @name ScoreWeightInferredOrganizationPreference
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightInferredOrganizationPreference: number = 0;

    /**
     * @name ScoreWeightInferredIndustryPreference
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightInferredIndustryPreference: number = 0;

    /**
     * @name ScoreWeightLibraryContentFeedbackSentiment
     * @servertype Int32
     * @type {number}
     */
    ScoreWeightLibraryContentFeedbackSentiment: number = 0;

    /**
     * @name TextIncludeTags
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeTags: boolean = false;

    /**
     * @name TextIncludeSelectionRules
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeSelectionRules: boolean = false;

    /**
     * @name TextIncludeVersions
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeVersions: boolean = false;

    /**
     * @name TextIncludeFeedback
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeFeedback: boolean = false;

    /**
     * @name TextIncludeReview
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeReview: boolean = false;

    /**
     * @name TextIncludeApproval
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeApproval: boolean = false;

    /**
     * @name TextIncludeAuthor
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeAuthor: boolean = false;

    /**
     * @name TextIncludeComments
     * @servertype Boolean
     * @type {boolean}
     */
    TextIncludeComments: boolean = false;

    /**
     * @name TextRequireApprovalForPrivate
     * @servertype Boolean
     * @type {boolean}
     */
    TextRequireApprovalForPrivate: boolean = false;

    /**
     * @name TextRequireApprovalForPublic
     * @servertype Boolean
     * @type {boolean}
     */
    TextRequireApprovalForPublic: boolean = false;

    /**
     * @name DocumentIncludeTags
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeTags: boolean = false;

    /**
     * @name DocumentIncludeSelectionRules
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeSelectionRules: boolean = false;

    /**
     * @name DocumentIncludeVersions
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeVersions: boolean = false;

    /**
     * @name DocumentIncludeFeedback
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeFeedback: boolean = false;

    /**
     * @name DocumentIncludeReview
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeReview: boolean = false;

    /**
     * @name DocumentIncludeApproval
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeApproval: boolean = false;

    /**
     * @name DocumentIncludeAuthor
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeAuthor: boolean = false;

    /**
     * @name DocumentIncludeComments
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentIncludeComments: boolean = false;

    /**
     * @name DocumentRequireApprovalForPrivate
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentRequireApprovalForPrivate: boolean = false;

    /**
     * @name DocumentRequireApprovalForPublic
     * @servertype Boolean
     * @type {boolean}
     */
    DocumentRequireApprovalForPublic: boolean = false;

    /**
     * @name Property01
     * @servertype String
     * @type {string}
     */
    Property01: string = "";

    /**
     * @name Property02
     * @servertype String
     * @type {string}
     */
    Property02: string = "";

    /**
     * @name Property03
     * @servertype String
     * @type {string}
     */
    Property03: string = "";

    /**
     * @name Property04
     * @servertype String
     * @type {string}
     */
    Property04: string = "";

    /**
     * @name Property05
     * @servertype String
     * @type {string}
     */
    Property05: string = "";

    /**
     * @name Property06
     * @servertype String
     * @type {string}
     */
    Property06: string = "";

    /**
     * @name Property07
     * @servertype String
     * @type {string}
     */
    Property07: string = "";

    /**
     * @name Property08
     * @servertype String
     * @type {string}
     */
    Property08: string = "";

    /**
     * @name Property09
     * @servertype String
     * @type {string}
     */
    Property09: string = "";

    /**
     * @name Property10
     * @servertype String
     * @type {string}
     */
    Property10: string = "";

    /**
     * @name Property01PickListId
     * @servertype string array
     * @type {string[]}
     */
    Property01PickListId: string[] = [];

    /**
     * @name Property02PickListId
     * @servertype string array
     * @type {string[]}
     */
    Property02PickListId: string[] = [];

    /**
     * @name Property03PickListId
     * @servertype string array
     * @type {string[]}
     */
    Property03PickListId: string[] = [];

    /**
     * @name Property04PickListId
     * @servertype string array
     * @type {string[]}
     */
    Property04PickListId: string[] = [];

    /**
     * @name Property05PickListId
     * @servertype string array
     * @type {string[]}
     */
    Property05PickListId: string[] = [];

    /**
     * @name Property06PickListId
     * @servertype string array
     * @type {string[]}
     */
    Property06PickListId: string[] = [];

    /**
     * @name Property07PickListId
     * @servertype string array
     * @type {string[]}
     */
    Property07PickListId: string[] = [];

    /**
     * @name Property08PickListId
     * @servertype string array
     * @type {string[]}
     */
    Property08PickListId: string[] = [];

    /**
     * @name Property09PickListId
     * @servertype string array
     * @type {string[]}
     */
    Property09PickListId: string[] = [];

    /**
     * @name Property10PickListId
     * @servertype string array
     * @type {string[]}
     */
    Property10PickListId: string[] = [];

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Library Review Document Result
 */
export class ReportCompilerLibraryReviewResultModel {

    /**
     * @name OriginalFileName
     * @servertype String
     * @type {string}
     */
    OriginalFileName: string = "";

    /**
     * @name MimeFileName
     * @servertype String
     * @type {string}
     */
    MimeFileName: string = "";

    /**
     * @name FinalFileName
     * @servertype String
     * @type {string}
     */
    FinalFileName: string = "";

    /**
     * @name FinalFileFullPath
     * @servertype String
     * @type {string}
     */
    FinalFileFullPath: string = "";

    /**
     * @name Extension
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name ContentType
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name ParagraphCountParsedRaw
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountParsedRaw: number = 0;

    /**
     * @name ParagraphCountSkipped
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountSkipped: number = 0;

    /**
     * @name ParagraphCountMetaData
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountMetaData: number = 0;

    /**
     * @name ParagraphCountParsed
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountParsed: number = 0;

    /**
     * @name ParagraphCountAdded
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountAdded: number = 0;

    /**
     * @name ParagraphCountUpdated
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountUpdated: number = 0;

    /**
     * @name ParagraphCountDeleted
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountDeleted: number = 0;

    /**
     * @name ParagraphCountNotChanged
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountNotChanged: number = 0;

    /**
     * @name ParagraphCountWarning
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountWarning: number = 0;

    /**
     * @name ParagraphCountError
     * @servertype Int32
     * @type {number}
     */
    ParagraphCountError: number = 0;

    /**
     * @name LibraryAssetsAdded
     * @servertype long array
     * @type {number[]}
     */
    LibraryAssetsAdded: number[] = [];

    /**
     * @name LibraryAssetsUpdated
     * @servertype long array
     * @type {number[]}
     */
    LibraryAssetsUpdated: number[] = [];

    /**
     * @name LibraryAssetsDeleted
     * @servertype long array
     * @type {number[]}
     */
    LibraryAssetsDeleted: number[] = [];

    /**
     * @name LibraryAssetsNotChanged
     * @servertype long array
     * @type {number[]}
     */
    LibraryAssetsNotChanged: number[] = [];

    /**
     * @name LibraryAssetsWarnings
     * @servertype long array
     * @type {number[]}
     */
    LibraryAssetsWarnings: number[] = [];

    /**
     * @name LibraryAssetsErrors
     * @servertype long array
     * @type {number[]}
     */
    LibraryAssetsErrors: number[] = [];

    /**
     * @name Notices
     * @servertype ReportCompilerLibraryReviewNoticeModel array
     * @type {ReportCompilerLibraryReviewNoticeModel[]}
     */
    Notices: ReportCompilerLibraryReviewNoticeModel[] = [];

    /**
     * @name Warnings
     * @servertype ReportCompilerLibraryReviewNoticeModel array
     * @type {ReportCompilerLibraryReviewNoticeModel[]}
     */
    Warnings: ReportCompilerLibraryReviewNoticeModel[] = [];

    /**
     * @name Errors
     * @servertype ReportCompilerLibraryReviewNoticeModel array
     * @type {ReportCompilerLibraryReviewNoticeModel[]}
     */
    Errors: ReportCompilerLibraryReviewNoticeModel[] = [];

    /**
     * @name DryRun
     * @servertype Boolean
     * @type {boolean}
     */
    DryRun: boolean = false;

}



/**
 * @name Library Review Document Notice
 */
export class ReportCompilerLibraryReviewNoticeModel {

    /**
     * @name AssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name OperationType
     * @servertype String
     * @type {string}
     */
    OperationType: string = "";

    /**
     * @name ResultCode
     * @servertype IB.Core.StandardResultCode Enum
     * @type {m.StandardResultCode}
     */
    ResultCode: m.StandardResultCode = null;

    /**
     * @name Message
     * @servertype String
     * @type {string}
     */
    Message: string = "";

    /**
     * @name CurrentLibraryMetaData
     * @servertype String
     * @type {string}
     */
    CurrentLibraryMetaData: string = "";

    /**
     * @name CurrentLibraryText
     * @servertype String
     * @type {string}
     */
    CurrentLibraryText: string = "";

    /**
     * @name RequestedLibraryMetaData
     * @servertype String
     * @type {string}
     */
    RequestedLibraryMetaData: string = "";

    /**
     * @name RequestedLibraryText
     * @servertype String
     * @type {string}
     */
    RequestedLibraryText: string = "";

    /**
     * @name Details
     * @servertype String
     * @type {string}
     */
    Details: string = "";

}



/**
 * @name Library Coverage
 */
export class ReportCompilerLibraryCoverageSummaryModel {

    /**
     * @name Item
     * @servertype String
     * @type {string}
     */
    Item: string = "";

    /**
     * @name Count
     * @servertype Int32
     * @type {number}
     */
    Count: number = 0;

    /**
     * @name Children
     * @servertype ReportCompilerLibraryCoverageSummaryModel array
     * @type {ReportCompilerLibraryCoverageSummaryModel[]}
     */
    Children: ReportCompilerLibraryCoverageSummaryModel[] = [];

}



/**
 * @name Library Coverage
 */
export class ReportCompilerLibraryCoverageModel {

    /**
     * @name LibraryGroupId
     * @servertype Int64
     * @type {number}
     */
    LibraryGroupId: number = 0;

    /**
     * @name LibraryGroup
     * @servertype String
     * @type {string}
     */
    LibraryGroup: string = "";

    /**
     * @name Property01
     * @servertype String
     * @type {string}
     */
    Property01: string = "";

    /**
     * @name Value01
     * @servertype String
     * @type {string}
     */
    Value01: string = "";

    /**
     * @name Property02
     * @servertype String
     * @type {string}
     */
    Property02: string = "";

    /**
     * @name Value02
     * @servertype String
     * @type {string}
     */
    Value02: string = "";

    /**
     * @name Property03
     * @servertype String
     * @type {string}
     */
    Property03: string = "";

    /**
     * @name Value03
     * @servertype String
     * @type {string}
     */
    Value03: string = "";

    /**
     * @name Property04
     * @servertype String
     * @type {string}
     */
    Property04: string = "";

    /**
     * @name Value04
     * @servertype String
     * @type {string}
     */
    Value04: string = "";

    /**
     * @name Property05
     * @servertype String
     * @type {string}
     */
    Property05: string = "";

    /**
     * @name Value05
     * @servertype String
     * @type {string}
     */
    Value05: string = "";

    /**
     * @name Property06
     * @servertype String
     * @type {string}
     */
    Property06: string = "";

    /**
     * @name Value06
     * @servertype String
     * @type {string}
     */
    Value06: string = "";

    /**
     * @name Property07
     * @servertype String
     * @type {string}
     */
    Property07: string = "";

    /**
     * @name Value07
     * @servertype String
     * @type {string}
     */
    Value07: string = "";

    /**
     * @name Property08
     * @servertype String
     * @type {string}
     */
    Property08: string = "";

    /**
     * @name Value08
     * @servertype String
     * @type {string}
     */
    Value08: string = "";

    /**
     * @name Property09
     * @servertype String
     * @type {string}
     */
    Property09: string = "";

    /**
     * @name Value09
     * @servertype String
     * @type {string}
     */
    Value09: string = "";

    /**
     * @name Property10
     * @servertype String
     * @type {string}
     */
    Property10: string = "";

    /**
     * @name Value10
     * @servertype String
     * @type {string}
     */
    Value10: string = "";

    /**
     * @name FullMatchCount
     * @servertype Int32
     * @type {number}
     */
    FullMatchCount: number = 0;

    /**
     * @name FullOrPartialMatchCount
     * @servertype Int32
     * @type {number}
     */
    FullOrPartialMatchCount: number = 0;

    /**
     * @name AsOf
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    AsOf: Date = null;

}



/**
 * @name Library Stats
 */
export class ReportCompilerLibraryStatsModel {

    /**
     * @name LibraryGroupId
     * @servertype String
     * @type {string}
     */
    LibraryGroupId: string = "";

    /**
     * @name LibraryGroup
     * @servertype String
     * @type {string}
     */
    LibraryGroup: string = "";

    /**
     * @name LibraryCategory
     * @servertype String
     * @type {string}
     */
    LibraryCategory: string = "";

    /**
     * @name AddedByContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AddedByContactId: number = null;

    /**
     * @name AddedByContactName
     * @servertype String
     * @type {string}
     */
    AddedByContactName: string = "";

    /**
     * @name Visibility
     * @servertype String
     * @type {string}
     */
    Visibility: string = "";

    /**
     * @name Count
     * @servertype Int32
     * @type {number}
     */
    Count: number = 0;

    /**
     * @name Validated
     * @servertype Boolean
     * @type {boolean}
     */
    Validated: boolean = false;

}



/**
 * @name ReportCompilerReportParserResultModel
 */
export class ReportCompilerReportParserResultModel {

    /**
     * @name ReportId
     * @servertype String
     * @type {string}
     */
    ReportId: string = "";

    /**
     * @name ReportFileName
     * @servertype String
     * @type {string}
     */
    ReportFileName: string = "";

    /**
     * @name LibraryGroupId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    LibraryGroupId: number = 0;

    /**
     * @name ShareWithOrganization
     * @servertype Boolean
     * @type {boolean}
     */
    ShareWithOrganization: boolean = false;

    /**
     * @name Tags
     * @servertype string array
     * @type {string[]}
     */
    Tags: string[] = [];

    /**
     * @name TemplateTypeId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TemplateTypeId: number = 0;

    /**
     * @name SubjectMatters
     * @servertype ReportCompilerReportParserResultSubjectMatterModel array
     * @type {ReportCompilerReportParserResultSubjectMatterModel[]}
     */
    SubjectMatters: ReportCompilerReportParserResultSubjectMatterModel[] = [];

    /**
     * @name PendingCount
     * @servertype Int32
     * @type {number}
     */
    PendingCount: number = 0;

    /**
     * @name AcceptedCount
     * @servertype Int32
     * @type {number}
     */
    AcceptedCount: number = 0;

    /**
     * @name MergedCount
     * @servertype Int32
     * @type {number}
     */
    MergedCount: number = 0;

    /**
     * @name DeletedAndSkippedCount
     * @servertype Int32
     * @type {number}
     */
    DeletedAndSkippedCount: number = 0;

    /**
     * @name DeletedCount
     * @servertype Int32
     * @type {number}
     */
    DeletedCount: number = 0;

}



/**
 * @name ReportCompilerReportParserResultSubjectMatterModel
 */
export class ReportCompilerReportParserResultSubjectMatterModel {

    /**
     * @name SubjectMatterId
     * @servertype String
     * @type {string}
     */
    SubjectMatterId: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name SubjectMatter
     * @servertype object array
     * @type {any[]}
     */
    SubjectMatter: any[] = [];

}



/**
 * @name ReportCompilerReportParserResultAssetModel
 */
export class ReportCompilerReportParserResultAssetModel {

    /**
     * @name ParagraphNumber
     * @servertype Int64
     * @type {number}
     */
    ParagraphNumber: number = 0;

    /**
     * @name ParagraphType
     * @servertype IB.Web.API.Models.v5.ReportCompilerReportParserParagraphType Enum
     * @type {ReportCompilerReportParserParagraphType}
     */
    ParagraphType: ReportCompilerReportParserParagraphType = null;

    /**
     * @name AssetId
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name AssetText
     * @servertype String
     * @type {string}
     */
    AssetText: string = "";

    /**
     * @name AssetCategory
     * @servertype String
     * @type {string}
     */
    AssetCategory: string = "";

    /**
     * @name IsCategoryHeading
     * @servertype Boolean
     * @type {boolean}
     */
    IsCategoryHeading: boolean = false;

    /**
     * @name SubjectMatterId
     * @servertype String
     * @type {string}
     */
    SubjectMatterId: string = "";

    /**
     * @name SourceParagraphNumbers
     * @servertype long array
     * @type {number[]}
     */
    SourceParagraphNumbers: number[] = [];

    /**
     * @name ExistingAssetMatches
     * @servertype ReportCompilerReportParserResultAssetMatchModel array
     * @type {ReportCompilerReportParserResultAssetMatchModel[]}
     */
    ExistingAssetMatches: ReportCompilerReportParserResultAssetMatchModel[] = [];

}



/**
 * @name ReportCompilerReportParserParagraphType Enum
 * @readonly
 * @enum {number}
 */
export enum ReportCompilerReportParserParagraphType {

    /**
     * Pending
     */
    Pending = 0,

    /**
     * Accepted
     */
    Accepted = 1,

    /**
     * Merged
     */
    Merged = 2,

    /**
     * Deleted And Skipped
     */
    DeletedAndSkipped = 3,

    /**
     * Deleted
     */
    Deleted = 4

}



/**
 * @name ReportCompilerReportParserResultAssetMatchModel
 */
export class ReportCompilerReportParserResultAssetMatchModel {

    /**
     * @name MatchAssetId
     * @servertype Int64
     * @type {number}
     */
    MatchAssetId: number = 0;

    /**
     * @name MatchAssetVisibility
     * @servertype String
     * @type {string}
     */
    MatchAssetVisibility: string = "";

    /**
     * @name MatchAssetHasWildcards
     * @servertype Boolean
     * @type {boolean}
     */
    MatchAssetHasWildcards: boolean = false;

    /**
     * @name MatchPercent
     * @servertype Double
     * @type {number}
     */
    MatchPercent: number = 0;

}



/**
 * @name ReportCompilerReportParserParagraphMetaDataEditViewModel
 */
export class ReportCompilerReportParserParagraphMetaDataEditViewModel {

    /**
     * @name ReportId
     * @servertype String
     * @type {string}
     */
    ReportId: string = "";

    /**
     * @name ParagraphNumber
     * @servertype Int32
     * @type {number}
     */
    ParagraphNumber: number = 0;

    /**
     * @name EndingParagraphNumber
     * @servertype Int32
     * @type {number}
     */
    EndingParagraphNumber: number = 0;

    /**
     * @name MetaDataType
     * @servertype String
     * @type {string}
     */
    MetaDataType: string = "";

    /**
     * @name MetaDataValue
     * @servertype String
     * @type {string}
     */
    MetaDataValue: string = "";

}



