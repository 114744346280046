// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';
import * as m5core from './ngModelsCore5';


/**
 * @name Multi-Factor Authentication Options
 */
export class MultiFactorAuthenticationOptionsViewModel {

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name TotpEnabled
     * @servertype Boolean
     * @type {boolean}
     */
    TotpEnabled: boolean = false;

    /**
     * @name PhoneNumbers
     * @servertype string array
     * @type {string[]}
     */
    PhoneNumbers: string[] = [];

    /**
     * @name EmailAddresses
     * @servertype string array
     * @type {string[]}
     */
    EmailAddresses: string[] = [];

}



/**
 * @name RestrictionsEditViewModel
 */
export class RestrictionsEditViewModel {

    /**
     * @name RequiredModulesAll
     * @servertype string array
     * @type {string[]}
     */
    RequiredModulesAll: string[] = [];

    /**
     * @name RequiredModulesAny
     * @servertype string array
     * @type {string[]}
     */
    RequiredModulesAny: string[] = [];

    /**
     * @name RequiredPermissionsAll
     * @servertype RestrictionPermissionEditViewModel array
     * @type {RestrictionPermissionEditViewModel[]}
     */
    RequiredPermissionsAll: RestrictionPermissionEditViewModel[] = [];

    /**
     * @name RequiredPermissionsAny
     * @servertype RestrictionPermissionEditViewModel array
     * @type {RestrictionPermissionEditViewModel[]}
     */
    RequiredPermissionsAny: RestrictionPermissionEditViewModel[] = [];

    /**
     * @name RequiredGroupIdsAll
     * @servertype long array
     * @type {number[]}
     */
    RequiredGroupIdsAll: number[] = [];

    /**
     * @name RequiredGroupIdsAny
     * @servertype long array
     * @type {number[]}
     */
    RequiredGroupIdsAny: number[] = [];

    /**
     * @name RequiredRoleIdsAll
     * @servertype long array
     * @type {number[]}
     */
    RequiredRoleIdsAll: number[] = [];

    /**
     * @name RequiredRoleIdsAny
     * @servertype long array
     * @type {number[]}
     */
    RequiredRoleIdsAny: number[] = [];

}



/**
 * @name RestrictionPermissionEditViewModel
 */
export class RestrictionPermissionEditViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name PermissionAreaType
     * @servertype String
     * @type {string}
     */
    PermissionAreaType: string = "";

    /**
     * @name PermissionArea
     * @servertype String
     * @type {string}
     */
    PermissionArea: string = "";

    /**
     * @name Rights
     * @servertype string array
     * @type {string[]}
     */
    Rights: string[] = [];

    /**
     * @name AllRightsRequired
     * @servertype Boolean
     * @type {boolean}
     */
    AllRightsRequired: boolean = false;

}



/**
 * @name Login Name Available Request
 */
export class LoginNameAvailableRequestViewModel {

    /**
     * @name Login
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Email
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name FirstName
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

}



/**
 * @name Multi-Factor Authentication Request
 */
export class MultiFactorAuthenticationRequestViewModel {

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name Login
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Method
     * @servertype String
     * @type {string}
     */
    Method: string = "";

    /**
     * @name Target
     * @servertype String
     * @type {string}
     */
    Target: string = "";

    /**
     * @name Source
     * @servertype String
     * @type {string}
     */
    Source: string = "";

    /**
     * @name Value
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name Security Policy
 */
export class SecurityPolicyViewModel {

    /**
     * @name SecurityPolicyId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SecurityPolicyId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactTypeDescription
     * @servertype String
     * @type {string}
     */
    ContactTypeDescription: string = "";

    /**
     * @name PartitionId
     * @servertype Int64
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name GroupIds
     * @servertype long array
     * @type {number[]}
     */
    GroupIds: number[] = [];

    /**
     * @name Priority
     * @servertype Int32
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name MinimumPasswordLength
     * @servertype Int32
     * @type {number}
     */
    MinimumPasswordLength: number = 0;

    /**
     * @name AlphaCharactersRequired
     * @servertype Boolean
     * @type {boolean}
     */
    AlphaCharactersRequired: boolean = false;

    /**
     * @name NumericCharactersRequired
     * @servertype Boolean
     * @type {boolean}
     */
    NumericCharactersRequired: boolean = false;

    /**
     * @name MixedCaseRequired
     * @servertype Boolean
     * @type {boolean}
     */
    MixedCaseRequired: boolean = false;

    /**
     * @name PunctuationRequired
     * @servertype Boolean
     * @type {boolean}
     */
    PunctuationRequired: boolean = false;

    /**
     * @name PasswordCannotContainLogin
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordCannotContainLogin: boolean = false;

    /**
     * @name PasswordCannotContainName
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordCannotContainName: boolean = false;

    /**
     * @name PasswordCannotContainImportantDates
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordCannotContainImportantDates: boolean = false;

    /**
     * @name DaysBeforePasswordChangeRequired
     * @servertype Int32
     * @type {number}
     */
    DaysBeforePasswordChangeRequired: number = 0;

    /**
     * @name PreviousPasswordsToBlockRepeating
     * @servertype Int32
     * @type {number}
     */
    PreviousPasswordsToBlockRepeating: number = 0;

    /**
     * @name PreviousPasswordDaysToBlockRepeating
     * @servertype Int32
     * @type {number}
     */
    PreviousPasswordDaysToBlockRepeating: number = 0;

    /**
     * @name FailureCountUntilLoginBlocked
     * @servertype Int32
     * @type {number}
     */
    FailureCountUntilLoginBlocked: number = 0;

    /**
     * @name SecondsToBlockLogin
     * @servertype Int32
     * @type {number}
     */
    SecondsToBlockLogin: number = 0;

    /**
     * @name AuditLoginFailureBadLoginName
     * @servertype Boolean
     * @type {boolean}
     */
    AuditLoginFailureBadLoginName: boolean = false;

    /**
     * @name AuditLoginFailureBadPassword
     * @servertype Boolean
     * @type {boolean}
     */
    AuditLoginFailureBadPassword: boolean = false;

    /**
     * @name AuditLoginFailureBadMultiFactorAuthentication
     * @servertype Boolean
     * @type {boolean}
     */
    AuditLoginFailureBadMultiFactorAuthentication: boolean = false;

    /**
     * @name AuditLoginBlocked
     * @servertype Boolean
     * @type {boolean}
     */
    AuditLoginBlocked: boolean = false;

    /**
     * @name AuditPasswordChanged
     * @servertype Boolean
     * @type {boolean}
     */
    AuditPasswordChanged: boolean = false;

    /**
     * @name AuditPasswordReset
     * @servertype Boolean
     * @type {boolean}
     */
    AuditPasswordReset: boolean = false;

    /**
     * @name AuditNewPublicIpAddress
     * @servertype Boolean
     * @type {boolean}
     */
    AuditNewPublicIpAddress: boolean = false;

    /**
     * @name AuditNewDeviceId
     * @servertype Boolean
     * @type {boolean}
     */
    AuditNewDeviceId: boolean = false;

    /**
     * @name AuditSecuritySettingsChanged
     * @servertype Boolean
     * @type {boolean}
     */
    AuditSecuritySettingsChanged: boolean = false;

    /**
     * @name SecurityQuestionAnswerRequired
     * @servertype Boolean
     * @type {boolean}
     */
    SecurityQuestionAnswerRequired: boolean = false;

    /**
     * @name SecurityQuestionAnswerFreeForm
     * @servertype Boolean
     * @type {boolean}
     */
    SecurityQuestionAnswerFreeForm: boolean = false;

    /**
     * @name SecurityQuestionAnswerCount
     * @servertype Int32
     * @type {number}
     */
    SecurityQuestionAnswerCount: number = 0;

    /**
     * @name SecurityQuestions
     * @servertype string array
     * @type {string[]}
     */
    SecurityQuestions: string[] = [];

    /**
     * @name LoginRecoveryToEmailAddress
     * @servertype Boolean
     * @type {boolean}
     */
    LoginRecoveryToEmailAddress: boolean = false;

    /**
     * @name PasswordChangeRequiresSecurityQuestionAnswer
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordChangeRequiresSecurityQuestionAnswer: boolean = false;

    /**
     * @name PasswordResetRequiresSecurityQuestionAnswer
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordResetRequiresSecurityQuestionAnswer: boolean = false;

    /**
     * @name PasswordResetLinkBasedOnLoginToEmailAddress
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordResetLinkBasedOnLoginToEmailAddress: boolean = false;

    /**
     * @name PasswordResetLinkBasedOnLoginAndSecurityQuestionToEmailAddress
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordResetLinkBasedOnLoginAndSecurityQuestionToEmailAddress: boolean = false;

    /**
     * @name TokenLifetimeHours
     * @servertype Int32
     * @type {number}
     */
    TokenLifetimeHours: number = 0;

    /**
     * @name TokenSupportSilentRefresh
     * @servertype Boolean
     * @type {boolean}
     */
    TokenSupportSilentRefresh: boolean = false;

    /**
     * @name TokenRejectOnNewPublicIpAddress
     * @servertype Boolean
     * @type {boolean}
     */
    TokenRejectOnNewPublicIpAddress: boolean = false;

    /**
     * @name TokenRejectOnNewDeviceId
     * @servertype Boolean
     * @type {boolean}
     */
    TokenRejectOnNewDeviceId: boolean = false;

    /**
     * @name SupportLoginOperatingSystem
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginOperatingSystem: boolean = false;

    /**
     * @name SupportLoginFacebook
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginFacebook: boolean = false;

    /**
     * @name SupportLoginTwitter
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginTwitter: boolean = false;

    /**
     * @name SupportLoginGoogle
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginGoogle: boolean = false;

    /**
     * @name SupportLoginMicrosoft
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginMicrosoft: boolean = false;

    /**
     * @name SupportLoginApple
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginApple: boolean = false;

    /**
     * @name SupportLoginLinkedIn
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginLinkedIn: boolean = false;

    /**
     * @name SupportLoginYahoo
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginYahoo: boolean = false;

    /**
     * @name SupportLoginInstagram
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginInstagram: boolean = false;

    /**
     * @name SupportLoginDropbox
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginDropbox: boolean = false;

    /**
     * @name RequireMultiFactorAuthentication
     * @servertype Boolean
     * @type {boolean}
     */
    RequireMultiFactorAuthentication: boolean = false;

    /**
     * @name RequireMultiFactorAuthenticationForNewPublicIpAddress
     * @servertype Boolean
     * @type {boolean}
     */
    RequireMultiFactorAuthenticationForNewPublicIpAddress: boolean = false;

    /**
     * @name RequireMultiFactorAuthenticationForNewDeviceId
     * @servertype Boolean
     * @type {boolean}
     */
    RequireMultiFactorAuthenticationForNewDeviceId: boolean = false;

    /**
     * @name RequireMultiFactorAuthenticationForGroups
     * @servertype long array
     * @type {number[]}
     */
    RequireMultiFactorAuthenticationForGroups: number[] = [];

    /**
     * @name MultiFactorAuthenticationCodeLength
     * @servertype Int32
     * @type {number}
     */
    MultiFactorAuthenticationCodeLength: number = 0;

    /**
     * @name MultiFactorAuthenticationFailOpen
     * @servertype Boolean
     * @type {boolean}
     */
    MultiFactorAuthenticationFailOpen: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationEmail
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationEmail: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationSms
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationSms: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationVoice
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationVoice: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationAppTotp
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationAppTotp: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationAppDuo
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationAppDuo: boolean = false;

    /**
     * @name NotificationGroupIdNewAccountCreated
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdNewAccountCreated: number = 0;

    /**
     * @name NotificationGroupIdLoginRecovery
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdLoginRecovery: number = 0;

    /**
     * @name NotificationGroupIdPasswordChanged
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdPasswordChanged: number = 0;

    /**
     * @name NotificationGroupIdPasswordReset
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdPasswordReset: number = 0;

    /**
     * @name NotificationGroupIdPasswordResetLink
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdPasswordResetLink: number = 0;

    /**
     * @name NotificationGroupIdMultiFactorAuthenticationCode
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdMultiFactorAuthenticationCode: number = 0;

    /**
     * @name NotificationGroupIdNewMultiFactorAuthenticationMethod
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdNewMultiFactorAuthenticationMethod: number = 0;

    /**
     * @name NotificationGroupIdNewPublicIpAddress
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdNewPublicIpAddress: number = 0;

    /**
     * @name NotificationGroupIdNewDeviceId
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdNewDeviceId: number = 0;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Password Reset Link Request
 */
export class PasswordResetLinkRequestViewModel {

    /**
     * @name Login
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name BaseUrl
     * @servertype String
     * @type {string}
     */
    BaseUrl: string = "";

    /**
     * @name QueryStringParameterName
     * @servertype String
     * @type {string}
     */
    QueryStringParameterName: string = "";

    /**
     * @name ValidMinutes
     * @servertype Int32
     * @type {number}
     */
    ValidMinutes: number = 0;

    /**
     * @name ValidCount
     * @servertype Int32
     * @type {number}
     */
    ValidCount: number = 0;

}



/**
 * @name Login Request
 */
export class LoginRequest {

    /**
     * @name Login
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Password
     * @servertype String
     * @type {string}
     */
    Password: string = "";

    /**
     * @name RememberMe
     * @servertype Boolean
     * @type {boolean}
     */
    RememberMe: boolean = false;

}



/**
 * @name Authenticated User
 */
export class AuthenticatedUserViewModel {

    /**
     * @name ContactType
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactFirstName
     * @servertype String
     * @type {string}
     */
    ContactFirstName: string = "";

    /**
     * @name ContactLastName
     * @servertype String
     * @type {string}
     */
    ContactLastName: string = "";

    /**
     * @name ContactEmail
     * @servertype String
     * @type {string}
     */
    ContactEmail: string = "";

    /**
     * @name PreferredLanguage
     * @servertype String
     * @type {string}
     */
    PreferredLanguage: string = "";

    /**
     * @name Flags
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name ParentContactType
     * @servertype String
     * @type {string}
     */
    ParentContactType: string = "";

    /**
     * @name ParentContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentContactId: number = null;

    /**
     * @name ParentContactName
     * @servertype String
     * @type {string}
     */
    ParentContactName: string = "";

    /**
     * @name PartitionId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name PartitionDescription
     * @servertype String
     * @type {string}
     */
    PartitionDescription: string = "";

    /**
     * @name Login
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Token
     * @servertype String
     * @type {string}
     */
    Token: string = "";

    /**
     * @name ApiKey
     * @servertype String
     * @type {string}
     */
    ApiKey: string = "";

    /**
     * @name PartnerTokens
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    PartnerTokens: {[key: string]:  string} = {};

    /**
     * @name MultiFactorAuthenticationTarget
     * @servertype IB.Web.API.Models.v5.AuthenticatedUserMultiFactorAuthenticationOptionViewModel
     * @type {AuthenticatedUserMultiFactorAuthenticationOptionViewModel}
     */
    MultiFactorAuthenticationTarget: AuthenticatedUserMultiFactorAuthenticationOptionViewModel = null;

    /**
     * @name MultiFactorAuthenticationOptions
     * @servertype AuthenticatedUserMultiFactorAuthenticationOptionViewModel array
     * @type {AuthenticatedUserMultiFactorAuthenticationOptionViewModel[]}
     */
    MultiFactorAuthenticationOptions: AuthenticatedUserMultiFactorAuthenticationOptionViewModel[] = [];

    /**
     * @name MultiFactorAuthenticationSupportsAppTotp
     * @servertype Boolean
     * @type {boolean}
     */
    MultiFactorAuthenticationSupportsAppTotp: boolean = false;

    /**
     * @name Settings
     * @servertype IB.Web.API.Models.v5.UserSettings
     * @type {UserSettings}
     */
    Settings: UserSettings = null;

    /**
     * @name Groups
     * @servertype AuthenticatedUserGroupViewModel array
     * @type {AuthenticatedUserGroupViewModel[]}
     */
    Groups: AuthenticatedUserGroupViewModel[] = [];

    /**
     * @name Roles
     * @servertype AuthenticatedUserRoleViewModel array
     * @type {AuthenticatedUserRoleViewModel[]}
     */
    Roles: AuthenticatedUserRoleViewModel[] = [];

    /**
     * @name Permissions
     * @servertype AuthenticatedUserPermissionViewModel array
     * @type {AuthenticatedUserPermissionViewModel[]}
     */
    Permissions: AuthenticatedUserPermissionViewModel[] = [];

    /**
     * @name AuthenticationData
     * @servertype IB.Web.API.Models.AuthenticationData
     * @type {m.AuthenticationData}
     */
    AuthenticationData: m.AuthenticationData = null;

    /**
     * @name AsOfUtc
     * @servertype DateTime
     * @type {Date}
     */
    AsOfUtc: Date = null;

}



/**
 * @name Authenticated User Multi-Factor Authentication Option
 */
export class AuthenticatedUserMultiFactorAuthenticationOptionViewModel {

    /**
     * @name Method
     * @servertype String
     * @type {string}
     */
    Method: string = "";

    /**
     * @name Target
     * @servertype String
     * @type {string}
     */
    Target: string = "";

    /**
     * @name Source
     * @servertype String
     * @type {string}
     */
    Source: string = "";

    /**
     * @name DefaultOption
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultOption: boolean = false;

}



/**
 * @name User Settings
 */
export class UserSettings {

    /**
     * @name ApplicationSecurityPreferences
     * @servertype IB.Web.API.Models.v5.UserSettingsApplicationSecurityPreferences
     * @type {UserSettingsApplicationSecurityPreferences}
     */
    ApplicationSecurityPreferences: UserSettingsApplicationSecurityPreferences = null;

    /**
     * @name AsOfUtc
     * @servertype DateTime
     * @type {Date}
     */
    AsOfUtc: Date = null;

}



/**
 * @name User Settings Application Security Preferences
 */
export class UserSettingsApplicationSecurityPreferences {

    /**
     * @name ExternalErrorTracking
     * @servertype String
     * @type {string}
     */
    ExternalErrorTracking: string = "";

    /**
     * @name ExternalAnalyticsForUsage
     * @servertype String
     * @type {string}
     */
    ExternalAnalyticsForUsage: string = "";

    /**
     * @name ExternalAnalyticsForScreenCapture
     * @servertype String
     * @type {string}
     */
    ExternalAnalyticsForScreenCapture: string = "";

}



/**
 * @name Authenticated User Group
 */
export class AuthenticatedUserGroupViewModel {

    /**
     * @name GroupId
     * @servertype Int64
     * @type {number}
     */
    GroupId: number = 0;

    /**
     * @name GroupDescription
     * @servertype String
     * @type {string}
     */
    GroupDescription: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Status
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name GroupOwner
     * @servertype Boolean
     * @type {boolean}
     */
    GroupOwner: boolean = false;

    /**
     * @name CanImpersonateGroup
     * @servertype Boolean
     * @type {boolean}
     */
    CanImpersonateGroup: boolean = false;

    /**
     * @name ContactGroupMembershipId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactGroupMembershipId: number = 0;

    /**
     * @name Reasons
     * @servertype AuthenticatedUserAssignmentReason array
     * @type {AuthenticatedUserAssignmentReason[]}
     */
    Reasons: AuthenticatedUserAssignmentReason[] = [];

}



/**
 * @name AuthenticatedUserAssignmentReason
 */
export class AuthenticatedUserAssignmentReason {

    /**
     * @name Reason
     * @servertype IB.Web.API.Models.v5.AuthenticatedUserReasonHasAssignment Enum
     * @type {AuthenticatedUserReasonHasAssignment}
     */
    Reason: AuthenticatedUserReasonHasAssignment = null;

    /**
     * @name ReasonDescription
     * @servertype String
     * @type {string}
     */
    ReasonDescription: string = "";

    /**
     * @name ReasonContactId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReasonContactId: number = null;

}



/**
 * @name AuthenticatedUserReasonHasAssignment Enum
 * @readonly
 * @enum {number}
 */
export enum AuthenticatedUserReasonHasAssignment {

    /**
     * Unknown
     */
    Unknown = 0,

    /**
     * Assigned To Contact
     */
    AssignedToContact = 1,

    /**
     * Assigned To Group Contact Belongs To
     */
    AssignedToGroupContactBelongsTo = 2,

    /**
     * Assigned To Contact Type Automatically
     */
    AssignedToContactTypeAutomatically = 3

}



/**
 * @name Authenticated User Role
 */
export class AuthenticatedUserRoleViewModel {

    /**
     * @name RoleId
     * @servertype Int64
     * @type {number}
     */
    RoleId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RoleRatio
     * @servertype Int32
     * @type {number}
     */
    RoleRatio: number = 0;

    /**
     * @name Reasons
     * @servertype AuthenticatedUserAssignmentReason array
     * @type {AuthenticatedUserAssignmentReason[]}
     */
    Reasons: AuthenticatedUserAssignmentReason[] = [];

}



/**
 * @name Authenticated User Permission
 */
export class AuthenticatedUserPermissionViewModel {

    /**
     * @name AreaType
     * @servertype String
     * @type {string}
     */
    AreaType: string = "";

    /**
     * @name Area
     * @servertype String
     * @type {string}
     */
    Area: string = "";

    /**
     * @name Rights
     * @servertype String
     * @type {string}
     */
    Rights: string = "";

    /**
     * @name Roles
     * @servertype AuthenticatedUserRoleViewModel array
     * @type {AuthenticatedUserRoleViewModel[]}
     */
    Roles: AuthenticatedUserRoleViewModel[] = [];

    /**
     * @name ContributingRoles
     * @servertype String
     * @type {string}
     */
    ContributingRoles: string = "";

    /**
     * @name ImplicitAccess
     * @servertype Boolean
     * @type {boolean}
     */
    ImplicitAccess: boolean = false;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

}



/**
 * @name Login Recovery Request
 */
export class LoginRecoveryRequestViewModel {

    /**
     * @name Email
     * @servertype String
     * @type {string}
     */
    Email: string = "";

}



/**
 * @name Login Change Request
 */
export class LoginChangeRequest {

    /**
     * @name ContactId
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name CurrentLogin
     * @servertype String
     * @type {string}
     */
    CurrentLogin: string = "";

    /**
     * @name NewLogin
     * @servertype String
     * @type {string}
     */
    NewLogin: string = "";

}



/**
 * @name Password Change Request
 */
export class PasswordChangeRequest {

    /**
     * @name Login
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name CurrentPassword
     * @servertype String
     * @type {string}
     */
    CurrentPassword: string = "";

    /**
     * @name NewPassword
     * @servertype String
     * @type {string}
     */
    NewPassword: string = "";

    /**
     * @name NewPasswordConfirmed
     * @servertype String
     * @type {string}
     */
    NewPasswordConfirmed: string = "";

    /**
     * @name ClientInformation
     * @servertype String
     * @type {string}
     */
    ClientInformation: string = "";

    /**
     * @name ForcedReset
     * @servertype Boolean
     * @type {boolean}
     */
    ForcedReset: boolean = false;

}



/**
 * @name Password View Request
 */
export class PasswordViewRequest {

    /**
     * @name Login
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name ClientInformation
     * @servertype String
     * @type {string}
     */
    ClientInformation: string = "";

}



/**
 * @name PermissionEditViewModel
 */
export class PermissionEditViewModel {

    /**
     * @name SecurityArea
     * @servertype String
     * @type {string}
     */
    SecurityArea: string = "";

    /**
     * @name ReadSingle
     * @servertype Boolean
     * @type {boolean}
     */
    ReadSingle: boolean = false;

    /**
     * @name Read
     * @servertype Boolean
     * @type {boolean}
     */
    Read: boolean = false;

    /**
     * @name Add
     * @servertype Boolean
     * @type {boolean}
     */
    Add: boolean = false;

    /**
     * @name Edit
     * @servertype Boolean
     * @type {boolean}
     */
    Edit: boolean = false;

    /**
     * @name Delete
     * @servertype Boolean
     * @type {boolean}
     */
    Delete: boolean = false;

    /**
     * @name Output
     * @servertype Boolean
     * @type {boolean}
     */
    Output: boolean = false;

    /**
     * @name Execute
     * @servertype Boolean
     * @type {boolean}
     */
    Execute: boolean = false;

    /**
     * @name Full
     * @servertype Boolean
     * @type {boolean}
     */
    Full: boolean = false;

}



/**
 * @name RoleDetailEditViewModel
 * @description
 * The Role Detail table contains information about security access areas and rights for the specified role.
 */
export class RoleDetailEditViewModel {

    /**
     * @name RoleDetailId
     * @description 
     * The id of the Role Detail.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleDetailId: number = null;

    /**
     * @name RoleId
     * @description 
     * The id of the role this detail belongs to.
     * @servertype Int64
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name SecurityAreaType
     * @description 
     * This is the security area type.  Possible values include: 
     * TB = Table
     * TG = Table Group
     * RP = Report
     * RG = Report Group
     * PA = Permission Area
     * SI = Sensitive Information
     * @servertype String
     * @type {string}
     */
    SecurityAreaType: string = "PA";

    /**
     * @name SecurityArea
     * @description 
     * The security area that is part of this role.  This is based on the security area type and could be a table name, table group,
     * report, report group or permission area (standard or custom).  For security area type of sensitive information this is a
     * category of sensitive information as defined in a pick list and used in the data table support object's sensitive information
     * handler data.
     * @servertype String
     * @type {string}
     */
    SecurityArea: string = "";

    /**
     * @name AllowReadSingle
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowReadSingle: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowRead
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowRead: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowAdd
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowAdd: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowEdit
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowEdit: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowDelete
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowDelete: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowOutput
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowOutput: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowExecute
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowExecute: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowFull
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowFull: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyReadSingle
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyReadSingle: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyRead
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyRead: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyAdd
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyAdd: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyEdit
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyEdit: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyDelete
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyDelete: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyOutput
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyOutput: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyExecute
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyExecute: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyFull
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyFull: RoleDetailPermissionEditViewModel = null;

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name RoleDetailPermissionEditViewModel
 */
export class RoleDetailPermissionEditViewModel {

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ScopeNone
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeNone: boolean = false;

    /**
     * @name ScopeOwner
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeOwner: boolean = false;

    /**
     * @name ScopeSupervisor
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeSupervisor: boolean = false;

    /**
     * @name ScopeNexus
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeNexus: boolean = false;

    /**
     * @name ScopeGroup
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeGroup: boolean = false;

    /**
     * @name ScopeChildGroup
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeChildGroup: boolean = false;

    /**
     * @name ScopeGroupMembers
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeGroupMembers: boolean = false;

    /**
     * @name ScopeChildGroupMembers
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeChildGroupMembers: boolean = false;

}



/**
 * @name RoleListViewModel
 * @description
 * The Role table contains information about roles.  Roles are used to manage security credentials.
 */
export class RoleListViewModel {

    /**
     * @name RoleId
     * @description 
     * The id of the Role.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name ExternalRoleId
     * @description 
     * An optional external role id for this role.
     * @servertype String
     * @type {string}
     */
    ExternalRoleId: string = "";

    /**
     * @name Description
     * @description 
     * The description for this role.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the role is enabled.  Setting to false provides an easy way to cause a role to be temporarily ignored until later
     * enabled again.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Anonymous
     * @description 
     * When true the role is used to define access for anonymous users.
     * @servertype Boolean
     * @type {boolean}
     */
    Anonymous: boolean = false;

    /**
     * @name ValidForCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForCustomer: boolean = false;

    /**
     * @name ValidForAgent
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForAgent: boolean = false;

    /**
     * @name ValidForVendor
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForVendor: boolean = false;

    /**
     * @name ValidForDirectory
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForDirectory: boolean = false;

    /**
     * @name ValidForGroup
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForGroup: boolean = false;

    /**
     * @name ValidForServiceProvider
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForServiceProvider: boolean = false;

    /**
     * @name AutomaticForCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForCustomer: boolean = false;

    /**
     * @name AutomaticForAgent
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForAgent: boolean = false;

    /**
     * @name AutomaticForVendor
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForVendor: boolean = false;

    /**
     * @name AutomaticForDirectory
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForDirectory: boolean = false;

    /**
     * @name AutomaticForGroup
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForGroup: boolean = false;

    /**
     * @name AutomaticForServiceProvider
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForServiceProvider: boolean = false;

}



/**
 * @name RoleEditViewModel
 * @description
 * The Role table contains information about roles.  Roles are used to manage security credentials.
 */
export class RoleEditViewModel {

    /**
     * @name RoleId
     * @description 
     * The id of the Role.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name ExternalRoleId
     * @description 
     * An optional external role id for this role.
     * @servertype String
     * @type {string}
     */
    ExternalRoleId: string = "";

    /**
     * @name Description
     * @description 
     * The description for this role.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description 
     * When true the role is enabled.  Setting to false provides an easy way to cause a role to be temporarily ignored until later
     * enabled again.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Anonymous
     * @description 
     * When true the role is used to define access for anonymous users.
     * @servertype Boolean
     * @type {boolean}
     */
    Anonymous: boolean = false;

    /**
     * @name ValidForCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForCustomer: boolean = false;

    /**
     * @name ValidForAgent
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForAgent: boolean = false;

    /**
     * @name ValidForVendor
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForVendor: boolean = false;

    /**
     * @name ValidForDirectory
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForDirectory: boolean = false;

    /**
     * @name ValidForGroup
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForGroup: boolean = false;

    /**
     * @name ValidForServiceProvider
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForServiceProvider: boolean = false;

    /**
     * @name AutomaticForCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForCustomer: boolean = false;

    /**
     * @name AutomaticForAgent
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForAgent: boolean = false;

    /**
     * @name AutomaticForVendor
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForVendor: boolean = false;

    /**
     * @name AutomaticForDirectory
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForDirectory: boolean = false;

    /**
     * @name AutomaticForGroup
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForGroup: boolean = false;

    /**
     * @name AutomaticForServiceProvider
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForServiceProvider: boolean = false;

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Details
     * @servertype RoleDetailEditViewModel array
     * @type {RoleDetailEditViewModel[]}
     */
    Details: RoleDetailEditViewModel[] = [];

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



