import { Component } from '@angular/core';
import { NavService } from 'projects/common-lib/src/lib/ux/nav/nav.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  // Trying to figure out why but it appears without injecting this here our api calls never actually get submitted via http client!?!?!?!?!?!
  // Nothing in the constructor seems meaningful beyond what we do in the AppService constructor but nonetheless it seems like NavService
  // does something we have not yet tracked down.
  constructor(public apiService: ApiService, public appService: AppService, public nav: NavService) {
  }

}
