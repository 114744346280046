// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';
import * as m5core from './ngModelsCore5';


/**
 * @name TelecomLocationCustomEditViewModel
 * @description
 * The Telecom Location Custom table contains information related to custom locations.  See the location standard table for
 * standard locations.
 */
export class TelecomLocationCustomEditViewModel {

    /**
     * @name TelecomLocationCustomId
     * @description 
     * Telecom Location Custom Id uniquely identifies this location.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TelecomLocationCustomId: number = null;

    /**
     * @name OwnerResourceType
     * @description 
     * The owner resource type for this location.  Possible values include: 
     * _ = System
     * C = Contact
     * L = Location Profile
     * R = Rating Profile
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description 
     * The owner resource id is the key of the owner resource type for this location (e.g. contact, location profile, or rating
     * profile id).
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name Location
     * @description 
     * The location is a combination of Country Code, NPA, NXX, and subscriber without any separators between.  For example, Troy
     * MI USA could be represented as 1248583 where 1 is the country code, 248 is the NPA, and 583 is the NXX.
     * @servertype String
     * @type {string}
     */
    Location: string = "";

    /**
     * @name LocationEndRange
     * @description 
     * Ending value of range of locations starting with Location when there is a range.  If no range is involved this is empty.
     * @servertype String
     * @type {string}
     */
    LocationEndRange: string = "";

    /**
     * @name CountryCode
     * @description 
     * Country Code portion of location.
     * @servertype String
     * @type {string}
     */
    CountryCode: string = "";

    /**
     * @name Npa
     * @description 
     * NPA portion of location.
     * @servertype String
     * @type {string}
     */
    Npa: string = "";

    /**
     * @name Nxx
     * @description 
     * NXX portion of location.
     * @servertype String
     * @type {string}
     */
    Nxx: string = "";

    /**
     * @name Subscriber
     * @description 
     * Subscriber portion of location.
     * @servertype String
     * @type {string}
     */
    Subscriber: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name Country
     * @description 
     * Country Name.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name IsoCountryCode2
     * @description 
     * The two letter country code from ISO 3166 standard.
     * @servertype String
     * @type {string}
     */
    IsoCountryCode2: string = "";

    /**
     * @name IsoCountryCode3
     * @description 
     * The three letter country code from ISO 3166 standard.
     * @servertype String
     * @type {string}
     */
    IsoCountryCode3: string = "";

    /**
     * @name IsoCountryCodeNumeric3
     * @description 
     * The three digit country code from ISO 3166 standard.
     * @servertype String
     * @type {string}
     */
    IsoCountryCodeNumeric3: string = "";

    /**
     * @name LocationType
     * @description 
     * Location type identifier for this location.  Possible values include:
     * STD = Standard
     * MOB = Mobile
     * PRC = Premium Cost
     * FRC = Free Cost
     * DSC = Discount Cost
     * SHC = Shared Cost
     * ENH = Enhanced
     * INC = Incomplete
     * VPN = VPN
     * PAG = Pager
     * CSC = Carrier Selection
     * FOL = Follow-Me
     * PAY = Payphone
     * AUD = Audiotext
     * VID = Videotext
     * TST = Test
     * VOM = Voice Mail
     * SAT = Satellite
     * ISP = ISP
     * SIP = SIP
     * @servertype String
     * @type {string}
     */
    LocationType: string = "";

    /**
     * @name UsageType
     * @description 
     * The usage type.  Possible values that can include:
     * LO = Voice - Local
     * IT = Voice - IntraLATA
     * IS = Voice - Intrastate
     * LD = Voice - Interstate
     * IN = Voice - International
     * FR = Voice - Free
     * EI = Voice - Extension/Internal
     * MO = Voice - Mobile
     * EX = Voice - Exception
     * UN = Voice - Unknown
     * EM = Voice - Emergency
     * OP = Voice - Operator
     * IO = Voice - International Operator
     * LI = Voice - Local Information
     * OI = Voice - Other Information (i.e. long distance)
     * DW = Data - Web
     * DE = Data - Email
     * DF = Data - Ftp
     * DN = Data - Nntp
     * DI = Data - IM
     * DS = Data - SMS
     * DM = Data - MMS
     * DA = Data - Analytics
     * DU = Data - Usage
     * DO = Data - Other
     * VC = Video - Conference
     * VM = Video - Movie
     * VT = Video - TV
     * VO = Video - Other
     * CA = Content - Application
     * CG = Content - Gaming
     * CD = Content - Download
     * CP = Content - Pay Per View/Use
     * CM = Content - Media
     * CS = Content - Services
     * CO = Content - Other
     * @servertype String
     * @type {string}
     */
    UsageType: string = "";

    /**
     * @name Vertical
     * @description 
     * Vertical Coordinate.  Can be Vertical portion of V&H coordinate or Longitude.
     * @servertype Double (nullable)
     * @type {number}
     */
    Vertical: number = 0;

    /**
     * @name Horizontal
     * @description 
     * Horizontal Coordinate.  Can be Horizontal portion of V&H coordinate or Latitude.
     * @servertype Double (nullable)
     * @type {number}
     */
    Horizontal: number = 0;

    /**
     * @name Lata
     * @description 
     * LATA value for this location.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Lata: number = 0;

    /**
     * @name LocationIdentifierCode
     * @description 
     * Location Identifier Code (e.g. CLLI) for this location.
     * @servertype String
     * @type {string}
     */
    LocationIdentifierCode: string = "";

    /**
     * @name OperatingCompanyNumber
     * @description 
     * Operating Company Number (OCN) for the CC/NPA/NXX.  Sometimes referred to as Common Carrier Code (CCC).
     * @servertype String
     * @type {string}
     */
    OperatingCompanyNumber: string = "";

    /**
     * @name Tier
     * @description 
     * Tier number to use for this location.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Tier: number = 0;

    /**
     * @name Geocode
     * @description 
     * Geographic code representing a tax jurisdiction.  The format depends on design of the tax system data but should use hyphen
     * delimiters and contain enough information to uniquely identify a jurisdiction.  So taxes applicable in larger jurisdictions
     * can be found using as few geocodes as needed they should start with the larger geographic identifier and work down to smaller
     * geographies (e.g. US, US-MI, US-MI-Oakland, US-MI-Oakland-Troy, etc.).  The geocode is combined with other information provided
     * for in the tax matrix table to fully qualify taxing jurisdictions and which items and scenarios are taxable in those jurisdictions.
     * @servertype String
     * @type {string}
     */
    Geocode: string = "";

    /**
     * @name PostalCode
     * @description 
     * Postal Code.  When available may represent the postal code of the central office.
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name TimeZoneId
     * @description 
     * Contains either a time zone id or a specific UTC offset for this location.  If this contains a UTC offset it will be in the
     * format +99:99 where + could be a + or - and 9 represents any digit from 0-9.
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = null;

    /**
     * @name NpaLength
     * @description 
     * NPA length.  Used in situations where NPA is not specified but length is known.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NpaLength: number = 0;

    /**
     * @name NxxLength
     * @description 
     * NXX length.  Used in situations where NXX is not specified but length is known.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NxxLength: number = 0;

    /**
     * @name Distance
     * @description 
     * Distance from a fixed point.  Typically only useful in enterprise situations where origination point is known and fixed.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Distance: number = 0;

    /**
     * @name DistanceType
     * @description 
     * Type of measurement represented by distance.  Possible values include:
     * M = Miles
     * K = KM
     * @servertype String
     * @type {string}
     */
    DistanceType: string = "K";

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this custom location is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this custom location is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name DataStatus
     * @description 
     * Data status used for data import control purposes.  Possible values include:
     * A = Active
     * I = Inactive
     * When doing data import existing rows have status set to inactive.  Then as data is added or updated the status is set to
     * active.  After mass updates if inactive records are no longer desired they can be deleted or have the valid date range adjusted.
     * @servertype String
     * @type {string}
     */
    DataStatus: string = "A";

    /**
     * @name Properties
     * @description 
     * Properties.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TelecomLocationGroupLinkEditViewModel
 * @description
 * The Telecom Location Group Link table contains list of target groups that are related to a source group.  This is used for
 * grouping locations for local calling areas, etc.  This is typically populated with data from Telcordia, Tele-Tech Services,
 * or some other data provider and used in conjunction with the Location Group List table.
 */
export class TelecomLocationGroupLinkEditViewModel {

    /**
     * @name TelecomLocationGroupLinkId
     * @description 
     * Telecom Location Group Link Id uniquely identifies this location group link.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TelecomLocationGroupLinkId: number = null;

    /**
     * @name SourceGroupNumber
     * @description 
     * The source group number.  When using Telcordia or similar data this value is compared to location group list group number
     * when looking for a source location in location group list.  When using Tele-Tech Services or similar data this value is the
     * source location.
     * @servertype Int64
     * @type {number}
     */
    SourceGroupNumber: number = null;

    /**
     * @name CallingPlan
     * @description 
     * The calling plan number for this source to target group lookup.  When using Telcordia or similar data see the location group
     * list table calling plan for business and residential columns.  When using Tele-Tech Services or similar data this value is
     * 0.
     * @servertype Int64
     * @type {number}
     */
    CallingPlan: number = null;

    /**
     * @name TargetGroupNumber
     * @description 
     * The target group number.  When using Telcordia or similar data this group is in relation to the source group number and calling
     * plan and is compared to location group list group number when looking for a target location in location group list.  When
     * using Tele-Tech Services or similar data this value is the target location.
     * @servertype Int64
     * @type {number}
     */
    TargetGroupNumber: number = null;

    /**
     * @name UsageType
     * @description 
     * The usage type for the source and target location combination.  Possible values that can include:
     * LO = Voice - Local
     * IT = Voice - IntraLATA
     * IS = Voice - Intrastate
     * LD = Voice - Interstate
     * IN = Voice - International
     * FR = Voice - Free
     * EI = Voice - Extension/Internal
     * MO = Voice - Mobile
     * EX = Voice - Exception
     * UN = Voice - Unknown
     * EM = Voice - Emergency
     * OP = Voice - Operator
     * IO = Voice - International Operator
     * LI = Voice - Local Information
     * OI = Voice - Other Information (i.e. long distance)
     * DW = Data - Web
     * DE = Data - Email
     * DF = Data - Ftp
     * DN = Data - Nntp
     * DI = Data - IM
     * DS = Data - SMS
     * DM = Data - MMS
     * DA = Data - Analytics
     * DU = Data - Usage
     * DO = Data - Other
     * VC = Video - Conference
     * VM = Video - Movie
     * VT = Video - TV
     * VO = Video - Other
     * CA = Content - Application
     * CG = Content - Gaming
     * CD = Content - Download
     * CP = Content - Pay Per View/Use
     * CM = Content - Media
     * CS = Content - Services
     * CO = Content - Other
     * @servertype String
     * @type {string}
     */
    UsageType: string = "";

    /**
     * @name ServiceType
     * @description 
     * The service type(s) for this group relationship.  Possible values include:
     * F = Free (indicates if the call is free)
     * R = Flat Rate (local calls free)
     * P = Per Call (local calls billed at per call rate)
     * X = Mixed (some local calls free, others charged, check for value F)
     * M = Measured (local calls billed a per minute rate)
     * U = Unknown (service type is unknown)
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name ServiceArea
     * @description 
     * The service area for this group relationship.  Possible values include:
     * B = Basic Local Calling Plan
     * M = Mandatory Expanded Local Calling Plan
     * O = Optional Expanded Local Calling Plan
     * U = Unknown
     * @servertype String
     * @type {string}
     */
    ServiceArea: string = "U";

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this location group can be used.  This can be used
     * to exclude location groups that have not reached their effective start date.  Accurate location identification requires that
     * changes in locations be handled by creating new location entries and using the valid date range to dictate when they are
     * valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this location group entry can be used.  This can be
     * used to exclude locations groups that expire on a certain date.  Accurate location identification requires that changes in
     * locations be handled by creating new location entries and using the valid date range to dictate when they are valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name DataStatus
     * @description 
     * Data status used for data import control purposes.  Possible values include:
     * A = Active
     * I = Inactive
     * When doing data import existing rows have status set to inactive.  Then as data is added or updated the status is set to
     * active.  After mass updates if inactive records are no longer desired they can be deleted or expired via the valid end date
     * time.  Note that inactive data is still used by the location engine until deleted or expired via the valid end date time.
     *  Accurate location identification requires that changes in locations be handled by creating new location group entries and
     * using the valid date range to dictate when location groups are valid.
     * @servertype String
     * @type {string}
     */
    DataStatus: string = "A";

    /**
     * @name Properties
     * @description 
     * Properties.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name TelecomLocationGroupListEditViewModel
 * @description
 * The Telecom Location Group List table contains a  list of locations and the groups they belong to.  This is used for grouping
 * locations for local calling areas, etc.  This is typically populated with data from Telcordia or some other data provider
 * and used in conjunction with the Location Group Link table.
 */
export class TelecomLocationGroupListEditViewModel {

    /**
     * @name TelecomLocationGroupListId
     * @description 
     * Telecom Location Group List Id uniquely identifies this location group list.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TelecomLocationGroupListId: number = null;

    /**
     * @name Location
     * @description 
     * The location is a combination of Country Code, NPA, NXX, and subscriber without any separators between.  For example, Troy
     * MI USA could be represented as 1248583 where 1 is the country code, 248 is the NPA, and 583 is the NXX.  This represents
     * either the source and/or target location depending on how group number compares to location group link source group number
     * and/or target group number.
     * @servertype String
     * @type {string}
     */
    Location: string = "";

    /**
     * @name Lata
     * @description 
     * LATA value for this location.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Lata: number = 0;

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name GroupNumber
     * @description 
     * The group number for this location and type.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    GroupNumber: number = 0;

    /**
     * @name CallingPlanBusiness
     * @description 
     * The business calling plan number for this location.  This is compared against location group link calling plan to find the
     * appropriate match.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CallingPlanBusiness: number = 0;

    /**
     * @name CallingPlanResidential
     * @description 
     * The residential calling plan number for this location.  This is compared against location group link calling plan to find
     * the appropriate match.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CallingPlanResidential: number = 0;

    /**
     * @name ValidStartDateTime
     * @description 
     * The valid start date and time represents the beginning date and time when this location group can be used.  This can be used
     * to exclude location groups that have not reached their effective start date.  Accurate location identification requires that
     * changes in locations be handled by creating new location entries and using the valid date range to dictate when they are
     * valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description 
     * The valid end date and time represents the ending date and time when this location group entry can be used.  This can be
     * used to exclude locations groups that expire on a certain date.  Accurate location identification requires that changes in
     * locations be handled by creating new location entries and using the valid date range to dictate when they are valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name DataStatus
     * @description 
     * Data status used for data import control purposes.  Possible values include:
     * A = Active
     * I = Inactive
     * When doing data import existing rows have status set to inactive.  Then as data is added or updated the status is set to
     * active.  After mass updates if inactive records are no longer desired they can be deleted or expired via the valid end date
     * time.  Note that inactive data is still used by the location engine until deleted or expired via the valid end date time.
     *  Accurate location identification requires that changes in locations be handled by creating new location group entries and
     * using the valid date range to dictate when location groups are valid.
     * @servertype String
     * @type {string}
     */
    DataStatus: string = "A";

    /**
     * @name Properties
     * @description 
     * Properties.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name TelecomLocationProfileEditViewModel
 * @description
 * The Telecom Location Profile table contains information for location profiles including settings that help determine if a
 * call is local, intralata, intrastate, etc.
 */
export class TelecomLocationProfileEditViewModel {

    /**
     * @name TelecomLocationProfileId
     * @description 
     * Telecom Location Profile Id uniquely identifies this location profile.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TelecomLocationProfileId: number = null;

    /**
     * @name ExternalTelecomLocationProfileId
     * @description 
     * Telecom Location Profile Id used in external systems or as an alternate means of identification.
     * @servertype String
     * @type {string}
     */
    ExternalTelecomLocationProfileId: string = "";

    /**
     * @name Description
     * @description 
     * Location Profile Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name VisibilityCostCenterType
     * @description 
     * A list of cost center types that this location profile is valid for.  Possible values include:
     * R1 = Retail 1
     * R2 = Retail 2
     * A1 = Agent 1
     * A2 = Agent 2
     * W1 = Wholesale 1
     * W2 = Wholesale 2
     * W3 = Wholesale 3
     * T1 = Alternate 1
     * @servertype string array
     * @type {string[]}
     */
    VisibilityCostCenterType: string[] = [];

    /**
     * @name VisibilityListType
     * @description 
     * A list of visibility list types that this location profile is valid for.  Possible values include:
     * PC = Postal Code
     * ST = State
     * CC = Country Code
     * CN = Country Code + NPA
     * CX = Country Code + NPA + NXX
     * LA = LATA
     * @servertype string array
     * @type {string[]}
     */
    VisibilityListType: string[] = [];

    /**
     * @name VisibilityAccountType
     * @description 
     * Account type that this item is valid for.  Empty indicates valid for all account types.
     * @servertype String
     * @type {string}
     */
    VisibilityAccountType: string = "";

    /**
     * @name VisibilityAccountClass
     * @description 
     * Account class that this item is valid for.  Empty indicates valid for all account classes.
     * @servertype String
     * @type {string}
     */
    VisibilityAccountClass: string = "";

    /**
     * @name VisibilityMarketType
     * @description 
     * Market type that this item is valid for.  Empty indicates valid for all market types.
     * @servertype String
     * @type {string}
     */
    VisibilityMarketType: string = "";

    /**
     * @name VisibilityContactId
     * @description 
     * A Contact Id that represents the only contact that this item is valid for.  Null indicates valid for all contacts.  If this
     * contact is an agent the item is valid for all customers assigned to this agent.  If this contact is a primary customer the
     * item is valid for all secondary customers assigned to this primary customer.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    VisibilityContactId: number = null;

    /**
     * @name VisibilityContactName
     * @servertype String
     * @type {string}
     */
    VisibilityContactName: string = "";

    /**
     * @name DetermineCallTypeFromAni
     * @description 
     * When true use ANI to help determine call type instead of cost center settings (i.e. local npa, intraLATA LATA, etc.)
     * @servertype Boolean
     * @type {boolean}
     */
    DetermineCallTypeFromAni: boolean = false;

    /**
     * @name TierMatchUsageType
     * @description 
     * The usage type to use when cost center (or ani) location tier number matches the target location tier number.  Possible values
     * can include:
     *    = No usage type is assigned based on tier numbers matching.
     * LO = Local
     * IT = IntraLATA
     * IS = IntraState
     * @servertype String
     * @type {string}
     */
    TierMatchUsageType: string = "";

    /**
     * @name TierNumberUse
     * @description 
     * Dictates how local, intraLATA, and intrastate tier are used by the location profile.  Possible values include: 
     * C = Compare target location tier number with tier value to determine if usage is local, intralata, or intrastate.
     * A = Assign tier value to activity object to assist with rating based on if the usage is local, intralata, or intrastate.
     * (default behavior)
     * @servertype String
     * @type {string}
     */
    TierNumberUse: string = "A";

    /**
     * @name LocalUseLocationGroup
     * @description 
     * Use location group to help determine if call is local based on published local calling areas.  This triggers use of the location
     * group tables using data from Telcordia, Tele-Tech Services, etc.  Possible values include:
     * N = No
     * B = Yes with Business Calling Plan
     * R = Yes with Residential Calling Plan
     * S = Yes with Simple Calling Plan
     * @servertype String
     * @type {string}
     */
    LocalUseLocationGroup: string = "N";

    /**
     * @name LocalNpa
     * @description 
     * Comma delimited list of NPAs in the local area.
     * @servertype String
     * @type {string}
     */
    LocalNpa: string = "";

    /**
     * @name LocalDistance
     * @description 
     * All calls within this threshold should be considered within the local area.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LocalDistance: number = 0;

    /**
     * @name LocalDistanceType
     * @description 
     * Type of measurement represented by local distance.  Possible values include:
     * M = Miles
     * K = KM
     * @servertype String
     * @type {string}
     */
    LocalDistanceType: string = "K";

    /**
     * @name LocalOnlyWithinLata
     * @description 
     * When true calls are considered local only if within the same LATA.
     * @servertype Boolean
     * @type {boolean}
     */
    LocalOnlyWithinLata: boolean = false;

    /**
     * @name LocalTierNumber
     * @description 
     * Based on tier number use setting this is either (a) the tier number to compare against the target tier number to determine
     * if the activity is local or (b) the tier number to be assigned to local calls.  Note that this is only one method of assigning
     * tier numbers and that tier numbers are not always necessary.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LocalTierNumber: number = 0;

    /**
     * @name IntraLataDefault
     * @description 
     * When true default all calls within LATA to intraLATA type.
     * @servertype Boolean
     * @type {boolean}
     */
    IntraLataDefault: boolean = false;

    /**
     * @name IntraLataNpa
     * @description 
     * Comma delimited list of NPAs in the intraLATA area.
     * @servertype String
     * @type {string}
     */
    IntraLataNpa: string = "";

    /**
     * @name IntraLataTierNumber
     * @description 
     * Based on tier number use setting this is either (a) the tier number to compare against the target tier number to determine
     * if the activity is intralata or (b) the tier number to be assigned to intralata calls.  Note that this is only one method
     * of assigning tier numbers and that tier numbers are not always necessary.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    IntraLataTierNumber: number = 0;

    /**
     * @name IntrastateDefault
     * @description 
     * When true default all calls within state to intrastate type.
     * @servertype Boolean
     * @type {boolean}
     */
    IntrastateDefault: boolean = false;

    /**
     * @name IntrastateNpa
     * @description 
     * Comma delimited list of NPAs in the intrastate area.
     * @servertype String
     * @type {string}
     */
    IntrastateNpa: string = "";

    /**
     * @name IntrastateTierNumber
     * @description 
     * Based on tier number use setting this is either (a) the tier number to compare against the target tier number to determine
     * if the activity is intrastate or (b) the tier number to be assigned to intrastate calls.  Note that this is only one method
     * of assigning tier numbers and that tier numbers are not always necessary.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    IntrastateTierNumber: number = 0;

    /**
     * @name CustomLocationDomesticFullNumberSearch
     * @description 
     * When true will search for a full phone number on domestic calls.
     * @servertype Boolean
     * @type {boolean}
     */
    CustomLocationDomesticFullNumberSearch: boolean = false;

    /**
     * @name CustomLocationDomesticRangeSearch
     * @description 
     * When true will search for a full phone number that may fall within a range of numbers on domestic calls.
     * @servertype Boolean
     * @type {boolean}
     */
    CustomLocationDomesticRangeSearch: boolean = false;

    /**
     * @name CustomLocationDomesticDigitSearch
     * @description 
     * The range of digits to search for domestic number search.  For example, if 3-6 search for a match using phone number digits
     * starting at 6 and going down to 3.
     * @servertype String
     * @type {string}
     */
    CustomLocationDomesticDigitSearch: string = "";

    /**
     * @name CustomLocationInternationalFullNumberSearch
     * @description 
     * When true will search for a full phone number on international calls.
     * @servertype Boolean
     * @type {boolean}
     */
    CustomLocationInternationalFullNumberSearch: boolean = false;

    /**
     * @name CustomLocationInternationalRangeSearch
     * @description 
     * When true will search for a full phone number that may fall within a range of numbers on international calls.
     * @servertype Boolean
     * @type {boolean}
     */
    CustomLocationInternationalRangeSearch: boolean = false;

    /**
     * @name CustomLocationInternationalDigitSearch
     * @description 
     * The range of digits to search for international number search.  For example, if 1-5 search for a match using phone number
     * digits starting at 5 and going down to 1.
     * @servertype String
     * @type {string}
     */
    CustomLocationInternationalDigitSearch: string = "";

    /**
     * @name IntroducedDateTime
     * @description 
     * Date and time when the location profile was first introduced.  Used for informational purposes only.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    IntroducedDateTime: Date = null;

    /**
     * @name RetiredDateTime
     * @description 
     * Date and time when the location profile was retired.  If this is null then the location profile is active.  When a location
     * profile is no longer used this value can contain the date and time when the profile was retired.  If a date and time is provided
     * then it will no longer appear in the list of available location profiles but can still be accessed through the database for
     * informational purposes and is still available for those grandfathered in.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    RetiredDateTime: Date = null;

    /**
     * @name Flags
     * @description 
     * A list of flags for this location profile.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description 
     * Custom properties for this location profile beyond the user-defined values.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name TelecomLocationStandardEditViewModel
 * @description
 * The Telecom Location Standard table contains information related to standard locations.  See the location custom table for
 * custom locations.
 */
export class TelecomLocationStandardEditViewModel {

    /**
     * @name TelecomLocationStandardId
     * @description 
     * Telecom Location Standard Id uniquely identifies this location.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TelecomLocationStandardId: number = null;

    /**
     * @name Location
     * @description 
     * The location is a combination of Country Code, NPA, NXX, and subscriber without any separators between.  For example, Troy
     * MI USA could be represented as 1248583 where 1 is the country code, 248 is the NPA, and 583 is the NXX.
     * @servertype String
     * @type {string}
     */
    Location: string = "";

    /**
     * @name CountryCode
     * @description 
     * Country Code portion of location.
     * @servertype String
     * @type {string}
     */
    CountryCode: string = "";

    /**
     * @name Npa
     * @description 
     * NPA portion of location.
     * @servertype String
     * @type {string}
     */
    Npa: string = "";

    /**
     * @name Nxx
     * @description 
     * NXX portion of location.
     * @servertype String
     * @type {string}
     */
    Nxx: string = "";

    /**
     * @name Subscriber
     * @description 
     * Subscriber portion of location.
     * @servertype String
     * @type {string}
     */
    Subscriber: string = "";

    /**
     * @name City
     * @description 
     * City.
     * @servertype String
     * @type {string}
     */
    City: string = "";

    /**
     * @name State
     * @description 
     * State.
     * @servertype String
     * @type {string}
     */
    State: string = "";

    /**
     * @name Country
     * @description 
     * Country Name.
     * @servertype String
     * @type {string}
     */
    Country: string = "";

    /**
     * @name IsoCountryCode2
     * @description 
     * The two letter country code from ISO 3166 standard.
     * @servertype String
     * @type {string}
     */
    IsoCountryCode2: string = "";

    /**
     * @name IsoCountryCode3
     * @description 
     * The three letter country code from ISO 3166 standard.
     * @servertype String
     * @type {string}
     */
    IsoCountryCode3: string = "";

    /**
     * @name IsoCountryCodeNumeric3
     * @description 
     * The three digit country code from ISO 3166 standard.
     * @servertype String
     * @type {string}
     */
    IsoCountryCodeNumeric3: string = "";

    /**
     * @name LocationType
     * @description 
     * Location type identifier for this location.  Possible values include:
     * STD = Standard
     * MOB = Mobile
     * PRC = Premium Cost
     * FRC = Free Cost
     * DSC = Discount Cost
     * SHC = Shared Cost
     * ENH = Enhanced
     * INC = Incomplete
     * VPN = VPN
     * PAG = Pager
     * CSC = Carrier Selection
     * FOL = Follow-Me
     * PAY = Payphone
     * AUD = Audiotext
     * VID = Videotext
     * TST = Test
     * VOM = Voice Mail
     * SAT = Satellite
     * ISP = ISP
     * SIP = SIP
     * @servertype String
     * @type {string}
     */
    LocationType: string = "";

    /**
     * @name Vertical
     * @description 
     * Vertical Coordinate.  Can be Vertical portion of V&H coordinate or Longitude.
     * @servertype Double (nullable)
     * @type {number}
     */
    Vertical: number = 0;

    /**
     * @name Horizontal
     * @description 
     * Horizontal Coordinate.  Can be Horizontal portion of V&H coordinate or Latitude.
     * @servertype Double (nullable)
     * @type {number}
     */
    Horizontal: number = 0;

    /**
     * @name Lata
     * @description 
     * LATA value for this location.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Lata: number = 0;

    /**
     * @name LocationIdentifierCode
     * @description 
     * Location Identifier Code (e.g. CLLI) for this location.
     * @servertype String
     * @type {string}
     */
    LocationIdentifierCode: string = "";

    /**
     * @name OperatingCompanyNumber
     * @description 
     * Operating Company Number (OCN) for the CC/NPA/NXX.  Sometimes referred to as Common Carrier Code (CCC).
     * @servertype String
     * @type {string}
     */
    OperatingCompanyNumber: string = "";

    /**
     * @name Tier
     * @description 
     * Tier number to use for this location.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Tier: number = 0;

    /**
     * @name Geocode
     * @description 
     * Geographic code representing a tax jurisdiction.  The format depends on design of the tax system data but should use hyphen
     * delimiters and contain enough information to uniquely identify a jurisdiction.  So taxes applicable in larger jurisdictions
     * can be found using as few geocodes as needed they should start with the larger geographic identifier and work down to smaller
     * geographies (e.g. US, US-MI, US-MI-Oakland, US-MI-Oakland-Troy, etc.).  The geocode is combined with other information provided
     * for in the tax matrix table to fully qualify taxing jurisdictions and which items and scenarios are taxable in those jurisdictions.
     * @servertype String
     * @type {string}
     */
    Geocode: string = "";

    /**
     * @name PostalCode
     * @description 
     * Postal Code.  When available may represent the postal code of the central office.
     * @servertype String
     * @type {string}
     */
    PostalCode: string = "";

    /**
     * @name TimeZoneId
     * @description 
     * Contains either a time zone id or a specific UTC offset for this location.  If this contains a UTC offset it will be in the
     * format +99:99 where + could be a + or - and 9 represents any digit from 0-9.
     * @servertype String
     * @type {string}
     */
    TimeZoneId: string = null;

    /**
     * @name NpaLength
     * @description 
     * NPA length.  Used in situations where NPA is not specified but length is known.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NpaLength: number = 0;

    /**
     * @name NxxLength
     * @description 
     * NXX length.  Used in situations where NXX is not specified but length is known.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    NxxLength: number = 0;

    /**
     * @name DataStatus
     * @description 
     * Data status used for data import control purposes.  Possible values include:
     * A = Active
     * I = Inactive
     * When doing data import existing rows have status set to inactive.  Then as data is added or updated the status is set to
     * active.  After mass updates if inactive records are no longer desired they can be deleted.
     * @servertype String
     * @type {string}
     */
    DataStatus: string = "A";

    /**
     * @name Properties
     * @description 
     * Properties.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataLightModel
     * @type {m.MetaDataLightModel}
     */
    MetaData: m.MetaDataLightModel = new m.MetaDataLightModel();

}



/**
 * @name TelecomNumberingPlanEditViewModel
 * @description
 * The Telecom Numbering Plan table contains information related to different numbering plans.  The numbering plan dictates
 * how phone numbers are interpreted and formatted.  Note that although numbering plans are typically associated with a country
 * they are not necessarily restricted to one country.  For example, the North American Numbering Plan (NANP) includes the United
 * States of America, Canada and several Caribbean countries.
 */
export class TelecomNumberingPlanEditViewModel {

    /**
     * @name TelecomNumberingPlanId
     * @description 
     * Telecom Numbering Plan Id uniquely identifies this numbering plan.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TelecomNumberingPlanId: number = null;

    /**
     * @name ExternalTelecomNumberingPlanId
     * @description 
     * Telecom Numbering Plan Id used in external systems or as an alternate means of identification.
     * @servertype String
     * @type {string}
     */
    ExternalTelecomNumberingPlanId: string = "";

    /**
     * @name Description
     * @description 
     * Numbering Plan Description.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name IsoCountryCode2
     * @description 
     * The two letter country code from ISO 3166 standard.
     * @servertype String
     * @type {string}
     */
    IsoCountryCode2: string = "";

    /**
     * @name IsoCountryCode3
     * @description 
     * The three letter country code from ISO 3166 standard.
     * @servertype String
     * @type {string}
     */
    IsoCountryCode3: string = "";

    /**
     * @name IsoCountryCodeNumeric3
     * @description 
     * The three digit country code from ISO 3166 standard.
     * @servertype String
     * @type {string}
     */
    IsoCountryCodeNumeric3: string = "";

    /**
     * @name TelecomCountryCode
     * @description 
     * The country code is the phone access code used to dial this country.
     * @servertype String
     * @type {string}
     */
    TelecomCountryCode: string = "";

    /**
     * @name MinimumNpaDigits
     * @description 
     * Minimum NPA (Area Code) Digits.  This is the number of digits in the shortest NPA.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MinimumNpaDigits: number = 0;

    /**
     * @name MaximumNpaDigits
     * @description 
     * Maximum NPA (Area Code) Digits.  This is the number of digits in the longest NPA.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaximumNpaDigits: number = 0;

    /**
     * @name MinimumNxxDigits
     * @description 
     * Minimum NXX (Exchange) Digits.  This is the number of digits in the shortest NXX.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MinimumNxxDigits: number = 0;

    /**
     * @name MaximumNxxDigits
     * @description 
     * Maximum NXX (Exchange) Digits.  This is the number of digits in the longest NXX.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaximumNxxDigits: number = 0;

    /**
     * @name MinimumNumberDigits
     * @description 
     * Minimum Phone Number Digits.  This is the number of digits in the shortest phone number.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MinimumNumberDigits: number = 0;

    /**
     * @name MaximumNumberDigits
     * @description 
     * Maximum Phone Number Digits.  This is the number of digits in the longest phone number.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaximumNumberDigits: number = 0;

    /**
     * @name MinimumLocalNumberDigits
     * @description 
     * Minimum Local Phone Number Digits.  This is the number of digits in the shortest local phone number.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MinimumLocalNumberDigits: number = 0;

    /**
     * @name MaximumLocalNumberDigits
     * @description 
     * Maximum Local Phone Number Digits.  This is the number of digits in the longest local phone number.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaximumLocalNumberDigits: number = 0;

    /**
     * @name MinimumLongDistanceNumberDigits
     * @description 
     * Minimum Long Distance Phone Number Digits.  This is the number of digits in the shortest long distance phone number.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MinimumLongDistanceNumberDigits: number = 0;

    /**
     * @name MaximumLongDistanceNumberDigits
     * @description 
     * Maximum Long Distance Phone Number Digits.  This is the number of digits in the longest long distance phone number.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaximumLongDistanceNumberDigits: number = 0;

    /**
     * @name MinimumInternationalNumberDigits
     * @description 
     * Minimum International Phone Number Digits.  This is the number of digits in the shortest international phone number.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MinimumInternationalNumberDigits: number = 0;

    /**
     * @name MaximumInternationalNumberDigits
     * @description 
     * Maximum International Phone Number Digits.  This is the number of digits in the longest international phone number.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MaximumInternationalNumberDigits: number = 0;

    /**
     * @name InternationalSearchDigits
     * @description 
     * This is the maximum number of digits to use when searching for international locations.  This is the longest number of digits
     * for a country code + city code combination.  The minimum value is always one because of one digit country codes (i.e. 1 is
     * North America).
     * @servertype Int32 (nullable)
     * @type {number}
     */
    InternationalSearchDigits: number = 0;

    /**
     * @name InternationalSearchValues
     * @description 
     * This is a comma delimited string of values to search when attempting to identify international numbers.  For example, a value
     * of F,13,3 would result in the full number being searched (F), if not found then the first 13 digits of the number would be
     * searched and if that wasn't found the first 3 down to 1 digits would be searched.  Note that since the shortest country code
     * is 1 digit long that is automatically used as the lower bound for searching.
     * @servertype String
     * @type {string}
     */
    InternationalSearchValues: string = "";

    /**
     * @name DomesticSearchValues
     * @description 
     * This is a comma delimited string of values to search when attempting to identify domestic numbers.  For example, a value
     * of F,7,1 would result in the full number being searched (F), if not found then the first 7 digits of the number would be
     * searched and if that wasn't found the first 1 digit would be searched.
     * @servertype String
     * @type {string}
     */
    DomesticSearchValues: string = "";

    /**
     * @name AllCallsIncludeNpa
     * @description 
     * True means all calls include the NPA.
     * @servertype Boolean
     * @type {boolean}
     */
    AllCallsIncludeNpa: boolean = false;

    /**
     * @name LocalCallsNeverIncludeNpa
     * @description 
     * True means local calls never include the NPA.
     * @servertype Boolean
     * @type {boolean}
     */
    LocalCallsNeverIncludeNpa: boolean = false;

    /**
     * @name AniNeedsLongDistancePrefix
     * @description 
     * True means ANI/Caller ID needs the long distance prefix.
     * @servertype Boolean
     * @type {boolean}
     */
    AniNeedsLongDistancePrefix: boolean = false;

    /**
     * @name IfNotLongDistanceThenLocal
     * @description 
     * True means if no long distance access code then must be local.
     * @servertype Boolean
     * @type {boolean}
     */
    IfNotLongDistanceThenLocal: boolean = false;

    /**
     * @name InsertNpaForLocalCalls
     * @description 
     * True means insert NPA for local calls.
     * @servertype Boolean
     * @type {boolean}
     */
    InsertNpaForLocalCalls: boolean = false;

    /**
     * @name TreatAllCallsAsInternational
     * @description 
     * True means treat all calls as international.  Useful for some E.164 roaming scenarios where all rates are fixed from country
     * to country and do not depend on activity type.
     * @servertype Boolean
     * @type {boolean}
     */
    TreatAllCallsAsInternational: boolean = false;

    /**
     * @name AccessCodesLongDistance
     * @description 
     * Comma delimited list of long distance access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesLongDistance: string = "";

    /**
     * @name AccessCodesInternational
     * @description 
     * Comma delimited list of international access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesInternational: string = "";

    /**
     * @name AccessCodesFree
     * @description 
     * Comma delimited list of free access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesFree: string = "";

    /**
     * @name AccessCodesOperator
     * @description 
     * Comma delimited list of operator access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesOperator: string = "";

    /**
     * @name AccessCodesInternationalOperator
     * @description 
     * Comma delimited list of international operator access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesInternationalOperator: string = "";

    /**
     * @name AccessCodesLocalInformation
     * @description 
     * Comma delimited list of local information access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesLocalInformation: string = "";

    /**
     * @name AccessCodesLongDistanceInformation
     * @description 
     * Comma delimited list of long distance information access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesLongDistanceInformation: string = "";

    /**
     * @name AccessCodesEmergency
     * @description 
     * Comma delimited list of emergency access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesEmergency: string = "";

    /**
     * @name AccessCodesException
     * @description 
     * Comma delimited list of exception access digits.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    AccessCodesException: string = "";

    /**
     * @name MinimumFormattingDigits
     * @description 
     * This is the minimum number of digits required before any phone number formatting will be done.  Some special access numbers
     * or VPN numbers should not be formatted.  For example, in the North American numbering plan (NANP) the smallest standard PSTN
     * number dialed is 7 digits so if this field contains 7 then special numbers like 911 and VPN numbers like 10002 would not
     * be formatted because they are less than 7 digits.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    MinimumFormattingDigits: number = 0;

    /**
     * @name DropDigits
     * @description 
     * Comma delimited list of digits to drop from phone number.  This could be automatic route selection (ARS) digits or other
     * digits to drop.  Use 'E:' prefix to indicate the code is a suffix.
     * @servertype String
     * @type {string}
     */
    DropDigits: string = "";

    /**
     * @name DropLongDistanceAccessCode
     * @description 
     * True means drop long distance access code from phone number.
     * @servertype Boolean
     * @type {boolean}
     */
    DropLongDistanceAccessCode: boolean = false;

    /**
     * @name DropInternationalAccessCode
     * @description 
     * True means drop international access code from phone number.
     * @servertype Boolean
     * @type {boolean}
     */
    DropInternationalAccessCode: boolean = false;

    /**
     * @name FormatInternationalAccessCodeAsNpa
     * @description 
     * True means format international access digits as NPA.
     * @servertype Boolean
     * @type {boolean}
     */
    FormatInternationalAccessCodeAsNpa: boolean = false;

    /**
     * @name FormatFreeAccessCodeAsNpa
     * @description 
     * True means format free access digits as NPA.
     * @servertype Boolean
     * @type {boolean}
     */
    FormatFreeAccessCodeAsNpa: boolean = false;

    /**
     * @name FormatOperatorAccessCodeAsNpa
     * @description 
     * True means format operator access digits as NPA.
     * @servertype Boolean
     * @type {boolean}
     */
    FormatOperatorAccessCodeAsNpa: boolean = false;

    /**
     * @name FormatInternationalOperatorAccessCodeAsNpa
     * @description 
     * True means format international operator access digits as NPA.
     * @servertype Boolean
     * @type {boolean}
     */
    FormatInternationalOperatorAccessCodeAsNpa: boolean = false;

    /**
     * @name FormatLocalInformationAccessCodeAsNpa
     * @description 
     * True means format local information access digits as NPA.
     * @servertype Boolean
     * @type {boolean}
     */
    FormatLocalInformationAccessCodeAsNpa: boolean = false;

    /**
     * @name FormatLongDistanceInformationAccessCodeAsNpa
     * @description 
     * True means format long distance information access digits as NPA.
     * @servertype Boolean
     * @type {boolean}
     */
    FormatLongDistanceInformationAccessCodeAsNpa: boolean = false;

    /**
     * @name FormatEmergencyAccessCodeAsNpa
     * @description 
     * True means format emergency access digits as NPA.
     * @servertype Boolean
     * @type {boolean}
     */
    FormatEmergencyAccessCodeAsNpa: boolean = false;

    /**
     * @name FormatExceptionAccessCodeAsNpa
     * @description 
     * True means format exception access digits as NPA.
     * @servertype Boolean
     * @type {boolean}
     */
    FormatExceptionAccessCodeAsNpa: boolean = false;

    /**
     * @name FormatAsE164
     * @description 
     * True means format domestic calls in E.164 format (i.e. like international calls with country code).
     * @servertype Boolean
     * @type {boolean}
     */
    FormatAsE164: boolean = false;

    /**
     * @name FormatSeparatorsBeforeLongDistanceAccessCode
     * @description 
     * Format separators before long distance access code.
     * @servertype String
     * @type {string}
     */
    FormatSeparatorsBeforeLongDistanceAccessCode: string = "";

    /**
     * @name FormatSeparatorsAfterLongDistanceAccessCode
     * @description 
     * Format separators after long distance access code.
     * @servertype String
     * @type {string}
     */
    FormatSeparatorsAfterLongDistanceAccessCode: string = "";

    /**
     * @name FormatSeparatorsBeforeNpa
     * @description 
     * Format separators before NPA.
     * @servertype String
     * @type {string}
     */
    FormatSeparatorsBeforeNpa: string = "";

    /**
     * @name FormatSeparatorsAfterNpa
     * @description 
     * Format separators after NPA.
     * @servertype String
     * @type {string}
     */
    FormatSeparatorsAfterNpa: string = "";

    /**
     * @name FormatSeparatorsBeforeNxx
     * @description 
     * Format separators before NXX.
     * @servertype String
     * @type {string}
     */
    FormatSeparatorsBeforeNxx: string = "";

    /**
     * @name FormatSeparatorsAfterNxx
     * @description 
     * Format separators after NXX.
     * @servertype String
     * @type {string}
     */
    FormatSeparatorsAfterNxx: string = "";

    /**
     * @name FormatSeparatorsBeforeInternationalAccessCode
     * @description 
     * Format separators before international access code.
     * @servertype String
     * @type {string}
     */
    FormatSeparatorsBeforeInternationalAccessCode: string = "";

    /**
     * @name FormatSeparatorsAfterInternationalAccessCode
     * @description 
     * Format separators after international access code.
     * @servertype String
     * @type {string}
     */
    FormatSeparatorsAfterInternationalAccessCode: string = "";

    /**
     * @name FormatSeparatorsBeforeCountryCode
     * @description 
     * Format separators before country code.
     * @servertype String
     * @type {string}
     */
    FormatSeparatorsBeforeCountryCode: string = "";

    /**
     * @name FormatSeparatorsAfterCountryCode
     * @description 
     * Format separators after country code.
     * @servertype String
     * @type {string}
     */
    FormatSeparatorsAfterCountryCode: string = "";

    /**
     * @name FormatSeparatorsBeforeCityCode
     * @description 
     * Format separators before city code.
     * @servertype String
     * @type {string}
     */
    FormatSeparatorsBeforeCityCode: string = "";

    /**
     * @name FormatSeparatorsAfterCityCode
     * @description 
     * Format separators after city code.
     * @servertype String
     * @type {string}
     */
    FormatSeparatorsAfterCityCode: string = "";

    /**
     * @name FormatOther
     * @description 
     * Format separators for other general use.
     * @servertype String
     * @type {string}
     */
    FormatOther: string = "";

    /**
     * @name DefaultNumberingPlan
     * @description 
     * True means this is the default numbering plan shown as first selection on selection dialogs.
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultNumberingPlan: boolean = false;

    /**
     * @name HideNumberingPlan
     * @description 
     * True means hide this numbering plan so it doesn't appear on selection dialogs.
     * @servertype Boolean
     * @type {boolean}
     */
    HideNumberingPlan: boolean = false;

    /**
     * @name NumberFormatting
     * @description 
     * Custom number formatting options.
     * @servertype String
     * @type {string}
     */
    NumberFormatting: string = "";

    /**
     * @name Flags
     * @description 
     * A list of flags for this numbering plan.
     * @servertype String
     * @type {string}
     */
    Flags: string = "";

    /**
     * @name Properties
     * @description 
     * Custom properties for this numbering plan beyond the user-defined values.
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name Comments
     * @description 
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Attributes
     * @servertype System.Object
     * @type {any}
     */
    Attributes: any = {};

    /**
     * @name TimeZone
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



