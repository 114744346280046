import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, PreloadAllModules } from '@angular/router';
import { CanDeactivateGuard } from 'projects/core-lib/src/lib/services/can-deactivate-guard.service';
import { AppPreloaderService } from 'projects/core-lib/src/lib/services/app-preloader.service';
import { HomeComponent } from 'projects/ib-kiosk-app/src/app/home/home.component';
import { RefundComponent } from 'projects/ib-kiosk-app/src/app/refund/refund.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: ':id', component: HomeComponent, pathMatch: 'full' },
  { path: 'refund', component: RefundComponent },
  { path: "refund/:id", component: RefundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: AppPreloaderService })],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(protected router: Router) {

    // Add any wildcard catch all
    // e.g. { path: '**', component: ComponentBuilder }
    // Could parse url for form id or asset id and do dynamic content
    // if not found could go to 404 page or to dashboard
    // dynamic components seem tricky with AOT could we have window.ibapp.[appname]
    // object with code that we then hook to form events that gets copy of
    // form, data, etc. passed into the methods for actions?  The form
    // would just know the "appname" and the event method to fire.
    // Or maybe "appname" is just "formid39811" so events that need to execute
    // live at window.ibapp.formid39811.eventname(form, data)? will we be able
    // to attach events and pass data dynamically in form?  Maybe data if referenced
    // at window.ibapp.formid39811.data ?

    // Reset routes with what we've added via helpers
    //this.router.resetConfig(appRoutes);

  }

}
