import { Component, OnInit } from '@angular/core';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { KioskSettings } from 'projects/ib-kiosk-app/src/app/models/kiosk-settings';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  settings: KioskSettings = null;
  id: string = null;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected appService: AppService) {

    this.settings = this.appService.config.settings;

    // If we don't have a defaultRoute or defaultUrl trust that we'll have a defaultApplication setting and our html will render the component we want.
    if (this.settings.defaultRoute) {
      this.router.navigate([this.settings.defaultRoute]);
    } else if (this.settings.defaultUrl) {
      window.location.href = this.settings.defaultUrl;
    }

  }


  ngOnInit() {

    // If we have a defaultApplication setting then we may need this id to pass through to it
    this.id = this.route.snapshot.params["id"];

  }


}
